import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Typography } from "antd";
import api from "api";
import SendMethods from "./sendmethods";
import SegmentPicker from "components/picker/segment_picker";
import {handleTemplateDescription} from '../../common/text.js'

const { Title } = Typography;

const { TabPane } = Tabs;
export default class ProgramConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {
                id: '0',
                events: [],
                is_active: true,
            },
            uploaded_logo: "/image/microsoft.png",
            showData: false,
            logo_status: "system",
            isLimit: false,
        }
        this.form = React.createRef();
        this.SendMethods = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        api.post("pointprogram/getconfigbyid", { type: this.props.match.params.type, id: this.props.match.params.id }).then(res => {
            if (this.props.match.params.id == "0")
                res.logo = res.default_logo;
            else {
                if (res.logo != res.default_logo)
                    this.setState({ logo_status: "customer" });
            }
            if (this.props.match.params.id == "0")
                res.is_active = true;
            if (res.customer_segment_id != "0" && res.customer_segment_id != null)
                this.setState({ isLimit: true })

            res = handleTemplateDescription(res);
            // if (res.reward != null)
            // {
            //     res.reward = res.reward.amount;
            // }
            // else
            //     res.reward = 0;

            this.setState({ config: res }, () => {
                this.form.current.setFieldsValue(res)
                // if (this.SendMethods.current)
                //     this.SendMethods.current.getData(res.id);
            })

        })
    }

    handleDescription = () => {
        
    }

    onChangeEvents = (events) => {
        var config = { ...this.state.config };
        config.events = events;
        this.setState({ config })
    }

    saveProgram = (values) => {
        var config = { ...this.state.config };
        values.logo = config.logo;
        values.name = config.name;
        values.events = config.events;
        values.reward = {
            id: "point",
            type: "fixed",
            amount: config.reward.amount,
        };

        if (values.customer_segment_id == null)
            values.customer_segment_id = "0";
        api.post("pointprogram/saveconfig", { config: values }).then(res => {
            message.success('Lưu thành công')
            var href = "/marketing/program_config/" + this.props.match.params.type + "/" + res.program_id;
            if (!window.location.href.includes(href))
                window.location.href = href;
            this.getData();
        }).catch(err => {
            message.error('Có lỗi xảy ra khi lưu')
            console.log('save err', err)
        })
    }

    radioChange = (value) => {
        var config = this.state.config;
        config.is_active = value;
        this.setState({ config });
    }

    logoChange = (value) => {
        if (value == "system") {
            var config = this.state.config;
            config.logo = this.state.config.default_logo;
            this.setState({ config });
        }
        else {
            var config = this.state.config;
            config.logo = this.state.uploaded_logo;
            this.setState({ config });
        }
        this.setState({ logo_status: value });
    }

    onTitleChange = (value) => {
        var config = this.state.config;
        config.name = value;
        this.setState({ config });
    }

    onPointChange = (value) => {
        var config = this.state.config;
        config.reward.amount = value;
        handleTemplateDescription(config);
        this.setState({ config });
    }
    onLimitChange = (value) => {
        console.log('checkbox', value)
        var config = this.state.config;
        if (!value) {
            config.customer_segment_id = "0"
            this.form.current.setFieldsValue({ customer_segment_id: "0" })
        }
        this.setState({ isLimit: value });
    }


    render() {
        var config = { ...this.state.config };
        var logo_status = this.state.logo_status;
        var point = 0;
        if (config.reward != null) {
            point = config.reward.amount;
        }

        return (
            <Form
                name="emailsetting"
                ref={this.form}
                layout="vertical"
                onFinish={this.saveProgram}
            >
                {
                    <div className={'program-wrapper'} style={{ width: "101%", marginLeft: -13 }} >
                        <Form.Item name={'name'} rules={[{ type: 'string' }]}>
                            {/* <Card style={{ width: "99%", marginLeft: 10, marginBottom: -15, height: 80 }}> */}
                            <Title level={2} style={{ marginLeft: 10 }} editable={{
                                onChange: this.onTitleChange,
                                icon: <i className="fa fa-edit" style={{ fontSize: 20, marginLeft: 10 }}></i>
                            }}>{config.name}</Title>
                            {/* </Card> */}
                        </Form.Item>
                        <Row>
                            <div style={{ display: "flex", width: "100%" }}>

                                <div style={{ display: "flex", width: "50%", margin: 10 }}>
                                    <Card title="Trạng thái" size="small" style={{ width: "50%", marginRight: 20 }}>
                                        <Form.Item hidden name={'id'} label={"id"} rules={[{ type: 'string' }]}>
                                        </Form.Item>
                                        <Form.Item hidden name={'type'} label={"type"} rules={[{ type: 'string' }]}>
                                        </Form.Item>
                                        <Form.Item hidden name={'default_logo'} label={"default_logo"} rules={[{ type: 'string' }]}>
                                        </Form.Item>
                                        <Form.Item
                                            name="is_active"
                                        >
                                            <Radio.Group
                                                onChange={(e) => this.radioChange(e.target.value)}
                                            >
                                                <Space direction="vertical">
                                                    <Radio
                                                        value={true}
                                                        style={{ marginTop: 30 }}>Hoạt động</Radio>
                                                    <Radio
                                                        value={false}
                                                        style={{ marginTop: 30 }}>Tạm dừng</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>

                                    </Card>

                                    <Card title="Biểu tượng" size="small" style={{ width: "50%" }}>

                                        <Radio.Group onChange={(e) => this.logoChange(e.target.value)} value={logo_status} style={{ margin: "15px 0px 0px 30px" }}>
                                            <Space direction="vertical">
                                                <Radio
                                                    value="system"
                                                >Mặc định</Radio>
                                                <div style={{ width: "55px", height: "55px", marginLeft: 25 }}><img src={config.default_logo} style={{ width: "55px", height: "55px", border: "1px solid #dfdfdf", }}></img></div>
                                                <Radio
                                                    value="customer"
                                                >Upload icon của bạn</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Card>
                                </div>

                                <Card title="Nội dung chương trình" style={{ width: "50%", margin: 10 }} size="small" >
                                    <Form.Item name={'description'} rules={[{ type: 'string' }]}>
                                        {config.display_description != null && config.display_description != "" ? <div dangerouslySetInnerHTML={{ __html: config.display_description }}></div> : ""}
                                    </Form.Item>
                                </Card>
                            </div>

                        </Row>
                        <Row style={{ display: "flex", width: "100%" }}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <Card title="Phần thưởng" style={{ width: "50%", margin: 10 }}>
                                    <label style={{ marginBottom: 10 }}>Điểm</label>
                                    <InputNumber style={{ marginBottom: 10, marginTop: 20, width: "100%" }} value={point} onChange={(value) => this.onPointChange(value)} 
                                    />
                                    <Checkbox style={{ marginTop: 20, marginBottom: 10 }} checked={this.state.isLimit} onChange={(e) => this.onLimitChange(e.target.checked)}>Giới hạn đối tượng tham gia</Checkbox>
                                    <Form.Item
                                        name="customer_segment_id"
                                        label="Đối tượng"
                                    >
                                        <SegmentPicker disabled={!this.state.isLimit}></SegmentPicker>

                                    </Form.Item>
                                </Card>
                                <SendMethods onChangeEvents={this.onChangeEvents} ref={this.SendMethods} events={this.state.config.events} program_id={this.props.match.params.id} type={this.props.match.params.type}></SendMethods>
                            </div>
                        </Row>
                        <Row>
                            <Card size="small" style={{ width: "99%", marginLeft: 10, marginTop: 10, height: 60 }}>
                                <Button type="primary" style={{ position: "absolute", right: 20 }} htmlType="submit">
                                    Lưu
                                </Button>
                            </Card>
                        </Row>
                    </div>

                }

            </Form>
        )
    }
}