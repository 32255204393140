import React,{createRef} from "react";
import { Card, Col, Row, Tag, Button, message, Popconfirm, Empty, Skeleton, Tabs,Switch,Typography,Space  } from "antd";
import api from "api";
import PopupCreateExchangPointProgram from "./popupcreateexchangepointprogram";
import { handleTemplateDescription,createSearchText } from "../../../common/text.js";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";
import AntTable from "components/common/AntTable";
import { blue } from "@ant-design/colors";


const { TabPane } = Tabs;
const { Text,Title } = Typography;
export default class RewardPointProgramList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      program_lst: [],
      visible: false,
      filter: {
        query: {
          $and: [{ type: "exchange_point" }, { is_delete: false }],
        },
      },
    };
    this.PopupCreateExchangPointProgram = React.createRef();
    this.Tb = createRef();
  }

  componentDidMount() {
    // this.getConfig();
    this.getTier();
  }

  getTier = () => {
    api.post("/loyaltyconfig/getTier").then((res) => {
      this.setState({ tiers: res.tiers });
    });
  };

  getConfig = () => {
    var filter = this.state.filter;
    if (filter.query.$and.length === 0) filter = { query: { $and: [{ type: "exchange_point" }] } };
    api.post("exchangepointprogram/getconfigdetails", filter).then((res) => {
      var program_lst = res.filter((z) => z.id != "0");
      program_lst = program_lst.map((config) => {
        config = handleTemplateDescription(config);
        return config;
      });

      this.setState({ program_lst, visible: true });
    });
  };

  deleteConfig = (id) => {
    api
      .post("exchangepointprogram/deleteconfig", { id: id })
      .then((res) => {
        message.success("Xoá thành công");
        this.getConfig();
      })
      .catch((err) => {
        message.error("Có lỗi xảy ra khi xoá");
      });
  };

  editRewardProgarm = (item) => {
    var href = "";
    api.post("exchangepointprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = "/marketing/exchangeprogram/" + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };
  async saveProgram(item){
    await api
      .post("exchangepointprogram/saveprogram", { data: item })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        if (err.code == 262) {
          message.error("Voucher Prefix đã tồn tại!");
        } else {
          message.error("Có lỗi xảy ra khi lưu");
        }
      });
  }
  getColumn = () => {
    var columns = [
      {
        title: "",
        dataIndex: "is_active",
        key: "is_active",
        align: "center",
        with:60,
        render: (text, record, index) => {
          return(
            <>
            <Switch checked={text} onChange={async()=>{
              let item={...record};
              item.is_active=!item.is_active;
              delete
              item.voucher;
              delete
              item.customer_segment;
              await this.saveProgram(item);
              this.Tb.current.reload();
            }}/>
            </>);
        },
      },
      {
        title: <div style={{padding:16}}>Biểu tượng</div>,
        dataIndex: "logo",
        key: "logo",
        with:140,
        render: (text, record, index) => {
          return(
          <>
          <div style={{width:"100%",textAlign:"center"}}>
            <img style={{width:48,height:48,objectFit:"contain"}} src={text} alt="logo" />
          </div>
          </>);
        },
      },
      {
        title: <div style={{padding:16}}>Tên chương trình</div>,
        dataIndex: "name",
        key: "name",
        with:370,
        render: (text, record, index) => {
          return(
          <div style={{padding:16}}>
            <Text type="strong" style={{fontWeight:500}}>{record?.name}</Text>
            <br/>
            <Text type="secondary" style={{fontSize:12}}>{record?.short_description}</Text>
          </div>);
        },
      },
      {
        title: <div style={{padding:16}}>Đối tượng</div>,
        dataIndex: "type",
        key: "type",
        with:370,
        render: (text, record, index) => {
          let result=""
          if(record?.conditions?.participants?.type==="all")
            result="Tất cả";
          if(record?.conditions?.participants?.type==="tier")
          {
            let name="";
            this.state?.tiers?.forEach((item)=>{
              if(item.id===record?.conditions?.participants?.tier_id)
              name =item.name;
            })
            result=`Hạng thành viên: ${name}`;
           }
           if(record?.conditions?.participants?.type==="group")
           {
            result=`Tập khách hàng: ${record?.customer_segment[0]?.name}`;
           }
           return(<Text type="strong" style={{fontWeight:500}}>{result}</Text>);
        },
      },
      {
        title: '',
        dataIndex: "action",
        key: 'action',
        align: "center",
        with:120,
        render: (text, record, index) => (
            <Space direction="horizontal" >
                <Popconfirm  title="Bạn có chắc muốn xóa không?" onConfirm={() => this.deleteConfig(record.id)} okText="Có" cancelText="Không">
                        <Text style={{ color: blue.primary }} >Xoá</Text>
                </Popconfirm>
                <Text style={{ color: blue.primary, paddingRight: 8 }} onClick={() => this.editRewardProgarm(record)}>Sửa</Text>
            </Space>
        ),
      }
    ];
    return columns;
  };
  onFilterChange = (type, value) => {
    var filter = { ...this.state.filter };
    var index = filter.query.$and.findIndex((x) => x[type]);
    if (value == "") {
      if (index > -1);
      filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][type] = { $regex: value };
      } else {
        filter.query.$and.push({ [type]: { $regex: value } });
      }
    }
    if (filter.query.$and.length === 0) filter = { query: { $and: [{ type: "exchange_point" }] } };
    this.setState({ filter: filter },()=>this.Tb.current.reload(undefined,false));
    // this.setState({ filter: filter }, () => this.getConfig());
  };

  onTabsChange = (value) => {
    this.setState(
      {
        type: value,
      },
      () => {
        console.log(this.state);
      }
    );
    this.onFilterChange("type", value);
  };

  render() {
    var utcNow = new Date(moment.utc().toDate()).toISOString();
    return (
      <>
        <div style={{ backgroundColor: "#ffffff" }}>
          <div style={{ padding: 25 }}>
            <SearchBox
              name="z"
              data={[]}
              placeholder="Thông tin"
              dataButton={[
                <Button type="primary" onClick={() => this.PopupCreateExchangPointProgram.current.open()} style={{ marginRight: 15 }} htmlType="submit">
                  <i className="fa fa-plus" style={{ marginRight: 5 }}></i> Tạo mới
                </Button>,
              ]}
              onSearch={() => this.getConfig()}
              onChange={(value) => {
                this.onFilterChange("name",value);
                // this.onFilterChange("search_text",createSearchText(value));
              }}
            />
          </div>
        </div>

        <Card style={{ marginTop: 30 }}>
          <Tabs activeKey={this.state.type} onChange={(key) => this.onTabsChange(key)}>
            <TabPane tab="Chương trình đổi điểm" key="exchange_point"></TabPane>
            <TabPane tab="Chương trình freeship" key="exchange_freeship"></TabPane>
            <TabPane tab="Chương trình Percentage off" key="exchange_percentage"></TabPane>
            {/* <TabPane tab="Chương trình quà tặng" key="exchange_gift"></TabPane> */}
            <TabPane tab="Chương trình thẻ cào" key="phone_card"></TabPane>
          </Tabs>
          <AntTable
          size="small"
          columns={this.getColumn()}
          customSearch={this.state.filter}
          tableName={"tbCustomerRewardPointProgramList"}
          ajax="exchangepointprogram/getconfigdetailsfollowpagination"
          ref={this.Tb}
        ></AntTable>
          {/* {this.state.visible ? (
            <div>
              {this.state.program_lst != null && this.state.program_lst.length != 0 ? (
                this.state.program_lst.map((item) => {
                  return (
                    <>
                      <Card size="small">
                        <Row guiter={16}>
                          <Col span={18} style={{ display: "flex" }}>
                            <div style={{ width: 130 }}>
                              {
                                <div src={item.logo} style={{ width: 100, height: 100 }}>
                                  {item.display_style === "image" ? (
                                    <img src={item.logo} style={{ width: "100%", height: "100%" }}></img>
                                  ) : (
                                    <div style={{ backgroundColor: "#ff0000", padding: 10, width: "100%", height: "100%", display: "flex" }}>
                                      <span style={{ margin: "auto", color: "#ffffff", fontFamily: "cursive" }}>{item.text}</span>
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                            <div style={{ marginTop: 15, marginLeft: 10, overflow: "hidden", width: 500 }}>
                              <b className="listTitle">{item.name}</b>
                              <div className="description">{item.display_short_description}</div>
                            </div>
                          </Col>

                          <Col span={4} style={{ display: "flex" }}>
                            {item.is_active &&
                            (!item.voucher.time_start.is_time_start || (item.voucher.time_start.is_time_start && item.voucher.time_start.value <= utcNow)) &&
                            (!item.voucher.time_end.is_time_end || (item.voucher.time_end.is_time_end && item.voucher.time_end.value >= utcNow)) ? (
                              <Tag style={{ margin: "auto" }} color="#87d068">
                                Đang hoạt đông
                              </Tag>
                            ) : (
                              <Tag style={{ margin: "auto" }} color="#f50">
                                Đã tạm dừng
                              </Tag>
                            )}
                          </Col>
                          <Col span={2} style={{ marginTop: 30, color: "#1890FF", fontSize: 20 }}>
                            <i style={{ marginRight: 10, height: 20, width: 20 }} className="fa fa-edit" aria-hidden="true" onClick={() => this.editRewardProgarm(item)}></i>
                            <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.deleteConfig(item.id)} okText="Có" cancelText="Không">
                              <i style={{ marginRight: 10 }} className="fa fa-trash" aria-hidden="true"></i>
                            </Popconfirm>
                          </Col>
                        </Row>
                      </Card>
                      <div style={{ marginTop: 10 }}></div>
                    </>
                  );
                })
              ) : (
                <Card bodyStyle={{ height: "50vh" }}>
                  <div>
                    <Empty style={{ marginTop: "13vh" }} description="Hãy tạo mới chương trình đầu tiên"></Empty>
                    <Row justify="center">
                      <Button
                        onClick={() => {
                          this.PopupCreateExchangPointProgram.current.open();
                        }}
                        style={{ marginTop: 20 }}
                        type="primary"
                      >
                        <i className="fas fa-plus"></i>&nbsp;Tạo mới
                      </Button>
                    </Row>
                  </div>
                </Card>
              )}
            </div>
          ) : (
            <Skeleton />
          )} */}
        </Card>

        <PopupCreateExchangPointProgram ref={this.PopupCreateExchangPointProgram}></PopupCreateExchangPointProgram>
      </>
    );
  }
}
