import React from "react";
import { Button, Switch, Row, Col, Modal, Form, Input, Select } from "antd";
import { makeid } from "common/custom_field";

const { Option } = Select;

export default class PopupSaveImageUrl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
    };
    this.form = React.createRef();
  }

  open = (url) => {
    this.setState({ visible: true });
    setTimeout(() => {
      this.form.current.setFieldsValue({ url: url });
    }, 1000);
  };

  close = () => {
    this.setState({ visible: false });
  };

  finish = (value) => {
    if (!value.id) {
      value.id = makeid(5);
    }
    this.props.saveUrl(value);
    this.setState({ visible: false });
  };

  render() {
    return (
      <Modal
        width={"40%"}
        open={this.state.visible}
        title="Basic Modal"
        onOk={() => {
          this.form.current.submit();
        }}
        onCancel={this.close}
      >
        <Form ref={this.form} layout="vertical" onFinish={this.finish}>
          <Form.Item label="Link hình ảnh" name="url" rules={[{ required: true, message: "Bạn chưa nhập link hình ảnh" }]}>
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
