import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");
var getLocalTimeZone = function () {
  var offset = new Date().getTimezoneOffset();
  return -(offset / 60);
};

var getTimezoneOffset = function () {
  var offset = new Date().getTimezoneOffset();
  return offset;
};

var formatMMSS = function (duration) {
  var m = Math.floor(duration / 60);
  var s = duration % 60;
  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }
  return `${m}:${s}`;
};

var getLocalTime = function (utc) {
  var offset = moment().utcOffset();
  //return moment.utc(utc).utcOffset(offset)
  return moment.utc(utc, "YYYY-MM-DD HH:mm:ss").utcOffset(offset);
  // return moment('2021-05-25 04:23:00.267').utcOffset(offset)
};

var formatL = function (utc) {
  return getLocalTime(utc).format("L LTS");
};
var formatLHD = function (utc) {
  return getLocalTime(utc).format("LT L");
};

var formatS = function (utc) {
  return getLocalTime(utc).format("L");
};

var formatH = function (utc) {
  return getLocalTime(utc).format("LT");
};

export { getLocalTimeZone, getTimezoneOffset, formatMMSS, getLocalTime, formatL, formatS, formatH, formatLHD };
