import React, { Component } from "react";
import { Modal, Col, Button, Select, DatePicker, Statistic, message } from "antd";
import api from "api";
import moment from "moment";
import AntTable from "components/common/AntTable";
import { formatL } from "../../common/date_time";
import { getSocket } from "common/realtime";

const { RangePicker } = DatePicker;

const { Option } = Select;

export default class CampaignResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      visible: false,
      filter: {
        query: {
          $and: [],
        },
      },
      lstApp: [],
      result: {
        total: 0,
        success: 0,
        fail: 0,
      },
    };

    this.Tb = React.createRef();
  }

  componentDidMount = () => {
    var socket = getSocket("admin_socket");

    socket.on("connect", () => {
      socket.on("reload_table_campaign", () => {
        this.Tb.current.reload();
      });
    });
    this.socket = socket;
  };

  open = (id) => {
    this.getInstalledApp(id);
    var filter = {
      query: {
        $and: [],
      },
    };
    var data = {
      "source.id": id,
    };
    filter.query.$and.push(data);
    this.setState(
      {
        visible: true,
        filter: filter,
        id: id,
      },
      () => this.getResult()
    );
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };

  onFilterChange = (name, value) => {
    var filter = { ...this.state.filter };
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value == "") {
      if (index > -1);
      filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name].$regex = value;
      } else {
        filter.query.$and.push({ [name]: { $regex: value } });
      }
    }
    this.setState({ filter: filter }, () => {
      this.Tb.current.reload();
      this.getResult();
    });
  };

  getColumn = () => {
    var columns = [
      {
        title: "Khách hàng",
        dataIndex: "",
        key: "cusomer_id",
        render: (record) => {
          var customer = record.customer;

          return (
            <>
              <div>
                {" "}
                {customer.first_name} {customer.last_name}
              </div>
              {
                <>
                  <div>{customer.email}</div>
                  <div>{customer.phone}</div>
                </>
              }
            </>
          );
        },
      },

      {
        title: "Kênh gửi",
        dataIndex: "",
        key: "app",
        render: (record) => {
          var app = this.state.lstApp.find((x) => x.id === record.app.id);
          return <div>{app && app.name}</div>;
        },
      },

      {
        title: "Trạng thái",
        dataIndex: "result.status",
        key: "status",
        sorter: true,
        render: (index, record, text) => {
          return <div>{record.result.error ? `${record.result.status} (${record.result.error.message})` : record.result.status}</div>;
        },
      },

      {
        title: "Ngày giờ",
        dataIndex: "create_on",
        key: "create_on",
        sorter: true,
        render: (index, record, text) => {
          var date = moment(record.create_on);
          return <div>{formatL(date)}</div>;
        },
      },
    ];
    return columns;
  };

  handleDatePickerChange = (date) => {
    var filter = this.state.filter;
    for (let i = 0; i < 2; i++) {
      var index = filter.query.$and.findIndex((x) => x["create_on"]);
      if (index > -1) {
        filter.query.$and.splice(index, 1);
      }
    }
    if (date) {
      var tms = date[0].startOf("day").format("X");
      var tms1 = date[1].endOf("day").format("X");
      var date = {
        create_on: {
          $lte: tms1 * 1000,
        },
      };
      var date1 = {
        create_on: {
          $gte: tms * 1000,
        },
      };
      filter.query.$and.push(date);
      filter.query.$and.push(date1);
    }
    this.setState(
      {
        visible: true,
        filter: filter,
      },
      () => {
        this.Tb.current.reload();
        this.getResult();
      }
    );
  };

  getResult() {
    api.post("campaign/getresult", { query: this.state.filter.query }).then((res) => {
      this.setState({
        result: res,
      });
    });
  }

  getInstalledApp(id) {
    api.post("campaign/getappbyid", { id: id }).then((res) => {
      this.setState({
        lstApp: res,
      });
    });
  }

  reSend() {
    api
      .post("campaign/rerunjob", { id: this.state.id })
      .then((res) => {
        if (res) {
          message.success("Chiến dịch đang được chạy lại");
        }
      })
      .catch((err) => {
        message.error("Đã có lỗi xảy ra");
      });
  }

  render() {
    return (
      <>
        <Modal
          width="80%"
          title={<>Kết quả chiến dịch </>}
          open={this.state.visible}
          onCancel={this.close}
          maskClosable={false}
          destroyOnClose={true}
          footer={[
            <Button onClick={() => this.close()} type="primary">
              Thoát
            </Button>,
            <Button onClick={() => this.reSend()} type="primary">
              Gửi lại
            </Button>,
          ]}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginBottom: 20, width: 300 }}>
              <label style={{ paddingLeft: 10 }}>Ngày gửi</label>
              <RangePicker placeholder={["Bắt đầu", "Kết thúc"]} onChange={this.handleDatePickerChange} />
            </div>

            <div style={{ marginBottom: 20, flex: 0.7 }}>
              <label style={{ paddingLeft: 10 }}>Kênh gửi</label>

              <Select style={{ width: "100%" }} onChange={(e) => this.onFilterChange("app.id", e)}>
                <Option value="">Tất cả</Option>
                {this.state.lstApp.map((item) => {
                  return <Option value={item.id}>{item.name}</Option>;
                })}
              </Select>
            </div>
          </div>

          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "31%", marginBottom: 30, textAlign: "center", border: "1px solid #DDDDDD" }}>
                <Col style={{ margin: 10 }}>
                  <Statistic title="Tổng số khách hàng đã gửi" value={this.state.result.total} />
                </Col>
              </div>

              <div style={{ width: "31%", marginBottom: 30, textAlign: "center", border: "1px solid #DDDDDD" }}>
                <Col style={{ margin: 10 }}>
                  <Statistic title="Tổng số khách hàng đã gửi thành công" value={this.state.result.success} />
                </Col>
              </div>

              <div style={{ width: "31%", marginBottom: 30, textAlign: "center", border: "1px solid #DDDDDD" }}>
                <Col style={{ margin: 10 }}>
                  <Statistic title="Tổng số khách hàng đã gửi thất bại" value={this.state.result.fail} />
                </Col>
              </div>
            </div>
          </>

          <>
            <AntTable
              columns={this.getColumn()}
              customSearch={this.state.filter}
              tableName={"tbCampaign"}
              ajax="campaign/gethistory"
              ref={this.Tb}
              rowKey={(record) => record.id}
            ></AntTable>
          </>
        </Modal>
      </>
    );
  }
}
