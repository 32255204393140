import React, { createRef } from "react";
import "./login.css";
import { Row, Col } from "antd";
import { Typography, Space } from "antd";
import { Form, Input, Button, Checkbox, message, Spin } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import api from "api";

const { Title } = Typography;

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      changePassword: false,
      resetPWLoading: false,
    };
    this.form = React.createRef();
  }
  componentDidMount() {
    this.checkOldToken();
  }

  checkOldToken = () => {
    var token = localStorage.getItem("token");
    if (token) {
      api
        .post("/login/checkoldtoken", { token: token })
        .then((res) => {
          // debugger
          return (window.location.href = window.location.origin);
        })
        .catch((err) => {
          // message.error(err.message)
        });
    }
  };

  onFinish = (values) => {
    values.user_name = values.user_name.toLowerCase();
    this.setState({
      loading: true,
    });
    api
      .post("login/index", values)
      .then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("name", res.user.name);
        localStorage.setItem("role", res.user.role);
        localStorage.setItem("user_name", values.user_name);
        localStorage.setItem("shop_name", values.shop);

        //message.error("helloworld");
        window.location.href = "/home";
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error(err.message);
      });
  };

  onFinishFailed = (errorInfo) => {};

  sendIntrucstion = (value) => {
    this.setState({ resetPWLoading: true });
    api
      .post("signup/sendintructionresetpassword", value)
      .then((res) => {
        message.success("Gửi thành công");
        this.setState({ resetPWLoading: false });
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({ resetPWLoading: false });
      });
  };

  forgetPassword = () => {
    this.setState({ changePassword: true });
  };

  strimValue = (name, value) => {
    var data = this.form.current.getFieldsValue();
    data[name] = value.trim();
    this.form.current.setFieldsValue(data);
  };

  shopNameChange = (value) => {
    this.form.current.setFieldsValue({ shop: value.toLowerCase() });
  };

  render() {
    return (
      <>
        <Spin size="large" spinning={this.state.loading} style={{ "margin-top": "30vh" }}>
          <Row>
            <Col
              xs={{ span: 0 }}
              lg={{ span: 12 }}
              sm={{ span: 0 }}
              md={{ span: 12 }}
              style={{ height: "100vh", "background-color": "#E4EBF7" }}
              className="background-left"
            >
              {/* <div style={{ height: "100vh" }}>
                        

                        </div> */}
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}>
                {/* <img src={'/assets/images/07.svg'} /> */}
              </Row>
              <Row justify="center " align="middle" style={{ height: "80vh" }}>
                <img src={"/assets/images/07.svg"} style={{ width: "100%", maxWidth: 500 }} />
              </Row>
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}>
                {/* <img src={'/assets/images/07.svg'} /> */}
              </Row>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Row justify="center" align="middle" style={{ height: "80vh" }}>
                {!this.state.changePassword ? (
                  <div style={{ height: "300px", width: "300px" }}>
                    <Title align="center">Đăng Nhập</Title>
                    <Form
                      ref={this.form}
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValues={{ remember: true }}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      layout="vertical"
                    >
                      <Form.Item label="Tên đăng nhập/ Email" name="user_name" rules={[{ required: true, message: "Nhập vào tên đăng nhập!" }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item label="Mật khẩu" name="password" rules={[{ required: true, message: "Nhập vào mật khẩu!" }]}>
                        <Input.Password />
                      </Form.Item>

                      <Form.Item label="Tên công ty" name="shop" rules={[{ required: true, message: "Nhập vào tên công ty!" }]}>
                        <Input onChange={(e) => this.shopNameChange(e.target.value)} />
                      </Form.Item>

                      <div>
                        <Form.Item style={{ width: "100%" }}>
                          <Button type="primary" style={{ width: "100%" }} htmlType="submit" icon={<PoweroffOutlined />}>
                            Đăng nhập
                          </Button>
                        </Form.Item>
                      </div>

                      <Row style={{ marginBottom: 20, width: "100%", height: 30 }}>
                        <Col span={16}>
                          <Button
                            type="link"
                            onClick={() => {
                              this.forgetPassword();
                            }}
                          >
                            Quên mật khẩu?
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Button type="link" href="/registration">
                            Đăng ký
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ) : (
                  <div style={{ width: "300px" }}>
                    <div>
                      <Title align="center">Thay đổi mật khẩu</Title>
                    </div>
                    <div style={{ marginBottom: 20 }}>Nhập email đã đăng ký tài khoản của bạn, chúng tôi sẽ gửi hướng dẫn thay đổi mật khẩu đến cho bạn.</div>
                    <div style={{ marginBottom: 20 }}>
                      <Form layout="vertical" onFinish={this.sendIntrucstion}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa nhập email!",
                            },
                            {
                              pattern:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                              message: "Email không hợp lệ!",
                            },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" style={{ width: "100%" }} htmlType="submit" loading={this.state.resetPWLoading}>
                            Gửi hướng dẫn
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    {/* <Button
                      style={{ width: "100%" }}
                      type="primary"
                      onClick={() => {
                        this.sendIntrucstion();
                      }}
                    >
                      Gửi hướng dẫn
                    </Button> */}
                    <Button style={{ float: "right" }} type="link" onClick={() => this.setState({ changePassword: false })}>
                      <i className="fas fa-arrow-left"></i>&nbsp; Đăng nhập
                    </Button>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}
