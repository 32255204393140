import React, { Component } from "react";
import TrustSales from "components/app/trustsales/config";
import Email from "components/app/email/config";
import SmsFPT from "components/app/smsfpt/config";
import Esms from "components/app/esms/index";
import DefaultEmail from "components/app/defaultemail/config";
import Facebook from "components/app/fb/config";
import Zalo from "components/app/zalov2";
import Woo from "components/app/woo/menu/index";
import IBCO from "components/app/ibco/config";
import api from "api";
import { Divider, Spin, Card } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import WooConfig from "./woo";
import Haravan from "./haravan";
import Shopee from "./shopee";
import TikTokShop from "./tiktok_shop";
import MShopKeeperConfig from "./mshopkeeper";
import KiotVietSalonConfig from "./kiovietsalon"; 

class AppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isintalled: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    var { appid } = this.props;
    api
      .post("app/checkintalledapp", { id: appid })
      .then((res) => {
        this.setState({ loading: false, isintalled: true });
      })
      .catch((err) => {
        this.setState({ loading: false, isintalled: false });
      });
  };

  render() {
    var app = <> </>;
    var { appid } = this.props;
    switch (appid) {
      case "trustsales":
        app = <TrustSales />;
        break;
      case "email":
        app = <Email />;
        break;
      case "smsfpt":
        app = <SmsFPT />;
        break;
      case "esms":
        app = <Esms />;
        break;
      case "default_email":
        app = <DefaultEmail />;
        break;
      case "fb":
        app = <Facebook />;
        break;
      case "zalo":
        app = <Zalo />;
        break;
      case "shopee":
        app = <Shopee />;
        break;
      case "woo":
        app = <WooConfig />;
        break;
      case "ibco":
        app = <IBCO />;
        break;
      case "haravan":
        app = <Haravan />;
        break;
      case "tiktok":
        app = <TikTokShop />;
        break;
      case "mshopkeeper":
        app = <MShopKeeperConfig />;
        break;
      case "kiotvietsalon":
          app = <KiotVietSalonConfig />;
          break;
      default:
        break;
    }
    if (this.state.loading) {
      return (
        <div style={{ width: "100vw", height: "100vh", display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <Spin size="large"></Spin>
          </div>
        </div>
      );
    } else {
      if (this.state.isintalled) {
        return <>{app}</>;
      } else
        return (
          <Card style={{ width: "100%" }}>
            <h2>
              App chưa được cài đặt. Vui lòng cái đặt app <a href="/apps">tại đây</a>
            </h2>
          </Card>
        );
    }
  }
}

const AppConfigFn = () => {
  const params = useParams();
  return <AppConfig appid={params.id}></AppConfig>;
};

export default AppConfigFn;
