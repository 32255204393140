import { Button, Card, Col, Form, Image, Input, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import api from "api";

const { Text } = Typography;

function ModalInfoZalo({ isModalOpen, setIsModalOpen, shop, refTable }) {
  const [form] = Form.useForm();
  const [infoZalo, setInfoZalo] = useState({});

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setInfoZalo({});
    if (Object.keys(shop).length > 0) {
      getInfoZalo(shop.id);
    }
  }, [shop]);

  const getInfoZalo = async (shopID) => {
    await api
      .post("master/shop/getinfozalo", { shop_id: shopID })
      .then((res) => {
        // console.log("master/shop/getinfozalo", res);
        if (res.configs) {
          setInfoZalo(res.configs);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title="Thông tin Zalo"
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      width="48vw"
      footer={[<Button onClick={handleCancel}>Đóng</Button>]}
    >
      <Text>Hình ảnh logo thương hiệu trên OA (400 x 96), nền trong suốt</Text>
      <Row gutter={[16, 16]}>
        <Col span={12} align="center">
          {infoZalo.logolight ? (
            <>
              <Image
                width="100%"
                style={{ borderStyle: "dashed", borderColor: "#D9D9D9", borderWidth: 1, borderRadius: 2 }}
                // src={'https://storage.googleapis.com/cubeloyalty_asia/web/e127c007-4a20-4249-b575-88a49c15f5d4.png'}
                src={infoZalo.logolight}
                preview={false}
              />
              <Button type="link" target="_blank" href={infoZalo.logolight}>
                Tải xuống
              </Button>
            </>
          ) : (
            <Card>
              <Text>Không có hình</Text>
            </Card>
          )}
        </Col>
        <Col span={12} align="center">
          {infoZalo.logodard ? (
            <>
              <Image
                width="100%"
                style={{ borderStyle: "dashed", borderColor: "#D9D9D9", borderWidth: 1, borderRadius: 2 }}
                src={infoZalo.logodard}
                preview={false}
              />
              <Button type="link" target="_blank" href={infoZalo.logodard} download>
                Tải xuống
              </Button>
            </>
          ) : (
            <Card>
              <Text>Không có hình</Text>
            </Card>
          )}
        </Col>
      </Row>
      <Form form={form} layout="horizontal">
        <Form.Item label="Số điện thoại">{infoZalo.phone && <Text copyable>{infoZalo.phone}</Text>}</Form.Item>
        <Form.Item label="Website">{infoZalo.address && <Text copyable>{infoZalo.address}</Text>}</Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalInfoZalo;
