import React, { Component } from "react";
import api from "api";
import { Card, Button, Skeleton, Empty, Row, Col, Popconfirm, message } from "antd";
import PopupSetting from "./popupsetting";
import SyncPopup from "./sync_popup";
const { Meta } = Card;

export default class WooAppConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      woo: [],
      showBtn: false,
      loading: true,
    };
    this.PopupSetting = React.createRef();
    this.SyncPopup = React.createRef();
  }

  componentDidMount = () => {
    this.getConfig();
  };
  getConfig = () => {
    api.post("app/woo/config/getconfig", { shop_id: 0 }).then((res) => {
      if (res != null) {
        this.setState({ woo: res, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };

  getData = () => {
    api.get("app/woo/config/getconfig").then((data) => {
      if (data) this.setState({ woo: data.woo });
    });
  };

  delete = (id) => {
    api
      .post("app/woo/config/deleteconfig", { id: id })
      .then((data) => {
        message.success("Xóa thành công");
        this.getConfig();
      })
      .catch((err) => {
        message.error("Xóa thất bại");
      });
  };

  renderItem = (item) => {
    return (
      <div style={{ marginRight: "10px", height: 320 }}>
        <Card
          className={"sms-fpt-card-item"}
          bordered={true}
          cover={<img height="auto" src={item.img} />}
          actions={[
            <div
              onClick={() => {
                this.SyncPopup.current.open(item._id);
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </div>,
            // <div
            //   onClick={() => {
            //     this.PopupSetting.current.open("edit", item);
            //   }}
            // >
            //   <i className="far fa-edit"></i>
            // </div>,
            <Popconfirm
              title="Bạn có chắc muốn xóa không?"
              onConfirm={() => {
                this.delete(item._id);
              }}
              okText="Có"
              cancelText="Không"
            >
              ,
              <div style={{ marginTop: -22 }}>
                <i className="far fa-trash-alt"></i>
              </div>
            </Popconfirm>,
          ]}
        >
          <Meta title="WooCommerce" description={item.url} />
        </Card>
      </div>
    );
  };
  render() {
    var woo = this.state.woo;
    return (
      <>
        <Card
          size="small"
          title={<span style={{ fontWeight: "normal", fontSize: "14px" }}>Các trang woocommerce đã kết nối</span>}
          bordered={false}
          style={{ border: "1px solid #dfdfdf" }}
          extra={
            <Button type="primary" onClick={() => this.PopupSetting.current.open("add", {})}>
              Thêm mới
            </Button>
          }
        >
          {this.state.loading ? (
            <Skeleton />
          ) : !woo || woo.length === 0 ? (
            <div style={{ marginTop: "13vh" }}>
              <Empty description="Hãy thêm trang đầu tiên"></Empty>
              <Row justify="center" style={{ marginBottom: "15vh" }}>
                <Button onClick={() => this.PopupSetting.current.open("add", {})} style={{ marginTop: 20 }} type="primary">
                  <i className="fas fa-plus"></i>&nbsp;Thêm mới
                </Button>
              </Row>
            </div>
          ) : (
            <Row>
              {woo.map((item) => {
                return (
                  <Col xl={5} lg={5} md={6}>
                    {this.renderItem(item)}
                  </Col>
                );
              })}
            </Row>
          )}
          <PopupSetting ref={this.PopupSetting} data={this.state.data} getConfig={this.getConfig}></PopupSetting>
          <SyncPopup ref={this.SyncPopup}></SyncPopup>
        </Card>
      </>
    );
  }
}
