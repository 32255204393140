import React, { Component } from "react";
import { Input, message, Modal, Form, Upload, DatePicker, Col, Row } from "antd";
import { Tabs } from "antd";
import AddNewEdit from ".";
import { PlusOutlined } from "@ant-design/icons";
import AntTable from "components/common/AntTable";
import moment from "moment";
import api from "api";
import { renderInput } from "common/custom_field.js";

const { Search } = Input;
const { TabPane } = Tabs;

export default class CustomerInfoPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      data: {
        name: "Nguyễn Văn Công",
        email: "nvc@mvp.com",
        phone: "0915858582",
        birthday: "08/08/2008",
      },
      system_config: [
        {
          type: "text",
          label: "cột 2",
          id: "col_ywyhm",
          attribute: "col_2",
        },
      ],
    };
    this.tb = React.createRef;
    this.popupAddEdit = React.createRef();
  }

  componentDidMount = () => {};

  getCustomerConfig = () => {
    if (this.state.visible == true) {
      var dataPost = {
        query: { key: "field" },
      };
      api
        .get("customerconfig/getconfig", dataPost)
        .then((res) => {
          if (res.length > 0) {
            this.setState({ system_config: res });
          }
        })
        .catch((err) => {});
    }
  };

  open = (id) => {
    api
      .post("customer/get_by_id", { id: id })
      .then((resp) => {
        if (resp != null) {
          if (resp.birthday != null) resp.birthday = moment(resp.birthday).format("DD-MM-YYYY");
          this.setState(
            {
              data: resp,
              visible: true,
              isLoading: true,
            },
            () => this.getCustomerConfig()
          );
        }
      })
      .catch((error) => {
        message.error("Có lỗi xảy ra");
        return null;
      });
  };

  close = () => {
    this.setState({ visible: false });
  };

  renderAttributes = () => {
    var customer = this.state.data;
    return this.state.system_config.map((config) => (
      <p>
        {config.label} : {customer[config.attribute]}
      </p>
    ));
  };

  render() {
    var customer = this.state.data;
    return (
      <>
        <Modal
          title={"Hồ sơ khách hàng"}
          open={this.state.visible}
          // onOk={this.handleOk}
          onCancel={this.close}
          okText={"Chỉnh sửa"}
          cancelText={"Huỷ"}
          onOk={() => this.popupAddEdit.current.open(this.state.data.id)}
          // footer={this.props.footer}
          width={"40%"}
        >
          <Tabs defaultActiveKey="customer-add-tab-1">
            <TabPane tab="Thông tin cơ bản" key="customer-add-tab-1">
              <Row>
                <Col span={18}>
                  <p>Họ và tên: {customer.name}</p>
                  <p>Email: {customer.email}</p>
                  <p>Số điện thoại: {customer.phone}</p>
                  <p>Ngày sinh: {customer.birthday}</p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Liên hệ" key="customer-add-tab-2">
              <p>Facebook: {customer.facebook_id}</p>
              <p>Zalo: {customer.zalo}</p>
            </TabPane>
            <TabPane tab="Tuỳ chỉnh" key="customer-add-tab-3">
              {this.renderAttributes()}
            </TabPane>
          </Tabs>

          {/* <p>Tag: <Tag>Plus</Tag> <Tag>Plus+</Tag> <Tag>Premium</Tag> </p> */}
        </Modal>
        <AddNewEdit ref={this.popupAddEdit} getData={(id) => this.open(id)}></AddNewEdit>
      </>
    );
  }
}
