import React from "react";
import { Modal, Button, message } from "antd";
import { Form, Input, Checkbox, Select, InputNumber } from "antd";
import PopupQrPrint from "./popup_print";
import api from "api";
import MyInputNumber from "components/picker/inputnumber_picker";

export default class PopupQr extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false, type: "order", id: "" };
    this.form = React.createRef();
    this.PopupQrPrint = React.createRef();
  }

  open = (id) => {
    if (id) {
      api.get("pointcode/get", { params: { id: id } }).then((data) => {
        this.setState({ type: data.type, isVisible: true, id: id }, (e) => {
          this.form.current.setFieldsValue(data);
        });
      });
    } else {
      this.setState({ isVisible: true, id: "", type: "order" }, (e) => {
        this.form.current.resetFields();
      });
    }
  };

  close = (e) => {
    this.setState({ isVisible: false });
  };

  onValuesChange = (values) => {
    if (values.type) {
      this.setState({ type: values.type });
    }
  };

  onFinish = (values) => {
    api
      .post("pointcode/save", values)
      .then((data) => {
        this.form.current.setFieldsValue(data);
        this.setState({
          id: data.id,
          isVisible: false,
        });
        message.success("Mã Qr của bạn đã được lưu thành công");
        if (this.props.onSaved) {
          this.props.onSaved(data);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  onSunmit = () => {
    this.form.current.submit();
  };

  render() {
    return (
      <>
        <Modal
          title="Mã tích điểm"
          open={this.state.isVisible}
          onOk={this.close}
          onCancel={this.close}
          footer={[
            <Button onClick={this.close}>Đóng</Button>,
            this.state.id != "" ? (
              <Button
                type="primary"
                onClick={(e) => {
                  this.PopupQrPrint.current.open(this.state.id);
                }}
              >
                QR
              </Button>
            ) : null,
            <Button
              type="primary"
              onClick={() => {
                this.onSunmit();
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form
            name="basic"
            initialValues={{ type: "order" }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
            layout="vertical"
            ref={this.form}
          >
            <Form.Item name="id" hidden>
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Kiểu" name="type" rules={[{ required: true, message: "Vui lòng chọn kiểu!" }]}>
              <Select>
                <Select.Option value="order">Đơn hàng</Select.Option>
                <Select.Option value="product">Sản phẩm</Select.Option>
                <Select.Option value="point">Điểm</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Mã đơn hàng" name="order_id" rules={this.state.type == "order" ? [{ required: true, message: "Vui lòng nhập mã đơn hàng!" }] : []} hidden={this.state.type != "order"}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Mã sản phẩm"
              name="product_id"
              rules={this.state.type == "product" ? [{ required: true, message: "Vui lòng nhập mã sản phẩm!" }] : []}
              hidden={this.state.type != "product"}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tổng tiền"
              name="total_amount"
              rules={
                this.state.type == "order" || this.state.type == "product"
                  ? [
                      {
                        required: true,
                        message: "Bạn chưa nhập tổng tiền!",
                      },
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (value > 0) {
                              resolve();
                            } else {
                              error();
                            }
                          });
                        },
                        message: "Không được nhập số âm!",
                      },
                    ]
                  : []
              }
              hidden={this.state.type != "order" && this.state.type != "product"}
            >
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>

            <Form.Item
              label="Số điểm"
              name="point"
              rules={
                this.state.type == "point"
                  ? [
                      {
                        required: true,
                        message: "Bạn chưa nhập số điểm!",
                      },
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (value > 0) {
                              resolve();
                            } else {
                              error();
                            }
                          });
                        },
                        message: "Không được nhập số âm!",
                      },
                    ]
                  : []
              }
              hidden={this.state.type != "point"}
            >
              <MyInputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>

            <Form.Item
              label="Ghi chú"
              name="note"
              rules={[
                {
                  validator: (rule, value) => {
                    return new Promise((resolve, error) => {
                      if (value.length < 1000) {
                        resolve();
                      } else {
                        error();
                      }
                    });
                  },
                  message: "Không nhập quá 1000 kí tự!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <PopupQrPrint ref={this.PopupQrPrint} />
      </>
    );
  }
}
