import React, { Component } from "react";
import { Modal, Row, Col, Divider, Table, Button, Upload, Steps, Alert, Form, Select, Card, message, Result, Progress } from "antd";
import { renderValue, renderInput, dataTypes, makeid } from "common/custom_field";
import * as XLSX from "xlsx";
import api from "api";
import moment from "moment";
import { io } from "socket.io-client";
import { wss_url } from "config";
import { getSocket } from "common/realtime";
import CustomerFieldPicker from "./customer_field_picker";
import excelCustomerExample from "./excelCustomerExample.xlsx";
import { Link } from "react-router-dom";

const { Dragger } = Upload;
// npm  i xlsx

const { confirm } = Modal;
const { Step } = Steps;

export default class PopupImportExcel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      current: 0,
      isLoading: false,
      lstError: [],
      dataExcel: [],
      files: [],
      lstHeader: [],
      mapData: {},
      lstConfigCus: [],
      data: [],
      dataExcel: [],
      imported: false,
      fileName: "",
      success: null,
      total: 0,
      loadingBtn: false,
      lstProvince: [],
      lstColValidate: ["province_id", "gender", "phone", "email"],
      lstFail: [],
      lstFieldRequire: [],
      currentimport: 0,
      percentimport: 0,
      itemsSuccess: 0,
      itemsFail: 0,
      itemsTotal: 0,
      key: "false",
    };
    this.mapData = React.createRef();
    this.lstAutoMap = [
      {
        value: "phone",
        labels: ["SỐ ĐIỆN THOẠI", "SDT", "SĐT", "PHONE", "PHONENUMBER"],
      },
      {
        value: "first_name",
        labels: ["TÊN KHÁCH HÀNG", "TÊN", "NAME", "FIRSTNAME", "FIRST NAME", "FIRST_NAME"],
      },
      {
        value: "last_name",
        labels: ["HỌ KHÁCH HÀNG", "HỌ", "LASTNAME", "LAST NAME", "LAST_NAME"],
      },
      {
        value: "email",
        labels: ["EMAIL"],
      },
      {
        value: "birth_day",
        labels: ["NGÀY SINH", "BIRTHDAY", "BIRTH_DAY", "BIRTH DAY"],
      },
      // {
      //   value: "address,address1",
      //   labels: ["ĐỊA CHỈ", "ĐC", "ADDRESS"],
      // },
      {
        value: "gender",
        labels: ["GIỚI TÍNH", "PHÁI", "GT", "GENDER", "SEX"],
      },
    ];
  }

  componentDidMount = () => {
    var socket = getSocket("admin_socket");

    socket.on("connect", () => {
      socket.on("import_excel_customer", (data) => {
        if (data.key === this.state.key) {
          if (data.status === "error") {
            this.setState({ loadingBtn: false, success: false });
            return;
          }
          var total = this.state.itemsTotal;
          var success = this.state.itemsSuccess;
          var fail = this.state.itemsFail;
          total++;
          if (data.status === "success") {
            success++;
          }
          if (data.status === "fail") {
            fail++;
          }
          console.log("data", total, data);
          if (total == this.state.data.length) {
            this.setState({ itemsTotal: total, itemsFail: fail, itemsSuccess: success, success: true });
          } else {
            this.setState({ itemsTotal: total, itemsFail: fail, itemsSuccess: success });
          }
        }
      });
    });
    this.socket = socket;
  };

  getAllConfig = () => {
    api.post("/customerconfig/listexcel").then((res) => {
      var index = res.findIndex((x) => x.attribute === "id");
      res.splice(index, 1);
      var lstFieldRequire = res.filter((x) => x.isrequire == true);
      this.setState({ lstConfigCus: res, lstFieldRequire: lstFieldRequire });
    });
  };

  getProvince = () => {
    api.post("/mapprovince/getprovince").then((res) => {
      this.setState({ lstProvince: res });
    });
  };

  open = () => {
    this.setState({ visible: true, isLoading: true, itemsTotal: 0, itemsSuccess: 0, itemsFail: 0, key: "", success: null }, async () => {
      await this.getAllConfig();
      await this.getProvince();
    });
  };

  close = () => {
    this.setState({ visible: false, isLoading: false, imported: false, current: 0 }, () => {
      this.mapData.current.resetFields();
      this.props.reload();
    });
  };

  next = () => {
    this.setState({ current: this.state.current + 1 });
  };

  prev = () => {
    this.setState({ current: this.state.current - 1 });
  };

  dragChange = (info) => {
    var f = info.file.originFileObj;
    if (!f) return;
    var reader = new FileReader();
    reader.onload = function (evt) {
      var data = evt.target.result;

      var workbook = XLSX.read(data, { type: "array" });

      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var headerData = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
      var sheetData = XLSX.utils.sheet_to_json(first_worksheet, { header: 0 });
      if (!sheetData) sheetData = [];
      var lstheader = [];
      for (var i = 0; i <= headerData[0].length; i++) {
        if (headerData[0][i] == null) {
          break;
        }
        lstheader.push(headerData[0][i]);
      }
      this.setState({ lstHeader: lstheader, dataExcel: sheetData, imported: true, fileName: info.file.name, total: sheetData.length });
    }.bind(this);
    reader.readAsArrayBuffer(f);
  };

  beforeUpload = (e) => {
    var lst = e.name.split(".");
    var tail = lst[lst.length - 1];
    var lstAllow = "xlsx";
    tail = tail.toLocaleLowerCase();
    if (lstAllow != tail) {
      message.error("Vui lòng upload file đuôi xlsx");
      return false;
    }
    return true;
  };

  autoMap = () => {
    var data = {};
    this.state.lstHeader.forEach((item) => {
      this.lstAutoMap.forEach((element) => {
        element.labels.forEach((label) => {
          if (item.toUpperCase() === label) {
            data[item] = element.value;
          }
        });
      });
    });
    this.mapData.current.setFieldsValue(data);
    this.next();
  };

  checkMapFieldRequire = (value) => {
    var result = Object.keys(value).map((key) => value[key]);
    var check = {
      status: true,
      message: "Các trường cần map:",
    };
    this.state.lstFieldRequire.forEach((item) => {
      var index = result.findIndex((x) => x.attribute === item.attribute);
      if (index === -1) {
        var mes = check.message;
        if (check.status) mes = mes + " " + item.label;
        else mes = mes + ", " + item.label;
        check = {
          status: false,
          message: mes,
        };
      }
    });
    return check;
  };

  reloadOption = (data) => {
    var lst = this.state.lstConfigCus;
    lst.push(data);
    this.setState({ lstConfigCus: lst });
  };

  saveMap = (value) => {
    var keyNames = Object.keys(value);
    keyNames.forEach((item) => {
      var key = value[item].split(",");
      if (key.length === 2) {
        var config = this.state.lstConfigCus.find((x) => x.attribute == key[0]);
        var child = config.child.find((x) => x.attribute === key[1]);
        value[item] = {
          parent: config.attribute,
          data: child,
        };
      } else {
        var index = this.state.lstConfigCus.findIndex((x) => x.attribute == value[item]);
        if (index > -1) value[item] = this.state.lstConfigCus[index];
      }
    });
    var checkSDTEmail = this.checkMapSDTOrEmail(value);
    if (checkSDTEmail) {
      var checkRequire = this.checkMapFieldRequire(value);
      if (checkRequire.status) {
        var checkMapDup = this.checkMapDup(value);
        if (checkMapDup.length === 0) {
          this.setState({ mapData: value }, () => {
            this.mapListData();
          });
        } else {
          var text = checkMapDup.toString().replaceAll(",", ", ");
          message.error("Các cột map dữ liệu bị trùng: " + text, 5);
        }
      } else {
        message.error(checkRequire.message, 5);
      }
    } else {
      message.error("Dữ liệu đưa vào phải có cột số điện thoại và email", 5);
    }
  };

  checkMapSDTOrEmail = (value) => {
    var result = Object.keys(value).map((key) => value[key]);
    var phone = result.findIndex((x) => x.attribute === "phone");
    var email = result.findIndex((x) => x.attribute === "email");
    if (phone == -1 && email == -1) {
      return false;
    }
    return true;
  };

  checkMapDup = (value) => {
    var result = Object.keys(value).map((key) => [key, value[key]]);
    var lst = [];
    result.forEach((item, index) => {
      var ind = result.findIndex((x) => JSON.stringify(x[1]) === JSON.stringify(item[1]) && x[0] !== item[0]);
      if (ind > -1 && ind !== index) lst.push(item[0]);
    });
    return lst;
  };

  timeConverter = (date) => {
    if (date % 1 === 0) {
      var data = new Date(Math.round((date - 25569) * 86400 * 1000));
      var utc = moment(data);
    } else {
      var utc = moment(date, "DD/MM/YYYY");
    }
    return utc;
  };

  mapListData = () => {
    var data = this.state.dataExcel;
    var map = this.state.mapData;
    var datamap = [];
    if (data.length > 0) {
      var keyNames = Object.keys(map).map((key) => [key]);
      datamap = data.map((item) => {
        var obj = {};

        keyNames.forEach((name) => {
          if (map[name].parent) {
            if (!obj[map[name].parent]) {
              obj[map[name].parent] = {};
            }

            obj[map[name].parent][map[name].data.attribute] = item[name];
          } else {
            obj[map[name].attribute] = item[name];
          }
        });
        obj.__rowNum__ = item.__rowNum__;
        return obj;
      });
      this.setState({ data: datamap }, () => {
        this.checkValidate();
        this.next();
      });
    } else {
      this.next();
    }
  };

  validateRequire = (fulldata, data, name) => {
    if (!data) {
      return {
        status: false,
        data: {
          row: fulldata.__rowNum__,
          error: name + "Chưa có dữ liệu",
        },
      };
    } else {
      return {
        status: true,
      };
    }
  };

  checkValidate = () => {
    var data = this.state.data;
    var map = this.state.mapData;
    var result = Object.keys(map).map((key) => [key, map[key]]);
    var lstErr = [];
    data.forEach((item, index) => {
      result.forEach((element) => {
        if (!element[1].parent) {
          if (element[1].type === "date" || element[1].type === "datetime") {
            item[element[1].attribute] = this.timeConverter(item[element[1].attribute]);
          }
          if (element[1].type === "dropdown" || element[1].type === "check" || element[1].type === "multiple") {
            var check = this.checkDataOption(item, item[element[1].attribute], element[1].options, element[1].label);
            if (!check.status) {
              lstErr.push(check.data);
            }
          }
          var index = this.state.lstColValidate.findIndex((x) => x === element[1].attribute);
          if (index > -1) {
            var func = "validate" + element[1].attribute;
            var res = this[func](item, item[element[1].attribute]);
            if (res.status) {
              item[element[1].attribute] = res.data;
            } else {
              lstErr.push(res.data);
            }
          }
          if (element[1].isrequire) {
            var check = this.validateRequire(item, item[element[1].attribute], element[1].label);
            if (!check.status) {
              lstErr.push(check.data);
            }
          }
        } else {
          if (element[1].data.type === "date" || element[1].data.type === "datetime") {
            item[element[1].data.attribute] = this.timeConverter(item[element[1].data.attribute]);
          }
          if (element[1].data.type === "dropdown" || element[1].data.type === "check" || element[1].data.type === "multiple") {
            var check = this.checkDataOption(item, item[element[1].data.attribute], element[1].data.options, element[1].data.label);
            if (!check.status) {
              lstErr.push(check.data);
            }
          }
          var index = this.state.lstColValidate.findIndex((x) => x === element[1].data.attribute);
          if (index > -1) {
            var func = "validate" + element[1].data.attribute;
            var res = this[func](item, item[element[1].data.attribute]);
            if (res.status) {
              item[element[1].data.attribute] = res.data;
            } else {
              lstErr.push(res.data);
            }
          }
          if (element[1].data.isrequire) {
            var check = this.validateRequire(item, item[element[1].data.attribute], element[1].data.label);
            if (!check.status) {
              lstErr.push(check.data);
            }
          }
        }
      });
      var checkdup = this.checkDuplicate(item, index);
      if (!checkdup.status) {
        lstErr.push(checkdup.data);
      }
    });
    this.setState({ lstError: lstErr });
  };

  validateprovince_id = (fulldata, data) => {
    if (data == null || data === "")
      return {
        status: true,
        data: data,
      };

    var index = this.state.lstProvince.findIndex((x) => x.state_name.toUpperCase() === data.toUpperCase());
    if (index > -1) {
      return {
        status: true,
        data: this.state.lstProvince[index].value,
      };
    }
    return {
      status: false,
      data: {
        row: fulldata.__rowNum__,
        error: "Không tìm thấy tỉnh trong cơ sở dữ liệu",
      },
    };
  };

  validategender = (fulldata, data) => {
    if (data == null || data === "")
      return {
        status: true,
        data: data,
      };

    var nam = ["nam", "male"];
    var nu = ["nu", "female", "nữ"];
    var i1 = nam.findIndex((x) => x.toUpperCase() === data.toUpperCase());
    var i2 = nu.findIndex((x) => x.toUpperCase() === data.toUpperCase());
    if (i1 === -1 && i2 === -1) {
      return {
        status: false,
        data: {
          row: fulldata.__rowNum__,
          error: "Giới tính không hợp lệ",
        },
      };
    } else {
      if (i1 > -1) {
        return {
          status: true,
          data: "male",
        };
      }
      if (i2 > -1) {
        return {
          status: true,
          data: "female",
        };
      }
    }
  };

  validatephone = (fulldata, data) => {
    if (!data) {
      return {
        status: false,
        data: {
          row: fulldata.__rowNum__,
          error: "Chưa có số điện thoại",
        },
      };
    }
    var filter = /^\+?(?:[0-9]●?){2,14}[0-9]$/g;
    var check = filter.test(data);
    if (check) {
      return {
        status: true,
        data: data,
      };
    }

    return {
      status: false,
      data: {
        row: fulldata.__rowNum__,
        error: "Số điện thoại không hợp lệ",
      },
    };
  };

  validateemail = (fulldata, data) => {
    if (data == null || data === "")
      return {
        status: true,
        data: data,
      };
    var filter = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    var check = filter.test(data);
    if (check) {
      return {
        status: true,
        data: data,
      };
    }
    return {
      status: false,
      data: {
        row: fulldata.__rowNum__,
        error: "Email không hợp lệ",
      },
    };
  };

  checkDataOption = (fulldata, data, lst, name) => {
    if (data == null || data === "")
      return {
        status: true,
        data: data,
      };

    var index = lst.findIndex((x) => x.label == data);
    if (index > -1) {
      return {
        status: true,
        data: lst[index].value,
      };
    }
    var err = "Dữ liệu " + name + " không khớp với khai báo";
    return {
      status: false,
      data: {
        row: fulldata.__rowNum__,
        error: err,
      },
    };
  };

  checkDuplicate(data, i) {
    var lstErr = [];
    if (data.phone) {
      lstErr = this.state.data.filter((x) => x.phone === data.phone);
      if (lstErr.length > 1) {
        lstErr = lstErr.map((x) => {
          return x.__rowNum__;
        });
        var err = `Trùng số điện thoại ${data.phone} với dòng ` + lstErr.join();
        return {
          status: false,
          data: {
            row: data.__rowNum__,
            error: err,
          },
        };
      }
    }
    if (data.email) {
      lstErr = this.state.data.filter((x) => x.email === data.email);
      if (lstErr.length > 1) {
        lstErr = lstErr.map((x) => {
          return x.__rowNum__;
        });
        var err = `Trùng số email ${data.email} với dòng ` + lstErr.join();
        return {
          status: false,
          data: {
            row: data.__rowNum__,
            error: err,
          },
        };
      }
    }
    return { status: true };
  }

  saveDataExcel = async () => {
    var data = this.state.data;
    var length = data.length;
    var key = makeid(7);
    this.setState({ key: key });
    if (length > 1000) {
      var du = length % 1000;
      var n = (length - du) / 1000;
      if (du > 0) n++;
      var lstCut = [];
      for (let index = 0; index < n * 1000; index = index + 1000) {
        lstCut.push(data.slice(index, index + 1000));
      }
      for (var i = 0; i < lstCut.length; i++) {
        api.post("/customer/importdata", { data: lstCut[i], id: this.socket.id, key: key });
      }
    } else {
      api.post("/customer/importdata", { data: data, id: this.socket.id, key: key });
    }
    this.setState({ current: 3, loadingBtn: false });
  };

  getIndexNullPhone(lstData) {
    return lstData
      .map((item, index) => {
        if (item.SDT == "" || item.SDT == null) return index;
        return -1;
      })
      .filter((item) => item != -1);
  }

  renderErrorList = () => {
    var lst = this.state.lstError;
    var columns = [
      {
        title: "Dòng",
        dataIndex: "row",
        key: "row",
      },
      {
        title: "Lỗi",
        dataIndex: "error",
        key: "error",
      },
    ];
    return (
      <>
        {lst.length > 0 && (
          <div style={{ borderTop: "3px solid #fc0000" }}>
            <Table dataSource={lst} columns={columns} />
          </div>
        )}
      </>
    );
  };

  renderDataCustomer = (lst) => {
    var map = this.state.mapData;
    var result = Object.keys(map).map((key) => map[key]);
    var pagination = {
      showTotal: () => {
        return <div style={{ marginRight: 30 }}>Tổng cộng: {this.state.total} khách hàng</div>;
      },
    };

    var columns = result.map((item) => {
      return {
        title: item.data ? item.data.label : item.label,
        dataIndex: "",
        key: item.data ? item.data.attribute : item.attribute,
        render: (text, record, index) => {
          return <div>{renderValue(item, record)}</div>;
        },
      };
    });
    return (
      <>
        <>
          <Table dataSource={lst} columns={columns} pagination={pagination} />
        </>
      </>
    );
  };

  render() {
    var check = true;

    var steps = [
      {
        title: "Tải file",
      },
      {
        title: "Map dữ liệu",
      },
      {
        title: "Dữ liệu được ghi nhận",
      },
      {
        title: "Hoàn tất",
      },
    ];

    return (
      <>
        <Modal
          title={<>Nhập Excel</>}
          open={this.state.visible}
          width="80%"
          onOk={this.showConfirm}
          onCancel={this.close}
          okButtonProps={{ disabled: check }}
          okText="Lưu dữ liệu"
          bodyStyle={{ minHeight: 500 }}
          maskClosable={false}
          footer={[
            <div>
              {this.state.current === 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    this.close();
                  }}
                >
                  Thoát
                </Button>
              )}
              {this.state.current > 0 && this.state.current !== steps.length - 1 && (
                <Button style={{ margin: "0 8px" }} onClick={() => this.prev()}>
                  Trở lại
                </Button>
              )}
              {this.state.current === 0 && (
                <Button type="primary" onClick={() => this.autoMap()} disabled={this.state.imported ? false : true}>
                  Tiếp tục
                </Button>
              )}
              {this.state.current === 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.mapData.current.submit();
                  }}
                >
                  Tiếp tục
                </Button>
              )}
              {this.state.current === 2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ loadingBtn: true }, () => this.saveDataExcel());
                  }}
                  disabled={this.state.dataExcel.length > 0 && this.state.lstError.length === 0 ? false : true}
                  loading={this.state.loadingBtn ? true : false}
                >
                  Tiếp tục
                </Button>
              )}
              {this.state.current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.close();
                  }}
                >
                  Thoát
                </Button>
              )}
            </div>,
          ]}
        >
          {this.state.isLoading && (
            <>
              <div>
                <Steps current={this.state.current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>

                <div hidden={this.state.current === 0 ? false : true} style={{ marginTop: 30 }}>
                  <Card>
                    <Dragger
                      name="file"
                      multiple={false}
                      onChange={(info) => this.dragChange(info)}
                      showUploadList={false}
                      accept=".xlsx"
                      action="#"
                      beforeUpload={(e) => this.beforeUpload(e)}
                    >
                      <p style={{ fontSize: 50, color: "#1890FF" }}>
                        <i className="fa fa-upload"></i>
                      </p>
                      <p>
                        Nhấp hoặc kéo tệp vào khu vực này để tải lên, file hướng dẫn cho người mới tại
                        <Link to={excelCustomerExample} download="excelCustomerExample" target="_blank" rel="noreferrer">
                          {" "}
                          đây
                        </Link>
                      </p>
                      <p>Hỗ trợ tải lên một lần hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu công ty hoặc các tệp khác</p>
                    </Dragger>
                    <div>
                      {this.state.imported && (
                        <div style={{ marginTop: 25 }}>
                          <Alert
                            message="Import thành công"
                            description={<span>Bạn đã import file: {this.state.fileName} thành công, hãy bấm tiếp tục để thực hiện bước tiếp theo.</span>}
                            type="success"
                            showIcon
                          />
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
                <div hidden={this.state.current === 1 ? false : true} style={{ marginTop: 30 }}>
                  <Card>
                    <Form name="mapdata" onFinishFailed={this.onFinishFailed} ref={this.mapData} layout="vertical" onFinish={this.saveMap}>
                      <div style={{ marginTop: 20 }}>
                        <Row>
                          {this.state.lstHeader.map((item) => {
                            return (
                              <>
                                <Col offset={2} span={2}>
                                  <label style={{ lineHeight: "32px", fontWeight: "bold" }}>{item} </label>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name={item}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Bạn chưa map trường này!",
                                      },
                                    ]}
                                  >
                                    <CustomerFieldPicker listField={this.state.lstConfigCus}></CustomerFieldPicker>
                                  </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </Form>
                  </Card>
                </div>
              </div>

              <div hidden={this.state.current === 2 ? false : true} style={{ marginTop: 30 }}>
                {this.state.current === 2 && (this.state.lstError.length === 0 ? this.renderDataCustomer(this.state.data) : this.renderErrorList())}
              </div>
              <div hidden={this.state.current === 3 ? false : true} style={{ marginTop: 30, display: "flex" }}>
                {this.state.success === null && (
                  <div style={{ margin: "auto", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ margin: "auto" }}>
                        <h2>Tiến độ import</h2>
                      </span>
                    </div>
                    <Progress percent={Math.floor((this.state.itemsTotal / this.state.total) * 100)} size="small" />
                  </div>
                )}
                {this.state.success == true && (
                  <Result
                    status="success"
                    title={<div>Import thành công {this.state.itemsSuccess + "/" + this.state.total} khách hàng!</div>}
                    style={{ width: "100%" }}
                  />
                )}
                {this.state.success == false && (
                  <>
                    <Result status="error" title="Nhập khách hàng không thành công!" style={{ width: "100%" }} />
                    {this.state.lstFail.length > 0 && this.renderDataCustomer(this.state.lstFail)}
                  </>
                )}
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}
