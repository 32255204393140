import { Button, Checkbox, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from "antd";
import React, { useState } from "react";
const { Text } = Typography;
const { TextArea } = Input;
function AddTurnModal({ isModalOpen, setIsModalOpen, value, onChange }) {
  const [form] = Form.useForm();
  const [type, setType] = useState("point");
  const handleOk = () => {
    form
      .validateFields()
      .then((res) => {
        var newItem = form.getFieldsValue();
        onChange(newItem);

        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal width={576} title="Cấu hình lượt" open={isModalOpen} okText="Lưu" cancelText="Thoát" onOk={handleOk} onCancel={handleCancel}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ type: "point", points: 100, turns: 1, max_change_times: 1 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Phương thức"
          name="type"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn 1 phương thức",
            },
          ]}
        >
          <Select
            onChange={(e) => {
              setType(e);
            }}
            options={[
              { value: "point", label: "Đổi điểm nhận lượt" },
              { value: "subscribezalo", label: "Quan tâm Zalo nhận lượt" },
              { value: "share_to_chat", label: "Chia sẻ bài viết zalo nhận lượt" },
              { value: "share_to_feed", label: "Đăng bài viết nhật kí nhận lượt" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Tên hiển thị"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên hiển thị",
            },
          ]}
        >
          <Input placeholder="Tên hiển thị" />
        </Form.Item>

        {type === "subscribezalo" && (
          <Form.Item
            label={"ZALO ID"}
            name={"zalo_id"}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập ZALO ID",
              },
            ]}
          >
            <Input placeholder="ZALO ID" />
          </Form.Item>
        )}

        {type === "point" && (
          <Space style={{ alignItems: "center", marginBottom: 24 }}>
            <Text>Cứ mỗi</Text>
            <Form.Item name={"points"} style={{ marginBottom: 0, width: 56 }}>
              <InputNumber
                onBlur={(e) => {
                  if (!e.target.value) {
                    form.setFieldValue("points", 1);
                  }
                }}
                min={1}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Text>điểm đổi</Text>
            <Form.Item name={"turns"} style={{ marginBottom: 0, width: 48 }}>
              <InputNumber
                onBlur={(e) => {
                  if (!e.target.value) {
                    form.setFieldValue("turns", 1);
                  }
                }}
                min={1}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Text>lượt. Tối đa không quá</Text>
            <Form.Item name={"max_change_times"} style={{ marginBottom: 0, width: 48 }}>
              <InputNumber
                min={1}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                onBlur={(e) => {
                  if (!e.target.value) {
                    form.setFieldValue("max_change_times", 1);
                  }
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Text>lần</Text>
          </Space>
        )}

        {type === "share_to_feed" ||
          (type === "share_to_chat" && (
            <Space style={{ alignItems: "center", marginBottom: 24 }}>
              <Text>Cứ mỗi 1 lần chia sẻ, đổi</Text>
              <Form.Item name={"turns"} style={{ marginBottom: 0, width: 48 }}>
                <InputNumber
                  onBlur={(e) => {
                    if (!e.target.value) {
                      form.setFieldValue("turns", 1);
                    }
                  }}
                  min={1}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Text>lượt. Tối đa không quá</Text>
              <Form.Item name={"max_change_times"} style={{ marginBottom: 0, width: 48 }}>
                <InputNumber
                  min={1}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      form.setFieldValue("max_change_times", 1);
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Text>lần</Text>
            </Space>
          ))}

        <Form.Item label="Mô tả" name={"description"}>
          <TextArea
            showCount
            maxLength={200}
            rows={5}
            style={{
              height: 136,
              resize: "none",
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddTurnModal;
