import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";

function HaravanSync() {
  const { hash } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    var code = hash.split("&id_token")[0].slice(6);
    connectToHaravan(code);
  }, []);

  const connectToHaravan = (code) => {
    api.post("app/haravan/webhook/login", { code: code, shop_name: localStorage.getItem("shop_name") }).then((res) => {
      setIsLoading(false);
      window.location.href = "/apps/haravan";
    });
  };
  return <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />;
}

export default HaravanSync;
