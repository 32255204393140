import React, { Component } from "react";
import { Modal, Select, Input, Card, Pagination, Tabs, Popconfirm, message } from "antd";
import { Row, Col } from "antd";
import api from "api";
import TabsHtml from "./editor_html";
import EditorHtml from "./tabs_html";
import PopupEdit from "./edit_template";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;
const { Meta } = Card;

export default class GalleryPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      data: [],
      categories: [],
      industry: [],
      usage: [],
      lstMyTemplate: [],
      filter: {
        category: "all",
        industry: "all",
        usage: "all",
        info: "",
      },
      selected: {},
      paging: { page: 1, pageSize: 6 },
      type: "emaileditor",
      show: false,
      key: {},
    };
    this.TabsHtml = React.createRef();
    this.EditorHtml = React.createRef();
    this.PopupEdit = React.createRef();
  }

  componentDidMount = async () => {
    await this.loadCategory();
    await this.loadTemplate();
    await this.lstMyTemplate();
  };

  lstMyTemplate = () => {
    api.post("emailtemplate/getemailtemplate", {}).then((res) => {
      this.setState({
        lstMyTemplate: res,
      });
    });
  };

  open = async () => {
    await this.loadCategory();
    await this.loadTemplate();
    await this.lstMyTemplate();
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleOk = () => {
    if (this.state.selected && this.props.onDesignSelected) {
      this.props.onDesignSelected(this.state.selected, this.state.type);
    }
    this.setState({
      isModalVisible: false,
    });
  };

  handleChange = (name, value) => {
    var filter = { ...this.state.filter };
    filter[name] = value;
    this.setState({ filter: filter });
  };

  filter = (data) => {
    var filter = { ...this.state.filter };
    data = [...data];

    if (!(filter.industry === "" || filter.industry == "all")) {
      data = data.filter((item) => {
        return item.industry == filter.industry;
      });
    }

    if (!(filter.usage === "" || filter.usage == "all")) {
      data = data.filter((item) => {
        return item.usage == filter.usage;
      });
    }
    if (!(filter.info === "")) {
      data = data.filter((item) => {
        return item.name.toLowerCase().includes(filter.info.toLowerCase());
      });
    }

    return data;
  };

  getHtml = (html) => {
    var selected = { html: html };
    this.setState(
      {
        selected: selected,
      },
      () => {
        this.handleOk();
      }
    );
  };

  loadCategory = () => {
    api.get("emailtemplate/getfilterlist").then((res) => {
      this.setState({ industry: res.industry, usage: res.usage });
    });
  };

  loadTemplate = () => {
    api.get("emailtemplate/gettemplate").then((res) => {
      this.setState({
        data: res,
      });
    });
  };

  getPagingData(data) {
    var page = this.state.paging.page;
    var pageSize = this.state.paging.pageSize;
    var lstAll = this.filter(data);
    var lst = lstAll.slice((page - 1) * pageSize, page * pageSize);
    return lst;
  }

  onPageChanged = (page, pageSize) => {
    this.setState({
      paging: { page: page, pageSize: pageSize },
    });
  };

  onTabChange = (key) => {
    this.setState({ type: key });
  };

  deletetemplate = (id) => {
    api
      .post("emailtemplate/delete", { id: id })
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.lstMyTemplate();
      })
      .catch((err) => {
        message.error("Xóa không thành công", 3);
      });
  };

  render() {
    return (
      <>
        <Modal width={"50%"} title="Design" open={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => {
              this.onTabChange(key);
            }}
          >
            <TabPane tab="Templates" key="1">
              <div style={{ marginBottom: 15 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: 150 }}>
                    <Select defaultValue={this.state.filter.industry} style={{ width: "100%" }} onChange={(e) => this.handleChange("industry", e)} showSearch>
                      <Option value={"all"}>Tất cả</Option>
                      {this.state.industry.map((item) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  </div>

                  <div style={{ width: 150 }}>
                    <Select defaultValue={this.state.filter.usage} style={{ width: "100%" }} onChange={(e) => this.handleChange("usage", e)}>
                      <Option value={"all"}>Tất cả</Option>
                      {this.state.usage.map((item) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  </div>

                  <div style={{ flex: 1, marginLeft: 15 }}>
                    <Input placeholder="Info" onChange={(e) => this.setState({ filter: { ...this.state.filter, info: e.target.value } })} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>

              <Row gutter={16}>
                {this.getPagingData(this.state.data ? this.state.data : []).map((item) => {
                  return (
                    <Col span={8}>
                      <div style={this.state.selected === item ? { border: "2px solid #458fff", marginBottom: 16 } : { border: "2px solid #f0f0f0", marginBottom: 16 }}>
                        <Card
                          onDoubleClick={(e) => this.setState({ selected: item }, this.handleOk)}
                          onClick={(e) => this.setState({ selected: item })}
                          style={{ width: "100%", height: 470 }}
                          cover={
                            <div style={{ overflow: "hidden", height: 350, display: "flex" }}>
                              <img style={{ width: "100%", margin: "auto" }} alt={item.name} src={item.cover_image} />
                            </div>
                          }
                        >
                          <Card.Meta title={item.name} description={item.description} />
                        </Card>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <div style={{ marginLeft: "30%" }}>
                <Pagination defaultCurrent={1} total={this.filter(this.state.data).length} pageSize={6} onChange={this.onPageChanged} />
              </div>
            </TabPane>

            <TabPane tab="My Templates" key="2">
              {this.state.lstMyTemplate && this.state.lstMyTemplate.length > 0 ? (
                <Row gutter={16}>
                  {this.getPagingData(this.state.lstMyTemplate ? this.state.lstMyTemplate : []).map((item) => {
                    return (
                      <div key={item.id}>
                        <Col span={8}>
                          <div
                            style={
                              this.state.selected === item
                                ? { border: "2px solid #458fff", marginBottom: 16, height: 192, width: 287 }
                                : { border: "2px solid #f0f0f0", marginBottom: 16, height: 192, width: 287 }
                            }
                          >
                            <Card
                              onDoubleClick={(e) => this.setState({ selected: item }, this.handleOk)}
                              onClick={(e) => this.setState({ selected: item })}
                              style={{ width: "100%", border: "none", height: 150 }}
                              actions={[
                                <EditOutlined
                                  key="edit"
                                  onClick={() => {
                                    this.PopupEdit.current.open(item.id);
                                  }}
                                />,

                                <Popconfirm title="Bạn có chắc muốn xóa không? " onConfirm={() => this.deletetemplate(item.id)} okText="Có" cancelText="Không">
                                  <DeleteOutlined />
                                </Popconfirm>,
                              ]}
                            >
                              <Meta style={{ height: 100 }} title={item.name} description={item.description} />
                            </Card>
                            ,
                          </div>
                        </Col>
                      </div>
                    );
                  })}
                </Row>
              ) : (
                <div></div>
              )}
              <div style={{ marginLeft: "30%" }}>
                <Pagination defaultCurrent={1} total={this.filter(this.state.lstMyTemplate).length} pageSize={6} onChange={this.onPageChanged} />
              </div>
            </TabPane>

            <TabPane tab="Code your own" key="3">
              <div style={{ display: "flex" }}>
                <div style={{ width: "48%", marginTop: 10, borderRadius: "1px" }}>
                  <Card
                    onClick={() => {
                      this.TabsHtml.current.open();
                    }}
                    hoverable
                    style={{ width: "100%", height: "30vh", margin: 10 }}
                    cover={
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        <h1>Rich Text Editor</h1> <br />
                        <img src={"https://my.sendinblue.com/public/theme/version4/assets/images/plain-text-editor-icon.png"} /> <br /> <br /> <br />
                        <p>Use the rich text editor to create simple emails</p>
                      </div>
                    }
                  >
                    <Card.Meta />
                  </Card>
                </div>

                <div style={{ flex: 1, margin: 10, borderRadius: "1px" }}>
                  <Card
                    onClick={() => {
                      this.EditorHtml.current.open();
                    }}
                    hoverable
                    style={{ width: "98%", height: "30vh", margin: 10 }}
                    cover={
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        <h1>Paste your Code</h1> <br />
                        <img src={"https://my.sendinblue.com/public/theme/version4/assets/images/html-editor-icon.png"} /> <br /> <br /> <br />
                        <p>Copy and paste your HTML code.</p>
                      </div>
                    }
                  >
                    <Card.Meta />
                  </Card>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Modal>
        <TabsHtml ref={this.TabsHtml} getHtml={this.getHtml}></TabsHtml>
        <EditorHtml ref={this.EditorHtml} getHtml={this.getHtml}></EditorHtml>
        <PopupEdit ref={this.PopupEdit} reload={this.getData} lstMyTemplate={this.lstMyTemplate}></PopupEdit>
      </>
    );
  }
}
