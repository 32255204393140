import { Button, Card, Col, Popconfirm, Row, Space, Tag, Typography, message } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import LoadingModal from "components/common/loading/LoadingModal";
import { api_url } from "config";
import { media_url } from "config";
import api from "api";

const { Text, Title } = Typography;
function ConnectTab({ isConnected, setIsConnected, isActive }) {
  const [configData, setConfigData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const addHaravan = () => {
    setIsLoading(true);
    api.post("app/shopee/config/getrequestpermisionurl").then((res) => {
      setIsLoading(false);
      window.location.href = res;
    });
  };

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    setIsLoading(true);
    api
      .post("app/shopee/config/getconfig")
      .then((res) => {
        if (res.shops && res.shops.length > 0) {
          setConfigData(res.shops[0]);
          setIsConnected(true);
          setIsLoading(false);
        } else {
          setConfigData();
          setIsConnected(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setConfigData();
        setIsConnected(false);
        setIsLoading(false);
      });
  };

  const deleteLink = (id) => {
    setIsLoading(true);
    api
      .post("app/shopee/config/deleteshopee", { shopee_id: id })
      .then((res) => {
        getConfig();
      })
      .catch((err) => {
        message.error("Xoá thất bại");
        setIsLoading(false);
      });
  };
  return (
    <>
      {configData ? (
        <Card
          //   style={{ width: "100%" }}
          title={configData.shop_name}
          extra={
            isActive ? (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52C41A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Hoạt động</Text>
              </Space>
            ) : (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Không hoạt động</Text>
              </Space>
            )
          }
        >
          <Col>
            <Row>
              <Space size={4}>
                <Text style={{ fontWeight: 600 }}>Access Token: </Text>
                <Text type="secondary">{configData.access_token}</Text>
              </Space>
            </Row>
            {/* <Row>
              <Space size={4}>
                <Text style={{ fontWeight: 600 }}>Session State: </Text>
                <Text type="secondary">{configData.session_state}</Text>
              </Space>
            </Row> */}
            {/* <Row>
    
              <Text style={{ fontWeight: 600 }}>Scope: </Text>
              <div>
                {configData.scope.split(" ").map((item) => {
                  return (
                    <Tag style={{ marginBottom: 8 }} color="blue">
                      {item}
                    </Tag>
                  );
                })}
              </div>
     
            </Row> */}
          </Col>
          <Row justify={"end"}>
            <Popconfirm
              title={<Text>Bạn có muốn xoá liên kết này?</Text>}
              onConfirm={() => {
                deleteLink(configData.shop_id);
              }}
              okText="Có"
              cancelText="Không"
            >
              <Button type="link" style={{ color: "red", padding: 4 }}>
                Xoá liên kết
              </Button>
            </Popconfirm>
          </Row>
        </Card>
      ) : (
        <>
          <Button type="primary" target="_blank" icon={<PlusCircleFilled />} onClick={addHaravan}>
            Thêm kết nối
          </Button>
          {/* <AddNewWooModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> */}
        </>
      )}
      <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />
    </>
  );
}

export default ConnectTab;
