import { Badge, Button, Checkbox, Dropdown, Form, Input, Row, Select, Space, Table, message } from "antd";
import { SearchOutlined, SyncOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SyncModal from "./SyncModal";
import api from "api";
import AntTable from "components/common/AntTable";
import numeral from "numeral";

function ProductTab({ totalProduct }) {
  const [listWooProduct, setListWooProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customSearch, setCustomSearch] = useState({ status: "all" });
  const [form] = Form.useForm();
  const refTable = React.createRef();
  const columns = [
    {
      title: "Ảnh",
      dataIndex: "images",
      key: "images",
      render: (images, record, index) => {
        return (
          <div
            style={{
              width: 80,
              height: 80,
              backgroundImage: `url(${images[0]?.src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Biến thể",
      dataIndex: "variations",
      key: "variations",
      render: (variations, record, index) => {
        // if(variations.length === 0){
        //     return 1
        // } else {
        return variations.length;
        // }
      },
    },
    {
      title: "Mã",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (price, record, index) => {
        return numeral(price).format("0,0");
      },
    },
    {
      title: "Kết nối với Loyalty",
      dataIndex: "is_link",
      key: "is_link",
      render: (is_link, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        if (is_link) {
          return <Badge color="green" text="Đã kết nối APP" />;
        } else {
          return <Badge color="red" text="Chưa kết nối APP" />;
        }
      },
    },
    {
      // title: "Kết nối với Loyalty",
      dataIndex: "slug",
      key: "slug",
      render: (slug, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        return (
          <Dropdown
            trigger={"click"}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Đồng bộ lại",
                },
              ],
              onClick: (e) => {
                syncWooProduct(record);
              },
            }}
            placement="bottomLeft"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const syncWooProduct = (wooProduct) => {
    api
      .post("app/woo/config/syncproduct", wooProduct)
      .then((res) => {
        refTable.current.reload();
        // checkIsLink(
        //   listWooProduct.map((item) => {
        //     return { woo_product_id: item.id };
        //   }),
        //   listWooProduct
        // );
      })
      .catch((err) => {
        console.log(err);
        message.error("Đồng bộ thất bại");
      });
  };

  const [linkList, setLinkList] = useState([]);

  //   const checkLink = async (id) => {
  //     var isLink = await api
  //       .post("product_map/getbywooproductid", { woo_product_id: id })
  //       .then((res) => {
  //         if (res) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       })
  //       .catch((err) => {
  //         return true;
  //       });
  //     return isLink;
  //   };

  useEffect(() => {
    // getListWooProduct();
  }, []);

  useEffect(() => {
    // if (listWooProduct.length > 0) {
    //   filterProduct();
    // }
  }, [listWooProduct]);

  //   const get

  const onFinish = () => {
    // filterProduct();
  };

  const onFinishFailed = () => {};

  const openSyncModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div>
      <Form form={form} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Space>
          <Form.Item name="text">
            <Input placeholder="Tên sản phẩm hoặc mã sản phẩm" style={{ width: "calc(100vw - 608px)" }} />
          </Form.Item>

          <Form.Item name="status">
            <Select
              placeholder="Trạng thái"
              style={{ width: 232 }}
              options={[
                { label: "Tất cả", value: "all" },
                { label: "Đã kết nối APP", value: "link" },
                { label: "Chưa kết nối APP", value: "unlink" },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: 112 }} icon={<SearchOutlined />} type="primary" htmlType="submit">
              Tìm kiếm
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: 112 }} icon={<SyncOutlined />} type="primary" ghost onClick={openSyncModal}>
              Đồng bộ
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {/* <Table dataSource={filterList} columns={columns} /> */}
      <AntTable
        columns={columns}
        autoLoad={true}
        customSearch={customSearch}
        tableName={"tbWooProduct"}
        ajax="app/woo/config/listallproducts"
        ref={refTable}
        rowKey={(record) => record.id}
      ></AntTable>
      {isModalOpen && <SyncModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} totalProduct={totalProduct} />}
    </div>
  );
}

export default ProductTab;
