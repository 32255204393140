import React from "react";
import { Card, Col, Row, Tag, Button, message, Popconfirm, Empty, Skeleton, Tabs, Table, Switch, Typography } from "antd";
import api from "api";
import PopupCreateGameProgram from "./popupcreategameprogram";
import { createSearchText } from "common/text";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";

const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;
export default class GameProgramList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      game_lst: [],
      visible: false,
      type: "spin",
      filter: {
        query: {
          $and: [{ is_delete: false }],
        },
      },
    };
    this.PopupCreateGameProgram = React.createRef();
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig = () => {
    api.post("gameprogram/getconfigdetails", this.state.filter).then((res) => {
      this.setState({ game_lst: res, visible: true });
    });
  };

  // deleteConfig = (id) => {
  //   api
  //     .post("gameprogram/deleteconfig", { id: id })
  //     .then((res) => {
  //       message.success("Xoá thành công");
  //       this.getConfig();
  //     })
  //     .catch((err) => {
  //       message.error("Có lỗi xảy ra khi xoá");
  //     });
  // };

  editGame = (item) => {
    var href = "";
    api.post("gameprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = res.config_url + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };

  onFilterChange = (type, value) => {
    var filter = { ...this.state.filter };
    value = createSearchText(value);
    var index = filter.query.$and.findIndex((x) => x[type]);
    if (value == "") {
      if (index > -1);
      filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][type] = { $regex: value, $options: "i" };
      } else {
        filter.query.$and.push({ [type]: { $regex: value, $options: "i" } });
      }
    }
    this.setState({ filter: filter }, () => this.getConfig());
  };

  onTabsChange = (value) => {
    this.setState(
      {
        type: value,
      },
      () => {
        console.log(this.state);
      }
    );
    this.onFilterChange("type", value);
  };

  deleteGameConfirm = (id) => {
    api.post("gameprogram/deletegame", { id: id }).then((res) => {
      if (res) {
        message.success("Xoá thành công");
        this.getConfig();
      }
    });
  };

  activeGame = (item) => {
    api.post("gameprogram/activegame", { id: item.id, is_active: !item.is_active }).then((res) => {
      if (res) {
        message.success("Lưu thành công");
        this.getConfig();
      }
    });
  };

  render() {
    var utcNow = moment.utc();
    return (
      <>
        <div style={{ backgroundColor: "#ffffff" }}>
          <div style={{ padding: 25 }}>
            <SearchBox
              name="z"
              data={[]}
              placeholder="Thông tin"
              dataButton={[
                <Button type="primary" onClick={() => this.PopupCreateGameProgram.current.open()} style={{ marginRight: 15 }} htmlType="submit">
                  <i className="fa fa-plus" style={{ marginRight: 5 }}></i> Tạo mới
                </Button>,
              ]}
              onSearch={() => this.getConfig()}
              onChange={(value) => {
                this.onFilterChange("search_text", value);
              }}
            />
          </div>
        </div>

        <Card style={{ marginTop: 30 }}>
          <Tabs activeKey={this.state.type} onChange={(key) => this.onTabsChange(key)}>
            <TabPane tab="Vòng quay may mắn" key="spin">
              {this.state.visible ? (
                <div>
                  {this.state.game_lst != null && this.state.game_lst.length != 0 ? (
                    // this.state.game_lst.map((item) => {
                    //   return (
                    //     <>
                    //       <Card size="small">
                    //         <Row guiter={16}>
                    //           <Col span={18} style={{ display: "flex" }}>
                    //             <div style={{ width: 130 }}>
                    //               <div src={item.logo} style={{ width: 100, height: 100 }}>
                    //                 <img src={item.logo} style={{ width: "100%", height: "100%" }}></img>
                    //               </div>
                    //             </div>
                    //             <div style={{ marginTop: 15, marginLeft: 10, overflow: "hidden", width: 500 }}>
                    //               <b className="listTitle">{item.name}</b>
                    //               <div className="description">{item.display_short_description}</div>
                    //             </div>
                    //           </Col>

                    //           <Col span={4} style={{ display: "flex" }}>
                    //             {item.is_active &&
                    //             (!item?.time_limit?.is_time_limit ||
                    //               (item?.time_limit?.is_time_limit && item?.time_limit.start <= utcNow && item?.time_limit.end >= utcNow)) ? (
                    //               <Tag style={{ margin: "auto" }} color="#87d068">
                    //                 Đang hoạt đông
                    //               </Tag>
                    //             ) : (
                    //               <Tag style={{ margin: "auto" }} color="#f50">
                    //                 Đã tạm dừng
                    //               </Tag>
                    //             )}
                    //           </Col>
                    //           <Col span={2} style={{ marginTop: 30, color: "#1890FF", fontSize: 20 }}>
                    //             <i
                    //               style={{ marginRight: 10, height: 20, width: 20 }}
                    //               className="fa fa-edit"
                    //               aria-hidden="true"
                    //               onClick={() => this.editGame(item)}
                    //             ></i>
                    //             <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.deleteConfig(item.id)} okText="Có" cancelText="Không">
                    //               <i style={{ marginRight: 10 }} className="fa fa-trash" aria-hidden="true"></i>
                    //             </Popconfirm>
                    //           </Col>
                    //         </Row>
                    //       </Card>
                    //       <div style={{ marginTop: 10 }}></div>
                    //     </>
                    //   );
                    // })
                    <Table dataSource={this.state.game_lst}>
                      <Column
                        // title="Tên"
                        dataIndex="is_active"
                        key="is_active"
                        render={(is_active, record, index) => (
                          <>
                            <Switch
                              onChange={() => {
                                this.activeGame(record);
                              }}
                              defaultChecked={is_active}
                            />
                          </>
                        )}
                      />
                      <Column
                        title="Biểu tượng"
                        dataIndex="image"
                        key="image"
                        render={(image, record, index) => (
                          <div
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: "50%",
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundColor: "transparent",
                            }}
                          ></div>
                        )}
                      />
                      <Column
                        title="Tên chương trình"
                        dataIndex="name"
                        key="name"
                        render={(name, record, index) => (
                          <>
                            <Typography.Title level={5}>{name}</Typography.Title>
                          </>
                        )}
                      />
                      <Column
                        align="center"
                        dataIndex="id"
                        key="id"
                        width={160}
                        render={(id, record, index) => (
                          <>
                            {/* {id.map((id) => ( */}
                            <Button type="link" onClick={() => this.editGame(record)}>
                              Sửa
                            </Button>
                            <Popconfirm
                              // title="Delete the task"
                              title={<Typography.Text>Bạn có muốn xoá trò chơi này?</Typography.Text>}
                              onConfirm={() => {
                                this.deleteGameConfirm(record.id);
                              }}
                              okText="Có"
                              cancelText="Không"
                            >
                              <Button type="link" style={{ color: "red" }}>
                                Xoá
                              </Button>
                            </Popconfirm>

                            {/* ))} */}
                          </>
                        )}
                      />
                    </Table>
                  ) : (
                    <Card bodyStyle={{ height: "50vh" }}>
                      <div>
                        <Empty style={{ marginTop: "13vh" }} description="Hãy tạo mới chương trình đầu tiên"></Empty>
                        <Row justify="center">
                          <Button
                            onClick={() => {
                              this.PopupCreateGameProgram.current.open();
                            }}
                            style={{ marginTop: 20 }}
                            type="primary"
                          >
                            <i className="fas fa-plus"></i>&nbsp;Tạo mới
                          </Button>
                        </Row>
                      </div>
                    </Card>
                  )}
                </div>
              ) : (
                <Skeleton />
              )}
            </TabPane>
          </Tabs>
        </Card>

        <PopupCreateGameProgram ref={this.PopupCreateGameProgram}></PopupCreateGameProgram>
      </>
    );
  }
}
