import React, { Component } from "react";
import { Button, List, Row, Col, Divider, Card, Empty } from "antd";
import PopupCreatePointProgram from "./popupcreatepointprogram";
import api from "api";
import { handleTemplateDescription } from "../../common/text.js";
import PopupCreateExchangPointProgram from "./exchange_point_program/popupcreateexchangepointprogram";
import Point from "./points";
import Expiry from "./expiry";
import PopupCreateGameProgram from "./gameprogram/popupcreategameprogram";

export default class PointConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgram: false,
    };

    this.Point = React.createRef();
    this.Expiry = React.createRef();
  }

  componentDidMount() { }

  render() {
    return (
      <div>
        <Row>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Tên điểm
            </div>
            <div className="description">Đặt tên cho điểm tích lũy. Ví dụ: Điểm, Ruby, Gold</div>
          </Col>
          <Col span={14}>
            <Point />
          </Col>
        </Row>

        <Divider style={{ marginTop: 50 }}></Divider>
        <Row>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Thời hạn sử dụng điểm
            </div>
            <div className="description">
              Quản lý hạn sử dụng của điểm
              <br />
              Nếu điểm có hạn sử dụng số điểm của khách hàng sẽ được thiết lập về 0 sau mỗi chu kỳ được thiết lập. <br /> Ví dụ: 6 tháng, 1 năm, 2 năm
            </div>
          </Col>
          <Col span={14}>
            <Expiry />
          </Col>
        </Row>
      </div>
    );
  }
}
