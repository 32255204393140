import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import VoucherPicker from "components/marketing/exchange_point_program/config/picker/voucher_picker";

const { Text } = Typography;
const { TextArea } = Input;
function AddEditRewardModal({ isModalOpen, setIsModalOpen, value, onChange }) {
  const [form] = Form.useForm();
  const [type, setType] = useState("voucher");

  const handleOk = () => {
    form
      .validateFields()
      .then((res) => {
        var newItem = form.getFieldsValue();
        if (value) {
          newItem.display = value.display;
          newItem.display.text.value = newItem.reward.text;
          newItem.index = value.index;
        } else {
          newItem.display = { type: "text", text: { value: newItem.reward.text } };
        }
        console.log(newItem);
        onChange(newItem);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen && value) {
      setType(value.reward.type);
      form.setFieldsValue(value);
    }
  }, [isModalOpen]);
  return (
    <Modal width={576} title="Cấu hình quà" open={isModalOpen} okText="Lưu" cancelText="Thoát" onOk={handleOk} onCancel={handleCancel}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ reward: { type: "voucher" }, ratio: 10 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* <Form.Item
          label="Phương thức"
          name="type"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn 1 phương thức",
            },
          ]}
        >
          <Select
            options={[
              { value: "point", label: "Đổi điểm nhận lượt" },
              { value: "subscribezalo", label: "Quan tâm Zalo nhận lượt" },
              { value: "share_to_chat", label: "Chia sẻ bài viết zalo nhận lượt" },
              { value: "share_to_feed", label: "Đăng bài viết nhật kí nhận lượt" },
            ]}
          />
        </Form.Item> */}

        <Form.Item
          label="Tên hiển thị"
          name={["reward", "text"]}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên hiển thị",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Row gutter={[8, 8]}>
          <Col span={type === "loss_turn" ? 20 : 10}>
            <Form.Item
              label="Quà tặng"
              name={["reward", "type"]}
              //   valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn 1 quà tặng",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setType(e);
                }}
                options={[
                  { value: "point", label: "Điểm tích luỹ" },
                  { value: "voucher", label: "Voucher" },
                  { value: "add_turn", label: "Thêm lượt" },
                  { value: "loss_turn", label: "Chúc may mắn lần sau" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col hidden={type === "loss_turn"} span={10}>
            {type === "voucher" && (
              //   <Form.Item>
              <VoucherPicker
                label="Voucher"
                name={["reward", "voucher"]}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn voucher",
                  },
                ]}
                onChange={(e) => {
                  console.log(e);
                }}
                type="all"
              ></VoucherPicker>
              //   </Form.Item>
            )}

            {type === "point" && (
              <Form.Item
                label="Số điểm"
                name={["reward", "point"]}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập điểm",
                  },
                ]}
              >
                <InputNumber placeholder="Số điểm" min={1} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} style={{ width: "100%" }} />
              </Form.Item>
            )}

            {type === "add_turn" && (
              <Form.Item
                label="Số lượt"
                name={["reward", "add_turn"]}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số lượt",
                  },
                ]}
              >
                <InputNumber placeholder="Số lượt" min={1} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} style={{ width: "100%" }} />
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            <Form.Item
              label="Tỷ lệ"
              name="ratio"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tỷ lệ",
                },
              ]}
            >
              <InputNumber
                min={0}
                onBlur={(e) => {
                  if (!e.target.value) {
                    form.setFieldValue("ratio", 1);
                  }
                }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddEditRewardModal;
