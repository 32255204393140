import React, { memo, useState, useEffect, forwardRef } from "react";
import { Row, Col, Select, Card, message, Typography, Form, Divider } from "antd";
import api from "api";
import TextPicker from "../text_picker";

const { Title, Text } = Typography

/**
 * ZaloEditor dùng cấu hình mẫu tin gửi bằng ZNS
 * @param {*} config config eSMS
 * @param {Function} onChange hàm gán lại giá trị cho zalo_content
 * @param {Object} value zalo_content 
 */
const ZaloESMSEditor = forwardRef(({ config, onChange, value }) => {
  const [mode, setMode] = useState("");
  const [lstZOA, setLstZOA] = useState([]);
  const [listTempalte, setListTemplate] = useState([]);
  // const [data, setData] = useState({});
  const [lstParam, setLstParam] = useState([]);
  const [content, setContent] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getListZOA();
    // setData(value);
    if (value.oa_id) {
      setMode("edit");
      //lấy danh sách mẫu tin oa cho mode edit
      form.setFieldValue('zalo_oa', value.oa_id);
      getZNSTemplates(value.oa_id);
    }
  }, [config, value]);

  useEffect(() => {
    if (mode === "edit") {
      onTemplateChange(value.template_id, mode);
    }
  }, [mode, listTempalte, lstParam])

  /**
   * Hàm lấy danh sách Zalo OA
   * @author Huy
   */
  const getListZOA = async () => {
    await api.post("app/esms/config/getlistzoa", { ...config }).then((res) => {
      setLstZOA(res.listZOA);
    }).catch((err) => { console.log(err); });
  }

  /**
   * Hàm lấy danh sách template Zalo
   * @author huy
   */
  const getZNSTemplates = async (oaID) => {
    await api.post("app/esms/config/gettemplateoa", { ...config, oa: oaID }).then((res) => {
      // console.log('get list zalo oa', res)
      setListTemplate(res.lstTemplate?.ZNSTemplates ? res.lstTemplate.ZNSTemplates : []);
    }).catch((err) => { console.log(err); });
  }

  /**
   * Hàm lấy chi tiết template Zns từ api ESMS theo api mới
   * 15/07/2023
   * @param {} oaID 
   * @param {} templateId
   * @author huy
   */
  const getZnsTemplateInfo = async (oaID, templateId) => {
    await api.post("app/esms/config/getznstemplateinfo", { ...config, oa: oaID, templateId: templateId }).then((res) => {
      // console.log('get Zns Template Info', res)
      setPreviewUrl(res.znsTemplateInfo.PreviewUrl)
    }).catch((err) => { console.log(err); });
  }

  /**
  * Hàm xử lý khi chọn Zalo OA
   * @param {*} value 
   */
  const onZaloOAChange = (value) => {
    form.setFieldValue('zalo_oa', value);
    if (onChange) { onChange(value, "oa_id"); }
    getZNSTemplates(value)
  };

  /**
   * xử lý khi chọn template zalo
   * @param {string} templateID id của template zalo
   * @param {string} mode mode của chiến dịch, hiện tại đang xử lý cho 1 mình mode edit
   */
  const onTemplateChange = async (templateID, mode) => {
    if (mode !== "edit") {
      form.setFieldValue('template_id', templateID);
      var temp = listTempalte.find((x) => x.TempId == templateID);

      //tạo mảng để push param vào
      var newLstParams = [];
      temp.ZNSTempDetail.forEach(element => newLstParams.push(""));

      setLstParam(temp.ZNSTempDetail);
      setContent(temp.TempContent);
      getZnsTemplateInfo(value.oa_id, temp.TempId);

      if (onChange) {
        onChange(templateID, 'template_id');
        onChange(newLstParams, 'params');
      }
    } else {
      form.setFieldValue('template_id', templateID);
      //render list param zalo
      var temp = await listTempalte.find((x) => x.TempId == templateID);

      if (temp) {
        setLstParam(temp.ZNSTempDetail);
        setContent(temp.TempContent);
        getZnsTemplateInfo(value.oa_id, temp.TempId);
      }

      //gán giá trị cho param
      lstParam.forEach((param, index) => {
        form.setFieldValue(param.Param, value.params[index]);
      })
    }
  };

  /**
   * xử lý data khi các param của zalo oa thay đổi
   * @param {string} paramText kí hiệu param
   * @param {string} name tên của param được chọn
   * @author huy
   */
  const onParamsChange = (paramText, name) => {
    form.setFieldValue(name, paramText);
    var newParams = value.params;
    var indexOfParam;
    var param = lstParam.find((x, index) => {
      indexOfParam = index
      return x.Param === name;
    });

    if (param) {
      newParams[indexOfParam] = paramText;
      if (onChange) { onChange(newParams, 'params'); }
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Divider style={{ marginTop: 0 }}>Cấu hình Zalo</Divider>

        <Form form={form} layout="vertical">
          <Form.Item label="Zalo OA:" name="zalo_oa" required>
            <Select
              value={value?.oa_id}
              options={lstZOA}
              fieldNames={{ label: "OAName", value: "OAID" }}
              onChange={onZaloOAChange}
            />
          </Form.Item>

          <Form.Item label="Mẫu tin OA:" name="template_id" required>
            <Select
              value={value?.template_id}
              options={listTempalte}
              fieldNames={{ label: "TempName", value: "TempId" }}
              onChange={onTemplateChange}
            />
          </Form.Item>

        </Form>

        <Form form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}>
          {lstParam && (lstParam.map((item) => {
            return (
              <Form.Item
                label={<Text strong>{`{{${item.Param}}}`}</Text>}
                labelAlign="left"
                name={item.Param}
                rules={[
                  {
                    required: true,
                    message: `Vui lòng nhập ${item.Param}!`,
                  },
                ]}
              >
                <TextPicker value={item.value} name={item.Param} onChange={onParamsChange}></TextPicker>
              </Form.Item>
            );
          }))}
        </Form>

      </Col>
      <Col span={12}>
        <Card
          style={{
            backgroundColor: '#F0F2F5',
            height: '100%'
          }}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {previewUrl ? (
            <iframe
              style={{ top: "0px", width: "100%", height: "68vh", overflow: "hidden" }}
              frameborder="0" allowfullscreen
              src={previewUrl}
              title="Đường dẫn đến bản xem trước của template" />
          ) : (content && (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              style={{
                margin: 24,
                padding: 24,
                backgroundColor: '#fff',
                borderRadius: 8
              }}
            />
          ))}
          {/* {content && (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              style={{
                margin: 24,
                padding: 24,
                backgroundColor: '#fff',
                borderRadius: 8
              }}
            />
          )} */}
        </Card>
      </Col>
    </Row>
  );
});

export default memo(ZaloESMSEditor);
