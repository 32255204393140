import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card } from "antd";
import api from "api";
import PopupCampaign from "./popupcampaign";
import AntTable from "components/common/AntTable";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";
import CampaignResult from "./campaign_result";
import { formatL } from "../../common/date_time";
import { Tabs } from "antd";
import { io } from "socket.io-client";
import { wss_url } from "config";
import { formatS } from "common/date_time";
import { getSocket } from "common/realtime";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const { Search } = Input;
const { Option } = Select;

class Campaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      filter: {
        query: {
          $and: [{ "schedule.type": { $all: ["onetime"] } }],
        },
      },
      lstApp: [],
      gotLstApp: false,
    };
    this.PopupCampaign = React.createRef();
    this.Tb = React.createRef();
    this.CampaignResult = React.createRef();
  }

  componentDidMount = () => {
    api
      .post("shop/getinstalledappbycategories", { categories: ["communication"] })
      .then((res) => {
        this.setState({ lstApp: res, gotLstApp: true });
      })
      .catch((err) => {});

    var socket = getSocket("admin_socket");

    socket.on("connect", () => {
      socket.on("reload_table_campaign", () => {
        this.Tb.current.reload();
      });
    });
    this.socket = socket;
  };

  onSelectFilterChange = (key, isSelected) => {
    // var filter = { ...this.state.filter };
    // if (isSelected) {
    //     return;
    // }
    // switch (key) {
    // }
  };

  delete = (id) => {
    api
      .post("campaign/delete", { id: id })
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.Tb.current.reload();
      })
      .catch((err) => {
        message.error("Xóa không thành công", 3);
      });
  };
  removeAscent = (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\s/g, "");
    return str;
  };

  onFilterChange = (name, value) => {
    var filter = { ...this.state.filter };
    if (name === "name") {
      name = "search_text";
      var new_value = this.removeAscent(value);
      value = new_value;
    }
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value === "") {
      if (index > -1);
      filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name].$regex = value;
      } else {
        filter.query.$and.push({ [name]: { $regex: value } });
      }
    }

    this.setState({ filter: filter }, () => this.Tb.current.reload());
  };

  onMultiSelectChange = (name, value) => {
    var filter = { ...this.state.filter };
    var index = filter.query.$and.findIndex((x) => x[name]);

    if (value.length === 0) {
      if (index > -1);

      filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name] = { $all: value };
      } else {
        filter.query.$and.push({ [name]: { $all: value } });
      }
    }
    this.setState({ filter: filter }, () => this.Tb.current.reload());
  };

  getColumn = () => {
    var columns = [
      {
        title: "Tên chiến dịch",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "Ngày tạo",
        dataIndex: "createdate",
        key: "createdate",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.createdate);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },
      {
        title: "Đối tượng",
        dataIndex: "customer_segment_id",
        key: "customer_segment_id",
        sorter: true,
        render: (text, record, index) => {
          if (record.customer_segment) {
            return <span>{record.customer_segment[0] && record.customer_segment[0].name}</span>;
          }
        },
      },
      {
        title: "Phương thức",
        dataIndex: "way_to_send",
        key: "way_to_send",
        render: (text, record, index) => {
          var lstshow = record.way_to_send.map((item) => {
            var index = this.state.lstApp.findIndex((x) => x.id === item.id);
            if (index > -1) return this.state.lstApp[index].name;
          });

          return <div>{lstshow.toString().replaceAll(",", "/ ")}</div>;
        },
        sorter: true,
      },
      {
        title: "Loại",
        dataIndex: "schedule",
        key: "schedule",
        render: (text, record, index) => {
          var type = "";
          switch (record.schedule.type) {
            case "daily":
              type = "Hàng ngày";
              break;
            case "weekly":
              type = "Hàng tuần";
              break;
            case "monthly":
              type = "Hàng tháng";
              break;
            case "onetime":
              type = "một lần";
              break;
          }
          return <div>{type}</div>;
        },
        sorter: true,
      },
      // {
      //   title: "Tiến độ",
      //   key: "progress",
      //   dataIndex: "progress",
      //   render: (text, record, index) => {
      //     var a = (
      //       <div>
      //         {record.progress.map((item) => {
      //           if (item.total == 0) return "100%";
      //           return <div>{`${formatS(item.run_on)}: ${Math.floor((item.sent / item.total) * 100)}%`}</div>;
      //         })}
      //       </div>
      //     );
      //     return a;
      //   },
      // },
      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Popconfirm title="Bạn có chắc muốn xóa không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                <Button type="link">Xóa</Button>
              </Popconfirm>
              <a style={{ color: "#0287FF", marginRight: "10px" }} onClick={() => this.navigate(`/campaign/${record.id}`)}>
                Sửa
              </a>

              <a style={{ color: "#0287FF" }} onClick={() => this.CampaignResult.current.open(record.id)}>
                Kết quả
              </a>
            </>
          );
        },
      },
    ];
    return columns;
  };

  getFilters = () => {
    var filters = [
      {
        Key: "way_to_send",
        Name: "Phương thức",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Loại</label>

              <Select style={{ width: "100%" }} onChange={(e) => this.onMultiSelectChange("way_to_send.id", e)} mode="multiple" allowClear={true}>
                {this.state.lstApp.map((item) => {
                  return <Option value={item.id}>{item.name}</Option>;
                })}
              </Select>
            </div>
          </Col>
        ),
      },
    ];
    return filters;
  };

  tabChange = (e) => {
    this.onMultiSelectChange("schedule.type", [e]);
  };
  navigate = (path) => {
    this.props.navigate(path);
  };
  render() {
    var filters = this.getFilters();
    return (
      <>
        {this.state.gotLstApp && (
          <>
            <CampaignResult ref={this.CampaignResult}></CampaignResult>

            <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
              <div>
                <SearchBox
                  name="campaign"
                  data={filters}
                  onSelectFilterChange={this.onSelectFilterChange}
                  placeholder="Thông tin"
                  dataButton={[
                    <Button
                      type="primary"
                      onClick={() => {
                        this.navigate("/campaign/add");
                      }}
                      style={{ marginRight: 15 }}
                      icon={<PlusOutlined />}
                    >
                      {/* <i className="fa fa-plus" aria-hidden="true"></i>&nbsp; */}
                      Thêm mới
                    </Button>,
                  ]}
                  onSearch={() => this.Tb.current.reload()}
                  onChange={(value) => {
                    this.onFilterChange("name", value);
                  }}
                />
              </div>
            </div>
            <div>
              <Card>
                <Tabs defaultActiveKey="onetime" onChange={this.tabChange}>
                  <TabPane tab="Một lần" key="onetime"></TabPane>
                  <TabPane tab="Hàng ngày" key="daily"></TabPane>
                  <TabPane tab="Hàng tuần" key="weekly"></TabPane>
                  <TabPane tab="Hàng tháng" key="monthly"></TabPane>
                </Tabs>

                <AntTable
                  columns={this.getColumn()}
                  customSearch={this.state.filter}
                  tableName={"tbCustomer"}
                  ajax="campaign/list"
                  ref={this.Tb}
                  rowKey={(record) => record.id}
                  rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {},
                  }}
                ></AntTable>
              </Card>
            </div>
            <PopupCampaign ref={this.PopupCampaign} reload={() => this.Tb.current.reload()}></PopupCampaign>
          </>
        )}
      </>
    );
  }
}

const CampaignHoc = () => {
  const navigate = useNavigate();
  return <Campaign navigate={navigate} />;
};

export default CampaignHoc;
