import React, { Component } from "react";
import { Input, message, Modal, Form, Upload, DatePicker, Col, Row, Card, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import api from "api";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import ColorPicker from "components/picker/color_picker";

export default class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      displayColorPicker: false,
      data: [
        {
          id: "",
          label: "",
          color: "#FF0000",
        },
      ],
      initialValue: [],
      form_key: "1-tag",
    };
    this.tb = React.createRef;
    this.popupAddEdit = React.createRef();
  }

  componentDidMount = () => {
    this.getTagConfig();
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color, index) => {
    var data = this.state.data;
    data[index].color = color.hex;
    this.setState({ data: data });
  };

  getTagConfig = () => {
    var initialValue = [];
    api
      .post("customerconfig/getconfig", { query: { key: "tag" } })
      .then((res) => {
        if (res.length > 0) {
          res.forEach((element) => {
            initialValue.push({
              id: element.id,
              label: element.label,
              color: element.color,
            });
          });
          this.setState({ data: res, initialValue: initialValue });
          if (this.state.form_key == "1-tag")
            this.setState({
              form_key: "2-tag",
            });
          else if (this.state.form_key == "2-tag")
            this.setState({
              form_key: "1-tag",
            });
        }
      })
      .catch((err) => {});
  };

  onFinish = (values) => {
    if (values != null && values.tags.length != 0) {
      api
        .post("customerconfig/save", { config: values.tags, key: "tag" })
        .then((res) => {
          message.success("Lưu tag thành công");
        })
        .catch((error) => {
          message.error("Có lỗi xảy ra khi lưu");
        });
    } else message.error("Vui lòng thêm tag");
  };

  render() {
    var initialValue = [...this.state.initialValue];
    return (
      <>
        <Card title={"Thiết lập tag"}>
          <Form key={this.state.form_key} onFinish={this.onFinish} autoComplete="off">
            <Form.List name="tags" initialValue={initialValue}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row>
                      <Col span={6}>
                        <Form.Item hidden {...restField} name={[name, "id"]}></Form.Item>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div>
                            <Form.Item
                              {...restField}
                              name={[name, "label"]}
                              rules={[
                                {
                                  required: true,
                                  type: "string",
                                  message: "Tên nhãn là trường bắt buộc!",
                                },
                              ]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              {...restField}
                              style={{ display: "flex" }}
                              name={[name, "color"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Màu nhãn là trường bắt buộc!",
                                },
                              ]}
                            >
                              <ColorPicker />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={18}>
                        <Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" style={{ width: 420 }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Thêm tag
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button style={{ marginLeft: 600 }} type="primary" htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </Form>

          {/* <p>Tag: <Tag>Plus</Tag> <Tag>Plus+</Tag> <Tag>Premium</Tag> </p> */}
        </Card>
      </>
    );
  }
}
