import React from "react";
import { Button, Switch, Row, Col } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeid } from "common/custom_field";
import PopupButtonSetting from "./popup_setting_button";
import { reorder } from "common/list";

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 0,
  width: "100%",
});

export default class AddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listButton: [],
    };
    this.PopupButtonSetting = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.value) this.setState({ listButton: this.props.value });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.value != prevProps.value) {
      this.setState({ listButton: this.props.value || [] });
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    var lst = [...this.state.listButton];
    var lst = reorder(lst, result.source.index, result.destination.index);
    this.setState({ listButton: lst }, () => {
      this.onChange();
    });
  };

  addButton = (data) => {
    var list = [...this.state.listButton];
    var index = list.findIndex((x) => x.id == data.id);
    if (index == -1) list.push(data);
    else {
      list[index] = data;
    }
    this.setState({ listButton: list }, () => {
      this.onChange();
    });
  };

  deleteButton = (id) => {
    var list = [...this.state.listButton];
    var index = list.findIndex((x) => x.id == id);
    list.splice(index, 1);
    this.setState({ listButton: list }, () => {
      this.onChange();
    });
  };

  onChange = () => {
    this.props.onChange(this.state.listButton);
  };

  render() {
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {this.state.listButton.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <div style={{ padding: 10, display: "flex", margin: "auto", border: "1px solid #dfdfdf" }}>
                          <div style={{ width: "5%", display: "flex", fontSize: 15, margin: "auto" }} {...provided.dragHandleProps}>
                            <i className="fas fa-arrows-alt-v"></i>
                          </div>
                          <div style={{ fontSize: 15, display: "flex", justifyContent: "center", width: "90%" }}>
                            <span>{item.title}</span>
                          </div>
                          <div
                            style={{ width: "5%", display: "flex", fontSize: 15, margin: "auto" }}
                            onClick={() => {
                              this.PopupButtonSetting.current.open(item);
                            }}
                          >
                            <i className="fas fa-cog"></i>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {this.state.listButton?.length < 3 && (
          <div style={{ display: "flex", justifyContent: "center", width: "100%", border: "2px dashed #dfdfdf", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
            <Button
              type="link"
              onClick={() => {
                this.PopupButtonSetting.current.open();
              }}
            >
              <i className="fas fa-plus"></i> &nbsp; Thêm nút
            </Button>
          </div>
        )}

        <PopupButtonSetting ref={this.PopupButtonSetting} addButton={this.addButton} delete={this.deleteButton}></PopupButtonSetting>
      </div>
    );
  }
}
