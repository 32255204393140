import React, { useEffect, useState, useRef } from "react";
import { Modal, message } from "antd";
import { Typography, Divider, Form, Input, Checkbox, Button, Row } from "antd";
import api from "api";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

function ModalChangePassword(props) {
  const [form] = Form.useForm();
  const [validateOldPassword, setValidateOldPassword] = useState();
  const oldPasswordInput = useRef(null);

  /**
   * Hàm submit đổi mật khẩu
   * @author NhatAnh
   */
  const onFinish = () => {
    var dataPost = form.getFieldsValue();
    api
      .post("master/user/changepassword", dataPost)
      .then((res) => {
        if (res) {
          message.success(res.message);
          localStorage.removeItem("token");
          props.handleCancel();
          setTimeout(() => {
            window.location.href = "/master/login";
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.message === "Mật khẩu cũ không đúng") {
          setValidateOldPassword(err.message);
          form.setFieldsValue({ oldPassword: "" });
          oldPasswordInput.current.focus();
        } else {
          message.error(err.message);
        }
      });
  };

  const onFinishFailed = () => {
    if (form.getFieldError("oldPassword")) {
      setValidateOldPassword(null);
    }
  };

  const oldPasswordChange = () => {
    setValidateOldPassword(null);
  };

  const handleCancel = () => {
    setValidateOldPassword(null);
    props.handleCancel();
  };

  useEffect(() => {
    if (props.isModalOpen === true) {
      form.resetFields();
    }
  }, [props.isModalOpen]);

  return (
    <>
      <Modal
        title={
          <>
            <Text style={{ fontSize: 16 }}>Đổi mật khẩu tài khoản</Text>
            <Divider style={{ margin: "16px 0" }} />
          </>
        }
        onCancel={handleCancel}
        open={props.isModalOpen}
        // footer={null}
        footer={[
          <>
            <Divider style={{ margin: "16px 0" }} />
            <Form form={form} name="change_password" layout="horizontal" autoComplete="off" onFinishFailed={onFinishFailed} onFinish={onFinish}>
              <Form.Item style={{ margin: "8px 0" }}>
                <Button onClick={handleCancel}>Thoát</Button>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Form.Item>
            </Form>
          </>,
        ]}
      >
        <Form form={form} name="change_password" layout="vertical" autoComplete="on" onFinishFailed={onFinishFailed} onFinish={onFinish}>
          <Form.Item name="oldPassword" style={{ padding: "8px 0" }} rules={[{ required: true, message: "Vui lòng nhập mật khẩu cũ" }]}>
            <Input.Password ref={oldPasswordInput} status={validateOldPassword ? "error" : null} placeholder="Mật khẩu cũ" onChange={oldPasswordChange} />
          </Form.Item>
          {validateOldPassword ? <Text style={{ color: "red", position: "absolute", top: 117 }}>{validateOldPassword}</Text> : null}
          <Form.Item name="newPassword" style={{ padding: "8px 0" }} rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}>
            <Input.Password placeholder="Mật khẩu mới" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            style={{ padding: "8px 0" }}
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu" },
              {
                validator(_, value) {
                  if (!value || value === form.getFieldsValue().newPassword) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Mật khẩu nhập lại phải giống mật khẩu mới");
                },
              },
            ]}
          >
            <Input.Password placeholder="Nhập lại mật khẩu mới" />
          </Form.Item>
          {/* <Divider style={{ margin: "16px 0" }} /> */}
          {/* <Form.Item style={{ padding: 0 }}>
            <Row justify="end" align="bottom">
              <Button onClick={handleCancel}>Thoát</Button>
              <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
                Lưu
              </Button>
            </Row>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default ModalChangePassword;
