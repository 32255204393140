import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Col, DatePicker, Input, Select, Empty } from "antd";
import api from "api";
import NamePicker from "../picker/name_picker";
import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import DateTimePicker from "components/picker/datetime_picker/datetime_picker";
import { toNoUnicode } from "common/text";
import CurrencyPicker from "components/picker/currency_picker";
import VoucherChild from "./childvoucher";
import AppPicker from "./apppicker";

const { Option } = Select
export default class VoucherConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "money",
            is_max_value_discount: false,
            has_child: false,
            voucherParent: "",
            lstChildVoucherNew: [],
            lstChildVoucher: [],
            show: false,
            isLimitTime: true,
            voucher_type: "discount",
            lstApp: []
        }
        this.form = React.createRef();
    }

    componentDidMount = () => {
        this.getCurrency();
    }

    getCurrency = () => {
        api.post("voucher/getcurrency").then(res => {
            this.setState({ currency: res }, () => { this.getApp() });
        })
    }

    getApp = () => {
        api.post("shop/getinstalledapp").then(res => {
            var latapp=res.filter(x=>x.category==="ERP"||x.category==="CRM")
            this.setState({ lstApp: latapp }, () => { this.getData() });

        })
    }

    getData = () => {
        if (this.props.match.params.id == 0) {
            var data = {
                id: "",
                voucher_code: "",
                name: "Voucher khuyến mãi",
                voucher_type: "discount",
                time:
                {
                    start: moment(),
                    end: moment().add(7, "days")
                },
                has_child: false,
                is_limittime: true,
                discount: {
                    type: "money",
                    max_value_discount: 0,
                    max_use: 1,
                    min_value_order_apply: 0,
                },
                app_id: [],
            }
            this.setState({ show: true }, () => { this.form.current.setFieldsValue(data) });
        }
        else {
            api.post("voucher/getbyid", { id: this.props.match.params.id }).then(res => {
                var is_max_value_discount = false;
                if (res.voucher_type != "pointprogarm_prefix") {
                    if (res.discount.type === "percent" && res.discount.max_value_discount != 0) {
                        is_max_value_discount = true;
                    }
                    this.setState({ type: res.discount.type, is_max_value_discount: is_max_value_discount, has_child: res.has_child, voucherParent: res, isLimitTime: res.is_limittime, voucher_type: res.voucher_type, show: true }, () => { this.form.current.setFieldsValue(res) })
                }
                else {
                    this.setState({ is_max_value_discount: is_max_value_discount, has_child: true, voucherParent: res, isLimitTime: res.is_limittime, show: true }, () => { this.form.current.setFieldsValue(res) })

                }

            })
        }
    }

    onTypeChange = (value) => {
        if (value === "money") {
            this.form.current.setFieldsValue({ discount: { max_value_discount: 0 } })
        }
        this.setState({ type: value })
    }
    onRadioChange = (value) => {
        this.setState({ is_max_value_discount: value });
    }

    validate = () => {
        var data = this.form.current.getFieldValue();
        if (data.time.start >= data.time.end) {
            var start = moment(data.time.start);
            this.form.current.setFieldsValue({ time: { start: start, end: data.time.start.add(1, 'days') } });
        }
    }
    onVoucherTimesChange = (value) => {
        if (value === true) {
            this.form.current.setFieldsValue({ discount: { max_use: 1 } });
        }
        this.setState({ has_child: value })
    }

    onIDChange = (value) => {
        var data = toNoUnicode(value.toUpperCase());
        this.form.current.setFieldsValue({ voucher_code: data });
    }

    onLimitTimeChange = (value) => {
        if (!value) {
            this.form.current.setFieldsValue({ time: { start: "", end: "" } });
        }
        else {

            this.form.current.setFieldsValue({
                time:
                {
                    start: moment(),
                    end: moment().add(7, "days")
                }
            });
        }
        this.setState({ isLimitTime: value });
    }

    voucherTypeChange = (value) => {
        this.setState({ voucher_type: value })
    }

    saveVoucher = (values) => {
        if (!values.discount.min_value_order_apply) {
            values.discount.min_value_order_apply = 0
        }
        if (!values.discount.max_value_discount) {
            values.discount.max_value_discount = 0
        }
        if (!values.discount.max_use) {
            values.discount.max_use = 1
        }
        if (this.props.match.params.id == 0) {

            values.list_voucher = [];
        }

        api.post("voucher/save", { data: values }).then(res => {
            message.success('Lưu thành công')
            var href = "/voucher/voucherconfig/" + res.id;
            if (!window.location.href.includes(href))
                window.location.href = href;
        }).catch(err => {
            if (err.code == 261) { message.error("Mã voucher đã tồn tại!"); }
            else {
                message.error('Có lỗi xảy ra khi lưu')
                console.log('save err', err)
            }

        })
    }



    render() {
        return (
            <>
                {
                    this.state.show &&
                    <div>
                        <Form
                            name="voucherconfig"
                            ref={this.form}
                            layout="vertical"
                            onFinish={this.saveVoucher}
                        >
                            <div style={{ minHeight: 60 }}>
                                <Form.Item name={'name'} rules={[{ type: 'string' }]}>
                                    <NamePicker></NamePicker>
                                </Form.Item>
                            </div>
                            <Form.Item name={'id'} hidden>
                                <Input></Input>
                            </Form.Item>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "50%" }}>
                                    <div style={{ paddingRight: 6 }}>

                                        <div>
                                            <div>
                                                <Card title={<span style={{ fontSize: 21 }}>Thông tin cơ bản</span>}>
                                                    <Form.Item
                                                        label="Mã voucher"
                                                        name="voucher_code"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "bạn chưa nhập voucher_code"
                                                            }
                                                        ]}
                                                    >
                                                        <Input disabled={this.props.match.params.id == 0 ? false : true} placeholder="ABC" onChange={(e) => this.onIDChange(e.target.value)}></Input>
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="is_limittime"
                                                        valuePropName="checked"
                                                    >
                                                        <Checkbox checked={this.state.isLimit} onChange={(e) => this.onLimitTimeChange(e.target.checked)} disabled={this.state.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false}>Giới hạn thời gian</Checkbox>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Input.Group>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <Form.Item
                                                                    label="Bắt đầu"
                                                                    name={["time", "start"]}
                                                                    style={{ width: "49%" }}
                                                                    rules={this.state.isLimitTime && [{
                                                                        required: true,
                                                                        message: 'Bạn chưa chọn ngày bắt đầu!'
                                                                    }]}
                                                                >
                                                                    <DateTimePicker disabled={this.state.isLimitTime ? false : true} onChange={() => { this.validate() }} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Kết thúc"
                                                                    name={["time", "end"]}
                                                                    style={{ width: "49%" }}
                                                                    rules={this.state.isLimitTime && [{
                                                                        required: true,
                                                                        message: 'Bạn chưa chọn ngày kết thúc!'
                                                                    }]}
                                                                >
                                                                    <DateTimePicker disabled={this.state.isLimitTime ? false : true} onChange={() => { this.validate() }} />
                                                                </Form.Item>
                                                            </div>

                                                        </Input.Group>
                                                    </Form.Item>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <FormItem
                                                            label="Loại voucher"
                                                            name="voucher_type"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Bạn chưa chọn loại voucher"
                                                                }
                                                            ]}
                                                            style={{ width: "49%" }}
                                                        >
                                                            <Select disabled={this.state.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false} onChange={(value) => { this.voucherTypeChange(value) }}>
                                                                <Option value="discount">Giảm  giá</Option>
                                                                <Option value="freeship">Free ship</Option>
                                                            </Select>
                                                        </FormItem>
                                                        <Form.Item
                                                            name="has_child"
                                                            label="Hình thức"
                                                            style={{ width: "49%" }}
                                                        >
                                                            <Radio.Group onChange={(e) => { this.onVoucherTimesChange(e.target.value) }} disabled={this.props.match.params.id == 0 ? false : true}>
                                                                <Radio value={false}>Voucher dùng nhiều lần</Radio>
                                                                <Radio value={true}>Voucher cá nhân hóa</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div hidden={this.state.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false} style={{ marginTop: 10 }}>
                                                <Card title="Thiết lập giảm giá">
                                                    <div >
                                                        <Form.Item>
                                                            <Input.Group>

                                                                {
                                                                    this.state.voucher_type === "discount" ?
                                                                        <>
                                                                            <div style={{ marginBottom: 8 }}>
                                                                                <span >Loại voucher | mức giảm</span>
                                                                            </div>
                                                                            <div style={{ display: "flex" }}>
                                                                                <Form.Item
                                                                                    name={["discount", "type"]}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: "Bạn chưa chọn loại voucher"
                                                                                        }
                                                                                    ]}
                                                                                    style={{ width: "20%" }}
                                                                                >
                                                                                    <Select style={{ width: "100%" }} onChange={(value) => { this.onTypeChange(value) }}>
                                                                                        <Option value="money">Theo số tiền</Option>
                                                                                        <Option value="percent">Theo phần trăm</Option>
                                                                                    </Select>
                                                                                </Form.Item>
                                                                                <Form.Item
                                                                                    name={["discount", "value"]}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: "Bạn chưa nhập giá trị voucher!"
                                                                                        }
                                                                                    ]}
                                                                                    style={{ width: "80%" }}
                                                                                >
                                                                                    <CurrencyPicker type={this.state.type} currency={this.state.currency} width={90} />
                                                                                </Form.Item>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <Form.Item
                                                                            name={["discount", "value"]}
                                                                            label="Giá trị"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Bạn chưa nhập giá trị"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <CurrencyPicker type={"money"} currency={this.state.currency} width={92} />
                                                                        </Form.Item>
                                                                }


                                                                <div hidden={this.state.type === "percent" ? false : true}>
                                                                    <div style={{ marginBottom: 8 }}>
                                                                        <span >Giới hạn giảm giá</span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 8 }}>
                                                                        <Radio.Group onChange={(e) => { this.onRadioChange(e.target.value) }} value={this.state.is_max_value_discount}>
                                                                            <Radio value={false}>Không giới hạn</Radio>
                                                                            <Radio value={true}>Giới hạn</Radio>
                                                                        </Radio.Group>
                                                                    </div>

                                                                    <Form.Item
                                                                        name={["discount", "max_value_discount"]}
                                                                        hidden={!this.state.is_max_value_discount}
                                                                    >
                                                                        <CurrencyPicker type={"money"} currency={this.state.currency} width={92} />
                                                                    </Form.Item>
                                                                </div>

                                                                <Form.Item
                                                                    label="Giá trị tối thiểu đơn hàng"
                                                                    name={["discount", "min_value_order_apply"]}
                                                                >
                                                                    <InputNumber min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} step={1000} style={{ width: "100%" }}></InputNumber>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Số lượt dùng tối đa"
                                                                    name={["discount", "max_use"]}
                                                                >
                                                                    <InputNumber disabled={this.state.has_child === true ? true : false} min={1} step={1} style={{ width: "100%" }}></InputNumber>
                                                                </Form.Item>
                                                            </Input.Group>
                                                        </Form.Item>
                                                    </div>


                                                </Card>
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                                <Card title="Phương thức">

                                                   
                                                    <Form.Item
                                                        name="app_id"
                                                        label="App"
                                                    >
                                                        <AppPicker lstApp={this.state.lstApp} />
                                                    </Form.Item>

                                                </Card>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {
                                    this.props.match.params.id != 0 && this.state.has_child ?
                                        <VoucherChild voucherParent={this.state.voucherParent} id={this.props.match.params.id} />
                                        :

                                        <Card title={<span style={{ fontSize: 21 }}>Danh sách voucher</span>} style={{ paddingLeft: 6, width: "50%", }} bodyStyle={{ display: "flex", height: "100%" }}>

                                            <div style={{ margin: "auto" }}>
                                                <Empty />
                                            </div>

                                        </Card>
                                }


                            </div>
                            <Row hidden={this.state.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false}>
                                <Card size="small" style={{ width: "100%", marginTop: 10, height: 60 }}>
                                    <Button type="primary" style={{ position: "absolute", right: 20 }} htmlType="submit">
                                        Lưu
                                    </Button>
                                </Card>
                            </Row>
                        </Form>
                    </div>
                }

            </>
        )
    }
}