import { memo, useState, useEffect } from "react";
import { Row, Col, Statistic } from "antd";
import APIHelper from "api";
import ATNDUpload from "./upload";
import { LikeOutlined } from "@ant-design/icons";
import numeral from "numeral"


const InformationAccount = ({ configOaId }) => {
  const [quota, setQuota] = useState({});
  const [level, setLevel] = useState("");
  const [oa7day, setOA7Day] = useState("");
  const [oacurrent, setOACurrent] = useState("");
  const [totalamountspent, setTotalAmountSpent] = useState("");
  const QualityInformationByLevel = {
    HIGH: "Tốt",
    MEDIUM: "Trung Bình",
    LOW: "Kém",
    UNDEFINED: "Không Có",
  };
  const LevelList = {
    OTP: 0,
    IN_TRANSACTION: 1,
    POST_TRANSACTION: 2,
    ACCOUNT_UPDATE: 3,
    GENERAL_UPDATE: 4,
    FOLLOW_UP: 5,
  };
  const getMessageOfTheDay = () => {
    APIHelper.post("app/zalov2/config/getznsquota", { oa_id: configOaId })
      .then((res) => {
        setQuota(res);
      })
      .catch((err) => {
        setQuota({});
      });
  };
  const getLevelAccount = () => {
    APIHelper.post("app/zalov2/config/getznscontenttype", { oa_id: configOaId })
      .then((res) => {
        setLevel(LevelList[res[res.length - 1]]);
      })
      .catch((err) => {
        setLevel(LevelList["OTP"]);
      });
  };

  const getQualityInformation = () => {
    APIHelper.post("app/zalov2/config/getznsqualityinformation", { oa_id: configOaId })
      .then((res) => {
        setOA7Day(QualityInformationByLevel[res.oa7dayQuality]);
        setOACurrent(QualityInformationByLevel[res.oaCurrentQuality]);
      })
      .catch((err) => {
        setOA7Day(QualityInformationByLevel["UNDEFINED"]);
        setOACurrent(QualityInformationByLevel["UNDEFINED"]);
      });
  };

  const getTotalAmountSpent = () => {
    APIHelper.post("sendhistory/totalamountspentofzalo", { oa_id: configOaId,status:"succeeded" })
      .then((res) => {
        setTotalAmountSpent(numeral(res.sumspent).format("0,0"));
      })
      .catch((err) => {
        setTotalAmountSpent("0");
      });
  };
  //   const col_vertical = <Col className="InformationAccount_col_vertical"></Col>;

  useEffect(() => {
    getMessageOfTheDay();
    getLevelAccount();
    getQualityInformation();
    getTotalAmountSpent();
  }, [configOaId]);
  return (
    <div style={{ padding: "0 16px" }}>
      <Row align="middle" style={{ paddingBottom: 16 }}>
        <Col style={{ borderRight: "1px solid #CAC4D0", width: "20%" }}>
          <Statistic title="Tin gửi trong ngày" value={`${quota?.dailyQuota - quota?.remainingQuota || 0}/${quota?.dailyQuota || 500}`} />
        </Col>
        <Col style={{ borderRight: "1px solid #CAC4D0", width: "20%", padding: "0 16px" }}>
          <Statistic title="Cấp độ" value={level} />
        </Col>
        <Col style={{ borderRight: "1px solid #CAC4D0", width: "20%", padding: "0 16px" }}>
          <Statistic title="Chất Lượng ZNS 7 ngày" value={`${oa7day}`} />
        </Col>
        <Col style={{ borderRight: "1px solid #CAC4D0", width: "20%", padding: "0 16px" }}>
          <Statistic title="Chất Lượng ZNS" value={`${oacurrent}`} />
        </Col>
        <Col style={{ width: "20%", padding: "0 16px" }}>
          <Statistic title="Số tiền đã chi tiêu" value={totalamountspent} />
        </Col>
      </Row>
      {/* <div className="content_zalov2" > */}
      {/* <Row gutter={[20, 16]} className="InformationAccount_row" justify={"space-between"}>
                <Col span={4} >
                    <Statistic title="Tin gửi trong ngày" value={quota?.dailyQuota-quota?.remainingQuota||0} suffix={`/${quota?.dailyQuota||500}`}/>
                </Col>
                {col_vertical}
                <Col  span={4}>
                    <Statistic title="Cấp độ" value={level} />
                </Col>
                {col_vertical}
                <Col span={4}>
                    <Statistic title="Chất Lượng ZNS 7 ngày" value={`${oa7day}`} />
                </Col>
                {col_vertical}
                <Col  span={4}>
                    <Statistic title="Chất Lượng ZNS" value={`${oacurrent}`} />
                </Col>
                {col_vertical}
                <Col span={4}>
                    <Statistic title="Số tiền đã chi tiêu" value={totalamountspent}/>
                </Col>
            </Row> */}
      <ATNDUpload></ATNDUpload>
      {/* </div> */}
    </div>
  );
};
export default memo(InformationAccount);
