import { Button, Card, Col, Popconfirm, Row, Skeleton, Space, Typography, message } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";
import AddNewConnectModal from "./AddNewConnectModal";

const { Text, Title } = Typography;
function ConnectTab({ isConnected, setIsConnected, isActive, setIsActive, reload }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configData, setConfigData] = useState();
  // const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setIsLoading(true);
      getConfig();
    }
  }, [isModalOpen]);

  // useEffect(() => {
  //   if (configData) {
  //     setIsActive(true);
  //   }
  // }, [configData]);

  const openAddConnect = () => {
    setIsModalOpen(true);
  };

  const getConfig = () => {
    api
      .post("app/mshopkeeper/config/getconfig")
      .then((res) => {
        if (res.AppID) {
          setIsConnected(true);
          setConfigData(res);
          setIsLoading(false);
        } else {
          setIsConnected(false);
          setConfigData();
          setIsLoading(false);
          setIsActive(false);
        }
      })
      .catch((err) => {
        setIsConnected(false);
        setConfigData();
        setIsLoading(false);
        setIsActive(false);
        console.log(err);
      });
  };

  const deleteLink = (id) => {
    setIsLoading(true);
    api
      .post("app/mshopkeeper/config/deleteconfig")
      .then((res) => {
        getConfig();
      })
      .catch((err) => {
        message.error("Xoá thất bại");
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton.Button style={{ height: "calc(100vh - 288px)" }} active={true} shape={"square"} block={true} />
        </>
      ) : (
        <>
          {configData ? (
            <Card
              style={{ width: "50%" }}
              title={configData.CompanyCode}
              extra={
                isActive ? (
                  <Space>
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52C41A" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Hoạt động</Text>
                  </Space>
                ) : (
                  <Space>
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Không hoạt động</Text>
                  </Space>
                )
              }
            >
              <Space direction="vertical" size={8}>
                <Row>
                  <Space size={4}>
                    <Text style={{ fontWeight: 600 }}>Tên kết nối: </Text>
                    <Text type="secondary">{configData.Domain}</Text>
                  </Space>
                </Row>
                <Row>
                  <Space size={4}>
                    <Text style={{ fontWeight: 600 }}>Mã bảo mật: </Text>
                    <Text type="secondary">{configData.Secret}</Text>
                  </Space>
                </Row>
                <Row>
                  <Space size={4}>
                    <Text style={{ fontWeight: 600 }}>Môi trường: </Text>
                    <Text type="secondary">{configData.Environment}</Text>
                  </Space>
                </Row>
              </Space>
              <Row justify={"end"}>
                <Popconfirm
                  title={<Text>Bạn có muốn xoá liên kết này?</Text>}
                  onConfirm={() => {
                    deleteLink();
                  }}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button type="link" style={{ color: "red", padding: 4 }}>
                    Xoá liên kết
                  </Button>
                </Popconfirm>
              </Row>
            </Card>
          ) : (
            <>
              <Button type="primary" icon={<PlusCircleFilled />} onClick={openAddConnect}>
                Thêm kết nối
              </Button>
              <AddNewConnectModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            </>
          )}
          {/* <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} /> */}
        </>
      )}
    </>
  );
}

export default ConnectTab;
