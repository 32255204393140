import React from "react";
import { Layout, Col, Row, Card, Breadcrumb, Menu, Avatar, Dropdown, Typography, Divider } from "antd";
import { MailOutlined, BellOutlined, ShopOutlined, DollarOutlined, UserOutlined, KeyOutlined, LogoutOutlined } from "@ant-design/icons";
import ParentMenu from "../menu/menu.jsx";
import SubMenu from "../menu/submenu.jsx";
import "../admin.css";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { server } from "../../../config/index";
import api from "api";

//master
//=====Begin=======
import ShopManagement from "components/master/shopManagement";
import PaymentManagemnt from "components/master/paymentManagement";
import UserManagement from "components/master/userManagement/index.jsx";
import ModalChangePassword from "components/master/login/changePassword.jsx";
import OaManagement from "components/master/oa_management/index.jsx";

const { Header, Content } = Layout;
const { Text } = Typography;

class AppMaster extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);

    this.max_span = 24;
    this.content_small_span = 21;
    this.content_large_span = 23;
    this.submenu_large_span = 2.5;
    this.submenu_small_span = 1.25;
    var token = window.localStorage.getItem("token");
    this.state = {
      check_token: false,
      collapsed: true,
      child_collapsed: urlParams.get("child_collapsed"),
      card_background: "transparent",
      menu: [
        {
          key: "shop",
          name: "Quản lý công ty",
          icon: <ShopOutlined style={{ color: "#FFF", marginRight: 5 }} />,
          path: "/master/shop-management",
          child: [],
        },
        {
          key: "payment",
          name: "Quản lý thanh toán",
          icon: <DollarOutlined style={{ color: "#FFF", marginRight: 5 }} />,
          path: "/master/payment-management",
          child: [],
        },
        {
          key: "user",
          name: "Quản lý nhân viên",
          icon: <UserOutlined style={{ color: "#FFF", marginRight: 5 }} />,
          path: "/master/user-management",
          child: [],
        },
        {
          key: "Task",
          name: "Task",
          icon: (
            <a target="_blank" href={`${server}/getauth?token=${token}`}>
              <i style={{ color: "#FFF", marginRight: 5 }} className="fas fa-tasks"></i>
            </a>
          ),
          path: "",
          child: [],
        },

        {
          key: "oa",
          name: "Quản lý zalo OA",
          icon: <i style={{ color: "white", marginRight: 5 }} className="fab fa-searchengin"></i>,
          path: "/master/oa-management",
          child: [],
        },
      ],
      current_parent_key: "",
      current_child_key: "",
      parent_menu_visible: false,
      sub_menu_visible: false,
      submenu_span: this.submenu_small_span,
      sub_span: this.max_span,
      default_key: "shop",
      data: {},
      loadData: false,
      isModalOpen: false,
    };

    this.ParentMenu = React.createRef();
    this.SubMenu = React.createRef();
    this.Router = React.createRef();
  }

  componentDidMount = async () => {
    await this.getCurrentUser();

    if (this.state.child_collapsed)
      this.setState({
        submenu_span: this.submenu_large_span,
      });
    this.handlePath();
  };

  getChildMenu = (current_parent_key) => {
    var menu = this.state.menu;
    var current_parent = menu.find((z) => z.key == current_parent_key);
    var current_child = [];
    if (current_parent != null) current_child = current_parent.child;
    var child = current_child;
    return { current_parent, child };
  };

  handlePath = () => {
    var path = window.location.pathname;
    var keys = path.split("/");
    var parent_key = this.state.default_key;
    var child_key = "";
    if (path != "/" && path != "") {
      if (keys != null && keys.length != 0) {
        if (keys[1] != "" && keys[1] != null) parent_key = keys[1];

        if (keys[2] != "" && keys[2] != null) {
          child_key = keys[2];
          var results = this.getChildMenu(parent_key);
          if (results.child != null && results.child.length != 0) {
            this.setState(
              {
                sub_menu_visible: true,
                current_child_key: child_key,
              },
              () => this.setSubMenuWidth()
            );
          }
        } else {
          this.setState({ sub_menu_visible: true });
        }
      }
    }

    this.setState({ current_parent_key: parent_key });
  };

  navigate = (path) => {
    //this.Router.current.history.push(path);
    this.props.navigate(path);
    this.handlePath();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  toggleChildCollapsed = () => {
    this.setState({
      child_collapsed: !this.state.child_collapsed,
    });
  };

  setSubMenuWidth = () => {
    var submenu_span = this.state.submenu_span;
    var sub_span = this.max_span;
    if (submenu_span == this.submenu_large_span) {
      submenu_span = this.submenu_small_span;
    } else {
      submenu_span = this.submenu_large_span;
    }

    if (this.state.sub_menu_visible) {
      if (submenu_span == this.submenu_large_span) {
        sub_span = this.content_small_span;
      } else {
        sub_span = this.content_large_span;
      }
    }
    this.setState({
      submenu_span: submenu_span,
      sub_span: sub_span,
    });
  };

  setParentKey = (parent_key) => {
    this.setState({
      current_parent_key: parent_key,
      sub_span: this.content_small_span,
    });
    var results = this.ParentMenu.current.getChildMenu(parent_key);
    if (results.child == null || results.child.length == 0) {
      this.setState(
        {
          sub_menu_visible: false,
        },
        () => {
          this.setSubMenuWidth();
        }
      );
      // window.open(results.current_parent.path, '_self');
      this.navigate(results.current_parent.path);
      this.handlePath();
    } else {
      // window.open(results.child[0].path, '_self');
      this.setState({
        current_child_key: results.child[0].key,
      });
      this.navigate(results.child[0].path);
      this.handlePath();
    }
  };

  getCurrentUser = () => {
    api
      .get("master/user/me")
      .then((res) => {
        this.setState({ check_token: true });
      })
      .catch((err) => {
        window.location.href = "/master/login";
      });
  };
  renderBreadcums = () => {
    return null;
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  getData = (avatar, first_name, last_name) => {
    var data = this.state.data;
    data.avatar = avatar;
    data.first_name = first_name;
    data.last_name = last_name;
    this.setState({ data: data });
  };

  navigateToLogin = () => {
    this.props.navigate("/master/login");
  };

  render() {
    if (!this.state.check_token) {
      return (
        <div>
          <p>..........loading..........</p>
        </div>
      );
    }

    var current_child_key = this.state.current_child_key;

    const items = [
      {
        key: "profile",
        label: (
          <>
            <Row>
              <Col span={24}>
                <UserOutlined style={{ fontSize: 16 }} />
                <Text style={{ padding: 8 }}>{localStorage.getItem("name")}</Text>
              </Col>

              {/* <Divider style={{ margin: "8px 0 0 0" }} /> */}
            </Row>
            <Row>
              <Col span={24}>
                <Text strong>{localStorage.getItem("role")}</Text>
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0 0 0" }} />
          </>
        ),
        child: [],
      },
      {
        key: "change-password",
        icon: <KeyOutlined style={{ fontSize: 16 }} />,
        label: "Đổi mật khẩu",
        child: [],
      },
      {
        key: "master-logout",
        icon: <LogoutOutlined style={{ fontSize: 16 }} />,
        label: "Đăng xuất",
        child: [],
      },
    ];

    const menu = [
      {
        key: 1,
        label: (
          <Dropdown
            trigger="click"
            arrow
            menu={{
              items: items,

              onClick: (e) => {
                // if (e.key == "login") {
                //   this.logoutClick();
                // } else this.props.navigate(e.key);
                if (e.key === "change-password") {
                  this.setState({
                    isModalOpen: true,
                  });
                } else if (e.key === "master-logout") {
                  localStorage.removeItem("token");
                  window.location.href = "/master/login";
                }
              },
            }}
          >
            {localStorage.getItem("user_name") && (
              <Avatar
                style={{
                  backgroundColor: "green",
                  verticalAlign: "middle",
                }}
              >
                {localStorage.getItem("user_name").slice(0, 1).toUpperCase()}
              </Avatar>
            )}
          </Dropdown>
        ),
      },
    ];
    return (
      <>
        <div className="main-layout">
          <Layout>
            <ParentMenu
              ref={this.ParentMenu}
              open={this.state.parent_menu_visible}
              menu={this.state.menu}
              current_parent_key={this.state.current_parent_key}
              setParentKey={this.setParentKey}
            ></ParentMenu>
            <Layout style={{ marginLeft: "45px" }} theme="light">
              <Content className="site-layout-background">
                <Header className="header-menu">
                  {/* <Menu mode="horizontal">
                  <Menu.Item key="1" icon={<BellOutlined style={{ verticalAlign: "middle", fontSize: "20px", marginLeft: 10 }} />}></Menu.Item>
                  <Menu.Item key="3" icon={<MailOutlined style={{ verticalAlign: "middle", fontSize: "20px", marginLeft: 10 }} />}></Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={this.state.avatar === "" ? <Avatar style={{ backgroundColor: "green", verticalAlign: "middle" }}>A</Avatar> : <Avatar src={this.state.data.avatar}></Avatar>}
                  >
                    {this.state.data.last_name} {this.state.data.first_name}
                    <a href="http://localhost:3000/test/profile"></a>
                  </Menu.Item>
                </Menu> */}
                  {localStorage.getItem("user_name") && <Menu style={{ display: "block" }} items={menu} mode="horizontal"></Menu>}
                </Header>
                <Row wrap={false}>
                  {this.state.sub_menu_visible && this.state.current_child_key != "" ? (
                    <Col flex={"none"}>
                      <SubMenu
                        ref={this.SubMenu}
                        open={this.state.sub_menu_visible}
                        collapsed={this.state.child_collapsed}
                        menu={this.state.menu}
                        setSubMenuWidth={this.setSubMenuWidth}
                        current_child_key={current_child_key}
                        current_parent_key={this.state.current_parent_key}
                        toggleChildCollapsed={this.toggleChildCollapsed}
                        navigate={this.navigate}
                      ></SubMenu>
                    </Col>
                  ) : (
                    <div></div>
                  )}
                  <Col flex={"auto"}>
                    <div
                      style={{
                        minHeight: "calc(100vh - 64px)",
                        marginTop: -1,
                        marginLeft: "0px",
                        marginRight: "0px",
                        padding: "16px",
                        background: "none",
                      }}
                      // bodyStyle={{
                      //     padding: 0
                      // }}
                    >
                      <Routes>
                        <Route path="/master" Component={ShopManagement} />
                        <Route path="/master/shop-management" Component={ShopManagement} />
                        <Route path="/master/payment-management" Component={PaymentManagemnt} />
                        <Route path="/master/user-management" Component={UserManagement} />
                        <Route path="/master/oa-management" Component={OaManagement} />
                      </Routes>
                    </div>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
        </div>
        <ModalChangePassword navigate={this.navigateToLogin} isModalOpen={this.state.isModalOpen} handleCancel={this.handleCancel}></ModalChangePassword>
      </>
    );
  }
}

const AppMasterHOC = () => {
  const navigate = useNavigate();

  return <AppMaster navigate={navigate}></AppMaster>;
};

export default AppMasterHOC;
