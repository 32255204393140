import { memo, useState, useEffect, createRef, useRef } from "react";
import { Button, Row, Col, Table, Badge, Input, Space, DatePicker, Select, Popover, Typography } from 'antd';
import { FileExcelOutlined } from "@ant-design/icons"
import AntTable from "components/common/AntTable";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import numeral from "numeral";


const { Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Paragraph style={{ width: "400px" }} ellipsis={false}>
      {isReadMore ? text?.slice(0, 175) || null : text}
      {text?.length > 175 &&
        <span onClick={toggleReadMore} style={{ color: "blue", cursor: "pointer" }}>
          {isReadMore ? "...read more" : " show less"}
        </span>}
    </Paragraph>
    // <p className="SendingHistory__Popover-text">
    //     {isReadMore ? text?.slice(0, 175)||null : text}
    //     {text?.length > 175 &&
    //     <span onClick={toggleReadMore} className="SendingHistory__Popover-ReadOrHide">
    //         {isReadMore ? "...read more" : " show less"}
    //     </span>
    //     }
    // </p>
  );


};
const SendingHistory = ({ configOaId }) => {
  const Tb = useRef("tbSendHistory");
  const ErorrStatus = <Badge status={"error"} text="Thất bại" />;
  const SuccessStatus = <Badge status={"success"} text="Thành công" />;
  const [open, setOpen] = useState({});
  const handleOpenChange = (status, index) => {
    var newOpen = { ...open }
    newOpen[index] = status
    setOpen(newOpen);
  };
  const status = {
    succeeded: SuccessStatus,
    failed: ErorrStatus,
  }
  const [filter, setFilter] = useState({ oa_id: configOaId, app: "zalo", phone: "", status: "", create_date: {} })

  const total = () => {
    var dataSendingHistory = Tb.current?.state?.data || [];
    var total = 0;
    for (var i = 0; i < dataSendingHistory?.length; i++) {
      var price = dataSendingHistory[i].dataPost?.data?.zalo?.price;
      if (dataSendingHistory[i].result.status === "succeeded") {
        total += Number(price) ? parseInt(price) : 0;
      }
    }
    return total
  }

  const getColumn = () => {
    var columns = [
      {
        title: "Ngày",
        dataIndex: "create_on",
        key: "create_on",
        width: 90,
        render: (text, record, index) => {
          if (text != "" && text != null) {
            var date = new Date(text);
            date = moment(date);
            return <div>{date.format("DD/MM/YYYY hh:mm:ss")}</div>;
          } else return "";
        },
      },
      {
        title: "Zalo OA",
        dataIndex: "ZaloOA",
        key: "ZaloOA",
        width: 120
      },
      {
        title: "Số điện thoại",
        dataIndex: "phone",
        key: "phone",
        width: 120,
        render: (text, record, index) => {
          return (record?.dataPost?.data?.to?.phone || null)
        }
      },
      {
        title: "Loại",
        dataIndex: "type",
        key: "type",
        width: 120,
        render: (text, record, index) => {
          return (record?.source?.type || null)
        }
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        width: 120,
        align: "center",
        render: (text, record, index) => {
          if (record?.result?.status == null)
            return (null);
          if (record?.result?.status == "failed")
            return (
              <>
                <div >
                  <Popover
                    content={<ReadMore>{record?.result?.error?.message || null}</ReadMore>}
                    // content={<ReadMore> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi suscipit maxime veniam perspiciatis libero accusantium debitis ratione atque non quia cupiditate consequuntur enim dicta quod vero deleniti, officia animi sed.</ReadMore>}
                    title="Error"
                    trigger="click"
                    open={typeof open[index] == null ? false : open[index]}
                    onOpenChange={(status) => handleOpenChange(status, index)}>
                    {status["failed"]}
                  </Popover>
                </div>
              </>
            )
          else
            return (status["succeeded"])
        },
      },
      {
        title: () => {
          return (<>Cước <br /> {numeral(total()).format("0,0")}</>);
        },
        key: "price",
        dataIndex: "price",
        width: 120,
        align: "center",
        render: (text, record, index) => {
          // debugger
          if (record?.result?.status == "failed")
            return (0)
          return (numeral(record?.dataPost?.data?.zalo?.price).format("0,0") || 0)
        }
      },
    ];
    return columns;
  };

  const onchangeRangePicker = (datejs, datestring) => {
    var data = { ...filter };
    if (datestring[0] == "" || datestring[1] == "")
      data.create_date = {};
    else
      data.create_date = { start: moment.utc(datestring[0], "DD/MM/YYYY").format(), end: moment.utc(datestring[1], "DD/MM/YYYY").format() };
    setFilter(data);
  }

  const onchangePhone = (e) => {
    var data = { ...filter };
    data.phone = e.target.value;
    setFilter(data);
  }

  const onchangeStatus = (value) => {
    var data = { ...filter };
    data.status = value;
    setFilter(data);
  }

  const exportExcel = () => {
    var fileName = "excelSendingHistory" + moment().format("DD-MM-YYYY");
    var colExcel = [];
    var columns = [...getColumn()];
    columns.forEach((d) => {
      if (d.children) {
        d.children.forEach((c) => {
          if (c.dataIndex) {
            var cc = {};
            cc.title = d.title + " " + c.title;
            cc.dataIndex = c.dataIndex;
            colExcel.push(cc);
          }
        });
      } else {
        if (d.dataIndex) {
          if (typeof (d.title) == "string")
            colExcel.push(d);
          else {
            var copy_d = { ...d }
            copy_d.title = "Cước " + numeral(total()).format("0,0")
            colExcel.push(copy_d)
          }
        }
      }
    });
    var dataSendingHistory = Tb.current.state.data;
    var lst = dataSendingHistory.map((item) => {
      var o = {};
      colExcel.forEach((c) => {
        if (c.dataIndex == "price") {
          o[c.title] = numeral(item?.dataPost?.data?.zalo?.price).format("0,0") || "0,0";
          return
        }
        if (c.dataIndex == "status") {
          o[c.title] = item?.result?.status || null
          return
        }
        if (c.dataIndex == "type") {
          o[c.title] = item?.source?.type || null
          return
        }
        if (c.dataIndex == "phone") {
          o[c.title] = item?.dataPost?.data?.to?.phone || null
          return
        }
        o[c.title] = item[c.dataIndex]
      });
      return o;
    });
    const ws = XLSX.utils.json_to_sheet(lst);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dt = new Blob([excelBuffer], { type: ".xlsx" });
    FileSaver.saveAs(dt, fileName + ".xlsx");
  };

  useEffect(() => {
    if (configOaId != filter.oa_id) {
      setFilter({ oa_id: configOaId })
    }
  }, [configOaId])

  useEffect(() => {
    Tb.current.reload();
  }, [filter])

  return (
    <>
      <Row gutter={[16, 16]} justify={"space-between"} style={{ padding: "8px" }} >
        <Col span={7}>
          <Input size="large" placeholder="Tìm kiếm số điện thoại" onChange={onchangePhone} style={{ width: "100%" }} />
        </Col>
        <Col span={7}>
          <RangePicker placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} size="large" onChange={onchangeRangePicker} style={{ width: "100%" }} format='DD/MM/YYYY' />
        </Col>
        <Col span={7}>
          <Select
            size="large"
            placeholder="Trạng thái"
            showSearch
            onChange={onchangeStatus}
            style={{ width: "100%" }}
          >
            <Option value="all">Tất cả</Option>
            <Option value="succeeded">Thành công</Option>
            <Option value="failed">Thất bại</Option>
          </Select>
        </Col>
        <Col span={3}>
          <Button size="large" style={{ width: "100%" }} icon={<FileExcelOutlined />} onClick={exportExcel}>Xuất Excel</Button>
        </Col>
        {/* <Col span={7}>
                    <Space direction="vertical" size="middle" style={{width:"100%"}}>
                        <Space.Compact size="large" style={{width:"100%"}}>
                            <Input placeholder="Tìm kiếm số điện thoại" onChange={onchangePhone}  style={{width:"100%"}}/>
                        </Space.Compact>
                    </Space>
                </Col>
                <Col span={7}>
                    <Space direction="vertical" size="middle" style={{width:"100%"}}>
                        <Space.Compact size="large" style={{width:"100%"}}>
                            <RangePicker onChange={onchangeRangePicker} style={{width:"100%"}} />
                        </Space.Compact>
                    </Space>
                </Col>
                <Col span={7}>
                    <Space direction="vertical" size="middle" style={{width:"100%"}}>
                        <Space.Compact size="large" style={{width:"100%"}}>
                            <Select
                                placeholder="Trạng thái"
                                showSearch
                                onChange={onchangeStatus}
                                style={{ width: "100%"}}
                                >
                                <Option value="succeeded">Thành công</Option>
                                <Option value="failed">Thất bại</Option>
                                <Option  value="all">Tất cả</Option>
                            </Select>
                        </Space.Compact>
                    </Space>
                </Col>
                <Col span={3}>
                    <Space direction="vertical" size="middle" style={{width:"100%"}}>
                        <Space.Compact size="large" style={{width:"100%"}}>
                            <Button style={{width:"100%"}} icon={<FileExcelOutlined />} onClick={exportExcel}>Xuất Excel</Button>
                        </Space.Compact>
                    </Space>
                </Col> */}
      </Row>
      <div style={{ marginTop: "16px" }}>
        <AntTable
          size="small"
          columns={getColumn()}
          customSearch={filter}
          tableName={"tbCustomerSendHistory"}
          ajax="sendhistory/listzalo"
          ref={Tb}
        ></AntTable>
      </div>

    </>
  );
}
export default memo(SendingHistory)