import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Image, Row, Col, Typography, Divider } from "antd";
import { UserOutlined, MoneyCollectOutlined, KeyOutlined, LogoutOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import api from "api";
const { Text } = Typography;
function LoyaltyHeader(props) {
  const navigate = useNavigate;
  const [totalAmount, setTotalAmount] = useState(0);
  const itemSP = [
    {
      key: "https://zalo.me/pc",
      label: "Hỗ trợ Zalo",
      icon: <Image preview={false} style={{ verticalAlign: "middle", paddingRight: 8 }} src="/assets/images/zalo-logo.svg" />,
    },
    {
      key: "https://anydesk.com/downloads",
      label: "Hỗ trợ Anydesk",
      icon: <Image preview={false} style={{ verticalAlign: "middle", paddingRight: 8 }} src="/assets/images/anydesk-logo.svg" />,
    },
    {
      key: "https://www.ultraviewer.net/vi/download.html",
      label: "Hỗ trợ Ultraview",
      icon: <Image preview={false} style={{ verticalAlign: "middle", paddingRight: 8 }} src="/assets/images/ultraview-logo.svg" />,
    },
  ];
  const items = [
    {
      key: "profile",
      label: (
        <>
          <Row>
            <Col span={24}>
              <UserOutlined style={{ fontSize: 16 }} />
              <Text style={{ padding: 8 }}>{localStorage.getItem("name")}</Text>
            </Col>

            {/* <Divider style={{ margin: "8px 0 0 0" }} /> */}
          </Row>
          <Row>
            <Col span={24}>
              <Text strong>{localStorage.getItem("role")}</Text>
            </Col>
          </Row>
          <Divider style={{ margin: "8px 0 0 0" }} />
        </>
      ),
      child: [],
    },
    {
      key: "/payment",
      icon: <MoneyCollectOutlined style={{ fontSize: 16 }} />,
      label: "Nạp tài khoản",
      child: [],
    },
    {
      key: "/setting/profile",
      icon: <KeyOutlined style={{ fontSize: 16 }} />,
      label: "Đổi mật khẩu",
      child: [],
    },
    {
      key: "login",
      icon: <LogoutOutlined style={{ fontSize: 16 }} />,
      label: "Đăng xuất",

      child: [],
    },
  ];

  const getTotalAmount = () => {
    api
      .get("payment/gettotalamount")
      .then((res) => {
        // localStorage.setItem("total_amount", res);
        setTotalAmount(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTotalAmount();
  }, []);

  return (
    <Row justify="end" style={{ width: "100%", backgroundColor: "white", padding: "8px 16px" }}>
      <div style={{ marginRight: 8 }}>
        <Row align="middle" justify="center" width="100%">
          <Text style={{ fontSize: 12, lineHeight: "inherit" }}>{localStorage.getItem("shop_name")}</Text>
        </Row>
        <Row align="middle" justify="center" width="100%">
          <Text strong style={{ fontSize: 14, color: blue.primary, lineHeight: "inherit" }}>
            {numeral(totalAmount).format("0,0")}
          </Text>
        </Row>
      </div>
      <Dropdown
        trigger="click"
        arrow
        menu={{
          items,
          onClick: (e) => {
            if (e.key === "login") {
              props.logoutClick();
            } else if (e.key !== "profile") {
              props.navigate(e.key);
            }
          },
        }}
      >
        {props.userAvatar ? (
          <Avatar style={{ margin: "0 8px" }} src={props.userAvatar}></Avatar>
        ) : (
          <Avatar
            style={{
              backgroundColor: "green",
              verticalAlign: "middle",
              margin: "0 8px",
            }}
          >
            {localStorage.getItem("user_name").slice(0, 1).toUpperCase()}
          </Avatar>
        )}
      </Dropdown>
      <Dropdown
        trigger="click"
        arrow
        menu={{
          items: itemSP,
          onClick: (e) => {
            if (e.key.includes("http")) {
              window.location = e.key;
            } else this.props.navigate(e.key);
          },
        }}
      >
        <Avatar
          style={{
            backgroundColor: "#F5F5F5",
            padding: 4,
            marginLeft: 8,
          }}
          src="/assets/images/menu-header.svg"
        ></Avatar>
      </Dropdown>
    </Row>
  );
}

export default LoyaltyHeader;
