import React, { memo, useState, useEffect } from "react";
import { Button, Modal, Steps, Card, Row, Col, Image, Space, Typography, Badge, Select, theme } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../modal.css";
import api from "api";
import { blue } from "@ant-design/colors";
import DetailTransaction from "./DetailTransaction";
const { Title, Text } = Typography;
// import "../../assets/images";
// const { token } = theme.useToken();

const { Step } = Steps;
var numeral = require("numeral");
const { useToken } = theme;
function ModalPackageRenewal(props) {
  const { token } = useToken();
  const urlPriceList = "https://web.cubeloyalty.vn/bang-gia/";
  const [currentStep, setCurrentStep] = useState(props.mode === "add" ? 0 : 1);
  const [listBank, setListBank] = useState([]);
  const [selectedBank, setSelectedBank] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [listPlan, setListPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const numberOptionsMonth = 4;
  const gapBetweenMonths = 6;
  const [amounttotal, setAmountTotal] = useState(0);
  const optionExtend = [];
  for (let i = 1; i <= numberOptionsMonth; i++) {
    optionExtend.push({
      label: `${i * gapBetweenMonths} tháng`,
      value: i * gapBetweenMonths,
    });
  }
  const onchangeMonth = (value) => {
    setAmountTotal(value * selectedPlan.money);
    setSelectedMonth(value);
  };
  const handleOk = () => {
    saveSubscription(amounttotal);
  };
  const handleCancel = () => {
    props.reloadTable();
    props.setIsModalOpen(false);
  };
  const onclickDetail = () => {
    window.open(urlPriceList);
  };
  const nextStep = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };
  useEffect(() => {
    if (props.mode === "add") {
      if (listBank.length > 0) {
        setSelectedBank(listBank[0]);
      }
      setCurrentStep(0);
    } else {
      if (listBank.length > 0) {
        var find = listBank.find((item) => item.bank_name === props.record.bank);
        setSelectedBank(find);
      }
      setData(props.record);
      setCurrentStep(1);
    }
  }, [props.isModalOpen, listBank]);

  useEffect(() => {
    getListBank();
    getListPlans();
  }, []);
  /**
   * lấy list bank
   **/
  const getListBank = async () => {
    await api
      .get("bank/getbank")
      .then((res) => {
        setListBank(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * lấy list plans
   **/
  const getListPlans = async () => {
    await api
      .get("plan/getdata")
      .then((res) => {
        console.log(res);
        setListPlan(res);
      })
      .catch((err) => {
        setListPlan([]);
      });
  };

  const selectBank = (item) => {
    setSelectedBank(item);
  };
  const selectPlan = (item) => {
    setSelectedPlan(item);
    nextStep();
    setAmountTotal(gapBetweenMonths * item.money);
    setSelectedMonth(gapBetweenMonths);
  };
  /**
   * lưu saveSubscription
   * @param {object} amount số tiền
   **/
  const saveSubscription = async (amount) => {
    setIsLoading(true);
    var dataPost = {
      amount: amount,
      bank: selectedBank.bank_name,
      description: `${selectedPlan.name} ${selectedMonth} tháng`,
      duration: { value: selectedMonth, currency: "Tháng", real_value: selectedMonth },
      plan: { name: selectedPlan.name, id: selectedPlan.id },
    };
    await api
      .post("subscription/create_subscription", dataPost)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        wrapClassName="payment-modal"
        width={"72vw"}
        title="Tạo thanh toán gia hạn gói cước"
        open={props.isModalOpen}
        onCancel={handleCancel}
        centered={true}
        footer={[
          <Row justify="space-between">
            <Col>
              {currentStep === 1 && (
                <Button type="default" onClick={prevStep}>
                  Trở về
                </Button>
              )}
            </Col>
            <Col>
              {currentStep === 1 && (
                <Button type="primary" onClick={handleOk}>
                  Tiếp tục
                </Button>
              )}
              {currentStep === 2 && (
                <Button type="default" onClick={handleCancel}>
                  Đóng
                </Button>
              )}
            </Col>
          </Row>,
        ]}
      >
        <div style={stepsStyle}>
          <Steps size="small" current={currentStep} style={{ padding: "0 24px" }}>
            <Step key="step1" title="Chọn gói cước" />
            <Step key="step2" title="Chọn thời gian" icon={isLoading ? <LoadingOutlined /> : null} />
            <Step key="step3" title="Hướng dẫn thanh toán" />
          </Steps>
        </div>
        {currentStep === 0 && (
          <div style={contentStyleStep0}>
            <Row style={{ width: "100%" }}>
              {listPlan.map((item, index) => {
                var color = "";
                if (item.name === "Plus") color = token.colorSuccess;
                if (item.name === "Enterprise") color = token.colorWarning;
                if (item.name === "Trial") return <React.Fragment key={index}></React.Fragment>;
                var content = (
                  <Space
                    direction="vertical"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "100%",
                      width: "100%",
                      background: "#FFFFFF",
                      padding: "32px 8px 16px 8px",
                    }}
                  >
                    <Space direction="vertical" style={{ gap: 0, width: "100%", textAlign: "center", marginBottom: 16 }}>
                      <Title level={2} style={{ color: color }}>
                        {item.name}
                      </Title>

                      {item.name === "Enterprise" && (
                        <>
                          <Text style={{ fontSize: 10 }}>*Gói danh riêng cho Doanh nghiệp quy mô lớn</Text>
                          <Text style={{ fontSize: 10 }}>*Nhận báo giá tốt nhất từ chúng tôi</Text>
                        </>
                      )}
                      {item.name !== "Enterprise" && (
                        <>
                          <Title level={4}>{numeral(item.money).format("0,0")}</Title>
                          <Title level={5}>
                            {item.name === "Plus" ? "50.000" : "10.000"}
                            {" Khách hàng"}
                          </Title>
                          <Text style={{ fontSize: 10 }}>*Tặng 45 ngày khi mua 12 tháng</Text>
                        </>
                      )}
                      <Button type="link" style={{ width: "100%", color: color }} onClick={onclickDetail}>
                        Chi tiết gói cước
                      </Button>
                    </Space>
                    {item.name === "Enterprise" ? (
                      <Button size="large" type="primary" style={{ width: "100%", background: color }}>
                        Liên hệ
                      </Button>
                    ) : (
                      <Button size="large" type="primary" style={{ width: "100%", background: color }} onClick={() => selectPlan(item)}>
                        Chọn
                      </Button>
                    )}
                  </Space>
                );
                if (item.name === "Basic") content = <Badge.Ribbon text={<Text style={{ color: "#FFFFFF" }}>Phổ biến nhất</Text>}>{content}</Badge.Ribbon>;
                return (
                  <React.Fragment key={index}>
                    <Col span={24} style={{ padding: 8 }} sm={12} lg={8}>
                      {content}
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </div>
        )}
        {currentStep === 1 && (
          <div style={contentStyleStep1}>
            <Card style={{ width: "100%" }}>
              <Row>
                {listBank.length > 0 &&
                  listBank.map((item, index) => {
                    var src = `/assets/images/${item.bank_name.toLowerCase()}.svg`;
                    return (
                      <React.Fragment key={index}>
                        <Col span={24} style={{ padding: 4 }} sm={12} lg={8}>
                          <Card
                            style={{ padding: 8, borderColor: item.id === selectedBank?.id ? blue.primary : null }}
                            hoverable
                            className="card-bank"
                            onClick={() => {
                              selectBank(item);
                            }}
                          >
                            <Image preview={false} width="100%" src={src} />
                          </Card>
                        </Col>
                      </React.Fragment>
                    );
                  })}
              </Row>
              <Select
                defaultValue={gapBetweenMonths}
                placeholder="Số tháng"
                showSearch
                onChange={onchangeMonth}
                style={{ width: "100%", padding: 4, margin: "8px 0" }}
                options={optionExtend}
              ></Select>
              <Row justify="space-between" style={{ padding: 4 }}>
                <Col>
                  <Text>Tổng tiền:</Text>
                </Col>
                <Col>
                  <Title level={5}>{numeral(amounttotal).format("0,0")}</Title>
                </Col>
              </Row>
            </Card>
          </div>
        )}
        {currentStep === 2 && (
          <div style={{ textAlign: "center", padding: 32 }}>
            <Title level={2} type="success">
              TẠO THANH TOÁN THÀNH CÔNG
            </Title>
            <Text type="secondary">Vui lòng làm theo hướng dẫn bên dưới để thanh toán</Text>
            <DetailTransaction data={data} modelTitle={"Thông tin gia hạn"} PropslistBank={listBank} />
          </div>
        )}
      </Modal>
    </div>
  );
}

const contentStyleStep0 = {
  padding: "8% 10%",
  backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/payment.png"})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const contentStyleStep1 = {
  padding: "8% 24%",
  backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/payment.png"})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const stepsStyle = {
  background: "#F5F5F5",
  padding: "16px 32px",
};

export default memo(ModalPackageRenewal);
