import React, { createRef } from "react";
import "./login.css";
import { Row, Col } from "antd";
import { Typography, Space } from "antd";
import { Form, Input, Button, Checkbox, message, Spin } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import api from "api";

const { Title } = Typography;

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      btnLoading: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {}

  changePassword = (values) => {
    this.setState({ btnLoading: true });
    var url_string = window.location.href;
    var url = new URL(url_string);
    var rptoken = url.searchParams.get("token");
    if (!rptoken) {
      message.error("Đường dẫn không hợp lệ");
    } else {
      api
        .post("/signup/changepassword", { token: rptoken, password: values.password })
        .then((res) => {
          message.success("Thay đổi Mật khẩu thành công");
          setTimeout(() => {
            window.location.href = window.location.origin + "/login";
          }, 2000);
        })
        .catch((err) => {
          if (err.status === 0) {
            message.error("Token không hợp lệ");
          } else {
            message.error("Thay đổi mật khẩu thất bại!");
          }
        });
    }
    this.setState({ btnLoading: false });
  };

  render() {
    return (
      <>
        <Spin size="large" spinning={this.state.loading} style={{ "margin-top": "30vh" }}>
          <Row>
            <Col xs={{ span: 0 }} lg={{ span: 12 }} sm={{ span: 0 }} md={{ span: 12 }} style={{ height: "100vh", "background-color": "#E4EBF7" }} className="background-left">
              {/* <div style={{ height: "100vh" }}>
                        

                        </div> */}
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}>
                {/* <img src={'/assets/images/07.svg'} /> */}
              </Row>
              <Row justify="center " align="middle" style={{ height: "80vh" }}>
                <img src={"/assets/images/07.svg"} style={{ width: "100%", maxWidth: 500 }} />
              </Row>
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}>
                {/* <img src={'/assets/images/07.svg'} /> */}
              </Row>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Row justify="center" align="middle" style={{ height: "80vh" }}>
                <div style={{ width: "300px" }}>
                  <div>
                    <Title align="center">Thay đổi mật khẩu</Title>
                  </div>
                  <div style={{ marginBottom: 20 }}>Nhập mật khẩu mới.</div>
                  <div style={{ marginBottom: 20 }}>
                    <Form layout="vertical" onFinish={this.changePassword}>
                      <Form.Item
                        name="password"
                        label="Mật khẩu"
                        validateTrigger={"onBlur"}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập mật khẩu!",
                          },
                          {
                            validator: (rule, value) => {
                              return new Promise((resolve, error) => {
                                if (value.length > 50) {
                                  error();
                                } else {
                                  resolve();
                                }
                              });
                            },
                            message: "Mật khẩu không được dài quá 50 ký tự!",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        name="confirm"
                        label="Xác nhận mật khẩu"
                        validateTrigger={"onBlur"}
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa xác nhận mật khẩu!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Mật khẩu không khớp!"));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" style={{ width: "100%" }} htmlType="submit" loading={this.state.btnLoading}>
                          Thay đổi mật khẩu
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}
