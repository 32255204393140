import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";

function ShopeeSync() {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var data = {
      code: search.slice(1).split("&")[0].replace("code=", ""),
      shop: search.slice(1).split("&")[1].replace("shop=", ""),
      shop_id: search.slice(1).split("&")[2].replace("shop_id=", ""),
    };
    connectToShopee(data);
  }, []);

  const connectToShopee = (data) => {
    api.post("app/shopee/webhook/sync", data).then((res) => {
      setIsLoading(false);
      window.location.href = "/apps/shopee";
    });
  };
  return <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />;
}

export default ShopeeSync;
