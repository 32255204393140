import { useState, memo, useEffect, useRef } from "react";
import Auth from "./auth";
import Config from "./config";
import api from "api";
import { getSocket } from "common/realtime";
import { Skeleton } from "antd";
import "./zalov2.css";

const ZaloApp = () => {
  const [data, setData] = useState();
  const [position, setPosition] = useState();
  const [loading, setLoading] = useState(true);
  const socketZalo = useRef();

  useEffect(() => {
    getData();
    var socket = getSocket("app_socket");
    socket.on("connect", () => {
      socket.on("get_zalo_app", () => {
        getData();
      });
    });
    socketZalo.current = socket;
  }, []);

  const getData = () => {
    api.get("app/zalov2/config/getconfig").then((data) => {
      if (data) {
        setPosition("right");
        setData(data);
        setLoading(false);
      } else {
        setPosition("");
      }
    });
  };

  return (
    <div>
      {loading ? (
        <div>
          <Skeleton></Skeleton>
        </div>
      ) : (
        <>
          {data?.oas?.length > 0 ? (
            <div>
              <Config data={data} position={position} getData={getData}></Config>
            </div>
          ) : (
            <div style={{height:"calc(100vh - 128px)",display: "flex",justifyContent: "center",alignItems: "center"}}>
              <Auth></Auth>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(ZaloApp);
