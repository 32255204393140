import React, { Component } from "react";
import { Button, Modal, Form, Input, message, Select, Col } from "antd";
import api from "api";

const { Option } = Select;
const { TextArea } = Input;

export default class PopupEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      id: "",
      visible: false,
      data: {},
    };
    this.form = React.createRef();
  }

  open(id) {
    if (!id) {
      this.setState({ visible: true, data: {}, type: this.props.type }, () => {
        this.form.current.setFieldsValue({ id: "", name: "", description: "" });
      });
    } else {
      api.post("emailtemplate/getbyid", { id: id }).then((res) => {
        this.setState({ visible: true, data: res }, () => {
          this.form.current.setFieldsValue(res);
        });
      });
    }
  }

  save = (values) => {
    api
      .post("emailtemplate/savemytemplate", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
        this.setState({ visible: false });
        this.props.lstMyTemplate();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  render() {
    return (
      <>
        <Modal
          title="Edit Template"
          open={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          width="50%"
          footer={[
            <Button
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              Thoát
            </Button>,

            <Button
              type="primary"
              onClick={() => {
                this.form.current.submit();
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form name="template" ref={this.form} layout="vertical" onFinish={this.save}>
            <Form.Item name="id" hidden>
              <Input></Input>
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input placeholder="Description" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
