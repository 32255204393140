import { useEffect, useState } from 'react';
import { Modal, Typography, Badge, Descriptions, Button, Tabs, Radio, Space, Col, Row, Card, InputNumber, message } from 'antd'
import api from "api";
import numeral from 'numeral';
import { formatS } from "common/date_time";

const { Text, Title } = Typography;

function ModalInfoShop({ isModalOpen, setIsModalOpen, shop, refTable }) {
  const [valueRadioGroup, setValueRadioGroup] = useState(1);
  const [valuePriceZalo, setValuePriceZalo] = useState(0);
  const [vatZAlo, setVatZalo] = useState(1.1);

  useEffect(() => {
    if (Object.keys(shop).length > 0) {
      // console.log('shop ne', shop);
      //gán giá mặc định theo hệ thống
      setDefaultValueForApp(shop.system_service_price);

      //gán giá custom theo config
      if (shop.service_price) {
        setValueForApp(shop.service_price.apps);
      }
    }
  }, [shop])

  // useEffect(() => { }, [valuePriceZalo])

  /**
   * Hàm gán giá trị mặc định cho app
   * @param {Array} apps danh sách app được config cho shop
   * @author huy
   */
  const setDefaultValueForApp = (apps) => {
    apps.forEach((app, index) => {
      // debugger
      switch (app.name) {
        case "zalozns":
          setValueRadioGroup(1);
          setValuePriceZalo(app.price);
          setVatZalo(app.vat);
      }
    });
  }

  /**
   * Hàm gán giá trị cho app nếu như app có lưu config
   * @param {Array} apps danh sách app được config cho shop 
   * @author huy
   */
  const setValueForApp = (apps) => {
    apps.forEach((app, index) => {
      // debugger
      switch (app.name) {
        case "zalozns":
          if (app.allow_price_custom) {
            setValuePriceZalo(app.price_custom);
            setValueRadioGroup(2);
          }
      }
    });
  }

  /**
   * Hàm xử lý khi thay đổi chọn radio
   * @param {*} e 
   */
  const onChangeRadioGroup = (e) => {
    var value = e.target.value;
    if (value === 1) {
      var app = shop.system_service_price.find(app => app.name === "zalozns");
      setValuePriceZalo(app.price);
    } else {
      if (shop.service_price) {
        var appConfig = shop.service_price.apps.find(app => app.name === "zalozns");
        setValuePriceZalo(appConfig.price_custom);
      }
    }
    setValueRadioGroup(value);
  };

  const onChangeInputNumber = (value) => {
    // console.log('changed', value);
    setValuePriceZalo(value);
  }

  const items = [
    {
      key: '1',
      label: 'Zalo ZNS',
      children: (
        <Row>
          <Col>
            <Radio.Group onChange={onChangeRadioGroup} value={valueRadioGroup}>
              <Space direction="vertical">
                <Radio value={1}>Tính giá theo hệ thống</Radio>
                <Radio value={2}>Tính bảng giá riêng</Radio>
              </Space>
            </Radio.Group>
          </Col>
          <Col>
            <Card size='small'>
              <Text>{`Giá tin nhắn = ( Giá hệ thống ZNS + `}</Text>
              <InputNumber
                disabled={valueRadioGroup === 1 && true} size='small'
                value={valuePriceZalo}
                onChange={onChangeInputNumber}
                style={{ width: 56 }}
                formatter={(value) => numeral(value).format("0,0")}
                min={0}
              />
              <Text> {` ) + ${numeral(vatZAlo * 100 - 100).format("0,0")}% VAT`}</Text>
            </Card>
          </Col>
        </Row>
      ),
    }
  ];

  /**
   * Hàm xử lý khi thay đổi tab
   * @param {*} key 
   */
  const onChangeTabs = (key) => {
    // console.log(key);
  };

  const handleCancel = () => {
    setValueRadioGroup(1);
    setValuePriceZalo(0);
    setIsModalOpen(false);
  };

  /**
   * Hàm xử lý khi nhấn lưu bảng giá dịch vụ
   */
  const saveServicePriceList = () => {
    var dataPost = {
      shop_id: shop.id,
      app_name: "zalozns",
      allow_price_custom: valueRadioGroup === 2 && true,
      price_custom: valuePriceZalo
    }
    api.post("master/shop/saveservicepricelist", dataPost).then((res) => {
      // console.log(res);
      message.success(res.message);
      refTable.current.reload();
    }).catch((err) => message.error(err.message ? err.message : "Lưu không thành công"));
  }

  return (
    <Modal
      title="Chi tiết công ty"
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      width="56vw"
      footer={[
        <Button onClick={handleCancel}>
          Đóng
        </Button>
      ]}
    >
      <Descriptions title="Thông tin" bordered column={2}>
        <Descriptions.Item label={<Text>Tên công ty</Text>}>{shop.name}</Descriptions.Item>
        <Descriptions.Item label={<Text>Tên khách hàng</Text>}>{shop.last_name + ' ' + shop.first_name}</Descriptions.Item>
        <Descriptions.Item label={<Text>Số điện thoại</Text>}>{shop.phone_number}</Descriptions.Item>
        <Descriptions.Item label={<Text>Email</Text>}>{shop.email}</Descriptions.Item>
        <Descriptions.Item label={<Text>Ngày tạo</Text>}>{formatS(shop.create_date)}</Descriptions.Item>
        <Descriptions.Item label={<Text>Tiền tài khoản</Text>}>{numeral(shop.account_balance).format("0,0")}đ</Descriptions.Item>
        <Descriptions.Item label={<Text>Ngày bắt đầu</Text>}>{shop.valid_from && formatS(shop.valid_from)}</Descriptions.Item>
        <Descriptions.Item label={<Text>Ngày kết thúc</Text>}>{shop.valid_to && formatS(shop.valid_to)}</Descriptions.Item>
        <Descriptions.Item label={<Text>Trạng thái</Text>} span={2}>
          <Badge
            color={new Date() < new Date(shop.valid_to) ? "blue" : "red"}
            text={new Date() < new Date(shop.valid_to) ? "Đang sử dụng" : "Đã hết hạn"}
          />
        </Descriptions.Item>
      </Descriptions>

      <Title level={5} style={{ marginTop: 16 }}>Bảng giá dịch vụ</Title>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChangeTabs}
        tabBarExtraContent={<Button type='primary' onClick={saveServicePriceList}>Lưu</Button>}
      />
    </Modal>
  )
}

export default ModalInfoShop