import { Badge, Col, DatePicker, Dropdown, Form, Input, Row, Select, Space, Tooltip, Typography } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs";
import api from "api";
import ServiceOrderDetail from "./ServiceOrderDetail";
function ServiceOrder() {
  const [form] = Form.useForm();
  const [dataPost, setDataPost] = useState();
  const Tb = createRef();
  const [locationList, setLocationList] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const getColumn = () => {
    var columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        // sorter: true,
        // render: (text, record, index) => {
        //   return <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY")}</p>;
        // },
      },
      {
        title: "Họ tên",
        dataIndex: "name",
        key: "name",

        render: (text) => {
          if (text) {
            return text.replace("null", "").replace("undefined", "").trim();
          }
        },
      },
      {
        title: "Số điện thoại",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Lịch hẹn",
        dataIndex: "order_time",
        key: "order_time",
        render: (text) => {
          return moment(text).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        title: "Chi nhánh",
        dataIndex: "location",
        key: "location",
        render: (text) => {
          if (text) {
            return text.name;
          }
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          switch (text) {
            case "new":
              return <Badge color="yellow" text="Chờ xác nhận" />;
            case "paid":
              return <Badge color="blue" text="Xác nhận" />;
            case "success":
              return <Badge color="green" text="Thành công" />;
            case "cancel":
              return <Badge color="red" text="Huỷ" />;
            default:
              break;
          }
        },
      },
      {
        dataIndex: "id",
        key: "id",
        render: (id, record, index) => {
          // var findItem = linkList.find((item) => {
          //   return item.woo_product_id === record.id;
          // });
          return (
            <Dropdown
              trigger={"click"}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Xem lịch hẹn",
                  },
                ],
                onClick: (e) => {
                  setSelectedRecord(record);
                  setOpenDetail(true);
                },
              }}
              placement="bottomLeft"
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];
    return columns;
  };

  useEffect(() => {
    getLocationServiceList();
  }, []);

  const getLocationServiceList = () => {
    api
      .post("location_service/getlist")
      .then((res) => {
        setLocationList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSearch = () => {
    var newDataPost = form.getFieldsValue();
    console.log(newDataPost);
    if (newDataPost.order_time && newDataPost.order_time.length > 0) {
      newDataPost.order_time = newDataPost.order_time.map((item, index) => {
        if (index === 0) {
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").utc().format();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").utc().format();
        }
      });
    }
    setDataPost(newDataPost);
  };

  useEffect(() => {
    if (!dataPost || dataPost || !openDetail) {
      Tb.current.reload();
    }
  }, [dataPost, openDetail]);

  useEffect(() => {
    if (!openDetail) {
      setSelectedRecord(null);
    }
  }, [openDetail]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div style={{ background: "white", borderRadius: 8 }}>
        <Form layout={"inline"} form={form} style={{ padding: 8 }}>
          <Form.Item name="text" style={{ marginInlineEnd: 16, width: "calc(25% - 12px)" }}>
            <Input
              placeholder="Mã đơn / Số điện thoại"
              onBlur={onSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
          </Form.Item>

          <Form.Item name="order_time" style={{ marginInlineEnd: 16, width: "calc(25% - 12px)" }}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
              onChange={() => {
                onSearch();
              }}
            />
          </Form.Item>

          <Form.Item name="status" style={{ marginInlineEnd: 16, width: "calc(25% - 12px)" }}>
            <Select
              placeholder="Trạng thái"
              mode="multiple"
              allowClear
              options={[
                { value: "new", label: "Chờ xác nhận" },
                { value: "paid", label: "Xác nhận" },
                { value: "success", label: "Thành công" },
                { value: "cancel", label: "Huỷ" },
              ]}
              onChange={onSearch}
            />
          </Form.Item>

          <Form.Item name="location" style={{ marginInlineEnd: 0, width: "calc(25% - 12px)" }}>
            <Select
              placeholder="Chi nhánh"
              mode="multiple"
              allowClear
              options={
                locationList.length > 0
                  ? locationList.map((item) => {
                      return { value: item.id, label: item.name };
                    })
                  : []
              }
              onChange={onSearch}
            />
          </Form.Item>
        </Form>

        <AntTable
          columns={getColumn()}
          customSearch={dataPost}
          tableName={"tbReview"}
          ajax="service_order/list"
          ref={Tb}
          rowKey={(record) => record.id}
        ></AntTable>
      </div>

      {openDetail && <ServiceOrderDetail open={openDetail} setOpen={setOpenDetail} record={selectedRecord} />}
    </Space>
  );
}

export default ServiceOrder;
