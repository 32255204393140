import React from "react";
import { Button, Mentions, Row, Col, Modal, Form, Input, Select } from "antd";
import { FormContext } from "antd/lib/form/context";
import ButtonTypePicker from "./button_type_picker";
import { makeid } from "common/custom_field";
import api from "api";

const { Option } = Select;
const { OptGroup } = Mentions;

export default class PopupButtonSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: null,
      lstSuggest: [],
      load: false,
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    api.post("mergertags/getall").then((res) => {
      this.setState({ lstSuggest: res, load: true });
    });
  };

  open = (dataButton) => {
    if (dataButton) {
      this.setState({ visible: true, data: dataButton });
      setTimeout(() => {
        this.form.current.setFieldsValue(dataButton);
      }, 500);
    } else {
      this.setState({ visible: true, data: null });
      setTimeout(() => {
        if (this.form.current) this.form.current.resetFields();
      }, 500);
    }
  };

  close = () => {
    this.setState({ visible: false });
  };

  finish = (value) => {
    if (!value.id) {
      value.id = makeid(5);
    }
    this.props.addButton(value);
    this.setState({ visible: false });
  };

  delete = () => {
    this.props.delete(this.state.data.id);
    this.setState({ visible: false });
  };

  renderOptionSuggest = () => {
    var options = [];
    // debugger;
    this.state.lstSuggest.forEach((item) => {
      if (item.child != undefined) {
        item.child.forEach((element) => {
          options.push(
            <Option value={"{" + item.attribute + "." + element.attribute + "}}"} label={"{" + element.label + "}"}>
              <span>{element.attribute} |</span>
              <span>{element.label}</span>
            </Option>
          );
        });
      } else {
        options.push(
          <Option value={"{" + item.attribute + "}}"} label={"{" + item.label + "}"}>
            <span>{item.attribute} |</span>
            <span>{item.label}</span>
          </Option>
        );
      }
    });
    return options;
  };

  render() {
    return (
      <Modal
        width={"40%"}
        open={this.state.visible}
        title="Thêm Nút"
        onOk={() => {
          this.form.current.submit();
        }}
        onCancel={this.close}
        footer={
          this.state.data
            ? [<Button onClick={this.close}>Thoát</Button>, <Button onClick={this.delete}>Xóa</Button>, <Button onClick={() => this.form.current.submit()}>Lưu</Button>]
            : [<Button onClick={this.close}>Thoát</Button>, <Button onClick={() => this.form.current.submit()}>Lưu</Button>]
        }
      >
        {this.state.load && (
          <Form ref={this.form} layout="vertical" onFinish={this.finish}>
            <Form.Item hidden={true} name="id"></Form.Item>
            <Form.Item label="Tiêu đề nút" name="title" rules={[{ required: true, message: "Bạn chưa nhập tiêu đề" }]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="type" rules={[{ required: true, message: "Bạn chưa chọn loại nút" }]}>
              <ButtonTypePicker></ButtonTypePicker>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
              {({ getFieldValue }) => {
                var data = getFieldValue("type");
                return (
                  <>
                    {data === "web_url" ? (
                      <Form.Item
                        label="Đường dẫn"
                        name="url"
                        rules={[
                          { required: true, message: "Bạn chưa nhập đường dẫn!" },
                          {
                            validator: (rule, value) => {
                              return new Promise((resolve, error) => {
                                if (value.startsWith("{{") && value.trim().endsWith("}}") && value.toLowerCase().includes("link")) {
                                  resolve();
                                } else {
                                  try {
                                    var url = new URL(value);
                                    resolve();
                                  } catch (_) {
                                    error();
                                  }
                                }
                              });
                            },
                            message: "Đường dẫn không hợp lệ!",
                          },
                        ]}
                      >
                        <Mentions
                          rows={1}
                          placeholder="Các param có thể nhập bằng bắt đầu với dấu {"
                          prefix={["{"]}
                          onBlur={(e) => {
                            this.form.current.setFieldsValue({ url: e.target.value.trim() });
                            this.form.current.validateFields(["url"]);
                          }}
                        >
                          {this.renderOptionSuggest()}
                        </Mentions>
                      </Form.Item>
                    ) : (
                      <>
                        {data === "phone_number" ? (
                          <Form.Item
                            label="Số điện thoại"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Bạn chưa nhập số điện thoại!",
                              },
                              {
                                validator: (rule, value) => {
                                  var rex = /^\+?(?:[0-9]●?){2,14}[0-9]$/g;
                                  return new Promise((resolve, error) => {
                                    if (rex.test(value)) {
                                      resolve();
                                    } else {
                                      error();
                                    }
                                  });
                                },
                                message: "Số điện thoại không hợp lệ!",
                              },
                            ]}
                          >
                            <Input></Input>
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label="Gửi lại"
                            name="payload"
                            rules={[
                              {
                                required: true,
                                message: "Bạn chưa chọn nội dung gửi lại",
                              },
                            ]}
                          >
                            <Select>
                              <Option value="CUBE_LOYALTY_BALANCE">Khách hàng thân thiết</Option>
                              <Option value="CUBE_LOYALTY_STORE_LIST">Danh sách cửa hàng</Option>
                              <Option value="CUBE_LOYALTY_GIFT_EXCHANGE">Chương trình đổi quà</Option>
                              <Option value="CUBE_LOYALTY_CONTACT">Thông tin</Option>
                            </Select>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}
