import Phaser from "phaser";

var gameOptions = {};

class LuckyWheel extends Phaser.Scene {
  constructor() {
    super();
  }

  preload() {
    // this.load.image('wheel', 'https://raw.githubusercontent.com/vanquyet94/wheel-lucky-draw/master/wheel.png');
    var width = this.cameras.main.width;
    var height = this.cameras.main.height;


    this.load.image("arrow", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/arrow_resized.png");
    this.load.image("pin", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/spin_button_resize.png");
    this.load.image("light_ring", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/light_ring_2_resize.png");
    this.load.image("center_pin", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/center_pin.png");
    this.load.audio("spin_sound", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/spin.mp3");
    this.load.audio("winer", "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/winer.mp3");

    for (var i = 0; i < gameOptions.slices.length; i++) {
      var item = gameOptions.slices[i];
      if (item.display.type == "img") {
        this.load.image("icons_" + i, item.display.image.url);
      }
    }
    // if (gameOptions.background.type == "img") {
    //   this.load.image("bgg", gameOptions.background.image_url);
    // }
    //  this.canvas = this.sys.game.canvas;
  }

  create() {
    game.input.touch.capture = false;
    this.cameras.main.setRoundPixels(true);
    var center = { x: game.config.width / 2, y: 150 + gameOptions.wheelRadius };
    this.light_ring = this.add.image(center.x, center.y - 5, "light_ring");

    this.light_ring.setDisplaySize(gameOptions.wheelRadius * 2 + 71, gameOptions.wheelRadius * 2 + 71);

    // if (gameOptions.background.type == "img") {
    //     var bg = this.add.image(center.x, game.config.height / 2, "bg");
    //     var h = (game.config.width / (bg.width * 1.0)) * bg.height;
    //     bg.setDisplaySize(game.config.width, h);
    //     //bg.setOrigin(0.5, 0.5)
    // }


    // starting degrees
    var startDegrees = -90;
    var degrees = 360 / gameOptions.slices.length;

    // making a graphic object without adding it to the game
    var graphics = this.make.graphics({
      x: 0,
      y: 0,
      add: false,
    });

    // adding a container to group wheel and icons
    this.wheelContainer = this.add.container(center.x, center.y);

    // array which will contain all icons
    var iconArray = [];
    var l = (3.14 * (gameOptions.wheelRadius - 50) * degrees) / 180;

    // looping through each slice
    for (var i = 0; i < gameOptions.slices.length; i++) {
      var item = gameOptions.slices[i];
      // converting colors from 0xRRGGBB format to Color objects
      var backgroundColor = Phaser.Display.Color.HexStringToColor(item.backgroundColor);

      // setting line style

      graphics.fillStyle(backgroundColor.color, 1);
      // drawing the biggest slice
      graphics.slice(gameOptions.wheelRadius, gameOptions.wheelRadius, gameOptions.wheelRadius, Phaser.Math.DegToRad(startDegrees), Phaser.Math.DegToRad(startDegrees + degrees), false);

      graphics.fillPath();

      if (item.display.type == "img") {
        // adding the icon
        var icon = this.add.image(
          gameOptions.wheelRadius * 0.75 * Math.cos(Phaser.Math.DegToRad(startDegrees + degrees / 2)),
          gameOptions.wheelRadius * 0.75 * Math.sin(Phaser.Math.DegToRad(startDegrees + degrees / 2)),
          "icons_" + i
        );

        //icon.displayWidth =30;
        //icon.displayHeight =30;
        // scaling the icon according to game preferences
        console.log("l", l);
        console.log("icon", icon);
        var scale = (l * 0.3) / (icon.displayHeight * 1.0);

        icon.scaleX = scale;
        icon.scaleY = scale;

        // rotating the icon
        icon.angle = startDegrees + degrees / 2;
        // adding the icon to icon array
        iconArray.push(icon);
      }
      if (item.display.type == "text") {
        let text = this.add.text(
          gameOptions.wheelRadius * 0.75 * Math.cos(Phaser.Math.DegToRad(startDegrees + degrees / 2)),
          gameOptions.wheelRadius * 0.75 * Math.sin(Phaser.Math.DegToRad(startDegrees + degrees / 2)),
          item.display.text.value,
          {
            font: "bold 16px Arial",
            color: `${item.display.text.color}`,
          }
        );
        text.setOrigin(0.8, 0.5);

        // set text angle
        text.angle = startDegrees + degrees / 2;
        iconArray.push(text);
      }
      // updating degrees
      startDegrees += degrees;
    }

    // generate a texture called "wheel" from graphics data
    graphics.generateTexture("wheel", gameOptions.wheelRadius * 2, gameOptions.wheelRadius * 2);

    // creating a sprite with wheel image as if it was a preloaded image
    var wheel = this.add.sprite(0, 0, "wheel");

    // adding the wheel to the container
    this.wheelContainer.add(wheel);

    // adding all iconArray items to the container
    this.wheelContainer.add(iconArray);

    // adding all iconArray items to the container
    this.wheelContainer.add(iconArray);
    this.center_pin = this.add.sprite(center.x, center.y, "center_pin");
    this.arrow = this.add.sprite(center.x, center.y - gameOptions.wheelRadius - 10, "arrow");

    this.arrow.setDisplaySize(50, 50);

    this.pin = this.add.sprite(center.x, center.y + gameOptions.wheelRadius + 75, "pin").setInteractive();
    this.pin.setDisplaySize(212, 61);
    // this.prizeText = this.add.text(game.config.width / 2, game.config.height - 20, "Spin the wheel", {
    //   font: "bold 32px Arial",
    //   align: "center",
    //   color: "white",
    // });

    // center the text
    //this.prizeText.setOrigin(0.5);

    // the game has just started = we can spin the wheel
    //this.canSpin = gameOptions.canspin;

    this.pin.on("pointerdown", (e) => {
      this.spin();
    });

    this.spin_sound = this.sound.add("spin_sound");
    this.winer_sound = this.sound.add("winer");
  }

  spin() {
    var degreesEach = 360 / gameOptions.slices.length;
    // can we spin the wheel?
    if (gameOptions.canSpin) {
      this.spin_sound.setVolume(0.5);
      this.spin_sound.play();
      var prize = this.getPrizeIndex();
      // resetting text field
      //this.prizeText.setText("quay");

      // the wheel will spin round for some times. This is just coreography
      let rounds = Phaser.Math.Between(gameOptions.wheelRounds.min, gameOptions.wheelRounds.max);

      let prizeDegreeStart = prize * degreesEach;
      let prizeDegreeEnd = prizeDegreeStart + degreesEach;

      // then will rotate by a random number for prize  degrees
      let degrees = Phaser.Math.Between(prizeDegreeStart + 1, prizeDegreeEnd - 1);

      // now the wheel cannot spin because it's already spinning
      gameOptions.canSpin = false;

      // animation tweeen for the spin: duration 3s, will rotate by (360 * rounds + degrees) degrees
      // the quadratic easing will simulate friction
      this.tweens.add({
        // adding the wheel container to tween targets
        targets: [this.wheelContainer],
        // angle destination
        //angle: 360 * rounds + degrees,
        angle: 360 * rounds - degrees,
        // tween duration
        duration: Phaser.Math.Between(gameOptions.rotationTimeRange.min, gameOptions.rotationTimeRange.max),

        // tween easing
        ease: "Cubic.easeOut",

        // callback scope
        callbackScope: this,

        // function to be executed once the tween has been completed
        onComplete: function (tween) {
          this.stopSound(this.spin_sound).then((e) => {
            this.winer_sound.play();
          });

          if (gameOptions.onSpinFinish) {
            gameOptions.onSpinFinish(gameOptions.slices[prize].reward);
          }

          //this.prizeText.setText(gameOptions.slices[prize].reward.text);
          //this.canSpin = true;
        },
      });
    }
    //this.add.tween(this.pin).to( { x: '+300' }, 2000, Phaser.Easing.Linear.None, true);
  }

  getPrizeIndex() {
    var array = [];
    for (let index = 0; index < gameOptions.slices.length; index++) {
      for (let j = 0; j < gameOptions.slices[index].ratio ?? 0; j++) {
        array.push(index);
      }
    }
    var i = Phaser.Math.Between(0, array.length - 1);
    return array[i];
  }
  stopSound(sound) {
    return new Promise((r, e) => {
      var timeOut = setInterval(() => {
        var volume = sound.volume - 0.1;
        if (volume <= 0) {
          sound.stop();
          clearInterval(timeOut);
          r();
        }
        sound.setVolume(volume);
      }, 100);
    });
  }
}

var game = null;
function init(parent, options) {
  if (game) {
    console.log("game destroy");
    game.destroy(true);
  }
  if (options) {
    gameOptions = options;
  }

  console.log("game will load", options);
  // var w = document.querySelector(`#${parent}`).clientWidth;
  var w = 375;
  var h = 812;
  gameOptions.wheelRadius = (w * 0.85) / 2;
  gameOptions.strokeWidth = 0;
  const config = {
    type: Phaser.AUTO,
    // scale: {
    //     mode: Phaser.Scale.FIT,
    //     parent: parent,
    //     autoCenter: Phaser.Scale.CENTER_BOTH,
    //     width: 600,
    //     height: 600
    // },
    "scale.mode": Phaser.Scale.NONE,
    width: w,
    //height: w * (16 / 9.0),
    height: h,
    resolution : window.devicePixelRatio,
    "render.transparent": true,
    transparent: true,
    "render.antialias": true,
    antialias: true,
    input: {
      touch: {
          capture: true
      }
    },
    //backgroundColor: options.background.color ?? "#880044",
    parent: parent,
    scene: [LuckyWheel],
  };

  game = new Phaser.Game(config);
}

function setOption(newOption) {
  gameOptions = { ...gameOptions, newOption };

  if (newOption.canSpin != null) {
    gameOptions.canSpin = newOption.canSpin;
  }

  if (newOption.mute === true) {
    game.sound.mute = true;
  }

  if (newOption.mute === false) {
    game.sound.mute = false;
  }
}

export { init, setOption };
