import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
export default class DatePickerNew extends Component {
  constructor(props) {
    super(props);
  }





  render() {


    return <RangePicker {...this.props} format="DD-MM-YYYY"       
    ranges={{
      'Hôm nay': [moment(), moment()],
      'Hôm qua': [moment().add(-1, "days"), moment().add(-1, "days")],
      'Tuần này': [moment().startOf('week'), moment().endOf('week')],
      'Tuần trước': [moment().add(-7, "days").startOf('week'), moment().add(-7, "days").endOf('week')],
      'Tháng này': [moment().startOf('month'), moment().endOf('month')],
      'Tháng trước': [moment().add(-1,"months").startOf('month'), moment().add(-1,"months").endOf('month')],
    }} />;
  }
}
