import React, { createRef, useEffect, useState } from "react";
import { Typography, Dropdown, Row, Col, Select, Divider, Input, DatePicker, Badge } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import AntTable from "components/common/AntTable";
import numeral from "numeral";
import moment from "moment";
import ModalInfoShop from "./ModalInfoShop";
import ModalShopManagement from "./ModalShopManagement";
import ModalInfoZalo from "./ModalInfoZalo";
import ModalInfoMiniApp from "./ModalInfoMiniApp";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

function ShopManagement() {
  const refTable = createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInfoShopOpen, setIsModalInfoShopOpen] = useState(false);
  const [isModalInfoZaloOpen, setIsModalInfoZaloOpen] = useState(false);
  const [isModalInfoMiniAppOpen, setIsModalInfoMiniAppOpen] = useState(false);
  const [shopSelected, setShopSelected] = useState({});

  const [filter, setFilter] = useState({
    text: "",
    valid: "all",
    // miniapp_id: "",
  });

  const columns = [
    {
      title: "Tên công ty",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "last_name",
      key: "last_name",
      align: "center",
      render: (text, record) => {
        return <Text>{record.last_name + " " + record.first_name}</Text>;
      },
    },
    {
      title: "SĐT",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
    },
    {
      title: "Tiền TK",
      dataIndex: "account_balance",
      key: "account_balance",
      align: "center",
      render: (text) => {
        return <Text>{numeral(text).format("0,0")}</Text>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "valid_from",
      key: "valid_from",
      align: "center",
      render: (text) => {
        // return (<>{text && formatL(text)}</>)
        return <>{text && moment(new Date(text)).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "valid_to",
      key: "valid_to",
      align: "center",
      render: (text) => {
        // return (<>{text && formatL(text)}</>)
        return <>{text && moment(new Date(text)).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      align: "center",
      render: (text, record, index) => {
        var currentDate = new Date();
        var expiredDate = new Date(record.valid_to);
        var check = currentDate <= expiredDate;
        return <Badge color={check ? "blue" : "red"} text={check ? "Đang sử dụng" : "Đã hết hạn"} />;
      },
    },
    {
      title: "Mini App ID",
      dataIndex: "miniapp_id",
      key: "miniapp_id",
      sorter: true,
      align: "center",
      // render: (text, record, index) => {
      //   var currentDate = new Date();
      //   var expiredDate = new Date(record.valid_to);
      //   var check = currentDate <= expiredDate;
      //   return <Badge color={check ? "blue" : "red"} text={check ? "Đang sử dụng" : "Đã hết hạn"} />;
      // },
    },
    {
      title: " ",
      key: "action",
      align: "center",
      render: (text, record, index) => {
        const items = [
          {
            label: "Thông tin công ty - Bảng giá",
            key: "1",
            onClick: () => {
              setShopSelected(record);
              setIsModalInfoShopOpen(true);
            },
          },
          {
            label: "Gia hạn",
            key: "2",
            onClick: () => {
              setShopSelected(record);
              setIsModalOpen(true);
            },
          },
          {
            label: "Thông tin Zalo",
            key: "3",
            onClick: () => {
              setShopSelected(record);
              setIsModalInfoZaloOpen(true);
            },
          },
          {
            label: "Thông tin Mini App",
            key: "4",
            onClick: () => {
              setShopSelected(record);
              setIsModalInfoMiniAppOpen(true);
            },
          },
        ];
        return (
          <Dropdown trigger="click" menu={{ items }} placement="bottomRight" arrow>
            <MoreOutlined style={{ fontSize: 24 }} />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    refTable.current.reload();
  }, [filter]);

  /**
   * Xử lý khi thay đổi bộ lọc ngày
   * @param {[dayjs, dayjs]} dates
   * @author huy
   */
  const onChangeRangePicker = (dates) => {
    if (dates) {
      // console.log('dates', dates[0].startOf('day').format(), dates[1].add(1, 'day').startOf('day').format());
      setFilter({
        ...filter,
        valid_from: {
          start: dates[0].startOf("day").format(),
          end: dates[1].add(1, "day").startOf("day").format(),
        },
      });
    } else {
      var newFilter = filter;
      if (newFilter.valid_from) {
        delete newFilter.valid_from;
      }
      if (newFilter.valid_to) {
        delete newFilter.valid_to;
      }
      setFilter({ ...newFilter });
    }
  };

  /**
   * Xử lý khi thay đổi bộ lọc trạng thái
   * @param {*} value giá trị của bộ lọc trạng thái khi thay đổi
   * @author huy
   */
  const onSelectValidChange = (value) => {
    setFilter({ ...filter, valid: value });
  };

  /**
   * Xử lý khi thay đổi bộ lọc search text
   * @param {*} value giá trị của search text được nhập vào
   * @author huy
   */
  const onSearchText = (value) => {
    setFilter({ ...filter, text: value });
  };

  const onSearchId = (value) => {
    setFilter({ ...filter, miniapp_id: value });
  };

  return (
    <div>
      <Title level={4}>Quản lý công ty</Title>
      <Row style={{ backgroundColor: "white", padding: 16, marginLeft: 0, marginRight: 0 }} gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} onChange={onChangeRangePicker} />
        </Col>
        <Col xs={24} md={6}>
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={onSelectValidChange}
            options={[
              { value: "all", label: "-- Tất cả --" },
              { value: true, label: "Đang sử dụng" },
              { value: false, label: "Đã hết hạn" },
            ]}
          />
        </Col>
        <Col xs={24} md={6}>
          <Search placeholder="Tên công ty/Tên khách hàng/SĐT" style={{ width: "100%" }} onSearch={onSearchText} allowClear />
        </Col>
        <Col xs={24} md={6}>
          <Search placeholder="Mini App ID" style={{ width: "100%" }} onSearch={onSearchId} allowClear />
        </Col>
      </Row>

      <Row style={{ backgroundColor: "white" }}>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
          <AntTable
            columns={columns}
            autoLoad={true}
            customSearch={filter}
            tableName={"tbShop"}
            ajax="master/shop/list"
            ref={refTable}
            rowKey={(record) => record.id}
          ></AntTable>
        </Col>
      </Row>

      <ModalInfoShop isModalOpen={isModalInfoShopOpen} setIsModalOpen={setIsModalInfoShopOpen} shop={shopSelected} refTable={refTable} />
      <ModalShopManagement isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} shop={shopSelected} refTable={refTable} />
      <ModalInfoZalo isModalOpen={isModalInfoZaloOpen} setIsModalOpen={setIsModalInfoZaloOpen} shop={shopSelected} refTable={refTable} />
      <ModalInfoMiniApp isModalOpen={isModalInfoMiniAppOpen} setIsModalOpen={setIsModalInfoMiniAppOpen} shop={shopSelected} refTable={refTable} />
    </div>
  );
}

export default ShopManagement;
