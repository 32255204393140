import React from "react";
import { Input } from "antd";
import api from "api";
import { Cascader, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const MergerTagPopup = (props) => {
  const [listMergerTag, setListMergerTag] = React.useState([]);

  React.useEffect(() => {
    api.post("/mergertags/getall").then((res) => {
      var listType = [];
      res.forEach((element) => {
        if (!listType.includes(element.typename)) listType.push(element.typename);
      });
      var listData = [];
      listType.forEach((item, i) => {
        var obj = {
          label: item,
          value: i,
          children: res.filter((x) => x.typename == item),
        };
        listData.push(obj);
      });
      setListMergerTag(listData);
    });
  }, []);

  return (
    <div>
      <Cascader
        options={listMergerTag}
        placement="bottomRight"
        onChange={(v) => {
          var data = v[v.length - 1];
          if (props.onChange) props.onChange(data);
        }}
      >
        <PlusCircleOutlined />
      </Cascader>
    </div>
  );
};

export default MergerTagPopup;
