import React, { Component } from "react";
import { Button, Checkbox, Input, Modal, Menu, Dropdown, Select, message } from "antd";
import { Tabs } from "antd";

import EmailEditor from "react-email-editor";
import { default_design } from "./default_design";
import api from "api";
import { getPath } from "common/file.js";
import GalleryPopup from "./gallery_popup";
import ReactSrcDocIframe from "../../../common/iframe-doc";

const mouseClickEvents = ["mousedown", "click", "mouseup"];
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

export default class Editor extends Component {
  constructor(props) {
    super(props);
    this.lstreason = ["email template 1", "email template 2", "email template 3"];
    this.state = {
      merge_tags: [],
      value: this.props.value,
      keyTab: "editor-1",
      type: "emaileditor",
      checkboxmytemplate: false,
      name: "",
      lstreason: this.lstreason,
      input: "",
      listGame: [],
      linkSpecialGame: [],
    };

    this.popupAddEdit = React.createRef();
    this.EmailEditor = React.createRef();
    this.popupCustomerDetails = React.createRef();
    this.form = React.createRef();
    this.GalleryPopup = React.createRef();
  }

  componentDidMount() {
    this.getCampaignParttern();
    this.getListGame();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
      this.onLoad();
    }
  }

  renderOptionSuggest = (data) => {
    var options = [];
    options = {};
    return options;
  };

  getCampaignParttern = () => {
    api
      .post("/mergertags/gettagforunlayer")
      .then((res) => {
        this.setState({ merge_tags: res });
      })
      .catch((err) => {});
  };

  getListGame = () => {
    api.post("gameprogram/getall").then((res) => {
      var linkSpecialGame = res.map((item) => {
        return {
          name: item.name,
          href: `{{game_link_${item.id}}}`,
          target: "_blank",
        };
      });
      this.setState({ listGame: res, linkSpecialGame: linkSpecialGame });
    });
  };

  removeAttachment = (obj) => {
    var value = this.props.value;
    value.attachments = value.attachments.filter((item) => item.uid != obj.uid);
    this.onSetValue(value);
  };

  open = () => {
    this.setState({ visible: true, type: this.props.type ? this.props.type : "emaileditor" });
  };

  close = () => {
    this.setState({ visible: false });
    if (this.state.type === "emaileditor") this.exportHtml();
  };

  upload = (e) => {
    var form = new FormData();
    form.append("file", e.file);
    api
      .post("File/upload", form)
      .then((data) => {
        var value = { ...this.props.value };
        value.attachments.push({
          uid: Math.random(),
          name: e.file.name,
          filename: e.file.name,
          url: getPath(data.path),
          path: data.path,
        });
        this.onSetValue(value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeRichText = (name, val) => {
    var value = this.props.value;
    value[name] = val;
    this.onSetValue(value);
  };

  onChangeEditor = (content) => {
    var value = this.props.value;
    value.html = content.html;
    value.json = content.json;
    this.onSetValue(value);
  };

  onLoad = () => {
    var value = this.props.value;
    this.setState({ value: value });
  };

  onEditorLoad = () => {
    var value = this.state.value;
    try {
      this.initEditor(value);
    } catch (e) {
      setTimeout(() => {
        this.initEditor(value);
      }, 2000);
    }
  };

  initEditor = (value) => {
    var merge_tags = this.state.merge_tags;
    var json = value.json;
    if (json != null && json != "" && Object.keys(json).length !== 0) this.EmailEditor.current.editor.loadDesign(json);
    else {
      this.loadBlank();
    }

    this.EmailEditor.current.setMergeTags(merge_tags);
    this.EmailEditor.current.registerCallback("image", function (file, done) {
      var form = new FormData();
      form.append("file", file.attachments[0]);
      api
        .post("file/upload", form)
        .then((data) => {
          done({ progress: 100, url: data.path });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  loadBlank = () => {
    this.EmailEditor.current.editor.loadBlank({
      backgroundColor: "#e7e7e7",
    });
    this.exportHtml();
  };

  loadDefault = () => {
    this.EmailEditor.current.editor.loadDesign(default_design);
    this.exportHtml();
  };

  exportHtml = () => {
    // this.EmailEditor.current.editor.exportHtml((data) => {
    //   var value = {};
    //   value.json = data.design;
    //   value.html = data.html;
    //   this.onSetValue(value);
    // });
  };

  onHtmlCodeChange = (html) => {
    this.onChangeRichText("html", html);
  };

  onSetValue = (value) => {
    this.setState({ value: value });
  };

  save = (value) => {
    var value = {};
    //if (this.state.type === "emaileditor") {
    this.EmailEditor.current.editor.exportHtml((data) => {
      value.json = data.design;
      value.html = data.html;
      this.onSetValue(value);
      if (this.props.onChangeRichMedia) {
        this.props.onChangeRichMedia(value);
      }
      if (this.state.checkboxmytemplate) {
        var newData = {
          name: this.state.input,
          design: value.json,
          html: value.html,
          description: "",
          categories: [],
        };
        api
          .post("emailtemplate/save", { data: newData })
          .then((res) => {
            message.success("Lưu thành công");
          })
          .catch((err) => {
            message.error("Lưu thất bại");
          });
      }
    });
    // } else {
    //   if (this.props.onChangeRichMedia) {
    //     this.props.onChangeRichMedia(value, this.state.type);
    //   }
    // }

    this.close();
  };

  openGallery = () => {
    this.GalleryPopup.current.open();
  };

  loadDesign = (data, type) => {
    console.log("load design", data, type);
    this.setState({ type: type });
    if (data.design) {
      try {
        this.EmailEditor.current.editor.loadDesign(data.design);
        this.EmailEditor.current.editor.exportHtml((data) => {
          var value = {};
          value.json = data.design;
          value.html = data.html;
          this.onSetValue(value);
        });
      } catch (error) {
        setTimeout(() => {
          this.EmailEditor.current.editor.loadDesign(data.design);
          this.EmailEditor.current.editor.exportHtml((data) => {
            var value = {};
            value.json = data.design;
            value.html = data.html;
            this.onSetValue(value);
          });
        }, 2000);
      }
    } else {
      this.onSetValue(data);
    }
  };

  onChangeCheckBox = (checked) => {
    this.setState({
      checkboxmytemplate: checked,
    });
  };

  onChange = (value) => {
    this.setState({
      input: value,
    });
  };

  onClick = (value) => {
    this.setState({
      input: value,
    });
  };

  menu = () => {
    return (
      <Menu onClick={(value) => this.onClick(value.key)}>
        {this.state.lstreason.map((item) => {
          return (
            <Menu.Item key={item} style={this.props.value === item && { backgroundColor: "#99e2fc", fontWeight: "bold" }}>
              <div>{item}</div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    var value = this.state.value ?? {};

    return (
      <>
        <Modal
          wrapClassName="Modal"
          bodyStyle={{ padding: 0, height: "94vh" }}
          style={{ top: 0, margin: " 0 0 " }}
          open={this.state.visible}
          onCancel={this.close}
          closable={false}
          footer={[
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1, display: "flex" }}>
                <div style={{ marginTop: 3 }}>
                  <Checkbox onChange={(e) => this.onChangeCheckBox(e.target.checked)} checked={this.state.checkboxmytemplate}>
                    <span>Save as my template</span>
                  </Checkbox>
                </div>
                <div>
                  {this.state.checkboxmytemplate && (
                    <Dropdown overlay={this.menu} placement="bottomCenter" trigger={["click"]}>
                      <Input style={{ width: 300 }} value={this.state.input} onChange={(e) => this.onChange(e.target.value)} />
                    </Dropdown>
                  )}
                </div>
              </div>

              <div style={{ marginRight: 20, width: 600 }}>
                <Button type="primary" onClick={this.openGallery}>
                  Thư viện
                </Button>
                <Button onClick={this.loadBlank}> Mẫu trống </Button>
                <Button type="primary" onClick={this.save}>
                  Lưu
                </Button>
                <Button onClick={this.close}> Huỷ </Button>
              </div>
            </div>,
          ]}
          width={"100%"}
        >
          <div hidden={this.state.type === 3}>
            <EmailEditor
              style={{ height: "93vh" }}
              ref={this.EmailEditor}
              onLoad={this.onEditorLoad}
              options={{
                specialLinks: [
                  {
                    name: "Link Đổi Quà",
                    href: "{{customer_link}}",
                    target: "_blank",
                  },
                  {
                    name: "Hủy Đăng Ký",
                    href: "{{unsubscribed}}",
                    target: "_blank",
                  },
                  {
                    name: "Link tra cứu đơn hàng",
                    href: "{{order_link}}",
                    target: "_blank",
                  },
                  {
                    name: "Game",
                    specialLinks: this.state.linkSpecialGame,
                  },
                ],
              }}
            />
          </div>

          <div hidden={this.state.type !== 3}>
            <div style={{ width: "98%" }}>
              <div style={{ marginLeft: "10px", marginRight: "10px", width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                <TextArea
                  onChange={(e) => this.onHtmlCodeChange(e.target.value)}
                  rows={20}
                  value={value.html}
                  style={{ marginTop: "50px", width: "48%", height: "85vh" }}
                ></TextArea>
                <ReactSrcDocIframe style={{ marginTop: "50px", marginBottom: 10, height: "85vh" }} srcDoc={value.html === "" ? "" : value.html} width="48%" />
              </div>
            </div>
          </div>
        </Modal>
        <GalleryPopup ref={this.GalleryPopup} onDesignSelected={this.loadDesign} />
      </>
    );
  }
}
