import React from "react";
import { Button, Space, List, Row, Col, Avatar, Divider, Modal } from "antd";
import api from "api";
export default class PopupCreateGameProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lstGame: [],
      visible: false,
    };
  }

  getConfig = () => {
    api.post("gameprogram/getconfig").then((res) => {
      var lstGame = [];
      res.forEach((obj) => {
        if (lstGame.find((z) => z.type == obj.type) == null) lstGame.push(obj);
      });
      this.setState({ lstGame: lstGame, visible: true });
    });
  };

  open = () => {
    this.getConfig();
  };

  render() {
    return (
      <Modal
        title={
          <div style={{ fontSize: 30, width: "100%", display: "flex" }}>
            <span style={{ margin: "auto" }}>Tạo trò chơi trúng thưởng</span>
          </div>
        }
        open={this.state.visible}
        onOk={() => {}}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        width={450}
        footer={null}
        bodyStyle={{ padding: 0 }}
      >
        {this.state.lstGame.map((item) => {
          var href = item.config_url + item.type + "/0";
          return (
            <div style={{ padding: "15px 15px", borderWidth: "2px 0 0 0", borderColor: "#dfdfdf", borderStyle: "solid" }}>
              <a style={{ display: "flex" }} href={href}>
                <div style={{ border: "1px solid #dfdfdf", marginRight: 20 }}>
                  {/* <img src={item.default_logo} style={{ width: "55px", height: "55px" }}></img> */}
                  <img src={item.default_logo} style={{ width: "40px", height: "40px" }}></img>
                </div>
                {/* <div style={{ color: "#000000", fontSize: 25, width: "100%" }}>
                  <span>{item.name}</span>
                </div> */}
                <div style={{ color: "#000000", fontSize: 16, width: "100%",margin: "7px 0 0 0"}}>
                  <span>{item.name}</span>
                </div>
              </a>
            </div>
          );
        })}
      </Modal>
    );
  }
}
