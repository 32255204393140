import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";

function TiktokSync() {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    var data = {
      code: search.slice(1).split("&")[0].replace("code=", ""),
      state: search.slice(1).split("&")[1].replace("state=", ""),
    };
    console.log(data);
    // var code = hash.split("&id_token")[0].slice(6);
    connectToTiktok(data);
  }, []);

  const connectToTiktok = (data) => {
    api.post("app/tiktok/webhook/login", { ...data, shop_name: localStorage.getItem("shop_name") }).then((res) => {
      setIsLoading(false);
      window.location.href = "/apps/tiktok";
    });
  };
  return <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />;
}

export default TiktokSync;
