import React, { Component } from "react";
import { init, setOption } from "./game";
import Header from "./header";
import Footer from "./footer";
import PopupNotificationReward from "./popup";
import "./game.css";

export default class LuckyWheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
    };
    this.popup = React.createRef();
  }

  previewGame = (options) => {
    console.log("options", options);
    this.setState({ options: options });
    options = {
      ...options,
      rotationTimeRange: {
        min: 7000,
        max: 10000,
      },

      // wheel rounds before it stops
      wheelRounds: {
        min: 5,
        max: 20,
      },

      // wheel radius, in pixels
      wheelRadius: 240,
      // color of stroke lines
      strokeColor: 0xffffff,
      // width of stroke lines
      strokeWidth: 2,
      mute: false,
      onSpinFinish: (reward) => {
        this.popup.current.open(reward);
        console.log("reward 2", reward);
      },
    };
    if (this.previewGameTimer) {
      clearTimeout(this.previewGameTimer);
    }

    this.setState({ options: options });
    this.previewGameTimer = setTimeout((e) => {
      init("gameDiv", options);
    }, 1000);
  };

  onEvent = (e) => {
    switch (e.name) {
      case "mute":
        debugger;
        var options = { ...this.state.options, mute: e.data };
        this.setState({ options: options });
        setOption({ mute: e.data });
        break;

      default:
        break;
    }
  };

  render() {
    return (
      <div
        style={
          this.state.options?.background?.type == "img"
            ? {
                position: "relative",
                maxWidth: 390,
                backgroundImage: `${
                  this.state.options?.background?.image_url
                    ? `url(${this.state.options?.background?.image_url})`
                    : "url('https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/bg_2.png')"
                }`,
                backgroundSize: "cover",
              }
            : { position: "relative", maxWidth: 390, backgroundColor: this.state.options?.background?.color ? this.state.options.background?.color : "#880044" }
        }
      >
        <PopupNotificationReward ref={this.popup} options={this.state.options}></PopupNotificationReward>
        <Header onEvent={this.onEvent} options={this.state?.options} />
        <div id="gameDiv" style={{ width: "100%" }}></div>
        <Footer />
      </div>
    );
  }
}
