import { Button, Card, Col, Popconfirm, Row, Space, Typography, message } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import AddNewWooModal from "./AddNewWooModal";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";

const { Text, Title } = Typography;
function ConnectTab({ isConnected, setIsConnected }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configData, setConfigData] = useState();
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setIsLoading(true);
      getConfig();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (configData) {
      checkActive();
    }
  }, [configData]);

  const openAddConnect = () => {
    setIsModalOpen(true);
  };

  const checkActive = () => {
    api
      .post("app/woo/config/checkactivekey")
      .then((res) => {
        setIsActive(true);
        // if (res.length > 0) {
        //   setConfigData(res[0]);
        // } else {
        //   setConfigData();
        // }
      })
      .catch((err) => {
        // setConfigData();
        setIsActive(false);
        console.log(err);
      });
  };

  const getConfig = () => {
    api
      .post("app/woo/config/getconfig")
      .then((res) => {
        if (res.length > 0) {
          setIsConnected(true);
          setConfigData(res[0]);
          setIsLoading(false);
        } else {
          setIsConnected(false);
          setConfigData();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsConnected(false);
        setConfigData();
        setIsLoading(false);
        console.log(err);
      });
  };

  const deleteLink = (id) => {
    setIsLoading(true);
    api
      .post("app/woo/config/deleteconfig", { id: id })
      .then((res) => {
        getConfig();
      })
      .catch((err) => {
        message.error("Xoá thất bại");
        setIsLoading(false);
      });
  };
  return (
    <>
      {configData ? (
        <Card
          style={{ width: "50%" }}
          title={configData.url}
          extra={
            isActive ? (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52C41A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Hoạt động</Text>
              </Space>
            ) : (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Không hoạt động</Text>
              </Space>
            )
          }
        >
          <Col>
            <Row>
              <Space size={4}>
                <Text style={{ fontWeight: 600 }}>Key: </Text>
                <Text type="secondary">{configData.consumerKey}</Text>
              </Space>
            </Row>
            <Row>
              <Space size={4}>
                <Text style={{ fontWeight: 600 }}>Secret: </Text>
                <Text type="secondary">{configData.consumerSecret}</Text>
              </Space>
            </Row>
          </Col>
          <Row justify={"end"}>
            <Popconfirm
              title={<Text>Bạn có muốn xoá liên kết này?</Text>}
              onConfirm={() => {
                deleteLink(configData._id);
              }}
              okText="Có"
              cancelText="Không"
            >
              <Button type="link" style={{ color: "red", padding: 4 }}>
                Xoá liên kết
              </Button>
            </Popconfirm>
          </Row>
        </Card>
      ) : (
        <>
          <Button type="primary" icon={<PlusCircleFilled />} onClick={openAddConnect}>
            Thêm kết nối
          </Button>
          <AddNewWooModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </>
      )}
      <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />
    </>
  );
}

export default ConnectTab;
