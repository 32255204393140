import { Radio, Input, Space, Button, Modal, Form, Row, Col } from "antd";
import React, { Component } from "react";
import { makeid } from "../../../../common/custom_field";
import colorList from "./colorList";
import ColorPicker from "components/picker/color_picker";

function rdArray(ar) {
  let cur = ar.length,
    rdidx;
  while (cur != 0) {
    rdidx = Math.floor(Math.random() * cur);
    cur--;
    [ar[cur], ar[rdidx]] = [ar[rdidx], ar[cur]];
  }
  return ar;
}

export default class PopupColor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      visible: false,
      value: 1,
      colors: colorList(),
      isCustom: false,
      selectedColor: [],
    };
    this.form = React.createRef();
  }

  // componentDidMount() {
  //     this.setState({colors:this.state.colors.sort((a, b) => a.length - b.length).filter(i=>{return(
  //         i.length >= this.props.dataTable.length
  //         )})})
  //
  // }

  onFinish = (values) => {
    //UpdateColors

    if (values.formcolors === "NONENONE") return;
    let ar = [];
    let res = "";
    let dataTable = this.props.dataTable;
    if (values?.formcolors === "ahihi") {
      for (var key in values) {
        if (values.hasOwnProperty(key) && values[key] !== "ahihi") {
          ar.push(values[key]);
        }
      }
      res = [...rdArray(ar)].slice(0, dataTable.length);
    } else res = [...rdArray(this.state.colors[values.formcolors]).slice(0, dataTable.length)];
    dataTable.map((i, k) => {
      res[k].startsWith("#") ? (i.background = res[k]) : (i.background = "#" + res[k]);
    });
    this.props.addReward(dataTable);
    this.setState({ visible: false });
    this.form.current.resetFields();
    this.setState({ isCustom: false });
  };

  inputColor = (name) => {
    return (
      <div style={{ marginLeft: "15px" }}>
        <Form.Item
          label={"Màu " + name}
          name={"clr" + name}
          rules={
            this.state.isCustom && [
              {
                required: true,
                message: "Nhập màu của bạn.",
              },
            ]
          }
        >
          <ColorPicker />
        </Form.Item>
      </div>
    );
  };

  onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  handleCancel = () => {
    this.setState({ visible: false });
    this.setState({ isCustom: false });

    this.form.current.resetFields();
  };

  open() {
    this.setState({
      colors: this.state.colors
        .sort((a, b) => a.length - b.length)
        .filter((i) => {
          return i.length >= this.props.dataTable.length;
        }),
    });

    this.setState({ visible: true });
  }

  render() {
    return (
      <>
        <Modal
          title="Chọn màu"
          open={this.state.visible}
          onCancel={this.handleCancel}
          width="60%"
          footer={[
            <Button
              onClick={() => {
                this.setState(this.handleCancel);
              }}
            >
              Thoát
            </Button>,

            <Button
              type="dashed"
              onClick={() => {
                window.open("https://coolors.co/palettes/popular", "_blank");
              }}
            >
              Tham khảo màu khác ...
            </Button>,

            <Button
              type="primary"
              onClick={() => {
                this.form.current.submit();
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form name="Form" ref={this.form} onFinish={this.onFinish}>
            <Row type="flex" justify="space-around">
              <Form.Item name="formcolors" initialValue="NONENONE">
                <Radio.Group>
                  <Space direction="vertical">
                    <div style={{ maxHeight: "60vh", width: "100%", overflowY: "scroll" }}>
                      {this.state.colors?.map((i, k) => {
                        return (
                          <>
                            <Radio style={{ marginBottom: "2px", display: "flex", alignItems: "center" }} value={k} onClick={() => this.setState({ isCustom: false })}>
                              <Row>
                                {i.map((ii, k) => {
                                  return (
                                    <>
                                      {" "}
                                      <div style={{ width: 45, height: 35, backgroundColor: "#" + ii }}></div>{" "}
                                    </>
                                  );
                                })}
                              </Row>
                            </Radio>
                          </>
                        );
                      })}
                    </div>

                    {/*CUSTOM_HERE*/}
                    <Radio value={"ahihi"} onClick={() => this.setState({ isCustom: true })}>
                      {this.state.isCustom ? (
                        <Row style={{ marginTop: "30px" }}>
                          {this.props.dataTable.map((i, k) => {
                            return <> {this.inputColor(k + 1)} </>;
                          })}
                        </Row>
                      ) : (
                        <div style={{ marginTop: "30px" }}>Chọn màu khác ...</div>
                      )}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}
