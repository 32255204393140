import React from "react";
import { Form, Space, List, Row, Col, message, Input, Modal, Button } from "antd";
import api from "api";
export default class PopupLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        label: "",
        link: "",
      },
    };
    this.form = React.createRef();
  }

  open = (id) => {
    if (id) {
      this.getData(id);
    } else
      this.setState({ data: { id: "", label: "", link: "" }, visible: true }, () => {
        setTimeout(() => {
          this.form.current.setFieldsValue({ id: "", label: "", link: "" });
        }, 500);
      });
  };

  getData = (id) => {
    api.post("loyaltyconfig/getlinkbyid", { id: id }).then((res) => {
      this.setState({ data: res, visible: true }, () => {
        setTimeout(() => {
          this.form.current.setFieldsValue(res);
        }, 500);
      });
    });
  };

  finish = (value) => {
    api
      .post("loyaltyconfig/savelink", { data: value })
      .then((res) => {
        message.success("Lưu thành công");
        this.props.getData();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
    this.setState({ visible: false });
  };

  render() {
    return (
      <Modal
        title="Thêm liên kết hiển thị"
        open={this.state.visible}
        onOk={() => {
          this.form.current.submit();
        }}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        width={"50%"}
      >
        <Form ref={this.form} layout="vertical" onFinish={this.finish} name="addLink">
          <div>
            <Form.Item
              name="label"
              label="Hiển thị"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập trường này!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="link"
              label="Liên kết"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập trường này!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item name="id" hidden={true}></Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" hidden>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
