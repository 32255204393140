import { Alert, Badge, Button, Col, Modal, Progress, Result, Row, Space, Typography, message } from "antd";
import { InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import api from "api";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { is } from "immutable";

const { Text } = Typography;
function SyncModal({ isModalOpen, setIsModalOpen, totalProduct, setTotalProduct }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [percent, setPercent] = useState(0);
  // const [totalProduct, setTotalProduct] = useState(0);
  const [syncedProduct, setSyncedProduct] = useState(0);
  const [syncedProductName, setSyncedProductName] = useState();

  const handleCancel = () => {
    if ((isSync && percent === "100") || !isSync) {
      setIsModalOpen(false);
      setPercent(0);
    }
  };

  useEffect(() => {
    getTotalProduct();
  }, []);

  const getTotalProduct = () => {
    api
      .post("app/shopee/product/countproductshopee")
      .then((res) => {
        setTotalProduct(res.count);
      })
      .catch((err) => {
        console.log(err);
        setTotalProduct(0);
      });
  };

  const onSync = async () => {
    // setIsLoading(true);
    // if (isAcitve) {
    setIsSync(true);
    setIsLoading(false);
    listAllProductFromShopee();
    // }
  };

  //   const checkWooProductExistAlready = () => {
  //     api
  //       .get("woo_product/checkexistalready")
  //       .then((res) => {
  //         if (res) {
  //           deleteListWooProduct();
  //         } else {
  //           listAllProductFromWooCommerce();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err, "checkexistalready");
  //       });
  //   };

  //   const deleteListWooProduct = () => {
  //     api
  //       .post("woo_product/deletelist")
  //       .then((res) => {
  //         if (res) {
  //           listAllProductFromWooCommerce();
  //         } else {
  //           message.error("Xảy ra lỗi");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error, "deletelist");
  //       });
  //   };

  const listAllProductFromShopee = async () => {
    var splitString = (totalProduct / 50).toString().split(".");
    var loopTimes = splitString[1] ? parseInt(splitString[0]) + 1 : parseInt(splitString[0]);
    for (let i = 0; i < loopTimes; i++) {
      await api.post("app/shopee/product/getlistshopeeproduct", { pagging: { skip: i * 50, limit: 50, sort: {} } }).then(async (res) => {
        var listProduct = res.data;
        // console.log(listProduct);
        if (listProduct.length > 0) {
          // setTotalProduct(listWooProduct.length);
          listProduct = listProduct.filter((item, index) => {
            return index < totalProduct;
          });
          for (let index = 0; index < listProduct.length; index++) {
            const item = listProduct[index];
            await syncShopeeProduct(item, 50 * i + index, listProduct.length);
          }
        }
      });
    }
  };

  const syncShopeeProduct = async (shopeeProduct, index, total) => {
    await api
      .post("app/shopee/product/syncproduct", shopeeProduct)
      .then((res) => {
        // console.log(syncedProduct + 1);
        setSyncedProduct(index + 1);
        setSyncedProductName(shopeeProduct.item_name);
        // console.log(((index + 1) / totalProduct) * 100);
        setPercent((((index + 1) / totalProduct) * 100).toString().split(".")[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const checkCategoryExistAlready = (category) =>{
  //     api.post("miniapp_category/checkexistalready", category).then((res)=>{

  //     })
  //   }

  //   const checkActive = async () => {
  //     var isActive = await api
  //       .post("app/woo/config/checkactivekey")
  //       .then((res) => {
  //         if (res) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       })
  //       .catch((err) => {
  //         return false;
  //         // setConfigData();
  //         console.log(err);
  //       });

  //     return isActive;
  //   };
  return (
    <Modal
      className="sync-modal"
      title="Đồng bộ sản phẩm Shopee"
      footer={
        isSync
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
      onCancel={handleCancel}
      width={680}
      bodyStyle={{ height: 260 }}
      open={isModalOpen}
    >
      {isSync ? (
        percent === "100" ? (
          <Result
            status="success"
            title="Đồng bộ thành công"
            subTitle={`Có ${totalProduct} sản phẩm đã được đồng bộ vào mini app`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Progress type="circle" percent={percent} size="small" />
              <Text>
                {syncedProduct}/{totalProduct}
              </Text>
              <Text>{syncedProductName}</Text>
            </Space>
          </Row>
        )
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            className="sync-alert"
            message="Lưu ý"
            description={
              <Space direction="vertical">
                <Badge color="black" text="Khi đồng bộ, hệ thống sẽ tự động tạo mới sản phẩm tương ứng trên mini app" />
                <Badge color="black" text="Với các sản phẩm trước đó đã liên kết sẽ tự động cập nhật lại thông tin và giá bán" />
                <Badge
                  color="black"
                  text={
                    <Text>
                      Hệ thống chỉ lấy các sản phẩm đã <Text strong>"Publish"</Text>
                    </Text>
                  }
                />
                <Badge color="black" text='Hệ thống hỗ trợ 2 loại sản phẩm "Sản phẩm đơn giản" và "Sản phẩm có biến thể"' />
                <Badge color="black" text="Hệ thống hỗ trợ sản phẩm có 2 thuộc tính trở xuống" />
              </Space>
            }
            type="info"
            showIcon
            icon={<InfoCircleOutlined />}
          />
          <Row justify={"center"}>
            <Button type="primary" loading={isLoading} icon={!isLoading ? <SyncOutlined /> : ""} onClick={onSync}>
              Đồng bộ sản phẩm
            </Button>
          </Row>
        </Space>
      )}
    </Modal>
  );
}

export default SyncModal;
