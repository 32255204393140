import React, {useEffect} from 'react';

import useState from 'react-usestateref'
import Wheel from "./Wheel";
import "./index.css";
import api from "api"
import {useParams} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"

function Index(props) {
    const [target,setTarget, targetRef] = useState("^_^")
    const [loading, setLoading, loadingRef] = useState(true)
    const [data,setData,dataRef] = useState(props.data)
    const [config, setConfig, configRef] = useState(":v")
    const params = useParams()
    const [id, setId, idRef] = useState(params.id)

    //
    useEffect(()=>{
        AOS.init({
            duration : 3000
        })
        if (params.id == 0) {
            var data = {
                id: "",
                type: params.type,
                is_active: true,
                name: "Vòng quay may mắn",
                turn_limit: "",
                time_limit: "",
                way_to_send: [],
                reward_list: [],
                gameData:[],
            };
            setData(data)
        } else {
            api.post("gameprogram/getconfigbyid", {id: params.id}).then((res) => {
                setData(res)
                setLoading(false)
            });
        }
    },[])

    return (
        <>
            {!loadingRef.current &&
            (<div className="cardW-all-test">
                <Wheel data={dataRef.current} setTarget={setTarget} />
                <div >
                    Tỉ lệ trúng thưởng:<br/>
                    {dataRef.current.reward_list.map((i,k)=>{
                        return (
                            <div style={{color:"white",display:"flex", flexDirection:"row"}}>
                                <div style={{display:"block",width:"250px"}}>
                                    {i.name}
                                </div>
                                {i.percent}%

                            </div>
                        )
                    })}
                    <br/><br/>
                    Sẽ quay trúng:<br/><div style={{color:"white"}}>{targetRef.current}</div><br/>
                </div>
            </div>)}
        </>


    );
}

export default Index;