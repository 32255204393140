import { Badge, Button, Dropdown, Form, Input, Select, Space, message } from "antd";
import { MoreOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AntTable from "components/common/AntTable";
import React, { useEffect, useState } from "react";
import SyncModal from "./SyncModal";
import api from "api";
import numeral from "numeral";
import LoadingModal from "components/common/loading/LoadingModal";
import SyncReviewModal from "./SyncReviewModal";
import SyncAllReviewModal from "./SyncAllReviewModal";

function ProductTab({ totalProduct, setTotalProduct }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customSearch, setCustomSearch] = useState();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const refTable = React.createRef();
  const [isModalReviewOpen, setIsModalReviewOpen] = useState(false);
  const [isModalReviewAllOpen, setIsModalReviewAllOpen] = useState(false);
  const [productSelected, setProductSelected] = useState();
  const columns = [
    {
      title: "Ảnh",
      dataIndex: "image",
      key: "image",
      render: (image, record, index) => {
        return (
          <div
            style={{
              width: 80,
              height: 80,
              backgroundImage: `url(${image?.image_url_list?.length > 0 ? image?.image_url_list[0] : ""})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Biến thể",
      dataIndex: "model",
      key: "model",
      render: (model, record, index) => {
        // if(variations.length === 0){
        //     return 1
        // } else {
        return model.length;
        // }
      },
    },
    {
      title: "Mã",
      dataIndex: "item_id",
      key: "item_id",
    },
    {
      title: "Giá",
      dataIndex: "price_info",
      key: "price_info",
      render: (price_info, record, index) => {
        // if(record.has_model && record.model.length > 0 && record.model[0].){

        // }
        if (price_info) {
          return numeral(price_info[0].current_price).format("0,0");
        } else {
          return 0;
        }
      },
    },
    {
      title: "Kết nối với Loyalty",
      dataIndex: "is_link",
      key: "is_link",
      render: (is_link, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        if (is_link) {
          return <Badge color="green" text="Đã kết nối APP" />;
        } else {
          return <Badge color="red" text="Chưa kết nối APP" />;
        }
      },
    },
    {
      // title: "Kết nối với Loyalty",
      dataIndex: "item_id",
      key: "item_id",
      render: (slug, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        return (
          <Dropdown
            trigger={"click"}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Đồng bộ lại",
                },
                {
                  key: "2",
                  label: "Đồng bộ đánh giá",
                  disabled: record.is_link ? false : true,
                },
              ],
              onClick: (e) => {
                if (e.key === "1") {
                  syncHaravanProduct(record);
                } else {
                  setProductSelected(record);
                  setIsModalReviewOpen(true);
                }
              },
            }}
            placement="bottomLeft"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const syncHaravanProduct = (haravanProduct) => {
    // setIsLoading(true);
    api
      .post("app/shopee/product/syncproduct", haravanProduct)
      .then((res) => {
        // setIsLoading(false);
        refTable.current.reload();
        // checkIsLink(
        //   listWooProduct.map((item) => {
        //     return { woo_product_id: item.id };
        //   }),
        //   listWooProduct
        // );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        message.error("Đồng bộ thất bại");
      });
  };

  const openSyncModal = () => {
    setIsModalOpen(true);
  };

  const openSyncReviewModal = () => {
    setIsModalReviewAllOpen(true);
  };

  const onFinish = () => {
    var data = form.getFieldsValue();
    setCustomSearch(data);
  };

  useEffect(() => {
    if (customSearch || !isModalOpen) {
      refTable.current.reload();
    }
  }, [customSearch, isModalOpen]);
  return (
    <div>
      <Form form={form} name="basic" onFinish={onFinish} autoComplete="off">
        <Space>
          <Form.Item name="text">
            <Input placeholder="Tên sản phẩm hoặc mã sản phẩm" style={{ width: "calc(100vw - 376px)" }} />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: 112 }} icon={<SearchOutlined />} type="primary" htmlType="submit">
              Tìm kiếm
            </Button>
          </Form.Item>
          <Form.Item>
            <Dropdown.Button
              style={{ width: 112 }}
              // icon={<SyncOutlined />}
              type="primary"
              // ghost
              onClick={openSyncModal}
              menu={{ items: [{ key: "1", label: "Đồng bộ đánh giá Shopee" }], onClick: openSyncReviewModal }}
            >
              <SyncOutlined />
              Đồng bộ
            </Dropdown.Button>
            {/* <Button style={{ width: 112 }} icon={<SyncOutlined />} type="primary" ghost onClick={openSyncModal}>
              Đồng bộ
            </Button> */}
          </Form.Item>
        </Space>
      </Form>
      <AntTable
        columns={columns}
        autoLoad={true}
        customSearch={customSearch}
        tableName={"tbShopeeProduct"}
        ajax="app/shopee/product/getlistproduct"
        ref={refTable}
        rowKey={(record) => record.id}
      ></AntTable>
      {isModalOpen && <SyncModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} totalProduct={totalProduct} setTotalProduct={setTotalProduct} />}
      <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} />
      {isModalReviewOpen && <SyncReviewModal isModalOpen={isModalReviewOpen} setIsModalOpen={setIsModalReviewOpen} record={productSelected} />}
      {isModalReviewAllOpen && <SyncAllReviewModal isModalOpen={isModalReviewAllOpen} setIsModalOpen={setIsModalReviewAllOpen} totalProduct={totalProduct} />}
    </div>
  );
}

export default ProductTab;
