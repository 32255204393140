import React from "react";
import { Space, Modal, Pagination, Image } from "antd";
import api from "api";
import PreviewImage from "./itemImage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class ImageGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      lst: [],
      selectedSrc: null,
      paging: { page: 1, pageSize: 12 },
      preview: false,
      imagePreview: "",
    };
  }

  componentDidMount() {
    this.getImageGallery(this.props.tags);
    if (this.props.tags && this.props.tags[0] === "tier") {
      this.setState({ paging: { page: 1, pageSize: 9 } });
    }
    if (this.props.tags && this.props.tags[0] === "promotion") {
      this.setState({ paging: { page: 1, pageSize: 6 } });
    }
  }

  open = () => {
    this.setState({ isVisible: true, selectedSrc: null });
  };

  close = () => {
    this.setState({ isVisible: this.closes });
  };

  selectImg = (src) => {
    this.setState({ selectedSrc: src });
    this.props.onSelect(src);
    this.close();
  };

  handleOk = (e) => {
    if (this.state.selectedSrc) {
      this.selectImg(this.state.selectedSrc);
    } else {
      this.close();
    }
  };

  getPagingData() {
    var page = this.state.paging.page;
    var pageSize = this.state.paging.pageSize;
    var lstAll = this.state.lst;
    var lst = lstAll.slice((page - 1) * pageSize, page * pageSize);
    return lst;
  }

  onPageChanged = (page, pageSize) => {
    this.setState({
      paging: { page: page, pageSize: pageSize },
    });
  };

  renderItem = (item) => {
    var boderColor = "#f0f0f0";
    if (item.src == this.state.selectedSrc) {
      boderColor = "#458fff";
    }
    return (
      <div
        style={{ width: 100, height: 100, overflow: "hidden", border: `2px solid ${boderColor}`, borderRadius: 2, cursor: "pointer", overflow: "hidden", display: "flex" }}
        onClick={(e) => {
          this.setState({ selectedSrc: item.src });
        }}
        onDoubleClick={(e) => {
          this.selectImg(item.src);
        }}
      >
        <div style={{ width: "100%", margin: "auto" }}>
          <Image preview={true} width={"100%"} src={item.src} />
        </div>
      </div>
    );
  };

  onClickItem = (data) => {
    this.setState({ selectedSrc: data });
  };

  getImageGallery = (data) => {
    api.post("image_gallery/getimagegallery", { tags: data }).then((res) => {
      this.setState({
        lst: res,
      });
    });
  };

  previewImage = (data) => {
    this.setState({ preview: true, imagePreview: data });
  };

  render() {
    if (this.props.tags && this.props.tags[0] === "tier") {
      return (
        <Modal title="Select image" open={this.state.isVisible} onOk={this.handleOk} onCancel={this.close}>
          <Space size={[25, 16]} wrap style={{ marginLeft: "12%" }}>
            {this.getPagingData().map((item,index) => {
              return (
                <React.Fragment key={index}>
                <PreviewImage
                  tags={this.props.tags || []}
                  url={item.src}
                  name={item.name}
                  selectedSrc={this.state.selectedSrc}
                  selectImg={this.selectImg}
                  width={100}
                  height={100}
                  onClickItem={this.onClickItem}
                  previewImage={this.previewImage}
                ></PreviewImage>
                </React.Fragment>
              );
            })}
          </Space>
          <Pagination
            total={this.state.lst.length}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            defaultPageSize={9}
            defaultCurrent={1}
            showSizeChanger={true}
            pageSizeOptions={[9, 12, 24, 36]}
            onChange={this.onPageChanged}
            style={{ marginTop: 15, display: "flex", justifyContent: "center" }}
          />
          {this.state.preview && <Lightbox mainSrc={this.state.imagePreview} onCloseRequest={() => this.setState({ preview: false })} />}
        </Modal>
      );
    }
    if (this.props.tags && this.props.tags[0] === "promotion") {
      return (
        <Modal title="Select image" open={this.state.isVisible} onOk={this.handleOk} onCancel={this.close}>
          <Space size={[8, 16]} wrap>
            {this.getPagingData().map((item,index) => {
              return (
                <React.Fragment key={index}>
                <PreviewImage
                  url={item.src}
                  name={item.name}
                  tags={this.props.tags || []}
                  selectedSrc={this.state.selectedSrc}
                  selectImg={this.selectImg}
                  width={100}
                  height={100}
                  onClickItem={this.onClickItem}
                  previewImage={this.previewImage}
                ></PreviewImage>
                </React.Fragment>
              );
            })}
          </Space>
          <Pagination
            total={this.state.lst.length}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            defaultPageSize={6}
            defaultCurrent={1}
            showSizeChanger={true}
            pageSizeOptions={[6, 9, 12, 24]}
            onChange={this.onPageChanged}
            style={{ marginTop: 15, display: "flex", justifyContent: "center" }}
          />
          {this.state.preview && <Lightbox mainSrc={this.state.imagePreview} onCloseRequest={() => this.setState({ preview: false })} />}
        </Modal>
      );
    } else {
      return (
        <Modal title="Select image" open={this.state.isVisible} onOk={this.handleOk} onCancel={this.close}>
          <Space size={[8, 16]} wrap>
            {this.getPagingData().map((item,index) => {
              return (
                <React.Fragment key={index}>
                <PreviewImage
                  url={item.src}
                  name={item.name}
                  tags={this.props.tags || []}
                  selectedSrc={this.state.selectedSrc}
                  selectImg={this.selectImg}
                  width={100}
                  height={100}
                  onClickItem={this.onClickItem}
                  previewImage={this.previewImage}
                ></PreviewImage>
                </React.Fragment>
              );
            })}
          </Space>
          <Pagination
            total={this.state.lst.length}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            defaultPageSize={12}
            defaultCurrent={1}
            showSizeChanger={true}
            pageSizeOptions={[12, 24, 36]}
            onChange={this.onPageChanged}
            style={{ marginTop: 15, display: "flex", justifyContent: "center" }}
          />
          {this.state.preview && <Lightbox mainSrc={this.state.imagePreview} onCloseRequest={() => this.setState({ preview: false })} />}
        </Modal>
      );
    }
  }
}
