import React, { PureComponent } from "react";
import { Radio, Space, Button, Row, Tabs, Form, Tag, Modal, Card, Checkbox, Empty, Popconfirm, message } from "antd";
import AppConfig from '../../campaign/popup_app_config';
import ContentPickerPopup from '../../picker/content_picker/content_picker_popup';
import PopupSelectApp from '../../campaign/popupselectapp';
// import EditApp from './edit';

import api from "api";
import { Content } from "antd/lib/layout/layout";
const { TabPane } = Tabs;

export default class SendMethods extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            showData: false,
            lst_app: [],
            method_template: {
                "name": "",
                "app_id": "",
                "content": {
                    "rich_media": {
                        "html": "",
                        "subject": "",
                        "attachments": []
                    },
                    "text": ""
                },
                "logo": "",
                "parameters": [],
                "is_active": true
            },
            lstPicker: [],
        }
        this.AppConfig = React.createRef();
        this.ContentPicker = React.createRef();
        this.PopupSelectApp = React.createRef();
        this.EditApp = React.createRef();


    }
    componentDidMount() {
        this.getApp();
        if (this.props.events) {
            if (this.props.events[0]) {
                if (this.props.events[0].send_methods) {
                    var lst = this.props.events[0].send_methods.map(item => {
                        return {
                            id: item.app_id,
                        }
                    })
                    this.setState({ lstPicker: lst, })
                }

            }
            this.setState({ events: this.props.events });
        }

    }

    getApp = () => {
        api.post("shop/getinstalledapp").then((res) => {
            this.setState({ lst_app: res });
        }).catch(err => {

        });
    }

    onChangeEvents = (events) => {
        this.setState({ events });
        if (this.props.onChangeEvents)
            this.props.onChangeEvents(events);
    }

    addApp = (lst_app, id) => {
        //Get list app được thêm
        var events = [...this.props.events];
        var found = events.find(obj => obj.event_id == id);
        var lst = this.state.lstPicker;
        lst_app.forEach(item => {
            debugger
            var new_method = { ...this.state.method_template };
            new_method.logo = item.logo;
            new_method.name = item.name;
            new_method.app_id = item.id;
            var random = Math.random().toString().substring(2);
            new_method.parameters = item.parameters;
            if (found.send_methods) {
                // debugger
                // var duplicate = found.send_methods.find(item => item.id.includes(random));
                // if (duplicate != null)
                //     random = Math.random().toString().substring(2);
                new_method.id = item.id + '_' + random;
                var old_method = found.send_methods.find(item => item.app_id == new_method.app_id);
                if (old_method == null)
                    found.send_methods.push(new_method)
            }
            else {
                var lstnew = [];
                lstnew.push(new_method);
                found.send_methods = lstnew;
            }

            var appID = { id: item.id };
            lst.push(appID);
        })
        this.setState({ lstPicker: lst, events: events });
        this.onChangeEvents(events);
        // this.setState({ events }, () => console.log("events", this.state.events))
    }

    removeApp = (id) => {
        var events = [...this.props.events];
        var event = {};
        var lstapp = this.state.lstPicker;
        events = events.map(obj => {
            var methodInd = obj.send_methods.findIndex(x => x.id == id);
            if (methodInd != -1) {
                obj.send_methods.splice(methodInd, 1);
                lstapp.splice(methodInd, 1);
            }
            return obj;
        })
        this.setState({ lstPicker: lstapp, events: events })
        this.onChangeEvents(events);
        // this.setState({ events });
    }

    getData = async (program_id) => {
        await this.getApp();
        console.log(this.props.program_id)
        var sent_program_id = this.props.program_id;
        if (program_id != null)
            sent_program_id = program_id;
        if (sent_program_id)
            api.post("pointprogram/getmethodsbyid", { program_id: sent_program_id, type: this.props.type }).then(res => {
                if (res != null && res.length != 0) {
                    // this.setState({ events: res, showData: true });
                    this.onChangeEvents(res);
                }
            })
    }

    onChangeParameters = (parameters, id) => {
        var events = [...this.props.events];
        events = events.map(obj => {
            if(obj.send_methods){
                var method = obj.send_methods.find(x => x.id == id);
                if (method != null) {
                    method.parameters = parameters;
                }
                return obj;
            }
           return {};
        })
        this.onChangeEvents(events);
    }

    onChangeContent = (content, id) => {
        var events = [...this.props.events];
        events = events.map(obj => {
            if (obj.send_methods) {
                var method = obj.send_methods.find(x => x.id == id);
                if (method != null) {
                    method.content = content;
                }
            }

            return obj;
        })
        this.onChangeEvents(events);
    }

    onChangeAppActive = (value, id) => {
        var events = [...this.props.events];
        events = events.map(obj => {
            var method = obj.send_methods.find(x => x.id == id);
            if (method != null) {
                method.is_active = value;
            }
            return obj;
        })
        this.onChangeEvents(events);
    }

    tabChange(key) {
        var event = this.state.events.find(x => x.event_id === key);
        var lst = [];
        if (event) {
            if (event.send_methods) {
                event.send_methods.forEach(item => {
                    var id = { id: item.app_id };
                    lst.push(id)
                })
            }

        }
        this.setState({ lstPicker: lst });
    }

    render() {
        var events = [];
        if (this.props.events != null && Array.isArray(this.props.events))
            events = [...this.state.events];
        return (
            <Card title="Thông Báo" style={{ width: "50%", margin: 10 }}>
                <Tabs
                    type="card"
                    onChange={(key) => this.tabChange(key)}
                >
                    {(events != null && events.length != 0) ?
                        events.map(item => {
                            return (
                                <TabPane tab={item.event_name} key={item.event_id} >
                                    <div style={{ marginTop: 50 }}></div>
                                    <div>
                                        {item.send_methods != null && item.send_methods.length != 0 ?
                                            item.send_methods.map(data => {
                                                return (
                                                    <>
                                                        <Button type='primary' style={{ position: 'absolute', right: 30, top: 150 }} onClick={() => this.PopupSelectApp.current.open(item.event_id)}>Thêm Mới</Button>

                                                        <div style={{ display: "flex", padding: "20px" }}>
                                                            <div>
                                                                <img width={60} height={60} src={data.logo} />
                                                            </div>
                                                            <div style={{ marginLeft: "20px" }}>
                                                                <div >
                                                                    <span style={{ marginRight: 7 }}>
                                                                        <b>{data.name}</b>
                                                                    </span>&nbsp;
                                                                    {
                                                                        data.is_active ?
                                                                            <Tag color="green">Hoạt động</Tag> :
                                                                            <Tag color="red">Tạm dừng</Tag>
                                                                    }

                                                                </div>
                                                                <div>
                                                                    {/* <span>Nội dung: </span>&nbsp; <span>{(data.content.rich_media.subject != "" && data.content.rich_media.subject != null) ? data.content.rich_media.subject : data.content.text}</span> */}
                                                                    <span>Nội dung: </span>&nbsp;
                                                                    <a style={{ color: "blue" }} onClick={() => this.ContentPicker.current.open(data)}>Xem</a>


                                                                </div>
                                                                <div>
                                                                    {
                                                                        (data.app_id === "sms") ?
                                                                            <div><span>Brandname: </span>&nbsp; <span>{data.account != null ? data.account : ''}</span></div>
                                                                            :
                                                                            <div></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", color: "#1890FF", fontSize: 20 }}>
                                                            <i onClick={() => this.AppConfig.current.open(data.app_id, data.id, data)} className="fa fa-cog" style={{ width: 20, height: 20, marginRight: 10 }}></i>
                                                            <i onClick={() => this.ContentPicker.current.open(data)} className="fa fa-edit" style={{ width: 20, height: 20, marginRight: 10 }}></i>
                                                            <Popconfirm title="Bạn có chắc muốn xoá app?" onConfirm={() => this.removeApp(data.id)} okText="Có" cancelText="Không">
                                                                <i className="fa fa-trash" style={{ width: 20, height: 20 }}></i>
                                                            </Popconfirm>
                                                        </div>
                                                        <div style={{ width: "100%", borderTop: "1px solid #dfdfdf", marginTop: 20 }}></div>

                                                    </>

                                                )
                                            })
                                            : <><Empty style={{ marginTop: 40, marginBottom: 40 }} description={"Thêm phương thức gửi thông tin"}
                                            >
                                                <Button type='primary' onClick={() => this.PopupSelectApp.current.open(item.event_id)}>Thêm Mới</Button>

                                            </Empty>
                                            </>
                                        }
                                    </div>
                                </TabPane>
                            )
                        }) : <><Empty description={false} style={{ marginLeft: '16vw' }} />
                        </>
                    }
                </Tabs>
                <ContentPickerPopup onChangeAppActive={this.onChangeAppActive} mode="modal" onChange={this.onChangeContent} ref={this.ContentPicker} ></ContentPickerPopup>
                <AppConfig onChangeParameters={this.onChangeParameters} ref={this.AppConfig}></AppConfig>
                <PopupSelectApp finish={this.addApp} ref={this.PopupSelectApp} lst={this.state.lst_app} lstPicker={this.state.lstPicker} ></PopupSelectApp>
                {/* <EditApp ref={this.EditApp}></EditApp> */}
            </Card>
        );
    }
}