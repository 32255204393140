import React, { Component } from "react";
import api from "api";
import { Input, Button, Form, Checkbox, Row, Col, message, Modal, Radio } from "antd";

export default class PopupTestSendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {},
      receiver: "",
      loadBtn: false,
    };
    this.inputType = React.createRef();

    this.form = React.createRef();
  }

  open = (data) => {
    this.getData(data);
  };

  getData = (data) => {
    api.get("app/email/config/getconfig").then((res) => {
      this.setState({ visible: true, receiver: res.send_test_mail, data: data });
    });
  };

  validateemail = (data) => {
    if (data == null || data === "") return false;

    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var check = filter.test(data);
    if (check) {
      return true;
    }

    return false;
  };

  onChange = (value) => {
    this.setState({ receiver: value });
  };

  send = () => {
    if (!this.validateemail(this.state.receiver)) {
      message.error("Sai email người nhận!");
      return;
    }
    var data = {
      config: this.state.data,
      data: {
        to: this.state.receiver,
        html: "This is a email test of website LoyaltyTiger",
        subject: "LoyaltyTiger test email",
      },
    };
    api
      .post("app/email/default/testasync", data)
      .then((res) => {
        message.success("Gửi thành công", 3);
        this.setState({ loadBtn: false });
      })
      .catch((err) => {
        message.error("Gửi thất bại", 3);
        this.setState({ loadBtn: false });
      });
  };

  render() {
    return (
      <Modal
        title="Test Email "
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        footer={[
          <Button onClick={() => this.setState({ visible: false })}>Thoát</Button>,
          <Button
            type="primary"
            onClick={() => {
              this.setState({ loadBtn: true }, () => {
                this.send();
              });
            }}
          >
            Gửi
          </Button>,
        ]}
      >
        <div>
          <div>
            <label>Người nhận:</label>
          </div>
          <Input
            onPressEnter={(e) => {
              this.onChange(e.target.value);
              this.setState({ loadBtn: true }, () => {
                this.send();
              });
            }}
            value={this.state.receiver}
            onChange={(e) => {
              this.onChange(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
    );
  }
}
