import React, { Component } from "react";
import api from "api";
import { Input, Button, Space, Card, Form, Alert, Checkbox, Row, Col, message, Modal } from "antd";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        type: "",
        auth: {
          client_id: "",
          client_secret: "",
          BrandName: "",
        },
        name: "",
        id: null,
      },
      type: "",
      mode: "",
    };
    this.inputType = React.createRef();

    this.form = React.createRef();
  }

  ///lưu setting
  saveSetting = (data) => {
    api
      .post("app/trustsales/config/save", data)
      .then((res) => {
        message.success("Lưu thành công", 3);
        this.props.getConfig();
        this.setState({ visible: false, data: {} });
      })
      .catch((err) => {
        message.error(err.message, 3);
      });
  };

  open = (mode, element) => {
    if (mode === "add") {
      var data = {
        type: "",
        auth: {
          client_id: "",
          client_secret: "",
          BrandName: "",
        },
        name: "",
        id: null,
      };
      this.setState({ data: data, visible: true, mode: mode }, () => {
        this.setDataForm(data);
      });
    } else {
      this.setState({ data: element, visible: true, mode: mode, type: element.type }, () => {
        this.setDataForm(element);
      });
    }
  };

  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };

  render() {
    return (
      <Modal
        title="Cấu hình api TrustSales"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={[
          <div style={{ height: "30px" }}>
            <Button htmlType="submit" type="primary" form="form_trustsales">
              Lưu
            </Button>
          </div>,
        ]}
        width="50%"
      >
        <Form ref={this.form} layout="vertical" onFinish={this.saveSetting} id="form_trustsales">
          <Form.Item
            name="token"
            label={<b>Token</b>}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập token",
              },
            ]}
          >
            <Input placeholder="token" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="use_external" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                console.log("ee", e);
                var data = { ...this.state.data };
                data.use_external = e.target.checked;
                this.setState({ data: data });
              }}
            >
              Dùng đường dẫn riêng
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="external_url"
            hidden={!(this.state.data.use_external ?? false)}
            label={<b>Url</b>}
            rules={[
              {
                required: this.state.data.use_external ?? false,
                message: "Bạn chưa nhập url",
              },
            ]}
          >
            <Input placeholder="url" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="id"></Form.Item>

          <div>
            Vui lòng nhập thông tin do TrustSales cung cấp. Xem tài liệu hướng dẫn nhập tại <a href="/">đây</a>.
          </div>
        </Form>
      </Modal>
    );
  }
}
