import React, { Component } from "react";
import { Button, Select } from 'antd';
import moment from "moment";
import api from "api";
import SegmentsCampingPopup from '../../campaign/segment/index'


export default class SegmenPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            segments: [],
            options: [],
            loading: true,
            value: '',
        }
        this.SegmentsCampingPopup = React.createRef();
    }

    componentDidMount() {
        this.getSegment();
    }

    getSegment = () => {
        api.post('segments/segmentlist', { query: {}, pagging: {} }).then(res => {
            var segments = res.data;
            var options = segments.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            this.setState({ segments: segments, options: options, value: this.props.value, loading: false });

        }).catch(err => {
            
        })
    }


    onChange = e => {
        if (this.props.onChange) {
            this.setState({ value: e })
            this.props.onChange(e)
        }

        if(this.props.setValueSegment){
            let segments = this.state.segments;
            let segment = segments.find(x=>x.id === e)
            this.props.setValueSegment(segment)
        }
    }

    setBuilderBySegment = (segment) => {
        this.setState({ value: segment.id })
    }


    searchSegment = (e) => {
        api.post('segments/segmentlist', { query: { name: e }, pagging: {} }).then(res => {
            var segments = res.data;
            var options = segments.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            this.setState({ segments: segments, options: options });

        }).catch(err => {

        })

    }

    setSelectValue = (segment) => {
        var options = [
            {
                label: segment.name,
                value: segment.id
            }
        ]

        this.setState({ options: options, value: segment.id });

        if (this.props.onChange) {
            this.props.onChange(segment.id);
        }
        if(this.props.setValueSegment){
            this.props.setValueSegment(segment);
        }

    }


    render() {
        return (
            <div style={{ display: "flex"}}>
          
                <div style={{ flex: 1 }}>
                    <Select
                        loading={this.state.loading}
                        disabled={this.props.disabled}
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={this.state.options}
                        onChange={(e) => this.onChange(e)}
                        onSearch={(e) => this.searchSegment(e)}
                        value={this.props.value}
                        style={{width:"100%"}}
                        >
                    </Select>
                </div>
                <div>
                    <Button onClick={() => this.SegmentsCampingPopup.current.open(-1)} loading={this.state.loading} disabled={this.props.disabled}>...</Button>
                </div>
                <SegmentsCampingPopup ref={this.SegmentsCampingPopup} setFilter={(s) => this.setBuilderBySegment(s)} setSelectValue={(e) => this.setSelectValue(e)}></SegmentsCampingPopup>


            </div>
        )
    }
}