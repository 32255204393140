import React, { Component } from "react";
import api from "api";
import { Input, Button, Checkbox, Row, Col, message, Modal, DatePicker, Space, Spin, Result, Table, Form } from "antd";

import { io } from "socket.io-client";
import { wss_url } from "config";
import { Record } from "immutable";
import { getSocket } from "common/realtime";

const { RangePicker } = DatePicker;

export default class SyncPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isSync: false,
      totaldown: 0,
      currentdown: 0,
      percentdown: 0,
      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      download: true,
      mode: "download",
      lstErr: [],
      totalErr: 0,
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    var socket = getSocket("app_socket");
    console.log("path", `${wss_url}/app_socket`);

    socket.on("connect", () => {
      socket.on("sync_order_woo", (data) => {
        if (data.status === "finish") this.setState({ success: true });
        var percent = 0;
        if (data.total > 0) {
          percent = Math.floor((data.current / data.total) * 100);
        }
        this.setState({
          currentsync: data.current,
          totalsync: data.total,
          percentsync: percent,
        });
      });
    });
    this.socket = socket;
  };

  open = (id) => {
    this.setState({
      visible: true,
      id: id,
      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      isSync: false,
      download: true,

      lstErr: [],
      totalErr: 0,
      ignore: false,
    });
    //  this.syncOrder(shop);
  };

  syncOrder = (values) => {
    this.setState({ isSync: true, success: false, currentsync: 0 });
    api
      .post("app/woo/order/syncorder", {
        id: this.socket.id,
        config_id: this.state.id,
      })
      .then((data) => {
        this.setState({ isSync: false });
      });
  };

  render() {
    return (
      <Modal
        title="Đồng bộ dữ liệu"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={
          this.state.success
            ? [
                <Button onClick={() => this.setState({ visible: false, type: "" })} type="primary">
                  Thoát
                </Button>,
              ]
            : [
                <Button
                  onClick={() => {
                    this.syncOrder();
                  }}
                  loading={this.state.isSync}
                  type="primary"
                >
                  Đồng bộ
                </Button>,
              ]
        }
        width="50%"
      >
        <div>
          {this.state.success === false ? (
            <div>
              <div>
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <h2 style={{ margin: "auto" }}>Đồng bộ dữ liệu</h2>
                </div>

                <label>Tiến độ đồng bộ: Đã đồng bộ được&nbsp;{this.state.currentsync}&nbsp;đơn hàng</label>
              </div>
            </div>
          ) : (
            <Result status="success" title="Đồng bộ thành công" subTitle={this.state.currentsync + " đơn hàng"} />
          )}
        </div>
      </Modal>
    );
  }
}
