import React, { Component } from "react";
import { Modal, Button, Table, message, Form, Checkbox, Upload } from "antd";
import MyInputNumber from "components/picker/inputnumber_picker";
import ReasonPicker from "./reasonpicker";
import api from "api";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const { Dragger } = Upload;
export default class PopupInitTotalSpent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      visible: false,
      lstCus: [],
      importExcel: false,
      fileName: "",
      lstErr: [],
      loadings: false,
      list: [],
      failHeader: false,
    };
    this.form = React.createRef();
  }

  open = () => {
    this.setState({
      data: {},
      visible: true,
      lstCus: [],
      importExcel: false,
      fileName: "",
      lstErr: [],
      loadings: false,
      list: [],
      failHeader: false,
    });
    setTimeout(() => {
      this.form.current.setFieldsValue({ money: null, reason: null });
    }, 100);
  };

  close = () => {
    this.setState({ visible: false });
  };

  finish = (values) => {
    var lstData = [];
    if (this.state.importExcel) {
      if (this.state.lstCus.length === 0) {
        message.info("Chưa ghi nhận được khách hàng nào");
        return;
      } else {
        lstData = this.state.lstCus.map((item) => {
          this.setState({
            loadings: false,
          });
          return { ...item, ...values };
        });
      }
    } else {
      if (this.props.lstCus.length === 0) {
        message.info("Hãy chọn khách hàng cần thay đổi ở màn hình chính hoặc dùng chức năng importexcel");
        return;
      } else {
        lstData = this.props.lstCus.map((item) => {
          return { customer_id: item.id, ...values };
        });
      }
    }
    console.log("lstData", lstData);

    api
      .post("/customer/totalspent", lstData)
      .then((res) => {
        this.setState({
          loadings: false,
          list: res,
        });
        if (res.length === 0) {
          message.success("Cập nhật thành công");
        } else {
          message.error("Có lỗi xảy ra trong quá trình cập nhật");
        }
      })
      .catch((error) => {
        this.setState({
          loadings: false,
        });
        message("Có lỗi xảy ra");
      });
  };

  exportExcel = () => {
    var lstdata = this.state.list;
    var lstexcel = lstdata.map((item) => {
      var o = {};

      o["Mã khách hàng"] = item["id"];
      o["Số điện thoại"] = item["phone"];
      o["Email"] = item["email"];
      o["Lỗi"] = item["message"];

      return o;
    });

    const ws = XLSX.utils.json_to_sheet(lstexcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dt = new Blob([excelBuffer], { type: ".xlsx" });
    FileSaver.saveAs(dt, "danh sách lỗi.xlsx");
  };

  onCheckboxChange = (checked) => {
    this.setState({ importExcel: checked });
  };

  dragChange = (info) => {
    var f = info.file.originFileObj;
    var reader = new FileReader();
    reader.onload = function (evt) {
      var data = evt.target.result;

      var workbook = XLSX.read(data, { type: "array" });

      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var headerData = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
      var sheetData = XLSX.utils.sheet_to_json(first_worksheet, { header: 0 });
      if (!sheetData) sheetData = [];

      var checkHeader = this.checkColumnFile(headerData);
      var lstErr = [];
      if (!checkHeader) lstErr = this.validate(sheetData);
      this.setState({ lstCus: sheetData, fileName: info.file.name, headerData: headerData, failHeader: checkHeader, lstErr: lstErr });
    }.bind(this);
    reader.readAsArrayBuffer(f);
  };

  checkColumnFile(headerRow) {
    if (!headerRow[0]) {
      return true;
    }
    if (!headerRow[0].find((x) => x === "customer_id")) {
      return true;
    }
    if (!headerRow[0].find((x) => x === "phone")) {
      return true;
    }
    if (!headerRow[0].find((x) => x === "email")) {
      return true;
    }
    if (!headerRow[0].find((x) => x === "money")) {
      return true;
    }
    return false;
  }

  validate = (lst) => {
    var lstErr = [];
    lst.forEach((item) => {
      if (item.customer_id == null) lstErr.push({ row: item.__rowNum__ + 1, error: "Không có mã khách hàng" });
      if (!this.validatephone(item.phone) && !this.validateemail(item.email)) lstErr.push({ row: item.__rowNum__ + 1, error: "Không số điện thoại và email!" });
      if (item.money == null) lstErr.push({ row: item.__rowNum__ + 1, error: "Không có điểm cập nhật" });
    });
    return lstErr;
  };

  validatephone = (data) => {
    if (!data) {
      return false;
    }
    var filter = /^\+?(?:[0-9]●?){2,14}[0-9]$/g;
    var check = filter.test(data);
    if (check) return true;

    return false;
  };

  validateemail = (data) => {
    if (data == null || data === "") return false;

    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var check = filter.test(data);
    if (check) {
      return true;
    }

    return false;
  };

  showError = (lstErr) => {
    var columns = [
      {
        title: "Dòng",
        dataIndex: "row",
        key: "row",
      },
      {
        title: "Lỗi",
        dataIndex: "error",
        key: "error",
      },
    ];
    return (
      <>
        {lstErr.length > 0 && (
          <div style={{ borderTop: "3px solid #fc0000" }}>
            <Table dataSource={lstErr} columns={columns} />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Modal
          title="Tiền chi tiêu"
          open={this.state.visible}
          onOk={() => this.form.current.submit()}
          onCancel={() => this.close()}
          okText="Cập nhật"
          cancelText="Thoát"
          footer={[
            <>
              {this.state.list.length > 0 && (
                <div style={{ float: "left" }}>
                  <Button onClick={() => this.exportExcel()}>Tải file excel lỗi</Button>
                </div>
              )}

              <Button onClick={() => this.close()}>Thoát</Button>
              <Button
                type="primary"
                loading={this.state.loadings}
                onClick={() => {
                  this.setState({ loadings: true }, () => this.form.current.submit());
                }}
                disabled={(this.state.lstErr.length > 0 || this.state.failHeader) && this.state.importExcel}
              >
                Cập nhật
              </Button>
            </>,
          ]}
        >
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Checkbox
              onChange={(e) => {
                this.onCheckboxChange(e.target.checked);
              }}
              checked={this.state.importExcel}
            >
              Nhập file excel
            </Checkbox>
          </div>

          <Form ref={this.form} layout="vertical" name="updatepoint" onFinish={this.finish}>
            {this.state.importExcel ? (
              <div style={{ marginBottom: 20 }}>
                <p>
                  Xin tải file dữ liệu mẫu
                  <a target="_blank" href="/Excel/updatetotalspent.xlsx">
                    {" tại đây"}
                  </a>
                </p>
                <Dragger name="file" multiple={false} onChange={(info) => this.dragChange(info)} showUploadList={false} accept=".xlsx" action="#">
                  <p style={{ fontSize: 30, color: "#1890FF" }}>
                    <i className="fa fa-upload"></i>
                  </p>
                  <p>Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                  {this.state.failHeader && (
                    <p>
                      <i className="far fa-times-circle" style={{ color: "#fc0000" }}></i>
                      {" Sai cấu trúc file, vui lòng nhập dữ liệu theo file mẫu"}
                    </p>
                  )}
                  {this.state.lstCus.length > 0 && !this.state.failHeader && (
                    <p>
                      Đã ghi nhận {this.state.lstCus.length} mã khách hàng từ file {this.state.fileName}
                    </p>
                  )}
                </Dragger>
              </div>
            ) : (
              <Form.Item
                name="money"
                label="Tiền"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập điểm",
                  },
                ]}
              >
                <MyInputNumber step={10}></MyInputNumber>
              </Form.Item>
            )}

            {this.state.lstErr.length === 0 ? (
              <>
                <Form.Item name="reason" label="Lý do">
                  <ReasonPicker></ReasonPicker>
                </Form.Item>
              </>
            ) : (
              this.showError(this.state.lstErr)
            )}
          </Form>
        </Modal>
      </>
    );
  }
}
