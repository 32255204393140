import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import api from "api";
import LoadingModal from "components/common/loading/LoadingModal";
import AddNewConnectModal from "./AddNewConnectModal";
import SyncModal from "./SyncModal";

const { Text, Title,Paragraph } = Typography;
function ConnectTab({
  isConnected,
  setIsConnected,
  isActive,
  setIsActive,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSyncOpen, setIsModalSyncOpen] = useState(false);
  const [configData, setConfigData] = useState();
  // const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState({
    total_kiot_order:0,
    total_system_order:0
  });

  useEffect(() => {
    if (!isModalOpen) {
      setIsLoading(true);
      getConfig();
    }
  }, [isModalOpen]);

  // useEffect(() => {
  //   if (configData) {
  //     setIsActive(true);
  //   }
  // }, [configData]);

  const openAddConnect = () => {
    setIsModalOpen(true);
  };

  const getConfig = () => {
    api
      .post("app/kiotvietsalon/config/getconfig")
      .then((res) => {
        if (res.ClientSecret) {
          setIsConnected(true);
          setConfigData(res);
          setIsLoading(false);
        } else {
          setIsConnected(false);
          setConfigData();
          setIsLoading(false);
          setIsActive(false);
        }
      })
      .catch((err) => {
        setIsConnected(false);
        setConfigData();
        setIsLoading(false);
        setIsActive(false);
        console.log(err);
      });
  };

  const deleteLink = (id) => {
    setIsLoading(true);
    api
      .post("app/kiotvietsalon/config/deleteconfig")
      .then((res) => {
        getConfig();
      })
      .catch((err) => {
        message.error("Xoá thất bại");
        setIsLoading(false);
      });
  };

  const openSyncModal = () => {
    setIsModalSyncOpen(true);
  };

  const reload =()=>{
    console.log("reload")
  }

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton.Button
            style={{ height: "calc(100vh - 288px)" }}
            active={true}
            shape={"square"}
            block={true}
          />
        </>
      ) : (
        <>
          {configData ? (
            <Card
              style={{ width: "50%" }}
              title={"API Key Kết Nối"}
              extra={
                isActive ? (
                  <Space>
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect
                          x="-0.00250244"
                          y="-0.000747681"
                          width="6.0025"
                          height="6.0015"
                          rx="3.00075"
                          fill="#52C41A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect
                            x="-0.00250244"
                            y="-0.000747681"
                            width="6.0025"
                            height="6.0015"
                            rx="3.00075"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Hoạt động</Text>
                  </Space>
                ) : (
                  <Space>
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect
                          x="-0.00250244"
                          y="-0.000747681"
                          width="6.0025"
                          height="6.0015"
                          rx="3.00075"
                          fill="red"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect
                            x="-0.00250244"
                            y="-0.000747681"
                            width="6.0025"
                            height="6.0015"
                            rx="3.00075"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Không hoạt động</Text>
                  </Space>
                )
              }
            >
              <Space direction="vertical" size={8}>
                <Paragraph>
                    <Text strong>ClientID: </Text>
                    <Text type="secondary">{configData.ClientID}</Text>
                </Paragraph>
                <Paragraph>
                    <Text strong>Mã bảo mật: </Text>
                    <Text type="secondary">{configData.ClientSecret}</Text>
                </Paragraph>
                <Paragraph>
                    <Text strong>Đơn hàng: </Text>
                    <Text type="secondary">{configData.total_system_order}/{configData.total_kiot_order}</Text>
                    <Button  type="link" onClick={openSyncModal}> Đồng bộ </Button>
                </Paragraph>
              </Space>
              <Row justify={"end"}>
                <Popconfirm
                  title={<Text>Bạn có muốn xoá liên kết này?</Text>}
                  onConfirm={() => {
                    deleteLink();
                  }}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button type="link" style={{ color: "red", padding: 4 }}>
                    Xoá liên kết
                  </Button>
                </Popconfirm>
              </Row>
                        
              <SyncModal
                setIsModalOpen={setIsModalSyncOpen}
                isModalOpen={isModalSyncOpen}
                totalProduct={configData.total_kiot_order}
                reload={reload}
                setTotalProduct={configData.total_kiot_order}
              />
      
            </Card>
          ) : (
            <>
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={openAddConnect}
              >
                Thêm kết nối
              </Button>
              <AddNewConnectModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </>
          )}
          {/* <LoadingModal isModalOpen={isLoading} setIsModalOpen={setIsLoading} /> */}
        </>
      )}
    </>
  );
}

export default ConnectTab;
