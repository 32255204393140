import React from "react";
import { Layout, Col, Row, Tooltip, Breadcrumb, Image, Avatar, Dropdown, Button, Space, notification, Menu, Typography, Divider, Popover } from "antd";
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
  BellOutlined,
  DownOutlined,
  MoneyCollectOutlined,
  UserOutlined,
  KeyOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import ParentMenu from "./menu/menu.jsx";
import Redirect from "./menu/redirect.jsx";
import SubMenu from "./menu/submenu.jsx";
import PointProgram from "../marketing/pointprogram.jsx";
import Test from "../customer/test.jsx";
import Tag from "../setting/tag";
import "./admin.css";
import Customer from "../customer";
import Campaign from "../campaign/index";
import AppConnection from "components/appconnection";
import ProgramConfig from "components/marketing/programconfig.jsx";
import OpenCustomer from "components/customer/opencustomer/index.jsx";
import PointProgramList from "components/marketing/pointprogramlist.jsx";
import PointawardProgram from "components/marketing/config/pointawardprogram.jsx";
import BirthdayProgram from "components/marketing/config/birthdayprogram.jsx";
import LuckySpin from "components/marketing/config/luckyspin.jsx";
import FBShareProgram from "components/marketing/config/fbshareprogram.jsx";
import CustomerFieldNew from "components/setting/customer_field/customerfield_new.jsx";
import DashBoard from "components/dashboard";
import Payment from "components/payment";
import TransactionManagement from "components/payment/transaction_management";

import ApiConfig from "components/api_config/index.jsx";

import { getSocket } from "common/realtime";
import ProFile from "../profile/profile";

import RewardPointProgramList from "components/marketing/exchange_point_program/rewardpointprogramlist.jsx";
// import VoucherFreeshipProgram from "components/marketing/exchange_point_program/config/voucherfreeshipprogram.jsx";
// import VoucherPercentage from "components/marketing/exchange_point_program/config/exchangepercentageoff.jsx";
import VoucherConfig from "components/voucher/voucherconfig.jsx";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

import Page1 from "components/page1";
import api from "api";
import MemberShipLevel from "components/marketing/membership_level/index.jsx";
import Notifications from "components/customer_notifications/notifications";
import Registration from "components/registration/registration";
import PointHistory from "components/point_history/index.jsx";
import PointReward from "components/point_reward";
import Orders from "components/orders";
import QrCodeConfig from "components/point_reward/qrcode_config.jsx";
import GameProgramList from "components/marketing/gameprogram/gameprogramlist.jsx";
import SendHistory from "components/send_history";
import ReceiveToken from "components/app/zalov2/receive_token.jsx";
import LoyaltyHeader from "./LoyaltyHeader.jsx";
//import UpdateSendHistoryByExcel from "components/send_history/update_send_history_by_excel.jsx";
//master
//=====Begin=======
import PaypalConfig from "components/master/paypal_config/index.jsx";
//=====end=======

import AppConfig from "components/app/index";

//===== Tien =======
//=====Begin=======
import Segment from "components/campaign/segment";
import UserConfig from "components/setting/user_config";
import APIkeyConfig from "components/setting/apikey_config";
import SmsFptAppConfig from "components/app/smsfpt/config/index.jsx";
import SapoAppConfig from "components/app/sapo/config/index.jsx";
import ExchangeProgram from "components/marketing/exchange_point_program/config/exchangeprogram.jsx";
import VoucherList from "components/voucher/index.jsx";
// import ExchangeGift from "components/marketing/exchange_point_program/config/exchange_gift.jsx";
import Branding from "components/marketing/display/index.jsx";
import DefaultThemeConfig from "components/marketing/display/theme/DefaultThemeConfig.jsx";
import ZaloMiniAppThemeConfig from "components/marketing/display/theme/ZaloMiniAppThemeConfig.jsx";
import GameTest from "components/marketing/config/gameTest/index";

import Billing from "components/billing";
import Plans from "components/master/plans/index.jsx";
import { io } from "socket.io-client";
import { wss_url } from "config";
import PopupQr from "components/point_reward/popup_qr";

import SpinGame from "components/game/spin";

import Blog from "components/blog/blog";
import BlogList from "components/blog/list";
import ExchangePointProgram from "components/marketing/exchange_point_program";
import GameProgram from "components/marketing/game_program.jsx";
import PointConfig from "components/marketing/point_config.jsx";

// import CampaignEditor from "components/campaign/edit.jsx";
import AddEditCampaign from "components/campaign/addEditCampaign.jsx";
import { Component } from "react";
import { formatDecimal } from "components/common/format/number.js";
import { blue } from "@ant-design/colors";
import numeral from "numeral";
import Review from "components/review/review.jsx";
import Product from "components/product/product.jsx";
import AddEditProductHOC from "components/product/AddEditProduct.jsx";
import MiniAppBanner from "components/marketing/miniapp_banner/miniapp_banner.jsx";
import AddEditLuckyWheel from "components/game/spin/AddEditLuckyWheel.jsx";
import GameProgramHoc from "components/marketing/game_program.jsx";
import GameConfig from "components/game/index.jsx";
import HaravanSync from "components/app/haravan/HaravanSync.jsx";
import Shopee from "components/app/shopee/index.jsx";
import ShopeeSync from "components/app/shopee/ShopeeSync.jsx";
import TiktokSync from "components/app/tiktok_shop/TiktokSync.jsx";
import LiveOrder from "components/orders/LiveOrder.jsx";
import CampaignExcel from "components/campaign/campaign_excel/index.jsx";
import AddCampaignExcel from "components/campaign/campaign_excel/addCampaignExcel.jsx";
import OrderTabs from "components/orders/OrderTabs.jsx";
import WebhookHistory from "components/marketing/display/theme/webhook_config/WebhookHistory.jsx";
import ServiceOrder from "components/service_order/index.jsx";
import Location from "components/location/index.jsx";
import Service from "components/service/service.jsx";
import AddEditServiceHOC from "components/service/AddEditService.jsx";

//=====END==========

const { Header, Content } = Layout;
const { Text } = Typography;

class App extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.max_span = 24;
    this.content_small_span = 21;
    this.content_large_span = 23;
    this.submenu_large_span = 2.5;
    this.submenu_small_span = 1.25;

    this.state = {
      check_token: false,
      collapsed: true,
      child_collapsed: urlParams.get("child_collapsed"),
      card_background: "transparent",
      menu: [
        {
          key: "home",
          name: "Trang chủ",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("home", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Trang chủ"}>
                <i className="fa fa-home" />
              </Tooltip>
            </div>
          ),
          path: "/home",
          child: [],
        },

        {
          key: "marketing",
          name: "Loyalty",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("marketing", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Loyalty"}>
                <i className="fas fa-gift" />
              </Tooltip>
            </div>
          ),
          path: "/marketing",
          child: [
            {
              key: "point-program",
              label: "Tích Điểm",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/point-program", true);
                    }
                  }}
                >
                  <i className="fa fa-plus" />
                </div>
              ),
              path: "/marketing/point-program",
              child: [],
            },
            {
              key: "exchange-point-program",
              label: "Đổi Điểm",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/exchange-point-program", true);
                    }
                  }}
                >
                  <i className="fa fa-gift" />
                </div>
              ),
              path: "/marketing/exchange-point-program",
              child: [],
            },
            {
              key: "game-program",
              label: "Trò Chơi",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/game-program", true);
                    }
                  }}
                >
                  <i className="fas fa-gamepad"></i>
                </div>
              ),
              path: "/marketing/game-program",
              child: [],
            },
            {
              key: "point-config",
              label: "Cấu Hình Điểm",
              icon: (
                <div
                  style={{ width: "Cấu Hình Điểm", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/point-config", true);
                    }
                  }}
                >
                  <i className="fas fa-wrench"></i>
                </div>
              ),
              path: "/marketing/point-config",
              child: [],
            },
            {
              key: "membership_level",
              label: "Hạng TV",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/membership_level", true);
                    }
                  }}
                >
                  <i className="fa fa-clock" />
                </div>
              ),
              path: "/marketing/membership_level",
              child: [],
            },
            {
              key: "branding",
              label: "Mini app",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/branding", true);
                    }
                  }}
                >
                  <i className="fas fa-tv"></i>
                </div>
              ),
              path: "/marketing/branding",
              child: [],
            },
            {
              key: "blog",
              label: "Khám phá",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/blog", true);
                    }
                  }}
                >
                  <i className="fas fa-blog"></i>
                </div>
              ),
              path: "/marketing/blog",
              child: [],
            },

            {
              key: "review",
              label: "Đánh giá",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/review", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/review",
              child: [],
            },
            {
              key: "product",
              label: "Sản phẩm",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/product", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/product",
              child: [],
            },
            {
              key: "miniapp_banner",
              label: "Banner",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/miniapp_banner", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/miniapp_banner",
              child: [],
            },
            {
              key: "order",
              label: "Đơn hàng",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/order", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/order",
              child: [],
            },
            {
              key: "location",
              label: "Chi nhánh",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/location", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/location",
              child: [],
            },
            {
              key: "service",
              label: "Dịch vụ",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/service", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/service",
              child: [],
            },
            {
              key: "service_order",
              label: "Lịch hẹn",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/marketing/service_order", true);
                    }
                  }}
                >
                  <i class="fas fa-star"></i>
                </div>
              ),
              path: "/marketing/service_order",
              child: [],
            },
          ],
        },
        {
          key: "customer",
          name: "Khách hàng",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("customer", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Khách hàng"}>
                <i className="fa fa-user" />
              </Tooltip>
            </div>
          ),
          path: "/customer",
          child: [
            {
              key: "list",
              label: "Danh sách khách hàng",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/customer/list", true);
                    }
                  }}
                >
                  <i className="fa fa-user" />
                </div>
              ),
              path: "/customer/list",
              child: [],
            },
            {
              key: "segment",
              label: "Nhóm khách hàng",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/customer/segment", true);
                    }
                  }}
                >
                  <i className="fas fa-users" />
                </div>
              ),
              path: "/customer/segment",
              child: [],
            },
          ],
        },

        {
          key: "campaign",
          name: "Chiến dịch",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("campaign", true);
                }
                //không phần biệt được path /campaign và /campaign/:id,...
                if (window.location.pathname != "/campaign/normal") {
                  this.props.navigate("/campaign/normal");
                }
              }}
            >
              <Tooltip placement="left" title={"Chiến dịch"}>
                <i className="fas fa-bullhorn" />
              </Tooltip>
            </div>
          ),
          path: "/campaign",
          child: [
            {
              key: "normal",
              label: "Chiến dịch",
              // icon: (
              //   <div
              //     style={{ width: "auto", height: "40px" }}
              //     onMouseDown={(event) => {
              //       if (event.button === 2) {
              //         this.onSelectChildMenu("/campaign/normal", true);
              //       }
              //     }}
              //   >
              //     <i className="fa fa-user" />
              //   </div>
              // ),
              path: "/campaign/normal",
              child: [],
            },
            {
              key: "zns_excel",
              label: "Gửi ZNS excel",
              // icon: (
              //   <div
              //     style={{ width: "auto", height: "40px" }}
              //     onMouseDown={(event) => {
              //       if (event.button === 2) {
              //         this.onSelectChildMenu("/campaign/zns_excel", true);
              //       }
              //     }}
              //   >
              //     <i className="fa fa-user" />
              //   </div>
              // ),
              path: "/campaign/zns_excel",
              child: [],
            },
          ],
        },

        {
          key: "apps",
          name: "Apps",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("apps", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Apps"}>
                <i className="fas fa-vector-square" />
              </Tooltip>
            </div>
          ),
          path: "/apps",
          child: [],
        },

        {
          key: "notifications",
          name: "Thông báo khách hàng",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("notifications", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Thông báo khách hàng"}>
                <i className="far fa-bell"></i>
              </Tooltip>
            </div>
          ),
          path: "/notifications",
          child: [],
        },
        {
          key: "setting",
          name: "Cài đặt",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("setting", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Cài đặt"}>
                <i className="fa fa-cog" />
              </Tooltip>
            </div>
          ),
          path: "/setting",
          child: [
            {
              key: "Users",
              label: "Tài khoản",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/setting/Users", true);
                    }
                  }}
                >
                  <i className="fa fa-user" />
                </div>
              ),
              path: "/setting/Users",
              child: [],
            },
            {
              key: "profile",
              label: "Hồ sơ",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/setting/profile", true);
                    }
                  }}
                >
                  <i className="fa fa-user" />
                </div>
              ),
              path: "/setting/profile",
              child: [],
            },
            {
              key: "Tag",
              label: "Nhãn",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/setting/Tag", true);
                    }
                  }}
                >
                  <i className="fa fa-tags" />
                </div>
              ),
              path: "/setting/Tag",
              child: [],
            },

            {
              key: "customerconfig",
              label: "Trường khách hàng",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/setting/customerfield", true);
                    }
                  }}
                >
                  <i className="fa fa-address-card"></i>
                </div>
              ),
              path: "/setting/customerfield",
              child: [],
            },
            {
              key: "apiconfig",
              label: "API",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.setPareonSelectChildMenuntKey("/setting/apikey", true);
                    }
                  }}
                >
                  <i className="fa fa-key"></i>
                </div>
              ),
              path: "/setting/apikey",
              child: [],
            },

            // {
            //   key: "billing",
            //   name: "Thanh toán hoá đơn",
            //   icon:<Tooltip placement="left" title={text} >

            //   "Thanh toán hoá đơn": "/setting/billing",
            //   child: [],
            // },
          ],
        },
        {
          key: "pointhistory",
          name: "Lịch sử điểm",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("pointhistory", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Lịch sử điểm"}>
                <i className="far fa-clock"></i>
              </Tooltip>
            </div>
          ),
          path: "/pointhistory",
          child: [],
        },
        {
          key: "pointreward",
          name: "Tích điểm bằng QR",
          icon: (
            <div
              style={{ width: "auto", height: "40px" }}
              onMouseDown={(event) => {
                if (event.button === 2) {
                  this.setParentKey("pointreward", true);
                }
              }}
            >
              <Tooltip placement="left" title={"Tích điểm bằng QR"}>
                <i className="fas fa-qrcode"></i>
              </Tooltip>
            </div>
          ),
          path: "/pointreward",
          child: [
            {
              key: "pointreward_list",
              label: "Danh sách mã tích điểm",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/pointreward/pointreward_list", true);
                    }
                  }}
                >
                  <i className="fas fa-qrcode"></i>
                </div>
              ),
              path: "/pointreward/pointreward_list",
              child: [],
            },
            {
              key: "qrcodeconfig",
              name: "Lấy mã token",
              icon: (
                <div
                  style={{ width: "auto", height: "40px" }}
                  onMouseDown={(event) => {
                    if (event.button === 2) {
                      this.onSelectChildMenu("/pointreward/qrcodeconfig", true);
                    }
                  }}
                >
                  <Tooltip placement="left" title={"Lấy mã token"}>
                    <i className="fab fa-get-pocket"></i>
                  </Tooltip>
                </div>
              ),
              path: "/pointreward/qrcodeconfig",
              child: [],
            },
          ],
        },
      ],
      current_parent_key: "",
      current_child_key: "",
      parent_menu_visible: false,
      sub_menu_visible: false,
      submenu_span: this.submenu_small_span,
      sub_span: this.max_span,
      default_key: "home",
      data: {},
      loadData: false,
    };

    this.ParentMenu = React.createRef();
    this.SubMenu = React.createRef();
    this.Router = React.createRef();
    this.PopupQr = React.createRef();
  }

  onSelectChildMenu = (path, newpage) => {
    if (newpage) {
      window.open(path, "_blank").focus();
      return;
    }
    if (this.props.collapsed) this.props.navigate(path + "?child_collapsed=true");
    // window.open(path + "?child_collapsed=true", '_self');
    else this.props.navigate(path);
    // window.open(path, '_self');
  };

  componentDidMount = async () => {
    await this.getCurrentUser();
    await this.getTotalAmount();
    var { innerWidth: width, innerHeight: height } = window;
    if (width < 480) {
      this.setState({
        child_collapsed: true,
      });
    }
    if (this.state.child_collapsed) {
      this.setState({
        submenu_span: this.submenu_large_span,
      });
    }
    this.handlePath();
    var socket = getSocket("admin_socket");

    socket.on("connect", () => {
      socket.on("process_pointcode", (data) => {
        console.log("data", data);
        notification.open({
          message: data.action === "refuse" ? "Từ chối tích điểm" : "Tích điểm thành công",
          description: data.action === "refuse" ? `Mã tích điểm ${data.id} đã bị từ chối` : `Mã tích điểm ${data.id} thành công`,

          key: `process${data.id}`,
          onClose: close,
        });
      });

      socket.on("notificate_to_admin", (data) => {
        console.log("data2", data);
        notification.open({
          duration: 20,
          message: "Yêu cầu tích điểm",
          description: `Khách hàng đang yêu cầu tích điểm cho đơn hàng ${data.id} với giá trị ${data.total_amount}`,
          btn: (
            <Space>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  this.PopupQr.current.open(data.pointcode_id);
                }}
              >
                Chỉnh sửa
              </Button>
              <Button type="primary" size="small" onClick={() => this.processPointCode(data.pointcode_id, "confirm")}>
                Xác nhận
              </Button>
              <Button size="small" onClick={() => this.processPointCode(data.pointcode_id, "refuse")}>
                Từ chối
              </Button>
            </Space>
          ),
          key: `process${data.pointcode_id}`,
          onClose: close,
        });
      });
    });
    this.socket = socket;
  };

  processPointCode = (id, action) => {
    api
      .post("pointcode/adminProcessPointCode", {
        data: { id: id, action: action },
      })
      .then((res) => {});
  };

  getProfile = async () => {
    api.post("profile/get").then((res) => {
      this.setState({
        data: res,
        loadData: true,
      });
    });
  };

  getChildMenu = (current_parent_key) => {
    var menu = this.state.menu;
    var current_parent = menu.find((z) => z.key == current_parent_key);
    var current_child = [];
    if (current_parent != null) current_child = current_parent.child;
    var child = current_child;
    return { current_parent, child };
  };

  handlePath = () => {
    var path = window.location.pathname;
    var keys = path.split("/");
    var parent_key = this.state.default_key;
    var child_key = "";
    if (path != "/" && path != "") {
      if (keys != null && keys.length != 0) {
        if (keys[1] != "" && keys[1] != null) parent_key = keys[1];

        if (keys[2] != "" && keys[2] != null) {
          child_key = keys[2];
          var results = this.getChildMenu(parent_key);
          if (results.child != null && results.child.length != 0) {
            this.setState(
              {
                sub_menu_visible: true,
                current_child_key: child_key,
              },
              () => this.setSubMenuWidth()
            );
          }
        } else {
          this.setState({ sub_menu_visible: true });
        }
      }
    }

    this.setState({ current_parent_key: parent_key });
  };

  navigate = (path) => {
    //this.Router.current.history.push(path);
    // window.location.href = path;
    this.props.navigate(path);
    this.handlePath();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  toggleChildCollapsed = () => {
    var { innerWidth: width, innerHeight: height } = window;
    if (width < 480) {
      this.setState({
        child_collapsed: true,
      });
    } else {
      this.setState({
        child_collapsed: !this.state.child_collapsed,
      });
    }
  };

  setSubMenuWidth = () => {
    var submenu_span = this.state.submenu_span;
    var sub_span = this.max_span;
    if (submenu_span == this.submenu_large_span) {
      submenu_span = this.submenu_small_span;
    } else {
      submenu_span = this.submenu_large_span;
    }

    if (this.state.sub_menu_visible) {
      if (submenu_span == this.submenu_large_span) {
        sub_span = this.content_small_span;
      } else {
        sub_span = this.content_large_span;
      }
    }
    this.setState({
      submenu_span: submenu_span,
      sub_span: sub_span,
    });
  };

  setParentKey = (parent_key, newpage) => {
    var results = this.ParentMenu.current.getChildMenu(parent_key);
    this.setState({
      current_parent_key: parent_key,
      sub_span: this.content_small_span,
    });
    if (results.child == null || results.child.length == 0) {
      if (newpage) {
        window.open(results.current_parent.path, "_blank").focus();
        return;
      }
      this.setState(
        {
          sub_menu_visible: false,
        },
        () => {
          this.setSubMenuWidth();
        }
      );
      // window.open(results.current_parent.path, '_self');
      this.navigate(results.current_parent.path);
      this.handlePath();
    } else {
      if (newpage) {
        window.open(results.child[0].path, "_blank").focus();
        return;
      }
      // window.open(results.child[0].path, '_self');
      this.setState({
        current_child_key: results.child[0].key,
      });
      this.navigate(results.child[0].path, newpage);
      this.handlePath();
    }
  };

  getCurrentUser = () => {
    api
      .get("user/me")
      .then((res) => {
        this.setState({ check_token: true });
        this.getProfile();
      })
      .catch((err) => {
        window.location.href = "/login";
      });
  };

  getTotalAmount = () => {
    api
      .get("payment/gettotalamount")
      .then((res) => {
        localStorage.setItem("total_amount", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderBreadcums = () => {
    var rs = [];

    var arrBreadcums = window.location.pathname.split("/");

    var firstNode = this.state.menu.find((item) => {
      return item.path === "/" + arrBreadcums[1];
    });
    rs.push(firstNode);

    var secondNode = undefined;
    if (arrBreadcums.length > 2) {
      try {
        secondNode = firstNode.child.find((item) => {
          return item.path === "/" + arrBreadcums[1] + "/" + arrBreadcums[2];
        });

        if (secondNode != undefined) {
          rs.push(secondNode);
        }
      } catch (error) {}
    }

    var arr = rs.map((item, index) => {
      // console.log(item);
      if (item !== undefined && item.name) {
        return { title: item.name };
      } else if (item && item.label) {
        return { title: item.label };
      } else {
        return <Breadcrumb.Item>NOT FOUND</Breadcrumb.Item>;
      }
    });
    // console.log(arr);
    return (
      <Breadcrumb
        style={{
          marginBottom: "16px",
        }}
        items={arr}
      ></Breadcrumb>
    );
  };

  getData = (avatar, first_name, last_name) => {
    var data = this.state.data;
    data.avatar = avatar;
    data.first_name = first_name;
    data.last_name = last_name;
    this.setState({ data: data });
  };

  logoutClick = (e) => {
    e?.preventDefault();
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  render() {
    if (!this.state.check_token) {
      return (
        <div>
          <p>..........loading..........</p>
        </div>
      );
    }

    var current_child_key = this.state.current_child_key;

    return (
      <div className="main-layout">
        <Layout>
          {window.location.pathname !== "/registration" && (
            <ParentMenu
              style={{ width: 140 }}
              ref={this.ParentMenu}
              open={this.state.parent_menu_visible}
              menu={this.state.menu}
              current_parent_key={this.state.current_parent_key}
              setParentKey={this.setParentKey}
            ></ParentMenu>
          )}

          <Layout style={{ marginLeft: window.location.pathname !== "/registration" ? "45px" : 0 }} theme="light">
            <Content>
              {window.location.pathname !== "/registration" && (
                <LoyaltyHeader userAvatar={this.state.data.avatar} navigate={this.props.navigate} logoutClick={this.logoutClick}></LoyaltyHeader>
              )}
              <Row wrap={false}>
                {this.state.sub_menu_visible && this.state.current_child_key != "" ? (
                  <Col>
                    <SubMenu
                      ref={this.SubMenu}
                      open={this.state.sub_menu_visible}
                      collapsed={this.state.child_collapsed}
                      menu={this.state.menu}
                      setSubMenuWidth={this.setSubMenuWidth}
                      current_child_key={current_child_key}
                      current_parent_key={this.state.current_parent_key}
                      toggleChildCollapsed={this.toggleChildCollapsed}
                      navigate={this.navigate}
                    ></SubMenu>
                  </Col>
                ) : (
                  <div></div>
                )}
                <Col flex={"auto"}>
                  <div
                    style={{
                      minHeight: "calc(100vh - 64px)",
                      marginTop: -1,
                      marginLeft: "0px",
                      marginRight: "0px",
                      padding: window.location.pathname !== "/registration" ? "16px" : 0,
                      background: "none",
                    }}
                    // bodyStyle={{
                    //     padding: 0
                    // }}
                  >
                    {window.location.pathname !== "/registration" && this.renderBreadcums()}

                    <Routes>
                      <Route path="/marketing/point-program" Component={PointProgram}></Route>
                      <Route path="/marketing/exchange-point-program" Component={ExchangePointProgram}></Route>
                      <Route path="/marketing/game-program" Component={GameProgramHoc}></Route>
                      <Route path="/marketing/point-config" Component={PointConfig}></Route>
                      <Route path="/paypalconfig" Component={PaypalConfig}></Route>
                      <Route path="/plans" Component={Plans}></Route>
                      <Route path="/marketing/membership_level" Component={MemberShipLevel}></Route>
                      <Route path="/marketing/branding" Component={Branding}></Route>
                      <Route path="/marketing/branding/configtheme" Component={DefaultThemeConfig}></Route>
                      <Route path="/marketing/branding/configthemezalominiapp" Component={ZaloMiniAppThemeConfig}></Route>
                      <Route path="/marketing/branding/configthemezalominiapp/webhook_history" Component={WebhookHistory}></Route>
                      <Route path="/marketing/program_config/list" Component={PointProgramList}></Route>
                      <Route path="/marketing/game/list" Component={GameProgramList}></Route>
                      <Route path="/home" Component={DashBoard}></Route>

                      <Route path="/customer/list" Component={Customer}></Route>
                      <Route path="/customer/segment" Component={() => <Segment displayMode="card" />}></Route>

                      <Route path="/setting/Tag" Component={Tag}></Route>
                      <Route path="/setting/Users" Component={UserConfig}></Route>
                      <Route path="/setting/apikey" Component={APIkeyConfig}></Route>

                      <Route path="/setting/billing" Component={Billing}></Route>
                      <Route path="/setting/profile" Component={ProFile}></Route>
                      <Route path="/campaign/normal" Component={Campaign} />
                      <Route path="/campaign/zns_excel" Component={CampaignExcel} />
                      <Route path="/campaign/zns_excel/add" Component={AddCampaignExcel} />

                      {/* <Route path="/campaign/:id" Component={CampaignEditor}>
                        {" "}
                      </Route> */}
                      <Route path="/campaign/:id" Component={AddEditCampaign}>
                        {" "}
                      </Route>
                      <Route path="/notifications" Component={Notifications}></Route>

                      <Route path="/setting/customerfield" Component={CustomerFieldNew}></Route>

                      <Route path="/registration" Component={Registration}></Route>
                      <Route path="/zalo/sync" Component={ReceiveToken}></Route>
                      <Route path="/haravan/sync" Component={HaravanSync}></Route>
                      <Route path="/shopee/sync" Component={ShopeeSync}></Route>
                      <Route path="/tiktok/sync" Component={TiktokSync}></Route>
                      <Route path="/apps/:id" Component={AppConfig}></Route>

                      <Route path="/apps" Component={AppConnection}></Route>

                      <Route path="/marketing/pointawardprogram/:type/:id/" Component={PointawardProgram}></Route>
                      <Route path="/marketing/birthdayprogram/:type/:id/" Component={BirthdayProgram}></Route>
                      <Route path="/marketing/fbshareprogram/:type/:id/" Component={FBShareProgram}></Route>
                      <Route path="/marketing/game/:type/:id/" Component={GameConfig}></Route>
                      <Route path="/marketing/test/game/:type/:id/" Component={GameTest}></Route>
                      <Route path="/marketing/exchangeprogram/:type/:id/" Component={ExchangeProgram}></Route>
                      {/* <Route path="/marketing/exchangepointprogram/:type/:id/" Component={ExchangePointProgram}></Route> */}
                      <Route path="/customer/detail/:id" Component={OpenCustomer}></Route>

                      <Route path="/marketing/rewardprogram_config/list" Component={RewardPointProgramList}></Route>
                      {/* <Route path="/marketing/exchangevoucherfreeship/:type/:id/" Component={VoucherFreeshipProgram}></Route>
                        <Route path="/marketing/exchangegift/:type/:id/" Component={ExchangeGift}></Route>
                        <Route path="/marketing/exchangepercentageoff/:type/:id/" Component={VoucherPercentage}></Route> */}

                      <Route path="/voucher/voucherconfig/:id" Component={VoucherConfig}></Route>
                      <Route path="/voucher" Component={VoucherList}></Route>

                      <Route path="/pointhistory" Component={PointHistory}></Route>
                      <Route path="/pointreward/pointreward_list" Component={PointReward}></Route>
                      <Route path="/pointreward/qrcodeconfig" Component={QrCodeConfig}></Route>

                      <Route path="/pointreward" Component={PointReward}></Route>

                      <Route path="/sendhistory" Component={SendHistory}></Route>

                      <Route path="/marketing/blog/add" Component={Blog}></Route>

                      <Route path="/marketing/blog/edit" Component={BlogEdit}></Route>

                      <Route path="/marketing/blog" Component={BlogList}></Route>

                      <Route path="/marketing/review" Component={Review}></Route>

                      <Route path="/marketing/product" Component={Product}></Route>

                      <Route path="/marketing/product/add" Component={AddEditProductHOC}></Route>
                      <Route path="/marketing/product/edit/:id" Component={AddEditProductHOC}></Route>
                      <Route path="/marketing/miniapp_banner" Component={MiniAppBanner} />
                      <Route path="/marketing/order" Component={OrderTabs} />
                      <Route path="/marketing/location" Component={Location} />
                      <Route path="/marketing/service_order" Component={ServiceOrder} />
                      <Route path="/marketing/service" Component={Service} />
                      <Route path="/marketing/service/add" Component={AddEditServiceHOC}></Route>
                      <Route path="/marketing/service/edit/:id" Component={AddEditServiceHOC}></Route>
                      <Route path="/apiconfig" Component={ApiConfig}></Route>

                      <Route path="/payment" Component={TransactionManagement}></Route>

                      <Route path="/" Component={DashBoard}></Route>
                    </Routes>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <PopupQr ref={this.PopupQr} onSaved={(e) => {}} />
      </div>
    );
  }
}

const BlogEdit = (props) => {
  // const query = new URLSearchParams(props.location.search);
  // var id = query.get("id");
  const paramsString = window.location.href.split("?")[1];
  const query = new URLSearchParams(paramsString);
  var id = query.get("id");
  return <Blog mode="edit" id={id}></Blog>;
};

const AppHOC = () => {
  const navigate = useNavigate();

  return <App navigate={navigate}></App>;
};

export default AppHOC;
