import React from "react";
import { Avatar, Button, Tag, Card, Row, Col, message, Modal, Tabs, Spin, Input, Timeline, Popconfirm, Empty, Form, Space, Typography } from "antd";
import api from "api";
import { BrowserRouter as Router, Link, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import AddNewEdit from "../add";
import { formatDecimal, formatCurrency } from "../../common/format/number";
import "./style.css";
import { formatL } from "../../../common/date_time";
import PointHistory from "./point_history";
import AntTable from "components/common/AntTable";
import numeral from "numeral";

const { TabPane } = Tabs;
const { Text } = Typography;
export default class OpenCustomer extends React.Component {
  constructor(props) {
    // const { id } = useParams();
    super(props);
    this.state = {
      data: {},
      showData: false,
      lstTag: [],
      note: "",
      visible: false,
      lstNote: [],
      noteID: "",
      keyTab: "note",
      show: false,
      customer: {},
      keyTab: 1,
      //   program_lst: [],
      visible: false,
      point: {},
      data: {},
      lstVoucherIsNotUsed: [],
      lstVoucherIsUsed: [],
      loading: false,
      filter: { query: { customer_id: this.getCustomerID() } },
    };
    this.noteForm = React.createRef();
    this.AddNewEdit = React.createRef();
  }

  componentDidMount = async () => {
    this.getData();
    var cus = await api.post("guestinfo/getinfo", { cus_id: this.getCustomerID() });
    console.log(cus.first_name, cus.last_name);
    var lstVoucherIsNotUsed = await api.post("guestinfo/getlstvoucher", { used: false, customer_id: this.getCustomerID() });
    var lstVoucherIsUsed = await api.post("guestinfo/getlstvoucher", { used: true, customer_id: this.getCustomerID() });
    var tab = 1;
    this.setState({
      customer: cus,
      lstVoucherIsNotUsed: lstVoucherIsNotUsed.list,
      keyTab: tab,
      lstVoucherIsUsed: lstVoucherIsUsed.list,
      show: true,
    });
  };

  tabChange = (data) => {
    this.setState({ keyTab: data });
  };

  getData = () => {
    api.post("/customer/getbyid", { id: this.getCustomerID() }).then((res) => {
      if (!res.first_name) {
        res.first_name = "";
      }
      if (!res.last_name) {
        res.last_name = "";
      }
      this.setState({ data: res }, () => {
        this.getNotes();
      });
    });
  };

  getNotes = async () => {
    api.post("/customer/getnotes", { customer_id: this.getCustomerID() }).then((res) => {
      this.setState({ lstNote: res, showData: true }, () => {
        this.getLstTag();
      });
    });
  };

  getLstTag = () => {
    api.post("/customerconfig/getconfig", { query: { key: "tag" } }).then((res) => {
      this.setState({ lstTag: res, showData: true });
    });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  getCustomerID = () => {
    const id = window.location.href.split("/").pop();
    // console.log(id);
    return id;
    // return this.props.id ?? this.props.match.params.id;
  };

  saveNote = (value) => {
    var dataPost = {
      customer_id: this.getCustomerID(),
      note: {
        id: this.state.noteID,
        note: value.note,
      },
    };
    api
      .post("/customer/savenote", dataPost)
      .then((res) => {
        message.success("Lưu ghi chú thành công", 3);
        this.setState({ visible: false, keyTab: "note" });
        this.getNotes();
        this.noteForm.current.resetFields();
      })
      .catch((err) => {
        {
          message.error("Lưu ghi chú thất bại", 3);
        }
      });
  };

  deleteNote = (id) => {
    var dataPost = {
      customer_id: this.getCustomerID(),
      id: id,
    };
    api
      .post("customer/deletenote", dataPost)
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.getNotes();
      })
      .catch((err) => {
        message.error("Xóa thất bại", 3);
      });
  };
  getGender(gender) {
    if (gender == "male" || gender == "Nam") {
      return "Nam";
    } else if (gender == "female" || gender == "Nu") {
      return "Nữ";
    } else {
      return gender ? gender : "";
    }
  }

  // onChange = (e) => {
  //   this.setState({ note: e.target.value });
  // };

  getColumn = () => {
    var columns = [
      {
        title: "Ngày",
        dataIndex: "created_on",
        key: "created_on",
        sorter: true,
        render: (text, record, index) => {
          return <p> {moment(text).format("DD/MM/YYYY")} </p>;
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "order_status",
        key: "order_status",
        sorter: true,
        render: (text, record, index) => {
          if (text === "new") {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="rgba(0, 0, 0, 0.45)" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Chưa xác nhận</Text>
              </Space>
            );
          } else if (text === "paid") {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#1677ff" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Đã xác nhận</Text>
              </Space>
            );
          } else if (text === "shipped") {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#1677ff" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Đang giao</Text>
              </Space>
            );
          } else if (text === "completed") {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52c41a" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Thành công</Text>
              </Space>
            );
          } else if (text === "cancelled") {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Đã huỷ</Text>
              </Space>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: "Mã đơn",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Số tiền",
        dataIndex: "total_price",
        key: "total_price",
        sorter: true,
        render: (text, record) => {
          return <Text>{numeral(text).format("0,0")}</Text>;
        },
      },
    ];
    return columns;
  };

  render() {
    // if (!this.state.show) {
    //   return <Loading />;
    // }\
    // console.log("props");
    return (
      <>
        {this.state.showData ? (
          <div>
            <Row>
              <Col span={8}>
                <Card bodyStyle={{ padding: 0 }}>
                  <div>
                    <div style={{ display: "flex", padding: 24 }}>
                      <Avatar
                        style={{
                          backgroundColor: "#8DC561",
                          verticalAlign: "middle",
                        }}
                        size={"lagre"}
                      >
                        {this.state.data.first_name && this.state.data.first_name[0]}
                      </Avatar>
                      &nbsp;&nbsp;
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          lineHeight: "32px",
                          textOverflow: "ellipsis",
                          width: "85%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {(this.state.data.last_name && this.state.data.last_name) + " " + (this.state.data.first_name && this.state.data.first_name)}
                      </div>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Row>
                        <Col span={12}>
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                                  {this.state.data.current_point ? formatCurrency(this.state.data.current_point) : 0}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontSize: 14, fontStyle: "normal", lineHeight: "32px", fontWeight: "normal" }}>Điểm</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                                  {this.state.data.tier ? this.state.data.tier.name.toUpperCase() : "Chưa có hạng"}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontSize: 14, fontStyle: "normal", lineHeight: "32px", fontWeight: "normal" }}>Hạng</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Row>
                        <Col span={6}>
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                                  {this.state.data.orders_count ? formatCurrency(this.state.data.orders_count) : 0}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontSize: 14, fontStyle: "normal", lineHeight: "32px", fontWeight: "normal" }}>Đơn hàng</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col span={9}>
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                                  {this.state.data.total_spent ? formatCurrency(this.state.data.total_spent) : 0}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontSize: 14, fontStyle: "normal", lineHeight: "32px", fontWeight: "normal" }}>Số tiền</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col span={9}>
                          <div style={{ display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                                  {this.state.data.orders_count
                                    ? this.state.data.total_spent
                                      ? formatCurrency(formatDecimal(this.state.data.total_spent / this.state.data.orders_count))
                                      : 0
                                    : 0}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ margin: "auto", fontSize: 14, fontStyle: "normal", lineHeight: "32px", fontWeight: "normal" }}>TB đơn</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
                <Card title="Tag" style={{ marginTop: 15 }}>
                  <div>
                    {this.state.data.tags && this.state.data.tags.length > 0 ? (
                      this.state.data.tags.map((item) => {
                        var index = this.state.lstTag.findIndex((x) => x.id === item);
                        if (index > -1) return <Tag color={this.state.lstTag[index].color}>{this.state.lstTag[index].label}</Tag>;
                      })
                    ) : (
                      <Empty />
                    )}
                  </div>
                </Card>

                <Card
                  title="Thông tin khách hàng"
                  extra={
                    <Button
                      loading={this.state.loading}
                      type="link"
                      onClick={async () => {
                        this.setState({ loading: true });
                        await this.AddNewEdit.current.open(this.state.data.id);
                        this.setState({ loading: false });
                      }}
                    >
                      Chỉnh sửa
                    </Button>
                  }
                  style={{ marginTop: 15 }}
                >
                  <div>
                    <Row>
                      <Col span={8}>
                        <div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Họ</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Tên</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Giới tính</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Tuổi</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Điện thoại</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Email</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Quốc gia</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap", fontWeight: "bold" }}>Tỉnh thành </div>
                        </div>
                      </Col>
                      <Col span={16}>
                        <div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>{" " + this.state.data.last_name}</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>{" " + this.state.data.first_name}</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>{this.getGender(this.state.data.gender)}</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>
                            {this.state.data.birth_day ? moment().year() - moment(this.state.data.birth_day).year() : ""}
                          </div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>{this.state.data.phone ? this.state.data.phone : ""}</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>{this.state.data.email ? this.state.data.email : ""}</div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>
                            {this.state.data.address.country ? this.state.data.address && this.state.data.address.country : ""}
                          </div>
                          <div style={{ height: 56, lineHeight: "56px", whiteSpace: "nowrap" }}>
                            {this.state.data.address.province ? this.state.data.address.province : ""}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col span={16}>
                <div style={{ paddingLeft: 20 }}>
                  <Card>
                    <Tabs
                      type="card"
                      onChange={(activekey) => {
                        this.setState({ keyTab: activekey });
                      }}
                      tabBarExtraContent={
                        this.state.keyTab === "note" &&
                        this.state.lstNote.length > 0 && (
                          <Button type="primary" onClick={() => this.setState({ note: "", noteID: "" }, () => this.showModal())}>
                            <i className="fa fa-plus"></i>&nbsp;&nbsp;Tạo Ghi Chú
                          </Button>
                        )
                      }
                    >
                      <TabPane tab="Ghi chú" key="note">
                        {this.state.lstNote.length === 0 ? (
                          <div style={{ display: "flex", minHeight: 550 }}>
                            <div style={{ margin: "auto" }}>
                              <div style={{ display: "flex" }}>
                                <img src="/image/notecustomer.svg" style={{ margin: "auto" }} />
                              </div>
                              <div style={{ display: "flex" }}>
                                <span style={{ fontSize: 20, fontWeight: "bold", margin: "auto" }}>Không tìm thấy ghi chú nào</span>
                              </div>
                              <div style={{ display: "flex", marginTop: 20 }}>
                                <Button
                                  style={{ margin: "auto", width: 200 }}
                                  type="primary"
                                  onClick={() => this.setState({ note: "", noteID: "" }, () => this.showModal())}
                                >
                                  <i className="fa fa-plus"></i>&nbsp;&nbsp;Tạo Ghi Chú
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Timeline>
                              {this.state.lstNote
                                .sort(function (a, b) {
                                  var dateA = new Date(a.updatedate),
                                    dateB = new Date(b.updatedate);
                                  return dateB - dateA;
                                })
                                .map((item) => {
                                  var date = new Date(item.updatedate);
                                  date = moment(date);
                                  return (
                                    <Timeline.Item>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <div>
                                            {item.note} <i className="fa fa-comments" aria-hidden="true" style={{ color: "#dfdfdf" }}></i>
                                          </div>
                                          <div style={{ color: "#dfdfdf" }}>
                                            <span>{date.format("DD-MM-YYYY")}</span>&ensp;
                                            <span>{item.updateby}</span>
                                          </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <div style={{ float: "right", color: "#1890FF", fontSize: 17 }}>
                                            <span
                                              style={{ marginRight: 20, cursor: "pointer" }}
                                              onClick={() => {
                                                this.setState({ note: item.note, noteID: item.id }, () => this.showModal());
                                              }}
                                            >
                                              <i className="fa fa-edit"></i>
                                            </span>
                                            <Popconfirm
                                              placement="top"
                                              title={"Bạn có chắc muốn xóa không?"}
                                              onConfirm={() => {
                                                this.deleteNote(item.id);
                                              }}
                                              okText="Có"
                                              cancelText="Không"
                                            >
                                              <span style={{ marginRight: 20, cursor: "pointer" }}>
                                                <i className="fa fa-trash"></i>
                                              </span>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    </Timeline.Item>
                                  );
                                })}
                            </Timeline>
                          </div>
                        )}
                      </TabPane>
                      <TabPane tab="Kênh trao đổi" key="apps">
                        <Empty />
                      </TabPane>
                      <TabPane tab="Voucher" key="voucher">
                        <div style={{ width: "94%", backgroundColor: "#E5E5E5" }}>
                          <Tabs defaultActiveKey="1" style={{ marginLeft: 20 }}>
                            <TabPane tab="Quà đã đổi" key="1">
                              {this.state.lstVoucherIsNotUsed && this.state.lstVoucherIsNotUsed.length > 0 ? (
                                <div style={{ marginBottom: 15, marginRight: 20 }}>
                                  {this.state.lstVoucherIsNotUsed.map((item) => {
                                    return (
                                      <div className="stamp" style={{ padding: "15px 0" }} key={item.voucher_id}>
                                        <div style={{ display: "flex", backgroundColor: "#ffffff" }}>
                                          <div style={{ width: 135, height: 155 }}>
                                            {item?.program?.display_style === "image" ? (
                                              <img src={item.program.logo} style={{ width: "100%", height: "100%" }}></img>
                                            ) : (
                                              <div style={{ backgroundColor: "#ff0000", padding: 10, width: "100%", height: "90%", display: "flex" }}>
                                                <span style={{ margin: "auto", color: "#ffffff", fontFamily: "cursive" }}>{item.program?.text}</span>
                                              </div>
                                            )}
                                          </div>

                                          <div style={{ flex: 1, marginTop: 15, marginLeft: 15 }}>
                                            <div className="name">
                                              <b style={{ color: "#32D68C", fontSize: 15 }}>Evoucher: </b>
                                              {item.program?.name}
                                            </div>

                                            <div
                                              className="description"
                                              style={{ wordBreak: "break-word", marginTop: -2, width: "95%", fontSize: 13, fontWeight: 400, color: "#CDCDCD" }}
                                            >
                                              {`Ngày tạo: ${formatL(item.create_on)}`}
                                            </div>

                                            <div
                                              className="description"
                                              style={{ wordBreak: "break-word", width: "95%", fontSize: 13, fontWeight: 400, color: "#CDCDCD", marginTop: -15 }}
                                            >
                                              Hạn sử dụng: {item.expiration_date ? formatL(item.expiration_date) : "Không hết hạn"}
                                            </div>

                                            <div className="name" style={{ position: "absolute", top: 120, fontSize: 15, width: "53%" }}>
                                              {`Mã voucher: ${item.voucher_id}`}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div style={{ display: "flex", width: "100%", height: "50vh" }}>
                                  <div style={{ margin: "auto" }}>
                                    <img src="/image/danhsach.png" />
                                    <p style={{ color: "#808080", marginTop: 15, fontSize: 16, fontWeight: 600 }}> Danh sách quà trống</p>
                                  </div>
                                </div>
                              )}
                            </TabPane>
                            <TabPane tab="Quà đã dùng" key="2">
                              {this.state.lstVoucherIsUsed && this.state.lstVoucherIsUsed.length > 0 ? (
                                <div style={{ marginBottom: 15, marginRight: 20 }}>
                                  {this.state.lstVoucherIsUsed.map((item) => {
                                    return (
                                      <div className="stamp" style={{ padding: "15px 0" }} key={item.voucher_id}>
                                        <div style={{ display: "flex", backgroundColor: "#ffffff" }}>
                                          <div style={{ width: 135, height: 155 }}>
                                            {item?.program?.display_style === "image" ? (
                                              <img src={item.program.logo} style={{ width: "100%", height: "100%" }}></img>
                                            ) : (
                                              <div style={{ backgroundColor: "#ff0000", padding: 10, width: "100%", height: "90%", display: "flex" }}>
                                                <span style={{ margin: "auto", color: "#ffffff", fontFamily: "cursive" }}>{item.program.text}</span>
                                              </div>
                                            )}
                                          </div>

                                          <div style={{ flex: 1, marginTop: 15, marginLeft: 15 }}>
                                            <div className="name">
                                              <b style={{ color: "#32D68C", fontSize: 15 }}>Evoucher: </b>
                                              {item.program.name}
                                            </div>
                                            <div
                                              className="description"
                                              style={{ wordBreak: "break-word", marginTop: -2, width: "95%", fontSize: 13, fontWeight: 400, color: "#CDCDCD" }}
                                            >
                                              {`Ngày tạo: ${formatL(item.create_on)}`}
                                            </div>

                                            <div
                                              className="description"
                                              style={{ wordBreak: "break-word", width: "95%", fontSize: 13, fontWeight: 400, color: "#CDCDCD", marginTop: -15 }}
                                            >
                                              Hạn sử dụng: {item.expiration_date ? formatL(item.expiration_date) : "Không hết hạn"}
                                            </div>

                                            <div className="name" style={{ position: "absolute", top: 120, fontSize: 15, width: "53%" }}>
                                              {`Mã voucher: ${item.voucher_id}`}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div style={{ display: "flex", width: "100%", height: "50vh" }}>
                                  <div style={{ margin: "auto" }}>
                                    <img src="/image/danhsach.png" />
                                    <p style={{ color: "#808080", marginTop: 15, fontSize: 16, fontWeight: 600 }}> Danh sách quà trống</p>
                                  </div>
                                </div>
                              )}
                            </TabPane>
                          </Tabs>
                        </div>
                      </TabPane>

                      <TabPane tab="Lịch sử tích điểm" key="addpoint">
                        <PointHistory id={this.getCustomerID()} />
                      </TabPane>
                      <TabPane tab="Đơn hàng" key="orders">
                        <AntTable
                          columns={this.getColumn()}
                          customSearch={this.state.filter}
                          tableName={"tbOrder"}
                          ajax="order/list"
                          ref={this.Tb}
                          rowKey={(record) => record.id}
                        ></AntTable>
                      </TabPane>
                    </Tabs>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ display: "flex", height: 500 }}>
            <Spin style={{ margin: "auto" }} size="large" />
          </div>
        )}
        <Modal
          title="Ghi chú"
          open={this.state.visible}
          zIndex={99999}
          okButtonProps={{
            children: "Custom OK",
          }}
          cancelButtonProps={{
            children: "Custom cancel",
          }}
          okText="Lưu"
          cancelText="Huỷ"
          onOk={() => {
            this.noteForm.current.submit();
          }}
          onCancel={() => {
            this.setState({ visible: false });
            this.noteForm.current.resetFields();
          }}
        >
          <Form ref={this.noteForm} onFinish={this.saveNote}>
            <Form.Item label="" name="note" rules={[{ required: true, message: "Bạn phải nhập ghi chú!" }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <AddNewEdit ref={this.AddNewEdit} customer_id={this.getCustomerID()} getData={this.getData} mode="edit"></AddNewEdit>
      </>
    );
  }
}
