import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Typography, DatePicker, Select, Col } from "antd";
import api from "api";
import SendMethods from "../sendmethods";
import SegmentPicker from "components/picker/segment_picker";
import { formatCurrency } from "../../common/format/number";
import NamePicker from "../exchange_point_program/config/picker/namepicker";
import moment from "moment";
import { formatS } from "common/date_time";
import UploadImagePicker from "components/picker/image_picker/index";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

class PointawardProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        id: "0",
        events: [],
        is_active: true,
      },
      uploaded_logo: "/image/microsoft.png",
      showData: false,
      isLimit: false,
      load: false,
      segmentName: "",
      timeLimit: false,
      byLevel: false,
      tiers: [],
      participants: "all",
      point: "",
    };
    this.form = React.createRef();
    this.SendMethods = React.createRef();
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig = () => {
    api.post("pointprogram/getconfigbytype", { type: this.props.pointType }).then((res) => {
      this.setState({ config: res }, () => {
        this.getTier();
      });
    });
  };

  getData = () => {
    if (this.props.pointId == 0) {
      var data = {
        id: "",
        type: this.props.pointType,
        created_by: "admin",
        created_date: "2021-10-08T01:24:27Z",
        is_active: true,

        name: "Chương trình tích điểm",
        exchange: {},
        short_description: "",
        time_limit: "",
        conditions: {
          participants: {
            type: "all",
          },
        },
      };
      this.form.current.setFieldsValue(data);
      this.setState({ data: data, load: true }, () => {
        this.changeDescription(data);
      });
    } else {
      api.post("pointprogram/getconfigbyid", { id: this.props.pointId }).then((res) => {
        if (res.conditions.time_limit.is_limited_time) {
          this.setState({ timeLimit: true });
          res.conditions.time_limit.time = [dayjs(res.conditions.time_limit.start), dayjs(res.conditions.time_limit.end)];
        }
        var segmentName = "";
        if (res.customer_segment[0]) {
          segmentName = res.customer_segment[0].name;
        }
        this.form.current.setFieldsValue(res);
        this.setState(
          {
            data: res,
            PointLimit: res.is_limitpoint,
            isLimit: res.is_limitcustomer,
            isPrefix: res.is_prefix,
            segmentName: segmentName,
            participants: res.conditions.participants.type,
            byLevel: res.reward.is_by_tier,
            load: true,
          },
          () => {
            this.changeDescription();
          }
        );
      });
    }
  };

  getTier = () => {
    api.post("/loyaltyconfig/getTier").then((res) => {
      this.setState({ tiers: res.tiers }, () => {
        this.getPoint();
      });
    });
  };

  getPoint = () => {
    api.post("/loyaltyconfig/getpoint").then((res) => {
      this.setState({ point: res.name.singular }, () => {
        this.getData();
      });
    });
  };

  onChangeEvents = (events) => {
    var config = { ...this.state.config };
    config.events = events;
    this.setState({ config });
  };

  saveProgram = (values) => {
    var config = { ...this.state.config };
    if (!values.reward.is_by_tier) {
      values.short_description =
        formatCurrency(values.reward.money) + "đ tương đương với " + formatCurrency(values.reward.point) + " " + this.state.point + ".";
    } else {
      values.short_description = "Sử dụng " + formatCurrency(values.reward.money) + "đ sẽ được tích " + this.state.point + " (tùy vào hạng thành viên)";
    }
    if (values.conditions.time_limit.is_limited_time) {
      values.conditions.time_limit = {
        is_limited_time: values.conditions.time_limit.is_limited_time,
        start: values.conditions.time_limit.time[0],
        end: values.conditions.time_limit.time[1],
      };
    } else values.conditions.time_limit.is_limited_time = false;

    api
      .post("pointprogram/saveconfig", { config: values })
      .then((res) => {
        message.success("Lưu thành công");
        var href = config.config_url + this.props.pointType + "/" + res.program_id;
        if (!window.location.href.includes(href)) window.location.href = href;
        this.getData();
      })
      .catch((err) => {
        message.error("Có lỗi xảy ra khi lưu");
        console.log("save err", err);
      });
  };

  radioChange = (value) => {
    var config = this.state.config;
    config.is_active = value;
    this.setState({ config }, () => this.changeDescription());
  };

  logoChange = (value) => {
    if (value == "system") {
      var config = this.state.config;
      config.logo = this.state.config.default_logo;
      this.setState({ config });
    } else {
      var config = this.state.config;
      config.logo = this.state.uploaded_logo;
      this.setState({ config });
    }
    this.setState({ logo_status: value });
  };

  onMoneyChange = (value) => {
    this.changeDescription();
  };

  changeDescription = () => {
    var data = this.form.current.getFieldsValue();
    var config = this.state.config;
    var textPoint = " ";
    if (data.reward.is_by_tier) {
      var lst = Object.keys(data.reward.tierpoint).map((item) => {
        return this.state.tiers.find((x) => x.id === item);
      });
      textPoint = "<div><ul>";
      lst.forEach((item) => {
        textPoint += "<li>" + item.name + ": " + (data.reward.tierpoint[item.id] ? data.reward.tierpoint[item.id] : "") + " " + this.state.point + ".</li>";
      });
      textPoint += "</ul></div>";
    } else {
      textPoint = (data.reward.point ? data.reward.point : "") + " " + this.state.point;
    }
    var text = "";
    if (data.conditions.participants.type === "all") {
      text = "Áp dụng với tất cả khách hàng.";
    } else {
      if (data.conditions.participants.type === "tier") {
        var tier = this.state.tiers.find((x) => x.id === data.conditions.participants.tier_id);
        if (tier) {
          text = "Áp dụng với khách hàng có hạng: " + tier.name;
        } else {
          text = "Áp dụng với khách hàng có hạng: " + "";
        }
      } else {
        text = "Áp dụng với tập khách hàng: " + (this.state.segmentName ? this.state.segmentName : "");
      }
    }

    var texttimelimit = "";
    // console.log("data", data);

    if (data.conditions.time_limit.is_limited_time === true && data.conditions.time_limit.time) {
      texttimelimit =
        "Bắt đầu từ ngày: " +
        formatS(moment(data.conditions.time_limit.time[0].$d)) +
        " đến ngày: " +
        formatS(moment(data.conditions.time_limit.time[1].$d)) +
        ".";
    } else texttimelimit = "Không giới hạn thời gian";
    var description = config.description;
    description = description
      .replaceAll("{{point}}", textPoint)
      .replaceAll("{{money}}", formatCurrency(data.reward.money) ? formatCurrency(data.reward.money) : 0)
      .replaceAll("{{apply}}", text)

      .replaceAll("{{time}}", texttimelimit);

    this.setState({ description: description });
  };

  onTimeLimitChange = (value) => {
    this.setState({ timeLimit: value }, () => {
      var config = this.state.config;
      if (!value) {
        config.timelimit = null;
        // this.form.current.setFieldsValue({ conditions: { time_limit: { time: null } } });
        this.changeDescription();
      } else {
        // var a = moment();
        // var b = moment().add(7, "days");
        // var data = [a, b];
        // console.log(data);
        // this.form.current.setFieldsValue({ conditions: { time_limit: { time: data } } });
        this.changeDescription();
      }
    });
  };

  onSegmentChange = (e) => {
    this.setState({ segmentName: e.name }, () => {
      this.changeDescription();
    });
  };

  onBylevelChange = (value) => {
    this.setState({ byLevel: value }, () => {
      this.changeDescription();
    });
  };

  participantsChange = (value) => {
    this.setState({ participants: value }, () => {
      this.changeDescription();
    });
  };

  render() {
    var config = { ...this.state.config };
    var logo_status = this.state.logo_status;

    return (
      <Form name="emailsetting" ref={this.form} layout="vertical" onFinish={this.saveProgram}>
        {this.state.load && (
          <div className={"program-wrapper"} style={{ width: "101%", marginLeft: -13 }}>
            <div style={{ minHeight: 60 }}>
              <Form.Item name={"name"} rules={[{ type: "string" }]}>
                <NamePicker></NamePicker>
              </Form.Item>
            </div>
            <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xs={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 12 }} md={{ span: 12 }} style={{ marginBottom: 20 }}>
                <Card title="Trạng thái" size="small" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }}>
                  <Form.Item hidden name={"id"} label={"id"} rules={[{ type: "string" }]}></Form.Item>
                  <Form.Item hidden name={"type"} label={"type"} rules={[{ type: "string" }]}></Form.Item>

                  <Form.Item name="is_active">
                    <Radio.Group onChange={(e) => this.radioChange(e.target.value)}>
                      <Space direction="vertical">
                        <Radio value={true} style={{ marginTop: 30 }}>
                          Hoạt động
                        </Radio>
                        <Radio value={false} style={{ marginTop: 30 }}>
                          Tạm dừng
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </Col>
              <Col xs={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 12 }} md={{ span: 12 }} style={{ marginBottom: 20 }}>
                <Card title="Biểu tượng" size="small" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }}>
                  <Form.Item
                    name="logo"
                    rules={[
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (!value || value === "") {
                              error();
                            } else {
                              resolve();
                            }
                          });
                        },
                        message: "Bạn chưa chọn logo tên!",
                      },
                    ]}
                  >
                    <UploadImagePicker crop={{ quality: 1, aspect: 1 }} size={{ width: 64, height: 64 }} tags={["logo"]}></UploadImagePicker>
                  </Form.Item>
                </Card>
              </Col>

              <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                <Card title="Nội dung chương trình" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                  {this.state.description != null && this.state.description != "" ? (
                    <div dangerouslySetInnerHTML={{ __html: this.state.description }}></div>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                <Card title="Phần thưởng" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "49%", display: "flex", flexDirection: "column-reverse" }}>
                      <Form.Item
                        label="Số tiền"
                        name={["reward", "money"]}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập số tiền!",
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          step={1000}
                          style={{ width: "100%" }}
                          onChange={() => {
                            this.changeDescription();
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ width: "49%" }}>
                      {!this.state.byLevel && (
                        <>
                          <Form.Item
                            label="Số điểm"
                            name={["reward", "point"]}
                            rules={[
                              {
                                required: true,
                                message: "Bạn chưa nhập số điểm!",
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              step={1}
                              style={{ width: "100%" }}
                              onChange={() => {
                                this.changeDescription();
                              }}
                            />
                          </Form.Item>
                        </>
                      )}
                    </div>
                  </div>

                  {this.state.byLevel && (
                    <>
                      {this.state.tiers.map((item) => {
                        return (
                          <Form.Item
                            label={item.name}
                            name={["reward", "tierpoint", item.id]}
                            rules={[
                              {
                                required: true,
                                message: `Bạn chưa nhập ${item.name}!`,
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              step={1}
                              style={{ width: "49%" }}
                              onChange={() => {
                                this.changeDescription();
                              }}
                            />
                          </Form.Item>
                        );
                      })}
                    </>
                  )}
                  <Form.Item name={["reward", "is_by_tier"]} valuePropName="checked">
                    <Checkbox
                      checked={this.state.byLevel}
                      onChange={(e) => {
                        this.onBylevelChange(e.target.checked);
                      }}
                    >
                      Theo hạng thành viên
                    </Checkbox>
                  </Form.Item>
                </Card>
              </Col>
              <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                <Card title="Điều kiện tham gia" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                  <Form.Item
                    label="Đối tượng"
                    name={["conditions", "participants", "type"]}
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa chọn loại đối tượng!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        this.participantsChange(e.target.value);
                      }}
                    >
                      <Radio value={"all"}>Tất cả</Radio>
                      <Radio value={"tier"}>Theo hạng</Radio>
                      <Radio value={"group"}>Theo nhóm</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {this.state.participants === "group" && (
                    <Form.Item
                      name={["conditions", "participants", "customer_segment_id"]}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa chọn nhóm!",
                        },
                      ]}
                      style={{ width: "50%" }}
                    >
                      <SegmentPicker setValueSegment={this.onSegmentChange}></SegmentPicker>
                    </Form.Item>
                  )}
                  {this.state.participants === "tier" && (
                    <Form.Item
                      name={["conditions", "participants", "tier_id"]}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa chọn hạng!",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "50%" }}
                        onChange={() => {
                          this.changeDescription();
                        }}
                      >
                        {this.state.tiers.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Option value={item.id}>{item.name}</Option>{" "}
                            </React.Fragment>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item name={["conditions", "time_limit", "is_limited_time"]} valuePropName="checked">
                    <Checkbox style={{ marginBottom: 10 }} checked={this.state.timeLimit} onChange={(e) => this.onTimeLimitChange(e.target.checked)}>
                      Giới hạn thời gian
                    </Checkbox>
                  </Form.Item>
                  {this.state.timeLimit && (
                    <Form.Item
                      name={["conditions", "time_limit", "time"]}
                      rules={
                        this.state.timeLimit && [
                          {
                            type: "array",
                            required: true,
                            message: "Bạn chưa chọn giới hạn thời gian!",
                          },
                        ]
                      }
                    >
                      <RangePicker format={"DD-MM-YYYY"} onChange={() => this.changeDescription()} />
                    </Form.Item>
                  )}
                </Card>

                {/* <SendMethods onChangeEvents={this.onChangeEvents} ref={this.SendMethods} events={this.state.config.events} program_id={this.props.pointId} type={this.props.pointType}></SendMethods> */}
              </Col>
            </Row>

            <Row>
              <Card size="small" style={{ width: "99%", marginLeft: 10, height: 60 }}>
                <Button type="primary" style={{ position: "absolute", right: 20 }} htmlType="submit">
                  Lưu
                </Button>
              </Card>
            </Row>
          </div>
        )}
      </Form>
    );
  }
}

const PointawardProgramHoc = () => {
  const { id, type } = useParams();
  return <PointawardProgram pointId={id} pointType={type}></PointawardProgram>;
};

export default PointawardProgramHoc;
