import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { Radio, Upload, Button, Tabs, Col, Row, Modal, message, Result, Spin } from "antd";
import api from "api";
import ZaloESMSEditor from "./esms_zalo_picker";
import EsmsSmsPicker from "./esms_sms_picker";
import { Link } from "react-router-dom";

const EsmsEditor = forwardRef((props, ref) => {
  const [isSynced, setIsSynced] = useState(false);
  const [esmsConfig, setEsmsConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedType, setselectedType] = useState(props.value.type ? props.value.type : "sms");

  useEffect(() => {
    var esms_wts = props.wayToSend?.find((x) => x.id === "esms");
    var item = esms_wts?.parameters.find((x) => x.type === "id");
    if (esms_wts) {
      setLoading(true);
      api.post("app/esms/config/getbyid", { id: item.value }).then((res) => {
        if (res) {
          setEsmsConfig(res);
          setIsSynced(true);
          setLoading(false)
        } else setIsSynced(false);
      }).catch((err) => { console.log(err); });
    }

  }, [props.wayToSend, props.value]);

  const onChangeRadio = (e) => {
    var newContent = props.value;
    newContent.type = e.target.value;
    setselectedType(e.target.value)
    props.onChange(newContent);
  };

  const zaloChange = (e, property) => {
    // debugger
    props.onChange(e, 'zalo_content', property);
  };

  const smsChange = (e, property) => {
    // debugger
    props.onChange(e, 'sms_content', property);
  };


  useImperativeHandle(
    ref,
    () => {
      return {
        validate: validate,
      };
    },
    [props.value]
  );

  const validate = () => {
    var error = {};
    switch (props.value.type) {
      case "sms":
        return error = validateSMS();
      case "zalo":
        return error = validateZalo(error);
      case "multichannel":
        var sms = validateSMS();
        var zalo = validateZalo();
        if (sms || zalo) {
          return error = { ...sms, ...zalo }
        }
    }

    if (Object.keys(error).length > 0) {
      return error;
    } else {
      return null;
    }
  };

  const validateSMS = () => {
    var error = {};

    if (!props.value.sms_content.brand_name) {
      error.oa_id = "Vui lòng chọn Brand Name";
    }

    if (!props.value.sms_content.sms_template) {
      error.template_id = "Vui lòng chọn Zalo Mẫu tin nhắn";
    }

    if (Object.keys(error).length > 0) {
      return error;
    }

  }

  const validateZalo = () => {
    var error = {};

    if (!props.value.zalo_content.oa_id) {
      error.oa_id = "Vui lòng chọn Zalo OA";
    }

    if (!props.value.zalo_content.template_id) {
      error.template_id = "Vui lòng chọn Zalo Template";
    }

    if (Object.keys(error).length > 0) {
      return error;
    }
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        {!isSynced ? (
          <Result
            title={!loading && 'Chưa đồng bộ ESMS'}
            icon={loading && (<></>)}
            style={{ width: '100%', minHeight: "48vh" }}
            extra={!loading && (
              <Button type="primary" key="console">
                <Link to={"/apps/esms"}>
                  Đồng bộ ngay
                </Link>
              </Button>
            )}
          />
        ) : (
          <>
            <Col span={24}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <h2>Phương thức gửi</h2>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                <Radio.Group onChange={(e) => onChangeRadio(e)} value={selectedType}>
                  <Radio value="sms">Gửi SMS</Radio>
                  <Radio value="zalo">Gửi ZALO</Radio>
                  <Radio value="multichannel">Gửi SMS và ZALO</Radio>
                </Radio.Group>
              </div>
            </Col>
            {(props.value.type == "sms" || props.value.type == "multichannel") && (
              <Col span={24}>
                <EsmsSmsPicker
                  config={esmsConfig}
                  value={props.value?.sms_content}
                  onChange={smsChange}
                />
              </Col>
            )}
            {(props.value.type == "zalo" || props.value.type == "multichannel") && (
              <Col span={24}>
                <ZaloESMSEditor
                  config={esmsConfig}
                  value={props.value?.zalo_content}
                  onChange={zaloChange}
                  mode={props.mode}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Spin>
  );
});
export default EsmsEditor;
