import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Steps, Button, Form, Input, Row, Col, Select, Space, DatePicker, TimePicker } from "antd";
import { MoreOutlined, CalendarOutlined } from "@ant-design/icons";
import SegmentsCampingPopup from "./segment";
import SegmenPicker from "components/picker/segment_picker";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/en_US";
const { TextArea } = Input;
const moment = require("moment");
/**
 *
 * @param {*} props nextStep: chuyển qua step tiếp theo, setInfomationCampaign: gán dữ liệu từ form vào
 * @author Nhật Anh
 */
function InfomationCampaign({ value, onChange, nextStep }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [scheduleType, setScheduleType] = useState("onetime");
  const [dateOfMonthOptions, setDateOfMonthOptions] = useState();

  const typeOptions = [
    {
      label: "Một lần",
      value: "onetime",
    },
    {
      label: "Hàng ngày",
      value: "daily",
    },
    {
      label: "Hàng tuần",
      value: "weekly",
    },
    {
      label: "Hàng tháng",
      value: "monthly",
    },
  ];

  const dateOfWeekOptions = [
    {
      label: "Thứ hai",
      value: "MON",
    },
    {
      label: "Thứ ba",
      value: "TUE",
    },
    {
      label: "Thứ tư",
      value: "WEB",
    },
    {
      label: "Thứ năm",
      value: "THU",
    },
    {
      label: "Thứ sáu",
      value: "FRI",
    },
    {
      label: "Thứ bảy",
      value: "SAT",
    },
    {
      label: "Chủ nhật",
      value: "SUN",
    },
  ];

  useEffect(() => {
    getListDateOfMonth();
  }, []);

  useEffect(() => {
    if (value.name) {
      var dataschedule = {};
      if (value.schedule.date) {
        dataschedule.date = dayjs(value.schedule.date);
      }
      if (value.schedule.time) {
        dataschedule.time = dayjs(value.schedule.time);
      }
      if (value.schedule.date_of_week) {
        dataschedule.date_of_week = value.schedule.date_of_week;
      }

      if (value.schedule.date_of_month) {
        dataschedule.date_of_month = value.schedule.date_of_month;
      }
      dataschedule.type = value.schedule.type;
      setScheduleType(dataschedule.type);
      form.setFieldsValue({
        ...value,
        schedule: dataschedule,
      });
    }
  }, [value]);

  /**
   * Hàm lấy list 31 ngày trong tháng
   * @author Nhat Anh
   */
  const getListDateOfMonth = () => {
    var list = [];
    for (let index = 1; index < 32; index++) {
      var item = { label: index, value: index };
      list.push(item);
    }
    setDateOfMonthOptions(list);
  };

  /**
   * Hàm set lại InformationCampaign và qua step tiếp theo
   * @author Nhật Anh
   */
  const onFinish = () => {
    var formValue = form.getFieldsValue();
    var formData = { ...formValue };
    formData.schedule = {
      date: formValue.schedule.date ? moment(new Date(formValue.schedule.date.$d)).utc().format() : moment(new Date()),
      time: moment(formValue.schedule.time.$d).format(),
      date_of_week: formValue.schedule.date_of_week,
      date_of_month: formValue.schedule.date_of_month,
      type: formValue.schedule.type,
    };
    onChange(formData);
    nextStep();
  };
  const onExit = () => {
    navigate("/campaign");
  };

  const onChangeScheduleType = (e) => {
    setScheduleType(e);
  };

  return (
    <>
      <Form
        style={{ minHeight: "63vh", position: "relative" }}
        name="information_campaign"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{ schedule: { type: "onetime" } }}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8} style={{ padding: "0 8px 0 0" }}>
            <Form.Item label="Tên chiến dịch" name="name" rules={[{ required: true, message: "Bạn chưa nhập tên chiến dịch!" }]}>
              <Input placeholder="Tên chiến dịch" />
            </Form.Item>
          </Col>

          <Col span={8} style={{ padding: "0 8px" }}>
            <Form.Item label="Lịch chạy" name={["schedule", "type"]} rules={[{ required: true, message: "Bạn chưa chọn lịch chạy chiến dịch!" }]}>
              <Select options={typeOptions} onChange={onChangeScheduleType} />
            </Form.Item>
          </Col>

          <Col span={8} style={{ padding: "0 0 0 8px" }}>
            <Form.Item
              name="customer_segment_id"
              label="Đối tượng"
              rules={[{ required: true, message: "Bạn chưa chọn đối tượng!" }]}
              style={{ display: "inline-block", width: "100%", marginBottom: 0 }}
            >
              <SegmenPicker disabled="" />
            </Form.Item>
          </Col>
          <Col span={8} style={{ padding: "0 8px 0 0" }}>
            <Form.Item label="Mô tả" name="description">
              <TextArea style={{ height: 160 }} />
            </Form.Item>
          </Col>

          <Col span={8} style={{ padding: "0 8px" }}>
            <Form.Item style={{ marginBottom: 0 }}>
              {/* Nếu chọn lịch chạy = Một lần */}
              {scheduleType === "onetime" && (
                <Form.Item
                  label="Ngày"
                  name={["schedule", "date"]}
                  rules={[{ required: true, message: "Bạn chưa chọn ngày chạy!" }]}
                  style={{ display: "inline-block", width: "calc(50% - 4px)", margin: "0 4px 0 0" }}
                >
                  <DatePicker
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        today: "Hôm nay",
                        // ok: "Submit",
                      },
                    }}
                    format={"DD/MM/YYYY"}
                    placeholder="Chọn ngày"
                    style={{ width: "100%" }}
                    picker="date"
                  />
                </Form.Item>
              )}

              {/* Nếu chọn lịch chạy = Hàng tuần */}
              {scheduleType === "weekly" && (
                <Form.Item
                  label="Ngày"
                  name={["schedule", "date_of_week"]}
                  rules={[{ required: true, message: "Bạn chưa chọn ngày chạy!" }]}
                  style={{ display: "inline-block", width: "calc(50% - 4px)", margin: "0 4px 0 0" }}
                >
                  <Select options={dateOfWeekOptions} suffixIcon={<CalendarOutlined style={{ fontSize: 14 }} />} placeholder="Chọn ngày" />
                </Form.Item>
              )}

              {/* Nếu chọn lịch chạy = Hàng tháng */}
              {scheduleType === "monthly" && dateOfMonthOptions.length > 0 && (
                <Form.Item
                  label="Ngày"
                  name={["schedule", "date_of_month"]}
                  rules={[{ required: true, message: "Bạn chưa chọn ngày chạy!" }]}
                  style={{ display: "inline-block", width: "calc(50% - 4px)", margin: "0 4px 0 0" }}
                >
                  <Select options={dateOfMonthOptions} suffixIcon={<CalendarOutlined style={{ fontSize: 14 }} />} placeholder="Chọn ngày" />
                </Form.Item>
              )}

              <Form.Item
                label="Giờ"
                name={["schedule", "time"]}
                rules={[{ required: true, message: "Bạn chưa chọn giờ chạy!" }]}
                style={{
                  display: "inline-block",
                  width: scheduleType === "daily" ? "100%" : "calc(50% - 4px)",
                  margin: scheduleType !== "daily" ? "0 0 0 4px" : 0,
                }}
              >
                <TimePicker
                  placeholder="Chọn giờ"
                  format={"HH:mm"}
                  hourStep={1}
                  minuteStep={15}
                  style={{ width: "100%" }}
                  locale={{
                    ...locale,
                    lang: {
                      ...locale.lang,
                      now: "Hiện tại",
                      // ok: "Submit",
                    },
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ position: "absolute", bottom: 0, right: 0, marginBottom: 0 }}>
          <Button style={{ marginRight: 8 }} type="default" onClick={onExit}>
            Thoát
          </Button>
          <Button type="primary" htmlType="submit">
            Tiếp tục
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default InfomationCampaign;
