import { Button, Card, Col, Form, Image, Input, Modal, Row, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import api from "api";

const { Text } = Typography;

function ModalInfoMiniApp({ isModalOpen, setIsModalOpen, shop, refTable }) {
  const [form] = Form.useForm();
  const [infoZalo, setInfoZalo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    form
      .validateFields()
      .then(async (res) => {
        setIsLoading(true);
        var payload = form.getFieldsValue();
        // console.log(payload);
        await api
          .post("master/shop/saveinfominiapp", { shop_id: shop.id, data: payload })
          .then((res) => {
            // form.setFieldsValue(res);
            if (res) {
              message.success(res);
              setIsLoading(false);
              setIsModalOpen(false);
            } else {
              message.error("Cập nhật thất bại");
              setIsLoading(false);
            }
            // if (res.configs) {
            //   setInfoZalo(res.configs);
            // }
          })
          .catch((err) => {
            message.error("Cập nhật thất bại");
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log("lôi");
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      getInfoMiniApp(shop.id);
    } else {
      form.resetFields();
    }
  }, [isModalOpen]);

  const getInfoMiniApp = async (shopID) => {
    await api
      .post("master/shop/getinfominiapp", { shop_id: shopID })
      .then((res) => {
        form.setFieldsValue(res);
        // if (res.configs) {
        //   setInfoZalo(res.configs);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title={`Thông tin Mini App ${shop.name}`}
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      width="40vw"
      footer={[
        <Button type="primary" onClick={handleSave} loading={isLoading}>
          Lưu
        </Button>,
        <Button onClick={handleCancel}>Đóng</Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="ID Mini App" name={"miniapp_id"}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Private Key Mini App"
          name={"miniapp_privatekey"}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập Private Key!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Tên thương hiệu" name={"brand_name"}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalInfoMiniApp;
