import { Badge, Card, Col, Row, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ConnectTab from "./ConnectTab";
import api from "api";
import ProductTab from "./ProductTab";
import { useLocation } from "react-router";

const { Title, Text } = Typography;
function Shopee() {
  const [isConnected, setIsConnected] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [tabItems, setTabItems] = useState([
    {
      key: "connect",
      label: "Kết nối",
      children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} />,
    },
  ]);
  const [totalProduct, setTotalProduct] = useState();

  useEffect(() => {
    if (isConnected) {
      getTotalProduct();
    } else {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} />,
        },
      ]);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isActive) {
      //   if (totalProduct) {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} />,
        },
        {
          key: "shopee_products",
          label: (
            <Space>
              <Text>Sản phẩm</Text>
              <Badge count={totalProduct} color="#E6F7FF" style={{ color: "blue" }} />
            </Space>
          ),
          forceRender: true,
          children: <ProductTab totalProduct={totalProduct} setTotalProduct={setTotalProduct} />,
        },
      ]);
      //   }
    }
  }, [isActive, totalProduct]);

  const getTotalProduct = () => {
    api
      .post("app/shopee/product/countproduct")
      .then((res) => {
        // console.log("vào đây");
        setTotalProduct(res.count);
        setIsActive(true);
      })
      .catch((err) => {
        console.log(err);
        setTotalProduct(0);
        setIsActive(false);
      });
  };

  return (
    <Card
      title={
        <Row gutter={16} style={{ padding: "16px 0px" }}>
          <Col>
            <div
              style={{
                width: 48,
                height: 48,
                backgroundImage: `url(/image/shopee.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </Col>
          <Col>
            <Row>
              <Title style={{ margin: 0 }} level={5}>
                Shopee
              </Title>
            </Row>
            <Row>
              <Text type="secondary">Đồng bộ sản phẩm shopee với hệ thống loyalty</Text>
            </Row>
          </Col>
        </Row>
      }
      //   extra={<a href="#">More</a>}
      style={{
        width: "100%",
        minHeight: "calc(100vh - 118px)",
      }}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Card>
  );
}

export default Shopee;
