import React, { memo, useRef, useState, forwardRef, useEffect } from "react";
import TextEditor from "../text_picker";
import api from "api";
import { Col, Row, Form, Card, Select, Input, Typography, Divider } from "antd";
import { getFromBetween } from "../../../../common/text";

const { TextArea } = Input;
const { Title, Text } = Typography

/**
 * sms eSMS
 *
 */
const EsmsSmsPicker = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [lstBrand, setLstBrand] = useState([]);
  const [lstSms, setLstSms] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    getLstBrandName();
    setValueForModeEdit();
  }, [props.value]);

  //Hàm gán giá trị cho các field khi ở mode edit
  const setValueForModeEdit = async () => {
    if (props.value.brand_name) {
      await form.setFieldValue('brand_name', props.value.brand_name);
      await getListTemplateESMS(props.value.brand_name);
    }
    onTemplateSmsChange(props.value.sms_template, "edit");
  }

  /**
   * Hàm lấy danh sách brand name
   * @author huy
   */
  const getLstBrandName = () => {
    api.post("app/esms/config/getbrand", { ...props.config }).then((res) => {
      setLstBrand(res.brand);
    }).catch((err) => { console.log(err); });
  }

  //Hàm xử lý khi thay đổi brand name
  const onBrandNameChange = (value) => {
    form.setFieldValue('brand_name', value);
    if (props.onChange) props.onChange(value, "brand_name");
    getListTemplateESMS(value);
  };

  /**
   * Hàm lấy danh sách template sms khi chọn brand 
   * @param {String} brandName 
   */
  const getListTemplateESMS = async (brandName) => {
    await api.post("app/esms/config/gettemplatesms", { ...props.config, brandName: brandName }).then((res) => {
      var smsData = { ...data };
      smsData.brand_name = brandName;
      setData(smsData)
      setLstSms(res.lstTemplate);
    }).catch((err) => console.log(err));
  }

  /**
   * Hàm xử lý khi chọn mẫu tin nhắn
   * @param {*} templateID id của mẫu tin được chọn 
   * @param {String} mode mode của chiến dịch, hiện tại đang xử lý cho 1 mình mode edit
   */
  const onTemplateSmsChange = (templateID, mode) => {
    if (mode !== "edit") {
      form.setFieldValue('sms_template', templateID);

      var smsData = { ...data };
      var sms = lstSms.find((x) => x.TempId == templateID);
      smsData.content = sms.TempContent;
      smsData.sms_template = templateID;

      //xử lý lấy list key
      var lstKey = getFromBetween.get(sms.TempContent, "{", "}");
      lstKey = lstKey.filter((value, index, array) => {
        return array.indexOf(value) === index;
      });
      lstKey = lstKey.map((x) => {
        return { name: x, value: null };
      });

      smsData.list_key = lstKey;
      setData(smsData);

      form.setFieldValue('content', smsData.content);
      props.onChange(smsData.content, "content");
      props.onChange(smsData.sms_template, "sms_template")
      props.onChange(smsData.list_key, "list_key")
    } else {
      // xử lý gán giá trị cho mode edit campaign
      form.setFieldValue('sms_template', props.value.sms_template);
      form.setFieldValue('content', props.value.content);

      //render list key
      setData({ ...data, list_key: props.value.list_key });
      //gán giá trị cho input
      for (const key of props.value.list_key) {
        form.setFieldValue(key.name, key.value);
      }
    }

  };

  const onContentChange = (e) => {
    var smsData = { ...data };
    smsData.content = e.target.value;
    setData(smsData);
    form.setFieldValue('content', smsData.content);
    if (props.onChange) props.onChange(smsData.content, "content");
  };

  /**
   * Hàm xử lý khi chọn key
   * @param {String} e kí hiệu key
   * @param {String} name tên của key 
   */
  const onKeyValueChange = (e, name) => {
    form.setFieldValue(name, e);
    var smsData = { ...data };
    var indexOfKey;
    var key = smsData.list_key.find((x, index) => {
      indexOfKey = index;
      return x.name === name;
    });

    if (key) {
      smsData.list_key[indexOfKey].value = e;
      setData(smsData);
      props.onChange(smsData.list_key, 'list_key');
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Divider style={{ marginTop: 0 }}>Cấu hình ESMS</Divider>

        <Form form={form} layout="vertical">
          <Form.Item label="Brand Name:" name="brand_name" required>
            <Select
              options={lstBrand}
              fieldNames={{ value: "Brandname" }}
              onChange={onBrandNameChange}
            />
          </Form.Item>

          <Form.Item label="Mẫu tin nhắn:" name="sms_template" required>
            <Select
              options={lstSms}
              fieldNames={{ label: "TempId", value: "TempId" }}
              onChange={(value, option) => onTemplateSmsChange(value)}
            />
          </Form.Item>

          <Form.Item label="Nội dung tin nhắn:" name="content" required>
            <TextArea
              rows={4}
              onChange={(e) => {
                onContentChange(e);
              }}
            />
          </Form.Item>
        </Form>

        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}>
          {data?.list_key && (data.list_key.map((item, index) => {
            return (
              <Form.Item
                label={<Text strong>{item.name}</Text>}
                name={item.name}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: `Vui lòng nhập ${item.name}!`,
                  },
                ]}
              >
                <TextEditor value={item.value} onChange={onKeyValueChange} name={item.name}></TextEditor>
              </Form.Item>
            );
          }))}
        </Form>
      </Col>

      <Col span={12}>
        <Card
          style={{
            backgroundColor: '#F0F2F5',
            height: '100%'
          }}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {props.value.content && (
            <Card>
              <Text>{props.value.content}</Text>
            </Card>
          )}
        </Card>
      </Col>
    </Row >
  );
});

export default memo(EsmsSmsPicker);
