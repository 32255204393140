import React, { Component } from "react";
import { Input, Button, Tag, Card, Popconfirm, Alert, Checkbox, Row, Col, message, Spin } from "antd";
import api from "api";
import AppItem from "./appitem";
import { Tabs, Radio, Space } from 'antd';

const { TabPane } = Tabs;


export default class AppConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstApp: [],
      lstIntalledApp: [],
      loading: true,
      loadingbutton: false,
    };
  }

  componentDidMount = async () => {
    await this.getInstalledApp();
    await api
      .post("app/list")
      .then((res) => {
        this.setState({ lstApp: res, loading: false });
      })
      .catch((err) => { });
  };

  getInstalledApp = async () => {
    await api
      .post("shop/getinstalledapp")
      .then((res) => {
        this.setState({ lstIntalledApp: res });
      })
      .catch((err) => { });
  };

  renderListApp = (category) => {
    return (
      <Row gutter={[16, 16]}>
        {this.state.lstApp.filter(x => x.categories.includes(category)).map((item) => {
          var index = this.state.lstIntalledApp.findIndex((x) => x.id === item.id);
          return <AppItem item={item} index={index} getInstalledApp={this.getInstalledApp} />;
        })}
      </Row>
    )
  }

  render() {
    return (
      <div>
        <Card>
          <Tabs>
            <TabPane tab="Email, Sms, Socical" key="comunication">
              {
                this.state.loading ? (
                  <div style={{ width: "100%", height: 500, display: "flex" }}>
                    <Spin style={{ width: 100, height: 100, margin: "auto" }}></Spin>
                  </div>
                ) :
                  <>
                    {this.renderListApp("communication")}

                  </>
              }
            </TabPane>
            <TabPane tab="Sales chanel, CRM" key="2">
              {
                this.state.loading ? (
                  <div style={{ width: "100%", height: 500, display: "flex" }}>
                    <Spin style={{ width: 100, height: 100, margin: "auto" }}></Spin>
                  </div>
                ) :
                  <>
                    {this.renderListApp("crm")}

                  </>
              }
            </TabPane>

          </Tabs>
        </Card>
      </div >
    );
  }
}
