import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency } from "../common/format/number";
import CustomerPointEverydayChart from "./customerpointeveryday_chart";
import CustomerPerTier from "./customerpertier_chart";
import Header from "./header";
import SendStatistics from "./send_statistics";
import PointsInterval from "./points_interval";
import FunnelPlot from "./plot/funnel";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalCustomer: 0,
    };
  }
  componentDidMount = () => {
    api.post("dashboard/gettotalcustomer").then((res) => {
      this.setState({ totalCustomer: res });
    });
  };

  render() {
    return (
      <div>
        <div className="site-card-wrapper">
          <Row gutter={[16, 24]}>
            <Header totalCustomer={this.state.totalCustomer} />
            <Col span={24}></Col>

            <Col span={16}>
              <CustomerPointEverydayChart />
            </Col>
            <Col span={8}>
              <CustomerPerTier />
            </Col>
          </Row>
          <Row gutter={[16, 24]} style={{ marginTop: 16 }}>
            <Col span={14} style={{ height: "inherit" }}>
              <PointsInterval totalCustomer={this.state.totalCustomer} />
            </Col>
            <Col span={10} style={{ height: "inherit" }}>
              <SendStatistics />
            </Col>
          </Row>
          <Row gutter={[16, 24]} style={{ marginTop: 16 }}>
            <Col span={14} style={{ height: "inherit" }}>
              <FunnelPlot />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
