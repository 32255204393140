import React from "react";
import { Button, message, Row, Col, Input, Upload } from "antd";
import api from "api";
import { getPath } from "common/file.js";
import AddButton from "./addButton";
import { fileImageFilter } from "common/file";
import "../../image_picker/style.css";
import RenderImageSettingMessage from "./render_image_setting";
import RenderCardSettingMessage from "./render_card_setting";
import TextEditor from "components/picker/content_picker/text_picker";
import ImagePicker from "components/picker/image_picker";

export default class RenderSettingMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        type: "",
        message: {},
      },
    };
    this.PopupSaveImageUrl = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.data) this.setState({ data: this.props.data });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data != prevProps.data) {
      this.setState({ data: this.props.data });
    }
  };

  uploadImage = (e) => {
    var form = new FormData();
    if (e.file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");

    if (fileImageFilter(e.file)) {
      form.append("file", e.file);
      this.setState({ isUploading: true });
      api
        .post("File/upload", form)
        .then((data) => {
          this.setState({ isUploading: false });
          var url = getPath(data.path);
          var state = this.state.data;
          state.url = url;
          this.setState({ data: state }, () => {
            this.onChange();
          });
        })
        .catch((err) => {
          message.error("Không thể tải file. Vui lòng thử lại");
          this.setState({ isUploading: false });
          console.log(err);
        });
    } else message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpeg, svg hoặc gif");
  };

  buttonChange = (lstBtn) => {
    var data = { ...this.state.data };
    data.message.buttons = lstBtn;
    this.setState({ data: data });
    this.onChange(data);

  };

  onChange = () => {
    this.props.onChange(this.state.data);
  };

  saveDataImage = (data) => {
    this.setState({ data: data }, () => {
      this.onChange();
    });
  };

  onChangeText = text => {
    var data = { ...this.state.data };
    data.message.text = text;
    this.setState({ data: data });
    this.onChange(data);

  }

  render() {
    return (
      <Row gutter={20} style={{ width: "100%" }}>
        <Col span={2}></Col>
        <Col span={20} style={{ display: "flex" }}>
          <div style={{ width: 260, margin: "auto", backgroundColor: "white" }}>
            {this.state.data.type === "text" && (
              <>
                <div style={{ backgroundColor: "#F1F3F5", borderTopLeftRadius: 15, borderTopRightRadius: 15, }}>
                  <TextEditor value={this.state.data.message.text} onChange={(e) => this.onChangeText(e)} autoSize={{ minRows: 3, maxRows: 8 }} ></TextEditor>
                  <div>
                    {this.state.data?.message?.image &&
                      <>
                        <div style={{ paddingLeft: 15 }}><label>Sub title</label></div>
                        <TextEditor value={this.state.data.message.sub_text}
                          autoSize={{ minRows: 3, maxRows: 8 }}
                          onChange={e => {
                            var data = { ...this.state.data };
                            data.message.sub_text = e;
                            this.setState({ data: data });
                            this.onChange(data);


                          }}
                          rows={3} ></TextEditor>
                      </>
                    }

                  </div>
                  <div style={{ paddingLeft: 15 }}><label>Hình ảnh</label></div>
                  <ImagePicker hidePreview hideLibary showClear value={this.state.data.message.image}

                    onChange={e => {
                      var data = { ...this.state.data };
                      data.message.image = e;
                      this.setState({ data: data });
                      this.onChange(data);
                    }}></ImagePicker>
                </div>

                <div>
                  <AddButton onChange={this.buttonChange} value={this.state.data.message.buttons}></AddButton>
                </div>
              </>
            )}

          </div>
        </Col>
        <Col span={2}></Col>
      </Row>
    );
  }
}
