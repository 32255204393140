import React from "react";

export default class AppPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lstSelect:[],
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.value!==this.props.value){
          //Perform some operation
          this.setState({lstSelect: nextProps.value });
          // ....
        }
      }

    onChange = (value) => {
        var lstSelect=this.state.lstSelect;
        var index=this.state.lstSelect.findIndex(x=> x==value);
        if(index===-1) lstSelect.push(value);
        else lstSelect.splice(index,1);
        this.setState({lstSelect:lstSelect});
        if (this.props.onChange) {
            this.props.onChange(lstSelect);
        }
    };




    renderItem(item) {
        var check=this.state.lstSelect.find(x=> x==item.id);
        return (
            <div style={{ marginRight: "20px" }}>
                <label >

                    <div onClick={() => this.onChange(item.id)} style={check ? { width: "100px", height: "100px", cursor: "pointer", border: "1px solid #26C4FF" } : {width: "100px", height: "100px", cursor:"pointer"}}>

                        <img src={item.logo} style={{ width: "100%", height: "100%" }}></img>
                    </div>

                </label>
                <div style={{ display: "flex", width: "100px", marginTop: "10px" }}>
                    <span style={{ margin: "auto", textAlign: "center" }}>{item.name}</span>
                </div>
            </div>
        )
    }

    render() {
        var lst = this.props.lstApp;
        return (

            <div style={{ display: "flex" }}>
                {lst.map(item => { return this.renderItem(item) })}
            </div>

        )
    }
}