import React from 'react';
import{Typography} from "antd";

const { Title } = Typography;

export default class NamePicker extends React.Component {

    onChange = (value) => {

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    render() {
        return(
            <Title level={2} style={{ marginLeft: 10 }} editable={{
                onChange: this.onChange,
                icon: <i className="fa fa-edit" style={{ fontSize: 20, marginLeft: 10 }}></i>
            }}>{this.props.value}</Title>
        )
     }




}