import { useState, memo, useEffect } from "react";
import { Divider, Space, Typography, Avatar, Descriptions, Skeleton } from "antd";
import numeral from "numeral"
import api from "api";
import moment from "moment";
const { Title, Text } = Typography;
const CustomerInformation = ({ props }) => {
  const [user, setUser] = useState({})
  const [totalamount, setTotalAmount] = useState(0)
  const [plans, setPlans] = useState({})
  const [loading, setLoading] = useState(true)
  /**
  * lấy user
  **/
  const getUserByUserName = async () => {
    await api.post("user/getuserdetailbyusername", { user_name: localStorage.getItem("user_name") }).then((res) => {
      setUser(res.data)
    }).catch((err) => {
      setUser({})
    })
  }
  /**
   * lấy tổng tiền
   **/
  const getTotalAmount = async () => {
    await api.post("payment/gettotalamount").then((res) => {
      setTotalAmount(res)
    }).catch((err) => {
      setTotalAmount(null)
    })
  }
  /**
 * lấy plan
 **/
  const getPlan = async () => {
    await api.post("shop/getplansbyshop").then((res) => {
      console.log(res)
      setPlans(res)
    }).catch((err) => {
      setPlans({})
    })
  }
  const getData = async () => {
    await getUserByUserName();
    await getTotalAmount();
    await getPlan();
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, []);

  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <Title level={5} style={{ padding: "8px 0 0 24px" }}>Thông tin tài khoản</Title>
      <Divider style={{ marginTop: 0 }} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space.Compact style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {props?.userAvatar ? (
            <Avatar style={{ width: 100, height: 100 }} src={props.userAvatar}></Avatar>
          ) : (
            <Avatar
              style={{
                backgroundColor: "green",
                verticalAlign: "middle",
                width: 100,
                height: 100,
                fontSize: 38,
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {localStorage.getItem("user_name").slice(0, 1).toUpperCase()}
            </Avatar>
          )}
        </Space.Compact>
        <Space.Compact style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Title level={3}>{localStorage.getItem("user_name")}</Title>
        </Space.Compact>
      </Space>
      <Skeleton loading={loading} active avatar>
        <Descriptions bordered style={{ width: "100%" }} size="small">
          <Descriptions.Item label="Tên shop" span={24}>{localStorage.getItem("shop_name")}</Descriptions.Item>
          <Descriptions.Item label="Người liên hệ" span={24}>{user?.last_name}{" "}{user?.first_name}</Descriptions.Item>
          <Descriptions.Item label="Số điện thoại" span={24}>{user?.phone}</Descriptions.Item>
          <Descriptions.Item label="Số dư" span={24}>{numeral(totalamount).format("0,0")}</Descriptions.Item>
          <Descriptions.Item label="Gói cước" span={24}>{plans?.name}</Descriptions.Item>
          <Descriptions.Item label="Ngày kết thúc" span={24}>{plans?.valid_to?moment(plans.valid_to).format("DD/MM/YYYY"):""}</Descriptions.Item>
        </Descriptions>
      </Skeleton>
    </div>);
};

export default memo(CustomerInformation);