import React, { Component } from "react";
import api from "api";
import { Input, Button, message, Modal } from "antd";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      receiver: "",
      loadings: false,
    };
    this.inputType = React.createRef();
    this.form = React.createRef();
  }

  open = (data) => {
    this.setState({
      visible: true,
      receiver: "",
      data: data.rich_media,
      loadings: false,
    });
    this.getEmailConfig();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (value) => {
    this.setState({ receiver: value });
  };

  getEmailConfig = async () => {
    await api.post("loyaltyconfig/getemailconfig").then((res) => {
      this.setState({
        receiver: res,
      });
    });
  };

  send = () => {
    var data = {
      to: this.state.receiver,
      html: this.state.data.html,
      subject: this.state.data.subject,
      attachments: this.state.data.attachments,
    };
    api
      .post("testemailcontent/testcontent", { data: data })
      .then((res) => {
        this.setState({
          loadings: false,
        });
        message.success("Gửi thành công", 3);
      })
      .catch((err) => {
        this.setState({
          loadings: false,
        });
        message.error(`Gửi thất bại (${JSON.stringify(err.message)})`, 3);
      });
  };

  render() {
    return (
      <Modal
        title="Gửi email test "
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        footer={[
          <Button onClick={this.handleCancel}>Thoát</Button>,
          <Button
            type="primary"
            loading={this.state.loadings}
            onClick={() => {
              this.setState({ loadings: true }, () => this.send());
            }}
          >
            Gửi
          </Button>,
        ]}
      >
        <div>
          <div>
            <label>Người nhận:</label>
          </div>

          <Input
            value={this.state.receiver}
            onChange={(e) => {
              this.onChange(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
    );
  }
}
