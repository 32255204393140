import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { calcTextWidth, SELECT_WIDTH_OFFSET_RIGHT } from "../../../../utils/domUtils";
import { mapListValues } from "../../../../utils/stuff";
import { useOnPropsChanged } from "../../../../utils/reactUtils";
import omit from "lodash/omit";
import debounce from 'lodash/debounce';

const Option = Select.Option;


export default class MultiSelectWidget extends PureComponent {
    static propTypes = {
        setValue: PropTypes.func.isRequired,
        config: PropTypes.object.isRequired,
        value: PropTypes.array,
        field: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        customProps: PropTypes.object,
        fieldDefinition: PropTypes.object,
        readonly: PropTypes.bool,
        // from fieldSettings:
        listValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        allowCustomValues: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        // useOnPropsChanged(this);
        this.state = {
            optionsData: [],
            optionsMaxWidth: 0,
            loading: false
        };
        // this.onPropsChanged(props);
        // this.setDataForOption().then((rs) => {

        // })
    }



    onSearch = (value) => {
        //console.log('tim kiem', value)
        this.onDelaySearch(value);
        //this.setDataForOption("máy").then(() => { })
    }


    onDelaySearch = debounce(query => {
        this.setState({
            loading: true
        })
        this.setDataForOption(query).then(() => { })
    }, 1000);

    handleChange = (val) => {
        //this.rewidth();
        if (val && !val.length)
            val = undefined; //not allow []
        this.props.setValue(val);
        this.setDataForOption().then(() => { })
    }


    setDataForOption = async (s) => {
        // console.log('async fetch', s)
        var rs = await this.props.asyncFetch(s);

        let optionsMaxWidth = 0;
        mapListValues(rs, ({ title, value }) => {
            optionsMaxWidth = Math.max(optionsMaxWidth, calcTextWidth(title, null));
        });

        this.setState({
            optionsData: rs,
            optionsMaxWidth: optionsMaxWidth,
            loading: false
        }, () => {

        })
    }

    rewidth = () => {
        //console.log('width')
        var data = this.state.optionsData;
        let optionsMaxWidth = 0;
        mapListValues(data, ({ title, value }) => {
            optionsMaxWidth = Math.max(optionsMaxWidth, calcTextWidth(title, null));
        });

        this.setState({
            optionsMaxWidth: optionsMaxWidth
        })
    }

    renderOption = () => {
        //console.log('redner nè', this.state.optionsData.length)
        var data = this.state.optionsData;

        var arr = data.map(item => {
            return (<Option key={item.value} value={item.value}>{item.title}</Option>);
        });

        return arr;
    }

    componentDidMount = () => {
        this.setDataForOption().then(() => { })
    }


    render() {
        const { config, placeholder, allowCustomValues, customProps, value, readonly } = this.props;
        const { renderSize } = config.settings;
        const placeholderWidth = calcTextWidth(placeholder);
        const aValue = value && value.length ? value : undefined;
        const width = aValue ? null : placeholderWidth + SELECT_WIDTH_OFFSET_RIGHT;
        const dropdownWidth = this.state.optionsMaxWidth + SELECT_WIDTH_OFFSET_RIGHT;
        const customSelectProps = omit(customProps, ["showCheckboxes"]);

        return (
            <Select
                disabled={readonly}
                mode={allowCustomValues ? "tags" : "multiple"}
                style={{
                    minWidth: width,
                    width: width,
                }}
                // dropdownStyle={{
                //     width: dropdownWidth,
                // }}
                key={"widget-multiselect"}
                dropdownMatchSelectWidth={false}
                placeholder={placeholder}
                size={renderSize}
                value={aValue}
                onChange={this.handleChange}
                onSearch={this.onSearch}
                filterOption={false} // async ko set cái này true dc
                loading={this.state.loading}
                //filterOption={this.filterOption}
                {...customSelectProps}
            >
                {/* {this.options} */}
                {this.renderOption()}
            </Select>
        );
    }
}
