import React from "react";
import { Tabs, Typography } from "antd";
import LiveOrder from "./LiveOrder";
import OrderHistory from "./OrderHistory";

const { Title } = Typography;
function OrderTabs() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Đơn hàng",
      children: <LiveOrder />,
    },
    {
      key: "2",
      label: "Lịch sử giao dịch",
      children: <OrderHistory />,
    },
  ];
  return (
    <>
      <Title level={5}>Danh sách đơn hàng</Title>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </>
  );
}

export default OrderTabs;
