import React, { Component } from "react";
import { Form, Input, Modal, message } from "antd";
import api from "api";

export default class PopupPassWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      checkPassword: true,
      data: {
        password: "",
        newpassword: "",
        repassword: "",
      },
    };
    this.Form = React.createRef();
  }
  open = () => {
    this.setState({ visible: true, checkPassword: true }, () => {
      this.Form.current.setFieldsValue(this.state.data);
    });
  };

  checkPass = () => {
    var data = this.Form.current.getFieldsValue();

    if (data.newpassword !== data.repassword) {
      this.setState({
        checkPassword: false,
      });
    } else {
      this.setState({
        checkPassword: true,
      });
    }
  };

  onFinish = (values) => {
    values.avatar = this.state.avatar;
    this.setState({ data: values });
    api
      .post("profile/savepasswordid", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        if (err.code === 270) {
          message.error("Password không đúng");
        } else {
          message.error("Có lỗi xảy ra");
        }
      });
  };

  render() {
    return (
      <>
        <Modal
          title="Đổi mất khẩu"
          open={this.state.visible}
          onOk={() => this.Form.current.submit()}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={this.onFinish} ref={this.Form}>
            <Form.Item label="Mật khẩu" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item label="Mật khẩu mới" name="newpassword" rules={[{ required: true, message: "Please input your password!" }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item label="Nhập lại mật khẩu mới" name="repassword" validateStatus={!this.state.checkPassword ? "error" : ""} help={!this.state.checkPassword ? "Nhập lại password không khớp" : ""}>
              <Input.Password
                onChange={() => {
                  this.checkPass();
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
