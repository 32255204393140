import React, { memo, useRef, useState, forwardRef, useImperativeHandle } from "react";
import TextEditor from "../text_picker";
import { Col, Row, Form, Card, Input, Typography, Button } from "antd";
import { useEffect } from "react";
import { validate } from "numeral";

const { TextArea } = Input;
const { Text } = Typography;

/**
 * SmsFptEditor dùng chỉnh sửa tin nhắn sẽ gửi qua FPT  sms
 *
 */
const SmsFptEditor = forwardRef((props, ref) => {
  const [contentValue, setContentValue] = useState();
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.value) {
      setContentValue(props.value);

      form.setFieldsValue({ content_picker_sms_fpt_send: props.value });
    }
  }, []);

  const onChangeValue = (e) => {
    var newContent = props.value;
    newContent = form.getFieldsValue().content_picker_sms_fpt_send;
    props.onChange(newContent);
    setContentValue(form.getFieldsValue().content_picker_sms_fpt_send);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: validate,
      };
    },
    [props]
  );

  const validate = () => {
    if (!props.value || props.value === "") {
      var error = "Vui lòng nhập nội dung gửi";
      return error;
    } else {
      return null;
    }
  };

  return (
    <>
      <Row style={{ minHeight: 400 }}>
        <Col span={12} style={{ padding: 8 }}>
          <Form ref={ref} form={form} name="sms_fpt" layout="vertical">
            <Form.Item
              label="Nội dung gửi"
              name="content_picker_sms_fpt_send"
              style={{
                marginBottom: 8,
              }}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập nội dung gửi",
                },
              ]}
            >
              <TextEditor ref={ref} rows={8} onChange={onChangeValue} />
            </Form.Item>
            <Text>Lưu ý: Tin nhắn quá 150 ký tự sẽ tính phí gấp đôi!</Text>
          </Form>
        </Col>
        <Col span={12} style={{ padding: 8 }}>
          <Row style={{ height: "100%", display: "flex", background: "#F0F2F5" }} justify="center" align="middle">
            <Card style={{ width: "400px", minHeight: "100px" }}>{contentValue}</Card>
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default memo(SmsFptEditor);
