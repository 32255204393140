import React, { Component } from "react";
import { Button, Input, message, Progress, Popconfirm, Modal, Avatar, Card, Tag, Select, Form } from "antd";
import AddNewEdit from "./add";
import AntTable from "components/common/AntTable";
import CustomerInfoPopup from "./add/customer_info_popup.jsx";
import api from "api";
import { renderValue, renderInput, dataTypes, makeid } from "common/custom_field";
import moment from "moment";
import { formatShortDate, formatLongDate } from "components/common/format/date";
import OpenCustomer from "./opencustomer";
import PopupImportExcel from "./Excel/PopupImportEcxel";

import CustomerFilter from "components/campaign/segment/addnewedit/cutomer_filter";

// Tien import
///Begin

///End

const { Search } = Input;
export default class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.Tb = React.createRef();
    this.AddNewEdit = React.createRef();
    this.CustomerInfoPopup = React.createRef();
    this.filterPopover = React.createRef();
    this.PopupImportExcel = React.createRef();

    this.CustomerFilter = React.createRef();
  }

  addNew = () => {
    this.popupAddEdit.current.open();
  };

  //=====End=====
  visibleModal = () => {
    let visibleSegmentModal = !this.state.visibleSegmentModal;
    this.setState({ visibleSegmentModal: visibleSegmentModal });
  };
  //===== END =====
  render() {
    return (
      <>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', flexWrap: 'wrap' }}>
      
          <div style={{ display: 'flex', width: '650px', justifyContent: 'space-around', flexWrap: 'wrap' }}>
            <Button onClick={() => this.AddNewEdit.current.open(0)}><i className="fas fa-plus"></i> &nbsp;Thêm khách hàng</Button>
            <Button onClick={() => {
              this.PopupImportExcel.current.open();
            }}><i className="far fa-file-excel"></i> &nbsp;Nhập Excel</Button>
            <Button onClick={() => this.visibleModal()}><i className="fa fa-user-plus" aria-hidden="true"></i> &nbsp;Tạo nhóm khách hàng</Button>

            <Button onClick={() => this.exportExcel()}><i className="fa fa-download" aria-hidden="true"></i> &nbsp;Xuất Excel</Button>
          </div>
        </div> */}
        <CustomerFilter ref={this.CustomerFilter} id={this.state.segmentId}></CustomerFilter>
        {/* <PopupImportExcel ref={this.PopupImportExcel}></PopupImportExcel>
        <AddNewEdit ref={this.AddNewEdit} getData={() => this.Tb.current.reload()}></AddNewEdit>
        <CustomerInfoPopup ref={this.CustomerInfoPopup}></CustomerInfoPopup> */}
      </>
    );
  }
}
