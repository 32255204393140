import React, { Component } from "react";

import { Form, Input, InputNumber, Button, Space, Popconfirm, message } from 'antd';
import { Typography } from 'antd';
import PopupCustomerField from "components/customer/Excel/PopupCustomerField";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { dataTypes, makeid } from "common/custom_field"

import api from "api"
const { Paragraph } = Typography;
const { TextArea } = Input;
export default class FieldNew extends Component {

    constructor(props) {
        super(props);
        this.state = {  }
        this.form = React.createRef();
        this.PopupCustomerField=React.createRef();
    }

    save = (values) => {
        values.attribute=this.toNoUnicode(values.attribute);
        if(values.attribute=='' || values.attribute==null)
        {
            message.error('Vui lòng nhập tên cột');
        }
        else
        {
            values.attribute=values.attribute;
            if(this.props.lstdata.findIndex(x=> x==this.props.data)>=0 && this.props.lstdata.length>0)
            {
                if(this.props.lstdata.findIndex(x=> x!=this.props.data && x.attribute==values.attribute)>=0)
                {
                    message.error('Tên cột đã tồn tại');
                }
                else{
                    this.props.save({ ... this.props.data, ...values },'edit');
                }
            }
            else
            {
                if(this.props.lstdata.findIndex(x=> x.attribute==values.attribute)>=0)
                {
                    message.error('Tên cột đã tồn tại');
                }
                else{
                    this.props.save({ ... this.props.data, ...values },'add');
                }
            }
        }


        
    }

    render() {
        var item = this.props.data;
        
        return (
            <div  style={{width:"100%", backgroundColor:'#ffffff', display:'flex', justifyContent:'space-between', height:'50px', padding:'18px 20px', border:'1px solid #dfdfdf', borderTop:'0'}}>
                <div className=" flex-grow-1">
                    {item.label}
                </div>
                <div>
                    <Space>

                        <a href="#" onClick={e => { e.preventDefault(); this.PopupCustomerField.current.open(item.id); }}>
                            <i className="far fa-edit"></i>
                        </a>
                        <Popconfirm
                            title= "Bạn có chắc muốn xoá không?"
                            onConfirm={() => { this.props.deleteItem(item.id) }}

                            okText= "Có"
                            cancelText= "Không"
                        >
                            <a href="#" onClick={e => { e.preventDefault() }}>
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        </Popconfirm>

                    </Space>

                </div>
                <PopupCustomerField ref={this.PopupCustomerField} reloadOption={this.props.reloadOption}></PopupCustomerField>
            </div>
        )
    }

}