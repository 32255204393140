import { Button, Checkbox, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import api from "api";

function AddNewWooModal({ isModalOpen, setIsModalOpen }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true);
        var payload = form.getFieldsValue();
        checkActive(payload);
        // api
        //   .post("app/woo/config/save", payload)
        //   .then((res) => {
        //     setIsLoading(false);
        //     message.success("Lưu thành công");
        //     setIsModalOpen(false);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setIsLoading(false);
        //     message.error(err.error);
        //   });
      })
      .catch((err) => {
        console.log("lỗi");
      });
    // setIsModalOpen(false);
  };

  const checkActive = (data) => {
    api
      .post("app/woo/config/checkactivekey", data)
      .then((res) => {
        saveConfig(data);
      })
      .catch((err) => {
        message.error("Mã khách hàng và mã bí mật khách hàng không hoạt động");
        setIsLoading(false);
        console.log(err);
      });
  };

  const saveConfig = (payload) => {
    api
      .post("app/woo/config/save", payload)
      .then((res) => {
        setIsLoading(false);
        message.success("Lưu thành công");
        setIsModalOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      confirmLoading={isLoading}
      title="Thêm mới website WooCommerce"
      open={isModalOpen}
      onOk={handleOk}
      okText="Lưu"
      onCancel={handleCancel}
      cancelText="Thoát"
    >
      <Form layout="vertical" name="basic" form={form} autoComplete="off">
        <Form.Item
          label="Url Website"
          name="url"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập url website wordpress",
            },
            {
              validator: (_, value) => {
                if (value && value.slice(0, 8) !== "https://") {
                  return Promise.reject("Vui lòng nhập đúng định dạng website");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="https://cubetech.vn" />
        </Form.Item>

        <Form.Item
          label="Mã khách hàng"
          name="consumerKey"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập vào Mã khách hàng",
            },
          ]}
        >
          <Input placeholder="Mã khách hàng" />
        </Form.Item>

        <Form.Item
          label="Mã bí mật khách hàng"
          name="consumerSecret"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập vào Mã bí mật khách hàng",
            },
          ]}
        >
          <Input placeholder="Mã bí mật khách hàng" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddNewWooModal;
