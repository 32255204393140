import React, { Component } from "react";
import { Button, List, Row, Col, Divider, Card, Empty, Typography, Switch, Popconfirm, message } from "antd";
import PopupCreatePointProgram from "./popupcreatepointprogram";
import api from "api";
import { handleTemplateDescription } from "../../common/text.js";
import PopupCreateExchangPointProgram from "./exchange_point_program/popupcreateexchangepointprogram";
import Point from "./points";
import Expiry from "./expiry";
import PopupCreateGameProgram from "./gameprogram/popupcreategameprogram";
import { useNavigate } from "react-router";

const { Title } = Typography;

class GameProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstGame: [],

      showProgram: false,
    };
    this.PopupCreateGameProgram = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var lstGame = await api.post("gameprogram/getlatestconfigdetails");

    this.setState({ lstGame: lstGame });
  };

  navigateEdit = (item) => {
    var href = "";
    api.post("gameprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = res.config_url + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };

  deleteGameConfirm = (id) => {
    api.post("gameprogram/deletegame", { id: id }).then((res) => {
      if (res) {
        message.success("Xoá thành công");
        this.getData();
      }
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Trò chơi
            </div>
            <div className="description">
              Tạo ra những trò chơi giúp khách hàng thư giãn
              <br></br> và nhận được nhiều phần quà to lớn
            </div>
            <br></br>
            <Button
              type="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                this.PopupCreateGameProgram.current.open();
              }}
            >
              Tạo Hình Thức Chơi Game Nhận Quà
            </Button>
          </Col>
          <Col span={14}>
            {this.state.show && (this.state.lstGame == null || this.state.lstGame.length === 0) ? (
              <Card title={<h5 style={{ marginTop: 10 }}>{"Chơi game nhận quà"}</h5>} style={{ marginLeft: 20 }} bodyStyle={{ height: "25vh" }}>
                <div>
                  <Empty description="Hãy tạo mới trò chơi đầu tiên"></Empty>
                  <Row justify="center">
                    <Button
                      onClick={() => {
                        this.PopupCreateGameProgram.current.open();
                      }}
                      style={{ marginTop: 20 }}
                      type="primary"
                    >
                      <i className="fas fa-plus"></i>&nbsp;Tạo mới
                    </Button>
                  </Row>
                </div>
              </Card>
            ) : (
              <Card
                bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                title={
                  <Title level={3} style={{ marginTop: 8 }}>
                    Chơi game nhận quà
                  </Title>
                }
                extra={
                  <Button
                    type="link"
                    onClick={() => {
                      this.props.navigate("/marketing/game/list");
                    }}
                  >
                    Xem tất cả
                  </Button>
                }
              >
                <List
                  style={{ background: "white" }}
                  // header={
                  //   <div style={{ marginBottom: 10 }}>
                  //     <h2>{"Chơi game nhận quà"}</h2>
                  //     <a href="/marketing/game/list" className="view-all">
                  //       Xem Tất Cả
                  //     </a>
                  //   </div>
                  // }
                  dataSource={this.state.lstGame}
                  renderItem={(item) => {
                    var href = item.config_url + item.type + "/" + item.id;
                    return (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={<img src={item.image} style={{ width: 70, height: 70 }} />}
                          title={<div className="listTitle">{item.name}</div>}
                          description={<div className="description">{item.display_short_description}</div>}
                        />
                        <a
                          onClick={() => {
                            this.navigateEdit(item);
                          }}
                          className="edit-link"
                        >
                          Chỉnh sửa
                        </a>
                      </List.Item>
                    );
                  }}
                ></List>
              </Card>
            )}
          </Col>
        </Row>

        <PopupCreateGameProgram ref={this.PopupCreateGameProgram} />
      </div>
    );
  }
}

const GameProgramHoc = () => {
  const navigate = useNavigate();
  return <GameProgram navigate={navigate} />;
};

export default GameProgramHoc;
