
import React, { Component } from "react";


import "./game.css"




export default class Header extends Component {





    render() {
        return (

            <div style={{ position: "absolute", top: 20, display: "flex", paddingLeft: 35, paddingRight: 35, width: "100%" }}>

                <div>
                    <div className="lk_btn" onClick={e => { }}>
                        <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/close.png"></img>

                    </div>
                </div>
                <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "white", height: 34, width: 158, borderRadius: 17, padding: 3 }}>
                            <div style={{ width: "100%", height: "100%", borderColor: "#FFC077", borderWidth: 1.29, borderStyle: "solid", borderRadius: 15, paddingLeft: 15, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <div> Có  <b>1/30</b> lượt</div>
                            </div>
                        </div>
                    </div>
                    <div className="lk_btnMoreTurn">
                        <div>
                            Thêm
                        </div>

                    </div>
                </div>
                <div>
                    <div className="lk_btn" onClick={e => { this.props.onEvent({ name: "mute", data: !this.props.options?.mute }) }}>
                        {this.props.options?.mute ?
                            <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/sound_off.png"></img>
                            :
                            <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/sound_on.png"></img>
                        }

                    </div>

                </div>


            </div>


        )
    }


}
