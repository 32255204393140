import React, { useEffect, useRef, useState } from "react";
import * as PIXI from "pixi.js";
// import gsap from "gsap";
// import ModalZaUI from "components/common/modal/ModalZaUI";
// import { Text, useNavigate } from "zmp-ui";
// import { sound } from "@pixi/sound";
// import { getStorage } from "common/zalo_storage/ZaloStorage";
// import PolicySheet from "components/common/bottom_sheet/PolicySheet";
import api from "api";
// import { getUserInfo } from "zmp-sdk/apis";
import { useNavigate, useParams } from "react-router-dom";

const WheelOfFortune = ({ data }) => {
  const app = useRef(null);
  const wheel = useRef(null);

  window.renderWheel = (data) => {
    var texture01 = PIXI.Texture.from(data.background?.image_url);
    const background = new PIXI.Sprite(texture01);
    const closeButton = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.close_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/close_button.svg"
    );
    const addRollButton = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.add_turn_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/add_roll_button.png"
    );
    let isSound = true;
    const soundOnTextTure = PIXI.Texture.from(
      data.themes ? JSON.parse(data.themes).themes_data.sound_on_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/sound_on_button.svg"
    );
    const soundOffTextTure = PIXI.Texture.from("https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/sound_off_button.svg");
    const soundButton = PIXI.Sprite.from(soundOnTextTure);
    const wheelWrapper = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.wheel_wrapper : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/wheel_wrapper_final.png"
    );
    // const wheelArrow = PIXI.Sprite.from("https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/wheel_arrow.png");
    const wheelSpin = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.spin_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/wheel_spin_2.png"
    );
    const spinButton = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.start_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/start_button.svg"
    );
    const historyButton = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.history_btn : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/history_button.png"
    );
    // const historyText = new PIXI.Text("Lịch sử", {
    //   fontFamily: "Arial",
    //   fontSize: 20,
    //   fontWeight: 700,
    //   fill: 0xffffff,
    // });
    const infoButton = PIXI.Sprite.from(
      data.themes ? JSON.parse(data.themes).themes_data.info_btn : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/info_button.png"
    );
    // const infoText = new PIXI.Text("Thể lệ", {
    //   fontFamily: "Arial",
    //   fontSize: 20,
    //   fontWeight: 700,
    //   fill: 0xffffff,
    // });

    const sections = data.slices.map((slice, index) => {
      return {
        id: index,
        backgroundColor: slice.backgroundColor,
        text: slice.display.text.value,
        percent: slice.ratio,
        color: slice.display.text.color,
        reward: slice.reward,
      };
    });

    var width = 390;
    var height = 844;

    var stageContainer = new PIXI.Container();

    //render background
    background.width = width;
    background.height = height;
    window.wheel_background = background;
    stageContainer.addChild(background);

    closeButton.width = 40;
    closeButton.height = 40;
    closeButton.x = 16;
    closeButton.y = 48;

    stageContainer.addChild(closeButton);

    addRollButton.width = 200;
    addRollButton.height = 40;
    addRollButton.x = width / 2 - 100;
    addRollButton.y = 48;

    stageContainer.addChild(addRollButton);

    soundButton.width = 40;
    soundButton.height = 40;
    soundButton.x = width - 16 - 40;
    soundButton.y = 48;

    stageContainer.addChild(soundButton);

    wheel.current = new PIXI.Container();
    wheel.current.x = width / 2;
    wheel.current.y = height / 2 + 50;
    stageContainer.addChild(wheel.current);

    // const

    const len = sections.length / 2;
    sections.forEach((section, index) => {
      if (!section.reward.text) {
        section.reward.text = section.text;
      }
      const arc = new PIXI.Graphics();
      arc.beginFill(section.backgroundColor);
      arc.moveTo(0, 0);
      arc.arc(0, 0, 150, index * (Math.PI / len), (index + 1) * (Math.PI / len));
      arc.endFill();

      const text = new PIXI.Text(section.text, {
        fontFamily: "Arial",
        fontSize: 14,
        fill: section.color,
        wordWrap: true,
        fontWeight: "bold",
      });
      text.anchor.set(0.5);

      // Tính toán góc quay cho văn bản
      const angle = ((2 * index + 1) * Math.PI) / sections.length;
      text.position.set(90 * Math.cos(angle), 90 * Math.sin(angle));

      // Đặt thuộc tính quay cho văn bản để hướng về trung tâm của bánh xe
      text.rotation = angle;

      arc.addChild(text);

      wheel.current.addChild(arc);
    });

    wheelWrapper.anchor.set(0.5);
    wheelWrapper.width = 340;
    wheelWrapper.height = 340;
    wheelWrapper.x = width / 2;
    wheelWrapper.y = height / 2 + 50;

    // wheelArrow.anchor.set(0.5);
    // wheelArrow.y = -50;

    // wheelWrapper.addChild(wheelArrow);
    stageContainer.addChild(wheelWrapper);

    wheelSpin.anchor.set(0.5);
    wheelSpin.width = 96;
    wheelSpin.height = 96;
    wheelSpin.x = width / 2;
    wheelSpin.y = height / 2 + 50;

    stageContainer.addChild(wheelSpin);

    spinButton.anchor.set(0.5);
    spinButton.width = 200;
    spinButton.height = 80;
    spinButton.x = width / 2;
    spinButton.y = height / 2 + 270;

    stageContainer.addChild(spinButton);

    // spinButton.anchor.set(0.5);
    historyButton.width = 70;
    historyButton.height = 70;
    historyButton.x = 16;
    historyButton.y = height - 80;

    // historyText.x = 0;
    // historyText.y = 70;
    // historyButton.addChild(historyText);
    stageContainer.addChild(historyButton);

    infoButton.width = 70;
    infoButton.height = 70;
    infoButton.x = width - 86;
    infoButton.y = height - 80;

    // infoText.x = 0;
    // infoText.y = 70;
    // infoButton.addChild(infoText);
    stageContainer.addChild(infoButton);

    return stageContainer;
  };

  window.setBackground = (url) => {
    var texture01 = PIXI.Texture.from(url);
    window.wheel_background.texture = texture01;
  };
  // window.reloadWheel = (data)=>{
  //   if (data!== undefined) {
  //     window.pixiapp.destroy(true,true);
  //     window.renderWheel(data);
  //   }

  // }

  useEffect(() => {
    app.current = new PIXI.Application({
      width: 390,
      height: 844,
      backgroundColor: 0xffffff,
      autoDensity: true,
      resolution: window.devicePixelRatio,
    });

    var stage = window.renderWheel(data);
    window.wheel_pixi = app.current;

    app.current.stage = stage;
    document.getElementById("wheel-container").appendChild(app.current.view);

    return () => {
      app.current.destroy(true, true);
    };
  }, []);

  return (
    <div style={{ width: 390, height: 844 }}>
      {/* <h1>Wheel of Fortune</h1> */}
      <div id="wheel-container" style={{ width: 390, height: 844 }}></div>
      <div style={{ position: "absolute", top: 60, left: "140px" }}>
        Có <span style={{ fontWeight: 700 }}>{data.customer_turn}</span> lượt
      </div>
    </div>
  );
};

export default WheelOfFortune;
