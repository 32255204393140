import React, { useEffect, useState } from 'react'
import { Button, Modal, DatePicker, Space, message, Form } from 'antd';
import api from "api";
import dayjs from 'dayjs';

function ModalShopManagement({ isModalOpen, setIsModalOpen, shop, refTable }) {
  const [form] = Form.useForm();

  const handleOk = async () => {
    var valid_from = form.getFieldValue('valid_from');
    var valid_to = form.getFieldValue('valid_to');

    await api.post('master/shop/extendshop', { shopID: shop.id, validFrom: valid_from, validTo: valid_to })
      .then((res) => {
        message.success(res.message);
      }).catch((err) => message.error(err.message));
    setIsModalOpen(false);
    refTable.current.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeDatePickerValidFrom = (date) => {
    form.setFieldValue('valid_from', date ? date.startOf('day').format() : "");
  }

  const onChangeDatePickerValidTo = (date) => {
    form.setFieldValue('valid_to', date ? date.add(1, 'day').startOf('day').format() : "")
  }

  const saveFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  return (
    <div>
      <Modal
        title="Gia hạn"
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[]}
      >
        <Form
          layout='vertical'
          onFinish={handleOk}
          onFinishFailed={saveFailed}
        >
          <Form.Item
            label="Ngày bắt đầu"
            name="valid_from"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày bắt đầu!',
              },
            ]}
            initialValue={dayjs()}
            getValueProps={onChangeDatePickerValidFrom}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: '100%' }}
              defaultValue={dayjs()}
              placeholder='Chọn ngày bắt đầu'
            />
          </Form.Item>

          <Form.Item
            label="Ngày kết thúc"
            name="valid_to"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày kết thúc!',
              },
            ]}
            initialValue={dayjs().add(1, 'day')}
            getValueProps={onChangeDatePickerValidTo}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: '100%' }}
              defaultValue={dayjs().add(1, 'day')}
              placeholder='Chọn ngày kết thúc'
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
          >
            <Space style={{ width: "100%", justifyContent: "right" }}>
              <Button onClick={handleCancel}>
                Đóng
              </Button>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </Space>

          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ModalShopManagement