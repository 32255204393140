import { api_url } from "config";

import axios from 'axios';

console.log("axios.create", axios.create, axios)

let getToken = function () {
  if (axiosInstance._TokenKey) {
    return localStorage.getItem(axiosInstance._TokenKey);
  }
  var token = localStorage.getItem("token");
  return token;
};

var axiosInstance = axios.create({
  baseURL: api_url,
  timeout: 10 * 60 * 1000,
  /* other custom settings */
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers["token"] = token;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    var data = response.data;
    if (data.status == "success") {
      return data.data;
    } else {
      console.error("api error: ", data.error);
      return Promise.reject(data.error);
    }
  },
  function (error) {
    console.error("api error: ", error);
    return Promise.reject(error);
  }
);

axiosInstance.setTokenKey = function (key) {
  axiosInstance._TokenKey = key;
};

axiosInstance.setToken = function (token) {
  var key = "";
  if (axiosInstance._TokenKey) {
    key = axiosInstance._TokenKey;
  }
  localStorage.setItem(key, token);
};

axiosInstance.getToken = function () {
  return getToken();
};

export default axiosInstance;
