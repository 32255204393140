import { media_url } from "config";
let getFileExtention = function (path) {
  try {
    return path.match(/\.([^\./\?]+)($|\?)/)[1].toLowerCase();
  } catch (e) {
    return null;
  }
};

let getFileType = function (path) {
  var ext = getFileExtention(path);
  if (!ext) {
    return "unknow";
  }
  var lstAllow = {
    //image
    image: ["jpg", "jpeg", "png", "gif"],
    //audio
    audio: ["mp3"],
    //video
    video: ["mp4", "wav", "mov"],
    //doc
    document: ["doc", "docx", "xls", "xlsx", "pdf"],
    //zip
    compression: ["zip", "rar", "tar"],
  };

  var keys = Object.keys(lstAllow);
  for (let index = 0; index < keys.length; index++) {
    const pr = keys[index];
    if (lstAllow[pr].includes(ext)) {
      return pr;
    }
  }
  return "unknow";
};

let checkFileValidToUpload = function (path) {
  var fileType = getFileType(path);
  return fileType != "unknow";
};

let getPath = function (path) {
  if (path && path.startsWith("https:")) {
    return path;
  }
  return `${media_url}${path}`;
};

let fileImageFilter = (file) => {
  var ext = "";
  var fileName = file.name;
  var i = fileName.lastIndexOf(".");
  if (i === -1) ext = "unknown";
  else ext = fileName.slice(i + 1);
  var lstAllow = [
    //image
    "jpg",
    "jpeg",
    "png",
    "gif",
    "svg",
  ];

  ext = ext.toLocaleLowerCase();
  // Accept images only
  if (!lstAllow.includes(ext)) {
    return false;
  }
  return true;
};

export { getFileExtention, getFileType, checkFileValidToUpload, getPath, fileImageFilter };
