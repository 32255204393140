import React, { PureComponent } from "react";
import { Select } from "antd";
import { toNoUnicode } from "common/text.js";
var $ = window.jQuery;
const Option = Select.Option;
class Select2 extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }
  onChange(value, option) {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, value);
    }
  }

  search(input, option) {
    var data = toNoUnicode(option.props.children.toLowerCase());
    var search = toNoUnicode(input.toLowerCase());
    return data.indexOf(search) >= 0;
  }

  componentWillReceiveProps(nexProps) {}

  render() {
    var data = this.props.data;
    var rx = Math.random();
    var dislayCol = this.props.displayCol ?? 1;
    var valCol = this.props.valCol ?? 0;

    var selectedVal = this.props.value;

    var option = data.map(function (item, index) {
      if (valCol == 0) {
        valCol = Object.keys(item)[0];
        dislayCol = Object.keys(item)[1];
      }
      return (
        <Option value={item[valCol]} key={"option_" + rx + "_" + item[valCol]}>
          {item[dislayCol]}
        </Option>
      );
    });

    var old = data.find(function (item) {
      return item[valCol] == selectedVal;
    });
    if (old == null) {
      if (data) {
        if (data.length > 0) {
          selectedVal = data[0][valCol];
          this.onChange(selectedVal);
        }
      }
    }
    var disabled = false;
    if (this.props.disabled) {
      disabled = this.props.disabled;
    }

    return (
      <Select
        style={{ width: "100%" }}
        onChange={this.onChange}
        value={selectedVal}
        showSearch
        disabled={disabled}
        placeholder={this.props.placeHolder ?? null}
        size={this.props.size ?? "medium"}
        filterOption={this.search}
      >
        {option}
      </Select>
    );
  }
}

export default Select2;
