import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Tabs, Checkbox, Typography, Space } from "antd";
import AntTable from "components/common/AntTable";
import { formatShortDate, formatLongDate } from "components/common/format/date";
import api from "api";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";
import RangePicker from "components/picker/daterange_picker";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import PopupResendNotification from "./popup_resend";
import dayjs from "dayjs";

const { Option } = Select;
const { Paragraph, Text, Link } = Typography;
export default class SendHistory extends Component {
  defaultFilter = {
    create_date: {
      start: dayjs(new Date()).add(-7, "day"),
      // start: moment().format("YYYY-MM-DD"),
      end: dayjs(new Date()),
    },
    apps: [],
    status: "all",
    events: [],
    // customer : {
    info: "all",
    // }
  };
  constructor(props) {
    super(props);
    this.filters = [];

    this.state = {
      data: {},
      filter: {
        ...this.defaultFilter,
      },
      lstApp: [],
      lstEvent: [],
      loadExport: false,
      listSelect: [],
    };
    this.PopupResendNotification = React.createRef();
    this.Tb = React.createRef();
  }

  componentDidMount = () => {
    // console.log("load");
    api.get("app/list").then((data) => {
      this.setState({ lstApp: data });
    });

    api.get("sendhistory/getallevent").then((data) => {
      this.setState({ lstEvent: data });
    });
  };
  componentDidUpdate = () => {
    console.log("filter", this.state.filter);
  };

  onMultiSelectChange = (name, value) => {
    var filter = { ...this.state.filter };
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value == []) {
      if (index > -1);
      filter.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name] = value;
      } else {
        filter.query.$and.push({ [name]: { $all: value } });
      }
    }
    this.setState({ filter: filter }, () => this.Tb.current.reload());
  };

  getColumn = () => {
    var columns = [
      {
        title: "Ngày",
        dataIndex: "create_on",
        key: "create_on",
        sorter: true,
        render: (text, record, index) => {
          var date = moment(record.create_on);

          return <div>{date.format("DD-MM-YYYY HH:mm:SS")}</div>;
        },
      },
      // {
      //     title: 'Nội dung',
      //     dataIndex: 'name',
      //     key: 'name',
      //     sorter: false,
      //     render: (text, record, index) => {

      //         return (
      //             <div>unknow</div>
      //         )
      //     },
      // },
      {
        title: "Kênh gửi",
        dataIndex: "app",
        key: "app",
        sorter: false,
        render: (text, record, index) => {
          var app = this.getAppByID(record.app.id);
          if (app) {
            return <div>{app.name}</div>;
          }
          return <div>{record.app.id}</div>;
        },
      },
      {
        title: "Khách hàng",
        dataIndex: "customer",
        key: "customer",
        sorter: false,
        render: (text, record, index) => {
          var customer = record.customer;

          if (customer) {
            return (
              <div>
                {customer.last_name} {customer.first_name}
                <div>
                  {customer.phone} {customer.email}
                </div>
              </div>
            );
          } else {
            return "";
          }
        },
      },
      // {
      //   title: "Nội dung",
      //   dataIndex: "content",
      //   key: "content",
      //   sorter: false,
      //   render: (text, record, index) => {
      //     var data = record?.dataPost?.data;
      //     if (data) {
      //       switch (record.app.id) {
      //         case "SMS":
      //           return <div>{data.text}</div>;
      //         case "default_email":
      //           return (
      //             <div>
      //               <Link>Nội dung Email</Link>
      //             </div>
      //           );
      //         case "email":
      //           return (
      //             <div>
      //               <Link>Nội dung Email</Link>
      //             </div>
      //           );
      //         case "ESMS":
      //           return (
      //             <div>
      //               <Link>Nội dung ESMS</Link>
      //             </div>
      //           );
      //         default:
      //           return <div>Chưa thực hiện chức năng này</div>;
      //       }
      //     } else {
      //       return <div>Chưa thực hiện chức năng này</div>;
      //     }
      //   },
      // },
      {
        title: "Loại",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (text, record, index) => {
          if (record.source.type == "customer_notification") {
            return `[Thông báo] ${record.event_name}`;
          }

          if (record.source.type == "campaign") {
            return `[Chiến dịch] ${record.campaign_name ?? ""}`;
          }
        },
      },
      {
        title: "Kết quả",
        dataIndex: "result",
        key: "result",
        sorter: false,
        render: (text, record, index) => {
          if (record.result.status == "failed") {
            return (
              <div>
                Thất bại
                {record.result.error && (
                  <Text style={{ width: 100, cursor: "pointer" }} ellipsis={{ tooltip: JSON.stringify(record.result.error) }}>
                    <div>{JSON.stringify(record.result.error)}</div>
                  </Text>
                )}
              </div>
            );
          }

          if (record.result.status == "succeeded") {
            return `Thành công`;
          }
        },
      },
    ];
    return columns;
  };

  onRangePickerChange = ([start, end]) => {
    var create_date = {};
    create_date.start = start.format("YYYY-MM-DD");
    create_date.end = end.format("YYYY-MM-DD");
    this.filterChange("create_date", create_date);
  };

  filterChange = (name, value) => {
    var filter = { ...this.state.filter };
    filter[name] = value;
    this.setState({ filter: filter }, (e) => {
      this.Tb.current.reload();
    });
  };

  getAppByID = (id) => {
    var app = this.state.lstApp.find((x) => x.id == id);
    return app;
  };

  getFilters = () => {
    var filter = this.state.filter;
    var filters = [
      {
        Key: "create_date",
        Name: "Thời gian gửi",
        Component: (
          <Col span={6} style={{ padding: 6 }}>
            <div>
              <label>Thời gian gửi</label>
              <div style={{ display: "flex" }}>
                <RangePicker
                  value={[dayjs(filter.create_date.start), dayjs(filter.create_date.end)]}
                  style={{ width: "100%" }}
                  onChange={this.onRangePickerChange}
                  allowClear={false}
                />
              </div>
            </div>
          </Col>
        ),
      },

      {
        Key: "apps",
        Name: "Kênh gửi",
        Component: (
          <Col span={6} style={{ padding: 6 }}>
            <div>
              <label>Kênh gửi</label>
              <div style={{ display: "flex" }}>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  value={this.state.filter.apps}
                  options={this.state.lstApp
                    .filter((x) => x.categories.includes("communication"))
                    .map((x) => {
                      return { value: x.id, label: x.name };
                    })}
                  onChange={(values) => {
                    this.filterChange("apps", values);
                  }}
                ></Select>
              </div>
            </div>
          </Col>
        ),
      },

      {
        Key: "status",
        Name: "Trạng thái",
        Component: (
          <Col span={6} style={{ padding: 6 }}>
            <div>
              <label>Trạng thái</label>
              <div style={{ display: "flex" }}>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.filter.status}
                  options={[
                    { label: "Tất cả", value: "all" },
                    { label: "Thành công ", value: "succeeded" },
                    { label: "Thất bại", value: "failed" },
                  ]}
                  onChange={(values) => {
                    this.filterChange("status", values);
                  }}
                ></Select>
              </div>
            </div>
          </Col>
        ),
      },

      {
        Key: "events",
        Name: "Sự kiện",
        Component: (
          <Col span={6} style={{ padding: 6 }}>
            <div>
              <label>Sự kiện</label>
              <div style={{ display: "flex" }}>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  value={this.state.filter.events}
                  options={this.state.lstEvent.map((x) => {
                    return { value: x.id, label: x.name };
                  })}
                  onChange={(values) => {
                    this.filterChange("events", values);
                  }}
                ></Select>
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return filters;
  };

  onSelectFilterChange = (name, value) => {
    // var filter = { ...this.state.filter };
    // filter[name] = this.defaultFilter[name];
    // this.setState({ filter: filter })
  };
  getContentByEvent = (event) => {};
  searchSource(source) {
    this.onFilterChange("events", [source.id]);
  }
  exportExcel = () => {
    var pagging = this.Tb.current.getCurrentPagination();
    var filter = { ...this.state.filter };
    filter.pagging = pagging;
    var lstdata = [];
    api.post("sendhistory/list", filter).then((data) => {
      lstdata = data.data;
      var lstexcel = lstdata.map((item) => {
        var o = {};
        o["Số điện thoại"] = item.customer.phone;
        o["Tên khách"] = `${item.customer.last_name} ${item.customer.first_name}`;
        o["Email"] = item.customer.email;
        var date = moment(item.create_on);
        o["Ngày Gửi"] = date.format("DD-MM-YYYY HH:mm:SS");
        var content = "";
        // if(item.event){
        //     content = await this.getContent
        // }
        o["Nội dung"] = content;
        o["Loại"] = item.event ? "Thông báo" : "Chiến dịch";
        o["Kết Quả"] = item.result.status == "failed" ? "Thất bại" : "Thành công";
        return o;
      });

      const ws = XLSX.utils.json_to_sheet(lstexcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dt = new Blob([excelBuffer], { type: ".xlsx" });
      FileSaver.saveAs(dt, "DS_gui_tin" + ".xlsx");
      this.setState({ loadExport: false });
    });
  };
  onFilterChange = (name, value) => {
    var filter = {};
    filter = this.state.filter;
    if (value) {
      filter[name] = value;
    } else {
      delete filter[name];
    }
    this.setState({ filter: filter }, () => {
      this.Tb.current.reload();
    });
  };

  reload = () => {
    this.Tb.current.reload();
  };

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
          <div>
            <SearchBox
              name="campaign"
              data={this.getFilters()}
              onSelectFilterChange={this.onSelectFilterChange}
              placeholder="Thông tin"
              dataButton={[
                <Button
                  style={{ marginLeft: 15 }}
                  loading={this.state.loadExport}
                  type="primary"
                  onClick={() => {
                    this.setState({ loadExport: true }, () => {
                      this.exportExcel();
                    });
                  }}
                >
                  <Space>
                    <i className="fa fa-download" aria-hidden="true"></i>Xuất Excel
                  </Space>
                </Button>,
                // <Button
                //   style={{ marginLeft: 15 }}
                //   loading={this.state.loadExport}
                //   type="primary"
                //   onClick={() => {
                //     var lstFail = this.state.listSelect.filter((x) => x.result.status === "failed");
                //     this.PopupResendNotification.current.open(lstFail);
                //   }}
                // >
                //   <Space>
                //     <i className="far fa-paper-plane"></i>Gửi lại tin
                //   </Space>
                // </Button>,
              ]}
              onSearch={() => this.Tb.current.reload()}
              onChange={(value) => {
                this.onFilterChange("info", value);
              }}
            />
          </div>
        </div>

        <AntTable
          columns={this.getColumn()}
          customSearch={this.state.filter}
          tableName={"tbCustomer"}
          ajax="sendhistory/list"
          ref={this.Tb}
          rowKey={(record) => record.id}
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({ listSelect: selectedRows });
            },
          }}
        ></AntTable>
        <PopupResendNotification ref={this.PopupResendNotification} reload={this.reload}></PopupResendNotification>
      </>
    );
  }
}
