import React, { useEffect, useState } from "react";
import { Card, Typography, Tabs, Form, Button, Space, Input, Switch, Row, Col, Table, Tag, Popconfirm, Divider, message, InputNumber, Select } from "antd";
import { WarningFilled } from "@ant-design/icons";
import UploadImagePicker from "components/picker/upload_image_picker";
import HTMLEditorPicker from "components/picker/html_editor_picker";
import ColorPalettePicker from "components/picker/color_palette_picker";
import { useParams } from "react-router";
import api from "api";
import WheelOfFortune from "./WheelOfForturne";
import AddTurnModal from "./AddTurnModal";
import AddEditRewardModal from "./AddEditRewardModal";

const { Text } = Typography;
const { Column, ColumnGroup } = Table;
function AddEditLuckyWheel() {
  const { id } = useParams();
  const { type } = useParams();
  //   console.log(id);
  const [form] = Form.useForm();
  const [displayData, setDisplayData] = useState();
  const [gameImage, setGameImage] = useState();
  const [gameBackground, setGameBackGround] = useState();
  const [isValid, setIsValid] = useState({ general: false, display: false, add_turn: false, reward: false });
  const [addTurnList, setAddTurnList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [isAddTurnModalOpen, setIsAddTurnModalOpen] = useState(false);
  const [isAddRewardModalOpen, setIsAddRewardModalOpen] = useState(false);
  const [rewardSelected, setRewardSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalRatio, setTotalRatio] = useState(0);
  const [themesList, setThemesList] = useState([]);
  useEffect(() => {
    getThemesGame();
    if (id === "0") {
      var data = {
        name: "Vòng quay 100% trúng quà",
        background: {
          type: "img",
          color: "#880044",
          image_url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/bg_default.png",
        },
        add_turn: [
          { type: "point", name: "100 điểm đổi 1 lượt chơi", points: 100, turns: 1, max_change_times: 4 },
          { type: "point", name: "200 điểm đổi 1 lượt chơi", points: 100, turns: 1, max_change_times: 4 },
        ],
        customer_turn: 1,
        image: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/Default_wheelfortune.png",
        is_active: true,
        logo: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/background_default.png",
        palette: ["#d0021b", "#f8e71c"],
        slices: [
          {
            backgroundColor: "#d0021b",
            display: {
              type: "text",
              text: {
                value: "+100 điểm",
                color: "#ffffff",
              },
              image: {
                url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/discount.png",
              },
            },
            reward: {
              text: "+100 điểm",
              type: "point",
              point: 100,
              add_turn: 1,
            },
            ratio: 50,
          },
          {
            backgroundColor: "#f8e71c",
            display: {
              type: "text",
              text: {
                value: "+100 điểm",
                color: "#d0021b",
              },
              image: {
                url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/discount.png",
              },
            },
            reward: {
              text: "+100 điểm",
              type: "point",
              point: 100,
              add_turn: 1,
            },
            ratio: 50,
          },
        ],
      };

      setDisplayData(data);
      setAddTurnList(data.add_turn);
      setRewardList(data.slices);
      setGameImage(data.image);
      setGameBackGround(data.background.image_url);
      form.setFieldsValue(data);
    } else {
      api
        .post("gameprogram/getconfigbyidandtype", { id: id, type: type })
        .then((res) => {
          if (res.themes) {
            res.themes_id = res.themes.id;
            res.themes = JSON.stringify(res.themes);
          }
          setDisplayData(res);
          setAddTurnList(res.add_turn);
          setRewardList(res.slices);
          setGameImage(res.image);
          setGameBackGround(res.background.image_url);
          form.setFieldsValue(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const getThemesGame = () => {
    api
      .post("gameprogram/getthemes", { game_type: type })
      .then((res) => {
        console.log(res);
        setThemesList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderGeneralForm = () => {
    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={3}>
            <Form.Item
              label="Hoạt động"
              name="is_active"
              valuePropName="checked"
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your username!",
              //     },
              //   ]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={17}>
            <Form.Item
              label="Tên trò chơi"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên trò chơi",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Số lượt miễn phí"
              name="customer_turn"
              rules={[
                {
                  required: true,
                  message: "Số lượt tối thiểu là 0",
                },
              ]}
            >
              <InputNumber
                onBlur={(e) => {
                  // var newDisPlayData = { ...displayData };
                  // newDisPlayData.customer_turn = e.target.value;
                  // setDisplayData(newDisPlayData);
                }}
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]} justify={"center"}>
          <Col style={{ width: 400 }}>
            <Form.Item
              label="Hình ảnh"
              name="image"
              rules={[
                {
                  required: true,
                  message: "Hình ảnh không được để trống",
                },
              ]}
            >
              <UploadImagePicker
                crop={{ quality: 1, aspect: 4 / 3 }}
                onRemove={() => {
                  setGameImage();
                  form.resetFields(["image"]);
                }}
                onChange={(e) => {
                  setGameImage(e);
                  form.setFieldValue(["image"], e);
                  // var newDisPlayData = { ...displayData };
                  // newDisPlayData.background.image_url = e;
                  // setDisplayData(newDisPlayData);
                  reloadWheel();
                }}
                limitImage={1}
                images={gameImage ? [gameImage] : []}
                className={"game_image"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="description" label="Mô tả" style={{ marginBottom: 48 }}>
          <HTMLEditorPicker style={{ height: 296 }}></HTMLEditorPicker>
        </Form.Item>
      </>
    );
  };

  const renderDisplayForm = () => {
    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Themes"
              name={"themes_id"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Hình ảnh không được để trống",
              //   },
              // ]}
            >
              <Select
                options={
                  themesList.length > 0
                    ? themesList.map((item) => {
                        return { label: item.themes_name, value: item.id };
                      })
                    : []
                }
                onChange={(e) => {
                  // console.log(e);
                  var findThemes = themesList.find((item) => {
                    return item.id === e;
                  });
                  if (findThemes) {
                    form.setFieldValue("themes", JSON.stringify(findThemes));
                    setGameBackGround(findThemes.themes_data.background);
                    form.setFieldValue(["background", "image_url"], findThemes.themes_data.background);
                    reloadWheel();
                  }
                }}
              ></Select>
            </Form.Item>
            <Form.Item hidden name={"themes"}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Background game"
              name={["background", "image_url"]}
              rules={[
                {
                  required: true,
                  message: "Hình ảnh không được để trống",
                },
              ]}
            >
              <UploadImagePicker
                limitImage={1}
                images={gameBackground ? [gameBackground] : []}
                crop={{ quality: 1, aspect: 390 / 844 }}
                onRemove={() => {
                  setGameBackGround();
                  form.resetFields([["background", "image_url"]]);
                }}
                onChange={(e) => {
                  console.log(e);
                  setGameBackGround(e);
                  form.setFieldValue(["background", "image_url"], e);
                  // var newDisPlayData = { ...displayData };
                  // newDisPlayData.background.image_url = e;
                  // setDisplayData(newDisPlayData);
                  reloadWheel();
                }}
                className={"game_background"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Chọn màu"
              name="palette"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn màu",
                },
              ]}
            >
              <ColorPalettePicker
                onChange={(data) => {
                  onChangePalette(data);
                }}
              ></ColorPalettePicker>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const onChangePalette = (data) => {
    var plIndex = 0;
    rewardList.forEach((item, index) => {
      if (plIndex >= data.length) {
        plIndex = 0;
      }
      item.backgroundColor = data[plIndex];
      item.display.text.color = getContrastColor(data[plIndex]);
      plIndex++;
      form.setFieldValue(["slices", index, "ratio"], item.ratio);
    });
    form.setFieldValue("slices", rewardList);
    // var newDisPlayData = { ...displayData };
    // newDisPlayData.slices = rewardList;
    // newDisPlayData.palette = data;
    // setDisplayData(newDisPlayData);
    reloadWheel();
  };

  const getContrastColor = (hex) => {
    var aRgbHex = hex.replace("#", "").match(/.{1,2}/g);
    var aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];

    var a = 1 - (0.299 * aRgb[0] + 0.587 * aRgb[1] + 0.114 * aRgb[2]) / 255;

    if (a < 0.5) return "#000000";
    else return "#ffffff";
  };

  const renderAddTurnTable = () => {
    return (
      <Table dataSource={addTurnList}>
        <ColumnGroup
          className="game_config_table"
          // style={{ borderBottom: "1px solid black" }}
          title={
            <>
              <Button
                type="dashed"
                style={{ width: "100%" }}
                onClick={() => {
                  setIsAddTurnModalOpen(true);
                }}
              >
                Thêm mới
              </Button>
            </>
          }
        >
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(name, record, index) => (
              <>
                <Form.Item hidden name={["add_turn", index, "name"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["add_turn", index, "descriptions"]}>
                  <Input />
                </Form.Item>
                <Text>{name}</Text>
              </>
            )}
          />
          <Column
            title="Phương thức thêm"
            dataIndex="type"
            key="type"
            render={(type, record, index) => (
              <>
                <Form.Item hidden name={["add_turn", index, "type"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["add_turn", index, "points"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["add_turn", index, "turns"]}>
                  <Input />
                </Form.Item>
                <Text>{renderAddTurnType(type)}</Text>
              </>
            )}
          />
          <Column
            title="Số lượt tối đa"
            dataIndex="max_change_times"
            key="max_change_times"
            render={(max_change_times, record, index) => (
              <>
                <Form.Item hidden name={["add_turn", index, "max_change_times"]}>
                  <Input />
                </Form.Item>

                <Text>{max_change_times}</Text>
              </>
            )}
          />
          <Column
            align="center"
            dataIndex="id"
            key="id"
            render={(id, record, index) => (
              <>
                {/* {id.map((id) => ( */}
                <Form.Item hidden name={["add_turn", index, "id"]}>
                  <Input />
                </Form.Item>
                <Popconfirm
                  style={{ width: 240 }}
                  title={<Text>Bạn có muốn xoá hình thức thêm lượt này?</Text>}
                  // description="Bạn có muốn xoá hình thức thêm lượt này?"
                  onConfirm={() => {
                    deleteAddTurnConfirm(index);
                  }}
                  // onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button type="link" style={{ color: "red" }} key={id}>
                    Xoá
                  </Button>
                </Popconfirm>

                {/* ))} */}
              </>
            )}
          />
        </ColumnGroup>
      </Table>
    );
  };

  const deleteAddTurnConfirm = (index) => {
    var newList = [...addTurnList];
    newList = newList.filter((item, i) => {
      return i !== index;
    });
    form.setFieldValue("add_turn", newList);
    setAddTurnList(newList);
  };

  const deleteRewardConfirm = (index) => {
    var newList = [...rewardList];
    newList = newList.filter((item, i) => {
      return i !== index;
    });
    form.setFieldValue("slices", newList);
    setRewardList(newList);
  };

  const renderRewardForm = () => {
    return (
      <>
        <Table dataSource={rewardList}>
          <ColumnGroup
            className="game_config_table"
            title={
              <>
                <Button
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setIsAddRewardModalOpen(true);
                  }}
                >
                  Thêm quà
                </Button>
              </>
            }
          >
            <Column
              title="Tên quà"
              dataIndex="display"
              key="display"
              render={(display, record, index) => (
                <>
                  <Form.Item hidden name={["slices", index, "backgroundColor"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "display", "text", "value"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "display", "text", "color"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "display", "type"]}>
                    <Input />
                  </Form.Item>

                  <Text>{display.text.value}</Text>
                </>
              )}
            />
            <Column
              title="Loại quà"
              dataIndex="reward"
              key="reward"
              render={(reward, record, index) => (
                <>
                  <Form.Item hidden name={["slices", index, "reward", "text"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "reward", "type"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "reward", "point"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "reward", "add_turn"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={["slices", index, "reward", "voucher"]}>
                    <Input />
                  </Form.Item>
                  <Text>{renderRewardType(reward.type)}</Text>
                </>
              )}
            />
            <Column
              width={160}
              title={`Tỷ lệ (${totalRatio}%)`}
              dataIndex="ratio"
              key="ratio"
              render={(ratio, record, index) => (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tỷ lệ",
                    },
                  ]}
                  name={["slices", index, "ratio"]}
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber
                    min={1}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    style={{ width: "100%" }}
                    onBlur={(e) => {
                      var newList = [...rewardList];
                      newList[index].ratio = form.getFieldValue(["slices", index, "ratio"]);
                      setRewardList(newList);
                    }}
                    onChange={(e) => {
                      var newList = [...rewardList];
                      newList[index].ratio = e;
                      setRewardList(newList);
                    }}
                  />
                </Form.Item>
              )}
            />
            <Column
              width={144}
              align="center"
              dataIndex="id"
              key="id"
              render={(id, record, index) => (
                <>
                  {/* {id.map((id) => ( */}
                  <Button
                    type="link"
                    key={id}
                    onClick={() => {
                      setRewardSelected({ ...record, index: index });
                      setIsAddRewardModalOpen(true);
                    }}
                  >
                    Sửa
                  </Button>
                  <Popconfirm
                    title={<Text>Bạn có muốn xoá quà này?</Text>}
                    // description=""
                    onConfirm={() => {
                      deleteRewardConfirm(index);
                    }}
                    // onCancel={cancel}
                    okText="Có"
                    cancelText="Không"
                  >
                    <Button type="link" style={{ color: "red" }} key={id}>
                      Xoá
                    </Button>
                  </Popconfirm>

                  {/* ))} */}
                </>
              )}
            />
          </ColumnGroup>
        </Table>
      </>
    );
  };

  const tabItems = [
    {
      key: "general",
      label: (
        <span>
          {isValid.general && <WarningFilled style={{ color: "red" }} />}
          Mô tả chung
        </span>
      ),
      forceRender: true,
      children: renderGeneralForm(),
    },
    {
      key: "display",
      label: (
        <span>
          {isValid.display && <WarningFilled style={{ color: "red" }} />}
          Hiển thị
        </span>
      ),
      forceRender: true,
      children: renderDisplayForm(),
    },
    {
      key: "add_turn",
      label: "Thêm lượt",
      forceRender: true,
      children: renderAddTurnTable(),
    },
    {
      key: "reward",
      label: (
        <span>
          {isValid.reward && <WarningFilled style={{ color: "red" }} />}
          Quà
        </span>
      ),
      forceRender: true,
      children: renderRewardForm(),
    },
  ];

  const checkValidation = () => {
    var newValid = { ...isValid };
    if (rewardList.length < 2) {
      newValid.reward = true;
      setIsValid(newValid);
      message.error("Quà tối thiểu phải là 2");
    } else if (rewardList.length >= 2 && totalRatio !== 100) {
      newValid.reward = true;
      setIsValid(newValid);
      message.error("Tổng tỷ lệ ra quà phải là 100%");
    } else {
      var payload = form.getFieldsValue();

      if (payload.themes) {
        payload.themes = JSON.parse(payload.themes);
        delete payload.themes_id;
      }

      api
        .post("gameprogram/savegame", { ...payload, type: type, id: id !== 0 ? id : null })
        .then((res) => {
          var newValid = { ...isValid };
          Object.keys(newValid).forEach((key) => {
            newValid[key] = false;
          });
          setIsValid(newValid);
          message.success("Lưu thành công");

          if (res.game_id !== id) {
            window.location.href = "/marketing/game/" + type + "/" + res.game_id;
          }
        })
        .catch((err) => {
          message.error("Lưu thất bại");
        });
    }
  };

  const onChangeTabs = () => {
    // if (isValidDisplay) {
    //   setTimeout(() => {
    //     displayForm.validateFields();
    //   }, 100);
    // }
  };

  const renderRewardType = (type) => {
    switch (type) {
      case "point":
        return "Điểm";
      case "voucher":
        return "Voucher";
      case "add_turn":
        return "Thêm lượt";
      case "loss_turn":
        return "Mất lượt";
      default:
        break;
    }
  };

  const renderAddTurnType = (type) => {
    switch (type) {
      case "point":
        return "Đổi điểm";
      case "subscribezalo":
        return "Quan tâm Zalo";
      case "share_to_chat":
        return "Chia sẻ bài viết";
      case "share_to_feed":
        return "Đăng bài viết";
      default:
        break;
    }
  };

  const onChangeAddTurnList = (value) => {
    var newList = [...addTurnList];
    newList.push(value);
    setAddTurnList(newList);
    form.setFieldValue("add_turn", newList);
    message.success("Lưu thành công");
  };

  const onChangeRewardList = (value) => {
    // debugger;
    var newList = [...rewardList];
    if (value.index || value.index === 0) {
      newList[value.index] = value;
      message.success("Lưu thành công");
    } else {
      if (rewardList.length < 10) {
        newList.push(value);
        message.success("Lưu thành công");
      } else {
        message.error("Tối đa chỉ được 10 phần quà");
      }
    }
    setRewardList(newList);
    form.setFieldValue("slices", newList);
  };

  const reloadWheel = () => {
    window.wheel_pixi.stage.removeChildren();
    var stage = window.renderWheel(form.getFieldsValue());
    window.wheel_pixi.stage = stage;
  };

  useEffect(() => {
    if (form.getFieldValue("palette") && rewardList.length > 0) {
      // debugger;
      onChangePalette(form.getFieldValue("palette"));
    }

    if (rewardList.length > 0) {
      var sum = 0;
      rewardList.forEach((item) => {
        sum += item.ratio;
      });
      setTotalRatio(sum);
    }
  }, [rewardList]);

  useEffect(() => {
    if (!isAddRewardModalOpen) {
      setRewardSelected();
    }
  }, [isAddRewardModalOpen]);

  useEffect(() => {
    if (displayData) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1);
    }
  }, [displayData]);

  return (
    <div>
      <Text type="secondary">Thêm mới trò chơi</Text>
      <Row justify={"space-between"}>
        <Col style={{ width: "calc(100vw - 488px)" }}>
          <Card bodyStyle={{ paddingTop: 0 }}>
            <Form
              form={form}
              onFinishFailed={() => {
                var newValid = { ...isValid };
                if (!form.getFieldValue("name") || !form.getFieldValue("customer_turn") || !form.getFieldValue("image")) {
                  newValid.general = true;
                }

                if (!form.getFieldValue(["background", "image_url"]) || !form.getFieldValue("palette")) {
                  newValid.display = true;
                }

                if (rewardList.length < 2) {
                  newValid.reward = true;
                }
                setIsValid(newValid);
              }}
              layout="vertical"
              onFinish={checkValidation}
            >
              <Tabs
                tabBarExtraContent={
                  <Space>
                    <Button>Thoát</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //   onClick={() => {
                      //     checkValidation();
                      //   }}
                    >
                      Lưu
                    </Button>
                  </Space>
                }
                defaultActiveKey="1"
                items={tabItems}
                onChange={onChangeTabs}
              />
            </Form>
          </Card>
        </Col>
        <Col style={{ width: 390 }}>{displayData && !isLoading && <WheelOfFortune data={displayData} />}</Col>
      </Row>
      {isAddTurnModalOpen && <AddTurnModal isModalOpen={isAddTurnModalOpen} setIsModalOpen={setIsAddTurnModalOpen} onChange={onChangeAddTurnList} />}
      {isAddRewardModalOpen && (
        <AddEditRewardModal isModalOpen={isAddRewardModalOpen} setIsModalOpen={setIsAddRewardModalOpen} value={rewardSelected} onChange={onChangeRewardList} />
      )}
    </div>
  );
}

export default AddEditLuckyWheel;
