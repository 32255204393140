import React, { Component } from "react";
import { Button, Spin, Row, Col, Form, Input, Checkbox, Typography, message } from "antd";
import api from "api";
import { PoweroffOutlined } from "@ant-design/icons";
import GoogleLogin from "react-google-login";
import { toNoUnicode } from "../../common/text.js";

const { Title } = Typography;

const styleButton = {
  height: "50px",
  width: "240px",
  backgroundColor: "#4285f4",
  color: "white",
  boxShadow: " 0 2px 4px 0 rgb(0 0 0 / 25%)",
  boxSizing: "border-box",
  transition: "background-color .218s,border-color .218s,box-shadow .218s",
};

const styleImg = {
  backgroundColor: "white",
  borderRadius: "1px",
  padding: 15,
  float: "left",
};

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emailtext: false,
      email: "",
      first_name: "",
      last_name: "",
      name: "",
      data: {
        password: "",
        repassword: "",
      },
      checkPassword: true,
      success: false,
      token: "",
      loadingBtn: false,
      auth: {},
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
  };

  onFinish = (values, e) => {
    values.first_name = values.first_name.trim();
    values.email = values.email.trim();
    values.password = values.password.trim();
    values.repassword = values.repassword.trim();
    values.last_name = values.last_name.trim();
    this.setState({ loadingBtn: true });
    api
      .post("signup/index", values)
      .then((res) => {
        this.setState({
          loading: false,
          success: true,
          token: res.token,
          loadingBtn: false,
        });
        message.success("Đăng ký thành công");
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({ loadingBtn: false });
      });
  };

  sendmail = () => {
    api
      .post("signup/sendemailagain", { token: this.state.token })
      .then((res) => {
        message.success("Gửi thành công");
      })
      .catch((error) => {
        message.error("Gửi không thành công");
      });
  };

  emailChange = () => {
    var data = this.form.current.getFieldsValue();
    if (data.email !== "" && data.email != null) this.setState({ emailtext: true });
    else this.setState({ emailtext: false });
  };

  checkPass = () => {
    var data = this.form.current.getFieldsValue();
    if (data.password !== data.repassword) {
      this.setState({
        checkPassword: false,
      });
    } else {
      this.setState({
        checkPassword: true,
      });
    }
  };

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onSuccess = (response) => {
    this.form.current.validateFields(["first_name", "name", "last_name", "password", "repassword"]).then((res) => {
      var data = this.form.current.getFieldsValue();
      data.email = response.profileObj.email;
      this.signupWithGGAcount(data);
    });
  };

  signupWithGGAcount = (data) => {
    api
      .post("signup/createshopwithgg", data)
      .then((res) => {
        message.success("Đăng ký thành công.");
      })
      .catch((err) => {
        message.error("Đăng ký không thành công");
      });
  };

  onChangeForm = (value) => {
    var data = toNoUnicode(value.toLowerCase());
    setTimeout(() => {
      this.form.current.setFieldsValue({ name: data });
    }, 50);
  };

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.loading} style={{ "margin-top": "30vh", margin: 20 }}>
          <Row>
            <Col
              xs={{ span: 0 }}
              lg={{ span: 12 }}
              sm={{ span: 0 }}
              md={{ span: 12 }}
              style={{ height: "100vh", "background-color": "#E4EBF7" }}
              className="background-left"
            >
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}></Row>
              <Row justify="center " align="middle" style={{ height: "80vh" }}>
                <img style={{ width: "100%", maxWidth: 500 }} src={"/assets/images/07.svg"} />
              </Row>
              <Row justify="space-around" align="middle" style={{ height: "10vh" }}></Row>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }} hidden={this.state.success}>
              <Row justify="center" align="middle" style={{ height: "50vh" }}>
                <div style={{ width: "85%", height: "300px" }}>
                  <Title align="center">Đăng ký</Title>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={this.onFinish}
                    autoComplete="off"
                    layout="vertical"
                    ref={this.form}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      validateTrigger={"onBlur"}
                      rules={[
                        {
                          required: true,
                          message: "Nhập vào email của bạn!",
                        },
                        {
                          type: "email",
                          message: "Email không hợp lệ!",
                        },
                      ]}
                      // rules={
                      //   !this.state.emailtext
                      //     ? [
                      //       {
                      //         required: true,
                      //         message: "Nhập vào email của bạn!",
                      //       },
                      //     ]
                      //     : [
                      //       {
                      //         type: "email",
                      //         message: "Email không hợp lệ!",
                      //       },
                      //     ]
                      // }
                    >
                      <Input
                        onChange={(e) => {
                          this.emailChange(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Row guiter={16}>
                      <Col span={12} style={{ paddingRight: 10 }}>
                        <Form.Item
                          label="Họ"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Nhập vào họ của bạn!",
                            },
                          ]}
                        >
                          <Input
                          // onChange={(e) => {
                          //   this.changeFirstName(e.target.value);
                          // }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} style={{ paddingLeft: 10 }}>
                        <Form.Item
                          label="Tên"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Nhập vào tên của bạn!",
                            },
                          ]}
                        >
                          <Input
                          // onChange={(e) => {
                          //   this.changeLastName(e.target.value);
                          // }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Mật khẩu"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Nhập vào mật khẩu!",
                        },
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (value.length >= 6 && value.length <= 50) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Mật khẩu không hợp lệ! Vui lòng nhập tối thiểu 6 kí tự và tối đa 50 kí tự!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Nhập lại mật khẩu"
                      name="repassword"
                      validateTrigger={"onBlur"}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa nhập lại mật khẩu!",
                        },
                        {
                          validator: (rule, value) => {
                            var data = this.form.current.getFieldsValue();
                            return new Promise((resolve, error) => {
                              if (data.password === value) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Nhập lại mất khẩu không khớp!",
                        },
                      ]}
                    >
                      <Input.Password
                        onChange={(e) => {
                          this.checkPass(e.target.value);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Tên công ty"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Nhập vào tên shop của bạn!",
                        },
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (value.length < 30) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Tên shop tối đa 30 kí tự!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          this.onChangeForm(e.target.value);
                        }}
                      />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button type="primary" style={{ width: "100%" }} loading={this.state.loadingBtn ? true : false} htmlType="submit">
                        Đăng ký
                      </Button>
                    </Form.Item>
                    <div>
                      <a href="/login" style={{ float: "right" }}>
                        Đăng nhập
                      </a>
                    </div>
                  </Form>
                  {/* <div style={{ display: "flex", paddingBottom: "0.875rem", alignItems: "center" }}>
                    <div style={{ width: "100%", backgroundColor: "#dbdbdb", height: 1, flex: 1 }}></div>
                    <span style={{ color: "#ccc", padding: "0 1rem", textTransform: "uppercase", fontSize: ".75rem" }}>Hoặc </span>
                    <div style={{ width: "100%", backgroundColor: "#dbdbdb", height: 1, flex: 1 }}></div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <GoogleLogin
                        //vvtoan
                        // clientId="758789878314-b0dd8tst3e0eqnf33uiht3tbtfsdf3ti.apps.googleusercontent.com"
                        //cubetech.test2
                        clientId="856917658103-cdnk43nh0u1ch76icsp51i9snkodpn0q.apps.googleusercontent.com"
                        buttonText={"Kết nối với Google"}
                        onSuccess={this.onSuccess}
                        cookiePolicy={"single_host_origin"}
                        render={(renderProps) => (
                          // <Button className=" loginBtnSmall--google" onClick={renderProps.onClick} style={{ height: "50px", width: "240px", }}>
                          <div style={{ display: "block" }} onClick={renderProps.onClick}>
                            <div style={styleButton}>
                              <div style={{ border: "1px solid transparent", height: "100%", width: "100%" }}>
                                <div style={styleImg}>
                                  <div style={{ width: "18px", height: "18px", color: "#fff" }}>
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" style={{ color: "#fff" }}>
                                      <g>
                                        <path
                                          fill="#EA4335"
                                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                        ></path>
                                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                        <path
                                          fill="#FBBC05"
                                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                        ></path>
                                        <path
                                          fill="#34A853"
                                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                        ></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div style={{ fontSize: 16, color: "#fff", display: "flex", height: "100%" }}>
                                  <div style={{ fontFamily: " Roboto,arial,sans-serif", letterSpacing: ".21px", margin: "auto" }}>
                                    {this.props.value && this.props.value.auth ? "Đã kết nối với " + this.props.value.auth.user : "Sign in with Google"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    <div style={{ float: "right", flex: 1 }} onClick={() => { }}>
                      <div style={{ ...styleButton, backgroundColor: "#1877f2", display: "flex" }}>
                        <div style={{ fontSize: 28, margin: "2px 20px 0 10px" }}>
                          <i className="fab fa-facebook"></i>
                        </div>
                        <div style={{ fontSize: 16, color: "#fff", display: "flex", height: "100%" }}>
                          <div style={{ fontFamily: " Roboto,arial,sans-serif", letterSpacing: ".21px", margin: "auto" }}>Sign in with Facebook</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Row>
            </Col>
            <Col span={12} hidden={!this.state.success}>
              <Row justify="center" align="middle" style={{ height: "60vh" }}>
                <div style={{ height: "300px", width: "85%", textAlign: "center", fontSize: 18 }}>
                  <Title align="center" style={{ marginBottom: 50 }}>
                    Đăng ký thành công
                  </Title>
                  <span>
                    Cảm ơn bạn đã đăng ký dịch vụ của chúng tôi! <br />
                    Kiểm tra email để kích hoạt tài khoản của bạn!
                  </span>
                  <br />
                  <br />
                  <p>
                    Bạn chưa nhận được email?
                    <Button onClick={() => this.sendmail()} type="link">
                      <span style={{ fontSize: 18 }}> Gửi lại email! </span>
                    </Button>
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
