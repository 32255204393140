import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Button, Form, Input, Select, Divider, Table, Tag, Switch, message, Dropdown, Menu, Modal } from "antd";
import { PlusOutlined, MoreOutlined, SmileOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "api";
import AntTable from "components/common/AntTable";
import ModalAddEditUser from "./ModalAddEditUser";

const { Title, Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

// const [form] = React.createRef();

function UserManagement() {
  const [dataTable, setDataTable] = useState([]);
  const [isLoadingTable, setLoadingTable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState();
  const [currentRecord, setCurrentRecord] = useState();

  const [filter, setFilter] = useState({
    role: { $ne: "" },
    search: { $ne: "" },
  });
  const [form] = Form.useForm();
  const refTable = React.createRef();

  const columns = [
    {
      title: "Mã nhân viên",
      dataIndex: "user_id",
      key: "user_id",
      sorter: true,
      align: "center",
    },
    {
      title: "Tên tài khoản",
      dataIndex: "user_name",
      key: "user_name",
      sorter: true,
      align: "center",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
      sorter: true,
      align: "center",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: true,
      align: "center",
    },
    {
      title: "Phân quyền",
      dataIndex: "role",
      key: "role",
      sorter: true,
      align: "center",
    },
    {
      title: "Kích hoạt tài khoản",
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      align: "center",
      render: (text, record, index) => {
        return (
          <Switch
            defaultChecked={text}
            onChange={(e) => {
              record.is_active = e;
              updateUser(record);
            }}
          />
        );
      },
    },
    {
      dataIndex: "user_id",
      key: "action",
      align: "center",
      render: (text, record, index) => {
        const items = [
          {
            label: "Chỉnh sửa",
            key: "1",
            onClick: () => {
              setMode("edit");
              setCurrentRecord(record);
              openModal();
            },
          },
          {
            label: "Xoá",
            key: "2",
            onClick: () => {
              showConfirm(record, "delete");
            },
          },
          {
            label: "Đặt lại mật khẩu",
            key: "3",
            onClick: () => {
              showConfirm(record, "resetpassword");
            },
          },
        ];
        return (
          <Dropdown
            trigger="click"
            menu={{
              items,
            }}
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Hàm kích hoạt/bỏ kích hoạt tài khoản
   * @param {*} dataPost Giá trị của 1 record trên table
   * @author NhatAnh
   */
  const updateUser = async (dataPost) => {
    delete dataPost._id;
    try {
      await api.post("master/user/save", dataPost).then((res) => {
        if (res) {
          refTable.current.reload();
          message.success(res.message);
        }
      });
    } catch (error) {
      refTable.current.reload();
      message.error(error.message);
    }
  };

  /**
   * Hàm xử lý khi thay đổi bộ lọc phân quyền hoặc search
   * @author NhatAnh
   */
  const handleChange = () => {
    var filterData = form.getFieldsValue();
    setFilter({
      role: filterData.role === "all" || filterData.role === undefined ? { $ne: "" } : filterData.role,
      search: filterData.search !== undefined && filterData.search !== "" ? { $regex: filterData.search, $options: "i" } : { $ne: "" },
    });
  };

  const showConfirm = (dataPost, type) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: type === "delete" ? "Xoá nhân viên" : "Đặt lại mật khẩu",
      content:
        type === "delete" ? "Bạn có chắc chắn muốn xoá nhân viên này?" : "Bạn có chắc chắn muốn đặt lại mật khẩu mặc định là 1234567890 cho nhân viên này?",
      onOk() {
        if (type === "delete") {
          dataPost.is_delete = true;
          updateUser(dataPost);
        } else {
          dataPost.password = "1234567890";
          updateUser(dataPost);
        }
      },
      okButtonProps: "red",
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    if (isModalOpen === false) {
      refTable.current.reload();
    }
  }, [isModalOpen, filter]);

  return (
    <div>
      <Title level={4}>Quản lý nhân viên</Title>
      <Row style={{ backgroundColor: "white" }}>
        <Col span={12} style={{ padding: 16 }}>
          <Form form={form} name="filter" layout="inline">
            <Form.Item name="role" style={{ width: "40%" }}>
              <Select
                defaultValue="all"
                style={{ width: "100%" }}
                onChange={handleChange}
                options={[
                  { value: "all", label: "-- Tất cả --" },
                  { value: "admin", label: "Administrator" },
                  { value: "sales", label: "Sales" },
                  { value: "accountant", label: "Kế Toán" },
                ]}
              />
            </Form.Item>
            <Form.Item name="search" style={{ width: "40%" }}>
              <Search placeholder="Tên tài khoản/SĐT" style={{ width: "100%" }} onSearch={handleChange} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12} style={{ padding: 16 }}>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => {
              setMode("add");
              setCurrentRecord(null);
              openModal();
            }}
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
        </Col>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
          <AntTable
            columns={columns}
            autoLoad={true}
            customSearch={filter}
            tableName={"tbUser"}
            ajax="master/user/list"
            ref={refTable}
            rowKey={(record) => record.id}
          ></AntTable>
        </Col>
      </Row>
      <ModalAddEditUser isModalOpen={isModalOpen} mode={mode} record={currentRecord} setIsModalOpen={setIsModalOpen}></ModalAddEditUser>
    </div>
  );
}

export default UserManagement;
