import React, { PureComponent } from "react";
import { Select } from "antd";
import { toNoUnicode } from "../../../common/text";
var $ = window.jQuery;
const Option = Select.Option;

class SelectNew extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }
  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  search(input, option) {
    var data = toNoUnicode(option.props.children.toLowerCase());
    var search = toNoUnicode(input.toLowerCase());
    return data.indexOf(search) >= 0;
  }


  render() {

    var data = this.props.options;
    var option = data.map(function (item, index) {
      var rx = Math.random();
      return (
        <Option key={item.value}>
          {item.label}
        </Option>
      );
    });

    var disabled = false;
    if (this.props.disabled) {
      disabled = this.props.disabled;
    }

    return (
      <Select
        style={{ width: "100%" }}
        onChange={this.onChange}
        value={this.props.value}
        showSearch
        disabled={disabled}
        placeholder={this.props.placeHolder ?? null}
        size={this.props.size ?? "medium"}
        filterOption={this.search}
      >
          {option}
      </Select>
    );
  }
}

export default SelectNew;
