import React, { Component } from "react";
import api from "api";
import { Input, Button, Progress, Checkbox, Row, Col, message, Modal, notification, Space, Spin, Result, Table } from "antd";

import { io } from "socket.io-client";
import { wss_url } from "config";
import { Record } from "immutable";

export default class SyncPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isSync: false,
      totaldown: 0,
      currentdown: 0,
      percentdown: 0,
      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      download: true,
      mode: "download",
      lstErr: [],
      totalErr: 0,
    };
  }

  componentDidMount = () => {
    var socket = io.connect(`${wss_url}/app_socket`, {
      reconnection: false,
    });
    console.log("path", `${wss_url}/chat_socket`);

    socket.on("connect", () => {
      socket.on("sync_order_sapo", (data) => {
        var percent = 100;
        if (data.total && data.total !== 0) var percent = Math.round((data.current / data.total) * 100);
        console.log("data.mode", data.mode);
        if (data.mode === "success") {
          this.setState({ percentsync: 100, percentdown: 100, isSync: true, success: true });
        } else {
          if (data.mode === "download") {
            this.setState({ currentdown: data.current, totaldown: data.total, percentdown: percent, mode: "download" });
          } else {
            var lstErr = this.state.lstErr;
            var total = this.state.totalErr;

            if (data.error) {
              total++;
              lstErr.push(data.error);
            }
            this.setState({ currentsync: data.current, totalsync: data.total, percentsync: percent, mode: "sync", lstErr: lstErr, totalErr: total });
            if (percent === 100) {
              if (this.state.lstErr > 0) {
                setTimeout(() => {
                  this.setState({ success: true });
                }, 1000);
              }
            }
          }
        }
      });
    });
    this.socket = socket;
  };

  open = (store) => {
    this.setState({
      visible: true,
      store: store,
      totaldown: 0,
      currentdown: 0,
      percentdown: 0,
      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      isSync: false,
      download: true,
      mode: "download",
      lstErr: [],
      totalErr: 0,
    });
    //  this.syncOrder(store);
  };

  renderErrorList = () => {
    var lst = [...this.state.lstErr];
    var columns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "error",
        dataIndex: "",
        key: "error",
        render: (index, record) => {
          return <div>{record.error.message}</div>;
        },
      },
    ];
    var pagination = {
      showTotal: () => {
        return <div style={{ marginRight: 30 }}>Không đồng bộ được {this.state.totalErr} đơn hàng</div>;
      },
    };
    return (
      <>
        {lst.length > 0 && (
          <div style={{ borderTop: "3px solid #fc0000" }}>
            <Table dataSource={lst} columns={columns} pagination={pagination} rowKey={"id"} />
          </div>
        )}
      </>
    );
  };

  syncOrder = (_) => {
    console.log("dfghsreg");
    var store = this.state.store;
    this.setState({ isSync: true });
    api.post("app/sapo/order/syncorder", { store: store, id: this.socket.id }).then((data) => {
      this.setState({ isSync: false });
      console.log("sync order", data);
    });
  };

  testdata = (_) => {
    var store = this.state.store;
    api.post("app/sapo/order/testio", { store: store, id: this.socket.id }).then((data) => {
      console.log("createtestdata", data);
    });
  };

  render() {
    console.log("this.state.success", this.state.success);
    return (
      <Modal
        title="Đồng bộ dữ liệu"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={
          this.state.success
            ? [
                <Button onClick={() => this.setState({ visible: false, type: "" })} type="primary">
                  Thoát
                </Button>,
              ]
            : [
                <Button onClick={this.syncOrder} loading={this.state.isSync} type="primary">
                  Đồng bộ
                </Button>,
              ]
        }
        width="50%"
      >
        <div>
          {this.state.success === false ? (
            <div>
              {this.state.mode === "download" ? (
                <div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <h2 style={{ margin: "auto" }}>Download dữ liệu</h2>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <span style={{ margin: "auto" }}>Bước 1/2</span>
                  </div>
                  <label>
                    Tiến độ download:&nbsp;{this.state.currentdown}/{this.state.totaldown}
                  </label>
                  <Progress percent={this.state.percentdown} />
                </div>
              ) : (
                <div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <h2 style={{ margin: "auto" }}>Đồng bộ dữ liệu</h2>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <span style={{ margin: "auto" }}>Bước 2/2</span>
                  </div>
                  <label>
                    Tiến độ đồng bộ:&nbsp;{this.state.currentsync}/{this.state.totalsync}
                  </label>
                  <Progress percent={this.state.percentsync} style={{ marginBottom: 10 }} />
                  {this.renderErrorList()}
                </div>
              )}
            </div>
          ) : (
            <Result status="success" title="Đồng bộ thành công" subTitle={this.state.currentsync + "/" + this.state.totalsync + " đơn hàng"} />
          )}
        </div>
        {/* <div>
                    Dang đồng bộ
                    <Space>

                        <Button onClick={this.testdata}>Test data</Button>

                        <Button onClick={this.syncOrder} loading={this.state.isSync} type="primary">Đồng bộ</Button>
                    </Space>
                </div> */}
      </Modal>
    );
  }
}
