import React from "react";
import { Row, Empty, Button, Space, List, Tabs, Popconfirm, message } from "antd";
import PopupAddVoucher from "./popup_add_voucher";
import { formatCurrency } from "components/common/format/number";

export default class ListPhoneCardPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "viettel",
    };
    this.popupAddVoucher = React.createRef();
  }
  onChange = (value) => {
    var data = { viettel: [], vina: [], mobile: [], vietnamobile: [] };
    if (this.props.value) data = this.props.value;
    var lstVoucher = value;
    lstVoucher = data[this.state.activeKey].concat(value);
    lstVoucher.forEach((item, index) => {
      lstVoucher.forEach((element, i) => {
        if (element.voucher_id === item.voucher_id && index !== i) {
          lstVoucher.splice(i, 1);
        }
      });
    });
    if (this.props.onChange) {
      data[this.state.activeKey] = lstVoucher;
      this.props.onChange(data);
    }
  };

  deletePhoneCard(item) {
    var lst = { viettel: [], vina: [], mobile: [], vietnamobile: [] };
    if (this.props.value) lst = this.props.value;
    var index = lst[this.state.activeKey].findIndex((x) => x === item);
    if (index > -1) {
      lst[this.state.activeKey].splice(index, 1);
    }
    if (this.props.onChange) {
      this.props.onChange(lst);
    }
  }

  tabChange = (data) => {
    this.setState({ activeKey: data });
  };

  render() {
    //  console.log("activekey", this.state.activeKey)
    var lst = { viettel: [], vina: [], mobile: [], vietnamobile: [] };
    if (this.props.value) lst = this.props.value;
    return (
      <div>
        <Tabs type="card" onChange={this.tabChange} activeKey={this.state.activeKey}>
          <Tabs.TabPane tab={`Viettel (${lst?.viettel.filter((x) => x.divided).length}/${lst.viettel.length})`} key="viettel"></Tabs.TabPane>
          <Tabs.TabPane tab={`Mobile (${lst?.mobile.filter((x) => x.divided).length}/${lst.mobile.length})`} key="mobile"></Tabs.TabPane>
          <Tabs.TabPane tab={`Vina (${lst?.vina.filter((x) => x.divided).length}/${lst.vina.length})`} key="vina"></Tabs.TabPane>
          <Tabs.TabPane tab={`Vietnamobile (${lst?.vietnamobile.filter((x) => x.divided).length}/${lst.vietnamobile.length})`} key="vietnamobile"></Tabs.TabPane>
        </Tabs>
        <Row style={{ display: "flex" }}>
          <span style={{ margin: "auto" }}>{`Có ${lst[this.state.activeKey].filter((x) => x.divided).length}/${lst[this.state.activeKey].length} thẻ đã phát`}</span>
        </Row>
        <Row style={{ width: "100%", display: "flex" }}>
          {lst[this.state.activeKey].length === 0 ? (
            <div style={{ margin: "auto" }}>
              <Empty />
            </div>
          ) : (
            <div style={{ width: "100%", maxHeight: 400, overflowY: "scroll" }}>
              <List
                style={{ background: "white", width: "100%" }}
                dataSource={lst[this.state.activeKey]}
                renderItem={(item) => {
                  return (
                    <List.Item key={item.voucher_id} style={{ fontSize: 15 }}>
                      <List.Item.Meta title={<div className="listTitle">{item.voucher_id}</div>} description={<div className="description">{item.divided ? "Đã được phát" : "Chưa được phát"}</div>} />

                      <Popconfirm
                        placement="top"
                        title={`Bạn có muốn xóa thẻ cào ${item.voucher_id} này không?`}
                        onConfirm={() => {
                          this.deletePhoneCard(item);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">Xóa</Button>
                      </Popconfirm>
                    </List.Item>
                  );
                }}
              ></List>
            </div>
          )}
        </Row>
        <Row style={{ width: "100%", display: "flex", marginTop: 20 }}>
          <div style={{ margin: "auto" }}>
            <Space>
              {lst[this.state.activeKey].length === 0 ? (
                <Button
                  onClick={() => {
                    message.error("Bạn chưa thêm thẻ cào");
                  }}
                >
                  {" "}
                  Xóa toàn bộ
                </Button>
              ) : (
                <Popconfirm
                  placement="top"
                  title={`Bạn có muốn xóa ${lst[this.state.activeKey].length} thẻ điện thoại này không??`}
                  onConfirm={() => {
                    var lst = { viettel: [], vina: [], mobile: [], vietnamobile: [] };
                    if (this.props.value) lst = this.props.value;
                    lst[this.state.activeKey] = [];
                    if (this.props.onChange) {
                      this.props.onChange(lst);
                    }
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button> Xóa toàn bộ</Button>
                </Popconfirm>
              )}

              <Button type="primary" onClick={() => this.popupAddVoucher.current.open()}>
                Thêm thẻ cào
              </Button>
            </Space>
          </div>
        </Row>
        <PopupAddVoucher ref={this.popupAddVoucher} onChange={this.onChange} card={true}></PopupAddVoucher>
      </div>
    );
  }
}
