import React, { Component } from "react";
import api from "api";
import PopupQrPrint from "./popup_print";
import AntTable from "components/common/AntTable";
import SearchBox from "components/common/SearchBox/SearchBox";
import { Input, Button, Select, Popconfirm, Col, message, Checkbox, DatePicker, Card, Space } from "antd";

import moment from "moment";
import { formatL } from "../../common/date_time";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];

export default class Orders extends Component {
  constructor(props) {
    super(props);
    this.selected = "";
    this.id = "";
    this.state = {
      listOrder: [],
      filter: {
        query: {
          $and: [{}],
        },
      },
      selected: [],
      id: {},
    };

    this.Tb = React.createRef();
    this.PopupQrPrint = React.createRef();
  }
  componentDidMount = () => {};

  handleStatusChange = (name, value) => {
    //value = value.toLowerCase();
    var filter = {};
    filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value === "" || value === "all") {
      if (index > -1) {
        filter.query.$and.splice(index, 1);
      }
    } else {
      if (index > -1) {
        filter.query.$and[index][name] = value;
      } else {
        filter.query.$and.push({ [name]: value });
      }
    }

    this.Tb.current.reload(false);

    this.setState({ filter: filter });
  };
  checkBoxControl = (name, affectName, check) => {
    var state = { ...this.state };
    state[name] = check;
    if (!check) {
      var array = state.filter.query.$and.filter((x) => !x[affectName]);
      state.filter.query.$and = array;
    } else {
      var array = state.filter.query.$and.filter((x) => !x[affectName]);
      state.filter.query.$and = array;
      var obj = {};
      state.filter.query.$and.push(obj);
    }
    state[affectName] = null;
    this.setState(state, () => {
      this.Tb.current.reload();
    });
  };

  onRangePickerChange = (name, value) => {
    var state = { ...this.state };
    var array = state.filter.query.$and.filter((x) => !x[name]);
    var obj = {};
    obj[name] = { $gte: value[0], $lte: value[1] };
    array.push(obj);
    state.filter.query.$and = array;
    state[name] = value;
    this.setState(state, () => {
      this.Tb.current.reload();
    });
  };
  onFilterChange = (name, value) => {
    var filter = {};
    filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value == "") {
      if (index > -1) filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name].$regex = value;
      } else {
        filter.query.$and.push({ [name]: { $regex: value } });
      }
    }
    this.setState({ filter: filter }, () => {
      this.Tb.current.reload();
    });
  };
  getFilters = () => {
    var filter = [
      {
        Key: "source_name",
        Name: "Nguồn tạo",
        Component: (
          <Col span={6}>
            <div>
              <label>Nguồn tạo</label>
              <div style={{ display: "flex" }}>
                <Select
                  defaultValue="all"
                  style={{ width: "80%" }}
                  onChange={(value) => {
                    this.handleStatusChange("source_name", value);
                  }}
                >
                  <Option value="all">Tất Cả</Option>
                  <Option value="FB">FACEBOOK</Option>
                  <Option value="CHATWEB">CHATWEB</Option>
                  <Option value="LADINGPAGE"> LANDINGPAGE</Option>
                  <Option value="SP">SHOPEE</Option>
                </Select>
              </div>
            </div>
          </Col>
        ),
      },
      {
        Key: "financial_status",
        Name: "Trạng thái",
        Component: (
          <Col span={6}>
            <div>
              <label>Trạng thái</label>
              <div style={{ display: "flex" }}>
                <Select
                  defaultValue="all"
                  style={{ width: "80%" }}
                  onChange={(value) => {
                    this.handleStatusChange("financial_status", value);
                  }}
                >
                  <Option value="all">Tất Cả</Option>
                  <Option value="paid">Đã thanh toán</Option>
                  <Option value="pending">Đang chờ</Option>
                  <Option value="cancelled"> Đã huỷ</Option>
                </Select>
              </div>
            </div>
          </Col>
        ),
      },
      {
        Key: "create_date",
        Name: "Ngày tạo",
        Component: (
          <Col span={6}>
            <div style={{ display: "inline-block" }}>
              <label>Ngày tạo</label>
              <div style={{ display: "flex", marginRight: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: "5px 8px",
                    border: "1px solid #dfdfdf",
                  }}
                >
                  <Checkbox
                    style={{ margin: "auto" }}
                    onChange={(e) => {
                      this.checkBoxControl("isCreateDate", "created_on", e.target.checked);
                    }}
                    value={this.state.isCreateDate}
                  ></Checkbox>
                </div>

                <RangePicker
                  value={this.state.create_date}
                  disabled={this.state.isCreateDate === false ? [true, true] : [false, false]}
                  style={{ width: "100%" }}
                  onChange={(e) => this.onRangePickerChange("created_on", e)}
                  format={dateFormat}
                />
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return filter;
  };

  getColumn = () => {
    var columns = [
      {
        title: "Mã",
        dataIndex: "id",
        key: "id",
        sorter: true,
        render: (text, record, index) => {
          return (
            <div>
              <Space>
                {record.qr_status[0] === "success" && <i className="far fa-check-circle" style={{ color: "#4CAF50" }}></i>}

                {record.qr_status[0] === "cancel" && <i className="fas fa-ban" style={{ color: "#FF5252" }}></i>}

                {(record.qr_status[0] === "new" || record.qr_status.length === 0) && <i className="far fa-clock" style={{ color: "#2196F3" }}></i>}

                {record.id ?? ""}
              </Space>
              <div></div>
            </div>
          );
        },
      },
      {
        title: "Ngày tạo",
        dataIndex: "created_on",
        key: "created_on",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.created_on);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },
      {
        title: "Nguồn tạo",
        dataIndex: "source_name",
        key: "source_name",
        render: (text, record, index) => {
          return <div>{record.source_name}</div>;
        },
      },

      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        sorter: false,
        render: (text, record, index) => {
          return <div>{record.financial_status} </div>;
        },
      },
      {
        title: "Tổng giá",
        dataIndex: "total_price",
        key: "total_price",
        sorter: true,
        render: (text, record, index) => {
          return <div>{record.total_price} </div>;
        },
      },

      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Popconfirm title="Bạn có chắc muốn hoàn tác không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                <Button type="link">
                  <i className="far fa-trash-alt"></i>
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    var filters = this.getFilters();
    return (
      <>
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: 20,
            border: "1px solid #dfdfdf",
            marginBottom: 30,
          }}
        >
          <div>
            <SearchBox
              name="orders"
              data={filters}
              onSelectFilterChange={this.onSelectFilterChange}
              placeholder="Thông tin"
              dataButton={[
                <Button
                  type="primary"
                  onClick={() => {
                    this.PopupQrPrint.current.open(this.id);
                  }}
                  style={{ marginRight: 15 }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Tích điểm
                </Button>,
              ]}
              onSearch={() => this.Tb.current.reload()}
              onChange={(value) => {
                this.onFilterChange("id", value);
              }}
            />
          </div>
        </div>
        <div>
          <Card>
            <AntTable
              // rowSelection={{
              //   type: "checkbox",
              //   ...this.rowSelection,
              // }}
              columns={this.getColumn()}
              customSearch={this.state.filter}
              tableName={"tbOrders"}
              ajax="order/list"
              ref={this.Tb}
              rowKey={(record) => record.id}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  this.selected = selectedRows;

                  this.id = selectedRows.map((item) => ({ id: item.id, id_point: item.id_point }));
                },
                getCheckboxProps: (record) => ({
                  disabled: record?.qr_status[0] !== "new" && record?.qr_status.length !== 0,
                  name: record.id,
                }),
              }}
            ></AntTable>
          </Card>
        </div>
        <PopupQrPrint
          ref={this.PopupQrPrint}
          onSaved={(e) => {
            this.tbl.current.reload();
          }}
        />
      </>
    );
  }
}
