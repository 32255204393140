import { Badge, Button, Card, Col, Row, Space, Tabs, Tag, Typography } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import ConnectTab from "./ConnectTab";
import ProductTab from "./ProductTab";
import "./styles.css";
import api from "api";

const { Text, Title } = Typography;
function MShopKeeperConfig() {
  const [isConnected, setIsConnected] = useState(false);
  const [totalProduct, setTotalProduct] = useState();
  const [isActive, setIsActive] = useState(true);
  const [tabItems, setTabItems] = useState([
    {
      key: "connect",
      label: "Kết nối",
      children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
    },
  ]);

  useEffect(() => {
    console.log(isConnected);
    if (isConnected) {
      getTotalWooProduct();
    } else {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        },
      ]);
    }
  }, [isConnected]);

  const getTotalWooProduct = () => {
    api
      .post("app/mshopkeeper/config/listallproducts", { pagging: { skip: 0, limit: 1, sort: {} } })
      .then((res) => {
        if (res) {
          setTotalProduct(res.total);
        }
      })
      .catch((err) => {
        setTotalProduct(0);
        console.log(err);
        if (err.code === 205) {
          setIsActive(false);
        }
      });
  };

  useEffect(() => {
    console.log(isActive);
    if (isActive) {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        },
        {
          key: "mshopkeeper_products",
          label: (
            <Space>
              <Text>Sản phẩm</Text>
              <Badge count={totalProduct} color="#E6F7FF" style={{ color: "blue" }} />
            </Space>
          ),
          forceRender: true,
          children: <ProductTab totalProduct={totalProduct} setTotalProduct={setTotalProduct} />,
        },
      ]);
    } else {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        },
      ]);
    }
  }, [isActive]);

  useEffect(() => {
    if (totalProduct) {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        },
        {
          key: "mshopkeeper_products",
          label: (
            <Space>
              <Text>Sản phẩm</Text>
              <Badge count={totalProduct} color="#E6F7FF" style={{ color: "blue" }} />
            </Space>
          ),
          forceRender: true,
          children: <ProductTab totalProduct={totalProduct} setTotalProduct={setTotalProduct} />,
        },
      ]);
    }
  }, [totalProduct]);

  return (
    <Card
      title={
        <Row gutter={16} style={{ padding: "16px 0px" }}>
          <Col>
            <div
              style={{
                width: 223,
                height: 48,
                backgroundImage: `url(/image/mshopkeeper.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </Col>
          <Col>
            <Row>
              <Title style={{ margin: 0 }} level={5}>
                MISA ESHOP
              </Title>
            </Row>
            <Row>
              <Text type="secondary">Đồng bộ sản phẩm, đồng bộ đơn hàng</Text>
            </Row>
          </Col>
        </Row>
      }
      //   extra={<a href="#">More</a>}
      style={{
        width: "100%",
        minHeight: "calc(100vh - 118px)",
      }}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Card>
  );
}

export default MShopKeeperConfig;
