var ProductExtendOperator = {
    product_already_buy: {
        label: "Đã Mua",
        labelForFormat: "product_already_buy",
        cardinality: 1,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => ({
            "$expr": {
                "$gt": [
                    {
                        "$size": {
                            "$filter": {
                                "input": { "$ifNull": ["$orders", []] },
                                "as": "el",
                                "cond": {
                                    "$gt": [
                                        {
                                            "$size": {
                                                "$filter": {
                                                    "input": "$$el.line_items",
                                                    "as": "el",
                                                    "cond": {
                                                        "$in": [
                                                            "$$el.product_id",
                                                            value
                                                        ]
                                                    }
                                                }
                                            }
                                        },
                                        0
                                    ]
                                }
                            }
                        }
                    },
                    0
                ]
            }
        }),
    },
    product_not_buy_yet: {
        label: "Chưa Mua",
        labelForFormat: "product_not_buy_yet",
        cardinality: 1,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => ({
            "$expr": {
                "$eq": [
                    {
                        "$size": {
                            "$filter": {
                                "input": { "$ifNull": ["$orders", []] },
                                "as": "el",
                                "cond": {
                                    "$gt": [
                                        {
                                            "$size": {
                                                "$filter": {
                                                    "input": "$$el.line_items",
                                                    "as": "el",
                                                    "cond": {
                                                        "$in": [
                                                            "$$el.product_id",
                                                            value
                                                        ]
                                                    }
                                                }
                                            }
                                        },
                                        0
                                    ]
                                }
                            }
                        }
                    },
                    0
                ]
            }
        }),
    },
    product_buy_period: {
        label: "Thời Gian Mua",
        labelForFormat: "product_buy_period",
        label: "Trong khoảng",
        labelForFormat: "BETWEEN",
        sqlOp: "BETWEEN",
        cardinality: 2,
        mongoFormatOp: (field, op, values, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
 
       

            return {
                "$expr": {
                    "$gt": [
                        {
                            "$size": {
                                "$filter": {
                                    "input": { "$ifNull": ["$orders", []] },
                                    "as": "el",
                                    "cond": {
                                        "$and": [
                                            {"$eq":["$$el.financial_status","paid"]},

                                            {
                                                "$gte": [
                                                    "$$el.created_on_date",
                                                    values[0].ISODate.dateString
                                                ]
                                            },
                                            {
                                                "$lte": [
                                                    "$$el.created_on_date",
                                                    values[1].ISODate.dateString
                                                ]
                                            }
                                        ]
                                    }
                                }

                            }
                        },
                        0
                    ]
                }
            }

        },
        //  mongoFormatOp: mongoFormatOp2.bind(null, ["$gte", "$lte"], false),
        validateValues: (values) => {
            if (values[0] != undefined && values[1] != undefined) {
                return values[0] <= values[1] ? null : "Invalid range";
            }
            return null;
        }
    }
}

export default ProductExtendOperator;