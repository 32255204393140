import React, { PureComponent } from "react";
import { Select } from "antd";
import { toNoUnicode } from "../../../common/text";
var $ = window.jQuery;
const Option = Select.Option;

class SelectNew extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }
  componentDidMount() {
    var valCol = this.getCol().valCol;
    if (this.props.options != null && this.props.options.length != 0)
      this.onChange(this.props.options[0][valCol])
  }
  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  getCol() {    
    var displayCol = "label";
    var valCol = "value";

    if (this.props.displayCol != null && this.props.valCol != null) {
      displayCol = this.props.displayCol;
      valCol = this.props.valCol
    }
    return {displayCol, valCol}
  }

  search(input, option) {
    var data = toNoUnicode(option.props.children.toLowerCase());
    var search = toNoUnicode(input.toLowerCase());
    return data.indexOf(search) >= 0;
  }


  render() {

    var data = this.props.options;
    var cols = this.getCol();
    var displayCol = cols.displayCol;
    var valCol = cols.valCol;

    var option = data.map(function (item, index) {
      var rx = Math.random();
      return (
        <Option key={item[valCol]}>
          {item[displayCol]}
        </Option>
      );
    });
    var disabled = false;
    if (this.props.disabled) {
      disabled = this.props.disabled;
    }

    return (
      <Select
        style={{ width: "100%" }}
        onChange={this.onChange}
        value={this.props.value}
        showSearch
        disabled={disabled}
        placeholder={this.props.placeHolder ?? null}
        size={this.props.size ?? "medium"}
        filterOption={this.search}
      >
        {option}
      </Select>
    );
  }
}

export default SelectNew;
