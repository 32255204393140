import React, { Component } from "react";

import { Card, Col, Row, Space, Menu } from "antd";
import WooAppConfig from "../config";
import Orders from "../orders";

export default class WooMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { config: "shop" };
  }
  render() {
    var config = this.state.config;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Card>
              <Menu>
                <Menu.Item onClick={() => this.setState({ config: "shop" })}>Cấu hình shop</Menu.Item>
                <Menu.Item onClick={() => this.setState({ config: "order" })}>Danh sách đơn hàng</Menu.Item>
              </Menu>
            </Card>
          </Col>
          <Col span={20}>
            <Card>
              {config === "shop" && <WooAppConfig />}
              {config === "order" && <Orders />}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
