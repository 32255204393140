var Formular = {
    formular: {
        type: "text",
        label: "Công thức",
        labelForFormat: "formular",
        cardinality: 1,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => (JSON.parse(value) ),
        cardinality: 1,

    },

    



}

export default Formular;