import { Badge, Button, DatePicker, Form, Input, Row, Select, Space, Typography } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import * as dayjs from "dayjs";
import ModalResponse from "./ModalResponse";
import RerunWebhookModal from "./RerunWebhookModal";

const { Text, Title } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;

function WebhookHistory() {
  const [form] = Form.useForm();
  const refTable = createRef();
  const [dataPost, setDataPost] = useState({});
  const [response, setResponse] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalRerunOpen, setIsModalRerunOpen] = useState(false);
  const [modalMode, setModalMode] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  var columns = [
    {
      title: "Thời gian",
      dataIndex: "created_time",
      key: "created_time",
      render: (text, record, index) => {
        return moment(text).format("YYYY/MM/DD HH:mm");
      },
    },
    {
      title: "Mã đơn",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Loại hook",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        switch (text) {
          case "trustsales":
            return "TRUSTSALES";
          case "trustsales_sandbox":
            return "TRUSTSALES SANDBOX";
          case "mshopkeeper":
            return "MISA eSHOP";
          case "webhook_url":
            return "WEBHOOK URL";
          default:
            break;
        }
      },
    },
    {
      title: "Sự kiện",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",

      render: (text, record, index) => {
        if (text === "success") {
          return <Badge color="green" text="Thành công" />;
        } else {
          return <Badge color="red" text="Thất bại" />;
        }
      },
    },
    {
      dataIndex: "order_id",
      key: "order_id",
      // sorter: true,
      width: "150px",
      render: (text, record, index) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                setSelectedRecord(record);
                setModalMode("run");
                setIsModalRerunOpen(true);
              }}
            >
              Chạy lại
            </Button>
            <Button
              type="link"
              onClick={() => {
                setResponse(record.response);
                setIsModalOpen(true);
              }}
            >
              Response
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = () => {
    var newDataPost = form.getFieldsValue();
    console.log(newDataPost);
    if (newDataPost.created_time && newDataPost.created_time.length > 0) {
      newDataPost.created_time = newDataPost.created_time.map((item, index) => {
        if (index === 0) {
          //   console.log(new Date(moment(dayjs(item).format("MM/DD/YYYY")).startOf("day")));
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").toISOString();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").toISOString();
        }
      });
    }
    setDataPost(newDataPost);
  };

  useEffect(() => {
    refTable.current.reload();
  }, [dataPost, isModalRerunOpen]);

  return (
    <div>
      <Title level={3}>Lịch sử webhook</Title>
      <Form style={{ background: "white", padding: 8 }} layout="inline" form={form}>
        <Form.Item style={{ width: "calc((100% - 48px)/4)" }} name="created_time">
          <RangePicker style={{ width: "100%" }} onChange={onSearch} placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}></RangePicker>
        </Form.Item>
        <Form.Item name={"type"} style={{ width: "calc((100% - 48px)/4)" }}>
          <Select
            onChange={onSearch}
            placeholder="Loại hook"
            mode="multiple"
            options={[
              {
                value: "trustsales",
                label: "TRUSTSALES",
              },
              {
                value: "trustsales_sandbox",
                label: "TRUSTSALES SANDBOX",
              },
              {
                value: "webhook_url",
                label: "WEBHOOK URL",
              },
              {
                value: "mshopkeeper",
                label: "MISA eSHOP",
              },
            ]}
          />
        </Form.Item>
        <Form.Item name={"status"} style={{ width: "calc((100% - 48px)/4)" }}>
          <Select
            onChange={onSearch}
            placeholder="Trạng thái"
            options={[
              { value: "all", label: "Tất cả" },
              {
                value: "success",
                label: "Thành công",
              },
              {
                value: "error",
                label: "Thất bại",
              },
            ]}
          />
        </Form.Item>
        <Form.Item style={{ width: "calc((100% - 48px)/4)", marginInline: 0 }} name="order_id">
          <Search
            placeholder="Tìm mã đơn"
            onSearch={onSearch}
            // style={{
            //   width: 200,
            // }}
          />
        </Form.Item>
      </Form>
      <Row style={{ background: "white", width: "100%", justifyContent: "flex-end", padding: 8 }}>
        <Button
          onClick={() => {
            setIsModalRerunOpen(true);
            setModalMode("pre_run");
          }}
        >
          Chạy lại webhook
        </Button>
      </Row>
      <AntTable
        columns={columns}
        customSearch={dataPost}
        tableName={"tbWebhookHistory"}
        ajax="webhook_log/list"
        ref={refTable}
        rowKey={(record) => record.id}
      ></AntTable>
      {isModalOpen && <ModalResponse isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} response={response} />}
      {isModalRerunOpen && <RerunWebhookModal isModalOpen={isModalRerunOpen} setIsModalOpen={setIsModalRerunOpen} mode={modalMode} data={selectedRecord} />}
    </div>
  );
}

export default WebhookHistory;
