import React, { Component } from "react";
import {
  Card,
  Switch,
  Form,
  Checkbox,
  Button,
  message,
  Input,
  Select,
  Space,
  Radio,
  InputNumber,
} from "antd";
import api from "api";
// import ContentPicker from "../../../picker/content_picker";
import ContentPicker from "components/picker/content_picker";
import VoucherPicker from "components/marketing/exchange_point_program/config/picker/voucher_picker";
import FBShareProgram from "components/marketing/config/fbshareprogram";

const { Option } = Select;

export default class RecurringNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstMed: [
        { label: "Khi bắt đầu", value: "ON_START" },
        { label: "Sau tin nhắn cuối cùng", value: "AFTER_LAST_MESSAGE" },
        { label: "Sau khi thêm thẻ", value: "AFTER_ADD_TAG" },
      ],
      auto: false,
      craft: false,
      linkfb: "Link cho người dùng",
      lstPage: [],
      frequency: "DAILY",
      options: null,
      showAfterMessage: false,
      showAfterAddTag: false,
    };
    //    this.formNotification = React.createRef();
    this.ContentPicker = React.createRef();
    this.fromRecurringSetting = React.createRef();
  }
  componentDidMount = () => {};
  setFieldsValue = (values) => {
    this.setState({ auto: values.noti.auto });
    this.setState({ recurring_setting: values });
    this.fromRecurringSetting.current.setFieldsValue(values);
    this.onChangeMethod(values.noti.method);
  };

  onNotiFinish = (values) => {
    console.log("values", values);
    var validated = this.checkMessageValidate(values.daily.content.chatbot.message);
    console.log("validated", validated)
    if(validated){
    values.noti.method = ["AFTER_LAST_MESSAGE"];
    values.noti.frequency = "DAILY";
    api
      .post("app/fb/config/savenoti", values)
      .then((data) => {
        message.success("Dữ liệu của bạn đã được lưu");
      })
      .catch((err) => {
        message.error(`Lỗi. Vui lòng thử lại. ${err.message ?? ""}`);
      });
    }
  };
  checkMessageValidate = (message) => {

    if(message.text.trim() != ""){
      this.setState({messageErr : false})
      return true;
    }
    else{
      this.setState({messageErr : true})
      return false;
    }
    
  }
  onChangeMethod = (e) => {
    e.includes("AFTER_LAST_MESSAGE")
      ? this.setState({ showAfterMessage: true })
      : this.setState({ showAfterMessage: false });
    e.includes("AFTER_ADD_TAG")
      ? this.setState({ showAfterAddTag: true })
      : this.setState({ showAfterAddTag: false });
  };

  render() {
    return (
      <div>
        <Form onFinish={this.onNotiFinish} ref={this.fromRecurringSetting}>
          <Card
            title="Thông báo định kỳ"
            extra={
              <Form.Item name="enable" noStyle valuePropName="checked">
                <Switch onChange = {(e) => {this.setState({enable : e.target.value})}}></Switch>
              </Form.Item>
            }
          >
            {/* <div style={{ marginLeft: 15 }} hidden={true}>
              <Form.Item name="frequency"
                label= "Tần suất gửi lại"
             //   rules= {[{required : true, message : "Bạn phải chọn tần suất gửi lại!" }]}
              >
                <Radio.Group name="frequency" defaultValue={this.state.frequency}>
                  <Radio value="DAILY">Hàng Ngày</Radio>
                  <Radio value="WEEKLY">Hàng Tuần</Radio>
                  <Radio value="MONTHLY">Hàng Tháng</Radio>
                </Radio.Group>
              </Form.Item>
            </div> */}
            <div style={{ marginLeft: 15, display: "flex" }}>
              <Form.Item name="job_again_id" hidden></Form.Item>
              <Form.Item
                name={["noti", "auto"]}
                label="Gửi yêu cầu tự động"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={() => {
                    this.setState({ auto: !this.state.auto });
                  }}
                ></Checkbox>
              </Form.Item>
              {/* <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.noti !== currentValues.noti}>
                {({ getFieldValue }) => {
                  var craft = getFieldValue(["noti", "craft"]);
                  return (
                    
                  );
                }}
              </Form.Item>
              <Form.Item style={{ marginLeft: 50 }} name={["noti", "craft"]} label="Yêu cầu thủ công" valuePropName="checked">
                <Checkbox onChange={(e) =>{this.setState({craft : !this.state.craft})}}></Checkbox>
              </Form.Item> */}
            </div>
            {/* <div style={{ marginLeft: 15,marginBottom:10, display: "flex" }} hidden={!this.state.craft}>
                  <Input.Group compact>
                    <Select style = {{width : 250 }} defaultValue="Các page đã liên kết"
                      onChange = {(e) => this.onPageChange(e)}
                      options={this.state.options}
                      filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
        
                    </Select>
                    <Input style={{ width: '50%', marginLeft:15 }} value={this.state.linkfb} />
                    <Button type="primary" onClick={() => {navigator.clipboard.writeText(this.state.linkfb);message.success("Đã copy link")}}>Copy Link</Button>
                  </Input.Group>
            </div> */}

            {/* <div style={{ marginLeft: 15 }}>
              <Form.Item name={["noti", "method"]} label="Phương thức gửi tự động:" hidden={!this.state.auto}>
                <Select style={{ width: "100%" }} mode="multiple" allowClear={true} onChange={(e) => this.onChangeMethod(e)}>
                  {this.state.lstMed.map((item) => {
                    return (
                      <Option value={item.value} label={item.label}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div> */}
            <div style={{ marginLeft: 15 }}>
              <Form.Item
                // hidden={!this.state.showAfterMessage || !this.state.auto}
                hidden={!this.state.auto}
                label="Sau tin nhắn cuối cùng (phút)"
                name={["noti", "mess_config", "after_message_minutes"]}
                rules={
                  this.state.showAfterMessage == true && [
                    {
                      required: true,
                      message:
                        "Bạn phải chọn thời gian sau khi nhận tin nhắn cuối cùng!",
                    },
                    {
                      pattern: /^\d+$/,
                      message: `Thời gian không đúng định dạng`,
                    },
                  ]
                }
              >
                <Input style={{ width: "30%" }} onChange={this.onChange} />
              </Form.Item>
            </div>
            {/* <div style={{ marginLeft: 15, display: "flex" }}>
              <Form.Item
                hidden={!this.state.showAfterAddTag || !this.state.auto}
                label="Sau khi thêm thẻ (phút)"
                name={["noti", "tag_config", "after_add_tag_minutes"]}
                rules={this.state.showAfterAddTag == true && [{ required: true, message: "Bạn phải chọn thời gian sau khi thêm thẻ!" }]}
              >
                <Input style={{ width: "40%" }} />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: 50 }}
                hidden={!this.state.showAfterAddTag || !this.state.auto}
                label="Thẻ"
                name={["noti", "tag_config", "tag"]}
                rules={this.state.showAfterAddTag == true && [{ required: true, message: "Bạn phải chọn thẻ!" }]}
              >
                <Input style={{ width: "70%" }} />
              </Form.Item>
            </div> */}
            <Form.Item name={["daily", "content"]} label="Tin nhắn mời" 
            validateStatus = {this.state.messageErr ? "error" : ""}
            help = {this.state.messageErr ? "Bạn cần nhập nội dung tin nhắn" : ""}
            rules={
              this.state.enable
                && [
                { required: true, message: "Bạn cần nhập nội dung tin nhắn!" },
              ]
            }>
              <ContentPicker
                style={{ paddingTop: "10px" }}
                wayToSend={[]}
                hideSms
                hideEmail
              ></ContentPicker>
            </Form.Item>
            <Form.Item
              name={["daily", "reward", "type"]}
              label="Phần thưởng"
              style={{ marginLeft: 15 }}
            >
              <Radio.Group
                onChange={(e) => {
                  var recurring_setting = { ...this.state.recurring_setting };
                  recurring_setting?.daily?.reward?.type
                    ? (recurring_setting.daily.reward.type = e.target.value)
                    : (recurring_setting.daily = {
                        reward: {
                          type: e.target.type,
                        },
                      });
                  this.setState({ recurring_setting: recurring_setting });
                }}
              >
                <Radio value={"none"}>Không có</Radio>
                <Radio value={"point"}>Tích điểm</Radio>
                <Radio value={"voucher"}>Voucher</Radio>
              </Radio.Group>
            </Form.Item>
            <div style={{ marginLeft: 15 }} >
              <Form.Item
                name={["daily", "reward", "points"]}
                label="Điểm"
                hidden={
                  this.state.recurring_setting?.daily?.reward?.type != "point"
                }
                rules={
                  this.state.recurring_setting?.daily?.reward?.type ==
                    "point" && [
                    { required: true, message: "Bạn cần nhập số điểm!" },
                  ]
                }
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                name={["daily", "reward", "voucher"]}
                label="Voucher"
                hidden={
                  this.state.recurring_setting?.daily?.reward?.type != "voucher"
                }
                rules={
                  this.state.recurring_setting?.daily?.reward?.type ==
                    "voucher" && [
                    { required: true, message: "Bạn cần nhập voucher!" },
                  ]
                }
              >
                <VoucherPicker type="all"></VoucherPicker>
              </Form.Item>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              }}
            >
              <Form.Item noStyle>
                <Button htmlType="submit" type="primary">
                  Lưu
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Form>
      </div>
    );
  }
}
