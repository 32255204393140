import React, { Component } from "react";
import { Button, message, Modal, Form, Input, Select, Radio } from "antd";
import api from "api";
import MyInputNumber from "components/picker/inputnumber_picker";
import ColorPicker from "components/picker/color_picker";
import { makeid } from "common/custom_field";
import { toNoUnicode } from "common/text";
import ListVoucherPicker from "../../exchange_point_program/config/picker/list_voucher_picker";

const { TextArea } = Input;
const { Option } = Select;

export default class PopupReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      visible: false,
      lstCRM: [],
    };
    this.form = React.createRef();
  }

  componentDidMount = async () => {
    var lstcrm = await api.post("shop/getinstalledappbycategories", { categories: ["crm"] });
    this.setState({ lstCRM: lstcrm, load: true });
  };

  open(data) {
    if (data.id === "" || data.id == null) {
      this.setState({ visible: true }, () => {
        this.form.current.resetFields();
      });
    } else {
      this.setState({ visible: true }, () => {
        this.form.current.resetFields();
        this.form.current.setFieldsValue(data);
      });
    }
  }

  saveReward = (values) => {
    if (values.id == null || values.id === "") {
      values.id = makeid(5);
    }
    if (this.props.addReward) {
      this.props.addReward({ ...this.props.dataTable.filter((i) => i.id === values.id)[0], ...values });
      this.setState({ visible: false });
    }
  };

  onPrefixChange = (value, name) => {
    var data = this.form.current.getFieldValue("voucher");
    data.voucher_prefix = toNoUnicode(value.toUpperCase());
    this.form.current.setFieldsValue({ voucher: data });
  };

  render() {
    return (
      <>
        <Modal
          title="Phần thưởng"
          open={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          width="50%"
          footer={[
            <Button
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              Thoát
            </Button>,

            <Button
              type="primary"
              onClick={() => {
                this.form.current.submit();
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form name="reward" ref={this.form} layout="vertical" onFinish={this.saveReward}>
            <Form.Item name="id" hidden>
              <Input></Input>
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
              <Form.Item
                style={{ width: "49%" }}
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập tên!",
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item label="Loại phần thưởng" name={"type"} rules={[{ required: true, message: "Bạn chưa chọn loại phần thưởng!" }]} style={{ width: "49%" }}>
                <Select placeholder={"Loại phần thưởng"} style={{ width: "100%" }}>
                  <Option value={"fail"}>Mất lượt</Option>
                  <Option value={"point"}>Tích điểm</Option>
                  <Option value={"voucher"}>Voucher</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Tỉ lệ trúng thưởng" name={"percent"} rules={[{ required: true, message: "Bạn chưa nhập tỉ lệ trúng thưởng!" }]} style={{ width: "49%" }}>
                <MyInputNumber placeholder="Tỉ lệ trúng thưởng" min={0} max={100} style={{ width: "100%" }} />
              </Form.Item>
              <div style={{ width: "49%", display: "flex", justifyContent: "space-between" }}>
                {/*<Form.Item label="Màu nền" name={"background"} rules={[{ required: true, message: "Bạn chưa chọn màu nền!" }]}>*/}
                {/*  <ColorPicker />*/}
                {/*</Form.Item>*/}
                <Form.Item
                  label="Số lượng hiển thị"
                  name={"amount"}
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa chọn số lượng!",
                    },
                  ]}
                >
                  <MyInputNumber placeholder="Số lượng" min={1} max={5} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>

            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
              {({ getFieldValue }) => {
                var type = getFieldValue(["type"]);
                return (
                  <>
                    {type && (
                      <>
                        {type !== "point" && type != "fail" ? (
                          <div>
                            <Form.Item
                              name={["voucher", "create_type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Bạn chưa chọn loại voucher!",
                                },
                              ]}
                            >
                              <Radio.Group>
                                <Radio value={"create"}>Tạo qua kênh</Radio>
                                <Radio value={"available"}>Có sẳn</Radio>
                              </Radio.Group>
                            </Form.Item>

                            {
                              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher !== currentValues.voucher}>
                                {({ getFieldValue }) => {
                                  var value = getFieldValue();
                                  return (
                                    <>
                                      {value.voucher && (
                                        <div>
                                          {/*<div style={{ display: "flex", justifyContent: "space-between" }}>*/}
                                          {/*  <Form.Item label="Loại voucher" name={["voucher", "type"]} rules={[{ required: true, message: "Bạn chưa chọn loại voucher!" }]} style={{ width: "49%" }}>*/}
                                          {/*    <Select placeholder={"Loại phần thưởng"} style={{ width: "100%" }}>*/}
                                          {/*      <Option value={"exchange_freeship"}>Miễn phí vận chuyển</Option>*/}
                                          {/*      <Option value={"exchange_point"}>Khuyến mãi giảm giá</Option>*/}
                                          {/*      <Option value={"exchange_percentage"}>Khuyến mãi giảm giá phần trăm</Option>*/}
                                          {/*    </Select>*/}
                                          {/*  </Form.Item>*/}

                                          {/*  <Form.Item label="Giá trị" name={["voucher", "value"]} rules={[{ required: true, message: "Bạn chưa nhập giá trị!" }]} style={{ width: "49%" }}>*/}
                                          {/*    <MyInputNumber placeholder={"Giá trị phần thưởng"} min={1} style={{ width: "100%" }} />*/}
                                          {/*  </Form.Item>*/}
                                          {/*</div>*/}
                                          {value?.voucher?.create_type === "available" ? (
                                            <div>
                                              <Form.Item
                                                label="Mã voucher"
                                                name={["voucher", "list_voucher_id"]}
                                                rules={
                                                  value == true && [
                                                    {
                                                      required: true,
                                                      message: "Bạn chưa nhập mã voucher!",
                                                    },
                                                  ]
                                                }
                                              >
                                                <ListVoucherPicker></ListVoucherPicker>
                                              </Form.Item>
                                            </div>
                                          ) : (
                                            <>
                                              <Form.Item
                                                label="Kênh tạo voucher"
                                                name={["voucher", "list_chanel"]}
                                                rules={
                                                  value == true && [
                                                    {
                                                      required: true,
                                                      message: "Bạn chưa chọn app!",
                                                    },
                                                  ]
                                                }
                                              >
                                                <Select mode="multiple" placeholder="App tạo voucher">
                                                  {this.state.lstCRM.map((item) => {
                                                    return <Option value={item.id}>{item.name}</Option>;
                                                  })}
                                                </Select>
                                              </Form.Item>
                                              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <Form.Item label={"Thời hạn voucher"} name={["voucher", "voucher_limit"]} style={{ width: "48%" }}>
                                                  <MyInputNumber min={1} style={{ width: "100%" }} placeholder="Giới hạn voucher (ngày)"></MyInputNumber>
                                                </Form.Item>
                                                <Form.Item label="Giá trị tối thiểu đơn hàng" name={["voucher", "min_order"]} style={{ width: "48%" }}>
                                                  <MyInputNumber min={1} style={{ width: "100%" }} placeholder="Giá trị tối thiểu đơn hàng"></MyInputNumber>
                                                </Form.Item>
                                                <Form.Item label="prefix" name={["voucher", "voucher_prefix"]} style={{ width: "48%" }}>
                                                  <Input onChange={(e) => this.onPrefixChange(e.target.value, name)} style={{ width: "100%" }} placeholder="Tiền tố voucher"></Input>
                                                </Form.Item>
                                                {value?.voucher.type === "exchange_percentage" && (
                                                  <Form.Item label="Giá trị lớn nhất voucher" name={[name, "voucher", "max_value"]} style={{ width: "48%" }}>
                                                    <MyInputNumber min={1} step={10000} style={{ width: "100%" }} placeholder="Giá trị tối đa của voucher"></MyInputNumber>
                                                  </Form.Item>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  );
                                }}
                              </Form.Item>
                            }
                          </div>
                        ) : (
                          <>
                            {type != "fail" && (
                              <Form.Item label=" Điểm" name={"point"} rules={[{ required: true, message: "Bạn chưa nhập điểm!" }]}>
                                <MyInputNumber placeholder={"Điểm"} min={1} style={{ width: "100%" }} />
                              </Form.Item>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" hidden>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
