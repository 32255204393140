import React, {useEffect, useState, useRef} from 'react';
import { Space, Form, Switch, Card, Row, Col, Checkbox, Input, Button, message } from 'antd';
import APIHelper from "api"
function ANTDConfiguration(props){
    const [isActive, setIsActive] = useState(false);
    const [isSave, setIsSave] = useState(false)
    const [initialValues, setInitialValues] = useState({
        balance : false,
        store : false,
        store_url : null,
        contact : false,
        contact_url : null,
    });
    const { TextArea } = Input;
    const formRef = useRef();
    const handleActiveChange = () => {
        setIsActive(!isActive);
      };
    useEffect(() =>{
        APIHelper.post("app/zalov2/config/updateconfig", {data : isActive, field : 'menu_enable'})
    }, [isActive])
    const handleClick = () => {
    if (!isSave){
        setIsSave(true);
    }else{
        if (formRef) formRef.current.submit();
    }
    };

    const compareObjects = (a, b) =>{
        return JSON.stringify(a)===JSON.stringify(b);
    }

    const onFinish = (values) => {
        // const dataDifferent = compareObjects(values, props.configMenuData.menu);
        // if(!dataDifferent){
        //      APIHelper
        //     .post("app/zalov2/config/updateconfig", { data: values, field : "menu"})
        //     .then(async (resp) => {
        //         message.success("Lưu menu thành công");
        //         setIsSave(false);
        //         await props.getData()
        //     })
        //     .catch((error) => {
        //         message.error("Có lỗi xảy ra khi lưu menu");
        //     })
        // }
        APIHelper
            .post("app/zalov2/config/updateconfig", { data: values, field : "menu"})
            .then(async (resp) => {
                message.success("Lưu menu thành công");
                setIsSave(false);
                await props.getData()
            })
            .catch((error) => {
                message.error("Có lỗi xảy ra khi lưu menu");
            })
        
    };

    useEffect(()=>{
        console.log('Config Menu Data', props.configMenuData);
        if (formRef.current){
            formRef.current.setFieldsValue( props.configMenuData.menu )
        }
    }, [isActive])

    useEffect(()=>{
        setIsActive(props.configMenuData.enabled);
    }, [])

    return(
        <>
            <Form >
                <Form.Item label="Menu">
                <Space direction="vertical">
                    <Switch checked={isActive} onChange={handleActiveChange} style={{marginTop : 6}}></Switch>
                    <p>Bật để dùng menu được cấu hình từ Loyalty</p>
                </Space>
                </Form.Item>
            </Form>
            {isActive ?
                <Card style={{width: "100%", height: "100%"}}>
                    <Row>
                        <Col span={10} style={{margin: "auto", width: "50%",height: "auto"}}>
                            <div style={{
                                        color : "#000000",
                                        fontSize : 20,
                                        fontWeight : 500,
                                        marginBottom : 25
                                    }}>
                                        Các menu hiện thị trên ZALO OA
                            </div>
                            <Form disabled = { isSave ? false : true}  ref={formRef}  onFinish={onFinish}>
                                <Form.Item name="balance" valuePropName='checked' style={{marginBottom:"12px"}}>
                                    <Checkbox>Khách hàng thân thiết</Checkbox>
                                </Form.Item>
                                <Form.Item lable="Checkbox" name="store" valuePropName={'checked'} style={{marginBottom:"12px"}}>
                                    <Checkbox>Danh sách cửa hàng</Checkbox>
                                </Form.Item>
                                <Form.Item name="store_url" style={{marginBottom:"12px"}}>
                                    <TextArea rows={4} placeholder="Danh sách cửa hàng, dạng link"></TextArea>
                                </Form.Item>
                                <Form.Item lable="Checkbox" name="contact" valuePropName={'checked'} style={{marginBottom:"12px"}}>
                                    <Checkbox>Thông tin liên hệ</Checkbox>
                                </Form.Item>
                                <Form.Item name="contact_url">
                                    <TextArea rows={4} placeholder="Thông tin liên hệ"></TextArea>
                                </Form.Item>
                            </Form>
                            <Button
                                style={{float: "right"}}
                                onClick={handleClick}
                            >
                                {isSave ? "Lưu" : "Chỉnh sửa"}
                            </Button>
                        </Col>
                    </Row>
                </Card>
                : null
            }

        </>
    )
}

export default ANTDConfiguration