import React, { createRef, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Button, Checkbox, Form, Input, Table, Typography, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import api from "api";
import AntTable from "components/common/AntTable";
import numeral from "numeral";
import moment from "moment";

const { Text } = Typography;
function TemplateOption({ oaId, setCurrentStep, dataPost, onChange, back }) {
  const [form] = Form.useForm();
  const [listTemplate, setListTemplate] = useState([]);
  const Tb = createRef();
  // const [selectedTemplateId]
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Mã",
      dataIndex: "templateId",
      key: "templateId",
    },
    {
      title: "Tên mẫu",
      dataIndex: "templateName",
      key: "templateName",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status, record, index) => {
        if (status === "UNABLE" || status === "REJECT") {
          return (
            <Space>
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3243_1067)">
                  <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                </g>
                <defs>
                  <clipPath id="clip0_3243_1067">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Text>Chưa duyệt</Text>
            </Space>
          );
        } else {
          return (
            <Space>
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3243_1067)">
                  <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52C41A" />
                </g>
                <defs>
                  <clipPath id="clip0_3243_1067">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Text>Đã duyệt</Text>
            </Space>
          );
        }
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return numeral(text).format("0,0") + "đ";
      },
    },
    {
      // title: "Giá",
      dataIndex: "templateId",
      key: "templateId",
      render: (templateId, record) => {
        return (
          <>
            <Space size="middle">
              <a
                onClick={() => {
                  getTemplateDetail(templateId, "view");
                }}
              >
                Xem
              </a>
              <a
                onClick={() => {
                  getTemplateDetail(templateId, "select");
                }}
              >
                Chọn
              </a>
            </Space>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getZaloTemplate();
  }, []);

  const onFinish = () => {};
  const onFinishFailed = () => {};
  const getZaloTemplate = () => {
    api
      .post("app/zalov2/config/getdataalltemplate")
      .then((res) => {
        var filter = res.filter((item) => {
          return item.status === "ENABLE";
        });
        setListTemplate(filter);
        if (dataPost.content.zalo.template_id) {
          getTemplateDetail(dataPost.content.zalo.template_id, "view");
        } else {
          getTemplateDetail(filter[0].templateId, "view");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTemplateDetail = (templateId, mode) => {
    setLoading(true);
    api
      .post("app/zalov2/config/getdetailtemplate", { oa_id: oaId, template_id: templateId })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        var template = res;
        if (mode === "view") {
          setSelectedTemplate(res);
        } else {
          setSelectedTemplate(res);
          form
            .validateFields(["name"])
            .then((res) => {
              var name = form.getFieldValue("name");
              var newData = {
                name: name,
                content: {
                  email: dataPost.content.email,
                  default_email: dataPost.content.default_email,
                  esms: dataPost.content.esms,
                  smsfpt: dataPost.content.smsfpt,
                  zalo: {
                    preview_url: template.previewUrl,
                    price: template.price,
                    params: template.listParams,
                    oa_id: oaId,
                    template_id: template.templateId,
                    template_name: template.templateName,
                  },
                },
              };
              onChange(newData);
              setCurrentStep(1);
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Row gutter={16}>
        <Col span={14}>
          <Form
            layout="vertical"
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ name: dataPost?.name ? dataPost.name : `Chiến dịch gửi ${moment(new Date()).format("DD/MM/YYYY")}` }}
          >
            <Form.Item
              label="Tên chiến dịch"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên chiến dịch!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <AntTable
                size="small"
                columns={columns}
                customSearch={{ oa_id: oaId, template_status: "ENABLE" }}
                tableName={"tbCustomerListTemplate"}
                ajax="app/zalov2/config/gettemplatesandprice"
                ref={Tb}
              ></AntTable>
            </Form.Item>
          </Form>
        </Col>
        <Col span={10}>
          {!loading ? (
            <iframe
              src={selectedTemplate.previewUrl}
              scrolling="no"
              frameBorder="0"
              style={{ width: "100%", height: 450, overflow: "hidden", margin: "auto" }}
            ></iframe>
          ) : (
            <Row style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                    }}
                    spin
                  />
                }
              />
            </Row>
          )}
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={back}>Thoát</Button>
      </Row>
    </div>
  );
}

export default TemplateOption;
