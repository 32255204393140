import React, { Component } from "react";
import api from "api";
import { Button, Card, Row, Space, Tag, Col } from "antd";

export default class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false };
  }

  createSubscription = (id) => {
    api
      .post("/plan/subscription", { plan_id: id, type: "plan" })
      .then((res) => {
        this.setState({ load: false });
        var url = res.links.find((x) => x.rel === "approve");
        window.open(url.href, "_blank").focus();
      })
      .catch((err) => {
        this.props.fail();
      });
  };

  render() {
    return (
      <Button
        onClick={() => {
          this.setState({ load: true });
          this.createSubscription(this.props.id);
        }}
        loading={this.state.load}
        type="primary"
      >
        Upgrade
      </Button>
    );
  }
}
