import { Button, Card, Col, Drawer, Form, Input, message, Popconfirm, Popover, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import api from "api";
const { Text, Title } = Typography;
const { TextArea } = Input;
function AddEditMenu({ open, setOpen, menu, onChangeMenu }) {
  const [newMenu, setNewMenu] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [menuListMaster, setMenuListMaster] = useState();
  const [form] = Form.useForm();
  const onClose = () => {
    newMenu.forEach((item) => {
      delete item.index;
    });
    onChangeMenu(newMenu);
    setOpen(false);
  };

  useEffect(() => {
    setNewMenu(menu);
    getMenuListMaster();
  }, []);

  const getMenuListMaster = () => {
    api
      .post("themeconfig/getmenulist")
      .then((res) => {
        setMenuListMaster(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(selectedItem);
    }
  }, [selectedItem]);

  const onChangeSelect = (e) => {
    var findItem = menuListMaster.find((item) => {
      return item.key === e;
    });

    setSelectedItem({ index: selectedItem.index, ...findItem });
  };

  const onChangeInput = () => {
    var data = form.getFieldsValue();
    var newSelectedItem = { ...selectedItem };
    newSelectedItem.title = data.title;
    newSelectedItem.img = data.img;
    setSelectedItem(newSelectedItem);
  };

  return (
    <Drawer width={"50vw"} title="Cấu hình Menu" onClose={onClose} open={open}>
      {newMenu && (
        <Row style={{ width: 390, boxShadow: "0 .1rem .3rem rgba(0,0,0,.15)", marginBottom: 16 }} justify="center" align="middle">
          {newMenu.map((item, index) => {
            return (
              <Col
                justify="center"
                align="middle"
                style={{ padding: 8, width: "20%" }}
                onClick={() => {
                  setSelectedItem({ index: index, ...item });
                }}
              >
                <div className="bottomIcon" dangerouslySetInnerHTML={{ __html: item.img }}></div>
                <Text style={{ fontSize: 12 }}>{item.title}</Text>
              </Col>
            );
          })}

          {(!newMenu || newMenu.length < 5) && (
            <Col
              justify="center"
              align="middle"
              style={{ padding: 8, width: "20%" }}
              onClick={() => {
                var findItem = menuListMaster.find((item) => {
                  return item.key === "Home";
                });
                setSelectedItem({ index: newMenu.length, ...findItem });
              }}
            >
              <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="12.5" y="0.5" width="39" height="39" stroke="#C4C6D0" stroke-dasharray="2 2" />
                <path d="M40 21H33V28H31V21H24V19H31V12H33V19H40V21Z" fill="#C4C6D0" />
              </svg>
            </Col>
          )}
        </Row>
      )}
      {selectedItem && (
        <Row>
          <Col span={12}>
            <Card
              title={`${selectedItem.index < newMenu.length ? "Chỉnh sửa" : "Thêm mớI"} - ${selectedItem.title}`}
              style={{
                width: "100%",
              }}
            >
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Link đến trang"
                  name={"key"}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn trang đến",
                    },
                  ]}
                >
                  <Select
                    options={
                      menuListMaster?.length > 0
                        ? menuListMaster.map((item) => {
                            return { value: item.key, label: item.title };
                          })
                        : []
                    }
                    onChange={onChangeSelect}
                  ></Select>
                </Form.Item>
                <Form.Item
                  label="Tiêu đề"
                  name={"title"}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tiêu đề",
                    },
                  ]}
                >
                  <Input showCount maxLength={20} onBlur={onChangeInput}></Input>
                </Form.Item>
                <Form.Item
                  label="Icon"
                  name={"img"}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng SVG cho menu",
                    },
                  ]}
                >
                  <TextArea onBlur={onChangeInput}></TextArea>
                </Form.Item>
                <Row justify={"end"}>
                  {selectedItem.index < newMenu.length && (
                    <Popconfirm
                      title={<span>Bạn có muốn xoá menu này</span>}
                      onConfirm={() => {
                        var newData = [...newMenu];
                        newData = newMenu.filter((item, index) => {
                          return index !== selectedItem.index;
                        });
                        setNewMenu(newData);
                        setSelectedItem();
                      }}
                      okText="Có"
                      cancelText="Không"
                    >
                      <Button type="link" style={{ color: "red" }}>
                        Xoá
                      </Button>
                    </Popconfirm>
                  )}

                  <Button
                    type="primary"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((res) => {
                          var newData = [...newMenu];
                          newData[selectedItem.index] = selectedItem;
                          // delete newData[selectedItem.index].index;
                          setNewMenu(newData);
                          message.success("Lưu thành công");
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    Lưu
                  </Button>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col span={12} justify="center" align="middle">
            <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "center" }}>
              <div className="bottomIcon" dangerouslySetInnerHTML={{ __html: selectedItem.img }}></div>
              <Text style={{ fontSize: 12 }}>{selectedItem.title}</Text>
            </div>
          </Col>
        </Row>
      )}
    </Drawer>
  );
}

export default AddEditMenu;
