import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Col, DatePicker, Input, Select, Empty, Table, Modal } from "antd";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import api from "api";

export default class PopupcreateChildVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      visible: false,
      lstID: this.props.voucherParent.list_voucher.map((item) => {
        return item.id;
      }),
    };
  }

  open = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  createID = () => {
    var lstid = this.state.lstID;
    var result = this.props.voucherParent.voucher_code + "_";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var check = lstid.find((x) => x === result);
    if (check) {
      console.log("trùng rồi", result);
      return this.createID();
    }
    lstid.push(result);
    this.setState({ lstID: lstid });
    return result;
  };

  onChange = (value) => {
    this.setState({ number: value });
  };

  createVoucher = () => {
    if (this.state.number) {
      var lstvoucher = [];
      for (let index = 0; index < this.state.number; index++) {
        var voucher = {
          id: this.createID(),
          is_use: false,
          create_date: moment(),
        };
        lstvoucher.push(voucher);
      }
      api
        .post("voucher/savelistchild", { id: this.props.voucherParent.id, lstchild: lstvoucher })
        .then((res) => {
          message.success("Tạo voucher thành công");
          this.props.getChildVoucher(lstvoucher);
          this.setState({ visible: false });
          return lstvoucher;
        })
        .catch((errr) => {
          message.error("Tạo voucher thất bại");
          return null;
        });
    } else message.error("Bạn chưa nhập số lượng", 3);
    return null;
  };

  createAndExportExcel = () => {
    var lst = this.createVoucher();
    if (lst) {
      var lstexcel = lst.map((item) => {
        var data = "";
        if (item.is_use) data = "Đã dùng";
        else data = "Chưa dùng";
        var o = {};

        o["Mã voucher"] = item["id"];
        o["Ngày tạo"] = item["create_date"].format("DD/MM/YYYY");
        o["Sử dụng"] = data;

        return o;
      });

      const ws = XLSX.utils.json_to_sheet(lstexcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dt = new Blob([excelBuffer], { type: ".xlsx" });
      FileSaver.saveAs(dt, "danh sách voucher con (" + this.props.voucherParent.voucher_code + ").xlsx");
    }
  };

  render() {
    return (
      <Modal
        title="Voucher con"
        open={this.state.visible}
        onOk={() => {}}
        onCancel={() => {
          this.onClose();
        }}
        width={"40%"}
        maskClosable={false}
        footer={[
          <div>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                this.close();
              }}
            >
              Thoát
            </Button>

            <Button type="primary" onClick={() => this.createVoucher()}>
              Tạo
            </Button>

            <Button
              type="primary"
              loading={this.state.loadingBtn ? true : false}
              onClick={() => {
                this.createAndExportExcel();
              }}
            >
              Tạo và xuất excel
            </Button>
          </div>,
        ]}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>Số lượng:</div>
          <div style={{ width: "80%" }}>
            <InputNumber
              min={1}
              onChange={(value) => {
                this.onChange(value);
              }}
              style={{ width: "100%" }}
              value={this.state.number}
            ></InputNumber>
          </div>
        </div>
      </Modal>
    );
  }
}
