import React, { useEffect, useState } from "react";
import { Row, Col, Statistic, Button, Card, Space, Divider, Form, DatePicker, Input, Select, Tooltip, Typography } from "antd";
import { StarFilled } from "@ant-design/icons";
// import { render } from "@testing-library/react";
import AntTable from "components/common/AntTable";
import api from "api";
import * as dayjs from "dayjs";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Paragraph, Text, Link } = Typography;

function Review() {
  const [form] = Form.useForm();
  const Tb = React.createRef();
  const [statistic, setStatistic] = useState([]);
  const [dataPost, setDataPost] = useState({});
  const getColumn = () => {
    var columns = [
      {
        title: "Thời gian",
        dataIndex: "created_date",
        key: "created_date",
        sorter: true,
        render: (text, record, index) => {
          return <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY")}</p>;
        },
      },
      {
        title: "Đánh giá",
        dataIndex: "star",
        key: "star",
        sorter: true,
        render: (text) => {
          return renderIcon(text);
        },
      },
      {
        title: "Kênh",
        dataIndex: "channel",
        key: "channel",
        sorter: true,
        render: (text) => {
          if (text === "zalo_mini_app") {
            return "ZALO MINI APP";
          } else if (text === "zns") {
            return "ZNS";
          } else if (text === "facebook") {
            return "FACEBOOK";
          } else if (text === "shopee") {
            return "SHOPEE";
          } else {
            return text;
          }
        },
      },
      {
        title: "Tên sản phẩm",
        dataIndex: "product_name",
        key: "product_name",
        sorter: true,
        // render: (text) => {
        //   if (text === "zalo_mini_app") {
        //     return "ZALO MINI APP";
        //   } else if (text === "zns") {
        //     return "ZNS";
        //   } else if (text === "facebook") {
        //     return "FACEBOOK";
        //   } else if (text === "shopee") {
        //     return "SHOPEE";
        //   } else {
        //     return text;
        //   }
        // },
      },
      {
        title: "Số điện thoại",
        dataIndex: "customer_phone",
        key: "customer_phone",
        sorter: true,
        render: (text, record) => {
          if (text) {
            var phone = text?.substr(0, 4) + "." + text?.substr(3, 3) + "." + text?.substr(6, 3);
            var name = record.customer_name?.replace("null", "").trim();
            return (
              <Text style={{ marginBottom: "auto", marginTop: "auto" }}>
                {phone}
                <br />
                {name}
              </Text>
            );
          } else {
            return record.customer_name?.replace("null", "").trim();
          }
        },
      },
      {
        title: "Ý kiến khách hàng",
        dataIndex: "comment",
        key: "comment",
        sorter: true,
        // ellipsis: true,
        // ellipsis: {
        //   showTitle: false,
        // },
        render: (comment) => {
          return (
            <Tooltip trigger="hover" placement="topLeft" title={comment}>
              <Paragraph style={{ maxWidth: 360 }} ellipsis={{ rows: 2 }}>
                {comment}
              </Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: "Mã tham chiếu",
        dataIndex: "order_id",
        key: "order_id",
        sorter: true,
      },
    ];
    return columns;
  };

  const getStatisticReview = () => {
    api
      .get("review/statistic")
      .then((res) => {
        setStatistic(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStatisticReview();
  }, []);

  const renderIcon = (quantity) => {
    var arr = [];
    for (let index = 0; index < 5; index++) {
      arr.push(index);
    }
    return (
      // <div style={{ textAlign: "center" }}>
      <Space>
        {arr.map((item, index) => {
          return <StarFilled style={{ fontSize: 20, color: index < quantity ? "#FADB14" : "#F0F0F0" }} />;
        })}
      </Space>
      // </div>
    );
  };

  const renderValue = (star) => {
    if (statistic.length > 0) {
      var find = statistic.find((item) => {
        return item.star === star;
      });
      return `${find.quantity}(${find.percentage})`;
    } else {
      return "0(0%)";
    }
  };

  const onSearch = () => {
    var newDataPost = form.getFieldsValue();
    if (newDataPost.created_date && newDataPost.created_date.length > 0) {
      newDataPost.created_date = newDataPost.created_date.map((item, index) => {
        if (index === 0) {
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").utc().format();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").utc().format();
        }
      });
    }

    setDataPost(newDataPost);

    // api.post("review/list", { ...dataPost }).then((res) => {
    //   console.log(res);
    // });
  };

  useEffect(() => {
    if (dataPost) {
      Tb.current.reload();
    }
  }, [dataPost]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div style={{ padding: 8, background: "white", borderRadius: 8 }}>
        <Row style={{ width: "100%" }}>
          <Col flex={1}>
            <Statistic title={<div style={{ textAlign: "center" }}>{renderIcon(5)}</div>} value={renderValue(5)} valueStyle={{ textAlign: "center" }} />
          </Col>
          <Divider style={{ height: "unset" }} type="vertical" />
          <Col flex={1}>
            <Statistic title={<div style={{ textAlign: "center" }}>{renderIcon(4)}</div>} value={renderValue(4)} valueStyle={{ textAlign: "center" }} />
          </Col>
          <Divider style={{ height: "unset" }} type="vertical" />
          <Col flex={1}>
            <Statistic title={<div style={{ textAlign: "center" }}>{renderIcon(3)}</div>} value={renderValue(3)} valueStyle={{ textAlign: "center" }} />
          </Col>
          <Divider style={{ height: "unset" }} type="vertical" />
          <Col flex={1}>
            <Statistic title={<div style={{ textAlign: "center" }}>{renderIcon(2)}</div>} value={renderValue(2)} valueStyle={{ textAlign: "center" }} />
          </Col>
          <Divider style={{ height: "unset" }} type="vertical" />
          <Col flex={1}>
            <Statistic title={<div style={{ textAlign: "center" }}>{renderIcon(1)}</div>} value={renderValue(1)} valueStyle={{ textAlign: "center" }} />
          </Col>
        </Row>
      </div>

      <div style={{ background: "white", borderRadius: 8 }}>
        <Form layout={"inline"} form={form} style={{ padding: 8 }}>
          <Row style={{ width: "100%", margin: 0 }} gutter={[8, 8]}>
            <Col span={5}>
              <Form.Item name="text" style={{ marginInlineEnd: 0 }}>
                <Search placeholder="Mã đơn / Số điện thoại" allowClear onSearch={onSearch} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="product_name" style={{ marginInlineEnd: 0 }}>
                <Search placeholder="Tên sản phẩm" allowClear onSearch={onSearch} />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item name="created_date" style={{ marginInlineEnd: 0 }}>
                <RangePicker
                  style={{ width: "100%" }}
                  placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                  onChange={() => {
                    onSearch();
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="star" style={{ marginInlineEnd: 0 }}>
                <Select
                  placeholder="Số sao"
                  mode="multiple"
                  allowClear
                  options={[
                    { value: 1, label: "1 sao" },
                    { value: 2, label: "2 sao" },
                    { value: 3, label: "3 sao" },
                    { value: 4, label: "4 sao" },
                    { value: 5, label: "5 sao" },
                  ]}
                  onChange={onSearch}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="channel" style={{ marginInlineEnd: 0 }}>
                <Select
                  placeholder="Kênh đánh giá"
                  mode="multiple"
                  allowClear
                  options={[
                    { value: "zalo_mini_app", label: "ZALO MINI APP" },
                    { value: "zns", label: "ZNS" },
                    { value: "facebook", label: "FACEBOOK" },
                    { value: "shopee", label: "SHOPEE" },
                  ]}
                  onChange={onSearch}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <AntTable columns={getColumn()} customSearch={dataPost} tableName={"tbReview"} ajax="review/list" ref={Tb} rowKey={(record) => record.id}></AntTable>
      </div>
    </Space>
  );
}

export default Review;
