import React, { Component } from "react";
import { Button, Input, Form, message, Modal, Upload, Tag, Col, Row, Radio, Select } from "antd";
import { Tabs } from "antd";
import { PlusOutlined, SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import api from "api";
import CustomerInfoPopup from "./customer_info_popup";
import { phone_regex } from "common/regex.js";
import { renderInput } from "common/custom_field.js";
import TagPicker from "components/picker/tag_picker";
import DatePickerNew from "components/picker/date_picker";
import { getPath } from "common/file.js";
import SelectNew from "../../picker/select_picker";
import "./styles.css";
import { admin_client } from "config/index";
import { getLocalTime } from "common/date_time";

const { TabPane } = Tabs;
const { TextArea } = Input;

export default class AddNewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: false,
      mode: "edit",
      visible: false,
      isLoading: false,
      form_key: "1-customer-add",
      failed: false,
      data: {
        id: 0,
        name: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        birth_day: "",
        facebook_id: "",
        zalo: "",
        tag: [],
      },
      lst_tag: [],
      system_config: [],
      countries: [],
      states: [],
      active_tab: "customer-add-tab-1",
      emailOrPhone: false,
    };

    this.popupAddEdit = React.createRef();
    this.popupCustomerDetails = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount() { }

  getCustomerConfig = async () => {
    var dataPost = {
      query: { key: "field" },
      limit: 100,
      skip: 0,
    };
    if (this.state.system_config.length == 0) {
      var res = await api.post("customerconfig/list", dataPost);
      if (res.length > 0) {
        this.setState({ system_config: res });
      }
    }
  };

  getCountries = async () => {
    if (this.state.countries.length == 0) {
      var res = await api.get("customer/getcountries");
      if (res.length > 0) {
        var countries = res
          .map((element) => {
            element.label = element.country_name;
            return element;
          })
          .sort((firstItem, secondItem) => firstItem.order_index - secondItem.order_index);
        this.setState({ countries: countries });
      }
    }
  };

  getStates = async () => {
    var res = await api.post("customer/getstates", { value: this.state.data.country });
    if (res != null) {
      var states = res
        .map((element) => {
          element.label = element.state_name;
          return element;
        })
        .sort((firstItem, secondItem) => firstItem.order_index - secondItem.order_index);
      this.setState({ states: states });
    }
  };
  open = async (customer_id) => {
    if (customer_id == null) if (this.props.customer_id) customer_id = this.props.customer_id;
    await this.getCustomerConfig();
    await this.getTagConfig();
    await this.getCountries();

    if (customer_id != 0 && customer_id != null) {
      // this.setState({
      //     visible: true
      // })
      api
        .post("customer/getbyid", { id: customer_id })
        .then((resp) => {
          if (resp != null) {
            if (resp.image != null && resp.image != "")
              resp.image = {
                uid: Math.random(),
                name: resp.image,
                url: resp.image,
              };
            var emailOrPhone = false;
            if ((resp.email !== "" && resp.email != null) || (resp.phone !== "" && resp.phone != null)) emailOrPhone = true;
            resp.phone_number = resp.phone;
            if (resp.birth_day) resp.birth_day = getLocalTime(resp.birth_day);
            this.setState(
              {
                data: resp,
                visible: true,
                failed: false,
                isLoading: true,
                mode: "edit",
                emailOrPhone: emailOrPhone,
              },
              () => {
                //  this.getStates();
                this.form.current.resetFields();
                // setTimeout(() => {
                this.form.current.setFieldsValue(resp);
                // },5000)
                return true;
              }
            );
          }
        })
        .catch((error) => {
          message.error("Có lỗi xảy ra");
          return null;
        });
    } else {
      this.setState(
        {
          visible: true,
          failed: false,
          isLoading: true,
          mode: "add",
          data: {
            name: "",
            first_name: "",
            last_name: "",
            gender: "male",
            email: "",
            phone: "",
            birth_day: "",
            tag: [],
            facebook_id: "",
            zalo: "",
          },
        },
        () => {
          this.form.current.resetFields();
          this.form.current.setFieldsValue(this.state.data);
          return true;
        }
      );
    }
  };

  fileFilter = (file) => {
    var ext = "";
    var fileName = file.name;
    var i = fileName.lastIndexOf(".");
    if (i === -1) ext = "unknown";
    else ext = fileName.slice(i + 1);

    var lstAllow = [
      //image
      "jpg",
      "jpeg",
      "png",
      "gif",
    ];

    ext = ext.toLocaleLowerCase();
    // Accept images only
    if (!lstAllow.includes(ext)) {
      return false;
    }
    return true;
  };

  uploadImage = (e) => {
    var form = new FormData();
    if (e.file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");
    if (this.fileFilter(e.file)) {
      form.append("file", e.file);
      api
        .post("File/upload", form)
        .then((data) => {
          var item = { ...this.state.data };
          item.image = {
            uid: Math.random(),
            name: e.file.name,
            url: getPath(data.path),
          };
          this.setState({ data: item }, () => this.form.current.setFieldsValue({ image: item.image }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpeg hoặc gif");
  };

  removeImage = () => {
    var data = this.state.data;
    data.image = null;
    this.form.current.setFieldsValue({ image: "" });
    this.setState({ data });
  };

  getTagConfig = async () => {
    var res = await api.post("customerconfig/getconfig", { key: "tag" });
    if (res.length > 0) {
      this.setState({ lst_tag: res });
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onChangeTab = (key) => {
    this.setState({ active_tab: key });
  };

  onFinish = (values) => {
    console.log("values", values);
    values.phone = values.phone_number;
    if (values.tags != null) {
      values.tags = values.tags.map((item) => {
        if (item.id != null) return item.id;
        else return item;
      });
    }
    if (values.image != null && values.image != "") values.image = values.image.url;
    console.log("post data", values);
    api
      .post("customer/index", { data: values })
      .then((resp) => {
        message.success("Lưu khách hàng thành công");
        this.close();
      })
      .catch((error) => {
        if (error.message) {
          message.error(error.message);
        } else {
          message.error("Có lỗi xảy ra khi lưu");
        }
      })
      .then(() => {
        if (this.props.getData) this.props.getData(this.state.data.id);
      });
  };

  onSubmit = () => {
    if (this.form.current) this.form.current.submit();
  };

  close = () => {
    this.setState({ visible: false });
  };

  renderLeftAttributes = () => {
    var config = this.state.system_config;
    config = config.filter((obj) => !obj.is_basic);

    return config.map((config, index) => {
      if (index % 2 == 0)
        return (
          <Form.Item
            name={config.attribute}
            label={config.label}
            rules={
              config.isrequire && [
                {
                  required: true,
                  message: "Bạn chưa nhập " + config.label + "!",
                },
              ]
            }
          >
            {renderInput(config)}
          </Form.Item>
        );
    });
  };

  renderRightAttributes = () => {
    var config = this.state.system_config;
    config = config.filter((obj) => !obj.is_basic);

    return config.map((config, index) => {
      if (index % 2 == 1)
        return (
          <Form.Item
            name={config.attribute}
            label={config.label}
            rules={
              config.isrequire && [
                {
                  required: true,
                  message: "Bạn chưa nhập " + config.label + "!",
                },
              ]
            }
          >
            {renderInput(config)}
          </Form.Item>
        );
    });
  };

  onChangeCountry = (value) => {
    var item = { ...this.state.data };
    item.country = value;
    this.setState({ data: item }, () => this.getStates());
    this.form.current.setFieldsValue({ state: "" });
  };

  add_tag = () => {
    window.open(admin_client + "/setting/customerfield");
  };

  emailOrPhoneChange = () => {
    var data = this.form.current.getFieldsValue();
    if ((data.email !== "" && data.email != null) || (data.phone !== "" && data.phone != null)) this.setState({ emailOrPhone: true });
    else this.setState({ emailOrPhone: false });
  };

  renderInfo = () => {
    var item = { ...this.state.data };
    var name = item.name;
    if (name == null) name = "";
    var lst_tag = this.state.lst_tag;
    var images = [];
    const accept = ".png,.jpg,.jpeg,.gif";
    var render_tag = [];
    if (item.image != null) images.push(item.image);
    // var countryObj = countries.find(c => c.value == country);
    // if (countryObj != null)
    //     states = countryObj.states;

    const gender_options = [
      {
        label: "Nam",
        value: "male",
      },
      {
        label: "Nữ",
        value: "female",
      },
    ];

    if (item.tag != null && item.tag.length != 0) {
      render_tag = item.tag.map((obj) => {
        var config_tag = lst_tag.find((element) => element.id == obj);
        if (config_tag != null) return <Tag color={config_tag.color}>{config_tag.label}</Tag>;
      });
    }
    return (
      <>
        <Tabs onChange={this.onChangeTab}>
          <TabPane tab="Thông Tin Cơ Bản" key="customer-add-tab-1"></TabPane>

          <TabPane tab="Tùy Chỉnh" key="customer-add-tab-3"></TabPane>
        </Tabs>
        <div hidden={this.state.active_tab === "customer-add-tab-1" ? false : true}>
          <Row>
            <Col span={11}>
              {/* <Row>
                <Form.Item hidden name={"id"} label={"id"}></Form.Item>
                <Form.Item hidden name={"image"} label={"image"}></Form.Item>
                <Form.Item
                  required
                  name={"first_name"}
                  label={"Tên"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Tên là trường bắt buộc!",
                    },
                    {
                      validator: (rule, value) => {
                        return new Promise((resolve, error) => {
                          if (value.length < 100) {
                            resolve();
                          } else {
                            error();
                          }
                        });
                      },
                      message: "Không nhập quá 100 kí tự!",
                    },
                  ]}
                >
                  <Input placeholder="Tên"></Input>
                </Form.Item>
                <Form.Item
                  required
                  name={"last_name"}
                  label={"Họ"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Họ là trường bắt buộc!",
                    },
                  ]}
                >
                  <Input placeholder="Họ"></Input>
                </Form.Item>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name={"gender"} label={"Giới tính"}>
                    <Radio.Group options={gender_options} />
                  </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  <Form.Item
                    name={"birth_day"}
                    label={"Ngày sinh"}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Bạn chưa nhập ngày sinh!",
                      // },
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (value > moment().startOf("day") && (this.state.data.id === "" || this.state.data.id == null)) {
                              error();
                            } else {
                              resolve();
                            }
                          });
                        },
                        message: "Ngày sinh phải nhỏ hơn ngày hiện tại!",
                      },
                    ]}
                  >
                    <DatePickerNew placeholder="Chọn ngày" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row> */}
            </Col>
            <Col span={1}></Col>
            {/* <Col span={12}>
              {item.image == null || item.image === "" ? (
                <Upload accept={accept} className="customer-upload" name="avatar" listType="picture-card" showUploadList={false} customRequest={this.uploadImage}>
                  <div style = {{marginTop : "25%"}}>
                    {<PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              ) : (
                <Upload
                  accept={accept}
                  className="customer-upload"
                  name="avatar"
                  fileList={images}
                  listType="picture-card"
                  showUploadList={true}
                  customRequest={this.uploadImage}
                  onRemove={this.removeImage}
                ></Upload>
              )}
              <p style={{ fontSize: 14 }}>Hỗ trợ định dạng: jpg, jpeg, png, gif. Tối đa 3MB</p>
            </Col> */}
          </Row>
          <Row>
            <Col span={11}>
              <Row>
                <Form.Item hidden name={"id"} label={"id"}></Form.Item>
                <Form.Item hidden name={"image"} label={"image"}></Form.Item>
                <Form.Item
                  required
                  name={"first_name"}
                  label={"Tên"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Tên là trường bắt buộc!",
                    },
                    {
                      validator: (rule, value) => {
                        return new Promise((resolve, error) => {
                          if (value.length < 100) {
                            resolve();
                          } else {
                            error();
                          }
                        });
                      },
                      message: "Không nhập quá 100 kí tự!",
                    },
                  ]}
                >
                  <Input placeholder="Tên"></Input>
                </Form.Item>
                <Form.Item name={"last_name"} label={"Họ"} style={{ width: "100%" }}>
                  <Input placeholder="Họ"></Input>
                </Form.Item>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name={"gender"} label={"Giới tính"}>
                    <Radio.Group options={gender_options} />
                  </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  <Form.Item
                    name={"birth_day"}
                    label={"Ngày sinh"}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Bạn chưa nhập ngày sinh!",
                      // },
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, error) => {
                            if (value > dayjs().startOf("day") && (this.state.data.id === "" || this.state.data.id == null)) {
                              error();
                            } else {
                              resolve();
                            }
                          });
                        },
                        message: "Ngày sinh phải nhỏ hơn ngày hiện tại!",
                      },
                    ]}
                  >
                    <DatePickerNew placeholder="Chọn ngày" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.display_style !== currentValues.display_style}>
                    {({ getFieldValue }) => {
                      var data = getFieldValue("phone_number");
                      return (
                        <Form.Item
                          name={"email"}
                          label="Email"
                          rules={
                            data == "" || data == null
                              ? [
                                {
                                  required: true,
                                  message: "Bạn phải nhập email hoặc số điện thoại",
                                },
                                {
                                  type: "email",
                                  message: "Email không hợp lệ!",
                                },
                              ]
                              : [
                                {
                                  type: "email",
                                  message: "Email không hợp lệ!",
                                },
                              ]
                          }
                        >
                          <Input
                            placeholder="Email"
                            onChange={() => {
                              this.emailOrPhoneChange();
                            }}
                          ></Input>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.display_style !== currentValues.display_style}>
                    {({ getFieldValue }) => {
                      var data = getFieldValue("email");
                      return (
                        <Form.Item
                          name={"phone_number"}
                          label="Số điện thoại"
                          rules={
                            data == "" || data == null
                              ? [
                                {
                                  required: true,
                                  message: "Bạn phải nhập email hoặc số điện thoại",
                                },
                              ]
                              : [
                                {
                                  pattern: phone_regex,
                                  message: "Vui lòng nhập đúng số điện thoại",
                                },
                              ]
                          }
                        >
                          <Input
                            placeholder="Nhập số điện thoại"
                            onChange={() => {
                              this.emailOrPhoneChange();
                            }}
                          ></Input>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item style={{ width: "100%" }} name={"tags"} label="Tag">
                  <TagPicker></TagPicker>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name={"note"} label="Ghi chú" style={{ width: "100%" }}>
                  <TextArea></TextArea>
                </Form.Item>
              </Row>
            </Col>

            <Col span={1}></Col>
            <Col span={12}>
              <Form.Item>
                <Input.Group>
                  <Row>
                    <Form.Item name={["address", "address1"]} label="Địa chỉ 1" style={{ width: "100%" }}>
                      <Input placeholder="Nhập địa chỉ"></Input>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name={["address", "address2"]} label="Địa chỉ 2" style={{ width: "100%" }}>
                      <Input placeholder="Nhập địa chỉ"></Input>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name={["address", "country"]} label="Quốc gia" style={{ width: "100%" }}>
                      <Input placeholder="Nhập quốc gia" style={{ width: "100%" }}></Input>
                    </Form.Item>
                  </Row>

                  <Row>
                    <Form.Item name={["address", "province"]} label="Tỉnh thành" style={{ width: "100%" }}>
                      <Input placeholder="Nhập tỉnh thành" style={{ width: "100%" }}></Input>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name={["address", "city"]} label="Thành phố" style={{ width: "100%" }}>
                      <Input placeholder="Nhập thành phố" style={{ width: "100%" }}></Input>
                    </Form.Item>
                  </Row>
                  <Form.Item name={["address", "ward_code"]} value="" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["address", "district_code"]} value="" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["address", "country_code"]} value="" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["address", "province_code"]} value="" hidden>
                    <Input />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div hidden={this.state.active_tab === "customer-add-tab-3" ? false : true}>
          <Row>
            <Col span={11}>
              {this.renderLeftAttributes()}
              <Button type="dashed" style={{ marginTop: 26, width: "22vw" }} onClick={() => this.add_tag()}>
                <p style={{ color: "#4f8aff" }}>
                  <i style={{ marginRight: 5 }} className="fa fa-plus"></i> Tạo trường tuỳ chỉnh{" "}
                </p>
              </Button>
            </Col>
            <Col span={1}></Col>
            <Col span={12}>{this.renderRightAttributes()}</Col>
          </Row>
        </div>
      </>
    );
  };

  render() {
    var item = { ...this.state.data };
    var name = item.name;
    var style = {};
    if (name == null) name = "";
    var title = "Chỉnh sửa khách hàng " + name;
    if (item.id == null) title = "Thêm mới khách hàng";

    if (this.state.active_tab != "customer-add-tab-1") style = { height: "100%" };
    return (
      <Modal
        title={title}
        open={this.state.visible}
        onCancel={this.handleCancel}
        bodyStyle={style}
        zIndex={1050}
        maskClosable={false}
        width={"50%"}
        footer={[
          <>
            <Form.Item>
              <Button style={{ marginRight: 10, marginTop: 20 }} type="default" onClick={this.handleCancel}>
                Đóng
              </Button>
              <Button onClick={() => this.onSubmit()} style={{ marginRight: 10 }} type="primary" key="submit" htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </>,
        ]}
      >
        {this.state.failed && <div style={{ color: "red" }}>Vui lòng điền đầy đủ các trường bắt buộc ở cả 2 tab</div>}
        <Form
          layout="vertical"
          onFinish={this.onFinish}
          id="customer-form"
          ref={this.form}
          onFinishFailed={(e) => {
            this.setState({ failed: true });
          }}
        >
          {this.renderInfo()}
        </Form>
        <CustomerInfoPopup style={{ display: "none" }} ref={this.popupCustomerDetails}></CustomerInfoPopup>
      </Modal>
    );
  }
}
