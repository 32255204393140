import { UPDATE_TO_SERVICE, ADD_TO_SERVICE } from "./ServiceTypes.js";
// Save the cartItems to local storage
const setServiceItem = (serviceItem) => {
  localStorage.setItem("serviceItem", JSON.stringify(serviceItem && Object.keys(serviceItem).length > 0 ? serviceItem : {}));
};

// Export function to calculate the total price of the cart and the total quantity of the cart
// export const sumItems = (cartItems) => {
//   Storage(cartItems);
//   let itemCount = cartItems.length > 0 ? cartItems.reduce((total, product) => total + product.quantity, 0) : 0;
//   let total = cartItems.reduce((total, product) => total + product.sale_price * product.quantity, 0).toFixed(2);
//   return { itemCount, total };
// };

// The reducer is listening for an action, which is the type that we defined in the CartTypes.js file
const ServiceReducer = (state, action) => {
  // The switch statement is checking the type of action that is being passed in
  switch (action.type) {
    // If the action type is ADD_TO_CART, we want to add the item to the cartItems array
    case UPDATE_TO_SERVICE:
      Object.keys(action.payload).forEach((item) => {
        state.serviceItem[item] = action.payload[item];
      });
      setServiceItem(state.serviceItem);
      return {
        serviceItem: state.serviceItem,
        ...state,
      };

    case ADD_TO_SERVICE:
      state.serviceItem = { ...action.payload };
      setServiceItem(state.serviceItem);
      return {
        serviceItem: state.serviceItem,
        ...state,
      };

    //Return the state if the action type is not found
    default:
      return state;
  }
};

export default ServiceReducer;
