import { Button, Space } from "antd";
import api from "api";
import moment from "moment";
import numeral from "numeral";
const setTblCols = (customer_fields, component) => {
  var columns = [
    {
      title: "Khách hàng",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record, index) => {
        return (
          <div>
            {(record.last_name != null && record.last_name != "") || (record.first_name != null && record.first_name != "") ? (
              <a href={`/customer/detail/${record.id}`} target="_blank">
                {record.last_name} {record.first_name}
              </a>
            ) : (
              <a href={`/customer/detail/${record.id}`} target="_blank">
                Vô danh
              </a>
            )}
          </div>
        );
      },
    },
    {
      title: "Liên hệ",
      dataIndex: "phone",
      key: "phone",
      render: (text, record, index) => {
        return (
          <div>
            <div>
              <Space>
                <i className="fas fa-envelope"></i> {record?.email}
              </Space>
            </div>

            <div>
              <Space>
                <i className="fas fa-phone"></i> {record.phone}
              </Space>
            </div>
          </div>
        );
      },
    },

    {
      title: "Ngày sinh",
      dataIndex: "birth_day",
      key: "birth_day",
      render: (text, record, index) => {
        return <div>{record.birth_day && <>{moment(record.birth_day).format("L")}</>}</div>;
      },
    },

    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      render: (text, record, index) => {
        var genderName = "";
        switch (record.gender) {
          case "male":
            genderName = "Nam";
            break;
          case "female":
            genderName = "Nữ";
            break;

          case "other":
            genderName = "Khác";
            break;
        }
        return <div>{genderName}</div>;
      },
    },

    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (text, record, index) => {
        return (
          <div>
            {record.address?.address1} {record.address?.city} {record.address?.province}
          </div>
        );
      },
    },
    {
      title: "Số lần mua hàng",
      dataIndex: "orders_count",
      key: "orders_count",
      render: (text, record, index) => {
        return <div style={{ textAlign: "right" }}>{text}</div>;
      },
    },

    {
      title: "Tổng chi tiêu",
      dataIndex: "total_spent",
      key: "total_spent",
      render: (text, record, index) => {
        return <div style={{ textAlign: "right" }}>{numeral(record.total_spent).format("#,##0")}</div>;
      },
    },
  ];

  // for (let index = 0; index < customer_fields.length; index++) {
  //     const item = customer_fields[index];
  //     if (item.is_basic)
  //         continue;
  //     var col = {
  //         title: item.label,
  //         dataIndex: item.attribute,
  //         key: item.attribute,
  //         render: (text, record, index) => {
  //             return (
  //                 <div>
  //                     {/* {renderValue(item, record)} */}
  //                 </div>
  //             )
  //         }
  //     }
  //     columns.push(col);

  // };

  // var action = {
  //     title: "action",
  //     dataIndex: "",
  //     key: "action",
  //     render: (text, record, index) => {
  //         return (
  //             <>
  //                 <a href={"/customer/detail/" + record.id} target="_blank">
  //                 <i class="far fa-eye"></i>
  //                 </a>
  //                 <Button type="link" onClick={() => component.updatePoint(record)}><i class="fas fa-pen-alt"></i></Button>
  //             </>
  //         )
  //     }
  // };

  // columns.push(action);
  component.setState({ table_cols: columns });
};

export { setTblCols };
