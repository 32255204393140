import React, { Component } from "react";
import { Layout, Menu, Col, Row, Card } from "antd";
import "./menu.css";

const { Header, Sider, Content } = Layout;

export default class ParentMenu extends React.Component {
  state = {
    collapsed: true,
    childCollapsed: false,
    current_parent_key: "",
  };

  onSelectParentMenu = (parent_key, newpage) => {
    this.props.setParentKey(parent_key, newpage);
  };

  getChildMenu = (current_parent_key) => {
    var menu = this.props.menu;
    var current_parent = menu.find((z) => z.key == current_parent_key);
    var current_child = [];
    if (current_parent != null) current_child = current_parent.child;
    var child = current_child;
    return { current_parent, child };
  };

  render() {
    var menu = this.props.menu;
    var parent_key = this.props.current_parent_key;

    return (
      <>
        <Sider
          className="parent-menu"
          collapsedWidth={"45px"}
          trigger={null}
          collapsed={true}
          collapsible
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={parent_key}
            defaultSelectedKeys={parent_key}
            onSelect={(e) => {
              this.onSelectParentMenu(e.key);
            }}
            items={menu}
          >
            {/* {menu.map((menu) => (
              <Menu.Item
                key={menu.key}
                icon={menu.icon}
                onClick={() => {
                  this.onSelectParentMenu(menu.key);
                }}
                onMouseDown={(event) => {
                  if (event.button === 2) {
                    event.preventDefault();
                    this.onSelectParentMenu(menu.key, true);
                  }
                }}
              >
                {menu.name}
              </Menu.Item>
            ))} */}
          </Menu>
        </Sider>
      </>
    );
  }
}
