import { Skeleton } from "antd";
import React from "react";
import PopupSelectApp from "./popupselectapp";

export default class SendingChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lst: [],
      lstPicker: [],
      user_accounts: [],
      lstSend: [],
      loading: true,
    };
    this.PopupSelectApp = React.createRef();
  }

  componentDidMount = () => {
    var lstPicker = [];
    if (this.props.value) lstPicker = this.props.value;
    this.setState({ lst: this.props.lst, lstPicker: lstPicker, loading: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.id !== this.props.id) {
      var lstPicker = [];
      if (this.props.value) lstPicker = this.props.value;
      this.setState({ lstPicker: lstPicker });
    }
  };

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onChangeParameters = (value, app_index) => {
    var lst = this.props.value;
    lst[app_index].parameters = value;
    if (this.props.onChange) {
      this.props.onChange(lst);
    }
  };

  finish = (lst, mode) => {
    // var lstshow = this.state.lstPicker;
    // if (lst && lst.length>0) {
    //   lst.forEach((item) => {
    //     var index = lstshow.findIndex((x) => x === item);
    //     if (index === -1) {
    //       lstshow.push(item);
    //     }
    //   });
    this.setState({ lstPicker: lst });
    this.onChange(lst);
  };

  delete = (id) => {
    var lst = this.props.value;
    var index = lst.findIndex((x) => x === id);
    lst.splice(index, 1);
    var lstsend = lst.map((item) => {
      return item;
    });
    this.onChange(lstsend);
    this.setState({ lstPicker: lst });
  };

  renderItem(item, index) {
    return (
      <div
        style={{
          height: "230px",
          width: "230px",
          boxShadow: "#bdbddb 0px 13px 27px -5px, #a6a6a6 0px 8px 16px -8px",
          border: "1px solid #dfdfdf",
          margin: "0 10px 30px 10px",
          cursor: "pointer",
          padding: "15px",
          position: "relative",
        }}
      >
        <div>
          <img src={item.logo} style={{ width: "70px", height: "70px" }}></img>
        </div>
        <div style={{ marginTop: "15px", fontSize: "15px", fontWeight: "bold" }}>{item.name}</div>
        <div style={{ marginTop: "15px", fontSize: "15px", color: "#c9c9c9" }}>{item.description}</div>
        <div style={{ position: "absolute", fontSize: "25px", color: "#898585", bottom: "5px", right: "50px" }}></div>
        <div
          style={{ position: "absolute", fontSize: "25px", color: "#898585", bottom: "5px", right: "15px" }}
          onClick={() => {
            this.delete(item.id);
          }}
        >
          <i className="fas fa-trash"></i>
        </div>
      </div>
    );
  }

  render() {
    var lst = [];
    if (this.props.value) {
      this.props.value.forEach((item) => {
        var index = this.state.lst.findIndex((x) => x.id === item);
        if (index > -1) lst.push(this.state.lst[index]);
      });
    }
    if (lst != null)
      return (
        <>
          {this.props.load && (
            <>
              {this.state.load ? (
                <Skeleton />
              ) : (
                <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                  <div
                    style={{
                      height: "230px",
                      width: "230px",
                      display: "flex",
                      boxShadow: "#bdbddb 0px 13px 27px -5px, #a6a6a6 0px 8px 16px -8px",
                      border: "1px solid #dfdfdf",
                      margin: "0 10px 30px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.PopupSelectApp.current.open();
                    }}
                  >
                    <div style={{ margin: "auto" }}>
                      <div style={{ border: "5px solid #969696", height: "50px", width: "50px", fontSize: "30px", display: "flex", color: "#969696", margin: "auto" }}>
                        <i className="fa fa-plus" style={{ margin: "auto" }}></i>
                      </div>
                      <span style={{ color: "#898585", marginTop: "15px" }}>Thêm mới</span>
                    </div>
                  </div>
                  {lst.map((item, index) => {
                    return this.renderItem(item, index);
                  })}
                </div>
              )}

              <PopupSelectApp ref={this.PopupSelectApp} finish={this.finish} lst={this.state.lst} lstPicker={this.props.value} campaign_id={this.props.campaign_id}></PopupSelectApp>
            </>
          )}
        </>
      );
    else return <div></div>;
  }
}
