import React, { Component } from "react";
import { InputNumber } from "antd";

export default class CurrencyPicker extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (value) => {
    if (this.props.type === "percent" && value > 100) {
      value = 100;
    }
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    var widthinput = this.props.width + "%";
    var currency = 100 - this.props.width + "%";
    var data = "";
    if (this.props.value != null) data = this.props.value;
    if (this.props.type === "percent" && data > 100) data = 100;
    return (
      <>
        <div style={{ display: "flex" }}>
          <InputNumber {...this.props} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} style={{ width: widthinput }} value={data}></InputNumber>
          <div style={{ width: currency, border: "1px solid #dfdfdf", display: "flex" }}>
            <div style={{ margin: "auto" }}>{this.props.type === "money" ? this.props.currency : "%"}</div>
          </div>
        </div>
      </>
    );
  }
}
