import numeral from "numeral";

const formatCurrency = (value) => {
  return numeral(value).format("#,###");
};

const formatDecimal = (value) => {
  return numeral(value).format("#,###.##");
};

const formatK = (value) => {
  return numeral(value).format("0a");
};
export { formatCurrency, formatDecimal, formatK };
