import { memo } from "react";
import { Row, Col, Image, Card, Button } from "antd";
import api from "api";

const ZaloApp = () => {
  const addOA = (id) => {
    api.post("app/zalov2/config/getrequestpermisionurl").then((res) => {
      window.open(res, "_blank").focus();
    });
  };

  return (
    <div>
      <Row>
        <Col span={2}></Col>
        <Col span={10} style={{ display: "flex", justifyContent: "center" }}>
          <Image src="https://storage.googleapis.com/cubeloyalty_asia/system/default_img/zalo_not_login.svg" preview={false}></Image>
        </Col>
        <Col span={10}>
          <Card>
            <div style={{ fontWeight: "bold", fontSize: 38, marginBottom: 30 }}>Đồng bộ Zalo OA</div>
            <div style={{ marginBottom: 30 }}>
              <b>Tại sao phải đồng bộ tài khoản ZALO OA </b>
              <ul>
                <li>Đồng bộ OA giúp hệ thống nhận điện được khách hàng của bạn </li>
                <li>Gửi Zalo Notification Service(ZNS) cho khách hàng</li>
                <li>Kết nối với khách hàng đúng lúc, đúng thời điểm , tăng tương tác </li>
              </ul>
              <b>Làm sao để gửi được ZNS </b>
              <ul>
                <li>Tài khoản ZALO OA phải là tài khoản trả phí</li>
                <li> Mẫu tin phải được ZALO duyệt</li>
                <li>Hạn mức ZNS khi vừa tạo tài khoản là 500 ZNS/Ngày </li>
              </ul>
              <b>ZALO OA có gửi tin khuyến mãi không</b>
              <ul>
                <li>Tin nhắn ZALO có 5 cấp độ , khi đạt đến cấp 5 sẽ được gửi tin Followup, đây là dạng tin ko hạn chế có thể gửi khuyến mãi</li>
                <li>Các ZNS đều hạn chế không được gửi khuyến mãi</li>
              </ul>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  addOA();
                }}
              >
                Đồng bộ Zalo OA
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ZaloApp);
