import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, message, Card, Col, Row, Typography, Form, Space, Modal } from "antd";
import ImagePicker from "components/picker/image_picker";
import HTMLEditorPicker from "components/picker/html_editor_picker";
import ParagraphEditable from "components/picker/text_editable";
import DatePicker from "components/picker/date_picker";
import CategoryPicker from "./category_picker";
import moment from "moment";
import "./blog.css";
import api from "api";
const { confirm } = Modal;

const { Text } = Typography;

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, titleLengthErr: false, titleEmptyErr: false };
    this.form = React.createRef();
  }

  componentDidMount() {
    if (this.props.mode == "add") {
      //load default data
      this.form.current.setFieldsValue({
        id: 0,
        title: "Tiêu đề bài viết",
        cover_image: "https://storage.googleapis.com/cubeloyalty_asia/system/default_img/post_image.jpg",
        publish_date: moment().format(),
        category: "",
      });
    }

    if (this.props.mode == "edit") {
      api.get("blog/get", { params: { id: this.props.id } }).then((data) => {
        this.form.current.setFieldsValue(data);
      });
    }
  }
  validateTitle = (values) => {
    if (values.title.length > 200) {
      this.setState({ titleLengthErr: true });
      return false;
    } else if (values.title.length == 0) {
      this.setState({ titleEmptyErr: true });
      return false;
    }
    return true;
  };

  save = (values) => {
    var validate = this.validateTitle(values);
    if (validate == false) {
      return;
    }
    this.setState({ saving: values.status });
    api.post("blog/save", values).then((data) => {
      this.setState({ saving: false });
      confirm({
        title: "Đã lưu bài viết thành công. ",
        icon: null,
        content: "Bạn có muốn đi chuyển đến màn hình danh sách ? ",
        onOk() {
          window.location = `/marketing/blog`;
        },
        onCancel() {},
      });
    });
  };

  saveWithStatus = (status) => {
    this.form.current.setFieldsValue({ status: status });
    this.form.current.submit();
  };

  render() {
    return (
      <>
        <Form ref={this.form} layout="vertical" onFinish={this.save}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="status" hidden>
            <Input />
          </Form.Item>

          <Card
            title={
              <>
                <Form.Item
                  // className = "title-form-item"
                  style={{ margin: 0 }}
                  name="title"
                  rules={[
                    { required: true, message: "Bạn phải nhập tiêu đề" },
                    { max: 200, message: "Tiêu đề tối đa chỉ 200 ký tự" },
                  ]}
                >
                  <ParagraphEditable style={{ margin: 0 }} ellipsis={true} />
                </Form.Item>
              </>
            }
            extra={
              <Space>
                <Button
                  disabled={this.state.saving == "publish"}
                  loading={this.state.saving == "draft"}
                  type="link"
                  onClick={(e) => this.saveWithStatus("draft")}
                >
                  Lưu nháp
                </Button>
                <Button
                  disabled={this.state.saving == "draft"}
                  loading={this.state.saving == "publish"}
                  type="primary"
                  onClick={(e) => this.saveWithStatus("publish")}
                >
                  Lưu & Xuất bản
                </Button>
              </Space>
            }
          >
            <Row gutter={6}>
              <Col span={6}>
                <Card title="Thông tin">
                  <Form.Item label="Danh mục" name="category" rules={[{ required: true, message: "Vui lòng chọn 1 danh mục" }]}>
                    <CategoryPicker placeholder="Chọn danh mục" />
                  </Form.Item>
                  <Form.Item label="Ngày xuất bản" name="publish_date" rules={[{ required: true, message: "Vui lòng chọn ngày xuất bản" }]}>
                    <DatePicker placeholder="Chọn ngày" />
                  </Form.Item>
                  <Form.Item label="Hình đại diện" name="cover_image" rules={[{ required: true, message: "Vui lòng chọn hình ảnh" }]}>
                    <ImagePicker size={{ width: 100, height: 100 }} crop={{ quality: 1, aspect: 1.0 / 1.0 }} />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={18}>
                <Card title="Nội dung">
                  <Form.Item
                    name="content"
                    rules={[
                      { required: true, message: "Vui lòng nhập nội dung" },
                      { pattern: /^(?!<p><br><\/p>$)/, message: "Vui lòng nhập nội dung" },
                    ]}
                  >
                    <HTMLEditorPicker></HTMLEditorPicker>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </Card>
        </Form>
      </>
    );
  }
}
