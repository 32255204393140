import React, { Component } from "react";
import { Modal, Table, Typography } from "antd";
import AntTable from "components/common/AntTable";
import { formatL } from "common/date_time";
import moment from "moment";
import SendHistory from "components/send_history";
const Text = { Typography };

export default class PopupSerachInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.sendhis = React.createRef();
  }
  componentDidMount = () => {};

  open = (record) => {
    this.setState({ visible: true }, () => {
      this.sendhis.current.searchSource(record.source);
    });
  };
  render() {
    return (
      <Modal title="Các tin đã gửi" open={this.state.visible} onCancel={() => this.setState({ visible: false })} width="80%" loading={this.state.loading} height="100%">
        <SendHistory ref={this.sendhis} />
      </Modal>
    );
  }
}
