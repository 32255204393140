import React, { Component } from "react";
import { Modal, Input, Radio, message, Form, Checkbox, Upload, Table, Button, Row, Card } from "antd";
import api from "api";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export default class PaypalConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      edit: false,
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    api.post("paypal/getconfig").then((res) => {
      this.setState({ data: res }, () => {
        this.form.current.setFieldsValue({ id: res.id, secret: res.secret });
      });
    });
  };

  getToken = (values) => {
    api
      .post("paypal/gettoken", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  render() {
    return (
      <>
        <Card
          title="Cấu hình Paypal"
          extra={
            <Button
              onClick={() => {
                this.setState({ edit: true });
              }}
              type="primary"
            >
              Sửa
            </Button>
          }
        >
          <Form layout="vertical" ref={this.form} onFinish={this.getToken}>
            <Form.Item
              label="Client ID"
              name="id"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập ID người dùng",
                },
              ]}
            >
              <Input disabled={!this.state.edit}></Input>
            </Form.Item>
            <Form.Item
              label="Secret"
              name="secret"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập secret!",
                },
              ]}
            >
              <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} disabled={!this.state.edit} />
            </Form.Item>
            <div hidden={!this.state.edit}>
              <Form.Item style={{ float: "right" }}>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Form.Item>
              <Button
                style={{ float: "right", marginRight: 10 }}
                onClick={() => {
                  this.getData();
                  this.setState({ edit: false });
                }}
              >
                Đóng
              </Button>
            </div>
          </Form>
        </Card>
      </>
    );
  }
}
