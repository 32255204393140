import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Typography, DatePicker, Select, Col, Input, Modal } from "antd";
import api from "api";
import { customer_client } from "config/index";
import RewardPicker from "components/marketing/config/reward_picker/index";
import { formatCurrency } from "../../common/format/number";
import NamePicker from "../exchange_point_program/config/picker/namepicker";
import moment from "moment";
import { formatS } from "common/date_time";
import UploadImagePicker from "components/picker/image_picker/index";
import HTMLEditorPicker from "components/picker/html_editor_picker/index";
import WayToSendPicker from "components/campaign/waytosendpicker";
import MyInputNumber from "components/picker/inputnumber_picker";
import GameTest from "./gameTest/index";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

export default class LuckySpin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        id: "0",
        events: [],
        is_active: true,
      },
      uploaded_logo: "/image/microsoft.png",
      showData: false,
      isLimit: false,
      load: false,
      segmentName: "",
      timeLimit: false,
      byLevel: false,
      tiers: [],
      participants: "all",
      point: "",
      lstApp: [],
      lstCRM: [],
      gameTest: false,
      customerLink: null,
    };
    this.form = React.createRef();
    this.SendMethods = React.createRef();
  }

  componentDidMount = async () => {
    var lstapp = await api.post("shop/getinstalledappbycategories", { categories: ["communication"] });
    var lstcrm = await api.post("shop/getinstalledappbycategories", { categories: ["crm"] });
    var link = await api.post("shop/getcustomerurl");
    this.setState({ lstApp: lstapp, lstCRM: lstcrm, customerLink: link });
    await this.getConfig();
  };

  getData = () => {
    if (this.props.match.params.id == 0) {
      var data = {
        id: "",
        type: this.props.match.params.type,
        is_active: true,
        name: "Vòng quay may mắn",
        logo: this.state.config.default_logo,
        turn_limit: "",
        time_limit: "",
        way_to_send: [],
        reward_list: [],
        gameData: [],
      };
      this.form.current.setFieldsValue(data);
      this.setState({ data: data, load: true });
    } else {
      api.post("gameprogram/getconfigbyid", { id: this.props.match.params.id }).then((res) => {
        if (res.time_limit.is_time_limit) {
          res.time_limit.time = [moment(res.time_limit.start), moment(res.time_limit.end)];
        }
        this.form.current.setFieldsValue(res);
        this.setState({
          load: true,
          data: res,
        });
      });
    }
  };
  getConfig = () => {
    api.post("gameprogram/getconfigbytype", { type: this.props.match.params.type }).then((res) => {
      this.setState({ config: res }, () => this.getData());
    });
  };

  onChangeEvents = (events) => {
    var config = { ...this.state.config };
    config.events = events;
    this.setState({ config });
    console.log(config);
  };

  saveProgram = (values) => {
    var config = { ...this.state.config };
    if (values.time_limit.is_time_limit) {
      values.time_limit = {
        is_time_limit: values.time_limit.is_time_limit,
        start: values.time_limit.time[0],
        end: values.time_limit.time[1],
      };
    } else values.time_limit.is_time_limit = false;
    api
      .post("gameprogram/savegame", { config: values })
      .then((res) => {
        message.success("Lưu thành công");
        var href = config.config_url + this.props.match.params.type + "/" + res.program_id;
        if (!window.location.href.includes(href)) window.location.href = href;
        this.getData();
      })
      .catch((err) => {
        message.error("Có lỗi xảy ra khi lưu");
      });
  };

  onBylevelChange = (value) => {
    this.setState({ byLevel: value });
  };

  participantsChange = (value) => {
    this.setState({ participants: value });
  };
  coppyLink = () => {};

  getLastest = (x) => {
    this.setState({ gameData: x });
  };
  doneGame = () => {
    // let ar = this.state.gameData.map((i,k)=>{
    //     i.hasOwnProperty("isOpen") && (i.isOpen = false)
    // })
    //
    // this.setState({gameData:ar})
    this.setState({ gameTest: false });
  };

  openGame = () => {
    window.open(window.location.origin + `/marketing/test/game/${this.props.match.params.type}/${this.props.match.params.id}`, "_blank");
  };
  initWheel = () => {
    return this.state.gameTest;
  };

  render() {
    var config = { ...this.state.config };
    var logo_status = this.state.logo_status;

    return (
      <>
        <Form
          name="emailsetting"
          ref={this.form}
          layout="vertical"
          onFinish={this.saveProgram}
          initialValues={{
            time_limit: { is_time_limit: false },
            turn_limit: { is_turn_limit: false },
          }}
        >
          {this.state.load && (
            <div className={"program-wrapper"} style={{ width: "101%", marginLeft: -13 }}>
              <div style={{ minHeight: 60 }}>
                <Form.Item name={"name"} rules={[{ type: "string" }]}>
                  <NamePicker></NamePicker>
                </Form.Item>
              </div>
              <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} style={{ marginLeft: 0, marginRight: 0 }}>
                <Col xs={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 12 }} md={{ span: 12 }} style={{ marginBottom: 20 }}>
                  <Card title="Trạng thái" size="small" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }}>
                    <Form.Item hidden name={"id"} label={"id"} rules={[{ type: "string" }]}></Form.Item>
                    <Form.Item hidden name={"type"} label={"type"} rules={[{ type: "string" }]}></Form.Item>

                    <Form.Item name="is_active">
                      <Radio.Group onChange={(e) => this.radioChange(e.target.value)}>
                        <Space direction="vertical">
                          <Radio value={true} style={{ marginTop: 30 }}>
                            Hoạt động
                          </Radio>
                          <Radio value={false} style={{ marginTop: 30 }}>
                            Tạm dừng
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 12 }} md={{ span: 12 }} style={{ marginBottom: 20 }}>
                  <Card title="Biểu tượng" size="small" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }}>
                    <Form.Item
                      name="logo"
                      rules={[
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (!value || value === "") {
                                error();
                              } else {
                                resolve();
                              }
                            });
                          },
                          message: "Bạn chưa chọn logo tên!",
                        },
                      ]}
                    >
                      <UploadImagePicker crop={{ quality: 1, aspect: 1 }} size={{ width: 64, height: 64 }} tags={["logo"]}></UploadImagePicker>
                    </Form.Item>
                  </Card>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                  <Card title="Thể lệ" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                    <Form.Item
                      name="description"
                      style={{ height: "90%" }}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa nhập thể lệ!",
                        },
                      ]}
                    >
                      <HTMLEditorPicker style={{ height: "100%" }}></HTMLEditorPicker>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>

              <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                <Col xs={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                  <Card title="Quy định" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                    <Col>
                      <Form.Item name={["time_limit", "is_time_limit"]} valuePropName="checked">
                        <Checkbox style={{ marginBottom: 10 }}>Giới hạn thời gian</Checkbox>
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.time_limit !== currentValues.time_limit}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["time_limit", "is_time_limit"]);
                          return (
                            <Form.Item
                              name={["time_limit", "time"]}
                              hidden={value ? false : true}
                              rules={
                                value == true && [
                                  {
                                    required: true,
                                    message: "Bạn chưa nhập giới hạn thời gian!",
                                  },
                                ]
                              }
                            >
                              <RangePicker style={{ width: "100%" }}></RangePicker>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name={["turn_limit", "is_turn_limit"]} valuePropName="checked">
                        <Checkbox style={{ marginBottom: 10 }}>Giới hạn lượt chơi</Checkbox>
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.turn_limit !== currentValues.turn_limit}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["turn_limit", "is_turn_limit"]);
                          return (
                            <>
                              <Form.Item
                                name={["turn_limit", "type"]}
                                hidden={value ? false : true}
                                rules={
                                  value == true && [
                                    {
                                      required: true,
                                      message: "Bạn chưa chọn phương thức giới hạn!",
                                    },
                                  ]
                                }
                              >
                                <Radio.Group>
                                  <Radio value={"day"}>Giới hạn theo ngày</Radio>
                                  <Radio value={"all"}>Giới hạn cả chương trình</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                name={["turn_limit", "value"]}
                                hidden={value ? false : true}
                                rules={
                                  value == true && [
                                    {
                                      required: true,
                                      message: "Bạn chưa nhập giới hạn lượt chơi!",
                                    },
                                  ]
                                }
                              >
                                <MyInputNumber style={{ width: "100%" }}></MyInputNumber>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Card>

                  {/* <SendMethods onChangeEvents={this.onChangeEvents} ref={this.SendMethods} events={this.state.config.events} program_id={this.props.match.params.id} type={this.props.match.params.type}></SendMethods> */}
                </Col>
                <Col xs={{ span: 18 }} lg={{ span: 18 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ marginBottom: 20 }}>
                  <Card title="Phần thưởng" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }} size="small">
                    <div>
                      <Form.Item
                        name={"reward_list"}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập phần thưởng",
                          },
                          {
                            validator: (rule, value) => {
                              return new Promise((resolve, error) => {
                                if (value.length >= 5) {
                                  resolve();
                                } else {
                                  error();
                                }
                              });
                            },
                            message: "Phải có ít nhất 5 phần thưởng!",
                          },
                        ]}
                      >
                        <RewardPicker getLastest={this.getLastest}></RewardPicker>
                      </Form.Item>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Card size="small" style={{ width: "99%", marginLeft: 10, height: 60 }}>
                  <div style={{ position: "absolute", right: 20 }}>
                    <Space>
                      {this.state.data?.id && (
                        <>
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(`${this.state.customerLink ? this.state.customerLink : customer_client}/${this.state.data.shop}/game/${this.state.data.id}`);
                              message.success("coppied");
                            }}
                          >
                            Coppy link
                          </Button>
                          <Button type="dashed" onClick={this.openGame}>
                            Chơi thử
                          </Button>
                        </>
                      )}
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>
                    </Space>
                  </div>
                </Card>
              </Row>
            </div>
          )}
        </Form>
      </>
    );
  }
}
