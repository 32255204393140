import React from "react";
import {
  Row,
  Col,
  Anchor,
  Typography,
  Form,
  Input,
  Card,
  Select,
  Radio,
  Switch,
  Table,
  Space,
  Popconfirm,
  InputNumber,
  Button,
  Modal,
  Divider,
  message,
} from "antd";
import UploadImagePicker from "components/picker/upload_image_picker";
import Editor from "../marketing/display/react_quill";
import { QuestionCircleOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import ProductState from "context/product/ProductState";
import ProductContext from "context/product/ProductContext";
import api from "api";
import { useNavigate, useParams } from "react-router";
import HTMLEditorPicker from "components/picker/html_editor_picker/index";
import AddEditVariant from "./AddEditVariant";
import VariantTable from "./VariantTable";
import { createRef } from "react";
import DeleteCategoryModal from "./DeleteCategoryModal";
import ServiceState from "context/service/ServiceState";
import ServiceContext from "context/product/ProductContext";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

const { Title, Text } = Typography;
const { Option } = Select;
const { success, error } = Modal;

function AddEditService() {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [categoryForm] = Form.useForm();
  const mode = window.location.pathname.split("/")[3];
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);

  const serviceContext = useContext(ServiceContext);

  const [variants, setVariants] = useState([{ id: "", name: "", isImage: false, options: [{ id: "", value: "", image: "" }], position: 1, mode: "add" }]);
  const [loading, setLoading] = useState(false);
  const [headerStyle, setHeaderStyle] = useState({});
  const categoryRef = createRef(null);
  const [isValidRef, setIsValidRef] = useState(0);
  const [isModalCategoryOpen, setIsModalCategoryOpen] = useState(false);
  useEffect(() => {
    form.resetFields();
    if (mode === "edit") {
      getServiceById();
    } else {
      serviceContext.addToService({
        name: null,
        category_id: null,
        images: [],
        description: null,
        is_require_contact: false,
        is_variant: false,
        variants: [],
        skus: [],
      });
      // setImageList([null, null, null, null, null]);
    }
  }, []);

  const getServiceById = () => {
    api
      .post("service/getbyid", { id: id })
      .then((res) => {
        if (res.id) {
          delete res._id;
          // if (!res.is_variant) {
          //   res.sku = res.skus[0];
          res.skus = res.skus.sort((a, b) => {
            if (a.option1 < b.option1) return -1;

            if (a.option1 > b.option1) return 1;
            if (a.option2 < b.option2) return -1;

            if (a.option2 > b.option2) return 1;
            if (a.option3 < b.option3) return -1;

            if (a.option3 > b.option3) return 1;
          });
          Object.keys(res).forEach((key) => {
            if (key !== "skus") {
              form.setFieldValue(key, res[key]);
            }
          });
          // form.setFieldsValue(res);
          // form.setFieldValue("category_id", "3db365f8-0438-4439-abce-067294c1872a");
          serviceContext.addToService(res);
          if (res.variants.length > 0) {
            setVariants(
              res.variants.map((variant) => {
                return { ...variant, mode: "view" };
              })
            );
          }
          // var product = res;
          // delete product.skus;
          // console.log(res.skus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (key, value) => {
    var newData = {};
    switch (key) {
      case "images":
        if (serviceContext.serviceItem.images) {
          newData[key] = serviceContext.serviceItem.images;
          newData[key].push(value);
        } else {
          newData[key] = [value];
        }
        break;
      case "is_variant":
        newData[key] = form.getFieldValue(key);
        newData.skus = [];
        form.setFieldValue("skus", []);
        updateForm.resetFields(["sale_price", "original_price", "quantity"]);
        if (!newData[key]) {
          newData.variants = [];
          setVariants([{ id: "", name: "", isImage: false, options: [{ id: "", value: "", image: "" }], position: 1, mode: "add" }]);
        }
        break;
      default:
        newData[key] = form.getFieldValue(key);
        break;
    }

    serviceContext.updateToService(newData);
  };

  const onFinish = () => {
    setLoading(true);
    var dataPost = form.getFieldsValue();
    if (mode === "add") {
      dataPost.name = dataPost.name.trim();
      if (serviceContext.serviceItem.images) {
        dataPost.images = serviceContext.serviceItem.images;
      } else {
        dataPost.images = [];
      }
      serviceContext.updateToService(dataPost);
    } else {
      // var dataPost = form.getFieldsValue();
      dataPost.id = id;
      if (serviceContext.serviceItem.images) {
        dataPost.images = serviceContext.serviceItem.images;
      } else {
        dataPost.images = [];
      }
      // console.log(dataPost);
      // debugger;
      serviceContext.updateToService(dataPost);
    }
    setTimeout(() => {
      // debugger;
      console.log(serviceContext.serviceItem);
      api
        .post("service/validate", serviceContext.serviceItem)
        .then((res) => {
          save();
        })
        .catch((err) => {
          if (err.error === -1) {
            error({
              title: "Lỗi",
              content: err.message,
              okText: "Đóng",
              okType: "primary",
            });
            setLoading(false);
          }
        });
    }, 1000);
  };

  const save = () => {
    api
      .post("service/save", serviceContext.serviceItem)
      .then((res) => {
        setLoading(false);
        success({
          title: "Thành công",
          content: "Lưu dịch vụ hoàn tất",
          okText: "Đóng",
          okType: "primary",
          onOk() {
            serviceContext.addToService({
              name: null,
              category_id: null,
              images: [],
              description: null,
              is_require_contact: false,
              is_variant: false,
              variants: [],
              skus: [],
            });
            // form.resetFields(["name", "category_id", "images", "description", "is_require_contact", "is_variant", "variants", "skus"]);
            window.location = `/marketing/service`;
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    api
      .post("service_category/list")
      .then((res) => {
        if (res.length > 0) {
          setCategoryList(
            res
              .filter((item) => {
                return !item.is_delete;
              })
              .sort((a, b) => {
                return a.order_num - b.order_num;
              })
              .map((item) => {
                return { value: item.id, label: item.name, key: item.id };
              })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeVariant = (value, index, name) => {
    // console.log(value);
    var newVariants = [...variants];
    if (!value.id && value.mode === "view") {
      newVariants = newVariants.filter((n, i) => {
        return i !== index;
      });
      setVariants(newVariants);
    } else {
      newVariants[index] = value;
      setVariants(newVariants);
    }
  };

  const removeVariant = (value, index) => {
    var newVariants = [...variants];
    newVariants = newVariants.filter((variant) => {
      return variant.id !== value.id;
    });
    setVariants(newVariants);
  };

  const setFieldValue = (fieldName, value) => {
    // console.log(fieldName);
    form.setFieldValue(fieldName, value);
  };

  const deleteCategory = (value) => {
    api
      .post("service_category/delete", { category_id: value })
      .then((res) => {
        getCategoryList();
        message.open({
          type: "success",
          content: "Xoá thành công",
        });
      })
      .catch((err) => {
        console.log(err);
        message.open({
          type: "error",
          content: "Xoá thất bại",
        });
      });
  };

  const onChangeCategoryPosition = (list) => {
    const ids = list.map((item) => {
      return item.value;
    });
    const order_num = list.map((item, index) => {
      return index;
    });
    setCategoryList(list);
    api
      .post("service_category/changeposition", { ids: ids, order_num: order_num })
      .then((res) => {
        getCategoryList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish} style={{ position: "relative" }}>
        <Row>
          <Col span={4}>
            <Title level={3}>Thêm mới dịch vụ</Title>
          </Col>
          <Col
            span={20}
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "calc(100% - 194px - ((100% - 160px) / 24) * 4)",
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              background: "#f5f5f5",
            }}
          >
            <Form.Item style={{ margin: 0 }}>
              <Space>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Thoát
                </Button>
                <Button loading={loading} type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Anchor
              items={[
                {
                  key: "basic-information",
                  href: "#basic-information",
                  title: "Thông tin cơ bản",
                },
                {
                  key: "image",
                  href: "#image",
                  title: "Hình ảnh",
                },
                {
                  key: "product-detail",
                  href: "#product-detail",
                  title: "Mô tả dịch vụ",
                },
                {
                  key: "sales-information",
                  href: "#sales-information",
                  title: "Thông tin bán hàng",
                },
              ]}
            />
          </Col>
          <Col span={20}>
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
              <Card title="Thông tin cơ bản" id="basic-information">
                <Form.Item
                  label="Tên dịch vụ"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên dịch vụ!",
                    },
                  ]}
                >
                  <Input showCount maxLength={255} placeholder="Tên dịch vụ" />
                </Form.Item>
                {/* <Form.Item> */}
                <Space.Compact block style={{ width: "100%" }}>
                  <Form.Item
                    style={{ width: "100%" }}
                    label="Danh mục"
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tối thiểu danh mục!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Danh mục"
                      options={categoryList}
                      onDropdownVisibleChange={(e) => {
                        if (!e) {
                          categoryRef.current.resetFields(["category_name"]);
                        }
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Form ref={categoryRef} layout="inline">
                            <Row style={{ width: "100%", padding: "0 12px 5px 12px", alignItems: "center" }}>
                              {/* <Form.Item
                            label="Tên dịch vụ"
                            name="category_name"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng nhập tên dịch vụ!",
                              },
                            ]}
                          > */}
                              <Col span={20}>
                                <Form.Item
                                  style={{ margin: 0 }}
                                  name={"category_name"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng điền tên danh mục!",
                                    },
                                    {
                                      message: "Tên danh mục đã tồn tại",
                                      validator: (_, value) => {
                                        var categoryNameList =
                                          categoryList.length > 0
                                            ? categoryList.map((c) => {
                                                return c.label.toLowerCase();
                                              })
                                            : [];
                                        if (value && categoryNameList.length > 0) {
                                          if (categoryNameList.includes(value.trim().toLowerCase())) {
                                            return Promise.reject("Some message here");
                                          }
                                        }

                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Tên dịch vụ" />
                                </Form.Item>
                              </Col>

                              {/* </Form.Item> */}
                              <Col span={4}>
                                <Button
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    categoryRef.current
                                      .validateFields()
                                      .then((value) => {
                                        api
                                          .post("service_category/save", { name: categoryRef.current.getFieldsValue().category_name })
                                          .then((res) => {
                                            categoryRef.current.resetFields(["category_name"]);
                                            getCategoryList();
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      })
                                      .catch((err) => {});

                                    // if (!categoryRef.current.getFieldsValue().category_name) {
                                    //   setIsValidRef(1);
                                    // } else if (
                                    //   categoryList.length > 0 &&
                                    //   categoryList
                                    //     .map((item) => {
                                    //       return item.name;
                                    //     })
                                    //     .includes(categoryRef.current.getFieldsValue().category_name)
                                    // ) {
                                    //   setIsValidRef(2);
                                    // } else {
                                    //   setIsValidRef(0);
                                    //   api
                                    //     .post("service_category/save", { name: categoryRef.current.getFieldsValue().category_name })
                                    //     .then((res) => {
                                    //       categoryRef.current.resetFields(["category_name"]);
                                    //       getCategoryList();
                                    //     })
                                    //     .catch((err) => {
                                    //       console.log(err);
                                    //     });
                                    // }
                                  }}
                                  type="link"
                                  icon={<PlusOutlined />}
                                >
                                  Thêm mới
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginTop: 30.8 }}>
                    <Button
                      onClick={() => {
                        setIsModalCategoryOpen(true);
                      }}
                    >
                      <MoreOutlined style={{ transform: "rotate(90deg)" }} />
                    </Button>
                  </Form.Item>
                </Space.Compact>
                {/* </Form.Item> */}
              </Card>
              <Card title="Hình ảnh" id="image">
                {/* {imageList.length > 0 &&
                imageList.map((item, index) => {
                  return ( */}
                <Form.Item
                  label={"Hình ảnh dịch vụ"}
                  name={"images"}
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập hình ảnh chính cho dịch vụ!",
                    },
                  ]}
                >
                  <UploadImagePicker
                    images={serviceContext.serviceItem.images}
                    onChange={(e) => {
                      onChange("images", e);
                    }}
                    onRemove={(e) => {
                      var dataPost = {};
                      dataPost.images = serviceContext.serviceItem.images.filter((item) => {
                        return item !== e.url;
                      });
                      serviceContext.updateToService(dataPost);
                    }}
                    limitImage={5}
                  />
                </Form.Item>
              </Card>
              <Card title="Mô tả dịch vụ" id="product-detail">
                <Form.Item name="description" style={{ marginBottom: 48 }}>
                  <HTMLEditorPicker style={{ height: 296 }}></HTMLEditorPicker>
                </Form.Item>
              </Card>
              <Card title="Thông tin bán hàng" id="sales-information">
                <Form.Item name="is_require_contact" label="Loại dịch vụ" initialValue={mode === "add" ? false : serviceContext.serviceItem.is_require_contact}>
                  <Radio.Group
                    onChange={(e) => {
                      serviceContext.updateToService({ is_require_contact: e.target.value });
                    }}
                  >
                    <Radio value={false}>Có giá bán</Radio>
                    <Radio value={true}>Liên hệ</Radio>
                  </Radio.Group>
                </Form.Item>

                {!serviceContext.serviceItem.is_variant && !serviceContext.serviceItem.is_require_contact && (
                  <Form.Item label="Giá và Số lượng">
                    {/* <Table
                    rowClassName={() => "editable-row"}
                    dataSource={serviceContext.serviceItem && serviceContext.serviceItem.skus ? serviceContext.serviceItem.skus : dataSource}
                    pagination={false}
                    columns={defaultColumns}
                  /> */}
                    <VariantTable setFieldValue={setFieldValue} />
                  </Form.Item>
                )}

                {serviceContext.serviceItem.is_variant && !serviceContext.serviceItem.is_require_contact && (
                  <Space size={"large"} direction="vertical" style={{ width: "100%" }}>
                    {variants.map((variant, index) => {
                      return <AddEditVariant index={index} item={variant} onChange={onChangeVariant} onRemove={removeVariant} />;
                    })}
                    <Form.Item style={{ margin: 0 }}>
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        disabled={variants.length > 1 || (variants.length > 0 && variants[variants.length - 1].mode === "add") ? true : false}
                        onClick={() => {
                          var newVariants = [...variants];

                          newVariants.push({
                            id: "",
                            name: "",
                            isImage: false,
                            options: [{ id: "", value: "", image: "" }],
                            position: variants.length + 1,
                            mode: "add",
                          });

                          setVariants(newVariants);
                        }}
                      >
                        Thêm mới
                      </Button>
                    </Form.Item>
                    {serviceContext.serviceItem.variants.length > 0 && (
                      <>
                        <Text>Danh sách biến thể</Text>
                        <Form form={updateForm}>
                          <div style={{ width: "100%", display: "flex", gap: 16, justifyContent: "end" }}>
                            <Form.Item name="sale_price" style={{ width: "20%" }}>
                              <InputNumber
                                min={0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                style={{ width: "100%" }}
                                placeholder="Giá bán"
                              ></InputNumber>
                            </Form.Item>

                            <Form.Item name="original_price" style={{ width: "20%" }}>
                              <InputNumber
                                min={0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                placeholder="Giá gốc"
                                style={{ width: "100%" }}
                              ></InputNumber>
                            </Form.Item>
                            <Form.Item name="quantity" style={{ width: "20%" }}>
                              <InputNumber min={0} placeholder="Số lượng" style={{ width: "100%" }}></InputNumber>
                            </Form.Item>
                            <Form.Item style={{ width: "20%" }}>
                              <Button
                                onClick={() => {
                                  var updateValue = updateForm.getFieldsValue();
                                  var newSkus = form.getFieldValue("skus");
                                  if (Object.keys(updateValue.length > 0)) {
                                    Object.keys(updateValue).forEach((key) => {
                                      if (updateValue[key]) {
                                        if (newSkus.length > 0) {
                                          newSkus.forEach((sku) => {
                                            if (updateValue[key] > 0) {
                                              sku[key] = updateValue[key];
                                            }
                                          });
                                        } else {
                                          newSkus.push(updateValue);
                                        }
                                      }
                                    });

                                    serviceContext.updateToService({ skus: newSkus });
                                    form.setFieldsValue({ skus: newSkus });
                                  }
                                }}
                                style={{ width: "100%" }}
                                type="primary"
                              >
                                Cập nhật
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>

                        <Form.Item>
                          <VariantTable setFieldValue={setFieldValue} />
                        </Form.Item>
                      </>
                    )}
                  </Space>
                )}
              </Card>
            </Space>
          </Col>
        </Row>

        <Row style={{ position: "relative" }}></Row>
      </Form>
      <DeleteCategoryModal
        onChange={onChangeCategoryPosition}
        isModalOpen={isModalCategoryOpen}
        onDelete={deleteCategory}
        categoryList={categoryList}
        setIsModalOpen={setIsModalCategoryOpen}
      />
    </>
  );
}

const AddEditServiceHOC = () => {
  return (
    <ServiceState>
      <AddEditService></AddEditService>
    </ServiceState>
  );
};

export default AddEditServiceHOC;
