import React, { Component } from "react";
import { Card, Switch, Form, Checkbox, Button, message, Input, Space, InputNumber } from "antd";

import { Radio } from 'antd';
import api from "api";

export default class ChatBotConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: {},
    };
    this.fromMenuSetting = React.createRef();
  }

  componentDidMount() {}
  setFieldsValue = (values) =>{
    this.fromMenuSetting.current.setFieldsValue(values);
    this.setState({store : values.menu.store});
    this.setState({contact : values.menu.contact});
  }

  onMenuFinish = (values) => {
    console.log("values", this.fromMenuSetting.current.getFieldValue())
    // return;
    api
      .post("app/fb/config/savemenu", values)
      .then((data) => {
        message.success("Dữ liệu của bạn đã được lưu");
      })
      .catch((err) => {
        message.error(`Lỗi. Vui lòng thử lại. ${err.message ?? ""}`);
      });
  };

  onRecurringFinish = (values) => {
    // return;
    api
      .post("app/fb/config/saverecurring", values)
      .then((data) => {
        message.success("Dữ liệu của bạn đã được lưu");
      })
      .catch((err) => {
        message.error(`Lỗi. Vui lòng thử lại. ${err.message ?? ""}`);
      });
  };




  render() {
    return (
      <>
        <Form onFinish={this.onMenuFinish} ref={this.fromMenuSetting}>
          <Card
            title="Menu"
            extra={
              <Form.Item name="enable" noStyle valuePropName="checked">
                <Switch></Switch>
              </Form.Item>
            }
          >
            <Form.Item name={["menu", "balance"]} valuePropName="checked">
              <Checkbox>Thành viên</Checkbox>
            </Form.Item>

            <Form.Item name={["menu", "store"]} valuePropName="checked" 
             >
              <Checkbox onChange = {(e) => {this.setState({store : e.target.checked})}}>Danh sách cửa hàng</Checkbox>
            </Form.Item>
            <div style={{ marginLeft: 15 }}>
              <Form.Item name={["menu", "store_url"]} label="Thông tin"
               rules={this.state.store == true && [{ required: true, message: "Bạn chưa nhập danh sách cửa hàng" }]}>
                <Input.TextArea placeholder="Danh sách cửa hàng: Link "></Input.TextArea>
              </Form.Item>
            </div>
            <Form.Item name={["menu", "contact"]} valuePropName="checked">
              <Checkbox onChange = {(e) => {this.setState({contact : e.target.checked})}}>Thông tin liên hệ</Checkbox>
            </Form.Item>

            <div style={{ marginLeft: 15 }}>
              <Form.Item name={["menu", "contact_url"]} label="Thông tin"
              rules={this.state.contact == true && [{ required: true, message: "Bạn chưa nhập thông tin liên hệ" }]}>
                <Input.TextArea placeholder="Thông tin liên hệ "></Input.TextArea>
              </Form.Item>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "column-reverse", alignItems: "flex-end" }}>
              <Form.Item noStyle>
                <Button htmlType="submit" type="primary">
                  Lưu
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Form>
        <Space>
          <div></div>
        </Space>




        
      </>
    );
  }
}
