import * as Widgets from "../components/widgets";
import React from "react";
const { VanillaFieldSelect, VanillaConjs, VanillaButton, VanillaButtonGroup, VanillaProvider, VanillaValueSources, vanillaConfirm } = Widgets;

export const settings = {
  formatField: (field, parts, label2, fieldDefinition, config, isForDisplay) => {
    if (isForDisplay) return label2;
    else return field;
  },

  renderField: (props) => <VanillaFieldSelect {...props} />,
  renderOperator: (props) => <VanillaFieldSelect {...props} />,
  renderFunc: (props) => <VanillaFieldSelect {...props} />,
  renderConjs: (props) => <VanillaConjs {...props} />,
  renderButton: (props) => <VanillaButton {...props} />,
  renderButtonGroup: (props) => <VanillaButtonGroup {...props} />,
  renderProvider: (props) => <VanillaProvider {...props} />,
  renderValueSources: (props) => <VanillaValueSources {...props} />,
  renderConfirm: vanillaConfirm,

  valueSourcesInfo: {
    value: {},
  },
  fieldSeparator: ".",
  fieldSeparatorDisplay: ".",
  renderSize: "small",
  maxLabelsLength: 100,
  canReorder: true,
  canRegroup: true,
  showNot: true,
  canLeaveEmptyGroup: true,
  shouldCreateEmptyGroup: false,
  forceShowConj: false,
  canShortMongoQuery: true,
  groupActionsPosition: "topRight", // oneOf [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight]
  setOpOnChangeField: ["keep", "default"], // 'default' (default if present), 'keep' (keep prev from last field), 'first', 'none'
  groupOperators: ["some", "all", "none"],

  convertableWidgets: {
    number: ["slider", "rangeslider"],
    slider: ["number", "rangeslider"],
    rangeslider: ["number", "slider"],
    text: ["textarea"],
    textarea: ["text"],
  },

  // localization
  locale: {
    moment: "en",
  },
  valueLabel: "Giá trị",
  valuePlaceholder: "Giá trị",
  fieldLabel: "Trường",
  operatorLabel: "Phép Toán",
  funcLabel: "Hàm",
  fieldPlaceholder: "Chọn thuộc tính",
  funcPlaceholder: "Chọn hàm",
  operatorPlaceholder: "Chọn phép tính",
  deleteLabel: null,
  addGroupLabel: "Thêm nhóm điều kiện",
  addRuleLabel: "Thêm điều kiện",
  addSubRuleLabel: "Thêm điều kiện con",
  delGroupLabel: "",
  notLabel: "Không",
  valueSourcesPopupTitle: "Chọn giá trị",
  removeRuleConfirmOptions: null,
  removeGroupConfirmOptions: null,

  defaultGroupConjunction: "AND",
  jsonLogic: {
    groupVarKey: "var",
    altVarKey: "var",
  },
};
