import React from "react";
import { Image, Space, Upload, message, Button, Card, Input } from "antd";
import { getPath } from "common/file.js";

import api from "api";
import Form from "rc-field-form/es/Form";

export default class UploadImagePicker extends React.Component {
    state = { isUploading: false };

    constructor(props) {
        super(props);
        this.form = React.createRef();

    }
    onChange = (value) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    uploadImage = (e) => {
        var form = new FormData();

        if (e.file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");

        if (this.fileFilter(e.file)) {
            form.append("file", e.file);
            this.setState({ isUploading: true });
            api
                .post("File/upload", form)
                .then((data) => {
                    this.setState({ isUploading: false });
                    var url = getPath(data.path);
                    this.onChange(url);
                })
                .catch((err) => {
                    message.error("Không thể tải file. Vui lòng thử lại");
                    this.setState({ isUploading: false });
                });
        } else message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpeg hoặc gif");
    };

    fileFilter = (file) => {
        var ext = "";
        var fileName = file.name;
        var i = fileName.lastIndexOf(".");
        if (i === -1) ext = "unknown";
        else ext = fileName.slice(i + 1);

        var lstAllow = [
            //image
            "jpg",
            "jpeg",
            "png",
            "gif",
        ];

        ext = ext.toLocaleLowerCase();
        // Accept images only
        if (!lstAllow.includes(ext)) {
            return false;
        }
        return true;
    };
    isValidSrc = (src) => {
        if (src && src != "" && typeof src == "string") {
            return true;
        }
        return false;
    };

    render() {
        var img_height = "auto";
        var img_width = "100%";
        if (this.props.size) {
            img_width = this.props.size.width || "100%";
            img_height = this.props.size.height || "100%";
        }
        return (
            <div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {this.isValidSrc(this.props.value) ? (
                            <Image style={{ width: img_width, height: img_height }} src={this.props.value} preview={false}></Image>
                        ) : (
                            <Card width="64" height="64">
                                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>No image</div>
                            </Card>
                        )}
                    </div>
                </div>

                <div style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: 10 }}>
                    <div>
                        {
                            !this.props.disabled &&
                            <Space>

                                <Upload accept=".png,.jpg,.jpeg,.gif" showUploadList={false} customRequest={this.uploadImage}>
                                    <Button loading={this.state.isUploading}>{this.state.isUploading ? "Uploading" : "Upload"}</Button>
                                </Upload>

                            </Space>
                        }

                    </div>

                </div>
                <div>
                    <label> URL </label>
                    <Input value={this.props.value} onChange={(e) => this.onChange(e.target.value)}></Input>
                </div>
            </div>
        );
    }
}
