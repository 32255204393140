import React, { Component } from "react";
import { Typography } from "antd";


const { Paragraph } = Typography;

export default class ParagraphEditable extends Component {

    onChange = e => {
    
        this.props.onChange(e)
    }
    render() {
        return <Paragraph {...this.props} editable={{ onChange: this.onChange }} >{this.props.value}</Paragraph>
    }
}