import React, { Component } from "react";

import { Form, Input, InputNumber, Button, Space, Popconfirm, message } from 'antd';
import { Typography } from 'antd';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { dataTypes, makeid } from "common/custom_field"

import api from "api"
const { Paragraph } = Typography;
const { TextArea } = Input;
export default class Field extends Component {

    constructor(props) {
        super(props);
        this.state = { isEditing: this.props.showEdit }
        this.form = React.createRef();

    }

    toNoUnicode = (value) => {
        var str = value;
        str = str.replace(/\s/g, "");
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$| |_/g, "");
        str = str.replace(/-+-/g, "-");
        str = str.replace(/^\-+|\-+$/g, "");
        return str;
    };


    save = (values) => {
        values.attribute=this.toNoUnicode(values.attribute);
        if(values.attribute=='' || values.attribute==null)
        {
            message.error('Vui lòng nhập tên cột');
        }
        else
        {
            values.attribute=values.attribute;
            if(this.props.lstdata.findIndex(x=> x==this.props.data)>=0 && this.props.lstdata.length>0)
            {
                if(this.props.lstdata.findIndex(x=> x!=this.props.data && x.attribute==values.attribute)>=0)
                {
                    message.error('Tên cột đã tồn tại');
                }
                else{
                    this.props.save({ ... this.props.data, ...values },'edit');
                    this.setState({ isEditing: false });
                }
            }
            else
            {
                if(this.props.lstdata.findIndex(x=> x.attribute==values.attribute)>=0)
                {
                    message.error('Tên cột đã tồn tại');
                }
                else{
                    this.props.save({ ... this.props.data, ...values },'add');
                    this.setState({ isEditing: false });
                }
            }
        }


        
    }

    render() {
        var item = this.props.data;
        if (this.state.isEditing)
            return this.renderEdit();
        return (
            <div  style={{width:"100%", backgroundColor:'#ffffff', display:'flex', justifyContent:'space-between', height:'50px', padding:'18px 20px', border:'1px solid #dfdfdf', borderTop:'0'}}>
                <div className=" flex-grow-1">
                    {item.label}
                </div>
                <div>
                    <Space>

                        <a href="#" onClick={e => { e.preventDefault(); this.setState({ isEditing: true }) }}>
                            <i className="far fa-edit"></i>
                        </a>
                        <Popconfirm
                            title= "Bạn có chắc muốn xoá không?"
                            onConfirm={() => { this.props.deleteItem(item.id) }}

                            okText= "Có"
                            cancelText= "Không"
                        >
                            <a href="#" onClick={e => { e.preventDefault() }}>
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        </Popconfirm>

                    </Space>

                </div>

            </div>
        )
    }


    renderEdit() {
        return (
            <div style={{width:"100%", backgroundColor:'#ffffff', padding:'20px 20px', border:'1px solid #dfdfdf', borderTop:'0'}} >
                <Form

                    layout="vertical"
                    ref={this.form}
                    onFinish={this.save}
                    initialValues={this.props.data}

                >
                     <Form.Item label="Tên" name="label" required>
                        <Input placeholder= "name" />
                    </Form.Item>
                    <Form.Item label="ID" name="attribute" required>
                        <Input placeholder="ID" />
                    </Form.Item>
                   
                    {this.props.data.type == "number" &&
                        < Form.Item label= 'format' name="format" >
                            <Input placeholder= 'format' />
                        </Form.Item>
                    }

                    {this.props.data.type == "date" &&
                        < Form.Item label= 'format' name="format" >
                            <Input placeholder= 'format'/>
                        </Form.Item>
                    }

                    {(this.props.data.type == "dropdown" || this.props.data.type == "check" )&&
                        <>
                            <div>
                            Opions
                            </div>
                            <Form.List
                                name="options"

                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <>

                                                <div style={{ display: "flex", maxWidth: 500 }}>
                                                    <div style={{ display: "none" }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'value']}
                                                        >
                                                            <Input placeholder="id" style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </div>
                                                    <div style={{ flex: 1 }}>

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'label']}
                                                        >
                                                            <Input placeholder= 'value' style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(name)}
                                                        />
                                                    </div>



                                                </div>


                                            </>


                                        ))}
                                        <Button
                                            type="dashed"
                                            onClick={() => add({ value: makeid(5), label: "" })}
                                            style={{ width: '60%' }}

                                        >
                                             Add
                                        </Button>

                                    </>
                                )}
                            </Form.List>
                        </>
                    }

                    <Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Space>
                            <Button  onClick ={()=>{this.setState({isEditing:false})}}>Close</Button>
                            <Button type="primary" htmlType="submit"> Save</Button>
                            </Space>
     
                        </div>

                    </Form.Item>
                </Form>
            </div >
        )
    }



}