import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Select, Row, Alert, Space, Result, Progress, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import api from "api";
import numeral from "numeral";

const { Text } = Typography;
function SyncAllReviewModal({ isModalOpen, setIsModalOpen, totalProduct }) {
  const [isSync, setIsSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMess, setErrorMess] = useState();
  const [percent, setPercent] = useState(0);
  const [totalReviewProduct, setTotalReviewProduct] = useState(0);
  const [totalReview, setTotalReview] = useState(0);
  const [productName, setProductName] = useState();
  const [indexReview, setIndexReview] = useState(0);
  const [indexProduct, setIndexProduct] = useState(0);
  const [productPercent, setProductPercent] = useState(parseFloat(numeral(100 / totalProduct).format("0.0")));
  const [filter, setFilter] = useState({ star: [1, 2, 3, 4, 5] });
  const [countReview, setCountReview] = useState(0);
  const [form] = Form.useForm();
  //   const [totalProduct, setTotalProduct] = useState();
  const handleOk = () => {};

  const handleCancel = () => {
    if ((isSync && percent === 100) || !isSync) {
      setIsModalOpen(false);
      setPercent(0);
    }
  };

  const syncAllReview = async () => {
    setIsLoading(true);
    if (totalProduct > 100) {
      var loopTimes = parseInt((totalProduct / 100).toString().split(".")[0]) + 1;
      for (let index = 0; index < loopTimes; index++) {
        await getProductList({ pagging: { skip: index * 100, limit: 100, sort: {} } });
      }
    } else {
      await getProductList({ pagging: { skip: 0, limit: 100, sort: {} } });
    }
  };

  const getProductList = async (pagging) => {
    await api.post("app/shopee/product/getlistproduct", pagging).then(async (res) => {
      if (res.data.length > 0) {
        setIsSync(true);
        for (let index = 0; index < res.data.length; index++) {
          setIndexProduct(index + 1 + (pagging.pagging.skip / 100) * pagging.pagging.limit);
          var item = res.data[index];
          setProductName(item.item_name);
          await getTotalReviewByProduct(item.item_id);
        }
      }
    });
  };

  const getTotalReviewByProduct = async (item_id) => {
    await api
      .post("app/shopee/product/gettotalreview", { shop_id: "", item_id: item_id })
      .then(async (res) => {
        setErrorMess();
        if (res.total > 0) {
          setTotalReviewProduct(res.total);
          setIndexReview(0);
          await getListReview("", item_id, res.total);
        }
      })
      .catch((err) => {
        // setErrorMess(err);
      });
  };

  const getListReview = async (shop_id, item_id, total) => {
    var splitString = (total / 50).toString().split(".");
    var loopTimes = splitString[1] ? parseInt(splitString[0]) + 1 : parseInt(splitString[0]);
    // console.log("loopTimes", loopTimes);
    for (let i = 0; i < loopTimes; i++) {
      //   console.log(i);
      await api
        .post("app/shopee/product/getproductreview", { shop_id: shop_id, item_id: item_id, pagging: { skip: i * 50, limit: 50, sort: {} } })
        .then(async (res) => {
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              //   console.log(50 * i + index);
              const item = res.data[index];
              //   console.log(50 * i + index);
              if (filter.star.includes(item.rating_star)) {
                await syncReview(item, 50 * i + index, item_id);
              }
            }
          }
        })
        .catch((err) => {
          setErrorMess(err);
        });
    }
  };

  const syncReview = async (review, index, item_id, total) => {
    setIndexReview(index + 1);
    await api
      .post("app/shopee/product/syncreview", { review: review, item_id: item_id })
      .then((res) => {
        // console.log(totalReview);
        // setErrorMess();
        // setPercent((((index + 1) / total) * 100).toString().split(".")[0]);
      })
      .catch((err) => {
        setErrorMess(err);
      });
  };

  useEffect(() => {
    if (indexProduct) {
      if (indexProduct === totalProduct) {
        setPercent(100);
      } else {
        setPercent(parseFloat(numeral(indexProduct * productPercent).format("0.0")));
      }
    }
  }, [indexProduct]);

  useEffect(() => {
    if (indexReview) {
      var count = countReview;
      count = count + 1;
      setCountReview(count);
    }
  }, [indexReview]);

  // const syncReviewByProduct = async (item_id) => {
  //   await api.post("app/shopee/product/syncreviewbyproduct", { item_id: item_id }).then(async (res) => {
  //     if (res.data.length > 0) {
  //       for (let index = 0; index < res.data.length; index++) {
  //         var item = res.data[index];
  //         await syncReviewByProduct(item.item_id);
  //         //   getTotalReviewByProduct(item.item_id);
  //       }
  //     }
  //   });
  // };

  return (
    <Modal
      className="sync-modal"
      width={680}
      bodyStyle={{ padding: "48px 96px" }}
      title="Đồng bộ đánh giá shopee"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        isSync
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
    >
      {isSync ? (
        errorMess ? (
          <Result
            status="error"
            title="Đồng bộ thất bại"
            subTitle={`error: ${JSON.stringify(errorMess)}`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : percent === 100 ? (
          <Result
            status="success"
            title="Đồng bộ thành công"
            subTitle={`Có ${countReview} đánh giá được đồng bộ`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Progress type="circle" percent={percent} size="small" />
              <Text>
                Sản phẩm: {productName} {indexProduct}/{totalProduct}
              </Text>
              <Text>
                Đánh giá: {indexReview}/{totalReviewProduct}
              </Text>
            </Space>
          </Row>
        )
      ) : (
        <Space style={{ width: "100%" }} direction="vertical">
          <Alert message="Thông tin" description="Chức năng giúp đưa đánh giá shopee lên mini app" type="info" showIcon />
          <Form
            name="basic"
            form={form}
            // style={{}}
            // bodyStyle={{ padding: 30 }}
            initialValues={{
              filter: filter.star,
            }}
            onFinish={syncAllReview}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="filter"
              // style={{ margin: 12 }}
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn ít nhất 1 điều kiện",
                },
              ]}
            >
              {/* <Input /> */}
              <Select
                mode="multiple"
                onChange={(e) => {
                  setFilter({ star: e });
                }}
                // style={{
                //   width: 120,
                // }}
                // onChange={(e) => {
                //   setType(e);
                // }}
                options={[
                  {
                    value: 1,
                    label: "1 sao",
                  },
                  {
                    value: 2,
                    label: "2 sao",
                  },
                  {
                    value: 3,
                    label: "3 sao",
                  },
                  {
                    value: 4,
                    label: "4 sao",
                  },
                  {
                    value: 5,
                    label: "5 sao",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Row justify={"center"}>
                <Button type="primary" loading={isLoading} icon={!isLoading ? <SyncOutlined /> : ""} htmlType="submit">
                  Đồng bộ sản phẩm
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Space>

        // <Form
        //   name="basic"
        //   initialValues={{
        //     remember: true,
        //   }}
        //   //   onFinish={onFinish}
        //   autoComplete="off"
        // >
        //   <Form.Item name="type" style={{ margin: 12 }}>
        //     <Select
        //       defaultValue="link"
        //       onChange={(e) => {
        //         // setType(e);
        //       }}
        //       options={[
        //         {
        //           value: "link",
        //           label: "Đồng bộ từ link shopee",
        //         },
        //         {
        //           value: "product",
        //           label: "Sản phẩm shopee hiện tại",
        //         },
        //       ]}
        //     />
        //   </Form.Item>

        //   {/* {type === "link" && (
        //     <Form.Item
        //       style={{ margin: 12 }}
        //       name="url"
        //       rules={[
        //         {
        //           required: true,
        //           message: "Vui lòng nhập link!",
        //         },
        //       ]}
        //     >
        //       <TextArea
        //         onChange={(e) => {
        //           if (extractInfo(e.target.value)) {
        //             setUrl(e.target.value);
        //           }
        //         }}
        //         placeholder="Link sản phẩm shopee"
        //         style={{
        //           height: 100,
        //           resize: "none",
        //         }}
        //       />
        //     </Form.Item>
        //   )}
        //   {url && (
        //     <Space direction="vertical" style={{ width: "100%", marginBottom: 12 }}>
        //       <Row justify={"center"}>{`ShopID: ${extractInfo(url).length > 0 ? extractInfo(url)[0] : ""}`}</Row>
        //       <Row justify={"center"}>{`ItemID: ${extractInfo(url).length > 0 ? extractInfo(url)[1] : ""}`}</Row>
        //     </Space>
        //   )} */}
        //   <Form.Item style={{ margin: 0 }}>
        //     <Row justify={"center"}>
        //       <Button type="primary" htmlType="submit" loading={isLoading} icon={!isLoading ? <SyncOutlined /> : ""}>
        //         Đồng bộ sản phẩm
        //       </Button>
        //     </Row>
        //   </Form.Item>
        // </Form>
      )}
    </Modal>
  );
}

export default SyncAllReviewModal;
