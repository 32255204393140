import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import api from "api";

function AddEditLocation({ isModalOpen, setIsModalOpen, mode, record }) {
  const [form] = Form.useForm();
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistricList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const handleOk = () => {
    form
      .validateFields(["name", "address", "province_code", "open_at", "phone"])
      .then((res) => {
        var dataPost = form.getFieldsValue();
        var findProvince = provinceList.find((item) => {
          return item.ID === dataPost.province_code;
        });
        dataPost.province = `${findProvince.Type} ${findProvince.Name}`;
        if (dataPost.district_code) {
          var findDistrict = districtList.find((item) => {
            return item.ID === dataPost.district_code;
          });

          dataPost.district = `${findDistrict.Type} ${findDistrict.Name}`;
        }
        if (dataPost.ward_code) {
          var findWard = wardList.find((item) => {
            return item.ID === dataPost.ward_code;
          });

          dataPost.ward = `${findWard.Type} ${findWard.Name}`;
        }

        if (mode === "edit") {
          dataPost.id = record.id;
        }

        api
          .post("location_service/save", dataPost)
          .then((res) => {
            message.success("Lưu chi nhánh thành công");
            setIsModalOpen(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  console.log(record);
  useEffect(() => {
    getProvinceList();
  }, []);

  useEffect(() => {
    if (record) {
      onChangeProvince(record.province_code);
      onChangeDistrict(record.district_code);
    }
  }, [record]);

  const onChangeProvince = (provinceId) => {
    api
      .post("guestlogin/district", { provinceId: provinceId })
      .then((res) => {
        setDistricList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeDistrict = (districtId) => {
    api
      .post("guestlogin/ward", { districtId: districtId })
      .then((res) => {
        setWardList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProvinceList = () => {
    api.post("guestlogin/province").then((res) => {
      setProvinceList(res);
    });
  };
  return (
    <Modal width={750} title={mode === "add" ? "Thêm mới chi nhánh" : "Chỉnh sửa chi nhánh"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" initialValues={record ? record : { open_at: "07:00 - 22:00" }}>
        <Form.Item
          name={"name"}
          label="Tên chi nhánh"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên chi nhánh",
            },
          ]}
        >
          <Input placeholder="Tên chi nhánh" />
        </Form.Item>

        <Form.Item
          name={"address"}
          label="Địa chỉ"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập địa chỉ",
            },
          ]}
        >
          <Input placeholder="Địa chỉ" />
        </Form.Item>
        <Row style={{ width: "100%", margin: 0 }}>
          <Col style={{ width: "calc((100% - 16px) / 3)", marginRight: 8 }}>
            <Form.Item
              name="province_code"
              label="Tỉnh thành"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn tỉnh thành",
                },
              ]}
              style={{ marginInlineEnd: 0 }}
            >
              <Select
                onChange={onChangeProvince}
                placeholder="Tỉnh thành"
                options={
                  provinceList.length > 0
                    ? provinceList.map((item) => {
                        return { value: item.ID, label: item.Name };
                      })
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col style={{ width: "calc((100% - 16px) / 3)", marginRight: 8 }}>
            <Form.Item name="district_code" label="Quận Huyện" style={{ marginInlineEnd: 0 }}>
              <Select
                onChange={onChangeDistrict}
                placeholder="Quận Huyện"
                options={
                  districtList.length > 0
                    ? districtList.map((item) => {
                        return { value: item.ID, label: item.Name };
                      })
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col style={{ width: "calc((100% - 16px) / 3)" }}>
            <Form.Item name="ward_code" label="Phường Xã" style={{ marginInlineEnd: 0 }}>
              <Select
                // onChange={onChangeDistrict}
                placeholder="Phường Xã"
                options={
                  wardList.length > 0
                    ? wardList.map((item) => {
                        return { value: item.ID, label: item.Name };
                      })
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "calc((100% - 8px) / 2)", marginRight: 8 }}>
            <Form.Item
              name={"open_at"}
              label="Thời gian hoạt động"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thời gian hoạt động",
                },
              ]}
            >
              <Input placeholder="Thời gian hoạt động" />
            </Form.Item>
          </Col>

          <Col style={{ width: "calc((100% - 8px) / 2)" }}>
            <Form.Item
              name={"phone"}
              label="Hotline"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Hotline",
                },
              ]}
            >
              <Input placeholder="Hotline" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddEditLocation;
