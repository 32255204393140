import React, { useState } from "react";
import { Modal, Button, Form, Input, Steps, Table, Checkbox, message } from "antd";
import api from "api";
import CoppyCol from "./coppy_col";

export default class PoupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.dataSource = [
      {
        type: "TXT",
        host: "@",
        value: "v=spf1 ip4:14.225.8.0/24 -all",
      },
      {
        type: "TXT",
        host: "dkim._domainkey",
        value:
          "v=DKIM1; h=sha256; k=rsa; t=y; p=MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvedm9/Xxf9yYH8tZPHehVslQXQYqxvC/jeCufuICjCc7l4vf5vNGSonlCEExVwMYcJMw90XKTYIE9gsoMc6eu6jcuTjBdJ1E10/frw7FeG0NoBrmaN4IYcVvbZqbDHIXXM2z9bYNzPvFddKB6nojdvyHRHJ2IvzG/wWMpcWHPmImpwfER1CqVpiyfVsmbKJnZscMcBQtGT0F920LFy4YID7hIq0lK/Mii2JP12Gv4B4SPPRJF4Q4x9BqJO5fEHAYnvQkKreHobaivSPs/tGjKXYsDSWntI44uccYHJrXcHrbm88Ey0T7B4mdIqXd0AOmyDmavIswjNRmddyLXhUHkQIDAQAB",
      },
    ];
    this.state = { isVisible: false, current: 0, data: {}, added: false, loadingBtn: false, domain: "" };
    this.form = React.createRef();
  }

  open = (id) => {
    if (id === "" || id == null) {
      var data = {
        verify: false,
        domain: "",
        account: [],
      };
      this.setState({ data: data, isVisible: true }, () => {
        this.form.current.resetFields();
        this.form.current.setFieldsValue(data);
      });
    } else {
      api.post("app/defaultemail/config/getconfigbyid", { id: id }).then((res) => {
        this.setState({ data: res, isVisible: true }, () => this.form.current.setFieldsValue(res));
      });
    }
  };

  handleCancel = () => {
    this.setState({ isVisible: false });
  };

  save = (values) => {
    api
      .post("app/defaultemail/config/save", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
        this.props.reload();
        this.setState({ loadingBtn: false, isVisible: false });
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        this.setState({ loadingBtn: false });
      });
  };

  onCheckboxChange = (value) => {
    this.setState({ added: value });
  };

  render() {
    var cols = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Host",
        dataIndex: "host",
        key: "host",
        render: (value) => {
          return (
            <div>
              <CoppyCol value={value}></CoppyCol>
            </div>
          );
        },
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (value) => {
          return (
            <div>
              <CoppyCol value={value}></CoppyCol>
            </div>
          );
        },
      },
    ];
    return (
      <>
        <Modal
          title="Thêm domain mới"
          width={"80%"}
          open={this.state.isVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                this.handleCancel();
              }}
            >
              Thoát
            </Button>,

            <Button
              type="primary"
              loading={this.state.loadingBtn ? true : false}
              onClick={() => {
                this.setState({ loadingBtn: true }, () => {
                  this.form.current.submit();
                  this.setState({ loadingBtn: false });
                });
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form layout="vertical" ref={this.form} onFinish={this.save}>
            <Form.Item
              name="domain"
              label="Domain"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập domain!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="verify" hidden={true}></Form.Item>
            <Form.Item name="id" hidden={true}></Form.Item>
            <Form.Item name="account" hidden={true}></Form.Item>
            <div style={{ marginTop: 30 }}>
              <span style={{ color: "red" }}>*</span>&nbsp; Cấu hình domain của bạn như sau:
              <Table columns={cols} dataSource={this.dataSource} style={{ width: "100%" }} pagination={false}></Table>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
