import React, { Component } from "react";
import "./style.css";
import { init, setOption } from "../game";

export default class PopupNotificationReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      reward: {},
    };
  }

  open = (reward) => {
    this.setState({ visible: true, reward: reward });
  };

  close = () => {
    setOption({ canSpin: true });
    this.setState({ visible: false });
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 90,
        }}
      >
        <div
          hidden={!this.state.visible}
          className="absolute-center"
          style={{
            width: 340,
            height: 458,
            position: "absolute",
            padding: 10,
            backgroundImage: `url("https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/vienpopup.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "transparent",
            color: "#992500",
            zIndex: 100,
          }}
        >
          <div
            style={{
              backgroundImage: `url("https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/nenpopup.png")`,
              width: "100%",
              height: "100%",
              borderRadius: 20,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div style={{ paddingTop: 80 }}>
              <div style={{ paddingTop: 10, display: "flex" }}>
                {this.state.reward.type == "point" ? (
                  <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/phongthu.png" style={{ margin: "auto" }} />
                ) : (
                  <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/iconvoucher.png" style={{ margin: "auto" }} />
                )}
              </div>
              <div style={{ paddingTop: 15, display: "flex" }}>
                <p style={{ margin: "auto", fontSize: 27, fontWeight: "bold" }}>{this.state.reward.type == "point" ? `+${this.state.reward.point} Điểm` : `Voucher giảm giá`}</p>
              </div>
            </div>
            <div id="scroll-beauty" style={{ paddingTop: 10, fontSize: 15, fontWeight: 650, maxHeight: 135, overflowY: "scroll" }}>
              <ul style={{ listStyleImage: `URL('https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/ngoisao.png')` }}>
                {this.state.reward.type == "voucher" && (
                  <>
                    <li style={{ margin: 10 }}>Mã voucher: FSDGDF</li>
                    <li style={{ margin: 10 }}>Ngày hết hạn: 15/10/2022</li>
                  </>
                )}
                <li style={{ margin: 10 }}>Hạt giống may mắn giúp bạn cảm thấy hạnh phúc và thoải mái</li>
              </ul>
            </div>
            <div style={{ paddingTop: 10, fontSize: 15, fontWeight: 650, display: "flex", justifyContent: "space-around" }}>
              <div>
                <div
                  style={{ width: 127, height: 40, backgroundColor: "#FEE9C7", border: "1px solid #992500", borderRadius: 20, fontSize: 15, fontWeight: 700, display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    this.close();
                  }}
                >
                  <p style={{ margin: "auto" }}>Trữ vào kho</p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    width: 127,
                    height: 40,
                    background: "linear-gradient(180deg, #FB7E0C 0%, #E75117 100%)",
                    borderRadius: 20,
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.close();
                  }}
                >
                  <p style={{ margin: "auto" }}>Gieo ngay</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", top: -50, left: -17 }}>
            <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/mai_nha.png" />
          </div>
        </div>
      </div>
    );
  }
}
