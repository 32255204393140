import React, { Component } from "react";
import { Button, List, Row, Col, Divider, Card, Empty, Typography } from "antd";
import PopupCreatePointProgram from "./popupcreatepointprogram";
import api from "api";
import { handleTemplateDescription } from "../../common/text.js";
import PopupCreateExchangPointProgram from "./exchange_point_program/popupcreateexchangepointprogram";
import Point from "./points";
import Expiry from "./expiry";
import PopupCreateGameProgram from "./gameprogram/popupcreategameprogram";

export default class PointProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointMethodData: [],

      showProgram: false,
    };
    this.PopupCreatePointProgram = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var res = await api.post("pointprogram/getlatestconfigdetails");
    if (res != null) {
      res.map((config) => {
        if (config.reward != null) {
          var reward = config.reward;
          if (reward.id != null && reward.id != "") {
            config = handleTemplateDescription(config);
          }
        }
        return config;
      });
      this.setState({ pointMethodData: res });
    }
  };

  navigateEdit = (item) => {
    var href = "";
    api.post("pointprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = res.config_url + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };

  render() {
    return (
      <div>
        <div className="heading-1">Chương Trình Tích Điểm</div>
        <Row>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Tích điểm
            </div>
            <div className="description">
              Tạo ra những phương thức giúp khách hàng tích điểm
              <br></br> khi họ mua hàng, tham gia các hoạt động like, share <br></br> hoặc tương tác với thương hiệu
            </div>
            <br></br>
            <Button
              type="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                this.PopupCreatePointProgram.current.open();
              }}
            >
              Tạo Hình Thức Tích Điểm
            </Button>
          </Col>
          <Col span={14}>
            {this.state.show && (this.state.pointMethodData == null || this.state.pointMethodData.length === 0) ? (
              <Card title={<h5 style={{ marginTop: 10 }}>{"Cách thức tích điểm"}</h5>} style={{ marginLeft: 20 }} bodyStyle={{ height: "25vh" }}>
                <div>
                  <Empty description="Hãy tạo mới chương trình đầu tiên"></Empty>
                  <Row justify="center">
                    <Button
                      onClick={() => {
                        this.PopupCreatePointProgram.current.open();
                      }}
                      style={{ marginTop: 20 }}
                      type="primary"
                    >
                      <i className="fas fa-plus"></i>&nbsp;Tạo mới
                    </Button>
                  </Row>
                </div>
              </Card>
            ) : (
              <Card
                bodyStyle={{ paddingTop: 12, paddingBottom: 12 }}
                title={
                  <Typography.Title level={3} style={{ marginTop: 8 }}>
                    Cách thức tích điểm
                  </Typography.Title>
                }
                extra={
                  <Button
                    type="link"
                    href="/marketing/program_config/list"
                    onClick={() => {
                      // window.href = ;
                    }}
                  >
                    Xem tất cả
                  </Button>
                }
              >
                <List
                  style={{ background: "white" }}
                  // header={
                  //   <div style={{ marginBottom: 10 }}>
                  //     <h2>{"Cách thức tích điểm"}</h2>
                  //     <a href="/marketing/program_config/list" className="view-all">
                  //       Xem Tất Cả
                  //     </a>
                  //   </div>
                  // }
                  dataSource={this.state.pointMethodData}
                  renderItem={(item) => {
                    var href = item.config_url + item.type + "/" + item.id;
                    return (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={<img src={item.logo} style={{ width: 70, height: 70 }} />}
                          title={<div className="listTitle">{item.name}</div>}
                          description={<div className="description">{item.display_short_description}</div>}
                        />
                        <a
                          onClick={() => {
                            this.navigateEdit(item);
                          }}
                          className="edit-link"
                        >
                          Chỉnh sửa
                        </a>
                      </List.Item>
                    );
                  }}
                ></List>
              </Card>
            )}
          </Col>
        </Row>

        <PopupCreatePointProgram ref={this.PopupCreatePointProgram}></PopupCreatePointProgram>
      </div>
    );
  }
}
