import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Select, Space, Typography, Row, Button } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;
function AddWebhookModal({ isModalOpen, setIsModalOpen, onSave, webhookList, mode }) {
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = useState(false);
  const [typeSelected, setTypeSelected] = useState();
  const [queryParameters, setQueryParameters] = useState([0]);
  const [headers, setHeaders] = useState([0]);
  const handleOk = () => {
    form
      .validateFields(["type", "url"])
      .then((res) => {
        var data = form.getFieldsValue();
        if (data.type === "trustsales" || data.type === "trustsales_sandbox" || data.type === "mshopkeeper") {
          delete data.headers;
          delete data.params;
        } else {
          data.headers = data.headers.filter((item) => {
            return item.key && item.value;
          });
          data.params = data.params.filter((item) => {
            return item.key && item.value;
          });
        }
        onSave(data);
        setIsModalOpen(false);
      })
      .catch((err) => {});
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeSelect = (value) => {
    setTypeSelected(value);
    if (value === "trustsales") {
      form.setFieldValue("url", "https://trustsales.vn/api/orders/createorder");
      setIsDisable(true);
    } else if (value === "trustsales_sandbox") {
      form.setFieldValue("url", "https://sanbox.trustsales.vn/api/orders/createorder");
      setIsDisable(false);
    } else if (value === "mshopkeeper") {
      form.setFieldValue("url", "https://graphapi.mshopkeeper.vn/g2/api/v1/invoices");
      setIsDisable(true);
    } else {
      form.resetFields(["url"]);
      setIsDisable(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields(["type", "url"]);
    } else {
      setTypeSelected();
    }
  }, [isModalOpen]);

  const deleteRecord = (index, type) => {
    if (type === "params") {
      var newList = [...queryParameters];
      if (index + 1 !== queryParameters.length) {
        for (let i = 0; i < newList.length - 1 - index; i++) {
          var key = form.getFieldValue([type, index + 1 + i, "key"]);
          var value = form.getFieldValue([type, index + 1 + i, "value"]);
          form.setFieldValue([type, index + i, "key"], key);
          form.setFieldValue([type, index + i, "value"], value);
          form.resetFields([
            [type, index + 1 + i, "key"],
            [type, index + 1 + i, "value"],
          ]);
        }
      }
      newList.pop();
      setQueryParameters(newList);
    } else {
      var newList = [...headers];
      if (index + 1 !== headers.length) {
        for (let i = 0; i < newList.length - 1 - index; i++) {
          var key = form.getFieldValue([type, index + 1 + i, "key"]);
          var value = form.getFieldValue([type, index + 1 + i, "value"]);
          form.setFieldValue([type, index + i, "key"], key);
          form.setFieldValue([type, index + i, "value"], value);
        }
      }
      newList.pop();
      setHeaders(newList);
    }
  };

  const AddRecord = (type) => {
    if (type === "params") {
      var newList = [...queryParameters];
      newList.push(queryParameters.length);
      // if (type === "params") {
      setQueryParameters(newList);
      // } else {
      //   setHeaders(newList);
      // }
    } else {
      var newHeaders = [...headers];
      newHeaders.push(headers.length);
      setHeaders(newHeaders);
    }
  };
  return (
    <Modal title="Thêm webhook" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Lưu" cancelText="Thoát">
      <Form form={form} layout="vertical">
        <Form.Item
          name="type"
          label="Loại"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại",
            },
          ]}
        >
          <Select
            placeholder="Loại"
            onChange={onChangeSelect}
            options={
              mode === "order"
                ? [
                    { value: "trustsales", label: "TRUSTSALES" },
                    { value: "trustsales_sandbox", label: "TRUSTSALES SANDBOX" },
                    { value: "webhook_url", label: "WEBHOOK URL" },
                    { value: "mshopkeeper", label: "MISAeSHOP" },
                  ]
                : [{ value: "webhook_url", label: "WEBHOOK URL" }]
            }
          />
        </Form.Item>
        <Form.Item
          name="url"
          label="Webhook Url"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập vào URL",
            },
            {
              message: "Webhook url đã tồn tại",
              validator: (_, value) => {
                if (value) {
                  if (webhookList.length > 0) {
                    if (
                      webhookList
                        .map((w) => {
                          return w.url;
                        })
                        .includes(value)
                    ) {
                      return Promise.reject("Some message here");
                    }
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Webhook Url" disabled={isDisable} />
        </Form.Item>
        {typeSelected === "webhook_url" && (
          <>
            <Form.Item label="Query Parameters">
              <Space direction="vertical" style={{ width: "100%" }}>
                {queryParameters.map((param) => {
                  return (
                    <Row style={{ width: "100%" }} align={"middle"}>
                      <Form.Item name={["params", param, "key"]} style={{ width: "calc(50% - 30px)", marginBottom: 0 }}>
                        <Input placeholder="Key" />
                      </Form.Item>
                      <Text style={{ marginInline: 8 }}>:</Text>
                      <Form.Item name={["params", param, "value"]} style={{ width: "calc(50% - 30px)", marginBottom: 0 }}>
                        <Input placeholder="Value" />
                      </Form.Item>
                      {param > 0 && (
                        <Button
                          style={{ marginLeft: 8 }}
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            deleteRecord(param, "params");
                          }}
                        ></Button>
                      )}
                    </Row>
                  );
                })}
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    AddRecord("params");
                  }}
                >
                  Thêm
                </Button>
              </Space>
            </Form.Item>

            <Form.Item label="Headers">
              <Space direction="vertical" style={{ width: "100%" }}>
                {headers.map((header) => {
                  return (
                    <Row style={{ width: "100%" }} align={"middle"}>
                      <Form.Item name={["headers", header, "key"]} style={{ width: "calc(50% - 30px)", marginBottom: 0 }}>
                        <Input placeholder="Key" />
                      </Form.Item>
                      <Text style={{ marginInline: 8 }}>:</Text>
                      <Form.Item name={["headers", header, "value"]} style={{ width: "calc(50% - 30px)", marginBottom: 0 }}>
                        <Input placeholder="Value" />
                      </Form.Item>
                      {header > 0 && (
                        <Button
                          style={{ marginLeft: 8 }}
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            deleteRecord(header, "headers");
                          }}
                        ></Button>
                      )}
                    </Row>
                  );
                })}
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    AddRecord("headers");
                  }}
                >
                  Thêm
                </Button>
              </Space>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}

export default AddWebhookModal;
