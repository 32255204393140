import React, { Component, useState } from "react";
import { Button, Row, Col, Switch, Empty, List, message } from "antd";
import api from "api";
import EditNotification from "./edit_notifications";
import Form from "rc-field-form/es/Form";

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: false,
      lstGroup: [],
      date: false,
      isModalOpen: false,
      event: null,
    };
    // this.EditNotification = React.createRef();
  }

  onChangeSwitch = async (event, checked) => {
    api
      .post("customer_notifications/enable", { event: event, checked: checked })
      .then((res) => {
        if (checked) {
          message.success("Kích hoạt thành công");
        } else {
          message.success("Tắt thành công");
        }
        this.getData();
      })
      .catch((err) => {
        if (checked) {
          message.error("Kích hoạt thất bại");
        } else {
          message.error("Tắt thất bại");
        }
      });
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    // console.log("test");
    var res = await api.post("customer_notifications/list");
    this.setState({
      lstGroup: res,
    });
  };

  openModal(event) {
    this.setState({
      event: event,
      isModalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    // const [isModalOpen, setIsModalOpen] = useState(false)
    return (
      <>
        <div style={{ fontSize: 24, fontWeight: "bold" }}>THÔNG BÁO KHÁCH HÀNG</div>
        <Row>
          <Col span={8}>
            <div style={{ marginTop: 20 }}>
              Các sự kiện mà khách hàng nhận được thông báo
              <br></br> thông qua các mạng xã hội như: <br></br> Zalo, FaceBook, Email,...
            </div>
          </Col>
          <Col span={16}>
            {this.state.lstGroup.map((element) => {
              return (
                <List
                  style={{ background: "white", padding: "15px 20px", marginBottom: 20 }}
                  dataSource={element.lst}
                  header={
                    <div>
                      <h2>{element.name}</h2>
                    </div>
                  }
                  renderItem={(item) => {
                    return (
                      <>
                        <List.Item key={item.id}>
                          <List.Item.Meta title={<div>{item.name}</div>} description={<div>{item.description}</div>} />
                          <Switch
                            checked={item.enable}
                            defaultChecked
                            onChange={(checked) => this.onChangeSwitch(item.event, checked)}
                            style={{ marginRight: 30 }}
                          />
                          <a onClick={() => this.openModal(item.event)}>Chỉnh sửa</a>
                        </List.Item>
                      </>
                    );
                  }}
                ></List>
              );
            })}
          </Col>
        </Row>
        <EditNotification
          // ref={this.EditNotification}
          reload={this.getData}
          isModalOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
          event={this.state.event}
        ></EditNotification>
      </>
    );
  }
}
