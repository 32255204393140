import { Badge, Button, Card, Col, Row, Space, Tabs, Tag, Typography } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import ConnectTab from "./ConnectTab";
import ProductTab from "./ProductTab";
import "./styles.css";
import api from "api";

const { Text, Title } = Typography;
function KiotVietSalonConfig() {
  const [isConnected, setIsConnected] = useState(false);
  const [totalProduct, setTotalProduct] = useState();
  const [isActive, setIsActive] = useState(true);
  const [tabItems, setTabItems] = useState([
    {
      key: "connect",
      label: "Kết nối",
      children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
    },
  ]);

  // useEffect(() => {
  //   console.log("isConnected",isConnected);
  //   if (isConnected) {
  //     getTotalOrderInfo();
  //   } 
  // }, [isConnected]);

  // const getTotalOrderInfo = () => {
  //   api
  //     .post("app/kiotvietsalon/config/getallkiotsalonorder")
  //     .then((res) => {
  //       console.log('getkiotorderinfo',res)
  //     })
  //     .catch((err) => {
  //       console.log('getkiotorderinfo-erro',err)
  //     });
  // };

  useEffect(() => {
    console.log(isActive);
    if (isActive) {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        }
      ]);
    } else {
      setTabItems([
        {
          key: "connect",
          label: "Kết nối",
          children: <ConnectTab isConnected={isConnected} setIsConnected={setIsConnected} isActive={isActive} setIsActive={setIsActive} />,
        },
      ]);
    }
  }, [isActive]);



  return (
    <Card
      title={
        <Row gutter={16} style={{ padding: "16px 0px" }}>
          <Col>
            <div
              style={{
                width: 223,
                height: 48,
                backgroundImage: `url(/image/kiotvietsalonlogo.png)`,
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}
            ></div>
          </Col>
          <Col>
            <Row>
              <Title style={{ margin: 0 }} level={5}>
                KIOTVIET SALON
              </Title>
            </Row>
            <Row>
              <Text type="secondary">Đồng bộ thông tin khách hàng , đơn hàng</Text>
            </Row>
          </Col>
        </Row>
      }
      //   extra={<a href="#">More</a>}
      style={{
        width: "100%",
        minHeight: "calc(100vh - 118px)",
      }}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Card>
  );
}

export default KiotVietSalonConfig;
