import { Badge, Button, Col, DatePicker, Dropdown, Form, Input, message, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs";
import api from "api";
import AddEditLocation from "./AddEditLocation";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;
function Location() {
  const [form] = Form.useForm();
  const [dataPost, setDataPost] = useState();
  const Tb = createRef();
  const [locationList, setLocationList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [mode, setMode] = useState();
  const getColumn = () => {
    var columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "id",
        // sorter: true,
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: "Tên chi nhánh",
        dataIndex: "name",
        key: "name",

        render: (text) => {
          if (text) {
            return text.replace("null", "").replace("undefined", "").trim();
          }
        },
      },
      {
        title: "Hotline",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Giờ mở cửa",
        dataIndex: "open_at",
        key: "open_at",
        // render: (text) => {
        //   return moment(text).format("DD/MM/YYYY HH:mm");
        // },
      },
      {
        title: "Địa chỉ",
        dataIndex: "address",
        key: "address",
        render: (text, record) => {
          var address = "";
          if (text) {
            address += text;
          }

          if (record.ward) {
            address += `, ${record.ward}`;
          }

          if (record.district) {
            address += `, ${record.district}`;
          }

          if (record.province) {
            address += `, ${record.province}`;
          }

          return address;
        },
      },

      {
        dataIndex: "id",
        key: "id",
        render: (id, record, index) => {
          // var findItem = linkList.find((item) => {
          //   return item.woo_product_id === record.id;
          // });
          return (
            <Dropdown
              trigger={"click"}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Chỉnh sửa",
                  },
                  {
                    key: "2",
                    label: "Xoá",
                  },
                ],
                onClick: (e) => {
                  if (e.key === "1") {
                    setMode("edit");
                    setSelectedRecord(record);
                    setIsModalOpen(true);
                  } else {
                    confirm({
                      title: "Bạn có muốn xoá chi nhánh?",
                      icon: <ExclamationCircleFilled />,
                      content: record.name,
                      okText: "Đồng ý",
                      cancelText: "Thoát",
                      onOk() {
                        api
                          .post("location_service/save", { id: record.id, is_deleted: true })
                          .then((res) => {
                            message.error("Xoá chi nhánh thành công");
                            Tb.current.reload();
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      },
                    });
                  }
                },
              }}
              placement="bottomLeft"
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];
    return columns;
  };

  const onSearch = () => {
    var newDataPost = form.getFieldsValue();

    setDataPost(newDataPost);
  };

  useEffect(() => {
    if (!dataPost || dataPost || !isModalOpen) {
      Tb.current.reload();
    }
  }, [dataPost, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedRecord(null);
    }
  }, [isModalOpen]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div style={{ background: "white", borderRadius: 8 }}>
        <Form layout={"inline"} form={form} style={{ padding: 8 }}>
          <Form.Item name="text" style={{ marginInlineEnd: 16, width: "calc(100% - 216px)" }}>
            <Input
              placeholder="Tìm theo tên showroom hoặc số điện thoại"
              onBlur={onSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={onSearch}>
              Tìm kiếm
            </Button>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              onClick={() => {
                setMode("add");
                setSelectedRecord();
                setIsModalOpen(true);
              }}
            >
              Thêm mới
            </Button>
          </Form.Item>
        </Form>

        <AntTable
          columns={getColumn()}
          customSearch={dataPost}
          tableName={"tbReview"}
          ajax="location_service/list"
          ref={Tb}
          rowKey={(record) => record.id}
        ></AntTable>
      </div>
      {isModalOpen && <AddEditLocation isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} record={selectedRecord} mode={mode} />}
    </Space>
  );
}

export default Location;
