import React, { Component } from "react";
import { Button, Input, Form, message, Modal, Col, Row, Select, Checkbox } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import api from "api";
import { toNoUnicode } from "common/text";
import SelectNew from "components/picker/dropdown_picker";
import { phone_regex } from "common/regex";

export default class AddNewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "edit",
      visible: false,
      roles: [],
      data: {
        user_name: "",
      },
      existed_username: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {}

  getRoles = () => {
    if (this.state.roles.length == 0)
      api.post("user/listroles").then((resp) => {
        if (resp != null) this.setState({ roles: resp });
        else message.error("Có lỗi xảy ra");
      });
  };

  open = async (record) => {
    await this.getRoles();
    if (record.user_name != 0 && record.user_name != null) {
      this.setState(
        {
          visible: true,
          mode: "edit",
          data: record,
        },
        () => {
          this.form.current.resetFields();
          this.form.current.setFieldsValue(record);
        }
      );
    } else {
      this.setState(
        {
          visible: true,
          mode: "add",
        },
        () => {
          this.form.current.resetFields();
          this.form.current.setFieldsValue({ is_active: true });
        }
      );
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onChangeUsername = (text) => {
    var user_name = toNoUnicode(text).toLowerCase();
    if (this.form.current) this.form.current.setFieldsValue({ user_name: user_name });
    api.post("user/getbyusername", { user_name: user_name }).then((resp) => {
      if (resp.existed) this.setState({ existed_username: true });
      else this.setState({ existed_username: false });
    });
  };

  onFinish = (values) => {
    api
      .post("user/save", { data: values })
      .then((resp) => {
        message.success("Lưu tài khoản thành công");
        this.close();
        if (this.props.getData) this.props.getData();
      })
      .catch((error) => {
        message.error("Có lỗi xảy ra khi lưu");
      });
  };

  close = () => {
    this.setState({ visible: false });
  };

  onSubmit = () => {
    if (this.form.current) this.form.current.submit();
  };

  render() {
    var user_name = this.state.data.user_name;
    var style = {};
    var disabled = false;
    var title = "Thêm mới tài khoản";
    var roles = [];
    if (user_name == null) user_name = "";

    if (this.state.roles != null)
      if (this.state.roles.length != 0) {
        roles = [...this.state.roles];
      }

    if (this.state.mode == "edit") {
      title = "Chỉnh sửa tài khoản " + user_name;
      disabled = true;
    }

    return (
      <Modal
        title={title}
        open={this.state.visible}
        onCancel={this.handleCancel}
        bodyStyle={style}
        maskClosable={false}
        width={"50%"}
        footer={[
          <>
            <Button style={{ marginRight: 10 }} type="default" onClick={this.handleCancel}>
              <i className="fas fa-window-close" style={{ marginRight: 10 }}></i> Hủy
            </Button>
            <Button onClick={() => this.onSubmit()} style={{ marginRight: 10 }} type="primary">
              <i className="fas fa-save" style={{ marginRight: 10 }}></i> Lưu
            </Button>
          </>,
        ]}
      >
        <Form layout="vertical" onFinish={this.onFinish} id="user-form" ref={this.form}>
          <Row>
            <Form.Item hidden name={"id"} label={"id"} rules={[{ type: "string" }]}></Form.Item>
            <Form.Item
              required
              name={"user_name"}
              label={"Tên đăng nhập (viết liền, không dấu)"}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Tên đăng nhập là trường bắt buộc!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input disabled={disabled} onChange={(e) => this.onChangeUsername(e.target.value)} placeholder="tendangnhap"></Input>
            </Form.Item>
            {this.state.existed_username ? <p style={{ marginTop: -20, color: "#FF0000" }}>Tên đăng nhập đã tồn tại!</p> : <></>}
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={"last_name"} label={"Họ"} style={{ width: "95%" }}>
                <Input placeholder="Họ"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"first_name"} label={"Tên"} style={{ width: "100%" }}>
                <Input placeholder="Tên"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name={"role"}
                label="Phân quyền"
                style={{ width: "95%" }}
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Vui lòng chọn phân quyền!",
                  },
                ]}
              >
                <SelectNew options={roles} valCol={"id"} displayCol={"name"}></SelectNew>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"phone"}
                label="Số điện thoại"
                validateTrigger={"onBlur"}
                rules={[
                  {
                    pattern: phone_regex,
                    message: "Số điện thoại không hợp lệ!",
                  },
                ]}
              >
                <Input placeholder="0123456789"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item name={"address"} label="Địa chỉ" style={{ width: "100%" }}>
              <Input placeholder="Nhập địa chỉ"></Input>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={"is_active"} style={{ width: "100%" }} valuePropName="checked">
              <Checkbox>Kích hoạt</Checkbox>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
}
