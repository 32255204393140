import React, { Component } from "react";
import { DatePicker } from 'antd';
import moment, { invalid } from "moment";

export default class DateTimePicker extends Component {
    constructor(props) {
        super(props);

    }



    onChange = (value) => {
        if (this.props.onChange)
            this.props.onChange(value);
    }



    render() {
        var vl = "";

        if (this.props.value != null && this.props.value != "") {

            vl = moment(this.props.value);

        }



        return (
            <DatePicker
                {...this.props}
                minuteStep={15}
                onSelect={this.onChange}
                value={vl}
                format="DD/MM/YYYY HH:mm"
                style={{ width: "100%" }}
                showTime
            />

        )
    }
}