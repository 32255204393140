import { memo, useState, useEffect } from "react";
import { Button, Modal, Steps, Card, Row, Col, Image, Form, Radio, Space, Typography} from "antd";
import { LoadingOutlined} from "@ant-design/icons";
import "./modal.css";
import api from "api";
import { blue } from "@ant-design/colors";
import DetailTransaction from "./transaction_management/DetailTransaction";
const { Title, Text } = Typography;
// import "../../assets/images";
// const { token } = theme.useToken();

const { Step } = Steps;
var numeral = require("numeral");

/**
 * @author Nhat Anh
 */

function ModalPayment(props) {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(props.mode === "add" ? 0 : 1);
  const [listBank, setListBank] = useState([]);
  const [selectedBank, setSelectedBank] = useState();
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const handleOk = () => {
    setAmount(form.getFieldValue("amount"));
    savePayment(form.getFieldValue("amount"));
  };
  const handleCancel = () => {
    props.reloadTable();
    props.setIsModalOpen(false);
  };

  useEffect(() => {
    if (props.mode === "add") {
      if (listBank.length > 0) {
        setSelectedBank(listBank[0]);
        form.setFieldsValue({
          amount: 500000,
        });
      }
      setCurrentStep(0);
    } else {
      if (listBank.length > 0) {
        var find = listBank.find((item) => item.bank_name === props.record.bank);
        setSelectedBank(find);
      }
      setData(props.record);
      setCurrentStep(1);
    }
  }, [props.isModalOpen, listBank]);

  useEffect(() => {
    getListBank();
  }, []);

  const getListBank = async () => {
    await api
      .get("bank/getbank")
      .then((res) => {
        setListBank(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectBank = (item) => {
    setSelectedBank(item);
  };

  const savePayment = async (amount) => {
    setIsLoading(true);
    var dataPost = { amount: amount, bank: selectedBank.bank_name, description: "Nạp tiền vào tài khoản" };
    await api
      .post("payment/create_transaction", dataPost)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        wrapClassName="payment-modal"
        width={"72vw"}
        title="Tạo thanh toán"
        open={props.isModalOpen}
        onCancel={handleCancel}
        centered={true}
        footer={
          currentStep > 0
            ? [<Button onClick={handleCancel}>Thoát</Button>]
            : [
                <Button type="primary" onClick={handleOk}>
                  Tiếp tục
                </Button>,
              ]
        }
      >
        <div style={stepsStyle}>
          <Steps size="small" current={currentStep} style={{ padding: "0 24px" }}>
            <Step key="step1" title="Chọn thanh toán" icon={isLoading ? <LoadingOutlined /> : null} />
            <Step key="step2" title="Hướng dẫn thanh toán" />
          </Steps>
        </div>
        {currentStep === 0 && (
          <div style={contentStyle}>
            <Card style={{ width: "100%" }}>
              <Row>
                {listBank.length > 0 &&
                  listBank.map((item) => {
                    var src = `/assets/images/${item.bank_name.toLowerCase()}.svg`;
                    return (
                      <Col span={24} style={{ padding: 4 }} sm={12} lg={8}>
                        <Card
                          style={{ padding: 8, borderColor: item.id === selectedBank?.id ? blue.primary : null }}
                          hoverable
                          className="card-bank"
                          onClick={() => {
                            selectBank(item);
                          }}
                        >
                          <Image preview={false} width="100%" src={src} />
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              <Form form={form} style={{ padding: 8 }}>
                <Form.Item name="amount">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio name="500000" value={500000}>
                        Gói 500,000 VND
                      </Radio>
                      <Radio name="1500000" value={1500000}>
                        Gói 1,500,000 VND
                      </Radio>
                      <Radio name="2000000" value={2000000}>
                        Gói 2,000,000 VND
                      </Radio>
                      <Radio name="2500000" value={2500000}>
                        Gói 2,500,000 VND
                      </Radio>
                      <Radio name="3000000" value={3000000}>
                        Gói 3,000,000 VND
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Card>
          </div>
        )}
        {currentStep > 0 && (
          <div style={{ textAlign: "center", padding: 32 }}>
            <Title level={2} type="success">
              TẠO THANH TOÁN THÀNH CÔNG
            </Title>
            <Text type="secondary">Vui lòng làm theo hướng dẫn bên dưới để thanh toán</Text>
            <DetailTransaction data={data} modelTitle={"Thông tin gia hạn"} PropslistBank={listBank}/>
          </div>
        )}
      </Modal>
    </div>
  );
}

const contentStyle = {
  padding: "8% 24%",
  backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/payment.png"})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const stepsStyle = {
  background: "#F5F5F5",
  padding: "16px 32px",
};

export default memo(ModalPayment);
