import React, { Component } from "react";
import {
  Button,
  List,
  Row,
  Col,
  Divider,
  Card,
  Empty,
  Popconfirm,
  message,
  Form,
  Input,
  Switch,
  Radio,
  Typography,
  Select,
  Space,
  InputNumber,
  Popover,
} from "antd";
import api from "api";
import PopupLink from "./popup_display_link";
import ColorPicker from "components/picker/color_picker";
import UploadImagePicker from "components/picker/image_picker";
import Editor from "./react_quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { QrcodeOutlined, CopyOutlined } from "@ant-design/icons";
import QRCode from "qrcode.react";

const { TextArea } = Input;
const { Text, Title } = Typography;

class Branding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        color: "",
        links: [],
        name: "",
        logo: "",
        introduce: "",
        question: "",
        info: {},
        code: "",
        miniapp_config: {},
      },
      loading: false,
      theme: "default",
    };
    this.popupLink = React.createRef();
    this.form = React.createRef();
    this.formName = React.createRef();
    this.pwa_form = React.createRef();
    this.ReactQuill = React.createRef();
    this.qwew = React.createRef();
    this.themeRef = React.createRef();
    this.miniappRef = React.createRef();
    this.shop_name = localStorage.getItem("shop_name");
  }

  componentDidMount() {
    this.getData();
    this.getDataBranding();
    this.getDataIntroduce();
    this.getDataQuestion();
    this.getDataCode();
    this.getTheme();
  }

  getData = async () => {
    try {
      var res = await api.post("loyaltyconfig/getbranding");
      if (res != null) {
        this.setState({ data: res }, () => {
          // console.log(res);
          // this.form.current.setFieldsValue(res.color, res.name, res.logo);
          this.setState({ header_bg_type: res.color.header_bg?.type, data: res });
          // this.pwa_form.current.setFieldsValue(res.pwa_config);
          this.miniappRef.current.setFieldsValue(res.miniapp_config);
        });
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  getTheme = async () => {
    await api
      .post("loyaltyconfig/gettheme")
      .then((res) => {
        this.setState({ theme: res.theme });
      })
      .catch((error) => {});
  };
  saveTheme = () => {
    api
      .post("loyaltyconfig/savetheme", { theme: this.state?.theme })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };
  delete = (id) => {
    api
      .post("loyaltyconfig/deletelink", { id: id })
      .then((res) => {
        message.success("Xóa thành công");
        this.getData();
      })
      .catch((err) => {
        message.error("Xóa thất bại");
      });
  };

  saveColor = (value) => {
    api
      .post("loyaltyconfig/savecolor", { data: value })
      .then((res) => {
        message.success("Lưu thành công");
        this.getData();
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };

  saveName = (values) => {
    api
      .post("loyaltyconfig/savename", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };
  savePwaConfig = (values) => {
    this.setState({ loading: true });
    api
      .post("loyaltyconfig/savepwaconfig", { data: values })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Lưu thành công");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Lưu thất bại");
      });
  };

  saveMiniAppConfig = (values) => {
    //validate theo spec, binh thuong la dung form roi
    if (!values.id) {
      message.error("Mã Mini App không được để trống");
    } else if (!values.description) {
      message.error("Mô tả không được để trống");
    } else {
      this.setState({ loading: true });
      api
        .post("loyaltyconfig/saveminiappconfig", { data: values })
        .then((res) => {
          this.setState({ loading: false });
          message.success("Lưu cấu hình thành công");
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Lưu thất bại");
        });
    }
  };

  getDataBranding = async () => {
    try {
      var point = await api.post("loyaltyconfig/getbranding");
      if (point != null) {
        this.setState(
          {
            name: point.name,
            logo: point.logo,
          },
          () => {
            this.formName.current.setFieldsValue({
              name: point.name,
              logo: point.logo,
              customer_url: point.customer_url,
              customer_short_url: point.customer_short_url,
              purchase_url: point.purchase_url,
              phone_number: point.phone_number,
            });
          }
        );
      } else {
        this.formName.current.setFieldsValue({
          customer_url: `shop.cubeloyalty.vn/${this.shop_name}.html`,
        });
      }
    } catch (error) {
      this.formName.current.setFieldsValue({
        customer_url: `shop.cubeloyalty.vn/${this.shop_name}.html`,
      });
      console.log(error);
    }
  };

  getDataIntroduce = async () => {
    api
      .post("loyaltyconfig/getintroduce")
      .then((res) => {
        this.setState({
          introduce: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDataQuestion = async () => {
    api
      .post("loyaltyconfig/getquestion")
      .then((res) => {
        this.setState({
          question: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDataCode = async () => {
    api
      .post("loyaltyconfig/getcode")
      .then((res) => {
        this.setState({
          code: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChange = (value, name) => {
    var state = this.state;
    state[name] = value;
    this.setState(state);
  };

  onChangeMiniAppConfig = (value, name) => {
    var miniapp_config = this.state.data.miniapp_config;
    miniapp_config[name] = value;
    this.setState(miniapp_config);
    // console.log('onChangeMiniAppConfig', miniapp_config);
  };

  onChangIntroduce = (html) => {
    this.setState({ introduce: html });
  };

  onChangQuestion = (html) => {
    this.setState({ question: html });
  };

  onChangeCode = (value) => {
    this.setState({ code: value });
  };

  save = () => {
    api
      .post("loyaltyconfig/saveintroduce", { introduce: this.state.introduce, question: this.state.question })
      .then((res) => {
        message.success("Lưu thành công!");
      })
      .catch((err) => {
        message.error("Lưu thành thất bại!");
      });
  };

  saveCode = () => {
    api
      .post("loyaltyconfig/savecode", { code: this.state.code })
      .then((res) => {
        message.success("Lưu thành công!");
      })
      .catch((err) => {
        message.error("Lưu thành thất bại!");
      });
  };

  formDisplayChange = ({ header_bg }) => {
    if (!header_bg || !header_bg.type) return;
    this.setState({ header_bg_type: header_bg.type });
  };

  changeConfigTheme = (value) => {
    this.setState({ theme: value });
  };

  configTheme = () => {
    var theme = this.state.theme;

    if (theme === "default") {
      this.props.navigate("/marketing/branding/configtheme");
    } else {
      this.props.navigate("/marketing/branding/configthemezalominiapp");
    }
  };

  render() {
    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Title level={2}>Nội dung hiển thị</Title>
        </Col>

        <Col xs={24} lg={10}>
          <Title level={3}>Tên thương hiệu</Title>
        </Col>
        <Col xs={24} lg={14}>
          <Card title={<Text style={{ fontSize: 20 }}>Tên thương hiệu</Text>}>
            <Form layout="vertical" ref={this.formName} onFinish={this.saveName}>
              <Form.Item
                name="name"
                rules={[
                  {
                    message: "Bạn chưa nhập tên thương hiệu!",
                  },
                ]}
              >
                <Input placeholder="Tên thương hiệu" />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="logo"
                    label="Logo"
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa chọn logo!",
                      },
                    ]}
                    style={{ float: "left" }}
                  >
                    <UploadImagePicker
                      crop={{ quality: 1, aspect: 1 }}
                      size={{ width: 64, height: 64 }}
                      tags={["logo"]}
                      onChange={(value) => {
                        this.onChange(value, "logo");
                      }}
                    ></UploadImagePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="phone_number"
                label="Số điện thoại"
                rules={[
                  {
                    validator(_, value) {
                      if (!value || Number(value) || (value.length === 1 && value === "0")) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Vui lòng nhập đúng định dạng số điện thoại!");
                    },
                  },
                  // {
                  //   validator(_, value) {
                  //     if (!value || value.length === 10) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject("Vui lòng nhập đủ 10 số!");
                  //   },
                  // },
                ]}
              >
                <Input maxLength={10} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="customer_url" label="Đường dẫn của khách hàng">
                <Input
                  addonBefore="https://"
                  readOnly
                  addonAfter={
                    // <Button>
                    <CopyOutlined
                      onClick={() => {
                        var customer_url = `https://${this.formName.current.getFieldValue("customer_url")}`;
                        navigator.clipboard.writeText(customer_url);
                        message.success("Copy đường dẫn thành công");
                      }}
                    />
                    // </Button>
                  }
                />
              </Form.Item>
              <Form.Item
                name="purchase_url"
                label="Link mua hàng"
                rules={[
                  {
                    validator: (rule, value) => {
                      return new Promise((resolve, error) => {
                        if (value == null || value == "") resolve();
                        else {
                          try {
                            var url = new URL("htpps://" + value);
                            resolve();
                          } catch {
                            error();
                          }
                        }
                      });
                    },
                    message: "Đường dẫn không hợp lệ!",
                  },
                ]}
              >
                <Input
                  addonBefore="https://"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace("https://", "");
                    //e.target.value = e.target.value.replace(" ", "");
                    this.formName.current.setFieldsValue({ purchase_url: e.target.value });
                  }}
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => {
                this.formName.current.submit();
              }}
            >
              Lưu
            </Button>
          </Card>
        </Col>

        <Divider />

        {/* <Col xs={24} lg={10}>
          <Space direction="vertical">
            <Title level={3}>Liên kết</Title>
            <Text type="secondary">Các liên kết được hiển thị trong màn hình khách hàng.</Text>
            <Button
              type="primary"
              onClick={() => {
                this.popupLink.current.open();
              }}
            >
              Thêm liên kết hiển thị
            </Button>
          </Space>
        </Col>
        <Col xs={24} lg={14}>
          {this.state.show && (this.state.pointMethodData == null || this.state.pointMethodData.length === 0) ? (
            <Card title={<Text style={{ fontSize: 20 }}>Liên kết</Text>}>
              <div>
                <Empty description="Tạo mới liên kết"></Empty>
                <Row justify="center">
                  <Button
                    onClick={() => {
                      this.PopupCreatePointProgram.current.open();
                    }}
                    style={{ marginTop: 20 }}
                    type="primary"
                  >
                    <i className="fas fa-plus"></i>&nbsp;Tạo mới
                  </Button>
                </Row>
              </div>
            </Card>
          ) : (
            <Card title={<Text style={{ fontSize: 20 }}>Liên kết</Text>}>
              <List
                style={{ background: "white" }}
                dataSource={this.state.data.links}
                // header={
                //   <div style={{ marginBottom: 10 }}>
                //     <h2>{"Liên kết"}</h2>
                //   </div>
                // }
                renderItem={(item) => {
                  return (
                    <List.Item key={item.id}>
                      <List.Item.Meta title={<div className="listTitle">{item.label}</div>} description={<div className="description">{item.link}</div>} />

                      <div style={{ height: "100%", display: "flex" }}>
                        <div style={{ margin: "auto" }}>
                          <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.delete(item.id)} okText="Có" cancelText="Không">
                            <Button type="link">
                              <i className="far fa-trash-alt"></i>
                            </Button>
                          </Popconfirm>
                          <Button
                            onClick={() => {
                              this.popupLink.current.open(item.id);
                            }}
                            type="link"
                          >
                            <i className="far fa-edit"></i>
                          </Button>
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              ></List>
            </Card>
          )}
        </Col>

        <Divider /> */}
        <PopupLink ref={this.popupLink} getData={this.getData} />

        {/* <Col xs={24} lg={10}>
          <Title level={3}>Tổng quan</Title>
        </Col>
        <Col xs={24} lg={14}>
          <Card title={<Text style={{ fontSize: 20 }}>Tổng quan</Text>}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Title level={5}>Giới thiệu</Title>
              <Editor
                id="id1"
                ref={this.ReactQuill}
                value={this.state.introduce ? this.state.introduce : ""}
                // style={{ height: 150 }}
                onChange={(e) => this.onChangIntroduce(e)}
              />
              <Button type="primary" onClick={() => this.save()} style={{ float: "right", marginTop: 16 }}>
                Lưu
              </Button>
            </Space>

            <Divider />

            <Space direction="vertical" style={{ width: "100%" }}>
              <Title level={5}>Câu hỏi thường gặp</Title>
              <Editor
                id="id2"
                ref={this.qwew}
                value={this.state.question ? this.state.question : ""}
                // style={{ height: 150 }}
                onChange={(e) => this.onChangQuestion(e)}
              />
              <Button type="primary" onClick={() => this.save()} style={{ float: "right", marginTop: 16 }}>
                Lưu
              </Button>
            </Space>
          </Card>
        </Col> */}

        {/* <Divider /> */}

        <Col xs={24} lg={10}>
          <Title level={3}>Theme</Title>
          <Text type="secondary">Bộ màu sắc, icon, hình ảnh được hiển thị trên Mini App của người dùng</Text>
        </Col>
        <Col xs={24} lg={14}>
          {/* Theme cũ */}
          {/* <Card title={<Text style={{ fontSize: 21 }}>Màu hiển thị</Text>}>
                <Form layout="vertical" ref={this.form} onFinish={this.saveColor} onValuesChange={this.formDisplayChange}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item name="main_color" label="Màu chính">
                        <ColorPicker></ColorPicker>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name="secondary_color" label="Màu phụ">
                        <ColorPicker></ColorPicker>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["header_bg", "type"]} label="Hình nền khách hàng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.header_bg !== currentValues.header_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("header_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["header_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 400.0 / 230.0 }} size={{ width: 400, height: 230 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.header_bg?.img || this.state.data.color?.header_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 400, height: 230 }} src={this.state.data.color?.header_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Form.Item name={["card_game_bg", "type"]} label="Hình nền thẻ game rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_game_bg !== currentValues.card_game_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("card_game_bg");
                        console.log("data", data);
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["card_game_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 224.0 / 200.0 }} size={{ width: 224, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.card_game_bg?.img || this.state.data.color?.card_game_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 224, height: 200 }} src={this.state.data.color?.card_game_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["card_blog_bg", "type"]} label="Hình nền bài viết rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_blog_bg !== currentValues.card_blog_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("card_blog_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["card_blog_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 260.0 / 200.0 }} size={{ width: 260, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.card_blog_bg?.img || this.state.data.color?.card_blog_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 260, height: 200 }} src={this.state.data.color?.card_blog_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["card_program_bg", "type"]} label="Hình nền quà của tôi rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_program_bg !== currentValues.card_program_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("card_program_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["card_program_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 300.0 / 200.0 }} size={{ width: 300, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={
                                  data?.type == "custom" || !this.state.data.color?.card_program_bg?.img || this.state.data.color?.card_program_bg?.img == ""
                                }
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 300, height: 200 }} src={this.state.data.color?.card_program_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["list_program_bg", "type"]} label="Hình nền danh sách quà của tôi rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_program_bg !== currentValues.list_program_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("list_program_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["list_program_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={
                                  data?.type == "custom" || !this.state.data.color?.list_program_bg?.img || this.state.data.color?.list_program_bg?.img == ""
                                }
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 240, height: 200 }} src={this.state.data.color?.list_program_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["list_point_history_bg", "type"]} label="Hình nền lịch sử điểm rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_point_history_bg !== currentValues.list_point_history_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("list_point_history_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["list_point_history_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={
                                  data?.type == "custom" ||
                                  !this.state.data.color?.list_point_history_bg?.img ||
                                  this.state.data.color?.list_point_history_bg?.img == ""
                                }
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 240, height: 200 }} src={this.state.data.color?.list_point_history_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["list_game_bg", "type"]} label="Hình nền danh sách game rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_game_bg !== currentValues.list_game_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("list_game_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["list_game_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.list_game_bg?.img || this.state.data.color?.list_game_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 240, height: 200 }} src={this.state.data.color?.list_game_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["list_blog_bg", "type"]} label="Hình nền danh sách bài viết rỗng">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_blog_bg !== currentValues.list_blog_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("list_blog_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["list_blog_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 260.0 / 200.0 }} size={{ width: 260, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.list_blog_bg?.img || this.state.data.color?.list_blog_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 260, height: 200 }} src={this.state.data.color?.list_blog_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["login_bg", "type"]} label="Hình nền màn hình đăng nhập">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.login_bg !== currentValues.login_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("login_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["login_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 166.0 / 200.0 }} size={{ width: 166, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.login_bg?.img || this.state.data.color?.login_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 166, height: 200 }} src={this.state.data.color?.login_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["otp_bg", "type"]} label="Hình nền màn hình OTP">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.otp_bg !== currentValues.otp_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("otp_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["otp_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 196.0 / 200.0 }} size={{ width: 196, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.otp_bg?.img || this.state.data.color?.otp_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 196, height: 200 }} src={this.state.data.color?.otp_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item name={["page_404_bg", "type"]} label="Hình nền màn hình 404">
                        <Radio.Group defaultValue={"system"}>
                          <Radio value="system">Hệ Thống</Radio>
                          <Radio value="custom">Upload</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.page_404_bg !== currentValues.page_404_bg}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("page_404_bg");
                        return (
                          <>
                            <Col span={24}>
                              <Form.Item name={["page_404_bg", "img"]} hidden={data?.type != "custom"}>
                                <UploadImagePicker crop={{ aspect: 300.0 / 200.0 }} size={{ width: 300, height: 200 }}></UploadImagePicker>
                              </Form.Item>
                              <div
                                hidden={data?.type == "custom" || !this.state.data.color?.page_404_bg?.img || this.state.data.color?.page_404_bg?.img == ""}
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <img style={{ width: 300, height: 200 }} src={this.state.data.color?.page_404_bg?.img} />
                              </div>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                </Form>

                <Button type="primary" style={{ float: "right" }} htmlType="submit" onClick={() => this.form.current.submit()}>
                  Lưu
                </Button>
              </Card> */}
          <Card title={<Text style={{ fontSize: 20 }}>Theme</Text>}>
            <Form name="theme" ref={this.themeRef}>
              <Form.Item>
                <Select
                  value={this.state?.theme || "default"}
                  options={[
                    { value: "default", label: "Theme mặc định" },
                    { value: "zaloMiniApp", label: "Theme Zalo Mini App" },
                  ]}
                  onChange={this.changeConfigTheme}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }}>
                <Button type="primary" style={{ float: "right", marginLeft: 8 }} onClick={this.saveTheme}>
                  Lưu
                </Button>
                <Button style={{ float: "right" }} onClick={this.configTheme}>
                  Cấu hình
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Divider />

        <Col xs={24} lg={10}>
          <Title level={3}>Cấu hình Mini App</Title>
          <Text type="secondary">Mô tả và hình ảnh thumbnail khi chia sẻ thông tin shop qua tin nhắn</Text>
        </Col>
        <Col xs={24} lg={14}>
          <Card title={<Text style={{ fontSize: 20 }}>Cấu hình Mini App</Text>}>
            <Form name="miniappconfig" ref={this.miniappRef} layout="vertical" onFinish={this.saveMiniAppConfig}>
              <Form.Item label="Mã Mini App" name="id" initialValue="">
                <Input
                  placeholder="Mã Mini App"
                  readOnly
                  addonAfter={
                    <Popover
                      title="Quét mã để truy cập Mini App"
                      // content={<QRCode value={`https://zalo.me/s/${this.state.data.miniapp_config.id}/`} />}
                      content={
                        <div style={{ textAlign: "center" }}>
                          {this.state.data.miniapp_config?.id ? (
                            <QRCode value={`https://zalo.me/s/${this.state.data.miniapp_config.id}/`} />
                          ) : (
                            <Text>Chưa cấu hình Mã Mini App</Text>
                          )}
                        </div>
                      }
                      trigger="click"
                    >
                      <QrcodeOutlined />
                    </Popover>
                  }
                />
              </Form.Item>

              <Form.Item label="Thumbnail (Tải lên ảnh có tỉ lệ 16:9):" name="thumbnail_url" initialValue="">
                <UploadImagePicker
                  crop={{ quality: 1, aspect: 1.7 }}
                  size={{ width: 320, height: 180 }}
                  tags={["thumbnail_url"]}
                  onChange={(value) => {
                    this.onChangeMiniAppConfig(value, "thumbnail_url");
                  }}
                />
              </Form.Item>

              <Form.Item label="Mô tả" name="description" initialValue="">
                <TextArea rows={4} maxLength={250} placeholder="Mô tả cho Mini App của bạn" />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit" style={{ float: "right", marginLeft: 8 }} loading={this.state.loading}>
                  Lưu
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        {/* <Divider /> */}

        {/* <Col xs={24} lg={10}>
          <Title level={3}>Cấu hình PWA</Title>
          <Text type="secondary">Cấu hình các thông tin cho app của shop</Text>
        </Col>
        <Col xs={24} lg={14}>
          <Form layout="name" ref={this.pwa_form} onFinish={this.savePwaConfig}>
            <Card
              title={<Text style={{ fontSize: 20 }}>Cho phép PWA hoạt động</Text>}
              extra={
                <Form.Item name="enable" noStyle valuePropName="checked">
                  <Switch></Switch>
                </Form.Item>
              }
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="icon"
                    label="icon"
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa chọn icon!",
                      },
                    ]}
                    style={{ float: "left" }}
                  >
                    <UploadImagePicker
                      crop={{ quality: 1, aspect: 1 }}
                      size={{ width: 64, height: 64 }}
                      tags={["icon"]}
                      onChange={(value) => {
                        this.onChange(value, "icon");
                      }}
                    ></UploadImagePicker>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <Form.Item name="theme_color" label="Màu chính">
                        <ColorPicker></ColorPicker>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="background_color" label="Màu nền">
                        <ColorPicker></ColorPicker>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Button type="primary" style={{ float: "right" }} htmlType="submit" loading={this.state.loading}>
                Lưu
              </Button>
            </Card>
          </Form>
        </Col> */}

        <Divider />

        <Col xs={24} lg={10}>
          <Title level={3}>Mã</Title>
        </Col>
        <Col xs={24} lg={14}>
          <Card title={<Text style={{ fontSize: 20 }}>Mã</Text>}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <TextArea onChange={(e) => this.onChangeCode(e.target.value)} value={this.state.code} rows={5}></TextArea>
              <Button type="primary" onClick={() => this.saveCode()} style={{ float: "right", marginTop: 16 }}>
                Lưu
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }
}

const BrandingHoc = () => {
  const navigate = useNavigate();
  return <Branding navigate={navigate} />;
};

export default BrandingHoc;
