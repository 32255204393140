import React from "react";
import { useLocation, useParams } from "react-router";
import AddEditLuckyWheel from "./spin/AddEditLuckyWheel";
import AddEditOpenGiftBox from "./giftbox/AddEditOpenGiftBox";

function GameConfig() {
  const { type } = useParams();
  console.log(type);
  return (
    <>
      {type === "lucky_spin" && <AddEditLuckyWheel />}
      {type === "open_giftbox" && <AddEditOpenGiftBox />}
    </>
  );
}

export default GameConfig;
