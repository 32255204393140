import React, { useEffect, useState } from "react";
import { Card, Space, List, Typography, Form, Switch, Row, Col, Tooltip, Input, Button, Popover, Select, InputNumber } from "antd";
import { QuestionCircleFilled, SettingOutlined } from "@ant-design/icons";

function ConfigMiniapp({ value, provinceList, onChange, loading }) {
  const [form] = Form.useForm();
  const [provinceNumber, setProvinceNumber] = useState(0);
  const [seletedProvince, setSelectedProvince] = useState(["SG", "HN"]);
  const data = [
    { key: "check_out_of_stock", text: "Kiểm tra hết hàng khi khách hàng đặt", tooltip: "Khi sản phẩm hết sẽ hiển thị hết hàng. Không cho khách hàng đặt" },
    {
      key: "area_shipping_limit",
      text: "Giới hạn khu vực ship",
      tooltip: "Giới hạn khu vực giao hàng. Ví dụ: Chỉ cho khách hàng tại Hồ Chí Minh và Nha Trang được lên đơn",
    },
    {
      key: "shipping_fee",
      text: "Phí ship tuỳ chỉnh",
      tooltip: "Khi bật cấu hình này cho phép chỉnh tiền ship mặc định trên giỏ hàng",
    },
    {
      key: "zalo_account",
      text: "Sử dụng Zalo cá nhân trên mini app",
      tooltip: "Khi bật option này, các mục liên hệ trên mini app sẽ trỏ về zalo cá nhân thay vì ZALO OA",
    },
  ];
  useEffect(() => {
    if (value) {
      form.setFieldsValue(value);
      setSelectedProvince(
        value.area_shipping_limit?.value.map((item) => {
          return item.code;
        })
      );
    } else {
      form.setFieldsValue({
        check_out_of_stock: { is_allow: false },
        area_shipping_limit: { is_allow: false },
        shipping_fee: { is_allow: false, value: 20000 },
        zalo_account: { is_allow: false, value: "" },
      });
    }
  }, []);

  useEffect(() => {
    setProvinceNumber(seletedProvince.length);
  }, [seletedProvince]);

  const options = provinceList.map((item) => {
    return { value: item.ID, label: item.Name };
  });

  const onChangeOption = () => {
    var data = form.getFieldsValue();
    data.area_shipping_limit.value = seletedProvince.map((item) => {
      var find_name = provinceList.find((item1) => {
        return item1.ID === item;
      });
      return { code: item, name: find_name.Name };
    });
    onChange(data);
  };

  const popoverContent = (
    <Select
      style={{ width: 288 }}
      defaultValue={seletedProvince}
      mode="multiple"
      allowClear
      placeholder="Chọn tỉnh thành"
      options={options}
      onChange={(e) => {
        setSelectedProvince(e);
      }}
      onBlur={() => {
        onChangeOption();
      }}
    />
  );

  return (
    <Form form={form} name="basic">
      <List
        style={{ width: "50%" }}
        header={
          <Typography.Title style={{ margin: 0 }} level={5}>
            Cấu hình Mini App
          </Typography.Title>
        }
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Row align={"middle"} justify={"space-between"} style={{ width: "100%" }}>
              <Col>
                <Row align={"middle"} style={{ gap: 8 }}>
                  <Form.Item name={[item.key, "is_allow"]} style={{ margin: 0 }} valuePropName="checked">
                    <Switch
                      loading={loading}
                      onChange={() => {
                        onChangeOption(item.key);
                      }}
                    />
                  </Form.Item>
                  <Typography.Text>{item.text}</Typography.Text>
                  <Tooltip title={item.tooltip}>
                    <QuestionCircleFilled />
                  </Tooltip>
                </Row>
              </Col>
              {item.key === "area_shipping_limit" && (
                <Col>
                  <Popover trigger={"click"} content={popoverContent} title="Chọn tỉnh thành">
                    <Form.Item style={{ width: 152, margin: 0 }}>
                      <Input readOnly value={provinceNumber + " tỉnh thành"} addonAfter={<SettingOutlined />} />
                    </Form.Item>
                  </Popover>
                </Col>
              )}
              {item.key === "shipping_fee" && (
                <Col>
                  <Form.Item name={[item.key, "value"]} style={{ width: 152, margin: 0 }}>
                    <InputNumber
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      style={{ width: "100%" }}
                      onBlur={onChangeOption}
                    />
                  </Form.Item>
                </Col>
              )}

              {item.key === "zalo_account" && (
                <Col>
                  <Form.Item name={[item.key, "value"]} style={{ width: 152, margin: 0 }}>
                    <Input style={{ width: "100%" }} onBlur={onChangeOption} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </List.Item>
        )}
      />
    </Form>
  );
}

export default ConfigMiniapp;
