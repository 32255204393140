import React from "react";
import { Row, Empty, Button, Space, List } from "antd";
import PopupAddVoucher from "./popup_add_voucher";
import { formatCurrency } from "components/common/format/number";

export default class ListVoucherPicker extends React.Component {
  constructor(props) {
    super(props);
    this.popupAddVoucher = React.createRef();
  }
  onChange = (value) => {
    var lstVoucher = value;
    if (this.props.value) lstVoucher = this.props.value.concat(value);
    lstVoucher.forEach((item, index) => {
      lstVoucher.forEach((element, i) => {
        if (element.voucher_id === item.voucher_id && index !== i) {
          lstVoucher.splice(i, 1);
        }
      });
    });
    if (this.props.onChange) {
      this.props.onChange(lstVoucher);
    }
  };

  deleteVoucher(item) {
    var lst = this.props.value;
    var index = lst.findIndex((x) => x === item);
    if (index > -1) {
      lst.splice(index, 1);
    }
    if (this.props.onChange) {
      this.props.onChange(lst);
    }
  }

  render() {
    var lst = [];
    if (this.props.value) lst = this.props.value;
    console.log("lst", lst);
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <span style={{ margin: "auto" }}>{`Tổng cộng ${formatCurrency(lst.length)} voucher`}</span>
        </Row>
        <Row style={{ width: "100%", display: "flex" }}>
          {lst.length === 0 ? (
            <div style={{ margin: "auto" }}>
              <Empty />
            </div>
          ) : (
            <div style={{ width: "100%", maxHeight: 500, overflowY: "scroll" }}>
              <List
                style={{ background: "white", width: "100%" }}
                dataSource={lst}
                renderItem={(item) => {
                  return (
                    <List.Item key={item.voucher_id} style={{ fontSize: 15 }}>
                      <List.Item.Meta title={<div className="listTitle">{item.voucher_id}</div>} description={<div className="description">{item.divided ? "Đã được phát" : "Chưa được phát"}</div>} />
                      <a
                        onClick={() => {
                          this.deleteVoucher(item);
                        }}
                        className="edit-link"
                      >
                        Xóa
                      </a>
                    </List.Item>
                  );
                }}
              ></List>
            </div>
          )}
        </Row>
        <Row style={{ width: "100%", display: "flex", marginTop: 20 }}>
          <div style={{ margin: "auto" }}>
            <Space>
              <Button
                onClick={() => {
                  if (this.props.onChange) {
                    this.props.onChange([]);
                  }
                }}
              >
                Xóa toàn bộ
              </Button>
              <Button type="primary" onClick={() => this.popupAddVoucher.current.open()}>
                Thêm voucher
              </Button>
            </Space>
          </div>
        </Row>
        <PopupAddVoucher ref={this.popupAddVoucher} onChange={this.onChange}></PopupAddVoucher>
      </div>
    );
  }
}
