import React, { Component } from "react";
import { Card, Row, Col, Button, message, Input, Typography, Popconfirm } from "antd";
import AddApiKeyPopup from "./addnew_popup";
import api from "api";
const { Text, Link } = Typography;

export default class APIKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.popupAddEdit = React.createRef();
  }

  componentDidMount = () => {
    this.loadListKey();
  };
  open = (mode) => {
    this.popupAddEdit.current.open(mode);
  };
  deleteKey = (item) => {
    console.log(item);
    api
      .post("customerconfig/deleteapikey", { user_name: item.user_name })
      .then((res) => {
        message.success("Xoá thành công");
        this.loadListKey();
      })
      .catch((err) => {
        message.error("Xoá không thành công");
      });
  };
  loadListKey = () => {
    api
      .get("customerconfig/getlistapikey")
      .then((res) => {
        this.setState({ list: res.result.list });
      })
      .catch((err) => {
        message.error("Đã xảy ra lỗi");
      });
  };
  generateKeyList = () => {
    return this.state.list ? (
      <div>
        {this.state.list.map((item) => {
          return (
            <Card title={item.first_name} style={{ marginTop: "15px" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <Input value={item.token} readOnly={true} disable={true} />
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(item.token);
                    message.info("Đã Copy");
                  }}
                >
                  <i className="fas fa-copy"></i>
                </Button>
              </div>
              <div style={{ float: "right", marginTop: "15px", display: "flex", columnGap: "30px" }}>
                <Popconfirm
                  placement="rightTop"
                  title={`Bạn có muốn xoá key ${item.first_name} không`}
                  onConfirm={() => this.deleteKey(item)}
                  okText="Có"
                  cancelText="Không"
                >
                  <Link underline>Xoá</Link>
                </Popconfirm>
                <Link
                  onClick={() => {
                    this.popupAddEdit.current.open("edit", item);
                  }}
                  underline
                >
                  Chỉnh sửa
                </Link>
              </div>
            </Card>
          );
        })}
      </div>
    ) : (
      <>aaa</>
    );
  };

  render() {
    return (
      <>
        <Card title={"Tạo API Key"}>
          <Row>
            <Col span={10}>
              <p>Cung cấp API giúp các hệ thống khác truy cập CUBELOYALTY </p>
              <p>
                {" "}
                Tài liệu kỹ thuật xin vui lòng xem tại <a href="#">đây</a>
              </p>
              <Button type="primary" onClick={this.open.bind(this)}>
                Tạo API key
              </Button>
            </Col>
            <Col span={14}>{this.generateKeyList()}</Col>
          </Row>
        </Card>
        <AddApiKeyPopup ref={this.popupAddEdit} reload={this.loadListKey} />
      </>
    );
  }
}
