import { Alert, Badge, Button, Col, Modal, Progress, Result, Row, Space, Typography, message } from "antd";
import { InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import api from "api";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { is } from "immutable";

const { Text } = Typography;
function SyncModal({ isModalOpen, setIsModalOpen, totalProduct }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [percent, setPercent] = useState(0);
  //   const [totalProduct, setTotalProduct] = useState(0);
  const [syncedProduct, setSyncedProduct] = useState(0);
  const [syncedProductName, setSyncedProductName] = useState();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if ((isSync && percent === "100") || !isSync) {
      setIsModalOpen(false);
      setPercent(0);
    }
  };

  //   useEffect(() => {
  //     getListWooProduct();
  //   }, []);

  const onSync = async () => {
    setIsLoading(true);
    var isAcitve = await checkActive();
    if (isAcitve) {
      setIsSync(true);
      setIsLoading(false);
      checkWooProductExistAlready();
    }
  };

  const checkWooProductExistAlready = () => {
    api
      .get("woo_product/checkexistalready")
      .then((res) => {
        if (res) {
          deleteListWooProduct();
        } else {
          listAllProductFromWooCommerce();
        }
      })
      .catch((err) => {
        console.log(err, "checkexistalready");
      });
  };

  const deleteListWooProduct = () => {
    api
      .post("woo_product/deletelist")
      .then((res) => {
        if (res) {
          listAllProductFromWooCommerce();
        } else {
          message.error("Xảy ra lỗi");
        }
      })
      .catch((error) => {
        console.log(error, "deletelist");
      });
  };

  const listAllProductFromWooCommerce = async () => {
    var splitString = (totalProduct / 100).toString().split(".");
    var loopTimes = splitString[1] ? parseInt(splitString[0]) + 1 : parseInt(splitString[0]);
    for (let i = 0; i < loopTimes; i++) {
      await api.post("app/woo/config/listallproducts", { pagging: { skip: i * 100, limit: 100, sort: {} } }).then(async (res) => {
        var listWooProduct = res.data.filter((item) => {
          return item.status === "publish" && (item.type === "simple" || item.type === "variable") && item.attributes.length < 3;
        });
        if (listWooProduct.length > 0) {
          // setTotalProduct(listWooProduct.length);
          for (let index = 0; index < listWooProduct.length; index++) {
            const item = listWooProduct[index];
            await syncWooProduct(item, 100 * i + index, listWooProduct.length);
          }
        }
      });
    }
    // api
    //   .post("app/woo/config/listallproducts", { pagging: { skip: 0, limit: 10, sort: {} } })
    //   .then((response) => {
    //     if (response) {
    //       api
    //         .post("app/woo/config/listallproducts", { pagging: { skip: 0, limit: response.total, sort: {} } })
    //         .then(async (res) => {
    //           var listWooProduct = res.data.filter((item) => {
    //             return item.status === "publish" && (item.type === "simple" || item.type === "variable") && item.attributes.length < 3;
    //           });
    //           if (listWooProduct.length > 0) {
    //             setTotalProduct(listWooProduct.length);
    //             for (let index = 0; index < listWooProduct.length; index++) {
    //               const item = listWooProduct[index];
    //               await syncWooProduct(item, index, listWooProduct.length);
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const syncWooProduct = async (wooProduct, index, total) => {
    await api
      .post("app/woo/config/syncproduct", wooProduct)
      .then((res) => {
        // console.log(syncedProduct + 1);
        setSyncedProduct(index + 1);
        setSyncedProductName(wooProduct.name);
        setPercent(numeral(((index + 1) / totalProduct) * 100).format("0"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(syncedProduct);
  }, [syncedProduct]);

  //   const checkCategoryExistAlready = (category) =>{
  //     api.post("miniapp_category/checkexistalready", category).then((res)=>{

  //     })
  //   }

  const checkActive = async () => {
    var isActive = await api
      .post("app/woo/config/checkactivekey")
      .then((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
        // setConfigData();
        console.log(err);
      });

    return isActive;
  };
  return (
    <Modal
      className="sync-modal"
      title="Đồng bộ sản phẩm WooCommerce"
      footer={
        isSync
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
      onCancel={handleCancel}
      width={680}
      bodyStyle={{ height: 260 }}
      open={isModalOpen}
    >
      {isSync ? (
        percent === "100" ? (
          <Result
            status="success"
            title="Đồng bộ thành công"
            subTitle={`Có ${totalProduct} sản phẩm đã được đồng bộ vào mini app`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Progress type="circle" percent={percent} size="small" />
              <Text>
                {syncedProduct}/{totalProduct}
              </Text>
              <Text>{syncedProductName}</Text>
            </Space>
          </Row>
        )
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            className="sync-alert"
            message="Lưu ý"
            description={
              <Space direction="vertical">
                <Badge color="black" text="Khi đồng bộ, hệ thống sẽ tự động tạo mới sản phẩm tương ứng trên mini app" />
                <Badge color="black" text="Với các sản phẩm trước đó đã liên kết sẽ tự động cập nhật lại thông tin và giá bán" />
                <Badge
                  color="black"
                  text={
                    <Text>
                      Hệ thống chỉ lấy các sản phẩm đã <Text strong>"Publish"</Text>
                    </Text>
                  }
                />
                <Badge color="black" text='Hệ thống hỗ trợ 2 loại sản phẩm "Sản phẩm đơn giản" và "Sản phẩm có biến thể"' />
                <Badge color="black" text="Hệ thống hỗ trợ sản phẩm có 2 thuộc tính trở xuống" />
              </Space>
            }
            type="info"
            showIcon
            icon={<InfoCircleOutlined />}
          />
          <Row justify={"center"}>
            <Button type="primary" loading={isLoading} icon={!isLoading ? <SyncOutlined /> : ""} onClick={onSync}>
              Đồng bộ sản phẩm
            </Button>
          </Row>
        </Space>
      )}
    </Modal>
  );
}

export default SyncModal;
