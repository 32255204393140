import { Badge, Button, Checkbox, Dropdown, Form, Input, Row, Select, Space, Table, message } from "antd";
import { SearchOutlined, SyncOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SyncModal from "./SyncModal";
import api from "api";
import AntTable from "components/common/AntTable";
import numeral from "numeral";

function ProductTab({ totalProduct, setTotalProduct }) {
  const [listWooProduct, setListWooProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customSearch, setCustomSearch] = useState();
  const [form] = Form.useForm();
  const refTable = React.createRef();
  const [categoryOptions, setCategoryOptions] = useState([{ value: "all", label: "--Tất cả danh mục--" }]);
  const columns = [
    {
      title: "Ảnh",
      dataIndex: "Picture",
      key: "Picture",
      render: (Picture, record, index) => {
        return (
          <div
            style={{
              width: 80,
              height: 80,
              backgroundImage: `url(${Picture})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Biến thể",
      dataIndex: "ListDetail",
      key: "ListDetail",
      render: (ListDetail, record, index) => {
        // if(variations.length === 0){
        //     return 1
        // } else {
        if (ListDetail && ListDetail.length > 0) {
          return ListDetail.length;
        } else {
          return 1;
        }
        // }
      },
    },
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Giá",
      dataIndex: "CostPrice",
      key: "CostPrice",
      render: (CostPrice, record, index) => {
        return numeral(CostPrice).format("0,0");
      },
    },
    {
      title: "Kết nối với Loyalty",
      dataIndex: "is_link",
      key: "is_link",
      render: (is_link, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        if (is_link) {
          return <Badge color="green" text="Đã kết nối APP" />;
        } else {
          return <Badge color="red" text="Chưa kết nối APP" />;
        }
      },
    },
    {
      // title: "Kết nối với Loyalty",
      dataIndex: "Id",
      key: "Id",
      render: (Id, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        return (
          <Dropdown
            trigger={"click"}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Đồng bộ lại",
                },
              ],
              onClick: (e) => {
                syncMShopKeeperProduct(record);
              },
            }}
            placement="bottomLeft"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const syncMShopKeeperProduct = (mshopkeeper_product) => {
    api
      .post("app/mshopkeeper/config/syncproduct", mshopkeeper_product)
      .then((res) => {
        refTable.current.reload();
      })
      .catch((err) => {
        console.log(err);
        message.error("Đồng bộ thất bại");
      });
  };

  const [linkList, setLinkList] = useState([]);

  //   const checkLink = async (id) => {
  //     var isLink = await api
  //       .post("product_map/getbywooproductid", { woo_product_id: id })
  //       .then((res) => {
  //         if (res) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       })
  //       .catch((err) => {
  //         return true;
  //       });
  //     return isLink;
  //   };

  useEffect(() => {
    // getListWooProduct();
    getCategoriesList();
  }, []);

  //   const get
  const getCategoriesList = () => {
    api
      .post("app/mshopkeeper/config/categorieslist")
      .then((res) => {
        if (res.length > 0) {
          var list = [...categoryOptions];
          res.forEach((item) => {
            list.push({ value: item.Id, label: item.Name });
          });
          setCategoryOptions(list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onFinish = () => {
    setCustomSearch(form.getFieldsValue());
  };

  useEffect(() => {
    refTable.current.reload();
  }, [customSearch]);

  const onFinishFailed = () => {};

  const openSyncModal = () => {
    setIsModalOpen(true);
  };

  const reload = () => {
    refTable.current.reload();
  };
  return (
    <div>
      <Form form={form} name="basic" autoComplete="off" initialValues={{ category: "all" }}>
        <Space>
          <Form.Item name="text">
            <Input placeholder="Tên sản phẩm hoặc mã sản phẩm" style={{ width: "calc(100vw - 608px)" }} />
          </Form.Item>

          <Form.Item name="category">
            <Select placeholder="Danh mục" style={{ width: 232 }} options={categoryOptions} />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: 112 }} icon={<SearchOutlined />} type="primary" onClick={onFinish}>
              Tìm kiếm
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: 112 }} icon={<SyncOutlined />} type="primary" ghost onClick={openSyncModal}>
              Đồng bộ
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {/* <Table dataSource={filterList} columns={columns} /> */}
      <AntTable
        columns={columns}
        // autoLoad={true}
        customSearch={customSearch}
        tableName={"tbMShopKeeperProduct"}
        ajax="app/mshopkeeper/config/listallproducts"
        ref={refTable}
        rowKey={(record) => record.id}
      ></AntTable>
      {isModalOpen && (
        <SyncModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          totalProduct={totalProduct}
          reload={reload}
          categoryOptions={categoryOptions}
          setTotalProduct={setTotalProduct}
        />
      )}
    </div>
  );
}

export default ProductTab;
