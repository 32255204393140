import React, { Component } from "react";

import { Input, Button, Select, Popconfirm, Col, message, Card, Tabs, Checkbox, DatePicker, Space, Image, ConfigProvider, Empty } from "antd";
import SearchBox from "components/common/SearchBox/SearchBox";
import AntTable from "components/common/AntTable";
import moment from "moment";
import { formatL } from "common/date_time";
import api from "api";
const { RangePicker } = DatePicker;
const defaultFilter = {
  create_date: {
    start: "",
    end: "",
  },
  categories: [],
  status: "all",
};
export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: { ...defaultFilter }, categories: [] };
    this.tb = React.createRef();
  }
  componentDidMount() {
    this.loadCategory();
  }

  onFilterChange = (name, value) => {
    var filter = { ...this.state.filter };
    filter[name] = value;
    this.setState({ filter: filter }, this.search);
  };

  search = () => {
    if (this.timeOutSearch) {
      clearTimeout(this.timeOutSearch);
    }
    this.timeOutSearch = setTimeout((e) => {
      this.tb.current.reload();
    }, 500);
  };

  delete = (id) => {
    api.post("/blog/delete", { id: id }).then((data) => {
      message.success("Bài viết đã được xóa.");
      this.tb.current.reload();
    });
  };

  loadCategory() {
    api.get("/blog/listcategory").then((data) => {
      var options = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.setState({ categories: options });
    });
  }
  onSelectFilterChange = (name, value) => {
    this.onFilterChange(name, defaultFilter[name]);
  };
  render() {
    var filter = this.state.filter;
    var filters = [
      {
        Key: "create_date",
        Name: "Ngày tạo",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Ngày tạo</label>
              <div style={{ display: "flex" }}>
                <RangePicker
                  placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                  //   value={[moment(filter.create_date.start), moment(filter.create_date.end)]}
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  onChange={(e) => {
                    if (e) {
                      this.onFilterChange("create_date", { start: e[0].format("YYYY-MM-DD"), end: e[1].format("YYYY-MM-DD") });
                    } else {
                      this.onFilterChange("create_date", { start: "", end: "" });
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        ),
      },
      {
        Key: "categories",
        Name: "Danh mục",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Danh mục</label>
              <div>
                <Select
                  style={{ width: "100%" }}
                  value={filter.categories}
                  mode="multiple"
                  options={this.state.categories}
                  onChange={(e) => this.onFilterChange("categories", e)}
                ></Select>
              </div>
            </div>
          </Col>
        ),
      },
      {
        Key: "status",
        Name: "Trạng thái",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Trạng thái</label>
              <div>
                <Select
                  style={{ width: "100%" }}
                  value={filter.status}
                  options={[
                    { value: "all", label: "Tất cả" },
                    { value: "draft", label: "Nháp" },
                    { value: "publish", label: "Xuất bản" },
                  ]}
                  mode="multiple"
                  allowClear
                  onChange={(e) => this.onFilterChange("status", e)}
                ></Select>
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return (
      <div>
        <Card>
          <SearchBox
            name="/marketing/blog"
            data={filters}
            onSelectFilterChange={this.onSelectFilterChange}
            placeholder="Thông tin"
            dataButton={[
              <Button
                type="primary"
                onClick={() => {
                  window.location = "/marketing/blog/add";
                }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Thêm mới
              </Button>,
            ]}
            onSearch={() => {
              this.tb.current.reload();
            }}
            onChange={(value) => {
              this.onFilterChange("title", value);
            }}
            ref={this.SearchBox}
          />
        </Card>
        <Space>
          <div></div>
        </Space>
        <Card>
          <ConfigProvider
            renderEmpty={() => (
              <Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" description={<span>Bạn chưa có bài viết nào</span>}>
                <Button
                  type="primary"
                  onClick={() => {
                    window.location = "/marketing/blog/add";
                  }}
                >
                  Thêm mới
                </Button>
              </Empty>
            )}
          >
            <AntTable
              columns={[
                {
                  title: "Hình ảnh",
                  dataIndex: "cover_image",
                  key: "cover_image",
                  fixed: "left",
                  sorter: false,
                  render: (text, record, index) => {
                    return <Image src={record.cover_image} width={100}></Image>;
                  },
                },
                {
                  title: "Tiêu đề",
                  dataIndex: "title",
                  key: "title",
                  sorter: false,
                  fixed: "left",
                  width: "100%",
                  render: (text, record, index) => {
                    return <>{record.title}</>;
                  },
                },
                {
                  title: "Trạng thái",
                  dataIndex: "status",
                  key: "status",
                  sorter: false,
                  fixed: "left",
                  width: "100%",
                  render: (text, record, index) => {
                    return <>{record.status === "draft" ? "Nháp" : "Xuất bản"}</>;
                  },
                },
                {
                  title: "Người tạo",
                  dataIndex: "create_by",
                  key: "create_by",
                  sorter: false,
                  fixed: "left",
                  width: "20%",
                  render: (text, record, index) => {
                    return `${record.create_by}`;
                  },
                },
                {
                  title: "Ngày tạo",
                  dataIndex: "create_on",
                  key: "create_on",
                  sorter: false,
                  fixed: "left",
                  width: "15%",
                  render: (text, record, index) => {
                    return ` ${formatL(record.create_on)}`;
                  },
                },
                {
                  title: "",
                  width: "10%",
                  sorter: false,
                  align: "center",
                  render: (text, record, index) => {
                    return (
                      <Space>
                        <Popconfirm title="Bạn có chắc chắn muốn xóa bài viết này" onConfirm={(e) => this.delete(record.id)}>
                          <Button type="link"> Xóa</Button>
                        </Popconfirm>

                        <Button
                          type="link"
                          onClick={() => {
                            window.location = `/marketing/blog/edit?id=${record.id}`;
                          }}
                        >
                          Sửa
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
              customSearch={{ query: this.state.filter }}
              tableName={"tblblogs"}
              ajax="blog/list"
              ref={this.tb}
            ></AntTable>
          </ConfigProvider>
        </Card>
      </div>
    );
  }
}
