import { memo, useState, useEffect } from "react";
import { Badge, Button, Col, Divider, Row, Space, Table, Tag, Typography, Popconfirm, message } from "antd";
import ModalPayment from "./ModalPayment";
import numeral from "numeral";
import api from "api";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
/**
 * Payment màn hình nạp tiền
 *
 */
const Payment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [mode, setMode] = useState("add");
  const [currentRecord, setcurrentRecord] = useState({});
  const [pagging, setPagging] = useState({ skip: 0, limit: 10 });
  const [totalPage, setTotalPage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const navigate = useNavigate();

  const openModal = (mode, record) => {
    setIsModalOpen(true);
    setcurrentRecord(record);
    setMode(mode);
  };

  const columns = [
    {
      title: "Mã giao dịch",
      dataIndex: "code_id",
      key: "code_id",
      align: "center",
      render: (text) => <Tag color="#40A9FF">{text}</Tag>,
    },
    {
      title: "Ngân hàng",
      dataIndex: "bank",
      key: "bank",
      align: "center",
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record, index) => {
        return <Title level={5}>{numeral(text.value).format("0,0")}</Title>;
      },
    },
    {
      title: "Trạng thái",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (text, record, index) => {
        switch (text) {
          case "pending":
            return (
              <Space>
                <Badge color="yellow" />
                <Text>Chờ thanh toán</Text>
              </Space>
            );
          case "completed":
            return (
              <Space>
                <Badge color="green" />
                <Text>Đã thanh toán</Text>
              </Space>
            );
          case "canceled":
            return (
              <Space>
                <Badge color="#D9D9D9" />
                <Text>Huỷ thanh toán</Text>
              </Space>
            );
        }
      },
    },
    {
      title: "Thao tác",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type="link" onClick={() => openModal("view", record)}>
            Xem
          </Button>

          {record.status === "pending" && (
            <Popconfirm title="Bạn có muốn hủy giao dịch này không?" onConfirm={() => cancelTransaction(record.id)} okText="Đồng ý" cancelText="Thoát">
              <Button type="link">Huỷ</Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    getDataPayment();
  }, [pagging]);
  const onLoadPageTransactionManagement=()=>{
    navigate("/payment/transaction-management")
  }
  /**
   * hàm lấy data payment
   * @author huy
   */
  const getDataPayment = async () => {
    setLoadingTable(true);
    await api.post("payment/getdata", { pagging: pagging }).then((res) => {
      //console.log("payment/getdata", res);
      if (res) {
        setLoadingTable(false);
        setDataTable(res.dataPayment);
        setTotalPage(res.total);
      }
    });
  };

  /**
   * Hàm xử lí khi thay đổi page
   * @param {*} page page được chọn
   * @param {*} pageSize số lượng phần tử được hiển thị
   * @author huy
   */
  const onChangePagination = (page, pageSize) => {
    setPagging({ skip: (page - 1) * 10 });
  };

  /**
   * Hàm xử lý khi nhấn nút đồng ý tại popconfirm huỷ giao dịch
   * @param {String} idTransaction id của giao dịch
   * @author huy
   */
  const cancelTransaction = async (idTransaction) => {
    var status = await api.post("payment/getstatustransaction", { id: idTransaction });

    if (status === "pending") {
      await api.post("payment/canceledtransaction", { id: idTransaction }).then((res) => {
        if (res) {
          getDataPayment();
          message.success("Huỷ thành công!");
        } else {
          message.error("Thao tác không hợp lệ, vui lòng F5 để thử lại");
        }
      });
    } else {
      message.error("Thao tác không hợp lệ, vui lòng F5 để thử lại");
    }
  };
  return (
    <div>
      <Title level={4}>Lịch sử nạp tiền</Title>
      <Row style={{ backgroundColor: "white" }}>
        <Col span={12} style={{ padding: 16 }}>
          <Button type="link" onClick={()=>onLoadPageTransactionManagement()}>
            <Title level={5} style={{ color: "#1677ff" }}>
              Quản lý giao dịch
            </Title>
          </Button>
        </Col>

        <Col span={12} style={{ padding: 16 }}>
          <Button type="primary" onClick={() => openModal("add")} style={{ float: "right" }}>
            Nạp tài khoản
          </Button>
        </Col>

        <Col span={24}>
          <Divider style={{ margin: 0 }} />
          <Table
            columns={columns}
            dataSource={dataTable}
            rowKey={(r) => r.id}
            loading={loadingTable}
            pagination={{
              onChange: onChangePagination,
              total: totalPage,
            }}
          />
        </Col>
      </Row>
      <ModalPayment isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} mode={mode} record={currentRecord} reloadTable={getDataPayment} />
    </div>
  );
};

export default memo(Payment);
