import React from "react";
import { Button, Switch, Row, Col } from "antd";
import "./style.css";

export default class PreviewMessage extends React.Component {
  constructor(props) {
    super(props);


  }

  renderButtons = _ => {
    var lstButton = (this.props.value.message?.buttons ?? []).map((item) => {
      return (
        <div style={{ padding: 4 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "nowrap",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
              backgroundColor: "#DADDE1",
              height: 36,
              fontSize: ".9375rem",
              fontWeight: "bold",
              borderRadius: 6,
            }}
          >
            <span style={{ margin: "0 24px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item.title}</span>
          </div>
        </div>
      );
    });
    return lstButton;
  }




  render() {

    return (
      <div style={{ padding: 5, border: "2px solid #dfdfdf", borderRadius: 20, minHeight: 255, display: "flex", marginTop: 20 }}>
        <div style={{ margin: "auto" }}>
          {this.props.value && (
            <>

              {this.props.value.type == "text" && (
                <>

                  <div style={{ maxWidth: 300, backgroundColor: "#F1F3F5", borderRadius: 20, padding: 12 }}>
                    <div>
                      <img style={{ width: "100%" }} src={this.props.value.message.image}></img>
                    </div>
                    <div >
                      <div style={{ margin: "-5px 0", paddingTop: 15 }}>
                        <b> {this.props.value.message?.text}</b>
                      </div>
                      <div style={{ margin: "-5px 0", paddingTop: 15 }}>
                        {this.props.value.message?.sub_text}
                      </div>
                      <div style={{ marginTop: 12 }}>
                        {this.renderButtons()}
                      </div>
                    </div>
                  </div>

                </>
              )}

            </>
          )}
        </div>
      </div>
    );
  }
}
