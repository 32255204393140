import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const CustomButton = () => <span className="octicon octicon-star" />;


function insertStar() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, "★");
    this.quill.setSelection(cursorPosition + 1);
}


const CustomToolbar = (id) => {
    return (
        <div id={id.id}>
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                <option value="1" />
                <option value="2" />
                <option selected />
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" />
            <button className="ql-list" value={"ordered"}/>
            <button className="ql-list" value={"bullet"}/>
            <button className="ql-clean" />
            <select className="ql-color">
                <option value="#a2220d" />
                <option value="#b90608" />
                <option value="#cf6402" />
                <option value="#bb7204" />
                <option value="#b3a70b" />
                <option value="#257b18" />
                <option value="#006f35" />
                <option value="#017071" />
                <option value="#004d90" />
                <option value="#091f79" />
                <option value="#450566" />
                <option value="#750161" />
                
                <option selected />
            </select>
            <button className="ql-insertStar">
                <CustomButton />
            </button>
        </div>)
};


export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorHtml: "",
            modules: {
                toolbar: {
                    container: "#" + this.props.id,
                    handlers: {
                        insertStar: insertStar
                    }
                },
                clipboard: {
                    matchVisual: false,
                }
            },
            formats: [
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "color"
            ]
        };
    }

    render() {
        return (
            <div className="text-editor">
                <CustomToolbar id={this.props.id} />
                <ReactQuill
                    value={this.props.value}
                    onChange={this.props.onChange}
                    modules={this.state.modules}
                    formats={this.state.formats}
                    theme={"snow"}
                />
            </div>
        );
    }
}

