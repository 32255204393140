import { Modal, Spin } from "antd";
import React from "react";
import { createPortal } from "react-dom";

function LoadingModal({ isModalOpen, setIsModalOpen }) {
  return (
    <>
      {isModalOpen &&
        createPortal(
          <Modal centered open={isModalOpen} footer={[]} closable={false} width={400} bodyStyle={{ padding: 24 }}>
            <Spin tip="Loading" size="small">
              <div className="content" />
            </Spin>
          </Modal>,
          document.getElementById("portal-root")
        )}
    </>
  );
}

export default LoadingModal;
