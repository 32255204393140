import React, { useState, useEffect } from "react";

// import './index.css';
import App from "./components/layout/admin";
import Master from "./components/layout/master";
import Login from "./components/login";
import LoginMaster from "./components/master/login/index";
import reportWebVitals from "./reportWebVitals";

import Registration from "./components/registration/registration";
import "antd/dist/reset.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ActiveResult from "components/registration/active_result";
import ChangePassword from "components/login/change_password";
// import TestGame from "components/game/spin/test"
import api from "api";
import CustomerInfo from "components/customer/opencustomer/customer_by_info";

const RootApp = (props) => {
  const pathname = window.location.pathname;
  const [logedState, setLogedState] = React.useState("checking");

  useEffect(() => {
    if (pathname.startsWith("/master")) {
      checkMasterlogin();
    } else {
      checkLogin();
    }
  }, []);
  /*
   * Check login state
   */
  const checkLogin = () => {
    api.get("user/me")
      .then((data) => {
        setLogedState("loged");
      })
      .catch((err) => {
        setLogedState("notLoged");
      });
  };

  /**
   * Check Master Login
   * @author Huy
   */
  const checkMasterlogin = () => {
    api.get("master/user/me")
      .then((res) => {
        setLogedState("master");
      }).catch((err) => {
        setLogedState("notMasterLoged");
      })
  }

  const renderLoginRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" Component={Login}></Route>
          <Route path="/login" Component={Login}></Route>
          <Route path="/registration" Component={Registration}></Route>
          <Route path="/activeshop" Component={ActiveResult}></Route>
          <Route path="/Changepassword" Component={ChangePassword}></Route>
        </Routes>
      </BrowserRouter>
    );
  };

  const renderLoginMasterRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/master/*" Component={LoginMaster}></Route>
        </Routes>
      </BrowserRouter>
    );
  };

  const renderAppRoutes = () => {
    return (
      <BrowserRouter>
        <App></App>
      </BrowserRouter>
    );
  };

  const renderAppMasterRoutes = () => {
    return (
      <BrowserRouter>
        <Master />
      </BrowserRouter>
    )
  }

  switch (logedState) {
    case "loged":
      return renderAppRoutes();
    case "notLoged":
      return renderLoginRoutes();
    case "master":
      return renderAppMasterRoutes();
    case "notMasterLoged":
      return renderLoginMasterRoutes();
    default:
      return <div>Loading</div>;
  }

};

export default RootApp;
