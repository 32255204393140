import React, { Component } from "react";
import { InputNumber } from "antd";
import moment, { invalid } from "moment";

export default class MyInputNumber extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (value) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    var vl = null;
    if (this.props.value != null) vl = this.props.value;
    return <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {...this.props} value={vl} />;
  }
}
