import React from "react";
import { Button, Space, List, Row, Col, Avatar, Divider, Modal } from "antd";
import api from "api";
export default class PopupCreateExchangPointProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      program_lst: [],
      visible: false,
    };
  }

  getConfig = () => {
    api.post("exchangepointprogram/getconfig").then((res) => {
      var program_lst = [];
      res.forEach((obj) => {
        if (program_lst.find((z) => z.type == obj.type) == null) program_lst.push(obj);
      });
      this.setState({ program_lst: program_lst, visible: true });
    });
  };

  open = () => {
    this.getConfig();
  };

  render() {
    return (
      <Modal
        title="Tạo chương trình đổi điểm"
        open={this.state.visible}
        onOk={() => { }}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        width={"30%"}
        footer={null}
      >
        {this.state.program_lst.map((item) => {
          var href = "/marketing/exchangeprogram/" + item.type + "/0";
          return (
            <div style={{ padding: "8px" }}>
              <a style={{ display: "flex" }} href={href}>
                <div>
                  <img src={item.default_logo} style={{ width: "48px", height: "48px" }}></img>
                </div>
                <div style={{ color: "#000000", fontSize: 16, margin: "16px 0 0 16px" }}>{item.name}</div>
              </a>
            </div>
          );
        })}
      </Modal>
    );
  }
}
