import React, { Component } from "react";
import { DatePicker } from 'antd';
import moment from "moment";

export default class DateTimePickerNew extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
        if (this.props.value != null)
            this.setState({value: moment(this.props.value)});
        },500)
    }   

    onChange = (value) => {
        if (this.props.onChange)
            this.props.onChange(value);
    }

    render() {
        var vl = moment()
        var data= new Date(this.props.value)
        if (this.props.value != null && this.props.value != "") {
            var data = new Date(this.props.value)
            vl = moment(data);        
        }
        else vl=""
        return (
           <DatePicker 
           style={{ width: "100%" }} 
           format="DD-MM-YYYY"  
           onChange={this.onChange}
           value = {vl}
           />
        )
    }
}