import React, { Component } from "react";
import { Space, Button, Row, Col, Card, Form, Switch, Select, message, Modal, Progress, Alert, Radio, Typography } from "antd";
import api from "api";
import { options } from "numeral";

const { Option } = Select;

export default class Expiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: false,
      switch: false,
      isConfirm: false,
      progress: 0,
      startdate: "",
      modal: {
        visible: false,
        btnLoading: false,
        lstSelectDate: [],
      },
      modalTurnOff: {
        visible: false,
        btnLoading: false,
      },
    };
    this.formDate = React.createRef();
    this.intervalRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var point = await api.post("loyaltyconfig/getpoint");
    if (point != null) {
      console.log(point.expiry.enable);
      this.setState(
        {
          point: point,
          switch: point.expiry.enable,
        },
        () => {
          this.formDate.current.setFieldsValue({
            period: point.expiry.period,
            start_dayofmonth: point.expiry.start_dayofmonth,
            start_month: point.expiry.start_month,
          });
        }
      );
    }
  };

  onChangeBtn = () => {
    this.formDate.current.submit();
  };

  renderOptionDateOfMonth = () => {
    var a = [];
    for (var i = 1; i <= 31; i++) {
      a.push(
        <Option key={"d" + i} value={i}>
          {i}
        </Option>
      );
    }
    return a;
  };
  renderOptionMonth = () => {
    var b = [];
    for (var i = 1; i <= 12; i++) {
      b.push(
        <Option key={"m" + i} value={i}>
          {i}
        </Option>
      );
    }
    return b;
  };

  onChangeSwitch = (checked) => {
    if (checked) {
      this.setState({
        switch: checked,
        date: false,
      });
    } else {
      this.openWarningTurnOff();
    }
  };

  saveConfig = (values) => {
    var formvalue = this.formDate.current.getFieldValue();
    var year = new Date().getFullYear();
    var nexty = year + 1;
    var option1 = {
      key: year + "/" + formvalue.start_month + "/" + formvalue.start_dayofmonth,
      value: formvalue.start_dayofmonth + "/" + formvalue.start_month + "/" + year,
    };
    var option2 = {
      key: nexty + "/" + formvalue.start_month + "/" + formvalue.start_dayofmonth,
      value: formvalue.start_dayofmonth + "/" + formvalue.start_month + "/" + nexty,
    };

    var modal = { ...this.state.modal };
    modal.lstSelectDate = new Array(option1, option2);
    this.setState({
      modal: modal,
      startdate: "",
    });
    console.log(`object`, this.state);

    this.openWarningModal();
  };
  openWarningModal = () => {
    var obj = { ...this.state.modal };
    obj.visible = true;
    this.setState({
      modal: obj,
      startdate: obj.lstSelectDate[0],
    });
  };

  openWarningTurnOff = () => {
    var obj = { ...this.state.modalTurnOff };
    obj.visible = true;
    this.setState({
      modalTurnOff: obj,
    });
  };

  startCount = () => {
    if (this.state.startdate == "") {
      message.error("Vui lòng chọn ngày bắt đầu tính", 3);
      return;
    }

    var next = this.state.progress + 1;
    this.intervalRef.current = setInterval(() => {
      if (this.state.progress == 99) {
        this.stopCounter();
        //xử lý khi hit 100
        //set loading cho button
        var obj = { ...this.state.modal };
        obj.btnLoading = true;
        this.setState({ modal: obj });
        //call về server xử lý
        var dataform = this.formDate.current.getFieldValue();

        dataform.enable = this.state.switch;
        dataform.start_date = this.state.startdate;
        api
          .post("loyaltyconfig/saveexpiry", { data: dataform })
          .then((res) => {
            //set loading cho button
            var obj = { ...this.state.modal };
            obj.btnLoading = false;
            this.setState({
              modal: obj,
              progress: 0,
            });
            //thông báo thanh cong
            message.success("Lưu thành công");
          })
          .catch((err) => {
            var obj = { ...this.state.modal };
            obj.btnLoading = false;
            this.setState({ modal: obj });
            message.error(err, 3);
          });
      }
      next += 1;
      this.setState({ progress: next });
      console.log("count", this.state.progress);
    }, 50);
  };

  startCountTurnOff = () => {
    var next = this.state.progress + 1;
    this.intervalRef.current = setInterval(() => {
      if (this.state.progress == 99) {
        this.stopCounter();
        //xử lý khi hit 100
        //set loading cho button
        var obj = { ...this.state.modal };
        obj.btnLoading = true;
        this.setState({ modal: obj });
        //call về server xử lý
        var dataform = this.formDate.current.getFieldValue();

        dataform.enable = false;
        dataform.start_date = "nội dung";
        api
          .post("loyaltyconfig/saveexpiry", { data: dataform })
          .then((res) => {
            //set loading cho button
            var obj = { ...this.state.modal };
            obj.btnLoading = false;
            this.setState({
              modal: obj,
              progress: 0,
              switch: false,
            });
            //thông báo thanh cong
            message.success("Lưu thành công");
          })
          .catch((err) => {
            var obj = { ...this.state.modal };
            obj.btnLoading = false;
            this.setState({ modal: obj });
            message.error(err, 3);
          });
      }
      next += 1;
      this.setState({ progress: next });
      console.log("count", this.state.progress);
    }, 50);
  };

  stopCounter = () => {
    if (this.state.progress == 100) {
      clearInterval(this.intervalRef.current);
      this.intervalRef.current = null;
    } else {
      clearInterval(this.intervalRef.current);
      this.intervalRef.current = null;
      this.setState({ progress: 0 });
    }
  };

  handleOk = () => {
    var obj = { ...this.state.modal };
    obj.btnLoading = true;
    this.setState({ modal: obj });
  };

  renderOptionDate = () => {
    var radios = [];

    radios = this.state.modal.lstSelectDate.map((item) => {
      return <Radio value={item.key}>{item.value}</Radio>;
    });

    return <Radio.Group onChange={this.handleStartDateChange}>{radios}</Radio.Group>;
  };

  handleStartDateChange = (eve) => {
    console.log("radio", eve.target.value);
    this.setState({
      startdate: eve.target.value,
    });
  };

  render() {
    return (
      <>
        <Card
          title={
            <Typography.Title level={3} style={{ marginTop: 8 }}>
              Hạn sử dụng điểm
            </Typography.Title>
          }
          extra={<Switch checked={this.state.switch} htmlType="submit" onChange={this.onChangeSwitch} />}
        >
          <Form ref={this.formDate} onFinish={this.saveConfig} layout="vertical">
            {this.state.switch && (
              <>
                <Form.Item label="Thời hạn" name="period">
                  <Select showSearch style={{ width: "40%" }} placeholder="Trong vòng">
                    <Option key={6} value={6}>
                      6 Tháng
                    </Option>
                    <Option key={12} value={12}>
                      1 Năm
                    </Option>
                    <Option key={18} value={24}>
                      2 Năm
                    </Option>
                  </Select>
                </Form.Item>

                <>
                  <label> Ngày bắt đầu:</label> <br />
                  <br />
                  <Row>
                    <Col span={12}>
                      <Form.Item label="Ngày" name="start_dayofmonth">
                        <Select style={{ width: "40%" }} placeholder="chọn ngày">
                          {this.renderOptionDateOfMonth()}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Tháng" name="start_month">
                        <Select style={{ width: "40%" }} placeholder="chọn tháng">
                          {this.renderOptionMonth()}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" style={{ marginLeft: "90%" }} onClick={this.onChangeBtn}>
                      {" "}
                      Save{" "}
                    </Button>
                  </Form.Item>
                </>
              </>
            )}
          </Form>
          <Modal
            open={this.state.modal.visible}
            title="Xác nhận lưu cấu hình điểm"
            //  onOk={this.handleOk}
            onCancel={() => {
              var obj = { ...this.state.modal };
              obj.visible = false;
              this.setState({
                modal: obj,
              });
            }}
            //okText="Lưu"
            cancelText="Thoát"
            okButtonProps={{
              loading: this.state.modal.btnLoading,
              hidden: true,
              // onMouseDown: this.startCount,
              // onMouseUp: this.stopCounter,
              // onMouseLeave: this.stopCounter
            }}
          >
            <div>
              <Space direction="vertical">
                <Alert message="Cảnh Báo" description="Mốc thời gian chọn sẽ là thời điểm bắt đầu tính toán lại điểm đưa về 0" banner />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>Chọn ngày bắt đầu tính hạn điểm</p>
                  <div> {this.renderOptionDate()}</div>
                  {/* <Select style={{ width: 120 }}>
                                    </Select> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Progress
                    onMouseDown={this.startCount}
                    onMouseUp={this.stopCounter}
                    onMouseLeave={this.stopCounter}
                    type="circle"
                    percent={this.state.progress}
                    format={() => (
                      <Button loading={this.state.modal.btnLoading} type="link">
                        Lưu
                      </Button>
                    )}
                  />
                  <p>Vui lòng giữ button Lưu 5s để xác nhận </p>
                </div>
              </Space>
            </div>
          </Modal>

          <Modal
            open={this.state.modalTurnOff.visible}
            title="Tắt cấu hình điểm theo chu kỳ"
            //  onOk={this.handleOk}
            onCancel={() => {
              var obj = { ...this.state.modalTurnOff };
              obj.visible = false;
              this.setState({
                modalTurnOff: obj,
              });
            }}
            //okText="Lưu"
            cancelText="Thoát"
            okButtonProps={{
              loading: this.state.modalTurnOff.btnLoading,
              hidden: true,
              // onMouseDown: this.startCount,
              // onMouseUp: this.stopCounter,
              // onMouseLeave: this.stopCounter
            }}
          >
            <div>
              <Space direction="vertical">
                <Alert message="Cảnh Báo" description="Khi tắt cấu hình điểm theo chu kỳ số điểm hiện tại vẫn sẽ giữ nguyên" banner />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Progress
                    onMouseDown={this.startCountTurnOff}
                    onMouseUp={this.stopCounter}
                    onMouseLeave={this.stopCounter}
                    type="circle"
                    percent={this.state.progress}
                    format={() => (
                      <Button loading={this.state.modal.btnLoading} type="link">
                        Lưu
                      </Button>
                    )}
                  />
                  <p>Vui lòng giữ button Lưu 5s để xác nhận </p>
                </div>
              </Space>
            </div>
          </Modal>
        </Card>
      </>
    );
  }
}
