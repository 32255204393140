import { io } from "socket.io-client";
import { wss_url } from "config";

var getSocket = (namespace) => {
  var socket = io(`${wss_url}/${namespace}`);
  socket.emit("switchRoom", localStorage.getItem("shop_name"));
  return socket;
};

export { getSocket };
