import moment from "moment";

var RecurringNotificationsExtendOperator = {
  recurring_notifications_daily: {
    type: "number",
    label: "Nhận thông báo hàng ngày",
    labelForFormat: "recurring_notifications_daily",
    cardinality: 0,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      "fb.recurring_notifications.DAILY.expried_on": { $gte: { ISODate: { dateString: moment().format() } } },
    }),
  },
  recurring_notifications_weekly: {
    type: "number",
    label: "Nhận thông báo hàng tuần",
    labelForFormat: "recurring_notifications_weekly",
    cardinality: 0,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      "fb.recurring_notifications.WEEKLY.expried_on": { $gte: { ISODate: { dateString: moment().format() } } },
    }),
  },
  recurring_notifications_monthly: {
    type: "number",
    label: "Nhận thông báo hàng tuần",
    labelForFormat: "recurring_notifications_monthly",
    cardinality: 0,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      "fb.recurring_notifications.MONTHLY.expried_on": { $gte: { ISODate: { dateString: moment().format() } } },
    }),
  },
};

export default RecurringNotificationsExtendOperator;
