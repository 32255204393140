import React, { useState, lazy, Suspense } from 'react';
import { Tabs, Typography, Space, Popover, Empty, Button, Modal, Spin, message } from 'antd';
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import numeral from 'numeral';
const PaymentHistory = lazy(() => import('./PaymentHistory'));
const PackageRenewalHistory = lazy(() => import('./PackageRenewalHistory'));

const { Text } = Typography;
const { confirm } = Modal;

export default function PaymentManagemnt() {
  const items = [
    {
      key: '1',
      label: 'Lịch sử giao dịch',
      children:
        <Suspense fallback={<Spin />}>
          <PaymentHistory RenderPopover={RenderPopover} />
        </Suspense>,
    },
    {
      key: '2',
      label: 'Lịch sử gia hạn gói cước',
      children:
        <Suspense fallback={<Spin />}>
          <PackageRenewalHistory RenderPopover={RenderPopover} />
        </Suspense>,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      tabBarStyle={{ padding: '8px 24px', marginBottom: 0 }}
      style={{ backgroundColor: 'white' }}
    />
  )
}

/**
 * Hàm render Popover ở dấu 3 chấm cuối bảng
 * phải tách ra 1 component nhỏ để quản lý state open riêng cho từng popover
 * @param {Object} record dữ liệu của giao dịch
 * @param {Function} changeStatusPayment hàm thay đổi trạng thái giao dịch
 * @author huy 
 */
const RenderPopover = ({ record, changeStatusPayment }) => {
  const [openPopover, setopenPopover] = useState(false);
  const onOpenChangePopover = (newOpen) => {
    setopenPopover(newOpen);
  }

  /**
   * Hàm render content popover
   * @returns Component
   * @author huy
   */
  const renderContent = () => {
    switch (record.status) {
      case "pending":
        return (<Space direction='vertical'>
          <Button style={{ width: '100%' }} type='text' onClick={confirmPayment}>Đã thanh toán</Button>
          <Button style={{ width: '100%' }} type='text' onClick={deleteTransaction}>Xoá giao dịch</Button>
        </Space>);
      case "completed":
        return (<Button type='text' onClick={backToPending}>Chờ thanh toán</Button>);
      default:
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

    }
  }

  /**
   * Hàm hiển thị modal xác nhận thanh toán
   * @author huy
   */
  const confirmPayment = () => {
    setopenPopover(false);
    confirm({
      title: 'Xác nhận thanh toán',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction='vertical'>
          <Text>Công ty: <Text strong={true}>{record.shop[0]?.name}</Text></Text>
          <Text>Ngân hàng: <Text strong={true}>{record.bank}</Text></Text>
          {record?.plan?.name && (
            <Text>Gói cước: <Text strong={true}>{record.description}</Text></Text>
          )}
          <Text>Số tiền: <Text strong={true}>{numeral(record.amount.value).format('0,0')}</Text></Text>
        </Space>
      ),
      okText: 'Xác nhận',
      cancelText: 'Thoát',
      onOk() {
        changeStatusPayment(record, 'completed');
      },
    });
  }

  /**
   * Hàm hiển thị modal xác nhận xoá giao dịch
   * @author huy
   */
  const deleteTransaction = () => {
    setopenPopover(false);
    confirm({
      title: 'Xác nhận xoá giao dịch',
      icon: <ExclamationCircleOutlined />,
      content: (<Text>Bạn có chắc chắn muốn xoá giao dịch này?</Text>),
      okText: 'Xác nhận',
      cancelText: 'Thoát',
      okButtonProps: {
        style: { backgroundColor: '#ff4d4f' }
      },
      onOk() {
        changeStatusPayment(record, 'delete');
      },
    });
  }

  /**
   * Hàm hiển thị modal xác nhận chuyển trạng thái giao dịch từ đã thanh toán về chờ thanh toán
   * @author huy
   */
  const backToPending = () => {
    setopenPopover(false);
    confirm({
      title: 'Xác nhận chuyển trạng thái',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction='vertical'>
          <Text>Bạn có chắc chắn muốn chuyển về trạng thái Chờ thanh toán cho:</Text>
          <Text>• Mã giao dịch:
            <Text strong> {record.code_id}</Text>
          </Text>
          <Text>• Công ty:
            <Text strong> {record.shop[0]?.name}</Text>
          </Text>
        </Space>
      ),
      okText: 'Xác nhận',
      cancelText: 'Thoát',
      okButtonProps: {
        style: { backgroundColor: '#ff4d4f' }
      },
      onOk() {
        changeStatusPayment(record, 'pending');
      },
    });
  }

  return (
    <Popover
      content={renderContent}
      trigger="click"
      open={openPopover}
      onOpenChange={onOpenChangePopover}
    >
      <MoreOutlined
        style={{ fontSize: 24 }}
      />
    </Popover>
  );
}