import { Input, InputNumber, Select, Checkbox, Radio } from "antd";
import DatePicker from "components/picker/date_picker";
import DateTimePicker from "components/picker/datetime_picker";
import TagPicker from "components/picker/tag_picker";
import moment from "moment";
import numeral from "numeral";

const { TextArea } = Input;

let dataTypes = [
  { type: "text", label: "Text" },
  { type: "long_text", label: "Multi lines text" },
  { type: "number", label: "Number", format: "0,000.00" },
  { type: "date", label: "Date", format: "MM/DD/YYYY" },
  { type: "datetime", label: "DateTime", format: "DD-MM-YYYY HH:ss" },
  // { value: "file", label: "File" },
  { type: "dropdown", label: "Select", options: [] },
  { type: "multiple", label: "MultipleSelect", options: [] },
  { type: "check", label: "Check", options: [] },
  { type: "radio", label: "Radio", options: [] },
];

let makeid = (length) => {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

let renderValue = (f, data) => {
  var value = null;
  if (f.parent) {
    value = data[f.parent][f.data.attribute];
    f = f.data;
  } else {
    value = data[f.attribute];
  }

  if (!value) {
    return null;
  }

  if (f.type == "number" && f.format) {
    return numeral(value).format(f.format);
  }

  if (f.type == "date" && f.format) {
    return moment(value).format(f.format);
  }

  if (f.type == "dropdown" && f.options) {
    var item = f.options.find((x) => x.value == value);
    if (item) {
      return item.label;
    }
  }

  if (f.type == "check" && f.options && value && value.includes) {
    var items = f.options.filter((x) => value.includes(x.value)).map((i) => i.label);

    return items.join(", ");
  }

  return value;
};

let renderInput = (field) => {
  switch (field.type) {
    case "long_text":
      return <TextArea />;
      break;
    case "number":
      if (field.format) {
        return <InputNumber style={{ width: "100%" }} formatter={(value) => numeral(value).format(field.format)} />;
      } else {
        return <InputNumber style={{ width: "100%" }} />;
      }

      break;
    case "date":
      return <DatePicker />;
      break;
    case "datetime":
      return <DateTimePicker />;
      break;

    case "dropdown":
      return <Select options={field.options} />;
      break;
    case "multiple":
      return <Select options={field.options} mode="multiple" />;
      break;
    case "check":
      return <Checkbox.Group options={field.options} />;
      break;
    case "radio":
      return (
        <Radio.Group>
          {field.options.map((item) => {
            return <Radio value={item.value}>{item.label}</Radio>;
          })}
        </Radio.Group>
      );
      break;

    default:
      return <Input placeholder={field.name} />;
      break;
  }
};

export { renderValue, renderInput, dataTypes, makeid };
