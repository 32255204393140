import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Select, Input, Row, Space, Result, Progress, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import api from "api";
import numeral from "numeral";
const { Text } = Typography;
const { TextArea } = Input;
function SyncReviewModal({ isModalOpen, setIsModalOpen, record }) {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("link");
  const [url, setUrl] = useState();
  const [isSync, setIsSync] = useState(false);
  const [percent, setPercent] = useState(0);
  const [errorMess, setErrorMess] = useState();
  const [totalReview, setTotalReview] = useState(0);
  const [indexReview, setIndexReview] = useState(0);
  const [filter, setFilter] = useState({ star: [1, 2, 3, 4, 5] });
  const [countReview, setCountReview] = useState(0);
  const [form] = Form.useForm();
  const handleOk = () => {};

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const extractInfo = (url) => {
    const regex = /i\.(\d+)\.(\d+)\?/; // Regex to extract the required information

    const match = url.match(regex);

    if (match) {
      const [, firstInfo, secondInfo] = match;
      return [parseInt(firstInfo, 10), parseInt(secondInfo, 10)];
    }

    return null; // Return null if no match is found
  };

  const onFinish = () => {
    setIsLoading(true);
    setIsSync(true);
    var shop_id = "";
    var item_id = "";
    if (type === "link") {
      shop_id = extractInfo(url)[0];
      item_id = extractInfo(url)[1];
    } else {
      item_id = record.item_id;
    }
    // } else {
    //   item_id = record.item_id;
    // }

    api
      .post("app/shopee/product/gettotalreview", { shop_id: shop_id, item_id: item_id })
      .then(async (res) => {
        setErrorMess();
        setTotalReview(res.total);
        if (res.total === 0) {
          setErrorMess({ message: "Sản phẩm này chưa có đánh giá" });
        } else {
          await getListReview(shop_id, item_id, res.total);
        }
      })
      .catch((err) => {
        setErrorMess(err);
      });
  };

  const getListReview = async (shop_id, item_id, total) => {
    var splitString = (total / 50).toString().split(".");
    var loopTimes = splitString[1] ? parseInt(splitString[0]) + 1 : parseInt(splitString[0]);
    // console.log("loopTimes", loopTimes);
    for (let i = 0; i < loopTimes; i++) {
      //   console.log(i);

      await api
        .post("app/shopee/product/getproductreview", { shop_id: shop_id, item_id: item_id, pagging: { skip: i * 50, limit: 50, sort: {} }, filter: filter })
        .then(async (res) => {
          setErrorMess();
          //   setTotalReview(res.total);
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              //   console.log(50 * i + index);
              const item = res.data[index];
              //   console.log(50 * i + index);
              if (filter.star.includes(item.rating_star)) {
                // console.log("includes", index);
                // await setPercent((((index + 1) / total) * 100).toString().split(".")[0]);
                await syncReview(item, 50 * i + index, total);
              } else {
                setPercent((((50 * i + index + 1) / total) * 100).toString().split(".")[0]);
              }
            }
          } else {
            setPercent(100);
          }
        })
        .catch((err) => {
          setErrorMess(err);
        });
    }
  };

  const syncReview = async (review, index, total) => {
    setIndexReview(index + 1);
    await api
      .post("app/shopee/product/syncreview", { review: review, item_id: record.item_id })
      .then((res) => {
        // console.log(totalReview);
        setErrorMess();
        setPercent((((index + 1) / total) * 100).toString().split(".")[0]);
      })
      .catch((err) => {
        setErrorMess(err);
      });
  };

  useEffect(() => {
    if (indexReview) {
      var count = countReview;
      count = count + 1;
      setCountReview(count);
    }
  }, [indexReview]);
  return (
    <Modal
      className="sync-modal"
      width={680}
      bodyStyle={{ padding: "48px 96px" }}
      title="Đồng bộ đánh giá shopee"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        isSync
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
    >
      {isSync ? (
        errorMess ? (
          <Result
            status="error"
            title="Đồng bộ thất bại"
            subTitle={`error: ${JSON.stringify(errorMess)}`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : percent === "100" ? (
          <Result
            status="success"
            title="Đồng bộ thành công"
            subTitle={`Có ${countReview} đánh giá được đồng bộ`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Progress type="circle" percent={percent} size="small" />
              <Text>Sản phẩm: {record.item_name}</Text>
              <Text>
                Đánh giá: {indexReview}/{totalReview}
              </Text>
            </Space>
          </Row>
        )
      ) : (
        <Form
          name="basic"
          form={form}
          // style={{}}
          // bodyStyle={{ padding: 30 }}
          initialValues={{
            filter: filter.star,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="type" style={{ margin: 12 }}>
            {/* <Input /> */}
            <Select
              defaultValue="link"
              // style={{
              //   width: 120,
              // }}
              onChange={(e) => {
                setType(e);
              }}
              options={[
                {
                  value: "link",
                  label: "Đồng bộ từ link shopee",
                },
                {
                  value: "product",
                  label: "Sản phẩm shopee hiện tại",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="filter"
            style={{ margin: 12 }}
            rules={[
              {
                required: true,
                message: "Vui lòng chọn ít nhất 1 điều kiện",
              },
            ]}
          >
            {/* <Input /> */}
            <Select
              mode="multiple"
              onChange={(e) => {
                setFilter({ star: e });
              }}
              // style={{
              //   width: 120,
              // }}
              // onChange={(e) => {
              //   setType(e);
              // }}
              options={[
                {
                  value: 1,
                  label: "1 sao",
                },
                {
                  value: 2,
                  label: "2 sao",
                },
                {
                  value: 3,
                  label: "3 sao",
                },
                {
                  value: 4,
                  label: "4 sao",
                },
                {
                  value: 5,
                  label: "5 sao",
                },
              ]}
            />
          </Form.Item>

          {type === "link" && (
            <Form.Item
              style={{ margin: 12 }}
              name="url"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập link!",
                },
              ]}
            >
              <TextArea
                // showCount
                // maxLength={100}
                onChange={(e) => {
                  if (extractInfo(e.target.value)) {
                    setUrl(e.target.value);
                  }
                }}
                placeholder="Link sản phẩm shopee"
                style={{
                  height: 100,
                  resize: "none",
                }}
              />
              {/* <Input.Password /> */}
            </Form.Item>
          )}
          {url && (
            <Space direction="vertical" style={{ width: "100%", marginBottom: 12 }}>
              <Row justify={"center"}>{`ShopID: ${extractInfo(url).length > 0 ? extractInfo(url)[0] : ""}`}</Row>
              <Row justify={"center"}>{`ItemID: ${extractInfo(url).length > 0 ? extractInfo(url)[1] : ""}`}</Row>
            </Space>
          )}
          <Form.Item style={{ margin: 0 }}>
            <Row justify={"center"}>
              <Button type="primary" htmlType="submit" loading={isLoading} icon={!isLoading ? <SyncOutlined /> : ""}>
                Đồng bộ sản phẩm
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

export default SyncReviewModal;
