import React, { Component } from "react";
import api from "api";
import { Button, Card, Row, Popconfirm, message, Skeleton, Empty, Col, List, Space } from "antd";
import PoupEdit from "./poupedit";
import PopupName from "./popup_update_name";
import PopupTestSendEmail from "./popuptestsendemail";

const { Meta } = Card;

export default class EmailAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      signIn: "",
      showBtn: false,
      loading: true,
    };
    this.PopupSetting = React.createRef();
    this.PoupEdit = React.createRef();
    this.PopupName = React.createRef();
    this.PopupTestSendEmail = React.createRef();
  }

  componentDidMount = () => {
    this.getDataEmailConfig();
  };

  // lấy data config
  getDataEmailConfig = () => {
    api.post("app/defaultemail/config/getconfig", { shop_id: 0 }).then((res) => {
      console.log("res", res);
      if (res != null) {
        this.setState({ data: res, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };

  //deleteconfig
  delete = (id) => {
    var dataPost = {
      id: id,
      shop_id: 0,
    };
    api
      .post("app/defaultemail/config/delete", dataPost)
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.setState({ loading: true });
        this.getDataEmailConfig();
        this.setState({ visible: false, data: [] });
      })
      .catch((err) => {
        message.error("Xóa thất bại", 3);
      });
  };

  //render item
  renderItem = (element, item) => {
    return (
      <div style={{ marginRight: "10px", height: 320 }}>
        <Card
          className={"email-card-item"}
          bordered={true}
          cover={<img maxWidth={230} src={item.img} />}
          actions={[
            <div
              onClick={() => {
                this.PopupSetting.current.open("edit", element);
              }}
            >
              <i className="far fa-edit"></i>
            </div>,
            <Popconfirm
              title="Bạn có chắc muốn xóa không?"
              onConfirm={() => {
                this.delete(element.id);
              }}
              okText="Có"
              cancelText="Không"
            >
              ,
              <div style={{ marginTop: -22 }}>
                <i className="far fa-trash-alt"></i>
              </div>
            </Popconfirm>,
          ]}
        >
          <Meta title={item.name} description={element.name} />
        </Card>
      </div>
    );
  };

  deleteAccount = (id, account_id) => {
    api
      .post("app/defaultemail/config/deleteaccount", { id: id, account_id: account_id })
      .then((res) => {
        message.success("Xóa thành công");
        this.getDataEmailConfig();
      })
      .catch((err) => {
        message.error("Xóa thất bại");
      });
  };

  testEmail = () => {
    api.post("app/defaultemail/config/test").then((data) => {
      alert(data);
    });
  };

  verify = (id) => {
    api
      .post("app/defaultemail/config/verifydomain", { id: id })
      .then((res) => {
        message.success("Verify thành công");
        this.getDataEmailConfig();
      })
      .catch((err) => {
        message.error("Verify thất bại");
      });
  };

  render() {
    return (
      <Row>
        <Col span={12}>
          <h1>Domain</h1>
          <p>Email của bạn sẽ được gửi đi với tên @yourcompany.com</p>
        </Col>
        <Col span={12}>
          <Card
            extra={
              <Button type="primary" onClick={() => this.PoupEdit.current.open()}>
                Thêm domain mới
              </Button>
            }
          >
            {this.state.loading ? (
              <Skeleton />
            ) : (
              <>
                {this.state.data && this.state.data.length > 0 ? (
                  <>
                    {this.state.data.map((item) => {
                      return (
                        <Card
                          style={{ marginBottom: 20 }}
                          title={
                            <Space>
                              {item.verify ? <i className="fas fa-check-circle" style={{ color: "green" }}></i> : <i className="fas fa-times-circle" style={{ color: "red" }}></i>}
                              {item.domain}
                            </Space>
                          }
                          extra={[
                            <Button
                              type="link"
                              hidden={item.verify}
                              onClick={() => {
                                this.verify(item.id);
                              }}
                            >
                              <Space>
                                {item.verify ? <i className="fas fa-check-square"></i> : <i className="far fa-square"></i>}
                                Xác minh
                              </Space>
                            </Button>,
                            <Button type="link" onClick={() => this.PoupEdit.current.open(item.id)} hidden={item.verify}>
                              <Space>
                                <i className="fas fa-cog"></i> Cấu hình
                              </Space>
                            </Button>,
                            <Button
                              type="primary"
                              hidden={!item.verify}
                              onClick={() => {
                                this.PopupName.current.open(item);
                              }}
                            >
                              <Space>
                                <i className="fas fa-plus"></i> Thêm tài khoản
                              </Space>
                            </Button>,
                            <Popconfirm
                              onConfirm={() => {
                                this.delete(item.id);
                              }}
                              placement="top"
                              title={"Bạn có chắc muốn xóa domain không?"}
                              okText="Có"
                              cancelText="Không"
                            >
                              <Button type="link">
                                <Space>
                                  <i className="fas fa-trash-alt"></i> Xoá
                                </Space>
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          {item.account && item.account.length > 0 ? (
                            <>
                              {item.account.map((element) => {
                                return (
                                  <List.Item
                                    actions={[
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          this.PopupTestSendEmail.current.open(item, element);
                                        }}
                                      >
                                        <Space>
                                          <i className="fas fa-paper-plane"></i>Gửi email
                                        </Space>
                                      </Button>,
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          this.PopupName.current.open(item, element.id);
                                        }}
                                      >
                                        <Space>
                                          <i className="fas fa-cog"></i> Sửa
                                        </Space>
                                      </Button>,
                                      <Popconfirm
                                        onConfirm={() => {
                                          this.deleteAccount(item.id, element.id);
                                        }}
                                        placement="top"
                                        title={"Bạn có chắc muốn xóa tên không?"}
                                        okText="Có"
                                        cancelText="Không"
                                      >
                                        <Button type="link">
                                          <Space>
                                            <i className="fas fa-trash-alt"></i> Xoá
                                          </Space>
                                        </Button>
                                      </Popconfirm>,
                                    ]}
                                  >
                                    <List.Item.Meta description={<>{element.name && element.prefix ? element.name + " <" + element.prefix + "@" + item.domain + ">" : item.domain}</>} />
                                  </List.Item>
                                );
                              })}
                            </>
                          ) : (
                            <div>
                              <Empty></Empty>
                            </div>
                          )}
                        </Card>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    <Empty></Empty>
                  </div>
                )}
              </>
            )}
          </Card>
        </Col>
        <PoupEdit ref={this.PoupEdit} reload={this.getDataEmailConfig}></PoupEdit>
        <PopupName ref={this.PopupName} saveName={this.saveName} reload={this.getDataEmailConfig}></PopupName>
        <PopupTestSendEmail ref={this.PopupTestSendEmail}></PopupTestSendEmail>
      </Row>
    );
  }
}
