import React, { Component } from "react";
import { Modal, Input } from "antd";

export default class PopupNameSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      visible: false,
    };
  }

  open = (name) => {
    this.setState({ name: name, visible: true });
  };

  onChange = (value) => {
    this.setState({ name: value });
  };

  close = () => {
    this.setState({ visible: false });
  };

  save = () => {
    this.props.saveName(this.state.name);
    this.close();
  };

  render() {
    return (
      <>
        <Modal title="Tên nhóm" open={this.state.visible} onOk={() => this.save()} onCancel={() => this.close()}>
          <div>
            <label>Tên nhóm</label>
            <Input
              style={{ width: "100%" }}
              value={this.state.name}
              onChange={(e) => {
                this.onChange(e.target.value);
              }}
            ></Input>
          </div>
        </Modal>
      </>
    );
  }
}
