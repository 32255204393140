import React, { Component } from "react";
import { Input, Button, Select, Modal, Col, message, Card, Tabs, Checkbox, Typography, Space } from "antd";
import api from "api";
import { io } from "socket.io-client";
import { wss_url } from "config";
import { makeid } from "common/custom_field";
import { getSocket } from "common/realtime";

export default class PopupResendNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: "confirm",
      list: [],
      data: {
        current: 0,
        total: 0,
      },
      key: makeid(5),
    };
  }

  componentDidMount = () => {
    var socket = getSocket(`admin_socket`);
    socket.on("connect", () => {
      socket.on("resend_history", (data) => {
        if (data.key === this.state.key) this.setState({ data, data });
      });
    });
    this.socket = socket;
  };

  open = (list) => {
    var type = "confirm";
    if (list.length === 0) {
      type = "no_data";
    }
    var data = {
      current: 0,
      total: list.length,
    };
    var key = makeid(5);
    this.setState({
      list: list,
      visible: true,
      type: type,
      data: data,
      key: key,
    });
  };

  close = () => {
    this.setState({ visible: false });
    this.props.reload();
  };

  reSend = () => {
    api.post("sendhistory/resend", { listhis: this.state.list, id: this.socket.id, key: this.state.key });
    this.setState({ type: "process" });
  };

  render() {
    return (
      <Modal
        open={this.state.visible}
        footer={
          this.state.type == "confirm"
            ? [
                <Button type="primary" onClick={() => this.reSend()} style={{ marginLeft: 15 }}>
                  Gửi lại
                </Button>,
                <Button style={{ marginLeft: 15 }} onClick={() => this.close()}>
                  Thoát
                </Button>,
              ]
            : [
                <Button style={{ marginLeft: 15 }} onClick={() => this.close()}>
                  Thoát
                </Button>,
              ]
        }
        onCancel={this.close}
        title="Gửi lại thông báo"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.state.type == "no_data" && <div>Vui lòng chọn tin gửi đi</div>}
          {this.state.type == "confirm" && <div>{`Bạn có muốn gửi lại ${this.state.list.length} tin thất bại đã chọn không?`}</div>}
          {this.state.type == "process" && (
            <>
              {this.state.data.current != this.state.data.total ? (
                <div>{`Số thông báo đã được xử lý (${this.state.data.current} / ${this.state.data.total})`}</div>
              ) : (
                <div>Quá trình gửi lại đã xong!</div>
              )}
            </>
          )}
        </div>
      </Modal>
    );
  }
}
