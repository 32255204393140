import React, { Component } from "react";
import CustomerField from "components/setting/customer_field";
import SegmenPicker from "components/picker/segment_picker"
export default class Test extends React.Component {
    render() {
        return (

            <div style={{ width: 250 }}>
                <SegmenPicker></SegmenPicker>
            </div>
        )
    }
}