import React, { useEffect, useState } from "react";
import { Modal, Col, Alert, Typography, Table, Button, Space, Divider, Row } from "antd";
import { InfoCircleOutlined, CloseCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
const { Text } = Typography;

function ConfirmDataModal({ isModalOpen, setIsModalOpen, fileData, submit, error }) {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (isModalOpen && fileData && error.status === false) {
      //   console.log(fileData);
      var newCol = fileData.lstHeader.map((item) => {
        return { title: item, dataIndex: item, key: item };
      });
      setColumns(newCol);
      var newDataSource = fileData.dataExcel;
      setDataSource(newDataSource);
    }

    if (error.status === true) {
      console.log(error);
      setColumns(error.columns);
      setDataSource(error.dataSource);
    }
  }, [isModalOpen, error]);
  const handleOk = () => {
    submit(dataSource, fileData.total);
    setIsModalOpen(false);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataSource);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      maskClosable={false}
      title="Xác nhận dữ liệu"
      width={"70%"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        error.status
          ? [
              <Button key={"back"} onClick={handleCancel}>
                Thoát
              </Button>,
            ]
          : [
              <Button type="primary" key={"submit"} onClick={handleOk}>
                Tiếp tục
              </Button>,
            ]
      }
    >
      <Col>
        {/* <Space style={{ width: "100%" }} direction="vertical"> */}
        {error.status === false && (
          <Alert
            message="Thông tin"
            description={
              <Text>
                File excel có <Text strong>{fileData.total}</Text> dòng, vui lòng bấm xác nhận để xác nhận danh sách
              </Text>
            }
            type="info"
            icon={<InfoCircleOutlined />}
            showIcon
          />
        )}

        {error.status === true && (
          <Alert
            message="Báo lỗi"
            description={
              <Row justify={"space-between"} align={"middle"}>
                <Text>
                  Hệ thống phát hiện <Text strong>{error.dataSource.length}</Text> lỗi. Vui lòng sửa hết các lỗi này
                </Text>
                <Button icon={<DownloadOutlined />} onClick={downloadExcel}>
                  Excel báo lỗi
                </Button>
              </Row>
            }
            type="error"
            icon={<CloseCircleOutlined />}
            showIcon
          />
        )}
        <Divider style={{ margin: "8px 0" }} />
        <Table dataSource={dataSource} columns={columns}></Table>
        {/* </Space> */}
      </Col>
    </Modal>
  );
}

export default ConfirmDataModal;
