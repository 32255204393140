import React, { Component } from "react";
import api from "api";
import { Modal, Form, Button, Input, Checkbox, message } from "antd";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {},
      mode: null,
      loadingBtn: false,
    };
    this.form = React.createRef();
  }
  open = (mode, element) => {
    if (mode === "add") {
      var data = {
        url: "",
        key: "",
      };
      this.setState({ data: data, visible: true, mode: mode }, () => {
        this.form.current.resetFields();
      });
    } else {
      this.setState({ data: element, visible: true, mode: mode, type: element.type }, () => {
        this.setDataForm(element);
      });
    }
  };
  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };
  saveSetting = (data) => {
    this.setState({ loadingBtn: true }, () => {
      api
        .post("app/ibco/config/save", data)
        .then((res) => {
          message.success("Lưu thành công", 3);
          this.props.getConfig();
          this.setState({ visible: false, data: {} });
          this.setState({ loadingBtn: false });
        })
        .catch((err) => {
          message.error(err, 3);
          this.setState({ loadingBtn: false });
        });
    });
  };

  render() {
    return (
      <Modal
        title="Cấu hình trang website của IBCO"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        footer={[
          <div style={{ height: "30px" }}>
            <Button htmlType="submit" type="primary" form="form_ibco" loading={this.state.loadingBtn}>
              Lưu
            </Button>
          </div>,
        ]}
        width="50%"
      >
        <Form ref={this.form} layout="vertical" onFinish={this.saveSetting} id="form_ibco">
          <Form.Item
            name="name"
            label="Tên trang"
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập tên trang",
              },
            ]}
          >
            <Input placeholder="URL" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="url"
            label="Đường dẫn"
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập đường dẫn",
              },
            ]}
          >
            <Input placeholder="URL" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="key"
            label="Khoá API"
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập khoá API",
              },
            ]}
          >
            <Input placeholder="Key" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
