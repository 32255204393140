import React from "react";
import { Modal, Button, message, Space } from "antd";
import { Form, Input, Checkbox, Select, InputNumber } from "antd";
import api from "api";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import QRCode from "qrcode.react";

export default class PopupQrPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      shop_url: "shop1",
      id: "",
      tex1: "",
      text2: "",
    };
    this.componentRef = React.createRef();
  }

  componentDidMount() {}

  open = (id) => {
    api.get("pointcode/getqrpath").then((data) => {
      this.setState({ shop_url: data.url });
    });
    this.getconfig();
    this.setState({ isVisible: true, id: id });
  };

  close = (e) => {
    this.setState({ isVisible: false });
  };

  getconfig = () => {
    api.post("pointcode/getconfig").then((res) => {
      this.setState({ text1: res.text1, text2: res.text2 });
    });
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
      <Button type="primary">
        <Space>
          <i className="fas fa-print"></i> In
        </Space>{" "}
      </Button>
    );
  };

  render() {
    return (
      <Modal
        title="Mã Qr"
        open={this.state.isVisible}
        onOk={this.close}
        onCancel={this.close}
        footer={[<Button onClick={this.close}>Đóng</Button>, <ReactToPrint content={this.reactToPrintContent} documentTitle="AwesomeFileName" removeAfterPrint trigger={this.reactToPrintTrigger} />]}
      >
        <div ref={this.setComponentRef} style={{ justifyContent: "center", paddingTop: 25, alignItems: "center", width: "100%", display: "flex", flexDirection: "column" }}>
          <QRCode value={`${this.state.shop_url}?id=${this.state.id}`} />

          <br />
          <p>{this.state.text1}</p>
          <p>{this.state.text2}</p>
        </div>
      </Modal>
    );
  }
}
