import { Space, Typography, Card, Button, Row, Popconfirm, List, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddBannerModal from "./AddBannerModal";
import api from "api";
const grid = 2;
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const { Title, Text } = Typography;
function MiniAppBanner() {
  const [bannerList, setBannerList] = useState([]);
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [record, setRecord] = useState();
  const [reload, setReload] = useState(true);
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    var newList = [...bannerList];
    newList = reorder(newList, result.source.index, result.destination.index);
    setBannerList(newList);
    onChangeBannerPosition(newList);
  };

  const onChangeBannerPosition = (list) => {
    const ids = list.map((item) => {
      return item.id;
    });
    const order_num = list.map((item, index) => {
      return index;
    });
    api
      .post("miniapp_banner/changeposition", { ids: ids, order_num: order_num })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (reload) {
      api
        .post("miniapp_banner/list")
        .then((res) => {
          setBannerList(res);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setBannerList([]);
          setReload(false);
        });
    }
  }, [reload]);

  const openModal = () => {
    setIsBannerModalOpen(true);
  };

  const renderLinkTo = (link_to) => {
    switch (link_to) {
      case "product":
        return "Sản phẩm";

      case "category":
        return "Danh mục";

      case "blog":
        return "Bài viết";
      case "game":
        return "Trò chơi";

      default:
        break;
    }
  };

  useEffect(() => {
    if (!isBannerModalOpen) {
      setRecord();
    }
  }, [isBannerModalOpen]);

  const onDelete = (id) => {
    api
      .post("miniapp_banner/deletebyid", { id: id })
      .then((res) => {
        message.open({
          type: "success",
          content: "Xoá thành công",
        });
        // api
        //   .post("miniapp_banner/list")
        //   .then((res) => {
        //     setBannerList(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     setBannerList([]);
        //   });
        setReload(true);
      })
      .catch((error) => {
        message.open({
          type: "error",
          content: "Xoá thất bại",
        });
      });
  };
  return (
    <Space direction="vertical" size={8} style={{ width: "100%" }}>
      <Title level={5}>Danh sách Banner</Title>
      <Card
        title="Banner"
        extra={
          <Button type="primary" onClick={openModal}>
            Thêm mới
          </Button>
        }
        bodyStyle={{ padding: 0 }}
        style={{ width: "100%" }}
      >
        {bannerList.length === 0 && <List dataSource={bannerList} renderItem={(item) => <List.Item>{item}</List.Item>} />}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {bannerList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <li style={{ margin: "0px", padding: 0 }} ref={provided.innerRef} {...provided.draggableProps}>
                        <Row
                          //   justify={"space-between"}
                          align={"middle"}
                          style={{ boxShadow: "0px -1px 0px 0px #F0F0F0 inset", padding: "16px 24px", background: "white", gap: 8 }}
                        >
                          <Col style={{ width: 200 }}>
                            {" "}
                            <div
                              style={{ width: 200, height: 80, backgroundImage: `url(${item.image})`, backgroundSize: "cover", backgroundPosition: "center" }}
                            ></div>
                          </Col>
                          <Col style={{ width: "calc(100% - 200px - 168px - 16px)" }}>
                            {/* <Row align={"middle"} style={{ gap: 8 }}> */}
                            <Text>
                              {renderLinkTo(item.link_to)}/{item.page.title}
                            </Text>
                            {/* </Row> */}
                          </Col>
                          <Col style={{ width: 168, justifySelf: "flex-end" }}>
                            <Row align={"middle"} style={{ height: "100%" }}>
                              <Button
                                type="link"
                                style={{ fontSize: 14 }}
                                onClick={() => {
                                  setIsBannerModalOpen(true);
                                  setRecord(item);
                                }}
                              >
                                Chỉnh sửa
                              </Button>
                              <Popconfirm
                                title="Bạn có chắc muốn xóa không?"
                                onConfirm={() => {
                                  onDelete(item.id);
                                }}
                                okText="Có"
                                cancelText="Không"
                              >
                                <Button type="link" style={{ fontSize: 14 }}>
                                  <Typography.Text type="danger">Xoá</Typography.Text>
                                </Button>
                              </Popconfirm>

                              <div {...provided.dragHandleProps} style={{ display: "flex", alignItems: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <g clip-path="url(#clip0_2735_3375)">
                                    <path
                                      d="M13.2075 6.91064L11.2138 5.3372C11.1971 5.32408 11.177 5.31592 11.1559 5.31366C11.1348 5.31141 11.1135 5.31514 11.0944 5.32443C11.0754 5.33373 11.0593 5.34821 11.0481 5.36622C11.0368 5.38423 11.0309 5.40504 11.031 5.42626V6.4372H7.5622V2.96845H8.5747C8.66845 2.96845 8.72158 2.85908 8.66376 2.78564L7.08876 0.791888C7.07834 0.778396 7.06496 0.767473 7.04966 0.759958C7.03435 0.752443 7.01753 0.748535 7.00048 0.748535C6.98343 0.748535 6.96661 0.752443 6.95131 0.759958C6.936 0.767473 6.92262 0.778396 6.9122 0.791888L5.3372 2.78564C5.32408 2.80232 5.31592 2.82236 5.31366 2.84346C5.31141 2.86457 5.31514 2.88588 5.32443 2.90496C5.33373 2.92404 5.34821 2.94011 5.36622 2.95134C5.38423 2.96257 5.40504 2.9685 5.42626 2.96845H6.4372V6.4372H2.96845V5.4247C2.96845 5.33095 2.85908 5.27783 2.78564 5.33564L0.791888 6.91064C0.778396 6.92106 0.767473 6.93444 0.759958 6.94974C0.752443 6.96505 0.748535 6.98187 0.748535 6.99892C0.748535 7.01597 0.752443 7.03279 0.759958 7.0481C0.767473 7.0634 0.778396 7.07678 0.791888 7.0872L2.78408 8.6622C2.85751 8.72001 2.96689 8.66845 2.96689 8.57314V7.5622H6.43564V11.031H5.42314C5.32939 11.031 5.27626 11.1403 5.33407 11.2138L6.90908 13.206C6.95439 13.2638 7.04189 13.2638 7.08564 13.206L8.66064 11.2138C8.71845 11.1403 8.66689 11.031 8.57157 11.031H7.5622V7.5622H11.031V8.5747C11.031 8.66845 11.1403 8.72158 11.2138 8.66376L13.2059 7.08876C13.2194 7.0782 13.2303 7.06474 13.2378 7.04938C13.2454 7.03403 13.2494 7.01718 13.2495 7.00008C13.2497 6.98297 13.246 6.96605 13.2387 6.95057C13.2314 6.93509 13.2208 6.92144 13.2075 6.91064Z"
                                      fill="#1890FF"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2735_3375">
                                      <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
      <AddBannerModal isModalOpen={isBannerModalOpen} setIsModalOpen={setIsBannerModalOpen} value={record} setReload={setReload} />
    </Space>
  );
}

export default MiniAppBanner;
