import React, { memo, useEffect, useState, useImperativeHandle } from "react";
import { Row, Col, Select, Card, message, Form, Typography, Input, Spin, Empty } from "antd";
import api from "api";
import TextPicker from "../text_picker";
/**
 * ZaloEditor dùng cấu hình mẫu tin gửi bằng ZNS
 *
 */
const { Text } = Typography;
const ZaloEditor = React.forwardRef((props, ref) => {
  /**
   * value: {oa_id,template_id, text, params :[{param,value}]}: giá trị để hiện thị dữ liệu cấu hình sms
   * oa_id: OA id trong Zalo OA
   * template_id: template id trong dropdown mẫu tin OA
   * params: [{param:"{p1,0}", value:"{{customer_name}}"}]
   * onChange: function (value): callBack khi dữ liệu thay đổi, value có cấu trúc giống với props.value
   */
  const [oas, setOas] = React.useState([]);
  const [isSynced, setIsSynced] = React.useState(false);
  const [listTemplate, setListTemplate] = React.useState([]);
  const [data, setData] = React.useState();
  const [error, setError] = React.useState({});
  const [oaSelected, setOASelected] = useState();
  const [templateSelected, setTemplateSelected] = useState();
  const [form] = Form.useForm();

  const getZaloConfig = () => {
    api
      .get("app/zalov2/config/getconfig")
      .then((res) => {
        if (res.oas?.length > 0) {
          var oasOptions = res.oas.map((item) => {
            return { value: item.oa_id, label: item.name };
          });
          setOas(oasOptions);
        } else {
          message.error("Chưa đồng bộ Zalo OA");
        }
      })
      .catch((err) => {
        setIsSynced(false);
        console.log(err);
        message.error(err);
      });
  };

  const getAllTemplate = () => {
    api
      .get("app/zalov2/config/getdataalltemplate")
      .then((res2) => {
        if (res2.length > 0) {
          var filter = res2.filter((item) => {
            return item.status === "ENABLE";
          });
          if (filter.length > 0) {
            var templateOptions = filter.map((item) => {
              return { value: item.templateId, label: item.templateName };
            });
            setListTemplate(templateOptions);
            // setIsSynced(true);
          }
        } else {
          setIsSynced(true);
          // message.error('')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Hàm lấy chi tiết mẫu tin zalo 
   * @param {*} template_id 
   */
  const getDetailTemplate = (template_id) => {
    // console.log(template_id);
    api.post("app/zalov2/config/getdetailtemplate", { oa_id: oaSelected, template_id: template_id })
      .then((res) => {
        var newData = props.value;
        if (
          (!data && props.value && props.value.oa_id) ||
          (data && props.value && data.template_id === props.value.template_id && data.template_id === templateSelected)
        ) {
          setData(newData);
          setIsSynced(true);
        } else {
          newData = {
            preview_url: res.previewUrl,
            price: res.price,
            params: res.listParams,
            oa_id: oaSelected,
            template_id: templateSelected,
          };
          setData(newData);
          setIsSynced(true);
        }
      })
      .catch((err) => {
        var dataZalo = props.value;
        dataZalo.template_id = null;
        setData(dataZalo);
        message.error(err);
      });
  };

  const onParamsChange = (e, name) => {
    var zaloData = data;
    var params = zaloData.params.find((x) => x.name === name);
    if (params) {
      params.value = e;
      setData(zaloData);
      props.onChange(zaloData);
    }
  };

  const onChangeTemplate = (e) => {
    if (e) {
      setIsSynced(false);
      setTemplateSelected(e);
    }
  };

  useEffect(() => {
    getZaloConfig();
  }, []);

  useEffect(() => {
    if (oas.length > 0) {
      if (props.value && props.value.template_id) {
        var find = oas.find((item) => {
          return item.value === props.value.oa_id;
        });
        if (find) setOASelected(find.value);
        else setOASelected(oas[0].value);
      } else {
        setOASelected(oas[0].value);
      }
    }
  }, [oas]);

  useEffect(() => {
    if (oaSelected) {
      getAllTemplate();
    }
  }, [oaSelected]);

  useEffect(() => {
    if (listTemplate.length > 0) {
      if (props.value && props.value.template_id) {
        var find = listTemplate.find((item) => {
          return item.value === props.value.template_id;
        });
        if (find) setTemplateSelected(find.value);
        else setOASelected(listTemplate[0].value);
      } else {
        setTemplateSelected(listTemplate[0].value);
      }
    }
  }, [listTemplate]);

  useEffect(() => {
    if (templateSelected) {
      // console.log(templateSelected);
      getDetailTemplate(templateSelected);
    }
  }, [templateSelected]);

  useEffect(() => {
    if (data) {
      if ((props.value && data.oa_id !== props.value.oa_id && data.template_id !== props.value.template_id) || !props.value) {
        props.onChange(data);
      }
    }
  }, [data]);

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: validate,
      };
    },
    [props.value]
  );

  const validate = () => {
    var error = {};
    if (!props.value.oa_id) {
      error.oa_id = "Vui lòng chọn Zalo OA";
    }

    if (!props.value.template_id) {
      error.template_id = "Vui lòng chọn Zalo Template";
    }
    if (Object.keys(error).length > 0) {
      return error;
    } else {
      return null;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      {isSynced ? (
        <>
          <Col span={12}>
            {/* <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <div>
                        <span style={{ color: "red" }}>*</span>Zalo OA
                      </div>
                      <Form.Item>
                        <Select
                          value={data?.oa_id}
                          options={oas}
                          fieldNames={{ label: "name", value: "oa_id" }}
                          onChange={(e) => {
                            onSelectChange(e, "oa_id");
                          }}
                        ></Select>
                      </Form.Item>

                      {error.oa_id && <div style={{ color: "red" }}>{error.oa_id}</div>}
                    </Col>
                    <Col span={24}>
                      <div>
                        <span style={{ color: "red" }}>*</span>Mẫu tin OA
                      </div>
                      <Form.Item>
                        <Select
                          value={data?.template_id}
                          options={listTempalte}
                          fieldNames={{ label: "templateName", value: "templateId" }}
                          onChange={(e) => {
                            onSelectChange(e, "template_id");
                          }}
                        ></Select>
                      </Form.Item>
                      {error.template_id && <div style={{ color: "red" }}>{error.template_id}</div>}
                    </Col>
                  </Row> */}
            <Form name="zalo-oa" form={form} autoComplete="off" layout="vertical" initialValues={{ oas: oaSelected, template: templateSelected }}>
              <Form.Item label="Zalo OA" name="oas" rules={[{ required: true, message: "Bạn chưa chọn tài khoản Zalo OA!" }]}>
                <Select options={oas}></Select>
              </Form.Item>

              <Form.Item label="Mẫu tin OA" name="template" rules={[{ required: true, message: "Bạn chưa chọn mẫu tin nhắn!" }]}>
                <Select options={listTemplate} onChange={onChangeTemplate}></Select>
              </Form.Item>
            </Form>
            {data?.params && data.params?.length > 0 && (
              <Row gutter={[20, 20]}>
                {data.params.map((item) => {
                  return (
                    <>
                      <Col span={6} style={{ display: "flex" }}>
                        <div style={{ fontWeight: "bold", marginBottom: "auto", marginTop: "auto" }}>{item.name}</div>
                      </Col>
                      <Col span={18}>
                        <TextPicker value={item.value} name={item.name} onChange={onParamsChange}></TextPicker>
                      </Col>
                    </>
                  );
                })}
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Card>
              {data && (
                <div style={{ display: "flex", overflow: "hidden", height: "100%" }}>
                  {listTemplate.length > 0 ? (
                    <iframe
                      src={data?.preview_url}
                      scrolling="no"
                      frameBorder="0"
                      style={{ width: 600, height: 450, overflow: "hidden", margin: "auto" }}
                    ></iframe>
                  ) : (
                    (<Empty />)()
                  )}
                </div>
              )}
            </Card>
          </Col>
        </>
      ) : (
        <Row style={{ minHeight: "48vh", width: "100%" }} justify="center" align="middle">
          <Spin />
        </Row>
      )}
    </Row>
  );
});

export default memo(ZaloEditor);
