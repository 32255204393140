import React, { PureComponent } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip, Table, Typography, Form } from "antd";
import { Column } from "@ant-design/plots";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency, formatK } from "../common/format/number";
import numeral from "numeral";
import PopUpSearchInfo from "./popupSearchInfo";
const { Text, Link } = Typography;

export default class PointsInterval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      range: 50,
      list: [],
      total: 0,
    };
    this.popupSearchInfo = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount = () => {
    var range = localStorage.getItem("point_segment") * 1;
    if (!range) range = 50;

    this.getPointsInterval({ range: range });
  };

  getPointsInterval = (value) => {
    localStorage.setItem("point_segment", value.range);

    var arr = [0, 1, value.range + 1];
    var record = value.range + 1;
    for (let index = 0; index < 9; index++) {
      record = record + value.range;
      arr.push(record);
    }
    arr.push(Number.POSITIVE_INFINITY);
    var promiseArr = [];
    for (var i = 0; i < arr.length - 1; i++) {
      var query = null;
      if (arr[i] == 0) {
        query = { points: 0 };
      } else {
        if (arr[i + 1] == Number.POSITIVE_INFINITY) {
          query = { points: { $gte: arr[i] } };
        } else {
          query = { $and: [{ points: { $gte: arr[i] } }, { points: { $lt: arr[i + 1] } }] };
        }
      }
      var res = api.post("dashboard/getpointsintervalcustomer2", { query: query, number: arr[i] });
      promiseArr.push(res);
    }

    Promise.all(promiseArr).then((res) => {
      arr.splice(arr.length - 1, 1);
      for (var i = 0; i < arr.length; i++) {
        var index = res.findIndex((x) => x._id === arr[i]);
        if (index != -1) {
          if (i == 0) {
            arr[i] = {
              type: res[index]._id,
              value: res[index].value,
            };
          } else {
            if (i == arr.length - 1) {
              arr[i] = {
                type: res[index]._id + "+",
                value: res[index].value,
              };
            } else {
              arr[i] = {
                type: `${arr[i]}-${arr[i + 1]}`,
                value: res[index].value,
              };
            }
          }
        } else
          arr[i] = {
            type: `${arr[i]}-${arr[i] + value.range - 1}`,
            value: 0,
          };
      }
      // console.log("arr", arr);
      this.setState({ list: arr });
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.totalCustomer !== 0) return true;
    return false;
  };

  render() {
    var max = 0;
    for (const item of this.state.list) {
      if (item.value > max) max = item.value;
    }
    const config = {
      data: this.state.list,
      xField: "type",
      yField: "value",
      seriesField: "",
      color: "#5B8FF9",
      yAxis: {
        tickCount: 0,
        max: max + (max / 10) * 1.5,
      },
      label: {
        position: "top",
        content: (originData) => {
          const val = parseFloat(originData.value);
          return formatK(val).replace("k", "K") + `\n${Math.floor((val / this.props.totalCustomer) * 10000) / 100}%`;
        },
        offset: 10,
      },
      legend: false,
      // xAxis: {
      //   title: {
      //     offset: 25,
      //     spacing: 25,
      //     position: "end",
      //     text: "Số điểm",
      //     style: {
      //       fontSize: 15,
      //       fontWeight: "bold",
      //     },
      //   },

      //   line: {
      //     style: {
      //       stroke: "black",
      //       lineWidth: 1,
      //     },
      //   },
      // },
      // yAxis: {
      //   title: {
      //     offset: 50,
      //     spacing: 50,
      //     position: "end",
      //     text: "Số khách",
      //     style: {
      //       fontSize: 15,
      //       fontWeight: "bold",
      //     },
      //   },

      //   line: {
      //     style: {
      //       stroke: "black",
      //       lineWidth: 1,
      //     },
      //   },
      // },
    };
    return (
      <Card
        headStyle={{ padding: "4px 24px" }}
        style={{ height: "100%" }}
        bodyStyle={{ height: "100%" }}
        title={
          <div style={{ display: "flex" }}>
            <Tooltip
              trigger="click"
              overlayInnerStyle={{ width: 320 }}
              placement="topRight"
              title={
                <Form
                  onFinish={this.getPointsInterval}
                  style={{ height: "100%" }}
                  ref={this.form}
                  initialValues={{ range: localStorage.getItem("point_segment") ? localStorage.getItem("point_segment") * 1 : 50 }}
                >
                  <div style={{ display: "flex", color: "white" }}>
                    <div>Nhập vào phân đoạn mong muốn</div>
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", justifyContent: "right", margin: "auto" }}>
                        <Form.Item
                          style={{ margin: 0 }}
                          name="range"
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa nhập phân đoạn",
                            },
                          ]}
                        >
                          <InputNumber
                            min={5}
                            onBlur={(e) => {
                              this.form.current.setFieldsValue({ range: Math.floor(e.target.value) });
                            }}
                          />
                        </Form.Item>
                        <div>
                          <Button type="primary" htmlType="submit">
                            Áp dụng
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              }
            >
              <div>Phân bổ khách hàng theo điểm</div>
            </Tooltip>
          </div>
        }
      >
        <div>
          <Column {...config} autoFit={true} />
        </div>
      </Card>
    );
  }
}
