import { Alert, Button, Modal } from "antd";
import React from "react";

function ModalResponse({ isModalOpen, setIsModalOpen, response }) {
  const handleOke = () => {};
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal open={isModalOpen} footer={[<Button onClick={handleCancel}>Thoát</Button>]} onCancel={handleCancel} title="Response request">
      <Alert message="Response" description={JSON.stringify(response)} type="info" showIcon />
    </Modal>
  );
}

export default ModalResponse;
