import React, { useState } from "react";
import { Card, Tabs, Typography, Row, Col, Button, ColorPicker, Space, Divider, Form, Radio, Spin, Popover, Input, message } from "antd";
import { useEffect } from "react";
import UploadImagePicker from "components/picker/image_picker";
import { themeFromSourceColor, argbFromHex, hexFromArgb } from "@material/material-color-utilities";
import api from "api";
import "../theme/index.css";
import HomeConfig from "./home_config/HomeConfig";
import ColorConfig from "./ColorConfig";
import WebhookConfig from "./webhook_config/WebhookConfig";
import ConfigMiniapp from "./config_miniapp/ConfigMiniApp";

const { Title, Text } = Typography;
const { TextArea } = Input;
function ZaloMiniAppThemeConfig() {
  const [form] = Form.useForm();
  const [dataPost, setDataPost] = useState({
    login_bg: { type: "system", system_img: "", custom_img: "" },
    empty_bg: { type: "system", system_img: "", custom_img: "" },
    change_point_btn: { type: "system", system_img: "", custom_img: "" },
    voucher_btn: { type: "system", system_img: "", custom_img: "" },
    history_btn: { type: "system", system_img: "", custom_img: "" },
    contact_btn: { type: "system", system_img: "", custom_img: "" },
  });
  // const [open, setOpen] = useState(false);
  const [open, setOpen] = useState([]);
  const [menuList, setMenuList] = useState([
    {
      key: "Home",
      title: "Trang chủ",
      img: ` <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M10.2002 20V14H14.2002V20H19.2002V12H22.2002L12.2002 3L2.2002 12H5.2002V20H10.2002Z"
      fill="currentColor"
    />
  </svg>`,
      type: "navigate",
      path: "/Home",
    },
    {
      key: "Point",
      title: "Tích điểm",
      img: `  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9867 17.8171C19.1586 18.5613 18.0957 19.1163 16.9077 19.4247C15.4911 19.8183 13.9991 20.0124 12.4986 19.9984C10.94 20.0249 9.40065 19.72 8.03968 19.1153C6.74702 18.5196 5.71177 17.6263 5.07038 16.5533C4.34326 15.3196 3.97828 13.9706 4.00302 12.6083V11.5507C3.96394 10.1633 4.30429 8.78572 5.00055 7.51321C5.59655 6.4348 6.59023 5.52573 7.85014 4.9062C9.16943 4.28643 10.6808 3.97305 12.2126 4.00182C14.5336 4.00182 16.3479 4.44249 17.6558 5.32385C18.9637 6.2052 19.7395 7.48589 19.9834 9.16575H16.0598C15.8802 8.2747 15.4845 7.62428 14.8694 7.20917C14.2542 6.79405 13.413 6.59037 12.3357 6.59037C11.725 6.56536 11.1164 6.66535 10.568 6.88059C10.0197 7.09584 9.54989 7.41925 9.20345 7.81996C8.48856 8.63962 8.1228 9.85849 8.11615 11.474V12.4681C8.11615 14.1022 8.50518 15.336 9.28325 16.1698C10.0613 17.0036 11.204 17.4204 12.7114 17.4204C14.2276 17.4204 15.3083 17.1641 15.9534 16.6511V13.9674H12.2958V11.6062H20L19.9867 17.8171Z"
        fill="currentColor"
      />
    </svg>`,
      type: "login_navigate",
      path: "/Point",
    },
    {
      key: "ProductList",
      title: "Sản phẩm",
      img: ` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
      <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
    </svg>`,
      type: "navigate",
      path: "/ProductList",
    },
    {
      key: "Chat",
      title: "Tin nhắn",
      img: ` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-dots-fill" viewBox="0 0 16 16">
      <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    </svg>`,
      type: "open_chat",
      path: "/Chat",
    },
    {
      key: "Profile",
      title: "Tài khoản",
      img: ` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.8003 12C11.7003 12 10.7586 11.6083 9.97529 10.825C9.19196 10.0417 8.80029 9.1 8.80029 8C8.80029 6.9 9.19196 5.95833 9.97529 5.175C10.7586 4.39167 11.7003 4 12.8003 4C13.9003 4 14.842 4.39167 15.6253 5.175C16.4086 5.95833 16.8003 6.9 16.8003 8C16.8003 9.1 16.4086 10.0417 15.6253 10.825C14.842 11.6083 13.9003 12 12.8003 12ZM4.80029 20V17.2C4.80029 16.6333 4.94629 16.1123 5.23829 15.637C5.53029 15.1617 5.91763 14.7993 6.40029 14.55C7.43363 14.0333 8.48363 13.6457 9.55029 13.387C10.617 13.1283 11.7003 12.9993 12.8003 13C13.9003 13 14.9836 13.1293 16.0503 13.388C17.117 13.6467 18.167 14.034 19.2003 14.55C19.6836 14.8 20.0713 15.1627 20.3633 15.638C20.6553 16.1133 20.801 16.634 20.8003 17.2V20H4.80029Z"
        fill="currentColor"
      />
    </svg>`,
      type: "login_navigate",
      path: "/Profile",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [provinceList, setProvinceList] = useState([]);
  useEffect(() => {
    getMiniAppConfig();
    getProvinceList();
  }, []);

  const getProvinceList = () => {
    api.post("themeconfig/getprovincelist").then((res) => {
      setProvinceList(res);
    });
  };

  const getMiniAppConfig = () => {
    var list = [...open];
    api
      .post("themeconfig/getconfig", { nameconfig: "zalo_mini_app" })
      .then((res) => {
        form.setFieldsValue(res);
        var newDataPost = res;
        newDataPost["homeblock"] = res.homeblock ? res.homeblock : [];
        newDataPost["webhook"] = res.webhook ? res.webhook : [];
        setDataPost(newDataPost);

        if (res.menu && res.menu.length > 0) {
          setMenuList(res.menu);
          // newMenuList.forEach((item) => {
          //   list.push(false);
          //   form.setFieldValue(`${item.key}`, item.img);
          // });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }

        // onChangePrimaryColor
      })
      .catch((error) => {
        restoreConfig();
        menuList.forEach((item) => {
          list.push(false);
          form.setFieldValue(`${item.key}`, item.img);
        });
        setIsLoading(false);
      });
    setOpen(list);
  };

  const restoreConfig = () => {
    const theme = themeFromSourceColor(argbFromHex("#005ac1"), []);
    var newColor = theme.schemes.light.props;
    Object.keys(newColor).forEach(function (key, index) {
      if (key === "primary") {
        newColor[key] = "#005ac1";
      } else {
        newColor[key] = hexFromArgb(newColor[key]);
      }
    });

    var newData = { ...dataPost };
    newData.color = newColor;

    Object.keys(newData).forEach(function (key, index) {
      if (key !== "color") {
        newData[key].type = "system";
      }
    });
    setDataPost(newData);
    form.setFieldsValue(newData);
  };

  const onChangeHome = (value) => {
    var newData = { ...dataPost };
    newData.homeblock = value;
    setDataPost(newData);
    saveConfig(newData);
  };

  const onChangeWebhook = (value) => {
    var newData = { ...dataPost };
    newData.webhook = value;
    setDataPost(newData);
    saveConfig(newData);
  };

  const onChangeServiceWebhook = (value) => {
    var newData = { ...dataPost };
    newData.webhook_service = value;
    setDataPost(newData);
    saveConfig(newData);
  };

  const onChangeColor = async (value) => {
    var newData = { ...dataPost };
    Object.keys(value).forEach((key) => {
      dataPost[key] = value[key];
    });
    setDataPost(newData);
  };

  const onChangeConfig = (value) => {
    setIsLoading(true);
    var newData = { ...dataPost };
    newData.config = value;
    setDataPost(newData);
    saveConfig(newData);
  };

  const saveConfig = async (value) => {
    await api.post("themeconfig/savezaloconfig", { nameconfig: "zalo_mini_app", data: { ...value, menu: menuList } }).then((res) => {
      if (res) {
        message.success("Lưu thành công");
        getMiniAppConfig();
      } else {
        message.success("Lưu thất bại");
        setIsLoading(false);
      }
    });
  };

  const onChangeMenu = (value) => {
    setMenuList(value);
  };

  const items = [
    {
      key: "color",
      children: (
        <ColorConfig
          value={dataPost}
          onChange={onChangeColor}
          menuList={menuList}
          onChangeMenu={onChangeMenu}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onSave={saveConfig}
          restoreConfig={restoreConfig}
        />
      ),
      label: "Màu sắc",
    },
    {
      key: "home",
      label: "Trang chủ",
      children: <HomeConfig value={dataPost.homeblock} onChange={onChangeHome} isLoading={isLoading} setIsLoading={setIsLoading} />,
    },
    {
      key: "webhook",
      label: "Webhook",
      children: (
        <WebhookConfig
          value={dataPost.webhook}
          onChange={onChangeWebhook}
          serviceWebhook={dataPost.webhook_service}
          onChangeServiceWebhook={onChangeServiceWebhook}
        />
      ),
    },
    {
      key: "config",
      label: "Cấu hình",
      children: <ConfigMiniapp value={dataPost.config} provinceList={provinceList} onChange={onChangeConfig} loading={isLoading} />,
    },
  ];

  return (
    <Card title={<Text style={{ fontSize: 24 }}>Cấu hình Theme Zalo Mini App</Text>} style={{ width: "100%" }}>
      <Tabs defaultActiveKey="color" items={items} />
    </Card>
  );
}

export default ZaloMiniAppThemeConfig;
