import React, { createRef, useState, useEffect } from "react";
import { Button, Form, Input, Radio, Select, DatePicker, Space, Typography, Dropdown, Drawer, Modal, message } from "antd";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import AntTable from "components/common/AntTable";
import moment from "moment";
import * as dayjs from "dayjs";
import numeral from "numeral";
import { DownOutlined, MoreOutlined, CloseCircleOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Text, Title, Link } = Typography;

function CampaignExcel() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dataPost, setDataPost] = useState();
  const [oa, setOa] = useState();
  const Tb = createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const getZaloOA = () => {
    // setLoading(true);
    api
      .post("shop/getappzalo")
      .then((res) => {
        // setLoading(false);
        if (res.oas.length > 0) {
          setOa(res.oas[0]);
        } else {
          setOa(null);
        }

        console.log(res);
      })
      .catch((error) => {
        // setLoading(false);
        setOa(null);
        console.log(error);
      });
  };

  useEffect(() => {
    getZaloOA();
  }, []);

  const getColumn = () => {
    var columns = [
      {
        title: "Chiến dịch",
        dataIndex: "id",
        key: "id",
        sorter: true,
        render: (text, record, index) => {
          return (
            <Space direction="vertical">
              <Text>{record.name}</Text>
              <Text>
                Mẫu ZNS{" "}
                <Link
                  onClick={() => {
                    api.post("app/zalov2/config/getdetailtemplate", { oa_id: oa.oa_id, template_id: record.content.zalo.template_id }).then((res) => {
                      setPreviewUrl(res.previewUrl);
                      setIsModalOpen(true);
                    });
                  }}
                >
                  {record.content.zalo.template_name ? record.content.zalo.template_name : record.content.zalo.template_id}
                </Link>
              </Text>
            </Space>
          );
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "id",
        key: "id",
        sorter: true,
        render: (text, record, index) => {
          if (record.status) {
            if (record.status === "waiting") {
              return (
                <Space>
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3243_1067)">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="rgba(0, 0, 0, 0.45)" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3243_1067">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text>Chờ gửi</Text>
                </Space>
              );
            } else if (record.status === "sending") {
              return (
                <Space>
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3243_1067)">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#1677ff" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3243_1067">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text>Đang gửi</Text>
                </Space>
              );
            } else {
              return (
                <Space>
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3243_1067)">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52c41a" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3243_1067">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text>Hoàn tất</Text>
                </Space>
              );
            }
          } else {
            if (record.campaign_run_on) {
              var date = new Date(moment(record.campaign_run_on).format("MM/DD/YYYY"));
              var date2 = new Date();
              if (date.getTime() < date2.getTime()) {
                return (
                  <Space>
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52c41a" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Hoàn tất</Text>
                  </Space>
                );
              } else {
                return (
                  <Space>
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3243_1067)">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#1677ff" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3243_1067">
                          <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text>Đang gửi</Text>
                  </Space>
                );
              }
            } else {
              return (
                <Space>
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3243_1067)">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="rgba(0, 0, 0, 0.45)" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3243_1067">
                        <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text>Chờ gửi</Text>
                </Space>
              );
            }
          }
        },
      },
      {
        title: "Ngày",
        dataIndex: "createdate",
        key: "createdate",
        sorter: true,
        render: (text, record, index) => {
          return (
            <Space direction="vertical">
              <Text style={{ marginBottom: "auto", marginTop: "auto" }}>Ngày tạo: {moment(text).format("DD/MM/YYYY HH:mm")}</Text>
              <Text style={{ marginBottom: "auto", marginTop: "auto" }}>
                Ngày gửi: {record.campaign_run_on ? moment(record.campaign_run_on).format("DD/MM/YYYY HH:mm") : ""}
              </Text>
            </Space>
          );
        },
      },
      {
        title: "Số tin",
        dataIndex: "list_customer",
        key: "list_customer",
        sorter: true,
        render: (text, record, index) => {
          if (text.length > 0) {
            return (
              <Space direction="vertical">
                <Text>
                  Danh sách: <Text strong>{numeral(text.length).format("0,0")}</Text>
                </Text>
                <Text>
                  Đã gửi: <Text strong>{record.result_count}</Text>
                </Text>
              </Space>
            );
          }
        },
      },
      {
        title: "Thông tin",
        dataIndex: "result",
        key: "result",
        sorter: true,
        render: (text, record) => {
          return (
            <Space direction="vertical">
              <Text>
                Gửi thành công: <Text strong>{record.list_count > 0 ? (record.result_count / record.list_count) * 100 : 0}%</Text>
              </Text>
              <Text>
                Chi phí: <Text strong>{numeral(record.result_count * record.content.zalo.price).format("0,0")} đ</Text>
              </Text>
            </Space>
          );
        },
      },
      {
        // title: "Thông tin",
        dataIndex: "id",
        key: "id",
        sorter: true,
        render: (text, record) => {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <Text
                        onClick={() => {
                          api
                            .post("campaign/getresultzns", { campaign_id: text })
                            .then((res) => {
                              if (res) {
                                const worksheet = XLSX.utils.json_to_sheet(res);
                                const workbook = XLSX.utils.book_new();
                                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                                //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                                XLSX.writeFile(workbook, `CampaignDetail_${text}.xlsx`);
                              } else {
                                message.info("Chiến dịch chưa có kết quả để tải excel");
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        Tải excel kết quả
                      </Text>
                    ),
                    key: "0",
                  },
                ],
              }}
              trigger={["click"]}
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];
    return columns;
  };
  const onSearch = () => {
    var newDataPost = form.getFieldsValue();

    if (newDataPost.createdate && newDataPost.createdate.length > 0) {
      newDataPost.createdate = newDataPost.createdate.map((item, index) => {
        if (index === 0) {
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").utc().format();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").utc().format();
        }
      });
    }
    setDataPost(newDataPost);
  };

  useEffect(() => {
    if (dataPost) {
      Tb.current.reload();
    }
  }, [dataPost]);
  return (
    <div style={{ backgroundColor: "white", padding: "16px 8px" }}>
      <Form
        // {...formItemLayout}
        layout={"inline"}
        form={form}
        style={{ gap: 16, marginBottom: 16 }}
      >
        <Form.Item name={"text"} style={{ margin: 0, width: "calc(100% - 519px)" }}>
          <Input
            style={{ width: "100%" }}
            placeholder="Tìm tên chiến dịch"
            onKeyDown={() => {
              onSearch();
            }}
          />
        </Form.Item>
        <Form.Item name={"createdate"} style={{ margin: 0 }}>
          <RangePicker placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" onClick={onSearch}>
            Tìm kiếm
          </Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            onClick={() => {
              if (oa) {
                navigate("/campaign/zns_excel/add", { state: { oaId: oa.oa_id, oaName: oa.name } });
              } else {
                Modal.confirm({
                  icon: <CloseCircleOutlined style={{ color: "red" }} />,
                  title: "Báo lỗi",
                  content: "Vui lòng đồng bộ OA vào hệ thống để sử dụng tính năng này",
                  cancelText: "Đồng bộ ZALO OA",
                  okText: "Thoát",
                  onCancel() {
                    navigate("/apps");
                  },
                  onOk() {
                    close();
                  },
                });
              }
            }}
          >
            Thêm mới
          </Button>
        </Form.Item>
      </Form>
      <AntTable columns={getColumn()} customSearch={dataPost} tableName={"tbReview"} ajax="campaign/listzns" ref={Tb} rowKey={(record) => record.id}></AntTable>
      <Drawer
        title="Mẫu tin chăm sóc khách hàng"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        width={572}
      >
        <iframe style={{ top: "0px", width: "100%", height: "100vh" }} frameborder="0" allowfullscreen scrolling="no" src={previewUrl || ""}></iframe>
      </Drawer>
    </div>
  );
}

export default CampaignExcel;
