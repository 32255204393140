import React, { Component } from "react";
import { Modal, Input, Radio, message, Form, Checkbox, Upload, Col, Button, Row, Card } from "antd";
import api from "api";

export default class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      edit: false,
      loading: false,
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    api.post("plan/getplans").then((res) => {
      this.setState({ data: res });
    });
  };

  createPlans = () => {
    api.post("/master/plan/signup").then((res) => {
      message.success(`Đăng ký thành công ${res} plans`);
      this.setState({ loading: false });
      this.getData();
    });
  };

  render() {
    return (
      <>
        <Card
          title="Cấu hình Paypal"
          extra={
            <Button
              onClick={() => {
                this.setState({ loading: true }, () => {
                  this.createPlans();
                });
              }}
              type="primary"
              loading={this.state.loading}
            >
              Kich hoạt plans
            </Button>
          }
        >
          <Row gutter={16}>
            {this.state.data.map((item) => {
              return (
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <div style={{ textAlign: "center", border: "1px solid #dfdfdf" }}>
                    <h2>{item.name}</h2>
                    <h1>{`$${item.money}/mo`}</h1>
                    <h1 style={item.signed ? { color: "green" } : { color: "red" }}>{item.signed ? "Đã kích hoạt" : "Chưa kích hoạt"}</h1>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Card>
      </>
    );
  }
}
