import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Input, Col, Button, Modal } from "antd";
const { TextArea } = Input;

export default class TextAreaWidget extends PureComponent {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    config: PropTypes.object.isRequired,
    value: PropTypes.string,
    field: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
    customProps: PropTypes.object,
    maxLength: PropTypes.number,
    maxRows: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
    this.PickupModal = React.createRef();
  }

  handleChange = (ev) => {
    const v = ev.target.value;
    const val = v === "" ? undefined : v; // don't allow empty value
    this.props.setValue(val);
  };

  formularSelected = (val) => {
    this.props.setValue(val);
  };

  render() {
    const { config, placeholder, customProps, value, readonly, maxLength, maxRows, fullWidth } = this.props;
    const { renderSize, defaultMaxRows } = config.settings;
    const aValue = value != undefined ? value : null;
    console.log("this.props", this.props);
    return (
      <Col>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <TextArea
              autoSize={{ minRows: 1, maxRows: maxRows || defaultMaxRows }}
              maxLength={maxLength}
              disabled={readonly}
              key="widget-textarea"
              size={renderSize}
              value={aValue}
              placeholder={"Nhập công thức"}
              onChange={this.handleChange}
              {...customProps}
            />
          </div>
          <div>
            <Button size="small" onClick={(e) => this.PickupModal.current.open()}>
              ...
            </Button>
          </div>
        </div>
        <PickupModal ref={this.PickupModal} onSelected={this.formularSelected}></PickupModal>
      </Col>
    );
  }
}

class PickupModal extends React.Component {
  constructor(props) {
    super(props);
    var data = [];

    this.state = {
      isModalVisible: false,
      data: [{ name: "Khách hàng sinh nhật trong tháng 10", value: { $eq: [{ $month: "$birth_day" }, { $month: "$$NOW" }] } }],
    };
  }

  open = (_) => {
    this.setState({ isModalVisible: true });
  };
  close = (_) => {
    this.setState({ isModalVisible: false });
  };

  selectItem = (item) => {
    this.props.onSelected(JSON.stringify(item.value));
    this.close();
  };
  renderItem(item) {
    return (
      <div>
        {item.name}
        <Button onClick={(e) => this.selectItem(item)}>Chọn</Button>
      </div>
    );
  }
  render() {
    return (
      <Modal title="Chọn công thức" open={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.close}>
        {this.state.data.map((item) => {
          return this.renderItem(item);
        })}
      </Modal>
    );
  }
}
