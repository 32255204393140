import React from "react";
import { Button, Popconfirm, Row, Col } from "antd";
import ButtonAddMessage from "./button_add_message";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeid } from "common/custom_field";
import RenderSettingMessage from "./renderSettingMessage";
import PreviewMessage from "./preview_message";

export default class ChatbotPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        type: "text",
        message: { text: "" },
      },
      quicklyMessage: {
        text: null,
      },
    };
  }

  componentDidMount = () => {
    this.setState({
      data: this.props.value ?? {
        type: "text",
        message: { text: "" ,elements:[]},
       
      }
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.value != prevProps.value) {
      this.setState({ data: this.props.value });
    }
  };



  cardClick = () => {
    var data = {
      type: "card",
      message: {
        type: "template",
        payload: {
          template_type: "generic",
          elements: [
            {
              title: "",
              subtitle: "",
              image_url: "",
              buttons: [],
            },
          ],
        },
      },
    };
    this.setState({ data: data });
  };

  onChange = (data) => {
    this.setState({ data: data }, () => {
      this.props.onChange(data);
    });
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Row gutter={20}>
          <Row gutter={20} style={{ width: "100%", padding: "0 10px" }}>
            {/* <Col span={4} style={{ marginBottom: 10 }}>
              <ButtonAddMessage
                text="Text"
                icon={<i className="fas fa-align-justify"></i>}
                onClick={() => {
                  this.textClick();
                }}
              ></ButtonAddMessage>
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              <ButtonAddMessage text="Image" icon={<i className="far fa-image"></i>} onClick={() => this.imageClick()}></ButtonAddMessage>
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              <ButtonAddMessage text="Card" icon={<i className="fas fa-window-maximize"></i>} onClick={() => this.cardClick()}></ButtonAddMessage>
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              <ButtonAddMessage text="Gallery" icon={<i className="far fa-window-restore"></i>}></ButtonAddMessage>
            </Col>
            <Col span={4} style={{ marginBottom: 10 }}>
              <ButtonAddMessage text="Video" icon={<i className="fab fa-youtube"></i>}></ButtonAddMessage>
            </Col>
            <Col span={4}>
              <ButtonAddMessage text="Audio" icon={<i className="fas fa-volume-up"></i>}></ButtonAddMessage>
            </Col> */}
          </Row>
          <Row gutter={20} style={{ width: "100%" }}>
            <Col span={10} className="render-image" style={{ overflowY: "scroll" }}>
              <div style={{ padding: 10 }}>{this.state.data && <RenderSettingMessage data={this.state.data} onChange={this.onChange}></RenderSettingMessage>}</div>
            </Col>
            <Col span={14}>
              <PreviewMessage value={this.state.data}></PreviewMessage>
       
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}
