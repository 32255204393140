import React from 'react'

export default class EmailTypePicker extends React.Component {

    onChange = (value) => {

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    renderItem(item) {
        return (
            <div style={{ marginRight: "20px" }}>
                <label >

                    <div onClick={() => this.onChange(item.type)} style={item.type == this.props.value ? { width: "150px", height: "100px", cursor: "pointer", border: "1px solid #26C4FF" } : { width: "150px", height: "100px", cursor: "pointer" }}>

                        <img src={item.img} style={{ width: "100%", height: "100%" }}></img>
                    </div>

                </label>
                <div style={{ display: "flex", width: "150px", marginTop: "10px" }}>
                    <span style={{ margin: "auto", textAlign: "center" }}>{item.name}</span>
                </div>
            </div>
        )
    }

    render() {
        var lst = [{ type: "gmail", name: "Gmail", img: "/assets/images/app/email/gmail.png" }, { type: "smtp", name: "SMTP", img: "/assets/images/app/email/mail.png" }]

        return (

            <div style={{ display: "flex" }}>
                {lst.map(item => { return this.renderItem(item) })}
            </div>

        )
    }
}




