import React, { Component } from "react";
import api from "api";
import { Button, Card, Row, Popconfirm, message, Skeleton, Empty, Col, List, Space, Switch, Tooltip } from "antd";
import ChatBotConfig from "./chatbot_config";
import FacebookLogin from "react-facebook-login";
import RecurringNotification from "./recurring_notification"

const { Meta } = Card;

export default class EmailAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      signIn: "",
      showBtn: false,
      loading: true,
      pages: [],
    };
    this.PopupEdit = React.createRef();
    this.ChatBotConfig = React.createRef();
    this.RecurringNotification = React.createRef();
  }

  componentDidMount = () => {
    this.getData();
    this.getConfig();
  };

  getData = () => {
    api.get("app/fb/config/getpages").then((data) => {
      if (data) this.setState({ pages: data.pages });
    });
  };
  getConfig = () => {
    api.get("app/fb/config/getsetting").then((data) => {
      this.setState({ setting: data });
      if (data?.menu_setting) {
        this.ChatBotConfig.current.setFieldsValue(data.menu_setting);
      }
      if (data?.noti_setting) {
        this.RecurringNotification.current.setFieldsValue(data.noti_setting)
      }
    });
  }

  responseFacebook = (data) => {
    if (data.grantedScopes) {
      api
        .post("app/fb/config/savepages", { token: data.accessToken })
        .then((res) => {
          message.success("Đồng bộ thành công");
          this.getData();
        })
        .catch((err) => {
          message.error("Đông bộ không thành công");
        });
    }
  };


  activePage = (value, page) => {
    api
      .post("app/fb/config/activepage", { value: value, page: page })
      .then((res) => {
        message.success("Lưu thành công");
        this.getData();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  render() {
    return (
      <Row gutter={[16, 16]}>
        <Col span={10}>

          <Card
            title="Danh sách trang"
            extra={
              <FacebookLogin
                appId="794789890681037"
                autoLoad={false}
                callback={this.responseFacebook}
                size="small"
                scope="public_profile,pages_show_list,pages_messaging, pages_manage_metadata"
                returnScopes={true}
                textButton="&nbsp;&nbsp;Đăng nhập bằng facebook"
              />
            }
          >
            {this.state.pages == null || this.state.pages.length === 0 ? (
              <div>
                <Empty></Empty>
              </div>
            ) : (
              <div>
                {this.state.pages.map((item) => {
                  return (
                    <List.Item actions={[<SwitchLoading getData={this.getData} data={item}></SwitchLoading>]}>
                      <List.Item.Meta
                        title={item.name}
                        description={<>

                          {item.active &&

                            <Space>

                              <Button type="link" onClick={() => {
                                navigator.clipboard.writeText(`http://m.me/${item.id}?ref=CUBE_LOYALTY_LINKACCOUNT`);
                                message.success("Đã copy link")
                              }}>
                                <Tooltip title="Copy link đăng ký thành viên">  <i className="far fa-user"></i></Tooltip> <i className="fas fa-bell-on"></i> </Button>


                              <Button type="link" onClick={() => {
                                navigator.clipboard.writeText(`http://m.me/${item.id}?ref=CUBE_LOYALTY_SEND_RECURRING`);
                                message.success("Đã copy link")
                              }}>
                                <Tooltip title="Copy link tin nhắn mời nhận thông báo">  <i className="fas fa-bullhorn"></i></Tooltip> <i className="fas fa-bell-on"></i> </Button>



                            </Space>

                          }
                        </>}
                      />

                    </List.Item>
                  );
                })}
              </div>
            )}
          </Card>
          <Space direction="vertical">
            <div></div>
          </Space>

          <ChatBotConfig ref={this.ChatBotConfig}></ChatBotConfig>
        </Col>
        <Col span={14}>
          <RecurringNotification ref={this.RecurringNotification} />
        </Col>
      </Row>
    );
  }
}

class SwitchLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.PopupEdit = React.createRef();
  }

  activePage = (value) => {

    this.setState({ loading: true });
    api
      .post("app/fb/config/activepage", { value: value, page: this.props.data.id })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Lưu thành công");
        this.props.getData();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Lưu thất bại");
      });
  };

  render() {
    return (
      <Switch
        checked={this.props.data.active}
        onChange={(value) => {
          this.activePage(value);
        }}
        loading={this.state.loading}
      ></Switch>
    );
  }
}
