import React from "react";
import { Button, Switch, Modal } from "antd";
import ContentPicker from "./index";
import "./styles.css";

export default class ContentPickerPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        rich_media: {
          html: "",
          json: {},
          subject: "",
          attachments: [],
        },
        text: "",
      },
      item_id: -1,
      is_active: true,
    };
    this.ContentPicker = React.createRef();
  }

  open = (data) => {
    console.log("data", data);
    this.setState({ value: data.content, item_id: data.id, is_active: data.is_active, mode: "modal", visible: true });
  };
  close = () => {
    this.setState({ visible: false });
  };
  onChange = (value) => {
    this.props.onChange(value, this.state.item_id);
  };

  onChangeSwitch = (value) => {
    if (this.props.onChangeAppActive) this.props.onChangeAppActive(value, this.state.item_id);
  };

  render() {
    var value = { ...this.state.value };
    var height = "95vh";
    if (window.innerHeight < 1000) height = "120vh";
    console.log("props", this.props);

    return (
      <Modal
        title={"Nội dung"}
        open={this.state.visible}
        onCancel={this.close}
        footer={
          <div>
            <Button style={{ marginLeft: 15 }} onClick={this.close}>
              Đóng
            </Button>
            <Button type="primary" style={{}} onClick={this.close}>
              Lưu
            </Button>
          </div>
        }
        width={"100%"}
        style={{ top: 20 }}
        bodyStyle={{ height: height }}
      >
        <div style={{ marginBottom: 20 }}>
          <label style={{ marginRight: 10 }}>Trạng thái</label>
          <Switch defaultChecked={this.state.is_active} onChange={this.onChangeSwitch}>
            Hoạt động
          </Switch>
        </div>
        <ContentPicker value={value} onChange={this.onChange}></ContentPicker>
      </Modal>
    );
  }
}
