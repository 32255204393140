import React, { useEffect, useState } from "react";
import { Space, Typography, Card, Button, Row, Popconfirm, List, Col, message, Form, Input, DatePicker, Select, Dropdown } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import numeral from "numeral";
import * as dayjs from "dayjs";
import * as XLSX from "xlsx";
import api from "api";
import { MoreOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;
function OrderHistory() {
  const [form] = Form.useForm();
  const Tb = React.createRef();
  const [dataPost, setDataPost] = useState();
  const getColumn = () => {
    var columns = [
      {
        title: "Thời gian",
        dataIndex: "transTime",
        key: "transTime",
        sorter: true,
        render: (text, record) => {
          if (text) {
            return moment(text).format("DD/MM/YYYY HH:mm");
          }
        },
      },
      {
        title: "Hình thức",
        dataIndex: "method",
        key: "method",
        sorter: true,
        // render: (text, record, index) => {
        //   return <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY")}</p>;
        // },
      },
      {
        title: "Mã đơn",
        dataIndex: "loyaltyOrderId",
        key: "loyaltyOrderId",
        sorter: true,
        // render: (text) => {
        //   return text?.phone;
        // },
      },
      {
        title: "Mã đơn - ZALO",
        dataIndex: "orderId",
        key: "orderId",
        sorter: true,
        // render: (text) => {
        //   return text?.name;
        // },
      },
      {
        title: "Thành tiền",
        dataIndex: "amount",
        key: "amount",
        sorter: true,
        render: (text) => {
          return numeral(text).format("0,0");
        },
        // render: (text, record) => {
        //   return `${numeral(text).format("0,0")} (${record.line_items
        //     .map((item) => {
        //       return item.quantity;
        //     })
        //     .reduce((a, b) => {
        //       return a + b;
        //     })})`;
        // },
        // render: (text, record) => {
        //   if (text) {
        //     var phone = text?.substr(0, 4) + "." + text?.substr(3, 3) + "." + text?.substr(6, 3);
        //     var name = record.customer_name?.replace("null", "").trim();
        //     return (
        //       <Text style={{ marginBottom: "auto", marginTop: "auto" }}>
        //         {phone}
        //         <br />
        //         {name}
        //       </Text>
        //     );
        //   } else {
        //     return record.customer_name?.replace("null", "").trim();
        //   }
        // },
      },
      {
        title: "Trạng thái",
        dataIndex: "resultCode",
        key: "resultCode",
        sorter: true,
        render: (text, record) => {
          if (text === 0) {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#faad14" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Giao dịch đang thực hiện</Text>
              </Space>
            );
          } else if (text === 1) {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="#52C41A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Thành công</Text>
              </Space>
            );
          } else {
            return (
              <Space>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3243_1067)">
                    <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="red" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3243_1067">
                      <rect x="-0.00250244" y="-0.000747681" width="6.0025" height="6.0015" rx="3.00075" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Thất bại</Text>
              </Space>
            );
          }
        },
        //   var string = "";
        //   if (text.length > 0) {
        //     text.forEach((item, index) => {
        //       if (index === 0) {
        //         string = `${item.quantity} ${item.name ? item.name : item.variant_title}`;
        //       } else {
        //         string += `, ${item.quantity} ${item.name ? item.name : item.variant_title}`;
        //       }
        //     });
        //   }
        //   return string;
        // },
        // ellipsis: true,
        // ellipsis: {
        //   showTitle: false,
        // },
        // render: (comment) => {
        //   return (
        //     <Tooltip trigger="hover" placement="topLeft" title={comment}>
        //       <Paragraph style={{ maxWidth: 360 }} ellipsis={{ rows: 2 }}>
        //         {comment}
        //       </Paragraph>
        //     </Tooltip>
        //   );
        // },
      },
      {
        dataIndex: "orderId",
        key: "orderId",
        sorter: true,
        render: (text) => {
          return (
            <Dropdown
              trigger={"click"}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Đồng bộ trạng thái",
                  },
                ],
                onClick: (e) => {
                  syncStatus(text);
                },
              }}
              placement="bottomLeft"
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
        // render: (text) => {
        //   return text?.province;
        // },
      },
    ];
    return columns;
  };

  const syncStatus = (orderId) => {
    api
      .post("order_payment/getorderstatuszalo", { orderId: orderId })
      .then((res) => {
        Tb.current.reload();
      })
      .catch((error) => {
        Tb.current.reload();
        console.log(error);
      });
  };
  const onSearch = () => {
    var newDataPost = form.getFieldsValue();

    if (newDataPost.transTime && newDataPost.transTime.length > 0) {
      newDataPost.transTime = newDataPost.transTime.map((item, index) => {
        if (index === 0) {
          //   console.log(new Date(moment(dayjs(item).format("MM/DD/YYYY")).startOf("day")));
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").toISOString();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").toISOString();
        }
      });
    }
    setDataPost(newDataPost);
  };

  const downloadExcel = () => {
    api.post("order_payment/list", dataPost).then((res) => {
      if (res.data.length > 0) {
        var data = res.data.map((item) => {
          return {
            "Thời gian": item.transTime,
            "Hình thức": item.method,
            "Mã đơn": item.loyaltyOrderId,
            "Mã đơn - ZALO": item.orderId,
            "Thành tiền": item.amount,
            "Trạng thái": item.resultCode === 0 ? "Đang thực hiện" : item.resultCode === 1 ? "Thành công" : "Thất bại",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
      }
    });
  };

  useEffect(() => {
    if (dataPost) {
      Tb.current.reload();
    }
  }, [dataPost]);
  return (
    <Space direction="vertical" size={8} style={{ width: "100%" }}>
      <div style={{ width: "100%", padding: 8, background: "white" }}>
        <Form layout={"inline"} form={form} style={{ padding: 8 }}>
          <Row style={{ width: "100%", margin: 0 }} gutter={[8, 8]}>
            <Col style={{ width: "calc((100% - 200px)/3)" }}>
              <Form.Item name="text" style={{ marginInlineEnd: 0 }}>
                <Input
                  placeholder="Tìm theo mã đơn"
                  onKeyDown={() => {
                    onSearch();
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={5}>
              <Form.Item name="product_name" style={{ marginInlineEnd: 0 }}>
                <Search placeholder="Tên sản phẩm" allowClear onSearch={onSearch} />
              </Form.Item>
            </Col> */}

            <Col style={{ width: "calc((100% - 200px)/3)" }}>
              <Form.Item name="transTime" style={{ marginInlineEnd: 0 }}>
                <RangePicker style={{ width: "100%" }} placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} />
              </Form.Item>
            </Col>

            <Col style={{ width: "calc((100% - 200px)/3)" }}>
              <Form.Item name="resultCode" style={{ marginInlineEnd: 0 }}>
                <Select
                  placeholder="Trạng thái"
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: 1,
                      label: "Thành công",
                    },
                    {
                      value: 0,
                      label: "Đang thực hiện",
                    },
                    {
                      value: -1,
                      label: "Thất bại",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item style={{ marginInlineEnd: 0 }}>
                <Button type="primary" style={{ width: "100%" }} onClick={onSearch}>
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginInlineEnd: 0 }}>
                <Button style={{ width: "100%" }} onClick={downloadExcel}>
                  Xuất Excel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AntTable
        columns={getColumn()}
        customSearch={dataPost}
        tableName={"tbReview"}
        ajax="order_payment/list"
        ref={Tb}
        rowKey={(record) => record.id}
      ></AntTable>
    </Space>
  );
}

export default OrderHistory;
