import {
  Alert,
  Badge,
  Button,
  Col,
  Modal,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import api from "api";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { is } from "immutable";

const { Text } = Typography;
function SyncModal({ isModalOpen, setIsModalOpen, reload, categoryOptions }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [percent, setPercent] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [syncedProduct, setSyncedProduct] = useState(0);
  const [syncedOrder, setSyncedOrder] = useState();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if ((isSync && percent === "100") || !isSync) {
      setIsModalOpen(false);
      setPercent(0);
      setIsSync(false);
      getTotalKiotOrder("all");
      reload();
    }
  };

  const onSync = async () => {
    setIsLoading(true);
    setIsSync(true);
    setIsLoading(false);
    listAllOrder();
  };

  const prepareCustomerData = async () => {
    await api
      .post("app/kiotvietsalon/config/preparedata")
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listAllOrder = async () => {
    await prepareCustomerData();
    var loopTimes = Math.ceil(totalProduct / 50);
    for (let i = 0; i < loopTimes; i++) {
      await api
        .post("app/kiotvietsalon/config/listallorder", {
          pagging: { skip: i * 50, limit: 50, sort: {} },
          category: selectedCategory,
        })
        .then(async (res) => {
          var dataprocess = res.result.data;
          if (dataprocess.length > 0) {
            // setTotalProduct(listWooProduct.length);
            for (let index = 0; index < dataprocess.length; index++) {
              const item = dataprocess[index];
              await syncOrder(item, i * 50 + index, res.result.total);
            }
          }
        });
    }
  };

  const syncOrder = async (kiotvietsalon_order, index) => {
    await api
      .post("app/kiotvietsalon/config/syncorder", kiotvietsalon_order)
      .then((res) => {
        // console.log(syncedProduct + 1);
        setSyncedProduct(index + 1);
        setSyncedOrder(
          kiotvietsalon_order.code + " - " + kiotvietsalon_order.customerName
        );
        setPercent(numeral(((index + 1) / totalProduct) * 100).format("0"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(syncedProduct);
    getTotalKiotOrder();
  }, []);

  const getTotalKiotOrder = (mode) => {
    setIsLoading(true)
    api
      .post("app/kiotvietsalon/config/countorderkiot", { mode: mode })
      .then((res) => {
        setIsLoading(false)
        if (res) {
          setTotalProduct(res.total);
        }
      })
      .catch((err) => {
        setTotalProduct(0);
        setIsLoading(false)
        console.log(err);
      });
  };

  return (
    <Modal
      className="sync-modal"
      title="Đồng bộ đơn hàng KIOTVIETSALON"
      footer={
        isSync
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
      onCancel={handleCancel}
      width={680}
      styles={{ body: {minHeight: '250px',alignContent:"center"} }}
      open={isModalOpen}
      destroyOnClose={true}
    >
      {isSync ? (
        percent === "100" ? (
          <Result
            status="success"
            title="Đồng bộ thành công"
            subTitle={`Có ${totalProduct} đơn hàng đã được đồng bộ vào hệ thống`}
            extra={[<Button onClick={handleCancel}>Thoát</Button>]}
          />
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Progress type="circle" percent={percent} size="default" />
              <Text>
                {syncedProduct}/{totalProduct}
              </Text>
              <Text>{syncedOrder}</Text>
            </Space>
          </Row>
        )
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            className="sync-alert"
            message="Lưu ý"
            description={
              <Space direction="vertical">
                <Badge
                  color="black"
                  text="Thông tin khách hàng và đơn hàng sẽ được kéo từ KIOTVIET"
                />
                <Badge color="black" text="Chỉ lấy các đơn hoàn thành" />
                <Badge
                  color="black"
                  text="Việc đồng bộ sẽ chạy tự động 2h sáng hàng ngày"
                />
              </Space>
            }
            type="info"
            showIcon
            icon={<InfoCircleOutlined />}
          />
          <Row justify={"center"}>
            <Select
              style={{ width: 300 }}
              defaultValue={"all"}
              options={[
                { value: "all", label: "Tất cả" },
                { value: "last7days", label: "7 ngày gần nhất" },
              ]}
              onChange={(e) => {
                getTotalKiotOrder(e);
                setSelectedCategory(e);
              }}
            ></Select>
          </Row>

          <Row justify={"center"}>
            <Button
              style={{ width: 300 }}
              type="primary"
              loading={isLoading}
              icon={!isLoading ? <SyncOutlined /> : ""}
              onClick={onSync}
              disabled = {totalProduct === 0 ?true:false}
            >
              Đồng bộ {totalProduct} đơn hàng
            </Button>
          </Row>
        </Space>
      )}
    </Modal>
  );
}

export default SyncModal;
