import React, { useRef, useImperativeHandle } from "react";
import { Input } from "antd";
import MergerTagPopup from "./merger_tag/merger_tag_popup";
/**
 * prop:{value, name, onchange}
 * value: require - giá trị của picker
 * name: tên của picker
 * onchange:require - xử lý data khi picker thay đổi
 * @author lqhoang
 */
const TextPicker = React.forwardRef((props, ref) => {
  const [cursorIndex, setCursorIndex] = React.useState([0, 0]);
  const [value, setValue] = React.useState(props.value);
  const [rdNumber, setRdNumber] = React.useState(Math.random());
  const localtionMouseDown = React.useRef({ screenX: 0, screenY: 0 });
  const { TextArea } = Input;

  React.useEffect(() => {
    document.getElementById("input" + rdNumber).addEventListener("keyup", function (e) {
      setCursorIndex([this.selectionStart, this.selectionEnd]);
    });

    document.getElementById("input" + rdNumber).addEventListener("mousedown", function (e) {
      localtionMouseDown.current = { screenX: e.screenX, screenY: e.screenY };
    });

    document.getElementById("input" + rdNumber).addEventListener("mouseup", function (e) {
      if (
        (localtionMouseDown.current?.screenX == null ||
          localtionMouseDown.current?.screenY == null ||
          e?.screenX != localtionMouseDown.current?.screenX ||
          e?.screenY != localtionMouseDown.current?.screenY) &&
        this.selectionStart == this.selectionEnd
      )
        setCursorIndex([0, this.selectionEnd]);
      else setCursorIndex([this.selectionStart, this.selectionEnd]);
    });
  }, []);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  /**
   * xử lý data khi các chọn dropdown
   * @param {string} e
   * @returns
   * @author lqhoang
   */
  const dropDownChange = (e) => {
    var data = value ? value.slice(0, cursorIndex[0]) + "{{" + e + "}}" + value.slice(cursorIndex[1]) : "{{" + e + "}}";
    setValue(data);
    if (props.onChange) props.onChange(data, props.name);
    setTimeout(() => {
      const input = document.getElementById("input" + rdNumber);
      input.focus();
      var index = cursorIndex[1] - (cursorIndex[1] - cursorIndex[0]) + (e.length + 4);
      input.setSelectionRange(index, index);
      setCursorIndex([index, index]);
      localtionMouseDown.current = { screenX: index, screenY: index };
    }, 50);
  };
  const textAreaRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        checkDataTextArea: checkDataTextArea,
      };
    },
    [props]
  );
  const checkDataTextArea = () => {
    const textAreaValue = textAreaRef.current.resizableTextArea.textArea.value;
    if (!textAreaValue) {
      return "Vui lòng nhập nội dung gửi";
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <TextArea
          ref={textAreaRef}
          id={"input" + rdNumber}
          rows={props.rows ? props.rows : 1}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) props.onChange(e.target.value, props.name);
          }}
          // suffix={<MergerTagPopup onChange={dropDownChange}></MergerTagPopup>}
          style={{ paddingRight: 30, resize: "none" }}
        ></TextArea>
        <span
          style={{
            margin: 0,
            position: "absolute",
            top: "15.5px",
            right: "0",
            transform: "translate(-50%, -50%)",
            color: "#1890FF",
          }}
        >
          <MergerTagPopup onChange={dropDownChange}></MergerTagPopup>
        </span>
      </div>
    </>
  );
});

export default TextPicker;
