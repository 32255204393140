import React, { Component } from "react";
import api from "api";
import { Input, Button, Form, Row, Col, message, Modal, Typography } from "antd";

const { Text } = Typography;

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        api_key: "",
        secret_key: "",
        user_id: 0,
        id: null,
      },
      type: "",
      mode: "",

      isBtnLoading: false,
      isValidate: false,
      messageLength: 0,
    };
    this.inputType = React.createRef();

    this.form = React.createRef();
  }

  saveSetting = (data) => {
    this.setState({
      isBtnLoading: true,
    });
    var mode = this.state.mode;
    data.id = this.state.data.id;
    var dataPost = { config: data, type: mode };
    api
      .post("app/esms/config/save", dataPost)
      .then((res) => {
        message.success("Lưu thành công", 3);
        this.props.getConfig();
        this.setState({ visible: false, data: {}, isBtnLoading: false });
      })
      .catch((err) => {
        // console.log("err", err);
        message.error(err.message, 3);
        this.setState({ isBtnLoading: false });
      });
  };

  open = async (mode, element) => {
    if (mode === "add") {
      var data = {
        name: "",
        auth: {
          api_key: "",
          secret_key: "",
        },
      };
      this.setState({ data: data, visible: true, mode: mode }, () => {
        this.form.current.resetFields();
        this.setDataForm(data);
      });
    } else {
      api.post("app/esms/config/getbyid", { id: element.id }).then((res) => {
        this.setState({ data: res, visible: true, mode: mode }, () => {
          this.setDataForm({ name: res.name, api_key: res.api_key, secret_key: res.secret_key });
        });
      });
    }
  };

  setDataForm = (data) => {
    if (this.form.current) this.form.current.setFieldsValue(data);
  };

  onSubmit = () => {
    if (this.form.current) this.form.current.submit();
  };

  render() {
    return (
      <Modal
        title="Cấu hình ESMS "
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={[
          <Button htmlType="submit" type="primary" loading={this.state.isBtnLoading} onClick={() => this.onSubmit()}>
            Lưu
          </Button>,
        ]}
      >
        <Form ref={this.form} layout="vertical" onFinish={this.saveSetting} id="myform">
          <Row>
            <Col span={24}>
              <Form.Item
                name={"name"}
                label={<Text strong>Tên</Text>}
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập tên!",
                  },
                ]}
              >
                <Input placeholder="Tên" style={{ width: "100%", borderRadius: "5px" }} />
              </Form.Item>
              <Form.Item
                name={"api_key"}
                label={<Text strong>API Key</Text>}
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập API Key!",
                  },
                ]}
              >
                <Input placeholder="API Key" style={{ width: "100%", borderRadius: "5px" }} />
              </Form.Item>

              <Form.Item
                name={"secret_key"}
                label={<Text strong>Secret Key</Text>}
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập Secret!",
                  },
                ]}
              >
                <Input placeholder="Secret Key" style={{ width: "100%", borderRadius: "5px" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
