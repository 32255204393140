import { Col, Divider, Dropdown, Form, Input, message, Row, Select, Switch, Typography, Button, Badge, Modal, Space, Drawer } from "antd";
import { MoreOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import api from "api";

import AntTable from "components/common/AntTable";
import ModalAddEditUser from "../userManagement/ModalAddEditUser";
import moment from "moment";
const { Title, Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;
function OaManagement() {
  const [dataTable, setDataTable] = useState([]);
  const [isLoadingTable, setLoadingTable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState();
  const [currentRecord, setCurrentRecord] = useState();

  const [filter, setFilter] = useState();
  const [form] = Form.useForm();
  const refTable = React.createRef();
  const refTable2 = React.createRef();
  const [shopList, setShopList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const columns = [
    {
      title: "ZALO ID",
      dataIndex: "oas",
      key: "oas",
      //   sorter: true,
      //   align: "center",
      render: (text, render) => {
        if (text?.length > 0) {
          return text[0].oa_id;
        } else {
          return "";
        }
      },
    },
    {
      title: "ZALO OA",
      dataIndex: "oas",
      key: "oas",
      //   sorter: true,
      //   align: "center",
      render: (text, render) => {
        if (text?.length > 0) {
          return text[0].name;
        } else {
          return "";
        }
      },
    },
    {
      title: "Shop",
      dataIndex: "shop_id",
      key: "shop_id",
      //   sorter: true,
      //   align: "center",
      render: (text, record) => {
        if (text && shopList.length > 0) {
          var findName = shopList.find((item) => {
            return item.value === text;
          });
          if (findName) {
            return findName.label;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "oas",
      key: "oas",
      //   sorter: true,
      //   align: "center",
      render: (text, record) => {
        if (text?.length > 0) {
          if (new Date(text[0].token_create_date) < new Date()) {
            return <Badge color="green" text="Hoạt động" />;
          } else {
            return <Badge color="red" text="Không hoạt động" />;
          }
        } else {
          return "";
        }
      },
    },

    {
      dataIndex: "oas",
      key: "oas",
      align: "center",
      render: (text, record, index) => {
        console.log(record);
        const items = [
          {
            label: "Xoá OA khỏi shop",
            key: "1",
            onClick: () => {
              confirm({
                title: "Bạn có muốn xoá OA này khỏi shop?",
                icon: <ExclamationCircleOutlined />,
                content: (
                  <Space direction="vertical">
                    <Text>ZALO OA ID: {text[0].oa_id}</Text>
                    <Text>ZALO OA NAME: {text[0].name}</Text>
                  </Space>
                ),
                okText: "Đồng ý",
                cancelText: "Thoát",
                onOk() {
                  deleteOa(text[0].oa_id, record.shop_id);
                },
              });
            },
          },
          {
            label: "Xem mẫu tin OA",
            key: "2",
            onClick: () => {
              setSelectedItem(record);
              setOpen(true);
            },
          },
        ];
        return (
          <Dropdown
            trigger="click"
            menu={{
              items,
            }}
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const deleteOa = (oa_id, shop_id) => {
    console.log(shop_id);
    api
      .delete("master/app_zalo/deleteoa", { data: { oa_id: oa_id, shop_id: shop_id } })
      .then((res) => {
        refTable.current.reload();
      })
      .catch((err) => {
        refTable.current.reload();
        console.log(err);
      });
  };

  useEffect(() => {
    if (isModalOpen === false) {
      refTable.current.reload();
    }
  }, [isModalOpen, filter]);

  useEffect(() => {
    getAllShop();
  }, []);

  const getAllShop = () => {
    api
      .post("master/shop/listall", { options: { projection: { id: 1, name: 1, _id: 0 } } })
      .then((res) => {
        console.log(res);
        if (res.length > 0) {
          setShopList(
            res.map((item) => {
              return { value: item.id, label: item.name };
            })
          );
        } else {
          setShopList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDetailTemplate = (template_id) => {
    api
      .post("master/app_zalo/getdetailtemplate", { shop_id: selectedItem?.shop_id, oa_id: selectedItem.oas[0].oa_id, template_id: template_id })
      .then((res) => {
        console.log(res);
        setPreviewUrl(res.previewUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Title level={4}>Danh sách ZALO OA</Title>
      <Row style={{ backgroundColor: "white" }}>
        <Col span={24} style={{ padding: 16 }}>
          <Form form={form} name="filter" layout="inline">
            <Form.Item name="text" style={{ width: "calc((100% - 89px) / 2)" }}>
              <Input
                placeholder="Tìm bằng tên Zalo OA hoặc OA ID"
                style={{ width: "100%" }}
                onKeyDown={() => {
                  var data = form.getFieldsValue();
                  setFilter(data);
                }}
              />
            </Form.Item>
            <Form.Item name="shop_id" style={{ width: "calc((100% - 155px) / 2)" }}>
              <Select
                // defaultValue="all"
                style={{ width: "100%" }}
                options={shopList}
                placeholder="Cửa hàng"
              />
            </Form.Item>

            <Form.Item style={{ marginInlineEnd: 0 }}>
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={() => {
                  var data = form.getFieldsValue();
                  setFilter(data);
                }}
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
          <AntTable
            columns={columns}
            autoLoad={true}
            customSearch={filter}
            tableName={"tbUser"}
            ajax="master/app_zalo/listpagging"
            ref={refTable}
            rowKey={(record) => record.id}
          ></AntTable>
        </Col>
      </Row>
      {open && selectedItem && (
        <Drawer
          title={`Danh sách mẫu tin ZALO OA [${selectedItem?.oas[0]?.name}]`}
          onClose={() => {
            setOpen(false);
            setPreviewUrl();
          }}
          open={open}
          width="80vw"
          bodyStyle={{ padding: 0 }}
        >
          <Row>
            <Col span={12}>
              <AntTable
                columns={[
                  {
                    title: "Mã Template",
                    dataIndex: "templateId",
                    key: "templateId",
                    //   sorter: true,
                    //   align: "center",
                    // render: (text, render) => {
                    //   if (text?.length > 0) {
                    //     return text[0].oa_id;
                    //   } else {
                    //     return "";
                    //   }
                    // },
                  },
                  {
                    title: "Tên mẫu tin",
                    dataIndex: "templateName",
                    key: "templateName",
                    //   sorter: true,
                    //   align: "center",
                    // render: (text, render) => {
                    //   if (text?.length > 0) {
                    //     return text[0].oa_id;
                    //   } else {
                    //     return "";
                    //   }
                    // },
                  },
                  {
                    title: "Trạng thái",
                    dataIndex: "status",
                    key: "status",
                    //   sorter: true,
                    //   align: "center",
                    render: (text, render) => {
                      if (text === "ENABLE") {
                        return <Badge color="green" text="Đã duyệt" />;
                      } else {
                        return <Badge color="red" text="Chưa duyệt" />;
                      }
                    },
                  },
                  {
                    // title: "Trạng thái",
                    dataIndex: "templateId",
                    key: "templateId",
                    //   sorter: true,
                    //   align: "center",
                    render: (text, render) => {
                      return (
                        <Button
                          type="link"
                          onClick={() => {
                            getDetailTemplate(text);
                          }}
                        >
                          Xem
                        </Button>
                      );
                    },
                  },
                ]}
                autoLoad={true}
                customSearch={{ shop_id: selectedItem.shop_id, oa_id: selectedItem.oas[0].oa_id }}
                tableName={"tbTemplate"}
                ajax="master/app_zalo/gettemplatesandprice"
                ref={refTable2}
                rowKey={(record) => record.id}
              ></AntTable>
            </Col>
            <Col span={12}>
              <iframe style={{ top: "0px", width: "100%", height: "100vh" }} frameborder="0" allowfullscreen scrolling="no" src={previewUrl || ""}></iframe>
            </Col>
          </Row>
        </Drawer>
      )}
      <ModalAddEditUser isModalOpen={isModalOpen} mode={mode} record={currentRecord} setIsModalOpen={setIsModalOpen}></ModalAddEditUser>
    </div>
  );
}

export default OaManagement;
