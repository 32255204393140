import { useState, useEffect, useRef } from "react";
import { Card, Row, Tabs, Button, message, Popconfirm, Empty, Typography, Image, Space, Switch } from "antd";
import api from "api";
import { handleTemplateDescription } from "../../common/text.js";
import PopupCreatePointProgram from "./popupcreatepointprogram";
import SearchBox from "components/common/SearchBox/SearchBox";
import { createSearchText } from "../../common/text.js";
import AntTable from "components/common/AntTable";

const { Text, Title } = Typography;

function PointProgramList() {
  const [programLst, setProgramLst] = useState([]);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("point_reward");
  const [filter, setFilter] = useState({
    query: {
      $and: [{ type: "point_reward" }, { is_delete: false }],
    },
  });
  const popupRef = useRef();
  const Tb = useRef("TB");

  useEffect(() => {
    getConfig();
  }, [])
  useEffect(() => {
    if(Tb.current!=="TB")
      Tb.current.reload(undefined,false);
  }, [filter])

  const getConfig = () => {
    api.post("pointprogram/getconfigdetails", filter).then((res) => {
      var programLst = res.data.filter((z) => z.id != "0");
      programLst = programLst.map((config) => {
        config = handleTemplateDescription(config);
        return config;
      });

      setProgramLst(programLst);
      setVisible(true);
    });
  };

  const deleteConfig = (id) => {
    api.post("pointprogram/deleteconfig", { id: id })
      .then((res) => {
        message.success("Xoá thành công");
        getConfig();
      })
      .catch((err) => {
        message.error("Có lỗi xảy ra khi xoá");
      });
  };

  const navigateEdit = (item) => {
    var href = "";
    api.post("pointprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = res.config_url + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };

  const onFilterChange = (type, value) => {
    var newFilter = { ...filter };
    var index = newFilter.query.$and.findIndex((x) => x[type]);
    if (value == "") {
      if (index > -1);
      newFilter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        newFilter.query.$and[index][type] = { $regex: value };
      } else {
        newFilter.query.$and.push({ [type]: { $regex: value } });
      }
    }

    setFilter(newFilter)
    getConfig()
  };

  /**
   * Hàm xử lý khi nhấn vào switch
   * @param {boolean} checked 
   * @param {string} programID 
   */
  const onChangeSwitch = (checked, record) => {
    record.is_active = checked;
    api.post("pointprogram/saveconfig", { config: record })
      .then((res) => {
        message.success("Cập nhật trạng thái thành công");
        getConfig();
      }).catch((err) => {
        message.error("Có lỗi xảy ra khi cập nhật trạng thái");
      })
  }

  const onTabsChange = (value) => {
    setType(value)
    onFilterChange("type", value);
  };

  const items = [
    {
      key: "point_reward",
      label: `Mua hàng tích điểm`,
      children: (
        <RenderTable
          programLst={programLst}
          deleteConfig={deleteConfig}
          navigateEdit={navigateEdit}
          onChangeSwitch={onChangeSwitch}
          popupRef={popupRef}
          filter={filter}
          Tb={Tb}
        />
      )
    },
    {
      key: "happ_birthday",
      label: `Chúc mừng sinh nhật`,
      children: (
        <RenderTable
          programLst={programLst}
          deleteConfig={deleteConfig}
          navigateEdit={navigateEdit}
          onChangeSwitch={onChangeSwitch}
          popupRef={popupRef}
          filter={filter}
          Tb={Tb}
        />
      )
    },
  ];

  return (
    <div>
      <Card style={{ backgroundColor: "#ffffff" }}>
        <SearchBox
          name="z"
          data={[]}
          placeholder="Thông tin"
          dataButton={[
            <Button type="primary" onClick={() => popupRef.current.open()} htmlType="submit">
              <i className="fa fa-plus"></i> &nbsp; Tạo Mới
            </Button>,
          ]}
          onSearch={getConfig}
          onChange={(value) => {
            onFilterChange("name", value);
            // onFilterChange("search_text", createSearchText(value));
          }}
        />
      </Card>
      <Card style={{ marginTop: 24 }}>
        <Tabs activeKey={type} onChange={(key) => onTabsChange(key)} items={items} />
      </Card>

      <PopupCreatePointProgram ref={popupRef} reload={getConfig}></PopupCreatePointProgram>
    </div>
  )
}

function RenderTable({ programLst, deleteConfig, navigateEdit, onChangeSwitch, popupRef, filter,Tb }) {
  // console.log('programLst', programLst);
  var columns = [
    {
      title: '',
      render: (text, record, index) => {
        return (
          <Switch checked={record.is_active} onChange={(checked, event) => onChangeSwitch(checked, record)} />
        )
      },
      width: 40
    },
    {
      title: 'Biểu tượng',
      dataIndex: 'logo',
      key: 'logo',
      width: 120,
      render: (text) => {
        return (<Image src={text} preview={false} width={48} />)
      }
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return (
          <Space direction="vertical">
            <Text strong>{text}</Text>
            <Text type="secondary">{record.short_description}</Text>
          </Space>
        )
      }
    },
    {
      title: 'Đối tượng',
      render: (text, record, index) => {
        switch (record.conditions.participants.type) {
          case "all":
            return (<Text>Tất cả</Text>);
          case "tier":
            var tiers = record.loyalty_config[0].tier.tiers;
            var nameTier = tiers.find((e) => {
              if (e.id === record.conditions.participants.tier_id) {
                return e;
              }
            }).name;
            return (<Text>Hạng thành viên: {nameTier}</Text>);
          case "group":
            return (<Text>Tập khách hàng: {record.customer_segment[0].name}</Text>);
          default:
            return (<Text>Tất cả</Text>);
        }
      }
    },
    {
      title: '',
      width: 100,
      render: (text, record, index) => {
        return (
          <Space >
            <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => deleteConfig(record.id)} okText="Có" cancelText="Không">
              <Button type="link" style={{ padding: 0 }}>Xoá</Button>
            </Popconfirm>
            <Button type="link" style={{ padding: 0 }} onClick={() => navigateEdit(record)}>Sửa</Button>
          </Space>
        )
      }
    }
  ];
  return (
    <div>
      {programLst.length > 0 ? (
        <AntTable
          columns={columns}
          customSearch={filter}
          tableName={"tbCustomerPointprogram"}
          ajax="pointprogram/getconfigdetails"
          ref={Tb}
        />
      ) : (
        <Card bodyStyle={{ height: "50vh" }}>
          <div>
            <Empty style={{ marginTop: "13vh" }} description="Hãy tạo mới chương trình đầu tiên"></Empty>
            <Row justify="center">
              <Button
                onClick={() => { popupRef.current.open(); }}
                style={{ marginTop: 16 }}
                type="primary"
              >
                <i className="fas fa-plus"></i>&nbsp;Tạo mới
              </Button>
            </Row>
          </div>
        </Card>
      )}
    </div>
  )
}

export default PointProgramList;