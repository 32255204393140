import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import api from "api";
import { formatCurrency, formatK } from "../../common/format/number";

export default class FunnelPlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        send_count: 0,
        open_count: 0,
        exchange_voucher_count: 0,
        used_voucher_count: 0,
      },
    };
  }

  componentDidMount = () => {
    api.post("dashboard/getfunnelchartdata").then((res) => {
      this.setState({ data: res });
    });
  };

  render() {
    return (
      <Card title="Biểu đồ phễu hành vi khách hàng">
        <div style={{ borderTop: "100px solid #6394F9", borderLeft: "80px solid transparent", borderRight: "80px solid transparent", width: "90%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, top: -100, display: "flex", width: "100%" }}>
            <div style={{ margin: "auto" }}>{`Gửi - 100%` + `${formatK(this.state.data.send_count)}`}</div>
          </div>
        </div>
        <div style={{ borderLeft: "80px solid transparent", borderRight: "80px solid transparent", width: "90%" }}>
          <div style={{ borderTop: "100px solid #62DAAA", borderLeft: "60px solid transparent", borderRight: "60px solid transparent", position: "relative" }}>
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, top: -100, display: "flex", width: "100%" }}>
              <div style={{ margin: "auto" }}>
                {`Xem - ${this.state.data.send_count === 0 ? "100%" : Math.floor((this.state.data.open_count / this.state.data.send_count) * 10000) / 100 + "%"} (${formatK(
                  this.state.data.open_count
                )})`}
              </div>
            </div>
            <div style={{ position: "absolute", right: -110, top: -100, borderTop: "1px solid #000000", width: 50 }}></div>
            <div style={{ position: "absolute", right: -170, top: -115 }}>
              {this.state.data.send_count === 0 ? "100%" : Math.floor((this.state.data.open_count / this.state.data.send_count) * 10000) / 100 + "%"}
            </div>
          </div>
        </div>
        <div style={{ borderLeft: "140px solid transparent", borderRight: "140px solid transparent", width: "90%" }}>
          <div style={{ borderTop: "100px solid #657797", borderLeft: "20px solid transparent", borderRight: "20px solid transparent", position: "relative" }}>
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, top: -100, display: "flex", width: "100%" }}>
              <div style={{ margin: "auto" }}>
                {`Đổi voucher - ${this.state.data.send_count === 0 ? "100%" : Math.floor((this.state.data.exchange_voucher_count / this.state.data.send_count) * 10000) / 100 + "%"} (${formatK(
                  this.state.data.exchange_voucher_count
                )})`}
              </div>
            </div>
            <div style={{ position: "absolute", right: -70, top: -100, borderTop: "1px solid #000000", width: 50 }}></div>
            <div style={{ position: "absolute", right: -130, top: -115 }}>
              {this.state.data.open_count === 0 ? "100%" : Math.floor((this.state.data.exchange_voucher_count / this.state.data.open_count) * 10000) / 100 + "%"}
            </div>
          </div>
        </div>
        <div style={{ borderLeft: "160px solid transparent", borderRight: "160px solid transparent", width: "90%" }}>
          <div style={{ backgroundColor: "#F6C021", position: "relative", height: 100 }}>
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, top: 0, display: "flex", width: "100%" }}>
              <div style={{ margin: "auto" }}>
                {`Dùng voucher - ${this.state.data.send_count === 0 ? "100%" : Math.floor((this.state.data.used_voucher_count / this.state.data.send_count) * 10000) / 100 + "%"} (${formatK(
                  this.state.data.used_voucher_count
                )})`}
              </div>
            </div>
            <div style={{ position: "absolute", right: -50, top: 0, borderTop: "1px solid #000000", width: 50 }}></div>
            <div style={{ position: "absolute", right: -110, top: -15 }}>
              {this.state.data.exchange_voucher_count === 0 ? "100%" : Math.floor((this.state.data.used_voucher_count / this.state.data.exchange_voucher_count) * 10000) / 100 + "%"}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}
