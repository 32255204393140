import React, { Component } from "react";
import { Input, Button, message, Typography, Popconfirm, Form } from "antd";
import api from "api";
import { SmileOutlined } from '@ant-design/icons';
import AntTable from "components/common/AntTable";
const { Paragraph } = Typography;
export default class QrCodeConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text1: "",
            text2: "",
            token: "",
            data: {},
        }
        this.form = React.createRef();
    }

    componentDidMount = () => {
        api.post("pointcode/getconfig").then(res => {
            this.setState({ data: res, token: res.token }, () => {
                this.form.current.setFieldsValue({
                    text1: this.state.data.text1,
                    text2: this.state.data.text2
                });
            });
        })
    }

    saveConfig = (values) => {
        if (values?.text1 == "" || values?.text1 == undefined) {
            var text1 = "Cảm ơn quý khách đã sử dụng dịch vụ của chúng tôi."
            values.text1 = text1

        }
        if (values?.text2 == "" || values?.text2 == undefined) {

            var text2 = "Quý khách vui lòng quét mã QR để tích điểm."
            values.text2 = text2
            console.log("ghsdjhw", values.text2)

        }
        console.log("vaoday", values.text1)
        api.post("pointcode/saveconfig", { text1: values.text1, text2: values.text2, token: this.state.token }).then(res => {
            console.log("resss", res)
            message.success("Lưu thành công");
        }).catch(err => {
            message.error("Lưu thất bại")
        })
    }

    getAccessToken = () => {
        api
            .post("pointcode/getaccesstoken")
            .then((res) => {
                message.success("Lấy token thành công");
                this.setState({
                    token: res
                });
            })
            .catch((err) => {
                message.error("Lấy token thất bại");
            });
    };


    render() {
        return (
            <>
                <div style={{ border: "1px solid #dfdfdf", padding: 20 }}>
                    <div style={{ marginBottom: 20 }}>
                        <label style={{fontSize: 16}}>Lấy mã QR</label>
                    </div>

                    <div style={{ height: 260, backgroundColor: "#ffffff", padding: 20 }}>

                        <div style={{ marginTop: 20 }}>
                            <Form
                                name="basic"
                                onFinish={this.saveConfig}
                                layout='vertical'
                                ref={this.form}

                            >
                                <Form.Item
                                    label="Văn bản 1"
                                    name="text1"
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, error) => {
                                                    if (value == undefined) {
                                                        resolve();
                                                    }
                                                    if (value.length < 1000) {
                                                        resolve();
                                                    } else {
                                                        error();
                                                    }
                                                });
                                            },
                                            message: "Không nhập quá 1000 kí tự!",
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            this.setDataForm(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Văn bản 2"
                                    name="text2"
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, error) => {
                                                    if (value == undefined) {
                                                        resolve();
                                                    }
                                                    if (value.length < 1000) {
                                                        resolve();
                                                    } else {
                                                        error();
                                                    }
                                                });
                                            },
                                            message: "Không nhập quá 1000 kí tự!",
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            this.setDataForm(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Form>



                        </div>
                        <div style={{ display: "flex", float: "right" }}>

                            <Button
                                style={{ marginRight: 10 }}
                                type="primary"
                                onClick={() => this.form.current.submit()}
                                htmlType="submit"
                            >
                                Lưu
                            </Button>

                        </div>

                    </div>

                    <div style={{margin: "20px 0px 20px 0px"}}>
                        <label style={{fontSize: 16}}>Lấy mã tích hợp</label>
                    </div>

                    <div style={{backgroundColor:"#ffffff", height: 80}}>

                        <div style={{ marginTop: 20, display: "flex", padding: 20 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 20, paddingTop: 5 }}>
                                    <Paragraph copyable={{ text: `https://api.cubetiger.app/api/pointqr/qrcodeimg?id={order_code}&note={order_note}&total_amount={total}&access_token=${this.state.token}` }} >Copy link image Sapo POS</Paragraph>
                                </div>
                                <div style={{ marginRight: 20, paddingTop: 5 }}>
                                    <Paragraph copyable={{ text: `https://api.cubetiger.app/api/pointcode/qrcodeimg?id={Ma_Don_Hang}&note=note&total_amount={Tong_Tien_Hang}&access_token=${this.state.token}` }} >Copy link image KiotViet</Paragraph>
                                </div>
                            </div>
                            {
                                this.state.token &&
                                <div style={{ marginRight: 20, paddingTop: 5 }}>
                                    <Paragraph copyable={{ text: `https://api.cubetiger.app/api/pointqr/qrcodeimg??id=order_id&note=note&total_amount=total_amount&access_token=${this.state.token}` }} >Copy mã link</Paragraph>
                                </div>
                            }

                            <div style={{ marginRight: 15 }}>
                                <Popconfirm title="Các mã tích hợp cũ sẽ bị vô hiệu hoá nếu bạn lấy mã tích hợp mới. Bạn có chắc chắn muốn lấy mã tích hợp mới?" onConfirm={() => { this.getAccessToken() }} okText="Có" cancelText="Không">
                                    <Button
                                        type="link"
                                    >
                                        Lấy mã token mới
                                    </Button>
                                </Popconfirm>

                            </div>



                        </div>
                    </div>
                </div>


            </>
        )
    }
}