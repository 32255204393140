import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip, Table, Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, CarryOutOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Pie, Line } from "@ant-design/plots";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency } from "../common/format/number";
import numeral from "numeral";
import PopUpSearchInfo from "./popupSearchInfo";
const { Text, Link } = Typography;

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        create_date: {
          start: moment().add("days", -7).format("YYYY-MM-DD"),
          //start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
      },
      data: { data: [], sum: 0 },
    };
    this.popupSearchInfo = React.createRef();
  }

  componentDidMount = () => {
    this.getSendStatistic();
  };

  getSendStatistic = () => {
    api.post("dashboard/getsendstatistic", this.state.filter).then((data) => {
      this.setState({ data: data });
    });
  };

  render() {
    return (
      <Card
        style={{ height: "100%" }}
        bodyStyle={{ height: "100%" }}
        title="Thống kê tin gửi "
        extra={
          <Button onClick={(e) => (window.location = "/sendhistory")} type="link">
            Chi tiết
          </Button>
        }
      >
        <Table
          dataSource={this.state.data.data}
          columns={[
            {
              title: "Hình thức",
              dataIndex: "name",
              key: "name",
              sorter: false,
              render: (text, record, index) => {
                if (record.source.type == "customer_notification") {
                  return `[Thông báo] ${record.event_name}`;
                }

                if (record.source.type == "campaign") {
                  return `[Chiến dịch] ${record.campaign_name ?? "[Đã xoá]"}`;
                }
              },
            },
            {
              title: `Số lượng(${this.state.data.sum})`,
              dataIndex: "quantity",
              key: "quantity",
              sorter: true,
              render: (text, record, index) => {
                return <div>{record.count}</div>;
              },
            },
            {
              title: "Tỉ lệ",
              dataIndex: "link",
              key: "link",
              sorter: true,
              render: (text, record, index) => {
                return <div>{numeral(record.percent).format("0.00%")}</div>;
              },
            },
            {
              title: "",
              dataIndex: "more",
              key: "more",
              sorter: false,
              render: (text, record, index) => {
                return (
                  <div>
                    <Link
                      onClick={() => {
                        this.popupSearchInfo.current.open(record);
                      }}
                      underline
                    >
                      Xem
                    </Link>
                  </div>
                );
              },
            },
          ]}
        ></Table>
        <PopUpSearchInfo ref={this.popupSearchInfo} />
      </Card>
    );
  }
}
