var DateExtendOperator = {
    date_today_before_days: {
        type: "number",
        label: "Trước đây",
        labelForFormat: "date_today_before",
        cardinality: 1,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => {
            return mongoFormatOpDateAdd(field, op, -value);
        },
        cardinality: 1,

    },
    date_today_after_days: {
        label: "Sau đây",
        labelForFormat: "date_today_before",
        cardinality: 1,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => {
            return mongoFormatOpDateAdd(field, op, value);
        },
    },

    date_today: {
        label: "Trong Hôm Nay",
        labelForFormat: "date_today",
        cardinality: 0,
        jsonLogic: "none",
        mongoFormatOp: (field, op, value) => {
            return mongoFormatOpDateAdd(field, op, 0);
        },
    },


}


const mongoFormatOpDateAdd = (field, op, value) => {


    if (field.startsWith("$")) {
        return (
            {
                $and: [
                    { $gte: [field, { '$add_time': { value: value, key: "days" } }] },
                    { $lt: [field, { '$add_time': { value: value + 1, key: "days" } }] },
                ]
            }

        )

    } else {

        var a1 = {};
        var a2 = {};

        a1[field] = { $gte: { '$add_time': { value: value, key: "days" } } };
        a2[field] = { $lt: { '$add_time': { value: value + 1, key: "days" } } };
        var a =
        {
            $and: [
                a1,
                a2
            ]
        }

        return a;




    }
};


export default DateExtendOperator;