import React, { Component } from "react";
import { Modal, Input, Button } from "antd";
import ReactSrcDocIframe from "../../../common/iframe-doc";
const { TextArea } = Input;
const style = {
  fontSize: "14px",
  minHeight: "0 !important",
  maHeight: "none !important",
  height: "0 !important",
  visibility: "hidden !important",
  overflow: "hidden !important",
  position: "absolute !important",
  zIndex: "-1000 !important",
  top: " 0 !important",
  right: "0 !important",
};

export default class TabsHtml extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      loading: false,
      visible: false,
      value: "",
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOkButton = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancelButton = () => {
    this.setState({ visible: false });
  };

  open = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
    this.props.getHtml(this.state.value);
  };

  onChange = (value) => {
    this.setState({ value: value });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <Modal
          title="HTML/Simple Text Editor"
          open={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"70%"}
          bodyStyle={{ minHeight: "50%" }}
          footer={[
            <Button onClick={this.showModal}>Open with the Rich Text Editor</Button>,
            <Button onClick={this.handleCancel}>Cancel</Button>,
            <Button type="primary" onClick={this.handleOk}>
              Ok
            </Button>,
          ]}
        >
          <div>
            <TextArea
              autoSize={{ minRows: 25, maxRows: 25 }}
              style={style}
              value={this.state.value}
              onChange={(e) => {
                this.onChange(e.target.value);
              }}
            />
          </div>

          <Modal
            open={visible}
            title="Confirm"
            onOk={this.handleOkButton}
            onCancel={this.handleCancelButton}
            width={"70%"}
            footer={[
              <Button key="back" onClick={this.handleCancelButton}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={this.handleOkButton}>
                Ok
              </Button>,
            ]}
          >
            <ReactSrcDocIframe style={{ marginBottom: 10, height: "60vh" }} srcDoc={this.state.value} width="100%" />
          </Modal>
        </Modal>
      </>
    );
  }
}
