import React, { Component } from "react";
import { Button, Spin, Row, Col, Form, Input, Checkbox, Result, message, Space } from "antd";
import api from "api";
import { toNoUnicode } from "../../common/text.js";

export default class ActiveResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      err: null,
      isChangeName: false,
      shopName: "",
    };
  }
  componentDidMount = () => {
    this.active();
  };

  active = () => {
    const queryParams = new URLSearchParams(window.location.search);
    var token = queryParams.get("token");
    api
      .post("/signup/accountactivated", { token: token, name: this.state.shopName })
      .then((res) => {
        this.setState({ loading: false, success: true });
      })
      .catch((err) => {
        console.log("dfhdfg", err);
        if (err.status === 101) {
          this.setState({ loading: false, success: false, err: err.message, isChangeName: true });
        } else this.setState({ loading: false, success: false, err: err.message });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin size="large" />
          </div>
        </>
      );
    } else {
      return (
        <>
          {this.state.success ? (
            <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ padding: 5, border: "8px solid green", width: "70%", borderRadius: 5 }}>
                <Result
                  status="success"
                  title="Kích hoạt tài khoản thành công"
                  subTitle="Tên đăng nhập của bạn là địa chỉ email của bạn"
                  extra={[
                    <Button type="primary" key="console" href="/login">
                      Đi tới trang đăng nhập
                    </Button>,
                  ]}
                />
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ padding: 5, border: "8px solid red", width: "70%", borderRadius: 5 }}>
                <Result
                  status="error"
                  title="Kích hoạt tài khoản thất bại"
                  subTitle={this.state.err}
                  extra={[
                    <div style={{ margin: "20px 0" }} hidden={!this.state.isChangeName}>
                      <Row style={{ display: "flex", justifyContent: "center" }}>
                        <label>Vui lòng nhập tên shop khác:</label>
                      </Row>
                      <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }}>
                          <Input
                            onChange={(e) => {
                              var data = toNoUnicode(e.target.value);

                              this.setState({ shopName: data });
                            }}
                            value={this.state.shopName}
                          ></Input>
                        </Col>
                      </Row>
                    </div>,
                    <div>
                      <Space>
                        <Button key="console" href="/registration">
                          Trở lại trang đăng ký
                        </Button>
                        <Button onClick={() => this.active()} type="primary" hidden={!this.state.isChangeName}>
                          Kích hoạt
                        </Button>
                      </Space>
                    </div>,
                  ]}
                ></Result>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
