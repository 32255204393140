import React from "react";
import { Spin, Result,notification,Button} from "antd";
import api from "api";
import { useSearchParams } from "react-router-dom";

const ReceiveToken = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [succeess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    var code = searchParams.get("code");
    var oa_id = searchParams.get("oa_id");
    api
      .post("app/zalov2/config/sync", { code, oa_id })
      .then((res) => {
        setSuccess(true);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        setLoading(false);
        setTimeout(() => {
          var url=window.location.protocol+"//"+window.location.hostname+"/apps/zalo"
          window.location.replace(url);
        }, 1000);
      })
      .catch((err) => {
        setSuccess(false);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        setMessage(err.message);
      });
  }, []);
  const [notifi, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    notifi.error({
      key:"err",
      message: `Báo Lỗi`,
      description:message,
      placement,
      btn:<Button size="small" type="primary" onClick={()=>{notifi.destroy("err")}}>đồng ý</Button>,
      closeIcon:"",
      duration: 0,
    });
  };
  return (
    <div>
      {contextHolder}
      {loading ? (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin size="large"></Spin>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>Đang xử lý</div>
          </div>
        </div>
      ) : (
        <div>
          {succeess ? (
            <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ padding: 5, border: "8px solid green", width: "70%", borderRadius: 5 }}>
                <Result status="success" title="Đăng nhập ZALO OA Thành công"></Result>
              </div>
            </div>
          ) : (
            // <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            //   <div style={{ padding: 5, border: "8px solid red", width: "70%", borderRadius: 5 }}>
            //     {openNotification("top")}
            //     {/* <BorderTopOutlined status="error" title="Đăng nhập ZALO OA thất bại" subTitle={message}/> */}
            //     {/* <Result status="error" title="Đăng nhập ZALO OA thất bại" subTitle={message}></Result> */}
            //   </div>
            // </div>
             openNotification("top")
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ReceiveToken);
