import React, { Component } from "react";
import { Input, Button, Tag, Card, Popconfirm, Alert, Checkbox, Row, Col, message, Modal } from "antd";
import api from "api";
import AntTable from "components/common/AntTable";
import SearchBox from "components/common/SearchBox/SearchBox";
import Select2 from "components/common/select2";
import moment from "moment";
import AddNewEdit from "./addnewedit";
import * as Filter from "./Filter";
import ChangeUserPassword from "./change_password/change_user_password";
const { Search } = Input;

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.filters = [
      {
        Key: "role",
        Name: "Phân quyền",
        Component: <Filter.SelectRole displayCol="name" valueCol="id" name="role" onChange={(name, value) => this.onFilterChange("role", value)} />,
      },
    ];

    this.state = {
      data: {},
      roles: [
        { label: "Admin", value: "admin" },
        { label: "CSKH", value: "customer_care" },
      ],
      filter: {
        query: {},
      },
    };
    this.Tb = React.createRef();
    this.AddNewEdit = React.createRef();
    this.ChangeUserPassword = React.createRef();
  }

  onSelectFilterChange = (key, isSelected) => {
    // var filter = { ...this.state.filter };
    // if (isSelected) {
    //     return;
    // }
    // switch (key) {
    // }
  };

  delete = (user_name) => {
    api
      .post("user/delete", { user_name: user_name })
      .then((res) => {
        message.success("Xóa thành công");
        this.Tb.current.reload();
      })
      .catch((err) => {
        message.error("Xóa không thành công");
      });
  };

  resetPassword = (user_name) => {
    api
      .post("user/resetpassword", { user_name: user_name })
      .then((res) => {
        message.success("Reset thành công");
      })
      .catch((err) => {
        message.error("Reset không thành công");
      });
  };

  onFilterChange = (name, value) => {
    var filter = { ...this.state.filter };
    console.log("name", name);
    if (name == "search") {
      if (value != "" && value != null)
        filter.query.$or = [
          {
            name: {
              $regex: value,
            },
          },
          {
            phone: {
              $regex: value,
            },
          },
          {
            user_name: {
              $regex: value,
            },
          },
        ];
      else delete filter.query.$or;
    } else {
      if (value != "all") filter.query[name] = value;
      else delete filter.query[name];
    }
    console.log(filter);

    this.setState({ filter }, () => this.getData());
  };

  getData = () => {
    if (this.Tb.current) this.Tb.current.reload();
  };

  getColumn = () => {
    var columns = [
      {
        title: "Tên đăng nhập",
        dataIndex: "user_name",
        key: "user_name",
        sorter: true,
      },
      {
        title: "Tên",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => {
          return (record.last_name || "") + " " + (record.first_name || "");
        },
      },
      {
        title: "Số điện thoại",
        dataIndex: "phone",
        key: "phone",
        sorter: true,
      },
      {
        title: "Địa chỉ",
        dataIndex: "address",
        key: "address",
        sorter: true,
      },
      {
        title: "Kích hoạt",
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        render: (text, record, index) => {
          if (text == true) return <i style={{ color: "#3DED97" }} className="fa fa-check"></i>;
          else return <i style={{ color: "#FF0000" }} className="fa fa-times"></i>;
        },
      },
      {
        title: "Phân quyền",
        dataIndex: "role",
        key: "role",
        sorter: true,
        render: (text, record, index) => {
          if (this.state.roles) {
            var found = this.state.roles.find((role) => role.id == text);
            if (found != null) return found.name;
          }
          return text;
        },
      },
      {
        title: "Người tạo",
        dataIndex: "created_by",
        key: "created_by",
        sorter: true,
      },
      {
        title: "Ngày tạo",
        dataIndex: "created_date",
        key: "created_date",
        sorter: true,
        render: (text, record, index) => {
          if (text != "" && text != null) {
            var date = new Date(text);
            date = moment(date);
            return <div>{date.format("DD-MM-YYYY")}</div>;
          } else return "";
        },
      },
      {
        title: "ĐN lần cuối",
        dataIndex: "last_login",
        key: "last_login",
        sorter: true,
        render: (text, record, index) => {
          if (text != "" && text != null) {
            var date = new Date(text);
            date = moment(date);
            return <div>{date.format("DD-MM-YYYY HH:mm")}</div>;
          } else return "";
        },
      },
      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <div style={{ float: "right", color: "#1890FF", fontSize: 17 }}>
              <i style={{ marginRight: 10 }} className="fa fa-edit" aria-hidden="true" onClick={() => this.AddNewEdit.current.open(record)}></i>
              <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.delete(record.user_name)} okText="Có" cancelText="Không">
                <i style={{ marginRight: 10 }} className="fa fa-trash" aria-hidden="true"></i>
              </Popconfirm>

              <i
                className="fa fa-undo"
                onClick={() => {
                  this.ChangeUserPassword.current.open(record.user_name);
                }}
              ></i>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
          <div>
            <SearchBox
              name="campaign"
              data={this.filters}
              onSelectFilterChange={this.onSelectFilterChange}
              placeholder="Thông tin"
              dataButton={[
                <Button
                  type="primary"
                  onClick={() => {
                    this.AddNewEdit.current.open("");
                  }}
                  style={{ marginLeft: 15, marginBottom: 10 }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Thêm mới
                </Button>,
              ]}
              onSearch={() => this.Tb.current.reload()}
              onChange={(value) => {
                this.onFilterChange("search", value);
              }}
            />
          </div>
        </div>

        <AntTable
          rowSelection={{
            type: "checkbox",
            ...this.rowSelection,
          }}
          columns={this.getColumn()}
          customSearch={this.state.filter}
          tableName={"tbCustomer"}
          ajax="user/list"
          ref={this.Tb}
          rowKey={(record) => record.id}
          // rowSelection={{
          //   type: "checkbox",
          // }}
        ></AntTable>
        <AddNewEdit ref={this.AddNewEdit} getData={this.getData}></AddNewEdit>
        <ChangeUserPassword ref={this.ChangeUserPassword}></ChangeUserPassword>
      </div>
    );
  }
}
