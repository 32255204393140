import React from 'react'
import { Popover, Button } from 'antd';
import { SketchPicker } from 'react-color'
import "./index.css"

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,

    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {

        if (this.props.onChange) {
            this.props.onChange(color.hex);
        }
    };

    render() {

        const styles = {

            color: {
                width: '40px',
                height: '30px',
                padding: '0px',
                paddingTop: '0px',
                borderRadius: '2px',
                background: this.props.value,
            },
            swatch: {
                
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },

        };

        return (
            <div style={{ display: 'flex' }}>

                <Popover content={<SketchPicker
                 color={this.props.value} onChange={this.handleChange} />} trigger="click" overlayClassName="overlayClassNameColorPicker">
                    <div style={styles.swatch} >
                        <div style={styles.color} />
                    </div>
                </Popover>

                <div style={{ marginLeft: 15, paddingTop: 5 }}>
                    {this.props.value}
                </div>
            </div>
        )
    }
}

export default ColorPicker