import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Tabs, Checkbox, DatePicker } from "antd";
import api from "api";
import AntTable from "components/common/AntTable";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";
import { formatL } from "../../common/date_time";
import { formatCurrency } from "components/common/format/number";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const { Search } = Input;
const { Option } = Select;

export default class PointHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      filter: {
        query: {
          $and: [{ type: { $not: { $in: ["", "cancel_usepoint"] } } }],
        },
      },

      lstApp: [],
      tabKey: "addpoint",
      isCreateDate: false,
    };
    this.tbAdd = React.createRef();
    this.tbUse = React.createRef();
    this.SearchBox = React.createRef();
  }

  componentDidMount = () => { };

  onSelectFilterChange = (key, isSelected) => {
    // var filter = { ...this.state.filter };
    // if (isSelected) {
    //     return;
    // }
    // switch (key) {
    // }
  };

  delete = (id) => {
    api
      .post("point_history/cancelpointhistory", { id: id })
      .then((res) => {
        message.success("Hoàn tác thành công", 3);
        this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
      })
      .catch((err) => {
        if (err.status != 999) message.error(err.message, 3);
        else message.error("Hoàn tác không thành công", 3);
      });
  };

  onFilterChange = (name, value) => {
    var filter = {};
    filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x[name]);
    if (value == "") {
      if (index > -1 && name != "type") filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name].$regex = value;
      } else {
        filter.query.$and.push({ [name]: { $regex: value } });
      }
    }
    this.setState({ filter: filter });
  };

  checkBoxControl = (name, affectName, check) => {
    var state = { ...this.state };
    state[name] = check;
    if (!check) {
      var array = state.filter.query.$and.filter((x) => !x[affectName]);
      state.filter.query.$and = array;
    }
    state[affectName] = null;
    this.setState(state, () => {
      if (check === false) {
        this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
      }
    });
  };

  onRangePickerChange = (name, value) => {
    var state = { ...this.state };
    var array = state.filter.query.$and.filter((x) => !x[name]);
    var obj = {};
    obj[name] = { $gte: value[0], $lte: value[1] };
    array.push(obj);
    state.filter.query.$and = array;
    state[name] = value;
    this.setState(state, () => {
      this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
    });
  };

  onTypeChange = (value) => {
    var filter = {};
    filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x.type);
    if (value == "" || value == null) {
      if (index > -1) filter.query.$and[index].type = { $not: { $eq: "" } };
    } else {
      if (index > -1) {
        filter.query.$and[index].type = value;
      }
    }
    this.setState({ filter: filter }, () => {
      this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
    });
  };

  getColumn = () => {
    var columns = [
      {
        title: "Tên khách hàng",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => {
          return <div>{record.customer[0]?.last_name + " " + record.customer[0]?.first_name}</div>;
        },
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_date",
        key: "create_date",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.create_date);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },

      {
        title: "Điểm",
        dataIndex: "change_point",
        key: "change_point",
        render: (text, record, index) => {
          return <div>{formatCurrency(record.change_point)}</div>;
        },
        sorter: true,
      },

      {
        title: "Mô tả",
        dataIndex: "description",
        key: "description",
      },

      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              {!record.is_cancel && (
                <Popconfirm title="Bạn có chắc muốn hoàn tác không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                  <Button type="link">Hoàn tác</Button>
                </Popconfirm>
              )}
            </>
          );
        },
      },
    ];
    return columns;
  };

  getColumnUse = () => {
    var columns = [
      {
        title: "Tên khách hàng",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => {
          return <div>{record.customer[0]?.last_name + " " + record.customer[0]?.first_name}</div>;
        },
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_date",
        key: "create_date",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.create_date);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },

      {
        title: "Điểm",
        dataIndex: "change_point",
        key: "change_point",
        render: (text, record, index) => {
          return <div>{formatCurrency(record.change_point)}</div>;
        },
        sorter: true,
      },

      {
        title: "Mô tả",
        dataIndex: "description",
        key: "description",
      },

      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Popconfirm title="Bạn có chắc muốn hoàn tác không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                <Button type="link">Hoàn tác</Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
    return columns;
  };

  getFilters = () => {
    var filters = [
      {
        Key: "create_date",
        Name: "Ngày tạo",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Ngày hết hạn</label>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1, padding: "5px 8px", border: "1px solid #dfdfdf" }}>
                  <Checkbox
                    style={{ margin: "auto" }}
                    onChange={(e) => {
                      this.checkBoxControl("isCreateDate", "create_date", e.target.checked);
                    }}
                    value={this.state.isCreateDate}
                  ></Checkbox>
                </div>
                <RangePicker
                  value={this.state.create_date}
                  disabled={this.state.isCreateDate === false ? [true, true] : [false, false]}
                  style={{ width: "100%" }}
                  onChange={(e) => this.onRangePickerChange("create_date", e)}
                />
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return filters;
  };

  getFiltersUse = () => {
    var filters = [
      {
        Key: "create_date",
        Name: "Ngày tạo",
        Component: (
          <Col span={6}>
            <div style={{ marginRight: 10 }}>
              <label>Ngày hết hạn</label>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1, padding: "5px 8px", border: "1px solid #dfdfdf" }}>
                  <Checkbox
                    style={{ margin: "auto" }}
                    onChange={(e) => {
                      this.checkBoxControl("isCreateDate", "create_date", e.target.checked);
                    }}
                    value={this.state.isCreateDate}

                  ></Checkbox>
                </div>
                <RangePicker
                  value={this.state.create_date}
                  disabled={this.state.isCreateDate === false ? [true, true] : [false, false]}
                  style={{ width: "100%" }}
                  onChange={(e) => this.onRangePickerChange("create_date", e)}
                />
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return filters;
  };

  tabChange = (e) => {
    var filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x.type != null);
    if (e === "usepoint") {
      filter.query.$and[index].type = { $in: ["", "cancel_usepoint"] };
    } else {
      filter.query.$and[index].type = { $not: { $in: ["", "cancel_usepoint"] } };
    }
    this.setState({ tabKey: e, filter: filter }, () => {
      this.SearchBox.current.renderFilter();
    });
  };

  render() {
    var filters = [];
    if (this.state.tabKey === "addpoint") filters = this.getFilters();
    else filters = this.getFiltersUse();
    return (
      <>
        <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
          <div>
            <SearchBox
              name="pointhistory"
              data={filters}
              onSelectFilterChange={this.onSelectFilterChange}
              placeholder="Thông tin"
              dataButton={[]}
              onSearch={() => {
                this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
              }}
              onChange={(value) => {
                this.onFilterChange("customer.first_name", value);
              }}
              ref={this.SearchBox}
            />
          </div>
        </div>
        <div>
          <Card>
            <Tabs defaultActiveKey={this.state.tabKey} onChange={this.tabChange}>
              <TabPane tab="Lịch sử tích điểm" key="addpoint"></TabPane>
              <TabPane tab="Lịch sử đổi điểm" key="usepoint"></TabPane>
            </Tabs>
            {this.state.tabKey === "addpoint" ? (
              <AntTable columns={this.getColumn()} customSearch={this.state.filter} tableName={"tbaddpointhistory"} ajax="point_history/getlistadd" ref={this.tbAdd}></AntTable>
            ) : (
              <>
                <AntTable columns={this.getColumnUse()} customSearch={this.state.filter} tableName={"tbusepointhistory"} ajax="point_history/getlistuse" ref={this.tbUse}></AntTable>
              </>
            )}
          </Card>
        </div>
      </>
    );
  }
}
