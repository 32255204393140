import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, message, Form, Radio, Typography } from "antd";
import ColorPicker from "components/picker/color_picker";
import UploadImagePicker from "components/picker/image_picker";
import api from "api";
import { useNavigate } from "react-router-dom";
const { Text, Title } = Typography;
function DefaultThemeConfig() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [stateData, setStateData] = useState();
  const datadefault = {
    main_color: "#005ac1",
    secondary_color: "#252525",
    header_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/f8843fcb-3b48-4d17-921e-37a384d5db3f.svg",
      custom_img: "",
    },
    card_game_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/2bbf319c-8e3a-404f-aa0b-97aabed50610.svg",
      custom_img: "",
    },
    card_blog_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/e792b7a1-c3a0-4f1f-b47d-a66e625e0e63.svg",
      custom_img: "",
    },
    card_program_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/92efecff-4c3b-42ae-87e0-93ace1004655.svg",
      custom_img: "",
    },
    list_program_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/f2e75ac3-a620-4a65-946c-bc5e1ea49bb7.svg",
      custom_img: "",
    },
    list_point_history_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/d228d2c0-aa62-40bc-a3db-5db24df6b82d.svg",
      custom_img: "",
    },
    list_game_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/bfe52886-bde7-403c-bb47-d4cb4d5aff51.svg",
      custom_img: "",
    },
    list_blog_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/12426291-31e4-4945-9a2e-6ff4f9affea2.svg",
      custom_img: "",
    },
    login_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/9e1eee8a-9628-401f-b3e2-abfddfdcba9e.svg",
      custom_img: "",
    },
    otp_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/9574cb8b-527d-4d29-9bd3-56315c74399b.svg",
      custom_img: "",
    },
    page_404_bg: {
      type: "system",
      system_img: "https://storage.googleapis.com/cubeloyalty_asia/web/e22f4c0a-2cfe-48da-9522-f3d93805da19.svg",
      custom_img: "",
    },
  };

  const saveConfig = (value) => {
    api
      .post("themeconfig/savedefault", { data: value })
      .then((res) => {
        message.success("Lưu thành công");
        getconfig();
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };

  const getconfig = async () => {
    await api
      .post("themeconfig/getconfig", { nameconfig: "default" })
      .then((res) => {
        setStateData(res.config);
        form.setFieldsValue(res.config);
      })
      .catch((error) => {
        setStateData(datadefault);
        form.setFieldsValue(datadefault);
      });
  };

  const handleRestoreSettings = async () => {
    form.resetFields();
  };

  const handleClose = () => {
    navigate("/marketing/branding");
  };

  useEffect(() => {
    getconfig();
  }, []);
  return (
    <>
      <Card
        title={<Text style={{ fontSize: 32 }}>Cấu hình Theme mặc định</Text>}
        extra={
          <Button size="large" type="link" onClick={handleRestoreSettings}>
            Khôi phục cài đặt
          </Button>
        }
        headStyle={{ padding: 16 }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={saveConfig}
          name="defaultTheme"
          initialValues={
            datadefault
            // {
            //   card_blog_bg: {type: 'system'},
            //   card_game_bg: {type: 'system'},
            //   card_program_bg: {type: 'system'},
            //   header_bg: {type: 'system'},
            //   list_blog_bg: {type: 'system'},
            //   list_game_bg : {type: 'system'},
            //   list_point_history_bg: {type: 'system'},
            //   list_program_bg: {type: 'system'},
            //   login_bg: {type: 'system'},
            //   main_color: "#005ac1",
            //   otp_bg: {type: 'system'},
            //   page_404_bg: {type: 'system'},
            //   secondary_color: "#252525",
            // }
          }
        >
          <Row gutter={16}>
            <Col span={24}>
              <Title level={4}>Màu sắc</Title>
            </Col>
            <Col span={6}>
              <Form.Item name="main_color" label="Màu chính">
                <ColorPicker></ColorPicker>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="secondary_color" label="Màu phụ">
                <ColorPicker></ColorPicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={4}>Hình nền</Title>
            </Col>
            <Col span={12}>
              <Form.Item name={["header_bg", "type"]} label="Hình nền khách hàng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.header_bg !== currentValues.header_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("header_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["header_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 400.0 / 230.0 }} size={{ width: 400, height: 230 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["header_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.header_bg?.system_img || stateData?.header_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 400, height: 230 }} src={stateData?.header_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item name={["card_game_bg", "type"]} label="Hình nền thẻ game rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_game_bg !== currentValues.card_game_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("card_game_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["card_game_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 224.0 / 200.0 }} size={{ width: 224, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["card_game_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.card_game_bg?.system_img || stateData?.card_game_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 224, height: 200 }} src={stateData?.card_game_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["card_blog_bg", "type"]} label="Hình nền bài viết rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_blog_bg !== currentValues.card_blog_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("card_blog_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["card_blog_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 260.0 / 200.0 }} size={{ width: 260, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["card_blog_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.card_blog_bg?.system_img || stateData?.card_blog_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 260, height: 200 }} src={stateData?.card_blog_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["card_program_bg", "type"]} label="Hình nền quà của tôi rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.card_program_bg !== currentValues.card_program_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("card_program_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["card_program_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 300.0 / 200.0 }} size={{ width: 300, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["card_program_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.card_program_bg?.system_img || stateData?.card_program_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 300, height: 200 }} src={stateData?.card_program_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["list_program_bg", "type"]} label="Hình nền danh sách quà của tôi rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_program_bg !== currentValues.list_program_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("list_program_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["list_program_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["list_program_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.list_program_bg?.system_img || stateData?.list_program_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 240, height: 200 }} src={stateData?.list_program_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["list_point_history_bg", "type"]} label="Hình nền lịch sử điểm rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_point_history_bg !== currentValues.list_point_history_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("list_point_history_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["list_point_history_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["list_point_history_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.list_point_history_bg?.system_img || stateData?.list_point_history_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 240, height: 200 }} src={stateData?.list_point_history_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["list_game_bg", "type"]} label="Hình nền danh sách game rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_game_bg !== currentValues.list_game_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("list_game_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["list_game_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 240.0 / 200.0 }} size={{ width: 240, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["list_game_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.list_game_bg?.system_img || stateData?.list_game_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 240, height: 200 }} src={stateData?.list_game_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["list_blog_bg", "type"]} label="Hình nền danh sách bài viết rỗng">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.list_blog_bg !== currentValues.list_blog_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("list_blog_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["list_blog_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 260.0 / 200.0 }} size={{ width: 260, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["list_blog_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.list_blog_bg?.system_img || stateData?.list_blog_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 260, height: 200 }} src={stateData?.list_blog_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["login_bg", "type"]} label="Hình nền màn hình đăng nhập">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.login_bg !== currentValues.login_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("login_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["login_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 166.0 / 200.0 }} size={{ width: 166, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["login_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.login_bg?.system_img || stateData?.login_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 166, height: 200 }} src={stateData?.login_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["otp_bg", "type"]} label="Hình nền màn hình OTP">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.otp_bg !== currentValues.otp_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("otp_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["otp_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 196.0 / 200.0 }} size={{ width: 196, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["otp_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.otp_bg?.system_img || stateData?.otp_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 196, height: 200 }} src={stateData?.otp_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={["page_404_bg", "type"]} label="Hình nền màn hình 404">
                <Radio.Group defaultValue={"system"}>
                  <Radio value="system">Hệ Thống</Radio>
                  <Radio value="custom">Upload</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.page_404_bg !== currentValues.page_404_bg}>
              {({ getFieldValue }) => {
                var data = getFieldValue("page_404_bg");
                return (
                  <>
                    <Col span={24}>
                      <Form.Item name={["page_404_bg", "custom_img"]} hidden={data?.type != "custom"}>
                        <UploadImagePicker crop={{ aspect: 300.0 / 200.0 }} size={{ width: 300, height: 200 }}></UploadImagePicker>
                      </Form.Item>
                      <Form.Item
                        name={["page_404_bg", "system_img"]}
                        hidden={data?.type == "custom" || !stateData?.page_404_bg?.system_img || stateData?.page_404_bg?.system_img == ""}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img style={{ width: 300, height: 200 }} src={stateData?.page_404_bg?.system_img} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Form>
        <Row justify="end" style={{ gap: 10, marginTop: 16 }}>
          <Col>
            <Button type="default" onClick={handleClose}>
              Thoát
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Lưu
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default DefaultThemeConfig;
