import React, { Component } from "react";
import { Input, Button, Steps, Card, Form, Row, Col, message, Modal, Select, Checkbox } from "antd";
import api from "api";
import WayToSendPicker from "./waytosendpicker";
import ContentPicker from "../picker/content_picker";
import moment from "moment";
import DateTimePicker from "../picker/datetime_picker";
import TimePicker from "../picker/time_picker";
import SegmenPicker from "components/picker/segment_picker";

const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;

export default class PopupCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lst: [],
      lsterror: [],
      visible: false,
      current: 0,
      listcycle: [
        {
          name: "Một lần",
          key: "onetime",
        },
        {
          name: "Hàng ngày",
          key: "daily",
        },
        {
          name: "Hàng tuần",
          key: "weekly",
        },
        {
          name: "Hàng tháng",
          key: "monthly",
        },
      ],
      steps: [
        {
          title: "Chiến dịch",
          description: <span>Thông tin</span>,
          fields: [
            { parent: "name", child: [] },
            { parent: "schedule", child: ["type", "time"] },
            { parent: "customer_segment_id", child: [] },
          ],
        },
        {
          title: "Kênh gửi",
          description: "Chọn kênh gửi",
          fields: [{ parent: "way_to_send", child: [] }],
        },
        {
          title: "Nội dung",
          description: "Nội dung gửi",

          fields: [{ parent: "content", child: [] }],
        },
        {
          title: "Hoàn tất",
          description: "",
          fields: [],
        },
      ],
      gotlstapp: false,
      loadingBtn: false,
      segmentSelected: {
        name: "",
        number_customer: 0,
      },
      typeSchedule: "onetime",
    };
    this.campaignForm = React.createRef();
    this.select = React.createRef();
    this.ContentPicker = React.createRef();
    this.defaultSteps = [
      {
        title: "Chiến dịch",
        description: <span>Thông tin</span>,
        fields: [
          { parent: "name", child: [] },
          { parent: "schedule", child: ["type", "time"] },
          { parent: "customer_segment_id", child: [] },
        ],
      },
      {
        title: "Kênh gửi",
        description: "Chọn kênh gửi",
        fields: [{ parent: "way_to_send", child: [] }],
      },
      {
        title: "Nội dung",
        description: "Nội dung gửi",

        fields: [{ parent: "content", child: [] }],
      },
      {
        title: "Hoàn tất",
        description: "",
        fields: [],
      },
    ];

    this.dateofweek = [
      {
        name: "Thứ 2",
        value: "MON",
      },
      {
        name: "Thứ 3",
        value: "TUE",
      },
      {
        name: "Thứ 4",
        value: "WED",
      },
      {
        name: "Thứ 5",
        value: "THU",
      },
      {
        name: "Thứ 6",
        value: "FRI",
      },
      {
        name: "Thứ 7",
        value: "SAT",
      },
      {
        name: "Chủ nhật",
        value: "SUN",
      },
    ];
  }

  // componentDidMount() {
  //     this.getSegment();
  // }

  getApp = () => {
    api
      .post("shop/getinstalledappbycategories", { categories: ["communication"] })
      .then((res) => {
        this.setState({ lst: res, gotlstapp: true });
      })
      .catch((err) => {});
  };

  open = (element) => {
    this.getApp();

    // var data = this.state.segmentSelected;
    // if (data && element.customer_segment) {
    //     if (element.customer_segment.length > 0)
    //         data.name = element.customer_segment[0].name;
    // }

    // this.setState({ segmentSelected: data });
    if (element === "") {
      var json = null;

      var data = {
        id: "",
        name: "",
        description: "",
        schedule: {
          type: "onetime",
          time: "",
          date: "",
          dateofmonth: "",
          dateofweek: "",
        },
        isallapp: true,
        customer_segment_id: "",
        send_all_app: true,
        way_to_send: [],
        content: {
          rich_media: {
            html: "",
            json: json,
            subject: "",
            attachments: [],
          },
          text: "",
        },
        createdate: "",
        createby: "",
      };
      this.setState({ visible: true, current: 0, data: data, typeSchedule: "onetime", way_to_send: [] }, () => {
        this.setDataForm(data);
        this.changeValidate();
      });
    } else {
      api.post("/campaign/getbyid", { id: element }).then((res) => {
        let segmentSelected = {};
        if (res.schedule.time) res.schedule.time = moment(res.schedule.time);
        if (res.customer_segment[0]) {
          segmentSelected = res.customer_segment[0];
        }
        this.setState(
          { visible: true, current: 0, data: res, segmentSelected: segmentSelected, typeSchedule: res.schedule.type, way_to_send: res.way_to_send },
          () => {
            this.setDataForm(res);
            this.changeValidate();
          }
        );
      });
    }
  };

  close = () => {
    this.setState({ visible: false, steps: this.defaultSteps, current: 0, lsterror: [], gotlstapp: false });
  };

  //các hàm chuyển tag
  next = () => {
    this.setState({ current: this.state.current + 1 });
  };

  prev = () => {
    this.setState({ current: this.state.current - 1 });
  };

  checkValidate = () => {
    var data = this.campaignForm.current.getFieldsValue();
    var steps = this.state.steps;
    var current = this.state.current;
    if (current === 2) {
      var err = 0;
      if (data.content.rich_media.subject == "" || data.content.rich_media.subject == null) {
        var haveEmail = data.way_to_send.find((x) => x.id === "email" || x.id === "default_email");
        if (haveEmail) {
          this.ContentPicker.current.showError("subject");
          err++;
        }
      }
      if (data.content.text == "" || data.content.text == null) {
        var haveSMS = data.way_to_send.find((x) => x.id === "smsfpt");
        if (haveSMS) {
          this.ContentPicker.current.showError("text");
          err++;
        }
      }
      if (err !== 0) {
        return;
      }
    }
    var lsterror = [];
    if (steps[current].fields.length > 0) {
      steps[current].fields.forEach((item) => {
        if (item.child.length === 0) {
          if (data[item.parent] === "" || data[item.parent] == null || data[item.parent].length === 0) {
            lsterror.push(item.parent);
          } else if (data.way_to_send.findIndex((w) => w.id === "smsfpt") > -1 && data[item.parent].text === "" && data[item.parent].rich_media?.html === "") {
            lsterror.push(item.parent);
          }
        } else {
          item.child.forEach((child) => {
            if (data[item.parent][child] === "" || data[item.parent][child] == null || data[item.parent][child] === []) {
              lsterror.push(child);
            }
          });
        }
      });
    }
    if (lsterror.length === 0) {
      this.setState({ lsterror: [] });
      this.next();
    } else {
      this.setState({ lsterror: lsterror });
    }
  };

  changeValidate = () => {
    var steps = this.state.steps;
    var type = this.state.typeSchedule;
    var lst = ["type", "time"];
    switch (type) {
      case "onetime":
        lst.push("date");
        break;
      case "weekly":
        lst.push("dateofweek");
        break;
      case "month":
        lst.push("dateofmonth");
        break;
    }
    steps[0].fields[1].child = lst;
    this.setState({ steps: steps });
  };

  //lưu chiến dịch
  saveCampaign = (value) => {
    var err = 0;
    if (value.content.rich_media.subject == "" || value.content.rich_media.subject == null) {
      var haveEmail = value.way_to_send.find((x) => x.id === "email" || x.id === "default_email");
      if (haveEmail) {
        this.ContentPicker.current.showError("subject");
        err++;
      }
    }
    if (value.content.text == "" || value.content.text == null) {
      var haveSMS = value.way_to_send.find((x) => x.id === "smsfpt");
      if (haveSMS) {
        this.ContentPicker.current.showError("text");
        err++;
      }
    }
    if (err !== 0) {
      this.setState({ loadingBtn: false });
      return;
    }
    var way_to_send = value.way_to_send.map((item) => {
      return {
        id: item.id,
        parameters: item.parameters,
      };
    });
    value.way_to_send = way_to_send;
    value.customer_segment_id = this.state.segmentSelected.id;
    console.log("value", value);
    api
      .post("campaign/save", value)
      .then((res) => {
        message.success("Lưu chiến dịch thành công", 3);
        this.setState({ visible: false, steps: this.defaultSteps, current: 0, loadingBtn: false }, () => {
          this.campaignForm.current.resetFields();
          this.props.reload();
        });
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
        this.setState({ loadingBtn: false });
      });
  };

  //set giá trị ban đầu cho form
  setDataForm = (data) => {
    this.campaignForm.current.setFieldsValue(data);
  };

  onFinishFailed = (errorInfo) => {
    var checkWaySent = errorInfo.errorFields.findIndex((x) => x.name[0] === "way_to_send");
    var steps = this.state.steps;
    if (checkWaySent === -1) {
      steps[0].status = "error";
      steps[1].status = "finish";
    } else {
      steps[1].status = "error";
      errorInfo.errorFields.splice(checkWaySent, 1);

      if (errorInfo.errorFields.length > 0) {
        steps[0].status = "error";
      } else {
        steps[0].status = "finish";
      }
    }

    this.setState({ steps: steps });
  };

  getDataForm = () => {
    var data = this.campaignForm.current.getFieldsValue(true);
    var vl = moment();
    if (data.schedule.time != null && data.schedule.time != "") {
      var date = new Date(data.schedule.time);
      vl = moment(date);
    } else vl = "";
    var schedule = "";
    switch (data.schedule.type) {
      case "daily":
        schedule = vl.format("HH:mm") + " hàng ngày";
        break;
      case "weekly":
        var name = this.dateofweek.find((x) => x.value === data.schedule.dateofweek);
        schedule = vl.format("HH:mm") + " " + name.name + " hàng tuần";
        break;
      case "monthly":
        schedule = vl.format("HH:mm") + " ngày " + data.schedule.dateofmonth + " hàng tháng";
        break;
      case "onetime":
        var date = new Date(data.schedule.date);
        var text = moment(date).format("DD-MM-YYYY");
        schedule = "Một lần - " + vl.format("HH:mm") + " " + text;
        break;
    }
    return (
      <div style={{ padding: "20px" }}>
        <Row>
          <Col span={11}>
            <div style={{ padding: 20 }}>
              <div style={{ marginBottom: "20px" }}>
                <label style={{ fontWeight: "bold" }}>Chiến dịch:</label>&ensp;
                <span>{data.name}</span>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label style={{ fontWeight: "bold" }}>Mô tả:</label>&ensp;
                <span>{data.description}</span>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label style={{ fontWeight: "bold" }}>Lịch chạy:</label>&ensp;
                <span>{schedule}</span>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label style={{ fontWeight: "bold" }}>Đối tượng:</label>&ensp;
                <span>{data.customer_segment ? data.customer_segment[0] && data.customer_segment[0].name : this.state.segmentSelected.name}</span>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{ padding: 20 }}>
              <div>
                <label style={{ fontWeight: "bold" }}>Nội dung</label>
              </div>
              <div style={{ margin: "20px" }}>
                <Button
                  onClick={() => {
                    this.setState({ current: 2 });
                  }}
                >
                  {" "}
                  <i className="fas fa-eye"></i>&ensp;xem nội dung
                </Button>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Kênh gửi</label>
              </div>
              <div>{this.showLogo(data.way_to_send)}</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  //Tiến
  //========Begin======
  showLogo = (lst) => {
    var lstapp = this.state.lst;
    var lstlogo = [];
    lst.forEach((item) => {
      var index = lstapp.findIndex((x) => x.id === item.id);
      if (index > -1) lstlogo.push(lstapp[index].logo);
    });
    return (
      <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "450px" }}>
        {lstlogo.map((item) => {
          return (
            <div style={{ margin: "20px" }}>
              <img src={item} style={{ width: "70px", height: "70px" }}></img>
            </div>
          );
        })}
      </div>
    );
  };

  handleSegmetChange = (value) => {
    if (value !== null) {
      this.setState({ segmentSelected: value });
    }
  };

  renderOptionDateOfMonth = () => {
    var a = [];
    for (var i = 1; i <= 31; i++) {
      a.push(<Option value={i}>{i}</Option>);
    }
    return a;
  };

  //========End========

  onStepChange = (current) => {
    if (this.state.data.id !== "") {
      this.setState({ current: current });
    }
  };

  render() {
    var steps = this.state.steps;
    var a = this.state.typeSchedule;
    return (
      <Modal
        title="Chiến dịch"
        open={this.state.visible}
        onOk={() => {}}
        onCancel={() => {
          this.close();
        }}
        width={"60%"}
        maskClosable={false}
        bodyStyle={{ minHeight: 500 }}
        footer={[
          <div>
            {this.state.current === 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => {
                  this.close();
                }}
              >
                Thoát
              </Button>
            )}
            {this.state.current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => this.prev()}>
                Trở lại
              </Button>
            )}
            {this.state.current < steps.length - 1 && (
              <Button type="primary" onClick={() => this.checkValidate()}>
                Tiếp tục
              </Button>
            )}
            {this.state.current === steps.length - 1 && (
              <Button
                type="primary"
                loading={this.state.loadingBtn ? true : false}
                onClick={() => {
                  this.setState({ loadingBtn: true }, () => this.campaignForm.current.submit());
                }}
              >
                Hoàn thành
              </Button>
            )}
          </div>,
        ]}
      >
        <Steps
          current={this.state.current}
          onChange={(e) => {
            this.onStepChange(e);
          }}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} description={item.description} />
          ))}
        </Steps>
        <div>
          {this.state.visible && (
            <Form name="emailsetting" onFinishFailed={this.onFinishFailed} ref={this.campaignForm} layout="vertical" onFinish={this.saveCampaign}>
              <Row hidden={this.state.current === 0 ? false : true}>
                <Col span={8}>
                  <Form.Item hidden name="id">
                    <Input />
                  </Form.Item>
                  <div style={{ margin: "20px 15px" }}>
                    <div>
                      <Form.Item
                        name="name"
                        label="Tên chiến dịch"
                        validateStatus={this.state.lsterror.findIndex((x) => x === "name") > -1 ? "error" : ""}
                        help={this.state.lsterror.findIndex((x) => x === "name") > -1 ? "Bạn chưa nhập tên chiến dịch!" : ""}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Tên chiến dịch" />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="description" label="Mô tả">
                        <TextArea rows={5} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ margin: "20px 15px" }}>
                    <div>
                      <Form.Item>
                        <Input.Group>
                          <div>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={["schedule", "type"]}
                              label="Lịch chạy"
                              validateStatus={this.state.lsterror.findIndex((x) => x === "type") > -1 ? "error" : ""}
                              help={this.state.lsterror.findIndex((x) => x === "type") > -1 ? "Bạn chưa chọn lịch chạy!" : ""}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  this.setState({ typeSchedule: value }, () => {
                                    this.changeValidate();
                                  });
                                }}
                              >
                                {this.state.listcycle.map((item) => {
                                  return <Option value={item.key}>{item.name}</Option>;
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.schedule !== currentValues.schedule}>
                            {({ getFieldValue }) => {
                              var data = getFieldValue(["schedule", "type"]);
                              return (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <Form.Item
                                    hidden={data === "onetime" ? false : true}
                                    style={{ width: "50%" }}
                                    label="Ngày"
                                    name={["schedule", "date"]}
                                    validateStatus={data === "onetime" && (this.state.lsterror.findIndex((x) => x === "date") > -1 ? "error" : "")}
                                    help={data === "onetime" && (this.state.lsterror.findIndex((x) => x === "date") > -1 ? "Bạn chưa chọn ngày chạy!" : "")}
                                    rules={
                                      data === "onetime" && [
                                        {
                                          required: true,
                                        },
                                      ]
                                    }
                                  >
                                    <DateTimePicker style={{ width: "100%" }} />
                                  </Form.Item>

                                  <Form.Item
                                    hidden={data === "weekly" ? false : true}
                                    style={{ width: "50%" }}
                                    label="Thứ"
                                    name={["schedule", "dateofweek"]}
                                    validateStatus={data === "weekly" && (this.state.lsterror.findIndex((x) => x === "dateofweek") > -1 ? "error" : "")}
                                    help={
                                      data === "weekly" && (this.state.lsterror.findIndex((x) => x === "dateofweek") > -1 ? "Bạn chưa chọn ngày chạy!" : "")
                                    }
                                    rules={
                                      data === "weekly"
                                        ? [
                                            {
                                              required: true,
                                            },
                                          ]
                                        : []
                                    }
                                  >
                                    <Select style={{ width: "100%" }}>
                                      {this.dateofweek.map((item) => {
                                        return <Option value={item.value}>{item.name}</Option>;
                                      })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    hidden={data === "monthly" ? false : true}
                                    style={{ width: "50%" }}
                                    label="Ngày trong tháng"
                                    name={["schedule", "dateofmonth"]}
                                    validateStatus={data === "monthly" && (this.state.lsterror.findIndex((x) => x === "monthly") > -1 ? "error" : "")}
                                    help={data === "monthly" && (this.state.lsterror.findIndex((x) => x === "monthly") > -1 ? "Bạn chưa chọn ngày chạy!" : "")}
                                    rules={
                                      data === "monthly" && [
                                        {
                                          required: true,
                                        },
                                      ]
                                    }
                                  >
                                    <Select style={{ width: "100%" }}>{this.renderOptionDateOfMonth()}</Select>
                                  </Form.Item>
                                  <Form.Item
                                    label="Giờ"
                                    name={["schedule", "time"]}
                                    validateStatus={this.state.lsterror.findIndex((x) => x === "time") > -1 ? "error" : ""}
                                    help={this.state.lsterror.findIndex((x) => x === "time") > -1 ? "Bạn chưa chọn giờ chạy!" : ""}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <TimePicker />
                                  </Form.Item>
                                </div>
                              );
                            }}
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ margin: "20px 15px" }}>
                    <div>
                      <Form.Item
                        name="customer_segment_id"
                        label="Đối tượng"
                        validateStatus={this.state.lsterror.findIndex((x) => x === "customer_segment_id") > -1 ? "error" : ""}
                        help={this.state.lsterror.findIndex((x) => x === "customer_segment_id") > -1 ? "Bạn chưa chọn đối tượng!" : ""}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <SegmenPicker disabled="" setValueSegment={(e) => this.handleSegmetChange(e)} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
              {this.state.gotlstapp && (
                <Card
                  title={<b>Phương Thức Gửi</b>}
                  style={{ marginTop: "20px" }}
                  hidden={this.state.current === 1 ? false : true}
                  extra={
                    <div>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                          return true;
                        }}
                      >
                        {/* <Checkbox style={{ marginBottom: 10 }}>Tất cả</Checkbox> */}
                        {({ getFieldValue }) =>
                          getFieldValue("way_to_send") != undefined && getFieldValue("way_to_send").length > 1 ? (
                            <Form.Item style={{ height: "0" }} name="send_all_app" valuePropName="checked">
                              <Checkbox style={{ marginBottom: 10 }}>Tất cả</Checkbox>
                            </Form.Item>
                          ) : (
                            <Form.Item style={{ height: "0" }} name="send_all_app" valuePropName="checked" hidden>
                              <Checkbox style={{ marginBottom: 10 }}>Tất cả</Checkbox>
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    </div>
                  }
                >
                  {
                    <Form.Item
                      name="way_to_send"
                      validateStatus={this.state.lsterror.findIndex((x) => x === "way_to_send") > -1 ? "error" : ""}
                      help={this.state.lsterror.findIndex((x) => x === "way_to_send") > -1 ? "Bạn chưa chọn phương thức gửi!" : ""}
                      rules={[
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (value.length !== 0) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                        },
                      ]}
                    >
                      <WayToSendPicker
                        load={this.state.current === 1 ? true : false}
                        lst={this.state.lst}
                        campaign_id={this.state.data.id}
                        onChange={(e) => {
                          this.setState({ way_to_send: e });
                        }}
                      />
                    </Form.Item>
                  }
                </Card>
              )}

              <div hidden={this.state.current === 2 ? false : true}>
                <Form.Item name="content">
                  <ContentPicker ref={this.ContentPicker} wayToSend={this.state.way_to_send}></ContentPicker>
                </Form.Item>
              </div>
              <Form.Item hidden name="createday">
                <Input />
              </Form.Item>
              <Form.Item hidden name="createby">
                <Input />
              </Form.Item>
            </Form>
          )}
          {this.state.current === 3 && <div>{this.getDataForm()}</div>}
        </div>
      </Modal>
    );
  }
}
