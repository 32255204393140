import React, { Component } from "react";
import api from "api";
import { Button, Card, Row, Space, Tag, Col, message } from "antd";
import Upgrade from "./upgrade";

export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], show: false };
    this.Customer = React.createRef();
    this.popupAddEdit = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
    await this.getCurrentPlan();
  };

  getData = () => {
    api.post("plan/getplans", { is_client: true }).then((res) => {
      this.setState({ data: res });
    });
  };

  getCurrentPlan = () => {
    api
      .post("shop/getplan")
      .then((res) => {
        this.setState({ shopPlan: res, show: true });
      })
      .catch((err) => {
        this.setState({ show: true });
      });
  };

  success() {
    message.success("đăng ký thành công!");
  }

  fail() {
    message.error("Đăng ký thất bại!");
  }
  render() {
    return (
      <>
        {this.state.show && (
          <>
            <Card>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Space>
                    Current plan
                    <Tag color="#108ee9">Free</Tag>
                  </Space>
                </div>
                <div></div>
              </div>
            </Card>
            <div style={{ height: 15, display: "block" }}></div>
            <Row gutter={16}>
              {this.state.data.map((item) => {
                return (
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} style={{ padding: "5px 0px" }}>
                    <Card>
                      <div style={{ textAlign: "center" }}>
                        <h1>{item.name}</h1>
                        <h2>{`$${item.money}/mo`}</h2>
                        <div>
                          {this.state.shopPlan && item.id === this.state.shopPlan.id ? (
                            <Button type="primary" style={{ backgroundColor: "#8fff54", borderColor: "transparent", fontWeight: "bold" }}>
                              USING
                            </Button>
                          ) : (
                            <Upgrade id={item.id} success={this.success} fail={this.fail} />
                          )}
                        </div>
                      </div>
                      <div style={{ height: 30, display: "block" }}></div>

                      <ul>
                        <li>Custom graphics</li>
                        <li>Personalized messaging</li>
                        <li> Remove our branding</li>
                      </ul>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </>
    );
  }
}
