import React, { Component } from "react";
import api from "api";
import { Input, Button, Space, Card, Empty, Tooltip, Row, Popconfirm, message, Skeleton, Col, Switch } from "antd";
import PopupSetting from "./popupsetting";
import SyncPopup from "./sync_popup";
import { io } from "socket.io-client";
import { getSocket } from "common/realtime";
import { wss_url } from "config";
const { Meta } = Card;

export default class SmsFptAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      signIn: "",
      showBtn: false,
      loading: true,
      autoAddPoint: false,
      isSync: false,
      synchonized: false,
      remain: 0,
      sync_socket_id: 0,
    };

    this.PopupSetting = React.createRef();
    this.SyncPopup = React.createRef();
  }

  componentDidMount = () => {
    this.getConfig();
    // var socket = io(`${wss_url}/app_socket`);
    // socket.emit("switchRoom", localStorage.getItem("shop_name"));
    var socket = getSocket("app_socket");
    socket.on("connect", () => {
      this.setState({ sync_socket_id: socket.id });
      socket.on("sync_queues", (data) => {
        console.log("data", data);
        if (data.synchonize_over == true) {
          this.setState({ isSync: false });
        } else {
          this.setState({ isSync: true });
          if (this.state.remain > data.remain) {
            this.setState({ remain: data.remain });
          }
        }
      });
    });
    this.socket = socket;
  };

  // lấy data config
  getConfig = () => {
    api.post("app/trustsales/config/getconfig", { shop_id: 0 }).then((res) => {
      if (res != null) {
        this.setState({ data: res.config, autoAddPoint: res.auto_add_point, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };

  //deleteconfig
  delete = (id) => {
    var dataPost = {
      id: id,
      shop_id: 0,
    };
    api
      .post("app/trustsales/config/delete", dataPost)
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.setState({ loading: true });
        this.getConfig();
        this.setState({ visible: false, data: [] });
      })
      .catch((err) => {
        message.error("Xóa thất bại", 3);
      });
  };

  //render item
  renderItem = (item) => {
    return (
      <div style={{ marginRight: "10px", height: 320 }}>
        <Card
          className={"sms-fpt-card-item"}
          bordered={true}
          cover={<img height="auto" src={item.img} />}
          actions={[
            <div
              onClick={() => {
                this.SyncPopup.current.open(item.store_id, this.state.sync_socket_id);
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </div>,
            <div
              onClick={() => {
                this.PopupSetting.current.open("edit", item);
              }}
            >
              <i className="far fa-edit"></i>
            </div>,
            <Popconfirm
              title="Bạn có chắc muốn xóa không?"
              onConfirm={() => {
                this.delete(item.store_id);
              }}
              okText="Có"
              cancelText="Không"
            >
              ,
              <div style={{ marginTop: -22 }}>
                <i className="far fa-trash-alt"></i>
              </div>
            </Popconfirm>,
          ]}
        >
          <Meta title="Trustsales" description={item.store_name}></Meta>
          {this.state.isSync && <h2 style={{ fontSize: 15, fontWeight: 10 }}>Đang đồng bộ, còn lại: {this.state.remain} đơn hàng</h2>}
        </Card>
      </div>
    );
  };

  autoAddPoint = (checked) => {
    api
      .post("app/trustsales/config/autoaddpoint", { check: checked })
      .then((res) => {
        this.setState({ autoAddPoint: checked });
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  render() {
    // danh sách các tài khoản có thể kết nối
    var data = this.state.data;

    return (
      <>
        <Card title="Tự động tích điểm" size="small" style={{ marginBottom: 20 }}>
          <Switch
            checked={this.state.autoAddPoint}
            onChange={(e) => {
              this.autoAddPoint(e);
            }}
          ></Switch>
        </Card>
        <Card
          size="small"
          title={<span style={{ fontWeight: "normal", fontSize: "14px" }}>Các tài khoản đã kết nối</span>}
          bordered={false}
          style={{ border: "1px solid #dfdfdf" }}
          extra={
            <Button type="primary" onClick={() => this.PopupSetting.current.open("add", {})}>
              Thêm mới
            </Button>
          }
        >
          {this.state.loading ? (
            <Skeleton />
          ) : !data || data.length === 0 ? (
            <div style={{ marginTop: "13vh" }}>
              <Empty description="Hãy thêm mới tài khoản đầu tiên"></Empty>
              <Row justify="center" style={{ marginBottom: "15vh" }}>
                <Button onClick={() => this.PopupSetting.current.open("add", {})} style={{ marginTop: 20 }} type="primary">
                  <i className="fas fa-plus"></i>&nbsp;Thêm mới
                </Button>
              </Row>
            </div>
          ) : (
            <Row>
              {data.map((item) => {
                return (
                  <Col xl={4} lg={5} md={6}>
                    {this.renderItem(item)}
                  </Col>
                );
              })}
            </Row>
          )}
          <PopupSetting ref={this.PopupSetting} data={this.state.data} getConfig={this.getConfig}></PopupSetting>
          <SyncPopup ref={this.SyncPopup}></SyncPopup>
        </Card>
      </>
    );
  }
}
