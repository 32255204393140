import React, { Component } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export default class DatePickerNew extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.value != null) this.setState({ value: dayjs(this.props.value) });
    }, 500);
  }

  onChange = (value) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    var vl = null;
    if (this.props.value != null && this.props.value != "") vl = dayjs(this.props.value);

    return <DatePicker {...this.props} onChange={this.onChange} value={vl} style={{ width: "100%" }} format="DD-MM-YYYY" />;
  }
}
