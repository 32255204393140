import React, { useEffect, useState } from "react";
import { Space, Typography, Card, Button, Row, Popconfirm, List, Col, message, Form, Input, DatePicker, Select } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import numeral from "numeral";
import * as dayjs from "dayjs";
import * as XLSX from "xlsx";
import api from "api";
const { Text, Title } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;
function LiveOrder() {
  const [form] = Form.useForm();
  const Tb = React.createRef();
  const [dataPost, setDataPost] = useState();
  const getColumn = () => {
    var columns = [
      {
        title: "Mã đơn",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Ngày đặt",
        dataIndex: "created_on_date",
        key: "created_on_date",
        sorter: true,
        render: (text, record, index) => {
          return <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY")}</p>;
        },
      },
      {
        title: "SĐT",
        dataIndex: "shipping_address",
        key: "shipping_address",
        sorter: true,
        render: (text) => {
          return text?.phone;
        },
      },
      {
        title: "Họ tên",
        dataIndex: "shipping_address",
        key: "shipping_address",
        sorter: true,
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: "Tổng tiền (SL)",
        dataIndex: "total_price",
        key: "total_price",
        sorter: true,
        render: (text, record) => {
          return `${numeral(text).format("0,0")} (${record.line_items
            .map((item) => {
              return item.quantity;
            })
            .reduce((a, b) => {
              return a + b;
            })})`;
        },
        // render: (text, record) => {
        //   if (text) {
        //     var phone = text?.substr(0, 4) + "." + text?.substr(3, 3) + "." + text?.substr(6, 3);
        //     var name = record.customer_name?.replace("null", "").trim();
        //     return (
        //       <Text style={{ marginBottom: "auto", marginTop: "auto" }}>
        //         {phone}
        //         <br />
        //         {name}
        //       </Text>
        //     );
        //   } else {
        //     return record.customer_name?.replace("null", "").trim();
        //   }
        // },
      },
      {
        title: "Sản phẩm",
        dataIndex: "line_items",
        key: "line_items",
        sorter: true,
        render: (text, record) => {
          var string = "";
          if (text.length > 0) {
            text.forEach((item, index) => {
              if (index === 0) {
                string = `${item.quantity} ${item.name ? item.name : item.variant_title}`;
              } else {
                string += `, ${item.quantity} ${item.name ? item.name : item.variant_title}`;
              }
            });
          }
          return string;
        },
        // ellipsis: true,
        // ellipsis: {
        //   showTitle: false,
        // },
        // render: (comment) => {
        //   return (
        //     <Tooltip trigger="hover" placement="topLeft" title={comment}>
        //       <Paragraph style={{ maxWidth: 360 }} ellipsis={{ rows: 2 }}>
        //         {comment}
        //       </Paragraph>
        //     </Tooltip>
        //   );
        // },
      },
      {
        title: "Tỉnh thành",
        dataIndex: "shipping_address",
        key: "shipping_address",
        sorter: true,
        render: (text) => {
          return text?.province;
        },
      },
    ];
    return columns;
  };
  const onSearch = () => {
    var newDataPost = form.getFieldsValue();

    if (newDataPost.created_on && newDataPost.created_on.length > 0) {
      newDataPost.created_on = newDataPost.created_on.map((item, index) => {
        if (index === 0) {
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").utc().format();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").utc().format();
        }
      });
    }
    setDataPost(newDataPost);
  };

  const downloadExcel = () => {
    api.post("live_order/list", dataPost).then((res) => {
      if (res.data.length > 0) {
        var data = res.data.map((item) => {
          var string = "";
          if (item.line_items.length > 0) {
            item.line_items.forEach((item1, index) => {
              if (index === 0) {
                string = `${item1.quantity} ${item1.name ? item1.name : item1.variant_title}`;
              } else {
                string += `, ${item1.quantity} ${item1.name ? item1.name : item1.variant_title}`;
              }
            });
          }
          return {
            "Mã đơn": item.id,
            "Ngày tạo": item.created_on_date,
            "Họ tên": item.shipping_address?.name ? item.shipping_address.name : "",
            "Số điện thoại": item.shipping_address?.phone ? item.shipping_address.phone : "",
            "Tổng tiền": item.total_price,
            "Sản phẩm": string,
            "Địa chỉ": item.shipping_address.address1
              ? `${item.shipping_address.address1}, ${item.shipping_address.ward}, ${item.shipping_address.district}`
              : "",
            "Tỉnh thành": item.shipping_address.province ? item.shipping_address.province : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
      }
    });
  };

  useEffect(() => {
    if (dataPost) {
      Tb.current.reload();
    }
  }, [dataPost]);
  return (
    <Space direction="vertical" size={8} style={{ width: "100%" }}>
      <div style={{ width: "100%", padding: 8, background: "white" }}>
        <Form layout={"inline"} form={form} style={{ padding: 8 }}>
          <Row style={{ width: "100%", margin: 0 }} gutter={[8, 8]}>
            <Col style={{ width: "calc(80% - 200px)" }}>
              <Form.Item name="text" style={{ marginInlineEnd: 0 }}>
                <Input
                  placeholder="Tìm theo mã đơn, số điện thoại"
                  onKeyDown={() => {
                    onSearch();
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={5}>
              <Form.Item name="product_name" style={{ marginInlineEnd: 0 }}>
                <Search placeholder="Tên sản phẩm" allowClear onSearch={onSearch} />
              </Form.Item>
            </Col> */}

            <Col style={{ width: "20%" }}>
              <Form.Item name="created_on" style={{ marginInlineEnd: 0 }}>
                <RangePicker style={{ width: "100%" }} placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item style={{ marginInlineEnd: 0 }}>
                <Button type="primary" style={{ width: "100%" }} onClick={onSearch}>
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginInlineEnd: 0 }}>
                <Button style={{ width: "100%" }} onClick={downloadExcel}>
                  Xuất Excel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AntTable columns={getColumn()} customSearch={dataPost} tableName={"tbReview"} ajax="live_order/list" ref={Tb} rowKey={(record) => record.id}></AntTable>
    </Space>
  );
}

export default LiveOrder;
