import React, { Component } from "react";

import "./game.css";

export default class Header extends Component {
  render() {
    return (
      <div style={{ position: "absolute", bottom: 50, display: "flex", paddingLeft: 35, paddingRight: 35, width: "100%" }}>
        <div>
          <div className="lk_btn_footer">
            <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/rotate.png"></img>
          </div>
          <div style={{ color: "#ffffff", fontSize: 20, fontWeight: 700, fontStyle: "normal", lineHeight: "24px" }}>Lịch sử</div>
        </div>
        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}></div>
        <div>
          <div className="lk_btn_footer">
            <img src="https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/info.png"></img>
          </div>
          <div style={{ color: "#ffffff", fontSize: 20, fontWeight: 700, fontStyle: "normal", lineHeight: "24px" }}>Thể lệ</div>
        </div>
      </div>
    );
  }
}
