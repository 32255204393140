var BirthdayExtendOperator = {
  birth_day_equal: {
    label: "Là Hôm Nay",
    labelForFormat: "birth_day_equal",
    cardinality: 0,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      $expr: {
        $or: [
          {
            $and: [
              { $eq: [{ $dayOfMonth: "$birth_day" }, { $dayOfMonth: { $add_time: { value: 0, key: "days", type: "date" } } }] },
              { $eq: [{ $month: "$birth_day" }, { $month: { $add_time: { value: 0, key: "days", type: "date" } } }] },
              { $lt: [{ $hour: "$birth_day" }, 17] },
            ],
          },
          {
            $and: [
              { $eq: [{ $dayOfMonth: "$birth_day" }, { $dayOfMonth: { $add_time: { value: -1, key: "days", type: "date" } } }] },
              { $eq: [{ $month: "$birth_day" }, { $month: { $add_time: { value: -1, key: "days", type: "date" } } }] },
              { $gte: [{ $hour: "$birth_day" }, 17] },
            ],
          },
        ],
      },
    }),
  },
  birth_day_next_xdays: {
    type: "number",
    label: "Trong X Ngày Đến",
    labelForFormat: "birth_day_next_xdays",
    cardinality: 1,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      $expr: {
        $and: [
          { $eq: [{ $dayOfMonth: "$birth_day" }, { $dayOfMonth: { $add_time: { value: value, key: "days", type: "date" } } }] },
          { $eq: [{ $month: "$birth_day" }, { $month: { $add_time: { value: value, key: "days", type: "date" } } }] },
        ],
      },
    }),
  },
  birth_day_thismonth: {
    label: "Trong Tháng Này",
    labelForFormat: "birth_day_thismonth",
    cardinality: 0,
    jsonLogic: "none",
    mongoFormatOp: (field, op, value) => ({
      $expr: {
        $and: [{ $eq: [{ $month: "$birth_day" }, { $month: { $add_time: { value: 0, key: "days", type: "date" } } }] }],
      },
    }),
  },
};

export default BirthdayExtendOperator;
