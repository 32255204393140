import { useState } from 'react'
import { Button, Card, Col, Modal, Row, Space, Typography, Spin } from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Meta } = Card;
const { Text } = Typography;
function ModalWayToSendCampaign({ isModalOpen, handleCancel, lstApp, lstSelectedApp, configApp, loadingModal }) {

  /**
   * Hàm rennder các app được cài đặt trong shop
   * @param {*} item dữ liệu của app để render
   * @param {*} index vị trí của phần tử trong mảng
   * @author huy 
   */
  const renderItemWayToSend = (item, index) => {
    var checkApp;
    if (lstSelectedApp.length > 0) {
      checkApp = lstSelectedApp.find((app) => app.id === item.id);
    }

    return (
      <Col xs={24} md={12} lg={8} xxl={6} style={{ height: 192 }}>
        <Card
          style={{
            width: '100%',
            height: '100%',
            boxShadow: '2px 4px 12px rgba(0,0,0,.08)',
            cursor: checkApp ? 'default' : 'pointer',
            opacity: checkApp ? 0.4 : 1
          }}
          cover={
            <img
              alt="example"
              src={item.logo}
              style={{ width: "88px", height: "88px", padding: 8, objectFit: 'cover' }}
            />
          }
          onClick={() => {
            if (!checkApp) {
              // mở modal cấu hình app lên để chọn tài khoản cho app
              configApp(item, index, 'add');
            }
          }}
        >
          <Meta title={item.name} description={item.description} />
        </Card>
      </Col>
    )
  }

  return (
    <Modal
      title="Chọn kênh gửi thông tin"
      open={isModalOpen}
      footer={[
        <Button type='primary' onClick={handleCancel}>Thoát</Button>
      ]}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleCancel}
      width='56vw'
    >
      <Spin size='large' tip='Đang tải...' spinning={loadingModal} style={{ height: '100%' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={8} xxl={6} style={{ height: 192 }}>
            <Link to='/apps' target='_blank'>
              <Card
                style={{
                  width: '100%', height: '100%', boxShadow: '2px 4px 12px rgba(0,0,0,.08)', cursor: 'pointer'
                }}
                bodyStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Space direction='vertical'>
                  <PlusSquareOutlined style={{ fontSize: 48, color: '#8c8c8c' }} />
                  <Text>Thêm mới</Text>
                </Space>
              </Card>
            </Link>
          </Col>

          {lstApp.map((item, index) => {
            return renderItemWayToSend(item, index);
          })}
        </Row>
      </Spin>
    </Modal>
  )
}

export default ModalWayToSendCampaign