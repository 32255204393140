import React, { Component } from "react";
import api from "api";
import { Card, Button, Skeleton, Empty, Row, Col, Popconfirm, message } from "antd";
import PopupSetting from "./popupsetting";
const { Meta } = Card;

export default class IBCOConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      showBtn: false,
      loading: false,
    };
    this.PopupSetting = React.createRef();
  }
  componentDidMount = () => {
    this.getConfig();
  };
  getConfig = () => {
    api.post("app/ibco/config/getconfig").then((res) => {
      if (res != null) {
        this.setState({ sites: res.sites, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };
  renderItem = (item) => {
    return (
      <div style={{ marginRight: "10px", height: 320 }}>
        <Card
          className={"sms-fpt-card-item"}
          bordered={true}
          cover={<img height="auto" src={item.img} />}
          actions={[
            <div
              onClick={() => {
                this.PopupSetting.current.open("edit", item);
              }}
            >
              <i className="far fa-edit"></i>
            </div>,
            <Popconfirm
              title="Bạn có chắc muốn xóa không?"
              onConfirm={() => {
                this.delete(item._id);
              }}
              okText="Có"
              cancelText="Không"
            >
              ,
              <div style={{ marginTop: -22 }}>
                <i className="far fa-trash-alt"></i>
              </div>
            </Popconfirm>,
          ]}
        >
          <Meta title={item.name} description={item.url} />
        </Card>
      </div>
    );
  };
  render() {
    var sites = this.state.sites;
    return (
      <>
        <Card
          size="small"
          title={<span style={{ fontWeight: "normal", fontSize: "14px" }}>Các trang của Ibco đã kết nối</span>}
          bordered={false}
          style={{ border: "1px solid #dfdfdf" }}
          extra={
            <Button type="primary" onClick={() => this.PopupSetting.current.open("add", {})}>
              Thêm mới
            </Button>
          }
        >
          {this.state.loading ? (
            <Skeleton />
          ) : !sites || sites.length === 0 ? (
            <div style={{ marginTop: "13vh" }}>
              <Empty description="Hãy thêm trang đầu tiên"></Empty>
              <Row justify="center" style={{ marginBottom: "15vh" }}>
                <Button onClick={() => this.PopupSetting.current.open("add", {})} style={{ marginTop: 20 }} type="primary">
                  <i className="fas fa-plus"></i>&nbsp;Thêm mới
                </Button>
              </Row>
            </div>
          ) : (
            <Row>
              {sites.map((item) => {
                return (
                  <Col xl={5} lg={5} md={6}>
                    {this.renderItem(item)}
                  </Col>
                );
              })}
            </Row>
          )}
          <PopupSetting ref={this.PopupSetting} data={this.state.data} getConfig={this.getConfig}></PopupSetting>
        </Card>
      </>
    );
  }
}
