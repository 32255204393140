import React, { useEffect, useState } from "react";
import { Col, Typography, Descriptions, Row, Button, Space, Form, Radio, DatePicker, message, Modal } from "antd";
import api from "api";
import moment from "moment";
import numeral from "numeral";
import dayjs from "dayjs";

const { Title, Text } = Typography;
function CompleteCampaign({ onChange, setCurrentStep, oaId, oaName, dataPost, back }) {
  const [form] = Form.useForm();
  const [scheduleType, setScheduleType] = useState(1);
  const [loading, setLoading] = useState(false);
  const saveCampaignZnsExcel = () => {
    setLoading(true);
    var formData = form.getFieldsValue();
    if (formData.schedule_type === 1) {
      dataPost.schedule.date = moment(new Date()).utc().format();
      dataPost.schedule.time = moment(new Date()).utc().format();
    } else {
      // console.log(formData.schedule.date);
      dataPost.schedule.date = moment(formData.schedule.date.$d).utc().format();
      dataPost.schedule.time = moment(formData.schedule.date.$d).utc().format();
    }

    api
      .post("campaign/savezns", { ...dataPost })
      .then((res) => {
        setLoading(false);
        // message.success(`Tạo chiến dịch ${dataPost.name} thành công`);
        Modal.success({
          maskClosable: false,
          title: "Thành công",
          content: (
            <Text>
              Tạo chiến dịch <Text strong>{dataPost.name}</Text> thành công
            </Text>
          ),
          okText: "Về danh sách",
          onOk() {
            back();
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        message.error(`Xảy ra lỗi`);
      });
  };

  const onChangeSchedule = (e) => {
    setScheduleType(e.target.value);
  };

  return (
    <>
      <Col>
        <Title level={4}>Xác nhận chiến dịch</Title>
        <Text>Hệ thống tiến hành gửi ZNS ngay sau khi xác nhận tạo chiến dịch</Text>
      </Col>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Tên chiến dịch">{dataPost.name}</Descriptions.Item>
        <Descriptions.Item label="OA gửi">{oaName}</Descriptions.Item>
        <Descriptions.Item label="Giá tiền 1 tin nhắn">{numeral(dataPost.content.zalo.price).format("0,0")}đ/ZNS</Descriptions.Item>
        <Descriptions.Item label="Số lượng người nhận">{dataPost.list_customer.length}</Descriptions.Item>
        <Descriptions.Item label="Chi phí dự kiến">
          <Text strong>{numeral(dataPost.content.zalo.price * dataPost.list_customer.length).format("0,0")} đ</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Thời gian gửi">
          <Form form={form} initialValues={{ schedule_type: scheduleType }}>
            <Form.Item name={"schedule_type"} style={{ marginBottom: 8 }}>
              <Radio.Group onChange={onChangeSchedule}>
                <Space direction="vertical">
                  <Radio value={1}>Gửi ngay</Radio>
                  <Radio value={2}>Cài đặt giờ gửi</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {scheduleType === 2 && (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["schedule", "date"]}
                help="Vui lòng bấm Enter sau khi nhập dữ liệu ngày gửi để cập nhật thời gian chính xác"
                rules={
                  scheduleType === 2
                    ? [
                        {
                          required: true,
                          message: "Vui lòng chọn thời gian gửi chiến dịch!",
                        },
                      ]
                    : []
                }
              >
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  placeholder="DD/MM/YYYY HH:mm"
                  format={"DD/MM/YYYY HH:mm"}
                />
                {/* <DatePicker renderExtraFooter={() => "extra footer"} showTime /> */}
              </Form.Item>
            )}
          </Form>
        </Descriptions.Item>
      </Descriptions>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            back();
          }}
        >
          Thoát
        </Button>
        <Space>
          <Button
            onClick={() => {
              setCurrentStep(1);
            }}
          >
            Quay lại
          </Button>
          <Button
            type="primary"
            onClick={() => {
              saveCampaignZnsExcel();
            }}
          >
            Hoàn tất
          </Button>
        </Space>
      </Row>
    </>
  );
}

export default CompleteCampaign;
