import React, { Component } from "react";
import { Button, message, List, Row, Col, Avatar, Form, Card, Empty, Skeleton, Switch, Radio, InputNumber, Popconfirm } from "antd";
import api from "api";
import PopupMemberShipLevel from "./popupmembership";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { io } from "socket.io-client";
import { wss_url } from "config";
import { getSocket } from "common/realtime";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "rgb(228, 230, 235)",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});

export default class MemberShipLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointMethodData: [],
      lstLevel: [],
      showProgram: false,
      checked: true,
      type: "",
      percentToSendNotification: 98,
      show: false,
      textBtn: "Cập nhật hạng của khách hàng",
      loadingUpdateAll: false,
      enableTier: true,
    };
    this.PopupMemberShipLevel = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount() {
    this.getData();
    var socket = getSocket("admin_socket");
    socket.on("connect", () => {
      socket.on("update_all_customer_tier", (data) => {
        console.log("ok", data);
        // data.current == data.total ? this.setState({ textBtn: "" }) : this.setState({ textBtn: data.current + "/" + data.total });
        this.setState({ textBtn: "Cập nhật hạng của khách hàng ( " + data.current + "/" + data.total + " )" });
        if (data.current == data.total) {
          this.setState({ loadingUpdateAll: false });
        }
      });
    });
    this.socket = socket;
  }

  getData = async () => {
    var res = await api.post("loyaltyconfig/gettier");
    if (res != null) {
      this.setState({ lstLevel: res.tiers, show: true, type: res.type, enableTier: res.enable }, () => {
        this.form.current.setFieldsValue(res);
      });
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    var lst = [...this.state.lstLevel];
    var lst = reorder(lst, result.source.index, result.destination.index);
    this.setState({
      lstLevel: lst,
    });
  };
  save = () => {
    api
      .post("loyaltyconfig/tierstier", { lst: this.state.lstLevel })
      .then((res) => {
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  finish = (values) => {
    api
      .post("loyaltyconfig/savegeneralconfig", { data: values })
      .then((res) => {
        this.getData();
        message.success("Lưu thành công");
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  delete = (id) => {
    api
      .post("/loyaltyconfig/delete", { id: id })
      .then((res) => {
        message.success("Xóa thành công");
        this.setState({ visible: false }, () => {
          this.getData();
        });
      })
      .catch((err) => {
        message.error("Xóa thất bại");
      });
  };

  updateAllCustomerTier = () => {
    this.setState({ loadingUpdateAll: true });
    message.success("Đang cập nhật hạng thành viên của khách hàng");
    api.post("/loyaltyconfig/updateallcustomertier");
  };

  render() {
    return (
      <div>
        <div style={{ fontSize: "30px", fontWeight: "bold" }}>Hạng thành viên</div>
        <Row>
          <Col span={8}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Hạng thành viên
            </div>
            <div className="description">
              Tạo ra những phương thức giúp khách hàng tích điểm
              <br></br> khi họ mua hàng, tham gia các hoạt động like, share <br></br> hoặc tương tác với thương hiệu
            </div>
            <div className="description">
              Danh sách hạng thành viên được sắp xếp từ cao xuống thấp.
              <br></br> Kéo thả hạng thành viên để sắp xếp chúng.
            </div>
            <br></br>

            <div style={{ marginTop: "50px" }}>
              <Button
                type="primary"
                onClick={() => {
                  this.PopupMemberShipLevel.current.open("");
                }}
              >
                Hạng thành viên
              </Button>
            </div>
          </Col>
          {this.state.show && (
            <Col span={16}>
              <div style={{ marginBottom: 50 }}>
                <Card title="Cấu hình chung hạng thành viên">
                  <Form ref={this.form} onFinish={this.finish}>
                    <Form.Item name="enable" label="Kích hoạt" valuePropName="checked">
                      <Switch checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                    <Form.Item name="type" label="Tiêu chí xếp hạng">
                      <Radio.Group>
                        <Radio value="total_spent">Tính theo tiền chi tiêu</Radio>
                        <Radio value="points">Tính theo điểm tích lũy</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="percent_send_notification_uprate" label="Mức tích lũy sắp tăng hạng">
                      <InputNumber
                        value={this.state.percentToSendNotification}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                      />
                    </Form.Item>
                    <div style={{ width: "100%" }}>
                      <Form.Item style={{ float: "right" }}>
                        <Button type="primary" htmlType="submit">
                          Lưu
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Card>
              </div>
              {this.state.lstLevel == null || this.state.lstLevel.length === 0 ? (
                <Card
                  title={<h5 style={{ marginTop: 10 }}>{"Hạng thành viên"}</h5>}
                  style={{ marginLeft: 20 }}
                  bodyStyle={{ height: "25vh" }}
                  extra={
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="off"
                      checked={this.state.checked}
                      size="default"
                      onChange={(checked) => this.onSwitchChange(checked)}
                    />
                  }
                >
                  <div>
                    <Empty description="Hãy tạo mới hạng thành viên"></Empty>
                    <Row justify="center">
                      <Button
                        onClick={() => {
                          this.PopupMemberShipLevel.current.open("");
                        }}
                        style={{ marginTop: 20 }}
                        type="primary"
                      >
                        <i className="fas fa-plus"></i>&nbsp;Tạo mới
                      </Button>
                    </Row>
                  </div>
                </Card>
              ) : (
                <>
                  <Card title="Danh sách hạng thành viên" bodyStyle={{ padding: 5 }}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.lstLevel.map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <li style={{ margin: "0px", padding: "10px" }} ref={provided.innerRef} {...provided.draggableProps}>
                                    <div
                                      style={
                                        index === 0
                                          ? { padding: 10, display: "flex", justifyContent: "space-between" }
                                          : { padding: 10, display: "flex", justifyContent: "space-between", borderTop: "1px solid #dfdfdf" }
                                      }
                                    >
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{ width: 50, display: "flex", fontSize: 20, marginRight: 20, borderRight: "1px solid #dfdfdf" }}
                                          {...provided.dragHandleProps}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            <i className="fas fa-arrows-alt"></i>
                                          </span>
                                        </div>
                                        <div>
                                          <Avatar shape="square" size={70} src={item.logo} />
                                        </div>
                                        <div style={{ marginLeft: 20 }}>
                                          <div style={{ fontWeight: 500, fontSize: 18, marginBottom: 5 }}>
                                            <span>{item.name}</span>
                                          </div>
                                          <div style={{ fontSize: 18, color: "#a8a6a6" }}>
                                            <span>
                                              {item.short_description != null && item.short_description != "" ? (
                                                <div dangerouslySetInnerHTML={{ __html: item.short_description }}></div>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div style={{ margin: "auto" }}>
                                          <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.delete(item.id)} okText="Có" cancelText="Không">
                                            <Button type="link" style={{ fontSize: 18 }}>
                                              Xóa
                                            </Button>
                                          </Popconfirm>
                                          <Button
                                            style={{ fontSize: 18 }}
                                            onClick={() => {
                                              this.PopupMemberShipLevel.current.open(item.id);
                                            }}
                                            type="link"
                                          >
                                            Chỉnh sửa
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10, marginTop: 10 }}>
                      <div style={{ float: "right", marginLeft: 20 }}>
                        <Button
                          type="primary"
                          loading={this.state.loadingUpdateAll}
                          onClick={() => {
                            this.updateAllCustomerTier();
                          }}
                        >
                          {this.state.textBtn}
                        </Button>
                      </div>
                      <div style={{ float: "right", marginRight: 20 }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Lưu
                        </Button>
                      </div>
                    </div>
                  </Card>
                </>
              )}
            </Col>
          )}
        </Row>
        <PopupMemberShipLevel ref={this.PopupMemberShipLevel} reload={this.getData} type={this.state.type}></PopupMemberShipLevel>
      </div>
    );
  }
}
