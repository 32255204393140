import React, { useState } from "react";
import { Card, Steps, Button, Form, Input, Row, Col, Select, Space, DatePicker, Tabs, message } from "antd";

import ContentPicker from "../picker/content_picker/index";
import api from "api";

import { createRef } from "react";

function ContentCampaign({ nextStep, prevStep, wayToSend, value, onChange }) {
  /**
   * nextStep: function next step
   * prevStep: function prev step
   * wayToSend:[app] list of selected app
   * value: object {email,smsFPT, zalo,..} the content of campaign
   * onChange : function ({email,smsFPT, zalo,..} ) callback when content changed
   */
  const contentPicker = createRef("contentPicker");
  const onNextStep = async () => {
    var error = await contentPicker.current.validate();
    if (!error) {
      nextStep();
    }
  }

  return (
    <>

      <ContentPicker value={value} ref={contentPicker} onChange={onChange} wayToSend={wayToSend} />

      <Space style={{ float: "right", marginTop: 16 }}>
        <Button onClick={prevStep}>Trở lại</Button>
        <Button onClick={onNextStep} type="primary">
          Tiếp tục
        </Button>
      </Space>
    </>
  );
}

export default ContentCampaign;
