import React, { PureComponent } from "react";
import { Tooltip, Select, Tabs } from "antd";
import { BUILT_IN_PLACEMENTS, SELECT_WIDTH_OFFSET_RIGHT, calcTextWidth } from "../../../../utils/domUtils";
import PropTypes from "prop-types";

import keys from "lodash/keys";
import { applyToJS } from "components/common/react-awesome-query-builder/lib/utils/stuff";
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

export default class FieldSelectCustom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      default_tab: "default",
      fields: this.props.config.fields,
      items: this.props.items,
      display_field: this.props.items,
    };
  }

  static propTypes = {
    config: PropTypes.object.isRequired,
    customProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    selectedKey: PropTypes.string,
    selectedKeys: PropTypes.array,
    selectedPath: PropTypes.array,
    selectedLabel: PropTypes.string,
    selectedAltLabel: PropTypes.string,
    selectedFullLabel: PropTypes.string,
    selectedOpts: PropTypes.object,
    readonly: PropTypes.bool,
    //actions
    setField: PropTypes.func.isRequired,
  };

  onChange = (key) => {
    this.props.setField(key);
  };

  filterOption = (input, option) => {
    const dataForFilter = option;
    const keysForFilter = ["title", "value", "grouplabel", "label"];
    const valueForFilter = keysForFilter.map((k) => (typeof dataForFilter[k] == "string" ? dataForFilter[k] : "")).join("\0");
    return valueForFilter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  render() {
    const { config, customProps, items, placeholder, selectedKey, selectedLabel, selectedOpts, selectedAltLabel, selectedFullLabel, readonly } = this.props;
    const { showSearch } = customProps || {};

    const selectText = selectedLabel || placeholder;
    const selectWidth = calcTextWidth(selectText);
    const isFieldSelected = !!selectedKey;
    const dropdownPlacement = config.settings.dropdownPlacement;
    const dropdownAlign = dropdownPlacement ? BUILT_IN_PLACEMENTS[dropdownPlacement] : undefined;
    const width = isFieldSelected && !showSearch ? null : selectWidth + SELECT_WIDTH_OFFSET_RIGHT;
    let tooltipText = selectedAltLabel || selectedFullLabel;
    if (tooltipText == selectedLabel) tooltipText = null;

    const fieldSelectItems = this.renderSelectItems(this.state.display_field, "default");
    //const fieldSelectItemsTabOption = this.renderSelectItems(items, "optional");

    const dropdown = "1";
    let res = (
      <Select
        dropdownAlign={dropdownAlign}
        dropdownMatchSelectWidth={false}
        style={{ width }}
        placeholder={placeholder}
        size={config.settings.renderSize}
        onChange={this.onChange}
        value={selectedKey || undefined}
        filterOption={this.filterOption}
        disabled={readonly}
        dropdownRender={(
          menu //this.renderOption(menu)
        ) => (
          <>
            <Tabs defaultActiveKey="default" style={{ width: 300, margin: 10 }} onChange={this.renderTabSelect}>
              <TabPane tab="Mặc Định" key="default"></TabPane>
              <TabPane tab="Tùy Chỉnh" key="optional"></TabPane>
            </Tabs>
            {menu}
          </>
        )}
        {...customProps}
      >
        {fieldSelectItems}
      </Select>
    );

    if (tooltipText && !selectedOpts.tooltip) {
      res = <Tooltip title={tooltipText}>{res}</Tooltip>;
    }

    return res;
  }

  renderTabSelect = (activeKey) => {
    console.log("here", activeKey);
    if (activeKey != "default") {
      var newarr = [];
      keys(this.state.fields).map((field) => {
        if (this.state.fields[field].tab_key == activeKey) {
          newarr.push(field);
        }
      });

      if (newarr.length > 0) {
        var rs = this.state.display_field.filter((x) => newarr.includes(x.key));
        this.setState({
          display_field: rs,
        });
      }
      return;
    }

    this.setState({
      display_field: this.state.items,
      default_tab: activeKey,
    });
  };

  renderSelectItems(fields) {
    return keys(fields).map((fieldKey) => {
      const field = fields[fieldKey];
      const { items, key, path, label, fullLabel, altLabel, tooltip, grouplabel, disabled } = field;
      const pathKey = path || key;

      if (items) {
        return (
          <OptGroup key={pathKey} label={label}>
            {this.renderSelectItems(items)}
          </OptGroup>
        );
      } else {
        const option = tooltip ? <Tooltip title={tooltip}>{label}</Tooltip> : label;
        return (
          <Option key={pathKey} value={pathKey} title={altLabel} grouplabel={grouplabel} label={label} disabled={disabled}>
            {option}
          </Option>
        );
      }
    });
  }
}
