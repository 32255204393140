import React from "react";
import { Modal, Space, Input, Divider, Table, Button, Upload, Steps, Alert, Form, Select, Card, message, Result, Radio, Checkbox } from "antd";
import { dataTypes, makeid } from "common/custom_field";
import api from "api";
import { formatShortDate, formatLongDate } from "../../common/format/date";
import { formatDecimal, formatCurrency } from "../../common/format/number";

export default class PopupCustomerField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      data: {},
      dataTypes: dataTypes,
      lstConfig: [],
      field: {},
      id: "",
      show: false,
    };
    this.form = React.createRef();
    this.input = React.createRef();
  }

  getColID = (e) => {
    var col = "";
    while (true) {
      col = "col_" + makeid(5);
      if (this.state.lstConfig.findIndex((x) => x.id == col) < 0) return col;
    }
  };

  getCustomConfig = (id) => {
    api
      .post("customerconfig/getconfig", { query: { key: "field" } })
      .then((res) => {
        if (id != null) {
          var value = res.find((x) => x.id === id);
          var data = this.state.dataTypes.find((x) => x.type === value.type);
          if (value) {
            this.setState({ field: value, type: value.type, data: data }, () => {
              this.form.current.setFieldsValue(value);
              this.input.current.focus({ cursor: "end" });
            });
          }
        } else {
          var type = this.state.dataTypes.find((x) => x.type === "text");
          var defaultid = this.getColID();
          var defaultvalues = { ...type, label: "", id: defaultid, attribute: "", isrequire: false };
          this.setState({ data: type }, () => {
            this.form.current.setFieldsValue(defaultvalues);
            this.input.current.focus({ cursor: "end" });
          });
        }
        if (res.length > 0) {
          this.setState({ lstConfig: res });
        }
        this.setState({ show: true });
      })
      .catch((err) => {});
  };

  radioChange = (e) => {
    var values = null;
    if (e.target.value.type === this.state.field.type) {
      values = this.state.field;
    } else {
      var id = this.getColID();
      if (this.state.id != null && this.state.id != "") id = this.state.id;
      values = { ...e.target.value, label: "", id: id, attribute: "", isrequire: false };
    }

    this.setState({ data: e.target.value }, () => {
      this.form.current.setFieldsValue(values);
      this.input.current.focus({ cursor: "end" });
    });
  };

  open = (id) => {
    this.getCustomConfig(id);

    this.setState({ visible: true, id: id });
  };
  close = () => {
    this.setState({ visible: false, data: {} });
  };

  toNoUnicode = (value) => {
    var str = value;
    str = str.replace(/\s/g, "");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$| |/g, "");
    str = str.replace(/-+-/g, "-");
    str = str.replace(/^\-+|\-+$/g, "");
    return str;
  };

  onNameChange = (e) => {
    var value = this.toNoUnicode(e.target.value.replaceAll(" ", "_").toLowerCase());
    this.form.current.setFieldsValue({ attribute: value });
  };

  onChangeInputNumber = (value) => {
    if (value == String) {
      var result = Number(value);
      var value = formatDecimal(result);
      this.form.current.setFieldsValue({ format_number: value });
    }
    formatDecimal(value);
  };

  save = (values) => {
    values.attribute = this.toNoUnicode(values.attribute);
    if (this.state.lstConfig.findIndex((x) => x.attribute == values.attribute && x.id !== values.id) >= 0) {
      message.error("ID đã tồn tại");
    } else {
      var dup = false;
      var lst = this.state.lstConfig;
      var data = { ...this.state.data, ...values };
      if (data.type == "dropdown" || data.type == "check" || data.type == "multiple" || data.type == "radio") {
        data.options.forEach((item, i) => {
          var index = data.options.findIndex((x) => x.label.toLowerCase() === item.label.toLowerCase());
          if (index != -1 && index != i) dup = true;
          else {
            var value = this.toNoUnicode(data.options[i].label.replaceAll(" ", "_").toLowerCase());
            data.options[i].value = value;
          }
        });
      }
      if (dup) message.error("Các option không được phép trùng nhau");
      else {
        if (this.state.id != null && this.state.id != "") {
          var index = lst.findIndex((x) => x.id === this.state.id);
          if (index > -1) {
            lst[index] = data;
          }
        } else lst.push(data);
        api
          .post("customerconfig/save", { config: lst, key: "field" })
          .then((res) => {
            message.success("Lưu thành công", 5);
            this.props.reloadOption(data);
            this.close();
          })
          .catch((err) => {
            message.error("Lưu thất bại", 5);
            this.close();
          });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.visible && (
          <Modal
            title="Thêm trường"
            open={this.state.visible}
            onOk={() => {
              this.form.current.submit();
            }}
            onCancel={() => this.close()}
            okText="Lưu"
            width="80%"
            bodyStyle={{ minHeight: 500 }}
            maskClosable={false}
          >
            <div>
              <div>
                <span style={{ marginRight: 50, fontWeight: "bold" }}>Loại dữ liệu:</span>
                <Radio.Group
                  value={this.state.data}
                  onChange={(e) => {
                    this.radioChange(e);
                  }}
                >
                  {this.state.dataTypes.map((item) => {
                    return <Radio value={item}>{item.label}</Radio>;
                  })}
                </Radio.Group>
              </div>
              <Divider></Divider>
              {JSON.stringify(this.state.data) !== "{}" && (
                <div>
                  <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "20px 20px", border: "1px solid #dfdfdf" }}>
                    <Form layout="vertical" ref={this.form} onFinish={this.save}>
                      <Form.Item
                        label="Tên"
                        name="label"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập tên!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="name"
                          ref={this.input}
                          onChange={(e) => {
                            if (this.state.id == null || this.state.id == "") {
                              this.onNameChange(e);
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name="id" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="ID"
                        name="attribute"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập ID!",
                          },
                        ]}
                      >
                        <Input placeholder="ID" readOnly={this.state.id != null && this.state.id != "" && true} />
                      </Form.Item>
                      <Form.Item name="isrequire" valuePropName="checked">
                        <Checkbox> Bắt buộc có giá trị</Checkbox>
                      </Form.Item>

                      {this.state.data.type == "number" && (
                        <Form.Item label="Format" name="format_number" onChange={(e) => this.onChangeInputNumber(e.target.value)}>
                          <Input placeholder="Format" />
                        </Form.Item>
                      )}

                      {(this.state.data.type == "date" || this.state.data.type == "datetime") && (
                        <Form.Item label="Format" name="format">
                          <Input placeholder="Format" />
                        </Form.Item>
                      )}

                      {(this.state.data.type == "dropdown" || this.state.data.type == "check" || this.state.data.type == "multiple" || this.state.data.type == "radio") && (
                        <>
                          <div>Ý kiến</div>
                          <Form.List name="options">
                            {(fields, { add, remove }, { errors }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <>
                                    <div style={{ display: "flex", maxWidth: 500 }}>
                                      <div style={{ flex: 1 }}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "label"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Bạn chưa nhập ý kiến!",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="value" style={{ width: "100%" }} />
                                        </Form.Item>
                                      </div>
                                      <div style={{ display: "none" }}>
                                        <Form.Item {...restField} name={[name, "value"]}>
                                          <Input placeholder="id" style={{ width: "100%" }} />
                                        </Form.Item>
                                      </div>
                                      <div>
                                        <div style={{ display: "flex", margin: 10 }} onClick={() => remove(name)}>
                                          <i style={{ margin: "auto" }} className="fa fa-minus-circle" aria-hidden="true"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                                <Button type="dashed" onClick={() => add({ value: "", label: "" })} style={{ width: "60%" }}>
                                  Add
                                </Button>
                              </>
                            )}
                          </Form.List>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  }
}
