import React, { Component } from "react";
import { Button, Space, Dropdown, Menu } from "antd";
import api from "api";
import { message, Form, Input, Card, notification } from "antd";
import numeral from "numeral";
import PopupNameSegment from "./popupnamesegment";
import Table from "components/common/AntTable";
import AddNewEdit from "components/customer/add";
import PopupImportExcel from "components/customer/Excel/PopupImportEcxel";
// Query builder
//=========Begin=========
import { Query, Builder, Utils as QbUtils } from "components/common/react-awesome-query-builder";
import AntdConfig from "components/common/react-awesome-query-builder/lib/config/antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "components/common/react-awesome-query-builder/lib/css/styles.css";
import "components/common/react-awesome-query-builder/lib/css/compact_styles.css";
import dayjs from "dayjs";
import { setQueryField } from "./query_field";
import { setTblCols } from "./table_cols";
import PopupUpdateCustomerPoint from "./popup_update_customerpoint";
import PopupInitTotalSpent from "./popup_init_total_spent";

const InitialConfig = AntdConfig;
const config = {
  ...InitialConfig,
  fields: {},
};

console.log('AntdConfig', AntdConfig);

const { Search } = Input;

const queryValue = { id: QbUtils.uuid(), type: "group" };
//========End=========

export default class SegmentsCampingAddNewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      //config: { ...InitialConfig, fields: {} },
      config: config,
      visible: false,
      lstCol: [],
      dataSource: [],
      segmentInfo: {
        number_customer: 0,
        name: "",
        query: "",
        status: true,
        create_date: dayjs(),
        date_update: dayjs(),
        tree: {},
        filter: {},
      },
      filter: {},
      loadingSegment: false,
      id: 0,
      search_mode: "simple",
      loadingSearch: true,
      loadingFinish: true,
      lstSelect: [],
      edit_point_type: "add",
    };
    this.PopupNameSegment = React.createRef();
    this.TableSegments = React.createRef();
    this.tbCustomer = React.createRef();
    this.Tb = React.createRef();
    this.AddNewEdit = React.createRef();
    this.PopupImportExcel = React.createRef();
    this.popupUpdateCustomerPoint = React.createRef();
    this.PopupInitTotalSpent = React.createRef();
  }

  ///=== React Lifecyle ======
  ///===========BEGIN=================
  componentDidMount = async () => {
    await this.getCustomerConfig();
    if (this.props.id && this.props.id !== "") {
      this.getSegment(this.props.id);
    }
  };

  getCustomerConfig = async () => {
    await api
      .get("customerconfig/list", {})
      .then((res) => {
        // console.log("1");
        setQueryField(res, config, this);
        setTblCols(res, this);
      })
      .catch((err) => { });
  };

  renderBuilder = (props) => (
    <div className="query-builder-container" style={{ paddingBottom: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  onChange = (immutableTree, config) => {
    this.setState({ tree: immutableTree, config: config });
  };

  updatePoint = (data) => {
    this.setState(
      {
        lstSelect: [data],
      },
      () => this.popupUpdateCustomerPoint.current.open()
    );
  };

  getSegment = (id) => {
    let filter = this.state.filter;
    this.setState({ loadingSegment: true });

    api
      .post("segments/getsegmentbyid", { id: id })
      .then((res) => {
        this.props.getName(res.name);
        let tree = QbUtils.checkTree(QbUtils.loadTree(res.tree), config);
        if (res.query) {
          filter.query = res.query == "" ? {} : JSON.parse(res.query);
        } else {
          filter.query = {};
        }

        this.setState({ segmentInfo: res, tree: tree, filter: filter, loadingSegment: false });
        filter.pagging = {};

        this.getlistCustomerByFilter(filter);
      })
      .catch((err) => {
        debugger;
      });
  };

  saveNameSegment = (name) => {
    this.verifySegment();
    var segmentInfo = this.state.segmentInfo;
    segmentInfo.name = name;
    this.setState({ segmentInfo: segmentInfo }, () => this.addNewEdit());
  };

  addNewEdit = () => {
    if (this.state.segmentInfo.name === "") {
      message.error("Vui lòng nhập tên chiến dịch");
      return;
    }
    if (this.state.segmentInfo.query === "") {
      message.error("Vui lòng điều chỉnh rule");
      return;
    }

    let segment = this.state.segmentInfo;
    segment.status = true;

    this.setState({ loadingSegment: true });

    api
      .post("segments/addneweditsegment", { segment: segment })
      .then((res) => {
        message.success("Lưu thành công");
        this.setState({ loadingSegment: false });

        if (this.props.reload) {
          this.props.reload();
        }
      })
      .catch((err) => {
        message.error("Đã có lỗi xảy ra");
      });
  };

  getlistCustomerByFilter = (filter) => {
    this.tbCustomer.current.reload();
  };

  onGetDataFinish = (res) => {
    if (res.error) {
      notification.error({
        message: "Lỗi",
        description: "Không thể lọc khách hàng. Vui lòng thử lại",
      });
      let dataSoure = [];
      let segmentInfo = this.state.segmentInfo;
      segmentInfo.number_customer = 0;
      this.setState({ dataSoure: dataSoure, segmentInfo: segmentInfo, loadingSearch: false });
    } else {
      let dataSoure = res.data;
      let segmentInfo = this.state.segmentInfo;
      segmentInfo.number_customer = res.total;
      this.setState({ dataSoure: dataSoure, segmentInfo: segmentInfo, loadingSearch: false });
    }
  };

  verifySegment = () => {
    this.setState({ loadingSearch: true });
    let filter = this.state.filter;
    let query = JSON.stringify(QbUtils.mongodbFormat(this.state.tree, config));

    if (query !== undefined) {
      let segmentInfo = this.state.segmentInfo;
      segmentInfo.query = query;
      segmentInfo.tree = this.state.tree;
      this.setState({ segmentInfo: segmentInfo });
    }

    if (query) {
      filter.query = query == "" ? {} : JSON.parse(query);
      filter.pagging = {};
    } else {
      filter.query = {};
      filter.pagging = {};
    }

    // console.log(JSON.stringify(filter.query));
    this.getlistCustomerByFilter(filter);
  };

  createSegment = () => {
    this.PopupNameSegment.current.open(this.state.segmentInfo.name);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id !== prevState.id) {
      return { id: nextProps.id };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      //Perform some operation here
      if (this.props.id.id === "" || this.props.id == null || this.props.id === -1) {
        var segmentInfo = {
          number_customer: 0,
          name: "",
          query: "",
          status: true,
          create_date: dayjs(),
          date_update: dayjs(),
          tree: {},
          filter: {},
        };
        var tree = QbUtils.checkTree(QbUtils.loadTree(queryValue), config);
        this.setState({ segmentInfo: segmentInfo, filter: {}, lstCol: [], dataSource: [], tree: tree }, () => {
          this.tbCustomer.current.reload();
        });
      } else {
        this.getSegment(this.props.id);
      }
    }
  }

  close = () => {
    this.setState({
      visible: false,
      filter: {},
      segmentInfo: {
        number_customer: 0,
        name: "",
        query: "",
        status: true,
        create_date: dayjs(),
        date_update: dayjs(),
        tree: {},
        filter: {},
      },
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      dataSource: [],
    });
  };

  onSearchInfo = (info) => {
    this.setState({ loadingSearch: true });
    let filter = this.state.filter;
    var query = {};
    if (info && info.trim() != "") {
      info = info.trim();
      query = {
        $or: [
          { first_name: { $regex: `${info}`, $options: "i" } },
          { last_name: { $regex: `${info}`, $options: "i" } },
          { phone: { $regex: `${info}`, $options: "i" } },
          { email: { $regex: `${info}`, $options: "i" } },
        ],
      };
    }

    filter.query = query;
    filter.pagging = {};
    this.getlistCustomerByFilter(filter);
  };

  isSegmentMode() {
    if (this.props.segmentMode) {
      return true;
    }
    return this.state.search_mode != "simple";
  }

  exportExcel = () => {
    var name = "excelCustomer" + dayjs().format("DD-MM-YYYY");
    this.tbCustomer.current.exportExcel(name);
  };

  render() {
    var isSegmentMode = this.isSegmentMode();
    // console.log("3", config);
    return (
      <div>
        <Card>
          <div style={{ display: !isSegmentMode ? "flex" : "none" }}>
            <div style={{ minWidth: 200, marginBottom: 15, flex: 1 }}>
              <Search placeholder="Thông tin khách hàng" onSearch={(e) => this.onSearchInfo(e)} style={{ width: "100%" }} ic />
            </div>

            <div style={{ marginLeft: 15 }}>
              <Button type="primary" onClick={() => this.setState({ search_mode: "more" })} loading={this.state.loadingFinish || this.state.loadingSearch}>
                <Space>
                  <i className="fa fa-filter" aria-hidden="true"></i>
                  Tìm kiếm nâng cao & phân nhóm
                </Space>
              </Button>
            </div>
          </div>

          <div style={{ display: isSegmentMode ? "block" : "none" }}>
            <div style={{ display: !this.props.segmentMode ? "flex" : "none", justifyContent: "space-between" }}>
              <div>
                <h2>{this.state.segmentInfo.name}</h2>
              </div>
            </div>
            <Form
              onFinish={() => {
                if (isSegmentMode) {
                  this.verifySegment();
                }
              }}
            >
              <Form.Item>
                <Query {...config} value={this.state.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 10, marginBottom: 10 }}>
            <Space>
              <div>
                <b>Kết quả: </b>
                {this.state.loadingSearch ? (
                  <>
                    <Spin indicator={<LoadingOutlined spin />} />
                  </>
                ) : (
                  <>{numeral(this.state.segmentInfo.number_customer).format("#,##0")} khách hàng</>
                )}
              </div>

              <Button onClick={() => this.AddNewEdit.current.open(0)}>
                <Space>
                  <i className="fas fa-plus"></i>Thêm khách hàng
                </Space>
              </Button>
              <Button
                onClick={() => {
                  this.PopupImportExcel.current.open();
                }}
              >
                <Space>
                  <i className="far fa-file-excel"></i>Nhập Excel
                </Space>
              </Button>
              <Button onClick={() => this.exportExcel()}>
                <Space>
                  <i className="fa fa-download" aria-hidden="true"></i>Xuất Excel
                </Space>
              </Button>

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="addpoint"
                      onClick={() => {
                        this.setState({
                          edit_point_type: "add",
                        });
                        this.popupUpdateCustomerPoint.current.open();
                      }}
                    >
                      Thêm điểm
                    </Menu.Item>
                    <Menu.Item
                      key="updatepoint"
                      onClick={() => {
                        this.setState({
                          edit_point_type: "update",
                        });
                        this.popupUpdateCustomerPoint.current.open();
                      }}
                    >
                      Cập nhật điểm
                    </Menu.Item>

                    <Menu.Item
                      key="updatetotalamount"
                      onClick={() => {
                        this.PopupInitTotalSpent.current.open();
                      }}
                    >
                      Cập nhật số tiền đã chi tiêu
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  <Space>
                    Thêm <i className="fas fa-chevron-down"></i>
                  </Space>
                </Button>
              </Dropdown>

              <div style={{ display: isSegmentMode ? "flex" : "none" }}>
                <Space>
                  <Button type="primary" onClick={() => this.verifySegment()} loading={this.state.loadingSearch}>
                    <Space>
                      <i className="fas fa-search"></i>Tìm kiếm
                    </Space>
                  </Button>

                  <Button type="primary" onClick={() => this.createSegment()} loading={this.state.loading}>
                    <Space>
                      <i className="fas fa-save"></i>Lưu nhóm khách hàng
                    </Space>
                  </Button>

                  <Button
                    onClick={(e) =>
                      this.setState(
                        {
                          search_mode: "simple",
                        },
                        (_) => this.onSearchInfo("")
                      )
                    }
                  >
                    <Space>
                      <i className="fas fa-times"></i> Đóng
                    </Space>
                  </Button>
                </Space>
              </div>
            </Space>
          </div>
          {this.state.table_cols && (
            <Table
              scroll={{ x: 200 }}
              columns={this.state.table_cols}
              customSearch={this.state.filter}
              tableName={"tbCustomer"}
              ajax="customer/list"
              ref={this.tbCustomer}
              rowKey={(record) => record.id}
              onGetDataFinish={this.onGetDataFinish}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ lstSelect: selectedRows });
                },
              }}
            ></Table>
          )}
        </Card>

        <PopupNameSegment ref={this.PopupNameSegment} saveName={this.saveNameSegment}></PopupNameSegment>
        <PopupImportExcel ref={this.PopupImportExcel} reload={() => this.tbCustomer.current.reload()}></PopupImportExcel>
        <AddNewEdit ref={this.AddNewEdit} getData={() => this.tbCustomer.current.reload()}></AddNewEdit>
        <PopupUpdateCustomerPoint ref={this.popupUpdateCustomerPoint} type={this.state.edit_point_type} lstCus={this.state.lstSelect}></PopupUpdateCustomerPoint>
        <PopupInitTotalSpent ref={this.PopupInitTotalSpent} lstCus={this.state.lstSelect}></PopupInitTotalSpent>
      </div>
    );
  }
}
