import React, { Component, useState, createRef } from "react";
import { Input, Button, Steps, Card, Form, Row, Col, message, Modal, Select, Checkbox, InputNumber, Radio, Tabs, Popconfirm, Spin, Space } from "antd";
import api from "api";
import WayToSendPicker from "../campaign/waytosendpicker";
import ContentPicker from "../picker/content_picker/index";
import { toNoUnicode } from "../../common/text";
import WayToSendCampaign from "components/campaign/wayToSendCampaign";
import { useEffect } from "react";

const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

// export default class EditNotification extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       lst: [],
//       lsterror: [],
//       visible: false,
//       current: 0,
//       data: {},
//       mode: "",
//       gotlstapp: false,
//       loadingBtn: false,
//       type: "",
//       description: "",
//     };

//     this.form = React.createRef();
//     this.select = React.createRef();
//     this.ContentPicker = React.createRef();
//   }

//   getApp = () => {
//     api
//       .post("shop/getinstalledappbycategories", { categories: ["communication"] })
//       .then((res) => {
//         this.setState({ lst: res, gotlstapp: true });
//       })
//       .catch((err) => {});
//   };

//   open = (event) => {
//     this.getApp();

//     api.post("/customer_notifications/getbyevent", { event: event }).then((res) => {
//       this.setState({ visible: true, current: 0, data: res, way_to_send: res.way_to_send }, () => {
//         this.setDataForm(res);
//         if (res.use_default_template) {
//           this.getDefaultTempalte(res.default_tempalte_id);
//         }
//       });
//     });
//   };

//   getDefaultTempalte(id) {
//     api.post("/master/emailtemplate/getbyid", { id: id }).then((res) => {
//       this.form.current.setFieldsValue({ content: { rich_media: { html: res.html, json: res.design } } });
//     });
//   }

//   close = () => {
//     this.setState({ visible: false, gotlstapp: false });
//   };

//   //các hàm chuyển tag

//   getErr = (step, data, parent) => {
//     var lstErr = [];

//     if (step.child.length === 0) {
//       if (data[step.name] == null || data[step.name] == "") {
//         if (parent) lstErr.push(parent + "." + step.name);
//         else lstErr.push(step.name);
//       }
//       console.log("lstErr", lstErr);
//       return lstErr;
//     } else {
//       step.child.forEach((item) => {
//         var err = this.getErr(item, data[step.name], step.name);
//         lstErr = lstErr.concat(err);
//       });
//       console.log("lstErr", lstErr);
//       return lstErr;
//     }
//   };

//   //lưu chiến dịch
//   save = (value) => {
//     var err = 0;
//     if (value.content.rich_media.subject == "" || value.content.rich_media.subject == null) {
//       var haveEmail = value.way_to_send.find((x) => x.id === "email" || x.id === "default_email");
//       if (haveEmail) {
//         this.ContentPicker.current.showError("subject");
//         err++;
//       }
//     }
//     if (value.content.text == "" || value.content.text == null) {
//       var haveSMS = value.way_to_send.find((x) => x.id === "smsfpt");
//       if (haveSMS) {
//         this.ContentPicker.current.showError("text");
//         err++;
//       }
//     }
//     if (err !== 0) {
//       this.setState({ loadingBtn: false });
//       return;
//     }
//     var way_to_send = value.way_to_send.map((item) => {
//       return {
//         id: item.id,
//         parameters: item.parameters,
//       };
//     });
//     value.way_to_send = way_to_send;
//     api
//       .post("customer_notifications/save", { data: value })
//       .then((res) => {
//         message.success("Lưu thành công", 3);
//         this.setState({ visible: false, loadingBtn: false }, () => {
//           this.props.reload();
//         });
//       })
//       .catch((err) => {
//         message.error("Lưu thất bại", 3);

//         this.setState({ loadingBtn: false });
//       });
//   };

//   //set giá trị ban đầu cho form
//   setDataForm = (data) => {
//     if (data?.content) {
//       if (!data.content?.chatbot) data.content.chatbot = null;
//     }
//     this.form.current.setFieldsValue(data);
//   };

//   onFinishFailed = () => {
//     message.info("Vui lòng điền đầy đủ thông tin");
//     this.setState({ loadingBtn: false });
//   };

//   onStepChange = (current) => {
//     this.setState({ current: current * 1 });
//   };

//   onTypeChange = (value) => {
//     this.setState({ type: value }, () => {
//       this.form.current.setFieldsValue({ schedule: { period: { type: "after", value: 30, key: "minutes" } } });
//       this.onDecription();
//     });
//   };

//   showErorMessage = () => {};

//   render() {
//     return (
//       <Modal
//         title="Thông báo khách hàng"
//         open={this.state.visible}
//         onOk={() => {}}
//         onCancel={() => {
//           this.close();
//         }}
//         width={"60%"}
//         maskClosable={false}
//         bodyStyle={{ minHeight: 500 }}
//         footer={[
//           <Button
//             onClick={() => {
//               this.close();
//             }}
//           >
//             Thoát
//           </Button>,
//           // <>
//           //   {this.state.mode === "edit" && (
//           //     <Popconfirm title="Bạn có chắc muốn xóa không?" onConfirm={() => this.delete(this.state.data.event)} okText="Có" cancelText="Không">
//           //       <Button>Xóa</Button>
//           //     </Popconfirm>
//           //   )}
//           // </>,
//           <Button
//             type="primary"
//             loading={this.state.loadingBtn ? true : false}
//             onClick={() => {
//               this.setState({ loadingBtn: true }, () => this.form.current.submit());
//             }}
//           >
//             Lưu
//           </Button>,
//         ]}
//       >
//         <Row>
//           <Col span={4} style={{ height: 350 }}>
//             <Tabs tabPosition={"left"} onChange={this.onStepChange} activeKey={this.state.current}>
//               <TabPane tab="Phương thức" key={0}></TabPane>
//               <TabPane tab="Nội dung" key={1}></TabPane>
//             </Tabs>
//           </Col>
//           <Col span={20}>
//             <div>
//               {this.state.visible && (
//                 <Form name="notifications" onFinishFailed={this.onFinishFailed} ref={this.form} layout="vertical" onFinish={this.save}>
//                   {this.state.gotlstapp && (
//                     <Card
//                       title={<b>Phương Thức Gửi</b>}
//                       style={{ marginTop: "20px" }}
//                       hidden={this.state.current === 0 ? false : true}
//                       extra={
//                         <div>
//                           <Form.Item
//                             noStyle
//                             shouldUpdate={(prevValues, currentValues) => {
//                               return true;
//                             }}
//                           >
//                             {({ getFieldValue }) =>
//                               getFieldValue("way_to_send") != undefined && getFieldValue("way_to_send").length > 1 ? (
//                                 <Form.Item style={{ height: "0" }} name="send_all_app" valuePropName="checked">
//                                   <Checkbox style={{ marginBottom: 10 }}>Gửi tất cả kênh</Checkbox>
//                                 </Form.Item>
//                               ) : (
//                                 <Form.Item style={{ height: "0" }} name="send_all_app" hidden valuePropName="checked">
//                                   <Checkbox style={{ marginBottom: 10 }}>Gửi tất cả kênh</Checkbox>
//                                 </Form.Item>
//                               )
//                             }
//                           </Form.Item>
//                         </div>
//                       }
//                     >
//                       <Form.Item
//                         name="way_to_send"
//                         rules={[
//                           {
//                             validator: (rule, value) => {
//                               return new Promise((resolve, error) => {
//                                 if (value.length !== 0) {
//                                   resolve();
//                                 } else {
//                                   error();
//                                 }
//                               });
//                             },
//                             message: "Bạn chưa chọn phương thức gửi!",
//                           },
//                         ]}
//                       >
//                         <WayToSendPicker
//                           load={this.state.current === 0 ? true : false}
//                           lst={this.state.lst}
//                           notification_id={this.state.data.id}
//                           onChange={(e) => {
//                             this.setState({ way_to_send: e });
//                           }}
//                         />
//                       </Form.Item>
//                     </Card>
//                   )}

//                   <div hidden={this.state.current === 1 ? false : true}>
//                     <Form.Item name="content">
//                       <ContentPicker
//                         ref={this.ContentPicker}
//                         wayToSend={this.state.way_to_send}
//                         onChange={() => {
//                           this.form.current.setFieldsValue({ use_default_template: false });
//                         }}
//                       ></ContentPicker>
//                     </Form.Item>
//                   </div>
//                   <Form.Item hidden name="enable"></Form.Item>
//                   <Form.Item hidden name="event"></Form.Item>
//                   <Form.Item hidden name="use_default_template"></Form.Item>
//                 </Form>
//               )}
//             </div>
//           </Col>
//         </Row>
//       </Modal>
//     );
//   }
// }

function EditNotification(props) {
  const [lstAllApp, setLstAllApp] = useState([]);
  const [lstApp, setLstApp] = useState([]);
  const [lstSelectedApp, setLstSelectedApp] = useState([]);
  const [wayToSend, setWayToSend] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState();
  const [content, setContent] = useState();
  const contentPicker = createRef("contentPicker");
  const [tabsActiveKey, setTabsActiveKey] = useState(1);

  useEffect(() => {
    getApp();
    getAllApp();
  }, []);

  useEffect(() => {
    if (props.isModalOpen) {
      getAppByEvent();
    } else {
      setTabsActiveKey(1);
      setLoading(true);
    }
  }, [props]);

  const handleCancel = () => {
    props.closeModal();
    setLoading(true);
  };

  const getApp = async () => {
    await api
      .post("shop/getinstalledappbycategories", { categories: ["communication"] })
      .then((res) => {
        setLstApp(res);

        // this.setState({ lst: res, gotlstapp: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllApp = async () => {
    await api
      .post("app/list")
      .then((res) => {
        setLstAllApp(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * check cấu hình có tồn tại trong app hay không
   * @param {string} app là id app
   * @param {string} type là trường cần so sánh giá trị
   * @param {string} value là giá trị cần so sánh
   * @param {object} newContent content
   * @author Tuấn
   */
  const handleCheckConfigApp = async (app, type, value,newContent) => {
    var result = false;
    var pathConfig = "getconfig";
    if (app == "fb") pathConfig = "getpages";
    if (app == "zalo") app = "zalov2";
    await api
      .post(`/app/${app.replace("_", "")}/config/${pathConfig}`)
      .then((res) => {
        var index = -1;
        var data = [];
        if (app == "zalov2") {
          if(res?.oas&&res?.oas?.length>0&&newContent?.zalo?.oa_id)
          {
            var checkOaId = res.oas.findIndex((x) => (x["oa_id"] == newContent.zalo.oa_id));
            if(checkOaId>=0)
            {
              // index = res.oas.findIndex((x) => (x[type] == value));
              if(res.oas[checkOaId][type]===value)
              {
                result = true;
                return
              }
                
            }
            newContent.zalo={
              oa_id:null,
              template_id:null,
              preview_url:null,
              price:null,
              params:[]}
            setContent(newContent)
          }
        }
        if (app == "email" || app == "esms" || app == "smsfpt") {
          data = res?.configs || [];
        }
        if (app == "default_email") {
          data = res || [];
        }
        if (app == "fb") {
          data = res?.pages || [];
        }
        if (data?.length && data?.length > 0) {
          index = data.findIndex((x) => (app == "fb" ? x.page == value : x[type] == value));
        }
        if (index >= 0) result = true;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  };
  /**
   * check cấu hình có tồn tại trong app hay không
   * @param {string} app là id app
   * @param {boolean} isSetting là gỡ cài đặt app
   * @author Tuấn
   */
  const showErorMessageOfConfigApp = (app, isSetting = false) => {
    var result = lstAllApp.find((item) => {
      return item.id === app;
    });
    var nameapp = app;
    if (result) nameapp = result.name;
    if (isSetting) message.warning(`App ${nameapp} cài đặt đã bị gỡ! vui lòng cài đặt lại app ${nameapp}`);
    else message.warning(`App ${nameapp} cấu hình đã bị gỡ! vui lòng cấu hình lại app ${nameapp}`);
  };
  const getAppByEvent = async () => {
    await api
      .post("/customer_notifications/getbyevent", { event: props.event })
      .then(async(res) => {
        setValue(res);
        setContent(res.content);
        var list = [];
        var way_to_send = [];
        if (res.way_to_send && res.way_to_send.length > 0) {
          for (const i of res.way_to_send) {
            //check id app được lưu có tồn tại hay không
            var checkexist = await handleCheckConfigApp(i.id, i.parameters[0].type, i.parameters[0].value,res.content);
            if (checkexist) {
              var find = lstApp.find((item) => {
                return item.id === i.id;
              });
              if (find) {
                list.push(find);
                way_to_send.push(i);
              } else {
                showErorMessageOfConfigApp(i.id, true);
              }
            } else {
              showErorMessageOfConfigApp(i.id);
            }
          }
          // res.way_to_send.forEach(async (i, index) => {
          //   //check id app được lưu có tồn tại hay không
          //   var checkexist = await handleCheckConfigApp(i.id, i.parameters[0].type, i.parameters[0].value,res.content);
          //   if (checkexist) {
          //     var find = lstApp.find((item) => {
          //       return item.id === i.id;
          //     });
          //     if (find) {
          //       list.push(find);
          //       way_to_send.push(i);
          //     } else {
          //       showErorMessageOfConfigApp(i.id, true);
          //     }
          //   } else {
          //     showErorMessageOfConfigApp(i.id);
          //   }
          //   if (res.way_to_send.length - 1 == index) {
          //     setLstSelectedApp(list);
          //     setWayToSend(way_to_send);
          //     setLoading(false);
          //   }
          // });
        } 
        setLstSelectedApp(list);
        setWayToSend(way_to_send);
        setLoading(false);
        // else{
        //   setLstSelectedApp(list);
        //   setWayToSend(way_to_send);
        //   setLoading(false);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeWayToSend = (ways) => {
    var newValue = value;
    newValue.way_to_send = ways;
    setValue(newValue);
    setWayToSend(ways);
  };

  const onChangeContentPicker = (content) => {
    var newValue = { ...value };
    newValue.content = content;
    setValue(newValue);
    // update nội dung content
    setContent(content);
  };

  const onChangeTab = (key) => {
    setTabsActiveKey(key);
    var way_to_send = [...wayToSend];
    // var data = { ...content };
    // way_to_send.forEach((item) => {
    //   if (item.id == "esms" && !data?.esms) {
    //     data["esms"] = {
    //       type: "sms",
    //       sms_content: {
    //         content: "",
    //         brand_name: "",
    //         list_key: [],
    //       },
    //       zalo_content: {
    //         oa_id: "",
    //         template_id: "",
    //         params: [],
    //       },
    //     };
    //     setContent(data);
    //   }
    // });
    setWayToSend(way_to_send);
  };

  const save = async () => {
    // save ở Tabs phương thức sẽ lỗi
    if (tabsActiveKey === 1) {
      setTabsActiveKey(2);
      return;
    }
    var error = await contentPicker.current?.validate();
    if (!error) {
      delete value._id;
      var contentKeys = Object.keys(value.content);
      contentKeys.forEach((key) => {
        var find = value.way_to_send.find((item) => {
          return item.id === key;
        });
        // console.log("find", find);
        if (!find) {
          if (key === "email" || key === "default_email") {
            value.content[key] = {
              html: null,
              json: null,
              subject: null,
              attachments: [],
            };
          } else if (key === "zalo") {
            value.content[key] = {
              oa_id: null,
              template_id: null,
              preview_url: null,
              price: null,
              params: [],
            };
          } else if (key === "esms") {
            value.content[key] = {
              type: "sms",
              sms_content: {
                content: "",
                brand_name: "",
                list_key: [],
              },
              zalo_content: {
                oa_id: "",
                template_id: "",
                params: [],
              },
            };
          } else {
            value.content[key] = null;
          }
        }
      });
      await api
        .post("customer_notifications/save", { data: value })
        .then((res) => {
          message.success("Lưu thành công", 3);
          props.reload();
          handleCancel();
        })
        .catch((err) => {
          message.error("Lưu thất bại", 3);
        });
    }
  };
  const setSendAllApp = (fag) => {
    var data = { ...value };
    data.send_all_app = fag;
    setValue(data);
  };

  return (
    <Modal
      title="Thông báo khách hàng"
      open={props.isModalOpen}
      onCancel={handleCancel}
      style={{ minWidth: "95vw", top: "5vh" }}
      // bodyStyle={{ minHeight: "40vh" }}
      footer={[
        <Button onClick={handleCancel}>Thoát</Button>,
        <Button type="primary" onClick={save}>
          {tabsActiveKey == 1 ? "Tiếp tục" : "Lưu"}
        </Button>,
      ]}
    >
      {loading ? (
        <Row style={{ height: "400px" }} justify="center" align="middle">
          <Spin size="large" />
        </Row>
      ) : (
        <Tabs
          onChange={onChangeTab}
          tabPosition="left"
          defaultActiveKey={1}
          activeKey={tabsActiveKey}
          items={[
            {
              label: "Phương thức",
              key: 1,
              children: (
                <WayToSendCampaign
                  lstApp={lstApp}
                  lstSelectedApp={lstSelectedApp}
                  setLstSelectedApp={setLstSelectedApp}
                  value={wayToSend}
                  onChange={onChangeWayToSend}
                  sendAllApp={value.send_all_app}
                  onChangeSendAllApp={setSendAllApp}
                ></WayToSendCampaign>
              ),
            },
            {
              label: "Nội dung",
              key: 2,
              children: <ContentPicker ref={contentPicker} onChange={onChangeContentPicker} wayToSend={wayToSend} value={content} />,
            },
          ]}
        />
      )}
    </Modal>
  );
}

export default EditNotification;
