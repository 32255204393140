import React, { Component } from "react";
import { TimePicker } from "antd";
import moment, { invalid } from "moment";

export default class TimePickerNew extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (value) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    var vl = "";
    if (this.props.value != null && this.props.value != "") {
      vl = moment(this.props.value);
    }

    return <TimePicker minuteStep={15} onSelect={this.onChange} value={vl} {...this.props} format="HH:mm" />;
  }
}
