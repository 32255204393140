import React, { Component } from "react";
import { Button, Input, message, Progress, Popconfirm, Modal, Avatar, Card, Tag, Select, Form } from "antd";
import AntTable from "components/common/AntTable";
import { formatShortDate, formatLongDate } from 'components/common/format/date';
import api from "api";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";

const { Option } = Select;
export default class VoucherList extends Component {
    constructor(props) {
        super(props);
        this.filters = [
         
        ];

        this.state = {
            data: {

            },
            filter: {
                query: {
                    $and: [

                    ]
                }
            },
            lstApp: [],
        }
        this.PopupCampaign = React.createRef();
        this.Tb = React.createRef();
        this.CampainResult = React.createRef();
    }

    componentDidMount = () => {
      
    }

    onSelectFilterChange = (key, isSelected) => {
        // var filter = { ...this.state.filter };
        // if (isSelected) {

        //     return;
        // }
        // switch (key) {

        // }
    }

    delete = (id) => {
        api.post("voucher/delete", { id: id }).then(res => {
            message.success("Xóa thành công", 3);
            this.Tb.current.reload();
        }).catch(err => {
            message.error("Xóa không thành công", 3)
        })
    }


    onFilterChange = (name, value) => {
        debugger;
        var filter = { ...this.state.filter };
        var index = filter.query.$and.findIndex(x => x[name]);
        if (value == "") {
            if (index > -1);
            filter.query.$and.splice(index, 1);
        }
        else {
            if (index > -1) {
                filter.query.$and[index][name].$regex = value;
            }
            else {
                filter.query.$and.push({ [name]: { $regex: value } })
            }
        }
        this.setState({ filter: filter }, () => this.Tb.current.reload());

    }

    onMultiSelectChange = (name, value) => {
        var filter = { ...this.state.filter };
        var index = filter.query.$and.findIndex(x => x[name]);
        if (value == []) {
            if (index > -1);
            filter.$and.splice(index, 1);
        }
        else {
            if (index > -1) {
                filter.query.$and[index][name] = value;
            }
            else {
                filter.query.$and.push({ [name]: { $all: value } })
            }
        }
        this.setState({ filter: filter }, () => this.Tb.current.reload());

    }

    getColumn = () => {
        var columns = [

            {
                title: 'Mã voucher',
                dataIndex: 'voucher_code',
                key: 'voucher_code',
                sorter: true
            },
            {
                title: 'Tên voucher',
                dataIndex: 'name',
                key: 'name',
                sorter: true
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'create_date',
                key: 'create_date',
                sorter: true,
                render: (text, record, index) => {
                    var date = new Date(record.create_date);
                    date = moment(date);
                    return (
                        <div>{date.format("DD-MM-YYYY")}</div>
                    )
                },
            },
            {
                title: 'Loại voucher',
                dataIndex: 'voucher_type',
                key: 'voucher_type',
                sorter: true,
            },
            {
                title: 'Thời gian',
                dataIndex: 'time',
                key: 'time',
                render: (text, record, index) => {
                    if(record.is_limittime){
                        var start = new Date(record.time.start);
                    start = moment(start);
                    var end = new Date(record.time.end);
                    end = moment(end);
                    }
                    
                    return (
                        <div>{record.is_limittime&&start.format("DD-MM-YYYY HH:mm")+" ==> "+end.format("DD-MM-YYYY HH:mm")}</div>
                    )
                },
            },
            {
                title: 'Discount',
                children: [
                    {
                        title: 'Loại',
                        dataIndex: '',
                        key: 'type',
                        render:(text, record, index)=>{
                            return(
                                <div>{record.voucher_type!=="pointprogarm_prefix"&&record.discount.type}</div>
                            )
                        }
                    },
                    {
                        title: 'Giá trị',
                        dataIndex: '',
                        key: 'value',
                        render:(text, record, index)=>{
                            return(
                                <div>{record.voucher_type!=="pointprogarm_prefix"&&record.discount.value}</div>
                            )
                        }
                    },
                    {
                        title: 'Số lần dùng tối đa',
                        dataIndex: '',
                        key: 'max_use',
                        render:(text, record, index)=>{
                            return(
                                <div>{record.voucher_type!=="pointprogarm_prefix"&& record.discount.max_use}</div>
                            )
                        }
                    },
                    {
                        title: 'Giá trị đơn hàng tối thiểu',
                        dataIndex: '',
                        key: 'min_value_order_apply',
                        render:(text, record, index)=>{
                            return(
                                <div>{record.voucher_type!=="pointprogarm_prefix"&&record.discount.min_value_order_apply}</div>
                            )
                        }
                    },
                    {
                        title: 'Giá trị tối đa của voucher',
                        dataIndex: 'companyAddress',
                        key: 'max_value_discount',
                        render:(text, record, index)=>{
                            return(
                                <div>{record.voucher_type!=="pointprogarm_prefix"&&(record.discount.max_value_discount==0? "không có":record.discount.max_value_discount)}</div>
                            )
                        }
                    },
                ],
            },

            {
                title: "",
                key: "action",
                render: (text, record, index) => {
                    var href="/voucher/voucherconfig/"+record.id;
                    return (
                        <>

                            <Popconfirm title="Bạn có chắc muốn xóa không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                                <Button type="link">Xóa</Button>
                            </Popconfirm>
                            <a style={{ color: '#0287FF', marginRight: "10px" }} href={href} >  Sửa</a>
                        </>

                    )

                },
            }
        ]
        return columns
    }



    render() {


        return (
            <>

                <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
                    <div >
                        <SearchBox name="campaign" data={this.filters} onSelectFilterChange={this.onSelectFilterChange} placeholder="Thông tin"
                            dataButton={[
                                <Button type="primary" style={{ marginRight: 15 }}><a href="/voucher/voucherconfig/0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Thêm mới</a></Button>
                            ]}
                            onSearch={() => this.Tb.current.reload()} onChange={(value) => { this.onFilterChange("name", value) }} />
                    </div>
                </div>


                <AntTable
                    rowSelection={{
                        type: 'checkbox',
                        ...this.rowSelection

                    }}
                    columns={this.getColumn()}
                    customSearch={this.state.filter}
                    tableName={"tbCustomer"}
                    ajax="voucher/getlist"
                    ref={this.Tb}
                    rowKey={record => record.id}
                    

                ></AntTable>

            </>
        );
    }
}
