import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Statistic,
  Button,
  Card,
  Space,
  Divider,
  Form,
  DatePicker,
  Input,
  Select,
  Tooltip,
  Typography,
  Switch,
  Popover,
  Dropdown,
  message,
  Modal,
  Menu,
  Image,
  Popconfirm,
  Avatar,
  Table,
} from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import api from "api";
import numeral from "numeral";
import { MoreOutlined, InfoCircleOutlined, DragOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import AddCategoryModal from "./AddCategoryModal";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;
const { confirm } = Modal;
const { Column } = Table;

function Product() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const Tb = React.createRef();
  const [dataPost, setDataPost] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const items = [
    {
      key: "edit",
      label: "Chỉnh sửa",
      child: [],
    },
    {
      key: "delete",
      label: "Xoá",
      child: [],
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const columns = [
    {
      title: "Age",
      dataIndex: "image",
      width: "40px",
      render: (text, record) => {
        if (text) {
          return <Avatar style={{ margin: "0 8px" }} src={text}></Avatar>;
        } else {
          return (
            <Avatar
              style={{
                backgroundColor: "green",
                verticalAlign: "middle",
                margin: "0 8px",
              }}
            >
              {record.name.slice(0, 1).toUpperCase()}
            </Avatar>
          );
        }
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Id",
      dataIndex: "id",
      render: (text, record) => {
        if (text !== "all") {
          return (
            <Dropdown
              trigger={"click"}
              onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
              }}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Chỉnh sửa",
                  },
                  {
                    key: "2",
                    label: <span style={{ color: "red" }}>Xoá</span>,
                  },
                ],
                onClick: (e) => {
                  if (e.key === "1") {
                    setItemEdit(record);
                    setIsModalOpen(true);
                  } else {
                    confirm({
                      title: "Bạn có muốn xoá danh mục này?",
                      icon: <ExclamationCircleFilled />,
                      // content: "Some descriptions",
                      okText: "Đồng ý",
                      okType: "danger",
                      cancelText: "Huỷ",
                      onOk() {
                        api
                          .post("miniapp_category/delete", { category_id: text })
                          .then((res) => {
                            getCategoryList();
                            message.open({
                              type: "success",
                              content: "Xoá thành công",
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                            message.open({
                              type: "error",
                              content: "Xoá thất bại",
                            });
                          });
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }
                },
              }}
              placement="bottomCenter"
            >
              <MoreOutlined />
            </Dropdown>
          );
        }
      },
    },

    {
      key: "sort",
    },
  ];

  const onClickCategory = (record) => {
    var newData = { ...dataPost };
    if (record.id !== "all") {
      newData.category = [record.id];
    } else {
      delete newData.category;
    }
    setDataPost(newData);
  };

  const RowTable = ({ children, ...props }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort" && props["data-row-key"] !== "0") {
            return React.cloneElement(child, {
              children: (
                <DragOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      var newList = [...categoryList];
      const activeIndex = categoryList.findIndex((i) => i.key === active.id);
      const overIndex = categoryList.findIndex((i) => i.key === over?.id);
      newList = arrayMove(categoryList, activeIndex, overIndex);
      setCategoryList(newList);
      onChangeCategoryPosition(newList);
    }
  };

  const onChangeCategoryPosition = (list) => {
    list = list.filter((item) => {
      return item.id !== "all";
    });
    const ids = list.map((item) => {
      return item.id;
    });
    const order_num = list.map((item, index) => {
      return index;
    });
    api
      .post("miniapp_category/changeposition", { ids: ids, order_num: order_num })
      .then((res) => {
        getCategoryList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumn = () => {
    var columns = [
      {
        title: "",
        dataIndex: "is_active",
        key: "is_active",
        align: "center",
        // width: ,
        render: (text, record, index) => {
          return (
            <Switch
              defaultChecked={text}
              onChange={(value) => {
                onChangeSwitch(record, value);
              }}
            />
          );
        },
      },
      {
        title: "Sản phẩm",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record) => {
          return (
            <Row gutter={[8, 8]}>
              <Col>
                <div style={{ backgroundImage: `url(${record.images[0]})`, width: 40, height: 40, backgroundSize: "cover" }}></div>
              </Col>
              <Col>
                <Text>{text}</Text>
              </Col>
            </Row>
          );
        },
      },
      {
        title: "Giá bán",
        dataIndex: "sale_price_range",
        key: "sale_price_range",
        sorter: true,
        render: (text, record) => {
          return `${numeral(record.sale_price_range.sale_price_min).format("0,0")} - ${numeral(record.sale_price_range.sale_price_max).format("0,0")}`;
        },
      },
      {
        title: "Biến thể",
        dataIndex: "skus",
        key: "skus",
        sorter: true,
        align: "center",
        render: (text, record) => {
          // console.log(text);
          return record.skus.length;
        },
      },
      {
        title: "Cập nhật",
        dataIndex: "updated_date",
        key: "updated_date",
        sorter: true,
        // ellipsis: true,
        // ellipsis: {
        //   showTitle: false,
        // },
        render: (text, record) => {
          if (text) {
            return (
              <>
                <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY hh:mm")}</p>
                <p style={{ marginBottom: "auto", marginTop: "auto" }}>{record.updated_by}</p>
              </>
            );
          }
        },
      },
      {
        dataIndex: "is_delete",
        key: "is_delete",
        align: "center",
        render: (text, record) => {
          return (
            // <Popover
            //   placement="bottomRight"
            //   content={
            //     <Col>
            //       <Text
            //         onClick={() => {
            //           navigate(`edit/${record.id}`);
            //         }}
            //       >
            //         Chỉnh sửa
            //       </Text>
            //       <br />
            //       <Text
            //         onClick={() => {
            //           onDelete(record, true);
            //         }}
            //       >
            //         Xoá
            //       </Text>
            //     </Col>
            //   }
            //   trigger="click"
            // >
            //   <MoreOutlined />
            // </Popover>
            <Dropdown
              trigger={"click"}
              menu={{
                items: items,
                onClick: (e) => {
                  if (e.key === "edit") {
                    navigate(`edit/${record.id}`);
                  } else {
                    onDelete(record);
                  }
                },
              }}
              placement="bottomRight"
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];
    return columns;
  };

  const onDelete = (record) => {
    confirm({
      title: "Bạn có muốn xoá sẩn phẩm này. ",
      icon: <InfoCircleOutlined />,
      content: `Sản phẩm: ${record.name}`,
      okText: "Có",
      onOk() {
        api
          .post("miniapp_product/deletebyid", { id: record.id })
          .then((res) => {
            message.success(`Cập nhật thành công sản phẩm ${record.name}`, 3);
            Tb.current.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      cancelText: "Không",
      onCancel() {},
    });
  };

  const onChangeSwitch = (record, is_active) => {
    api
      .post("miniapp_product/updateactivebyid", { ...record, is_active: is_active })
      .then((res) => {
        message.success(`Cập nhật thành công sản phẩm ${record.name}`, 3);
        Tb.current.reload();
      })
      .catch((err) => {
        console.log(err);
        message.error(`Cập nhật thất bại sản phẩm ${record.name}`, 3);
        Tb.current.reload();
      });
  };
  const getCategoryList = () => {
    api
      .post("miniapp_category/list")
      .then((res) => {
        if (res.length > 0) {
          var newCategoryList = res.map((item, index) => {
            return { key: `${index + 1}`, id: item.id, name: item.name, image: item.image };
          });

          newCategoryList.unshift({
            key: "0",
            id: "all",
            name: "Tất cả",
          });
          setCategoryList(newCategoryList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const onSearch = () => {
    setDataPost(form.getFieldsValue());
  };

  useEffect(() => {
    if (isReload) {
      getCategoryList();
      Tb.current.reload();
    }
  }, [isReload]);

  useEffect(() => {
    if (!isModalOpen) {
      setItemEdit();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (dataPost) {
      Tb.current.reload();
    }
  }, [dataPost]);

  return (
    <div>
      <Title level={5}>Danh sách sản phẩm</Title>
      <Row>
        <Col
          span={6}
          align={"middle"}
          style={{ background: "white", borderRight: "1px solid #f0f0f0", maxHeight: 768, overflowY: "auto", position: "relative", padding: 0 }}
        >
          <Button
            style={{ width: "calc(100% - 16px)", marginBottom: 8, marginTop: 8 }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Thêm danh mục
          </Button>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={categoryList.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      onClickCategory(record);
                    }, // click row
                    style: {
                      fontWeight: (dataPost.category && dataPost.category[0] === record.id) || (!dataPost.category && record.id === "all") ? 500 : 400,
                      background:
                        (dataPost.category && dataPost.category[0] === record.id) || (!dataPost.category && record.id === "all") ? "#E6F7FF" : "default",
                    },
                  };
                }}
                showHeader={false}
                components={{
                  body: {
                    row: RowTable,
                  },
                }}
                rowKey="key"
                dataSource={categoryList}
                columns={columns}
                size="small"
                pagination={false}
              ></Table>
            </SortableContext>
          </DndContext>
        </Col>
        <Col span={18} style={{ background: "white" }}>
          <div style={{ background: "white" }}>
            <Form layout={"inline"} form={form} style={{ padding: 8 }}>
              <Row style={{ width: "100%", margin: 0, height: "100%" }} gutter={[8, 8]}>
                <Col span={9}>
                  <Form.Item name="text" style={{ marginInlineEnd: 0 }}>
                    <Input
                      placeholder="Tìm tên hoặc SKU"
                      allowClear
                      onSearch={() => {
                        // onSearch()
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent the default Enter key behavior (e.g., line break)
                          onSearch();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={6}>
                  <Form.Item name="category" style={{ marginInlineEnd: 0 }}>
                    <Select
                      placeholder="Danh mục"
                      mode="multiple"
                      allowClear
                      options={categoryList}
                      // onChange={onSearch}
                    />
                  </Form.Item>
                </Col> */}

                <Col span={9}>
                  <Form.Item name="is_active" style={{ marginInlineEnd: 0 }}>
                    <Select
                      placeholder="Trạng thái"
                      mode="multiple"
                      allowClear
                      options={[
                        { value: true, label: "Hiển thị" },
                        { value: false, label: "Không hiển thị" },
                      ]}
                      // onChange={onSearch}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item style={{ marginInlineEnd: 0 }}>
                    {/* <Space style={{ width: "100%" }}> */}
                    <Button style={{ width: "calc(50% - 4px)", marginRight: 4 }} type="primary" onClick={onSearch}>
                      Tìm kiếm
                    </Button>
                    <Button
                      style={{ width: "calc(50% - 4px)", marginLeft: 4 }}
                      onClick={() => {
                        navigate("add");
                      }}
                    >
                      Thêm mới
                    </Button>
                    {/* </Space> */}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <AntTable
            columns={getColumn()}
            customSearch={dataPost}
            tableName={"tbProduct"}
            ajax="miniapp_product/list"
            ref={Tb}
            rowKey={(record) => record.id}
            size={"small"}
          ></AntTable>
        </Col>
      </Row>
      {isModalOpen && <AddCategoryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} item={itemEdit} setIsReload={setIsReload} />}
    </div>
  );
}

export default Product;
