import React, { useEffect, useState } from "react";
import { Col, Row, Alert, Space, Badge, Typography, message, Upload, Button, Table, Tooltip } from "antd";
import { InfoCircleOutlined, InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import ConfirmDataModal from "./confirmDataModal";
import * as XLSX from "xlsx";
import moment from "moment";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const { Column, ColumnGroup } = Table;
function ListOption({ onChange, setCurrentStep, oaId, dataPost, back }) {
  const [columns, setColumns] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [fileFormat, setFileFormat] = useState({});
  const [totalRecord, setTotalRecord] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState({
    status: false,
    columns: [
      { title: "Số dòng", dataIndex: "col_num", key: "col_num" },
      { title: "Tên lỗi", dataIndex: "err_name", key: "err_name" },
    ],
    dataSource: [],
  });

  useEffect(() => {
    if (dataPost.list_customer?.length > 0) {
      setDataSource(dataPost.list_customer);
    }
  }, []);

  const onDragChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      var f = info.file.originFileObj;
      if (!f) return;
      var reader = new FileReader();
      reader.onload = function (evt) {
        var data = evt.target.result;

        var workbook = XLSX.read(data, { type: "array" });

        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        var headerData = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        var sheetData = XLSX.utils.sheet_to_json(first_worksheet, { header: 0 });
        if (!sheetData) sheetData = [];
        var lstheader = [];
        for (var i = 0; i <= headerData[0].length; i++) {
          if (headerData[0][i] == null) {
            break;
          }
          lstheader.push(headerData[0][i]);
        }
        //   console.log(lstheader);
        var newError = { ...error };
        columns.forEach((col) => {
          var findItem = lstheader.find((item) => {
            return item === col.title;
          });
          if (!findItem) {
            newError.status = true;
            newError.dataSource.push({ col_num: "Dòng 0", err_name: `Không tìm thấy cột ${col.title}` });
          }
        });

        var dataExcel = [];
        for (let index = 0; index < sheetData.length; index++) {
          const element = sheetData[index];
          columns
            .map((item) => {
              return item.title;
            })
            .forEach((key) => {
              // console.log(key, element[key]);
              if (!element[key]) {
                newError.status = true;
                newError.dataSource.push({
                  col_num: `Dòng ${index + 1}`,
                  err_name: `Dữ liệu cột ${key} không được trống`,
                });
                return;
              }
              var findCol = columns.find((item) => {
                return item.title === key;
              });
              if (findCol) {
                if (element[key].length > findCol.maxLength) {
                  newError.status = true;
                  newError.dataSource.push({
                    col_num: `Dòng ${index + 1}`,
                    err_name: `Độ dài ký tự cột ${findCol.title} tối đa là ${findCol.maxLength}`,
                  });
                  return;
                }

                if (findCol.type !== "DATE" && findCol.type !== "PARAM_URL_ENCODE") {
                  console.log((typeof element[key]).toUpperCase());
                  if ((typeof element[key]).toUpperCase() !== findCol.type) {
                    newError.status = true;
                    newError.dataSource.push({
                      col_num: `Dòng ${index + 1}`,
                      err_name: `Dữ liệu cột ${findCol.title} phải là ${findCol.type === "NUMBER" ? "số" : "chữ"}`,
                    });
                    return;
                  }
                } else if (findCol.type === "DATE") {
                  if (typeof element[key] !== "string" || moment(element[key], "DD/MM/YYYY")._isValid === false) {
                    newError.status = true;
                    newError.dataSource.push({
                      col_num: `Dòng ${index + 1}`,
                      err_name: `Dữ liệu cột ${findCol.title} phải là dạng  DD/MM/YYYY hoặc HH:mm DD/MM/YYYY`,
                    });
                    return;
                  }
                }
              }
            });
          if (dataExcel.length > 0) {
            var findPhone = dataExcel.find((item) => {
              return item.phone === element.phone;
            });
            if (findPhone) {
              newError.status = true;
              newError.dataSource.push({ col_num: `Dòng ${index + 1}`, err_name: "Dữ liệu trùng lặp" });
            } else {
              dataExcel.push(element);
            }
            // return;
          } else {
            dataExcel.push(element);
            // return;
          }
          // dataExcel.push(element);
          // console.log(newError);
        }
        setError(newError);
        setFileFormat({ lstHeader: lstheader, dataExcel: dataExcel, imported: true, fileName: info.file.name, total: sheetData.length });
        setIsModalOpen(true);
      };
      reader.readAsArrayBuffer(f);
    }
    // message.success(`${info.file.name} file uploaded successfully.`);
  };

  const beforeUpload = (e) => {
    var lst = e.name.split(".");
    var tail = lst[lst.length - 1];
    var lstAllow = "xlsx";
    tail = tail.toLocaleLowerCase();
    if (lstAllow !== tail) {
      message.error("Vui lòng upload file đuôi xlsx");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isModalOpen) {
      setError({
        status: false,
        columns: [
          { title: "Số dòng", dataIndex: "col_num", key: "col_num" },
          { title: "Tên lỗi", dataIndex: "err_name", key: "err_name" },
        ],
        dataSource: [],
      });
    } else {
      if (error.status === true) {
        setDataSource([]);
      }
    }
  }, [isModalOpen]);

  //   const dataSource = [];

  useEffect(() => {
    var newCol = dataPost.content.zalo.params.map((item) => {
      return { title: item.name, dataIndex: item.name, key: item.name, type: item.type, maxLength: item.maxLength };
    });
    newCol.unshift({ title: "phone", dataIndex: "phone", key: "phone", type: "STRING", maxLength: 15 });
    console.log(newCol);
    setColumns(newCol);
  }, []);

  //   const columns = dataPost.content.zalo.params.map((item) => {
  //     return { title: item.name, dataIndex: item.name, key: item.key };
  //   });

  const submit = (data, total) => {
    var newDataSource = data;
    setDataSource(newDataSource);
    setTotalRecord(total);
  };

  const downloadExcel = () => {
    var data = [];
    columns.forEach((item) => {
      var obj = {};
      obj[`${item.title}`] = null;
      data.push(obj);
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  return (
    <Space direction="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                <Alert
                  message="Đảm bảo tệp danh sách người nhận ZNS đúng định dạng quy định"
                  description={
                    <Space direction="vertical">
                      {/* <Badge color="black" text="Định dạng tệp tải lên: XLSX" /> */}
                      <Badge color="black" text="Danh sách tải lên tối đa 20.000 dòng" />
                      {/* <Badge
                  color="black"
                  text={
                    <Text>
                      Hệ thống chỉ lấy các sản phẩm đã <Text strong>"Publish"</Text>
                    </Text>
                  }
                /> */}
                      <Badge color="black" text="Tên cột trong tập tin phải trùng với tên tham số" />
                      <Badge color="black" text="Danh sách không chứa dòng dữ liệu trùng lặp" />
                      <Badge color="black" text="Danh sách tải lên sẽ không được ghi nhận thành khách hàng" />
                    </Space>
                  }
                  type="info"
                  icon={<InfoCircleOutlined />}
                  showIcon
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Dragger
                  showUploadList={false}
                  maxCount={1}
                  multiple={false}
                  name={"file"}
                  action={"#"}
                  accept=".xlsx"
                  beforeUpload={(e) => {
                    beforeUpload(e);
                  }}
                  onChange={(info) => {
                    onDragChange(info);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Tải lên danh sách khách hàng</p>
                  <p className="ant-upload-hint">Sử dụng định dạng file excel XLSX</p>
                </Dragger>
              </Col>
            </Row>
          </Space>
        </Col>
        <Col span={12}>
          <iframe
            src={dataPost.content.zalo.preview_url}
            scrolling="no"
            frameBorder="0"
            style={{ width: "100%", height: 412, overflow: "hidden", margin: "auto" }}
          ></iframe>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Title level={4}>{`Danh sách gửi (${totalRecord})`}</Title>
          <Text>Dữ liệu trong tập danh sách tải lên cần phải đúng thứ tự cột và quy định về tham số như bên dưới:</Text>
        </Col>
        <Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
          <Button icon={<DownloadOutlined />} onClick={downloadExcel}>
            Tải tập tin mẫu
          </Button>
        </Col>
      </Row>
      <Table dataSource={dataSource}>
        {columns.map((item) => {
          return (
            <ColumnGroup
              title={
                <Tooltip title={`Tối đa ${item.maxLength} kí tự`}>
                  <span>{`${item.type}(${item.maxLength})`}</span>
                </Tooltip>
              }
            >
              <Column title={item.title} dataIndex={item.dataIndex} key={item.key} />
            </ColumnGroup>
          );
        })}
      </Table>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={back}>Thoát</Button>
        <Space>
          <Button
            onClick={() => {
              onChange({ list_customer: [] });
              setCurrentStep(0);
            }}
          >
            Quay lại
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // var newListCustomer = dataSource
              if (dataSource.length > 0) {
                onChange({ list_customer: dataSource });
                setCurrentStep(2);
              } else {
                message.error("Danh sách gửi tin phải có ít nhất 1 dòng dữ liệu");
              }
            }}
          >
            Tiếp tục
          </Button>
        </Space>
      </Row>
      <ConfirmDataModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} fileData={fileFormat} submit={submit} error={error} />
    </Space>
  );
}

export default ListOption;
