import React, { Component } from "react";
import { Card } from "antd";
import api from "api";
import { Button, Tooltip, message, Input } from "antd";
import AntTable from "components/common/AntTable";
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import SegmentsModal from "./addnewedit/index";
const { Search } = Input;

export default class SegmentsCampingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      visible: false,
      filter: {
        query: { status: true },
      },
    };

    this.TableSegments = React.createRef();
    this.SegmentPopup = React.createRef();
    this.SegmentsAddNewEdit = React.createRef();
  }

  ///=== React Lifecyle ======
  ///===========BEGIN=================
  componentDidMount() {}
  ///===========END=================

  ///==== Segment item Function =======
  deleteSegment = (id) => {
    api
      .post("segments/delete", { id: id })
      .then((res) => {
        message.success("Xóa thành công");
        this.TableSegments.current.reload();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  getSegment = (id) => {
    api
      .post("segments/getsegmentbyid", { id: id })
      .then((res) => {
        this.props.setFilter(res);
        this.setState({ visible: false });
      })
      .catch((err) => {});
  };

  setSegmentSelected = (segment) => {
    this.props.setSelectValue(segment);
    this.setState({ visible: false });
  };
  ///===========BEGIN=================
  segmentTableColumn = () => {
    var columns = [
      {
        title: "Chiến dịch",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Lượng khách hàng",
        dataIndex: "number_customer",
        key: "number_customer",
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_date",
        key: "create_date",
        sorter: true,

        render: (text, record, index) => {
          var time = moment(new Date(record.create_date)).format("DD/MM/YYYY");
          return <p>{time}</p>;
        },
      },
      {
        title: "Ngày cập nhật",
        dataIndex: "date_update",
        key: "date_update",
        sorter: true,
        render: (text, record, index) => {
          var time = moment(new Date(record.date_update)).format("DD/MM/YYYY");
          return <p>{time}</p>;
        },
      },
      {
        title: "",
        dataIndex: "date_update",
        key: "date_update",
        render: (text, record, index) => {
          return (
            <>
              <Tooltip placement="topLeft" title="Sửa">
                <Button type="link" onClick={() => this.SegmentsAddNewEdit.current.open(record.id)}>
                  Sửa
                </Button>
              </Tooltip>
              {
                this.props?.setSelectValue?
                <Tooltip placement="topLeft" title="Áp dụng">
                  <Button type="link" onClick={() => this.setSegmentSelected(record)}>
                    Áp dụng
                  </Button>
                </Tooltip>
                :<></>
              }
              
            </>
          );
        },
      },
    ];

    return columns;
  };

  ///=== Popup Function ======
  ///===========BEGIN=================
  open = (id) => {
    this.setState({
      visible: true,
    });
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };

  confirm = (id) => {
    this.getSegment(id);
  };

  onSearch = (value) => {
    this.setState({ filter: { query: { name: value, status: true } } }, () => this.TableSegments.current.reload());
  };

  ///===========END=================

  render() {
    var dataRender = (
      <>
        <Search placeholder="Nhập thông tin chiến dịch" onSearch={(v) => this.onSearch(v)} enterButton style={{ marginBottom: 10 }} />
        <Button type="primary" onClick={() => this.SegmentsAddNewEdit.current.open(-1)} style={{ marginBottom: 30, float: "right" }}>
          <i className="fas fa-plus" style={{ paddingRight: 10 }}></i> Thêm mới{" "}
        </Button>
        <AntTable
          bordered
          rowSelection={{
            type: "checkbox",
            ...this.rowSelection,
          }}
          columns={this.segmentTableColumn()}
          customSearch={this.state.filter}
          tableName={"tbSegments"}
          ajax="segments/segmentlist"
          ref={this.TableSegments}
          rowKey={(record) => record.id}
        ></AntTable>
        <SegmentsModal ref={this.SegmentsAddNewEdit} reload={() => this.TableSegments.current.reload()}></SegmentsModal>
      </>
    );
    if (this.props.displayMode == "card") {
      return <Card>{dataRender}</Card>;
    }
    return (
      <Modal title="Nhóm khách hàng" open={this.state.visible} onOk={() => this.confirm()} onCancel={() => this.close()} width="80%" loading={this.state.loading} footer={[]}>
        {dataRender}
      </Modal>
    );
  }
}
