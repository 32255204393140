import { Card, Layout, Col, Row, Input, Checkbox, Form, InputNumber, Radio, DatePicker, Select, Slider, Switch, Upload, Button, Divider, Space, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ColorPicker from "components/picker/color_picker";
import HTMLEditorPicker from "../../picker/html_editor_picker";
import ImagePicker from "components/picker/image_picker";
import ColorPalettePicker from "components/picker/color_palette_picker";

import VoucherPicker from "components/marketing/exchange_point_program/config/picker/voucher_picker";
import React, { Component } from "react";
import { init } from "./game";
import moment from "moment";
import api from "api";
import LuckyWheel from "./luckywheel";
import MyInputNumber from "components/picker/inputnumber_picker";
import SegmentPicker from "components/picker/segment_picker";
import { useParams } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

class ProFile extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      editingKey: -1,
      data: null,
      exchange_turn_type: [],
      add_turn: [],
      listItemErr: [],
    };
    this.game = React.createRef();
  }
  componentDidMount = () => {
    api.post("/loyaltyconfig/getTier").then((res) => {
      this.setState({ tiers: res.tiers });
    });
    api.post("/gameprogram/getconfigbytype", { type: this.props.ProFileType }).then((res) => {
      this.setState({ exchange_turn_type: res.exchange_turn_type });
    });
    if (!this.props.ProFileId || this.props.ProFileId === "0") {
      var options = {
        // slices configuration
        canSpin: true,
        slices: [
          {
            backgroundColor: "#ad070e",
            display: {
              type: "text",
              text: { value: "Thêm lượt", color: "#ffffff" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/discount.png" },
            },
            reward: {
              text: "Thêm lượt",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
          {
            backgroundColor: "#ffe6b5",
            display: {
              type: "text",
              text: { value: "+500 điểm", color: "#f92929" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/discount_2.png" },
            },
            reward: {
              text: "+500 điểm",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
          {
            backgroundColor: "#ad070e",
            display: {
              type: "text",
              text: { value: "Phúc Long" + "\n" + "35k", color: "#ffffff" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/discount.png" },
            },
            reward: {
              text: "Phúc Long 35k",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
          {
            backgroundColor: "#ffe6b5",
            display: {
              type: "text",
              text: { value: "Chúc may " + "\n" + "mắn lần sau", color: "#f92929" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/discount.png" },
            },
            reward: {
              text: "Chúc may mắn" + "\n" + " lần sau",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
          {
            backgroundColor: "#ad070e",
            display: {
              type: "text",
              text: { value: "Mất lượt", color: "#ffffff" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/discount_3.png" },
            },
            reward: {
              text: "Mất lượt",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
          {
            backgroundColor: "#ffe6b5",

            display: {
              type: "text",
              text: { value: "Loa JBL", color: "#f92929" },
              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/discount.png" },
            },
            reward: {
              text: "Loa JBL",
              type: "point",
              point: 1,
            },
            ratio: 10,
          },
        ],

        // wheel rotation duration range, in milliseconds
        rotationTimeRange: {
          min: 7000,
          max: 10000,
        },

        // wheel rounds before it stops
        wheelRounds: {
          min: 5,
          max: 20,
        },

        // wheel radius, in pixels
        wheelRadius: 240,
        // color of stroke lines
        strokeColor: 0xffffff,
        // width of stroke lines
        strokeWidth: 2,

        backgroundColor: "#880044",
        background: {
          type: "img",
          color: "#880044",
          image_url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/bg_default.png",
        },
        onSpinFinish: (reward) => {
          console.log("reward", reward);
        },
        add_turn: [],
        conditions: {
          participants: {
            type: "all",
          },
        },
        is_active: true,
        name: "Trò chơi của tôi",
        customer_turn: 1,
        display_style: "image",
        image: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/Default_wheelfortune.png",
        logo: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/background_default.png",
        text: "Trò chơi",
        description: "<p>Mô tả</p>",
      };

      this.setState({ data: options }, (e) => {
        this.form.current.setFieldsValue(options);
        this.previewGame(options);
      });
    } else {
      api.post("gameprogram/getconfigbyidandtype", { id: this.props.ProFileId, type: this.props.ProFileType }).then((res) => {
        if (res.conditions?.time_limit?.is_limited_time) {
          this.setState({ timeLimit: true });
          res.conditions.time_limit.time = [moment(res.conditions.time_limit.start), moment(res.conditions.time_limit.end)];
        }
        if (res.add_turn) {
          res.add_turn.map((item, index) => {
            this.onTurnTypeChange(item.type, index);
          });
        }
        var options = {
          // slices configuration
          ...res,
          canSpin: true,
          rotationTimeRange: {
            min: 7000,
            max: 10000,
          },

          // wheel rounds before it stops
          wheelRounds: {
            min: 5,
            max: 20,
          },

          // wheel radius, in pixels
          wheelRadius: 240,
          // color of stroke lines
          strokeColor: 0xffffff,
          // width of stroke lines
          strokeWidth: 2,

          onSpinFinish: (reward) => {
            console.log("reward", reward);
          },
        };
        var add_turn = [...options.add_turn];
        add_turn.map((x) => (x.id = x.type));
        this.setState({ data: options, add_turn: options.add_turn }, (e) => {
          this.form.current.setFieldsValue(options);
          this.previewGame(options);
        });
      });
    }
  };

  fomChanged = (changedValues, allValues) => {
    allValues.canSpin = true;
    if (this.fomChangedTimeOut) {
      clearTimeout(this.fomChangedTimeOut);
    }
    this.fomChangedTimeOut = setTimeout(() => {
      this.setState({ data: allValues });
    }, 500);

    this.previewGame(allValues);
  };

  previewGame = (options) => {
    this.game.current.previewGame(options);
  };

  cloneItem = (index) => {
    var item = { ...this.state.data.slices[index] };
    var options = { ...this.state.data };
    options.slices.push(item);
    this.previewGame(options);
    this.setState({ data: options }, (e) => {
      this.form.current.setFieldsValue(options);
    });
  };

  onFinish = (values) => {
    if (values.slices.length < 2) {
      message.error("Phải có ít nhất 2 phần thưởng");
      return;
    }
    values.type = "lucky_spin";
    if (values.conditions?.time_limit?.is_limited_time) {
      values.conditions.time_limit = {
        is_limited_time: values.conditions?.time_limit?.is_limited_time,
        start: values.conditions.time_limit.time[0],
        end: values.conditions.time_limit.time[1],
      };
    } else values.conditions.time_limit.is_limited_time = false;
    api
      .post("gameprogram/savegame", values)
      .then((res) => {
        message.success("Lưu thành công");
        if (res.game_id != values.id) {
          window.location.href = "/marketing/game/" + values.type + "/" + res.game_id;
        }
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };
  onTurnTypeChange = (type, name) => {
    var type_data = this.state.exchange_turn_type.find((x) => x.id == type);
    var addTurn = [...this.state.add_turn];
    addTurn.splice(name, 0, type_data);
    this.setState({ add_turn: addTurn });
  };

  ColorPalettePickerChange = (data) => {
    console.log("ColorPalettePickerChange", data);
    var options = { ...this.state.data };
    var slices = options.slices;

    var plIndex = 0;
    for (let index = 0; index < slices.length; index++) {
      var element = slices[index];
      if (plIndex >= data.length) {
        plIndex = 1;
      }

      element.backgroundColor = data[plIndex];
      element.display.text.color = this.getContrastColor(element.backgroundColor);
      plIndex++;
    }

    this.setState({ data: options }, (e) => {
      this.form.current.setFieldsValue(options);
      this.previewGame(options);
    });
  };

  getContrastColor = (hex) => {
    var aRgbHex = hex.replace("#", "").match(/.{1,2}/g);
    var aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];

    var a = 1 - (0.299 * aRgb[0] + 0.587 * aRgb[1] + 0.114 * aRgb[2]) / 255;

    if (a < 0.5) return "#000000";
    else return "#ffffff";
  };

  validateSlice = (name) => {
    this.form.current
      .validateFields()
      .then((value) => {
        this.setState({ editingKey: -1 });
      })
      .catch((error) => {
        var errorIndex = error.errorFields.findIndex((er) => er.name[0] === "slices" && er.name[1] === name);
        if (errorIndex == -1) {
          this.setState({ editingKey: -1 });
        }
      });
  };

  validateAddTurn = (name) => {
    this.form.current
      .validateFields()
      .then((value) => {
        this.setState({ add_turn_key: -1 });
      })
      .catch((error) => {
        var errorIndex = error.errorFields.findIndex((er) => er.name[0] === "add_turn" && er.name[1] === name);
        if (errorIndex == -1) {
          this.setState({ add_turn_key: -1 });
        }
      });
  };

  render() {
    if (!this.state.data) {
      return <div>...</div>;
    }
    return (
      <Form onValuesChange={this.fomChanged} ref={this.form} layout={"vertical"} onFinish={this.onFinish} initialValues={{ add_turn: { enable: true } }}>
        <Row gutter={6}>
          <Col span={16}>
            <Card
              title="Cấu hình"
              extra={
                <Button type="primary" onClick={(e) => this.form.current.submit()}>
                  Lưu
                </Button>
              }
            >
              <Form.Item name={"id"} hidden>
                <Input></Input>
              </Form.Item>
              {/* <Divider title="Nền" >Phần thưởng</Divider> */}

              <Form.List span={24} name="slices" shouldUpdate={true}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <div>
                          <div>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }}>{this.state.data.slices[name]?.display?.text?.value}</div>
                              <div>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    if (this.state.data.slices.length > 1) {
                                      remove(name);
                                    } else {
                                      message.error("Không thể xóa phần tử này");
                                    }
                                  }}
                                >
                                  <i className="fas fa-minus-circle"></i>
                                </Button>
                                <Button type="link" onClick={(e) => this.cloneItem(key)}>
                                  <i className="fas fa-clone"></i>
                                </Button>

                                <Button type="link" onClick={(e) => this.setState({ editingKey: key })}>
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: this.state.editingKey == key ? "block" : "none" }}>
                            <Card>
                              <Form.Item
                                label="Tiêu đề"
                                {...restField}
                                name={[name, "display", "text", "value"]}
                                rules={[
                                  { required: true, message: "Vui lòng nhập tiêu đề" },
                                  { max: 50, message: "Tối đa 50 ký tự" },
                                ]}
                              >
                                <TextArea placeholder="Text" />
                              </Form.Item>
                              <Row gutter={30}>
                                <Col span={6}>
                                  <Form.Item label="Màu nền" {...restField} name={[name, "backgroundColor"]}>
                                    <ColorPicker></ColorPicker>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item label="Màu chữ" {...restField} name={[name, "display", "text", "color"]}>
                                    <ColorPicker></ColorPicker>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={30}>
                                <Col span={6}>
                                  <Form.Item label="Kiểu hiện thị" {...restField} name={[name, "display", "type"]}>
                                    <Radio.Group>
                                      <Radio value="text">Text</Radio>
                                      <Radio value="img">Hình</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <div style={{ display: this.state.data.slices[name]?.display?.type == "img" ? "block" : "none" }}>
                                    <Form.Item {...restField} name={[name, "display", "image", "url"]}>
                                      <ImagePicker hideLibary={true} size={{ width: 50, height: 50 }} crop={{ quality: 1, aspect: 1.0 / 1.0 }} tags={["promotion"]}></ImagePicker>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={30}>
                                <Col span={18}>
                                  <Form.Item
                                    label="Quà tặng"
                                    {...restField}
                                    validateTrigger="onBlur"
                                    name={[name, "reward", "type"]}
                                    rules={[{ required: true, message: "Bạn chưa chọn loại quà tặng!" }]}
                                  >
                                    <Radio.Group>
                                      <Radio value="voucher">Voucher</Radio>
                                      <Radio value="point">Điểm tích lũy </Radio>
                                      <Radio value="add_turn">Thêm lượt</Radio>
                                      <Radio value="loss_turn">Mất lượt </Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    label="Tỉ lệ(%)"
                                    {...restField}
                                    validateTrigger="onBlur"
                                    name={[name, "ratio"]}
                                    rules={[
                                      { required: true, message: "Bạn chưa nhập tỉ lệ!" },
                                      { pattern: /^\d+$/, message: "Sai định dạng tỉ lệ" },
                                    ]}
                                  >
                                    <InputNumber></InputNumber>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <div style={{ display: this.state.data.slices[name]?.reward?.type == "voucher" ? "block" : "none" }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "reward", "voucher"]}
                                  validateTrigger="onBlur"
                                  label="Voucher"
                                  rules={this.state.data.slices[name]?.reward?.type == "voucher" && [{ required: true, message: "Bạn chưa chọn voucher!" }]}
                                >
                                  <VoucherPicker type="all"></VoucherPicker>
                                </Form.Item>
                              </div>

                              <div style={{ display: this.state.data.slices[name]?.reward?.type == "phone_card" ? "block" : "none" }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "reward", "phone_card"]}
                                  validateTrigger="onBlur"
                                  label="Thẻ cào"
                                  rules={this.state.data.slices[name]?.reward?.type == "phone_card" && [{ required: true, message: "Bạn chưa chọn thẻ cào điện thoại!" }]}
                                >
                                  <VoucherPicker type="phone_card"></VoucherPicker>
                                </Form.Item>
                              </div>

                              <div style={{ display: this.state.data.slices[name]?.reward?.type == "point" ? "block" : "none" }}>
                                <Form.Item
                                  {...restField}
                                  validateTrigger="onBlur"
                                  name={[name, "reward", "point"]}
                                  label="Điểm"
                                  rules={
                                    this.state.data.slices[name]?.reward?.type == "point" && [
                                      { required: true, message: "Bạn chưa nhập số điểm!" },
                                      { pattern: /^\d+$/, message: "Sai định dạng tỉ lệ" },
                                    ]
                                  }
                                >
                                  <InputNumber></InputNumber>
                                </Form.Item>
                              </div>

                              <div style={{ display: this.state.data.slices[name]?.reward?.type == "add_turn" ? "block" : "none" }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "reward", "add_turn"]}
                                  validateTrigger="onBlur"
                                  label="Số lượt"
                                  rules={this.state.data.slices[name]?.reward?.type == "add_turn" && [{ required: true, message: "Bạn chưa chọn số lượt" }]}
                                >
                                  <InputNumber defaultValue={1}></InputNumber>
                                </Form.Item>
                              </div>

                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    this.validateSlice(name);
                                  }}
                                >
                                  Lưu
                                </Button>
                              </div>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          var data = this.form.current.getFieldValue(["slices"]);
                          add({
                            backgroundColor: data.length % 2 == 0 ? "#F91312" : "#FFEBB8",
                            display: {
                              type: "text",
                              text: { value: "Quà mới", color: "#ffffff" },
                              image: { url: "https://storage.googleapis.com/cubeloyalty_asia/system/game/lucky_wheel/discount.png" },
                            },
                            reward: {
                              text: "",
                              type: "point",
                              point: 1,
                            },
                            ratio: 10,
                          });
                          this.setState({ editingKey: data.length });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm quà
                      </Button>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ColorPalettePicker onChange={this.ColorPalettePickerChange} />
                      </div>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Divider title="Nền">Nền</Divider>
              <div>
                <Form.Item label="Kiểu hiển thị" name={["background", "type"]}>
                  <Radio.Group>
                    <Radio value="color">Màu</Radio>
                    <Radio value="img">Hình</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="Màu" name={["background", "color"]} hidden={this.state.data.background.type != "color"}>
                  <ColorPicker></ColorPicker>
                </Form.Item>

                <Form.Item label="Hình nền" name={["background", "image_url"]} hidden={this.state.data.background.type != "img"}>
                  <ImagePicker size={{ width: 40, height: 72 }} crop={{ quality: 1, aspect: 9.0 / 16.0 }} tags={["promotion"]}></ImagePicker>
                </Form.Item>

                <Divider>Cách thức thêm lượt</Divider>
                <Form.List name="add_turn">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }}>
                                <span>{this.state.data.add_turn[name]?.name}</span>
                              </div>
                              <Button
                                type="link"
                                onClick={() => {
                                  remove(name);
                                }}
                              >
                                <i className="fas fa-minus-circle"></i>
                              </Button>

                              <Button type="link" onClick={(e) => this.setState({ add_turn_key: key })}>
                                <i className="fas fa-edit"></i>
                              </Button>
                            </div>

                            <div style={{ display: this.state.add_turn_key == key ? "block" : "none", padding: 10 }}>
                              <Card>
                                <Form.Item label="Phương thức thêm lượt" name={[name, "type"]} rules={[{ required: true, message: "Bạn chưa chọn cách thêm lượt" }]}>
                                  <Select
                                    style={{ width: 350 }}
                                    onChange={(e) => {
                                      this.onTurnTypeChange(e, name);
                                      this.setState({ add_turn_key: key });
                                    }}
                                  >
                                    {this.state.exchange_turn_type.map((item) => {
                                      return <Option value={item.id}>{item.description}</Option>;
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Tên hiển thị"
                                  name={[name, "name"]}
                                  {...restField}
                                  validateTrigger="onBlur"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng nhập vào tên hiển thị",
                                    },
                                  ]}
                                >
                                  <Input style={{ width: "50%" }} />
                                </Form.Item>
                                <Form.Item
                                  label="Số điểm"
                                  name={[name, "points"]}
                                  {...restField}
                                  validateTrigger="onBlur"
                                  hidden={this.state.add_turn[name]?.id !== "point"}
                                  rules={
                                    this.state.add_turn[name]?.id === "point" && [
                                      {
                                        required: true,
                                        message: "Bạn chưa nhập số điểm",
                                      },
                                    ]
                                  }
                                >
                                  <MyInputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                  label="Zalo ID"
                                  name={[name, "zalo_id"]}
                                  {...restField}
                                  validateTrigger="onBlur"
                                  hidden={this.state.add_turn[name]?.id !== "subscribezalo"}
                                  rules={
                                    this.state.add_turn[name]?.id === "subscribezalo" && [
                                      {
                                        required: true,
                                        message: "Vui lòng nhập ZALO ID",
                                      },
                                    ]
                                  }
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Số lượt quay"
                                  name={[name, "turns"]}
                                  validateTrigger="onBlur"
                                  hidden={this.state.add_turn[name]?.id === "subscribezalo"}
                                  {...restField}
                                  rules={this.state.add_turn[name]?.id !== "subscribezalo" && [{ pattern: /^([0-9]*[1-9][0-9]*)$/, message: "Số lượt quay phải lớn hơn 0" }]}
                                >
                                  <MyInputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                  label="Số lượt đổi tối đa"
                                  name={[name, "max_change_times"]}
                                  hidden={this.state.add_turn[name]?.id === "subscribezalo"}
                                  {...restField}
                                  validateTrigger="onBlur"
                                  rules={this.state.add_turn[name]?.id !== "subscribezalo" && [{ pattern: /^([0-9]*[1-9][0-9]*)$/, message: "Số lượt đổi tối đa phải lớn hơn 0" }]}
                                >
                                  <MyInputNumber min={0} />
                                </Form.Item>

                                <Form.Item label="Mô tả cách thêm lượt" name={[name, "description"]}>
                                  <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item name={[name, "id"]}>
                                  <Input type="hidden" />
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <Button type="primary" onClick={(e) => this.validateAddTurn(name)}>
                                    Lưu
                                  </Button>
                                </div>
                              </Card>
                            </div>
                          </>
                        );
                      })}
                      <Button
                        type="dashed"
                        style={{ width: "100%" }}
                        onClick={() => {
                          var data = this.form.current.getFieldValue(["add_turn"]);
                          add({
                            type: "",
                            name: "Thêm lượt quay",
                          });
                          this.setState({ add_turn_key: data.length });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm mới
                      </Button>
                    </>
                  )}
                </Form.List>

                <Divider title="Nền">Trạng thái</Divider>

                <Form.Item name="is_active">
                  <Radio.Group>
                    <Space>
                      <Radio value={true} style={{ marginTop: 30 }}>
                        Hoạt động
                      </Radio>
                      <Radio value={false} style={{ marginTop: 30 }}>
                        Tạm dừng
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Divider>Hiển thị</Divider>

                <Form.Item
                  label="Tên"
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa nhập tên trò chơi!",
                    },
                  ]}
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  label="Lượt chơi mỗi khách hàng"
                  name={"customer_turn"}
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa nhập số lượt chơi!",
                    },
                  ]}
                >
                  <InputNumber></InputNumber>
                </Form.Item>
                <div>
                  <Form.Item name="display_style" rules={[{ required: true, message: "Bạn phải chọn kiểu mô tả!" }]}>
                    <Radio.Group>
                      <Radio value="image">Hình ảnh</Radio>
                      <Radio value="text">Văn bản</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.display_style !== currentValues.display_style}>
                    {({ getFieldValue }) => {
                      var data = getFieldValue("display_style");
                      return (
                        <>
                          <div hidden={data === "text" ? false : true}>
                            <Form.Item
                              name="text"
                              style={{ marginTop: -26 }}
                              rules={
                                data === "text" && [
                                  {
                                    required: true,
                                    message: "Bạn chưa nhập trường văn bản!",
                                  },
                                ]
                              }
                            >
                              <Input placeholder="Văn bản" style={{ width: "48%" }} />
                            </Form.Item>
                          </div>
                          <div hidden={data === "image" ? false : true}>
                            <Row gutter={16}>
                              <Col xs={{ span: 8 }} lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item
                                  name="logo"
                                  label="Logo"
                                  style={{ marginTop: -25 }}
                                  rules={
                                    data === "image" && [
                                      {
                                        required: true,
                                        message: "Bạn chưa chọn logo!",
                                      },
                                    ]
                                  }
                                >
                                  <ImagePicker crop={{ quality: 1, aspect: 3 / 4 }} size={{ width: 64, height: 64 }} tags={["promotion_icon"]}></ImagePicker>
                                </Form.Item>
                              </Col>
                              <Col xs={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item
                                  name="image"
                                  label="Hình ảnh"
                                  style={{ marginTop: -25 }}
                                  rules={
                                    data === "image" && [
                                      {
                                        required: true,
                                        message: "Bạn chưa chọn hình ảnh!",
                                      },
                                    ]
                                  }
                                >
                                  <ImagePicker size={{ width: 400, height: 200 }} crop={{ quality: 1, aspect: 1.9 / 1 }} tags={["promotion"]}></ImagePicker>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </>
                      );
                    }}
                  </Form.Item>

                  <Form.Item
                    name="description"
                    label="Mô tả"
                    style={{ height: "90%" }}
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa nhập mô tả!",
                      },
                    ]}
                  >
                    <HTMLEditorPicker style={{ height: "100%" }}></HTMLEditorPicker>
                  </Form.Item>
                </div>
              </div>
              <Divider>Điều kiện tham gia</Divider>

              <Form.Item
                label="Đối tượng"
                name={["conditions", "participants", "type"]}
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa chọn loại đối tượng!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"all"}>Tất cả</Radio>
                  <Radio value={"tier"}>Theo hạng</Radio>
                  <Radio value={"group"}>Theo nhóm</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.conditions !== currentValues.conditions}>
                {({ getFieldValue }) => {
                  var value = getFieldValue(["conditions", "participants", "type"]);
                  return (
                    <>
                      {value === "group" && (
                        <Form.Item
                          name={["conditions", "participants", "customer_segment_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa chọn nhóm!",
                            },
                          ]}
                          style={{ width: "50%" }}
                        >
                          <SegmentPicker setValueSegment={this.onSegmentChange}></SegmentPicker>
                        </Form.Item>
                      )}
                      {value === "tier" && (
                        <Form.Item
                          name={["conditions", "participants", "tier_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Bạn chưa chọn hạng!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "50%" }}
                            // onChange={() => {
                            //   this.handleTemplateDescription();
                            // }}
                          >
                            {this.state.tiers.map((item) => {
                              return <Option value={item.id}>{item.name}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      )}
                    </>
                  );
                }}
              </Form.Item>

              <Form.Item name={["conditions", "time_limit", "is_limited_time"]} valuePropName="checked">
                <Checkbox style={{ marginBottom: 10 }} checked={this.state.timeLimit}>
                  Giới hạn thời gian
                </Checkbox>
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.conditions !== currentValues.conditions}>
                {({ getFieldValue }) => {
                  var value = getFieldValue(["conditions", "time_limit", "is_limited_time"]);
                  return (
                    <>
                      {value && (
                        <Form.Item
                          name={["conditions", "time_limit", "time"]}
                          rules={
                            this.state.timeLimit && [
                              {
                                type: "array",
                                required: true,
                                message: "Bạn chưa chọn giới hạn thời gian!",
                              },
                            ]
                          }
                        >
                          <RangePicker format={"DD-MM-YYYY"} />
                        </Form.Item>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Preview" style={{ align: "center" }}>
              <LuckyWheel ref={this.game}></LuckyWheel>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}

const ProFileHoc = () => {
  const { id,type} = useParams();

  return <ProFile ProFileId={id} ProFileType={type}></ProFile>;
};

export default ProFileHoc;
