import React, { Component } from "react";
import api from "api";
import { Card, Row, Popconfirm, message, Tabs, Col } from "antd";
import PopupSetting from "../popupsetting";
import SyncPopup from "../sync_popup";

const { Meta } = Card;
const { TabPane } = Tabs;

export default class SapoAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      signIn: "",
      showBtn: false,
      loading: true,
    };
    this.PopupSetting = React.createRef();
    this.SyncPopup = React.createRef();
  }

  componentDidMount = () => {
    this.getDataSapoConfig();
  };

  // lấy data config
  getDataSapoConfig = () => {
    api.post("app/sapo/config/getconfig", { shop_id: 0 }).then((res) => {
      if (res != null) {
        this.setState({ data: res, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };

  //deleteconfig
  delete = (id) => {
    var dataPost = {
      id: id,
      shop_id: 0,
    };
    api
      .post("app/sapo/config/delete", dataPost)
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.setState({ loading: true });
        this.getDataSapoConfig();
      })
      .catch((err) => {
        message.error("Xóa thất bại", 3);
      });
  };

  //render item
  renderItem = (element) => {
    return (
      <Card
        className={"email-card-item"}
        bordered={true}
        cover={<img maxWidth={100} src="/image/sapo.jpg" />}
        actions={[
          <div
            onClick={() => {
              this.SyncPopup.current.open(element.store);
            }}
          >
            <i className="fas fa-sync-alt"></i>
          </div>,
          <Popconfirm
            title="Bạn có chắc muốn xóa không?"
            onConfirm={() => {
              this.delete(element.store);
            }}
            okText="Có"
            cancelText="Không"
          >
            ,
            <div style={{ marginTop: -22 }}>
              <i className="far fa-trash-alt"></i>
            </div>
          </Popconfirm>,
        ]}
      >
        <Meta description={element.store} />
      </Card>
    );
  };

  render() {
    // danh sách các tài khoản có thể kết nối
    var data = this.state.data.config || [];

    return (
      <>
        <Row gutter={16}>
          {data.map((item) => {
            return <Col span={6}>{this.renderItem(item)}</Col>;
          })}
          <Col span={6}>
            <Card
              className={"email-card-item"}
              bordered={true}
              cover={<img maxWidth={100} src="/image/sapo.jpg" />}
              actions={[
                <div onClick={() => this.PopupSetting.current.open("add", {})}>
                  <i className="fas fa-plus"></i> Thêm Mới
                </div>,
              ]}
            >
              <Meta description={"Thêm mới tài khoản Sapo.vn"} />
            </Card>
          </Col>
        </Row>

        <PopupSetting ref={this.PopupSetting} data={this.state.data} getDataEmailConfig={this.getDataSapoConfig}></PopupSetting>

        <SyncPopup ref={this.SyncPopup}></SyncPopup>
      </>
    );
  }
}
