import React, { Component } from "react";
import { Button, Form, Select, Modal, Empty } from "antd";
import api from "api";
import { renderInput } from "common/custom_field.js";

const { Option } = Select;

export default class AppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: false,
      mode: "view",
      visible: false,
      isLoading: false,
      form_key: "1-customer-add",
      lst_tag: [],
      system_config: [],
      user_accounts: [],
      app_index: -1,
      url: "",
      app_id: "",
    };

    this.popupAddEdit = React.createRef();
    this.popupCustomerDetails = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount() {}

  open = async (app_id, app_index, values, url) => {
    var dataPost = {
      query: { app_id: app_id },
      limit: 100,
      skip: 0,
    };
    if (this.state.system_config.length == 0) {
      var res = await api.post("app/listconfig", dataPost);
      if (res.response.length > 0) {
        this.setState({ system_config: res.response });
      }
    }
    this.setState({ app_index, visible: true, configUrl: url, app_id: app_id }, () => {
      if (values.parameters != null && values.parameters.length != 0) {
        var set_fields = {};
        values.parameters.forEach((obj) => {
          set_fields[obj.type] = obj.value;
        });
        this.form.current.setFieldsValue(set_fields);
      } else {
        //tự lấy option đầu tiên đối với dropdown
        var config = this.state.system_config;
        var set_fields = {};
        config.forEach((obj) => {
          if (obj.data_type == "dropdown") {
            set_fields[obj.value] = obj.options[0].value;
          }
        });
        this.form.current.setFieldsValue(set_fields);
      }
    });
  };

  onFinish = (values) => {
    var keys = Object.keys(values);
    var parameters = [];
    keys.forEach((element) => {
      var value = values[element];
      if (value == null) {
        var config = this.state.system_config;
        var found = config.find((x) => x.value == element);
        value = found.options[0].value;
      }
      var obj = {
        type: element,
        value: value,
      };
      parameters.push(obj);
    });
    if (this.props.onChangeParameters) this.props.onChangeParameters(parameters, this.state.app_index);
    this.close();

    if (this.props.ok) this.props.ok();
  };

  close = () => {
    this.setState({ visible: false, system_config: [] }, () => this.form.current.resetFields());
  };

  renderAttributes = () => {
    var config = this.state.system_config;

    return config.map((config) => {
      var rules = [];
      var data_type = config.data_type;
      if (config.required == true)
        rules = [
          {
            required: true,
            type: "string",
            message: `${config.label} là trường bắt buộc!`,
          },
        ];
      var result = (
        <Form.Item name={config.value} label={config.label} rules={rules}>
          {renderInput(config)}
        </Form.Item>
      );
      return result;
    });
  };

  render() {
    var url = "#";
    if (this.state.configUrl != null) {
      url = this.state.configUrl;
    }
    return (
      <Modal
        title={"Cấu hình"}
        open={this.state.visible}
        onCancel={this.close}
        onOk={() => this.form.current.submit()}
        width={"40%"}
        maskClosable={false}
        okText="Lưu"
        cancelText="Trở lại"
        footer={[
          <Button type="primary">
            <a href={`/apps/${this.state.app_id}`} target="_blank">
              Cấu hình
            </a>
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              this.close();
            }}
          >
            Thoát
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              this.form.current.submit();
            }}
          >
            Lưu
          </Button>,
        ]}
      >
        <Form layout="vertical" onFinish={this.onFinish} ref={this.form}>
          {this.state.system_config.length === 0 ? <Empty /> : this.renderAttributes()}
        </Form>
      </Modal>
    );
  }
}
