import React, { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { Card, Steps, message,Spin,Row } from "antd";
import api from "api";
import InfomationCampaign from "./infomationCampaign";
import WayToSendCampaign from "./wayToSendCampaign";
import ContentCampaign from "./contentCampaign";
import SuccessCampaign from "./successCampaign";
import moment from "moment";

/**
 * Màn hình thêm mới hoặc chỉnh sửa Chiến dịch
 * @author Nhật Anh
 */
function AddEditCampaign() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [campaign, setCampaign] = useState({
    id: null,
    name: null,
    description: null,
    schedule: {
      type: null,
      time: null,
      date: null,
      date_of_month: null,
      date_of_week: null,
    },
    // isallapp: true,
    customer_segment_id: null,
    send_all_app: false,
    way_to_send: [],
    content: {
      email: {
        html: null,
        json: null,
        subject: null,
        attachments: [],
      },
      default_email: {
        html: null,
        json: null,
        subject: null,
        attachments: [],
      },
      zalo: {
        oa_id: null,
        template_id: null,
        preview_url: null,
        price: null,
        params: [],
      },
      esms: {
        type: "sms",
        sms_content: {
          content: "",
          brand_name: "",
          list_key: [],
          sms_template: "",
        },
        zalo_content: {
          oa_id: "",
          template_id: "",
          params: [],
        },
      },
      smsfpt: null,
    },
    created_date: null,
    created_by: null,
  });
  const stepItems = [
    {
      title: "Chiến dịch",
      description: "Thông tin chiến dịch",
      key: 0,
    },
    {
      title: "Kênh gửi",
      description: "Kênh gửi chiến dịch",
      key: 1,
    },
    {
      title: "Nội dung",
      description: "Nội dung gửi",
      key: 2,
    },
    {
      title: "Hoàn tất",
      description: "Xem lại thông tin",
      key: 3,
    },
  ];
  const [lstAllApp, setLstAllApp] = useState();
  const [lstApp, setLstApp] = useState();
  const [lstSelectedApp, setLstSelectedApp] = useState([]);

  useEffect(() => {
    getLstApp();
    getAllApp();
    if (id !== "add") {
      getCampaignById();
    }
  }, [id]);
  
  useEffect(() => {
   if(lstApp&&lstAllApp)
   {
    if(id !== "add")
      checkApps();
    setLoading(false);
   }
  }, [lstApp,lstAllApp]);
  // kiểm tra cấu hình và cài đặt các app
  const checkApps= async()=>{
    var campaignCopy = {...campaign}
    var list=[]
    var way_to_send = []
    if (campaignCopy?.way_to_send && campaignCopy?.way_to_send?.length > 0) {
      for (const i of campaignCopy.way_to_send) {
        var checkexist = await handleCheckConfigApp(i.id, i.parameters[0].type, i.parameters[0].value,campaignCopy)
        if (checkexist) {
          var find = lstApp.find((item) => {
            return item.id === i.id;
          });
          if(find)
          {
            list.push(find);
            way_to_send.push(i);
          }
          else{
            showErorMessageOfConfigApp(i.id,true)
          }
        }
        else {
          showErorMessageOfConfigApp(i.id)
        }
      }
      campaignCopy.way_to_send = way_to_send;
      setCampaign(campaignCopy);
      setLstSelectedApp(list);
      // campaignCopy.way_to_send.forEach(async (i, index) => {
      //   //check id app được lưu có tồn tại hay không
      //   var checkexist = await handleCheckConfigApp(i.id, i.parameters[0].type, i.parameters[0].value,campaignCopy)
      //   if (checkexist) {
      //     var find = lstApp.find((item) => {
      //       return item.id === i.id;
      //     });
      //     if(find)
      //     {
      //       list.push(find);
      //       way_to_send.push(i);
      //     }
      //     else{
      //       showErorMessageOfConfigApp(i.id,true)
      //     }
      //   }
      //   else {
      //     showErorMessageOfConfigApp(i.id)
      //   }
      //   if (campaignCopy.way_to_send.length - 1 == index) {
      //     campaignCopy.way_to_send = way_to_send;
      //     setCampaign(campaignCopy);
      //     setLstSelectedApp(list);
      //   }
      // });
    }
  }
  /**
   * Qua bước tiếp theo
   */
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  /**
   * Quay lại bước trước
   */
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  /**
   * check cấu hình có tồn tại trong app hay không
   * @param {string} app là id app
   * @param {string} type là trường cần so sánh giá trị
   * @param {string} value là giá trị cần so sánh
   * @param {object} newCampaign là campaign
   * @author Tuấn
   */
  const handleCheckConfigApp = async (app, type, value,newCampaign) => {
    var result = false;
    var pathConfig = "getconfig";
    if (app == "fb") pathConfig = "getpages";
    if (app == "zalo") app = "zalov2";
    await api
      .post(`/app/${app.replace("_", "")}/config/${pathConfig}`)
      .then((res) => {
        var index = -1;
        var data = [];
        if (app == "zalov2") {
          if(res?.oas&&res?.oas?.length>0&&newCampaign?.content?.zalo?.oa_id)
          {
            var checkOaId = res.oas.findIndex((x) => (x["oa_id"] == newCampaign.content.zalo.oa_id));
            if(checkOaId>=0)
            {
              // index = res.oas.findIndex((x) => (x[type] == value));
              if(res.oas[checkOaId][type]===value)
              {
                result = true;
                return
              }
            }
            newCampaign.content.zalo={
              oa_id:null,
              template_id:null,
              preview_url:null,
              price:null,
              params:[]}
            setCampaign(newCampaign)
          }
        }
        if (app == "email" || app == "esms" || app == "smsfpt") {
          data = res?.configs || [];
        }
        if (app == "default_email") {
          data = res || [];
        }
        if (app == "fb") {
          data = res?.pages || [];
        }
        if (data?.length && data?.length > 0) {
          index = data.findIndex((x) => (app == "fb" ? x.page == value : x[type] == value));
        }
        if (index >= 0) result = true;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  };
    /**
  * check cấu hình có tồn tại trong app hay không
  * @param {string} app là id app
  * @param {boolean} isSetting là gỡ cài đặt app
  * @author Tuấn
  */
  const showErorMessageOfConfigApp=  (app,isSetting=false)=>{
    var result = lstAllApp.find((item) => {
      return item.id === app;
    });
    var nameapp=app;
    if(result)
      nameapp=result.name;
    if(isSetting)
      message.warning(`App ${nameapp} cài đặt đã bị gỡ! vui lòng cài đặt lại app ${nameapp}`)
    else
      message.warning(`App ${nameapp} cấu hình đã bị gỡ! vui lòng cấu hình lại app ${nameapp}`)
  }
  /**
   * Hàm lấy thông tin 1 chiến dịch từ id
   * @author Nhật Anh
   */
  const getCampaignById = async () => {
    await api
      .post("/campaign/getbyid", { id: id })
      .then((res) => {
        setCampaign(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (campaign) {
      handleLstSelectedAppModeEditCampaign(campaign.way_to_send);
    }
  }, [campaign]);

  /**
   * Hàm lấy danh sách app
   * @author huy
   */
  const getLstApp = async () => {
    await api
      .post("shop/getinstalledappbycategories", { categories: ["communication"] })
      .then((res) => {
        setLstApp(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllApp = async () => {
    await api
      .post("app/list")
      .then((res) => {
        setLstAllApp(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * Hàm xử lý danh sách app được chọn nếu như mode là edit campaign
   * @author huy
   */
  const handleLstSelectedAppModeEditCampaign = (wayToSend) => {
    if (id !== "add" && wayToSend?.length > 0 && lstApp?.length > 0) {
      var newLst = wayToSend.map((item) => {
        var app = lstApp.find((appItem) => {
          return appItem.id === item.id;
        });
        if (app) {
          return { ...item, logo: app.logo, name: app.name, description: app.description };
        }
      });

      setLstSelectedApp([...newLst]);
    }
  };

  const setContent = (content) => {
    var campaignNew = { ...campaign };
    campaignNew.content = content;
    setCampaign(campaignNew);
  };

  const setWayToSend = (ways) => {
    var campaignNew = { ...campaign };
    campaignNew.way_to_send = ways;
    setCampaign(campaignNew);
  };

  const setSendAllApp = (value) => {
    var campaignNew = { ...campaign };
    campaignNew.send_all_app = value;
    setCampaign(campaignNew);
  }

  const infoFields = ["name", "description", "schedule", "customer_segment_id"];

  const setInfo = (data) => {
    var campaignNew = { ...campaign };
    for (let index = 0; index < infoFields.length; index++) {
      const element = infoFields[index];
      campaignNew[element] = data[element];
    }
    setCampaign(campaignNew);
  };

  const getCampaignInfo = () => {
    var data = {};
    for (let index = 0; index < infoFields.length; index++) {
      const element = infoFields[index];
      data[element] = campaign[element];
    }
    return data;
  };

  return (
    <Card>
      <Steps style={{ padding: 8 }} current={currentStep} items={stepItems} />
      {loading ? (
        <Row style={{ height: "400px" }} justify="center" align="middle">
          <Spin size="large" />
        </Row>
      ) : (<>
      {currentStep === 0 && <InfomationCampaign nextStep={nextStep} value={getCampaignInfo()} onChange={setInfo} />}
        {currentStep === 1 && (
          <WayToSendCampaign
            prevStep={prevStep}
            nextStep={nextStep}
            lstApp={lstApp}
            lstSelectedApp={lstSelectedApp}
            setLstSelectedApp={setLstSelectedApp}
            value={campaign.way_to_send}
            onChange={setWayToSend}
            sendAllApp={campaign.send_all_app}
            onChangeSendAllApp={setSendAllApp}
          />
        )}
        {currentStep === 2 && (
          <ContentCampaign
            nextStep={nextStep}
            prevStep={prevStep}
            value={campaign.content}
            onChange={setContent}
            wayToSend={campaign.way_to_send}
          />
        )}
        {currentStep === 3 && <SuccessCampaign prevStep={prevStep} value={campaign}></SuccessCampaign>}
      </>)}
      
    </Card>
  );
}

export default memo(AddEditCampaign);
