import React, { Component } from "react";
import { Input, Dropdown, Menu } from "antd";
import moment from "moment";

export default class ReasonPicker extends Component {
  constructor(props) {
    super(props);
    this.lstreason = ["Lý do 1", "Lý do 2", "Lý do 3"];
    this.state = { lstreason: this.lstreason };
  }

  onChange = (value) => {
    var lstreason = this.lstreason;
    var newlst = [];
    lstreason.forEach((item) => {
      if (item.includes(value)) newlst.push(item);
    });
    this.setState({ lstreason: newlst });
    if (this.props.onChange) this.props.onChange(value);
  };

  onClick = (value) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  menu = () => {
    return (
      <Menu onClick={(value) => this.onClick(value.key)}>
        {this.state.lstreason.map((item) => {
          return (
            <Menu.Item key={item} style={this.props.value === item && { backgroundColor: "#99e2fc", fontWeight: "bold" }}>
              <div>{item}</div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    return (
      <Dropdown overlay={this.menu} placement="bottomCenter" trigger={["click"]}>
        <Input value={this.props.value} onChange={(e) => this.onChange(e.target.value)} />
      </Dropdown>
    );
  }
}
