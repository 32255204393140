import React, { Component } from "react";
import { Input, Button, Tag, Card, Popconfirm, Alert, Checkbox, Row, Col, message } from "antd";
import { Redirect } from "react-router";
import api from "api";

export default class AppItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBTN: false,
    };
  }

  installApp = async (id) => {
    api
      .post("app/auth", { app_id: id })
      .then((res) => {
        this.props.getInstalledApp();
        message.success("Cài đặt thành công", 3);
        this.setState({ loadingBTN: false });
      })
      .catch((err) => {
        message.error("Cài đặt thất bại", 3);
        this.setState({ loadingBTN: false });
      });
  };

  uninstallApp = (id) => {
    api
      .post("shop/unintallapp", { app_id: id })
      .then((res) => {
        this.props.getInstalledApp();
        message.success("Gỡ cài đặt thành công", 3);
      })
      .catch((err) => {
        message.error("Gỡ cài đặt thất bại", 3);
      });
  };

  render() {
    return (
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div
          style={{
            height: "190px",
            width: "100%",
            border: "1px solid #dfdfdf",
            padding: "15px",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
            <div>
              <img src={this.props.item.logo} style={{ width: "80px" }}></img>
            </div>
            <div style={{ marginLeft: 20, flex: 1 }}>
              <div style={{ marginBottom: "15px", fontSize: "15px", fontWeight: "bold" }}>{this.props.item.name}</div>
              <div style={{ marginBottom: "15px", fontSize: "15px", color: "#c9c9c9" }}>{this.props.item.description}</div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div>
              {this.props.index > -1 ? (
                // <Button type="primary" onClick={() => { this.setState({loadingBTN:true},async()=>{ await this.uninstall(this.props.item.id);this.setState({loadingBTN:false})}) }} loading={this.state.loadingBTN? true:false}><i className="fas fa-upload"></i>&nbsp;Gỡ cài đặt</Button>
                <div style={{ display: "flex" }}>
                  <Button style={{ marginRight: 10 }}>
                    <a href={`/apps/${this.props.item.id}`}>
                      <i className="fa fa-cog" aria-hidden="true"></i>&nbsp;Cấu hình
                    </a>
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ loadingBTN: true }, () => {
                        this.uninstallApp(this.props.item.id);
                        this.setState({ loadingBTN: false });
                      });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Gỡ cài đặt
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ loadingBTN: true }, async () => {
                      await this.installApp(this.props.item.id);
                    });
                  }}
                  loading={this.state.loadingBTN ? true : false}
                >
                  <i className="fa fa-download"></i>&nbsp;Cài đặt
                </Button>
              )}
            </div>
          </div>
        </div>
      </Col>
    );
  }
}
