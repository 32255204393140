import React, { Component } from "react";
import api from "api";
import { Input, Button, Progress, Checkbox, Row, Col, message, Modal, DatePicker, Space, Spin, Result, Table, Form } from "antd";

import { io } from "socket.io-client";
import { wss_url } from "config";
import { Record } from "immutable";

import { getSocket } from "common/realtime";
const { RangePicker } = DatePicker;

export default class SyncPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isSync: false,
      totaldown: 0,
      currentdown: 0,
      percentdown: 0,
      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      download: true,
      mode: "download",
      lstErr: [],
      totalErr: 0,
      sync_socket_id: 0,
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    var socket = getSocket("app_socket");
    console.log("path", `${wss_url}/app_socket`);
    socket.on("connect", () => {
      socket.on("sync_order_trustsales", (data) => {
        console.log("data", data);
        if (data.complete) {
          message.success("Đồng bộ thành công. Chúng tôi đang xử lý dữ liệu của bạn. Chúng tôi sẽ thông báo đến bạn sau khi xử lý xong");
        } else {
          var percent = 0;
          if (data.total > 0) {
            percent = Math.floor((data.current / data.total) * 100);
          }
          this.setState({ currentsync: data.current, totalsync: data.total, percentsync: percent });
          if (data.success) {
            this.setState({ isSync: false });
          }
        }
        if (data.synchonize_over == true) {
          this.setState({ isSync: false });
        }
        var percent = 0;
        var current = data.total - data.remain;
        if (data.total > 0) {
          percent = Math.floor((current / data.total) * 100);
        }

        this.setState({ currentsync: current, totalsync: data.total, percentsync: percent });
        if (data.success) {
          this.setState({ isSync: false });
        }
      });
    });
    this.socket = socket;
  };

  open = (store, socketid) => {
    this.setState({
      visible: true,
      store: store,

      totalsync: 0,
      currentsync: 0,
      percentsync: 0,
      success: false,
      isSync: false,
      download: true,

      lstErr: [],
      totalErr: 0,
      ignore: false,
      sync_socket_id: socketid,
    });
    //  this.syncOrder(store);
  };

  renderErrorList = () => {
    var lst = [...this.state.lstErr];
    var columns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "error",
        dataIndex: "",
        key: "error",
        render: (index, record) => {
          return <div>{record.error.message}</div>;
        },
      },
    ];
    var pagination = {
      showTotal: () => {
        return <div style={{ marginRight: 30 }}>Không đồng bộ được {this.state.totalErr} đơn hàng</div>;
      },
    };
    return (
      <>
        {lst.length > 0 && (
          <div style={{ borderTop: "3px solid #fc0000" }}>
            <Table dataSource={lst} columns={columns} pagination={pagination} rowKey={"id"} />
          </div>
        )}
      </>
    );
  };

  syncOrder = (values) => {
    var store = this.state.store;
    var date = {
      start_date: values.date[0],
      end_date: values.date[1].add(1, "day"),
    };
    this.setState({ isSync: true });
    api.post("app/trustsales/order/syncorder", { store: store, id: this.socket.id, sync_socket_id: this.state.sync_socket_id, date: date, ignore: !values.ignore }).then((data) => {
      this.setState({ visible: false });
    });
  };

  render() {
    return (
      <Modal
        title="Đồng bộ dữ liệu"
        open={this.state.visible}
        onCancel={() => this.setState(!this.state.isSync && { visible: false, type: "" })}
        maskClosable={false}
        footer={
          this.state.success
            ? [
                <Button onClick={() => this.setState(!this.state.isSync && { visible: false, type: "" })} type="primary">
                  Thoát
                </Button>,
              ]
            : [
                <Button
                  onClick={() => {
                    this.form.current.submit();
                  }}
                  loading={this.state.isSync}
                  type="primary"
                >
                  Đồng bộ
                </Button>,
              ]
        }
        width="50%"
      >
        <div>
          {this.state.success === false ? (
            <div>
              <div>
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <h2 style={{ margin: "auto" }}>Đồng bộ dữ liệu</h2>
                </div>
                <Form
                  ref={this.form}
                  onFinish={this.syncOrder}
                  style={{ marginBottom: 20 }}
                  initialValues={{
                    ignore: false,
                  }}
                >
                  <Row gutter={20}>
                    <Col span={12}>
                      <Form.Item
                        label="Thời gian"
                        name={"date"}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa thời gian!",
                          },
                        ]}
                      >
                        <RangePicker format={"DD-MM-YYYY"}></RangePicker>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="ignore" valuePropName="checked">
                        <Checkbox>Tích điểm</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {/* <label>
                  Tiến độ đồng bộ:&nbsp;{this.state.currentsync}/{this.state.totalsync}
                </label>
                <Progress percent={this.state.percentsync} style={{ marginBottom: 10 }} />
                {this.renderErrorList()} */}
              </div>
            </div>
          ) : (
            <Result status="success" title="Đồng bộ thành công" subTitle={this.state.currentsync + "/" + this.state.totalsync + " đơn hàng"} />
          )}
        </div>
        {/* <div>
                    Dang đồng bộ
                    <Space>

                        <Button onClick={this.testdata}>Test data</Button>

                        <Button onClick={this.syncOrder} loading={this.state.isSync} type="primary">Đồng bộ</Button>
                    </Space>
                </div> */}
      </Modal>
    );
  }
}
