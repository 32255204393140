import React from "react";
import { Button, Switch, Row, Col } from "antd";

export default class ButtonTypePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (data) => {
    console.log("datacudo", data);
    this.props.onChange(data);
  };

  renderItem = () => {
    var listType = [
      { type: "phone_number", icon: <i className="fas fa-phone-alt"></i>, text: "Gọi điện thoại" },
      { type: "web_url", icon: <i className="fas fa-link"></i>, text: "Mở liên kết" },
      { type: "postback", icon: <i className="fas fa-brain"></i>, text: "Trả lời lại" },
    ];
    var data = listType.map((item) => {
      return (
        <Col span={8}>
          <div
            style={
              this.props.value !== item.type
                ? { width: "100%", border: "2px dashed #dfdfdf", padding: "10px 18px", fontSize: 15, fontFamily: "Poppins", borderRadius: 15 }
                : { width: "100%", border: "2px dashed #00bfff", padding: "10px 18px", fontSize: 15, fontFamily: "Poppins", borderRadius: 15 }
            }
            onClick={() => {
              this.onChange(item.type);
            }}
          >
            <div>
              {item.icon}&nbsp;&nbsp;{item.text}
            </div>
          </div>
        </Col>
      );
    });
    return data;
  };

  render() {
    return (
      <div>
        <title>Hành động</title>
        <Row gutter={20}>{this.renderItem()}</Row>
      </div>
    );
  }
}
