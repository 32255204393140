import React from "react";
import { Modal, Input, Card, Button, Tooltip, message, Col, Select } from "antd";
import AntTable from "components/common/AntTable";
import VoucherFilter from "./voucher_filter";
import PopupAddVoucher from "./popup_add_voucher_template";
import moment from "moment";
import api from "api";
import SearchBox from "components/common/SearchBox/SearchBox";
const { Search } = Input;
const { Option } = Select;

export default class PopupListVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      filter: {
        query: { type: this.props.type },
      },
    };
    this.TableVoucher = React.createRef();
    this.PopupAddVoucher = React.createRef();
  }
  open = () => {
    this.setState({
      visible: true,
    });
  };
  close = () => {
    this.setState({
      visible: false,
    });
  };
  onSearch = (e) => {
    var filter = this.state.filter;
    filter.query.name = { $regex: e };
    this.setState({ filter: filter }, () => this.TableVoucher.current.reload());
  };
  setVoucherSelected = (record) => {
    if (this.props.setVoucherSelected) {
      this.props.setVoucherSelected(record);
    }
    this.close();
  };
  reload = () => {
    this.TableVoucher.current.reload();
    this.props.reload();
  };

  removeAscent = (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\s/g, "");
    return str;
  };

  onFilterChange = (name, value) => {
    var filter = { ...this.state.filter };
    if (value == null || value == "") {
      delete filter.query[name];
    } else {
      if (name == "search_text") {
        var new_value = this.removeAscent(value);
        value = new_value;
      }
      filter.query[name] = { $regex: value };
    }
    this.setState({ filter: filter }, () => this.TableVoucher.current.reload());
  };

  onMultiSelectChange = (name, value) => {
    var filter = { ...this.state.filter };
    if (value == null || value == []) {
      delete filter.query[name];
    } else {
      filter.query[name] = { $in: value };
    }
    this.setState({ filter: filter }, () => this.TableVoucher.current.reload());
  };

  getColumns = () => {
    var columns = [
      {
        title: "Tên Voucher",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_on",
        key: "create_on",
        sorter: true,

        render: (text, record, index) => {
          var time = moment(new Date(record.create_on)).format("DD/MM/YYYY");
          return <p>{time}</p>;
        },
      },
      {
        title: "",
        dataIndex: "date_update",
        key: "date_update",
        render: (text, record, index) => {
          return (
            <>
              {/* <Tooltip placement="topLeft" title="Xoá">
                        <Button type="link" onClick={() => this.delete(record.id)}>
                          Xoá
                        </Button>
                      </Tooltip> */}
              <Tooltip placement="topLeft" title="Sửa">
                <Button type="link" onClick={() => this.PopupAddVoucher.current.open(record.id)}>
                  Sửa
                </Button>
              </Tooltip>
              <Tooltip placement="topLeft" title="Áp dụng">
                <Button type="link" onClick={() => this.setVoucherSelected(record)}>
                  Áp dụng
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    ];
    return columns;
  };

  getFilters = () => {
    var filters =
      this.props.type == "all"
        ? [
            {
              Key: "way_to_send",
              Name: "Phương thức",
              Component: (
                <Col span={6}>
                  <div style={{ marginRight: 10 }}>
                    <label>Loại</label>

                    <Select style={{ width: "100%" }} onChange={(e) => this.onMultiSelectChange("type", e)} mode="multiple" allowClear={true}>
                      <Option value="exchange_point">Voucher theo số tiền</Option>
                      <Option value="exchange_percentage">Voucher theo phần trăm</Option>
                      <Option value="exchange_freeship">Voucher Freeship</Option>
                      <Option value="exchange_gift">Voucher quà tặng</Option>
                      <Option value="phone_card">Thẻ cào điện thoại</Option>
                    </Select>
                  </div>
                </Col>
              ),
            },
          ]
        : [];
    return filters;
  };

  render() {
    var filters = this.getFilters();
    var listvoucher = (
      <>
        <Card style={{ marginBottom: 20 }}>
          <SearchBox
            name="campaign"
            data={filters}
            onSelectFilterChange={this.onSelectFilterChange}
            placeholder="Thông tin"
            dataButton={[
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={() => {
                  this.PopupAddVoucher.current.open(0);
                }}
              >
                <i className="fas fa-plus" style={{ paddingRight: 10 }}></i> Thêm mới{" "}
              </Button>,
            ]}
            onSearch={() => this.TableVoucher.current.reload()}
            onChange={(value) => {
              this.onFilterChange("search_text", value);
            }}
          />
        </Card>

        <AntTable
          bordered
          columns={this.getColumns()}
          customSearch={this.state.filter}
          tableName={"tbVoucher"}
          ajax="voucher/getvoucherstemplate"
          ref={this.TableVoucher}
          rowKey={(record) => record.id}
        ></AntTable>
        <PopupAddVoucher ref={this.PopupAddVoucher} reload={this.reload} type={this.props.type} />
      </>
    );
    return (
      <Modal title="Các voucher có thể sử dụng" open={this.state.visible} onOk={() => {}} onCancel={() => this.close()} width="80%" loading={this.state.loading} footer={[]}>
        {listvoucher}
      </Modal>
    );
  }
}
