import React, { useEffect } from "react";
import { wheel } from "./WheelCon";
import "./index.css";
import useState from "react-usestateref";
import { Modal, Button } from "antd";
import { useParams } from "react-router-dom";
import AOS from "aos";
import MyInputNumber from "components/picker/inputnumber_picker";
import { Helmet } from "react-helmet";

const rdAr = (ar) => {
  for (let i = ar.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [ar[i], ar[j]] = [ar[j], ar[i]];
  }
  return ar;
};

const genByPercent = (ar, n) => {
  return ar.flatMap((i) => Array(n).fill(i));
};

function Wheel(props) {
  const { data, setTarget } = props;
  const [winCon, setWinCon, winConRef] = useState({});
  const [isSpinng, setIsSpinning, isSpinningRef] = useState(false);
  const [winner, setWinner, winnerRef] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    let rewards = [];
    let ws = [];
    for (const i of data?.reward_list) {
      for (let ii = 0; ii < i.amount; ii++) {
        rewards.push({
          id: i.id,
          text: i.name,
          type: i.type,
          percent: i.percent,
          fillStyle: i.background,
        });
      }
      ws.push(genByPercent([i.name], i.percent));
    }

    rewards = rdAr(rewards);
    const wn = rdAr(ws.flat())[0];
    setTarget(wn);
    const pos = rewards
      .map((i, k) => {
        if (i.text == wn) return k;
      })
      .filter((x) => Number(x));
    setWinCon({ ...winConRef.current, total: rewards.length, pos: pos });

    window.Wheel = new wheel({
      outerRadius: 205,
      innerRadius: 43,
      textFontSize: 15,
      textAlignment: "outer",
      numSegments: rewards.length,
      segments: rewards,
      animation: {
        type: "spinToStop",
        duration: 15,
        spins: 3,
        callbackFinished: handleWinner,
      },
      pins: {
        number: rewards.length,
        fillStyle: "white",
        outerRadius: 10,
        margin: -23,
      },
    });

    function resetWheel() {
      window.Wheel.stopAnimation(false);
      window.Wheel.rotationAngle = 0;
      window.Wheel.draw();
    }

    function handleWinner(x) {
      setIsSpinning(false);
      setWinner(data?.reward_list.filter((i) => i.name == x.text)[0]);
      // alert("Trúng thưởng kìaaaaa " + x.text);
      setIsModalVisible(true);

      window.Wheel.stopAnimation(false);
      // window.Wheel.draw();
    }

    window.Wheel.draw();
  }, []);

  const fired = () => {
    if (!isSpinng) {
      setIsSpinning(true);
      window.Wheel.animation.spins = rdAr([5, 6, 7, 8, 9, 10])[0];
      let stop =
        parseInt(360 / winConRef.current.total) * rdAr(winConRef.current.pos)[0] +
        parseInt(360 / winConRef.current.total) / 3 +
        Math.floor((Math.random() * parseInt(360 / winConRef.current.total)) / 3);
      window.Wheel.animation.stopAngle = stop;
      window.Wheel.startAnimation();
    }
  };

  const handleOk = () => {
    window.Wheel.stopAnimation(false);
    window.Wheel.rotationAngle = 0;
    window.Wheel.draw();

    setIsModalVisible(false);
    setWinner(null);
    window.location.reload();

    // setInitWheel(true)
  };

  const modalHeader = () => {
    switch (winnerRef.current?.type) {
      case "fail":
        return <> Rất tiếc bạn đã bị mất lượt</>;
      case "point":
        return <>Chúc mừng quý khách đã tích được {new Intl.NumberFormat().format(winner?.point)} điểm</>;
      case "voucher":
        return <>Chúc mừng quý khách đã nhận được </>;
    }
  };

  const modalBody = () => {
    switch (winnerRef.current?.type) {
      case "fail":
        return <>Chúc bạn may mắn lần sau...</>;
      case "point":
        return <>Phần thưởng chương trình ...</>;
      case "voucher":
        return <>1 mã giảm giá {new Intl.NumberFormat().format(winner?.voucher.value)}đ</>;
      case "exchange_freeship":
        return (
          <>
            Mã giảm giá Freeship trị giá {new Intl.NumberFormat().format(winner?.value)}đ <br />
            Áp dụng cho đơn hàng tối thiểu là {new Intl.NumberFormat().format(winner?.min_order)}đ
          </>
        );
    }
  };

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js" type="text/javascript" />
      </Helmet>
      <div className="wheel" onClick={fired}>
        <div className="wheel-child">
          <div className="wheel-child-arrow">
            <div className="wheel-child-arrow1" />
          </div>
        </div>
        <canvas id="canvasNekk" width="465" height="465">
          <p align="center">
            Ây daaa trình duyệt của bạn không hỗ trợ vòng quay này.
            <br /> Vui lòng đổi trình duyệt khác.
          </p>
        </canvas>
      </div>

      <Modal style={{ fontFamily: "'Encode Sans', sans-serif" }} title={modalHeader()} open={isModalVisible} onOk={handleOk} onCancel={handleOk}>
        {isModalVisible && (
          <h2 data-aos="flip-right" style={{ textAlign: "center", fontSize: "17px" }}>
            {modalBody()}
          </h2>
        )}
      </Modal>
    </>
  );
}

export default Wheel;
