import { useState, memo } from "react";
import { Button, Typography,Tabs } from "antd";
import PackageRenewalHistory from "./PackageRenewalHistory";
import TransactionHistory from "./TransactionHistory";
const { Title, Text } = Typography;
const History = () => {
  const [topupaccount,setTopUpAccount]=useState(false)
  const [packagerenewal,setPackageRenewal]=useState(false)
  const ButtonTopUpAccount=<Button size="middle" type="primary" onClick={()=>{setTopUpAccount(true)}}>
  Nạp tài khoản
</Button>
  const ButtonPackageRenewal=<Button size="middle" type="primary" onClick={()=>{setPackageRenewal(true)}}>
  Gia hạn
 </Button>
  const [slot,setSlot] = useState(() => {
    return (
      ButtonTopUpAccount
    );
  }, ["right"]);
  const handleChangeTabs=(key)=>{
    if(key==1)
    setSlot(() => {
        return (
          ButtonTopUpAccount
        );
      }, ["right"])
    else
    setSlot(() => {
        return (
          ButtonPackageRenewal
        );
      }, ["right"])
  }
  const items = [
    {
      key: '1',
      label: "Lịch sử giao dịch",
      children: <TransactionHistory topupaccount={topupaccount} setTopUpAccount={setTopUpAccount}/>,
    },
    {
      key: '2',
      label:"Lịch sử gia hạn gói cước",
      children: <PackageRenewalHistory packagerenewal={packagerenewal} setPackageRenewal={setPackageRenewal}/>,
    }
  ];
  
  return (
  <div style={{width:"100%",backgroundColor: "white"}}>
    <Tabs defaultActiveKey="1" items={items}  style={{padding:"8px 24px 0 24px"}} tabBarExtraContent={slot} onChange={handleChangeTabs}/>
  </div>);
};

export default memo(History);