import React, { Component } from "react";
import Select2 from 'components/common/select2';
import { Col} from "antd";
import api from 'api';

class SelectRole extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      data: [],
      value: 'ALL',
      roles: []
    }
  }

  componentDidMount () {
    this.getRoles();
  }

  onChange = (name, value) => {
    this.props.onChange('role', value);
    this.setState({ value: value });
  }

  getRoles = () => {
    if (this.state.roles.length == 0)
      api.post("user/listroles").then(resp => {
        if (resp != null)
        {
          resp.unshift({
            id: "all",
            name: "--- TẤT CẢ ---"
          })
          this.setState({ roles: resp })
        }
        else
          console.log('Có lỗi phân quyền xảy ra')
      })
  }

  render() {
    var roles = [...this.state.roles];
    return (
      <Col span={4}>
        <label>Phân Quyền</label><br />
        <Select2 data={roles} value={this.state.value} displayCol={"name"} valCol={"id"} onChange={this.onChange} name={this.props.name}>
        </Select2>
        </Col>
    )
  }
}

export default SelectRole;
