import React, { memo, useState, forwardRef, useImperativeHandle, useRef, createRef } from "react";
import { Input, Upload, Button, Tabs, Col, Row, Modal, message } from "antd";
import { WarningFilled } from "@ant-design/icons";
import api from "api";
import { getPath } from "common/file.js";
import "./styles.css";

import ChatbotPicker from "./chatbot_content_editor";

import SmsFPTEditor from "./fpt_sms_editor";
import EmailEditor from "./email_editor";
import ZaloEditor from "./zalo_editor";
import EsmsEditor from "./esms_editor";

const { TextArea } = Input;
const { TabPane } = Tabs;

const ContentPicker = forwardRef((props, ref) => {
  const { onChange, value, wayToSend } = props;
  /**
   * Kiểm tra dữ liệu
   * Báo lỗi ! trên các tab đang lỗi
   * @returns {object} null nếu dữ liệu hợp lệ, {tab:{error}} nếu dữ liệu chưa hợp lệ
   */
  const [error, setError] = useState({});
  const [activeKey, setActiveKey] = useState("content-picker-sms-fpt");
  const emailRef = useRef("emailRef");
  const zaloRef = useRef("zaloRef");
  const smsfptRef = useRef("smsfptRef");
  const esmsRef = useRef("esmsRef");
  const [lstTab, setLstTab] = React.useState();
  const [contentText, setContentText] = useState(null);

  React.useEffect(() => {
    var lst = [];
    wayToSend?.forEach((item, index) => {
      if (item.id == "email" || item.id == "default_email") {
        lst.push({
          key: "content-picker-email",
          label: <span>Email {error.email && <WarningFilled style={{ color: "red", margin: "0 0 0 8px" }} />}</span>,
          children: <EmailEditor ref={emailRef} value={value?.email} onChange={emailEditorChange}></EmailEditor>,
          forceRender: true,
        });
      }
      if (item.id == "zalo") {
        lst.push({
          key: "content-picker-zalo",
          label: <span>Zalo {error.zalo && <WarningFilled style={{ color: "red", margin: "0 0 0 8px" }} />}</span>,
          children: <ZaloEditor ref={zaloRef} onChange={zaloEditorChange} value={value?.zalo}></ZaloEditor>,
          forceRender: true,
        });
      }
      if (item.id == "esms") {
        lst.push({
          key: "content-picker-esms",
          label: <span>ESMS {error.esms && <WarningFilled style={{ color: "red", margin: "0 0 0 8px" }} />}</span>,
          children: <EsmsEditor ref={esmsRef} wayToSend={wayToSend} value={value?.esms} onChange={esmsEditorOnChange} mode={props.mode} />,
          forceRender: true,
        });
      }
      if (item.id == "fb") {
        lst.push({
          key: "content-picker-facebook",
          label: <span>Chatbot {error.Chatbot && <WarningFilled style={{ color: "red", margin: "0 0 0 8px" }} />}</span>,
          forceRender: true,
        });
      }
      if (item.id == "smsfpt") {
        lst.push({
          key: "content-picker-sms-fpt",
          label: <span>SMS FPT {error.smsfpt && <WarningFilled style={{ color: "red", margin: "0 0 0 8px" }} />}</span>,
          children: <SmsFPTEditor ref={smsfptRef} rows={10} value={value?.smsfpt} onChange={smsFPTEditorOnChange}></SmsFPTEditor>,
          forceRender: true,
        });
      }
    });
    setActiveKey(lst[0]?.key);
    setLstTab(lst);
  }, [wayToSend, error, value]);

  useImperativeHandle(
    ref,
    () => {
      return {
        validate: validate,
      };
    },
    [props]
  );

  /**
   * Kiểm tra dữ liệu của picker
   * @returns error or null
   * @author lqhoang
   */
  const validate = () => {
    var error = {};
    if (emailRef?.current?.validate) {
      var emailError = emailRef.current.validate();
      if (emailError) {
        error.email = emailError;
      }
    }
    if (zaloRef?.current?.validate) {
      // console.log(zaloRef.current.validate);
      var zaloError = zaloRef.current.validate();
      if (zaloError) {
        error.zalo = zaloError;
      }
    }
    if (smsfptRef?.current?.validate) {
      var smsfptError = smsfptRef.current.validate();
      if (smsfptError) {
        error.smsfpt = smsfptError;
      }
    }
    if (esmsRef?.current?.validate) {
      var esmsError = esmsRef.current.validate();
      if (esmsError) {
        error.esms = esmsError;
      }
    }
    setError(error);
    if (Object.keys(error).length > 0) {
      return error;
    }
    return null;
  };

  /**
   * xử lý data khi zalopicker thay đổi
   * @param {obj} data
   * @returns
   * @author lqhoang
   */
  const zaloEditorChange = (data) => {
    var value = { ...props.value };
    if (data) {
      value.zalo = data;
    }
    props.onChange(value);
  };

  const emailEditorChange = (data) => {
    var value = { ...props.value };
    value.email = data;

    props.onChange(value);
  };

  const smsFPTEditorOnChange = (data) => {
    // setContentText(data);
    var value = { ...props.value };
    value.smsfpt = data;
    props.onChange(value);
  };

  /**
   * Hàm thay đổi nội dung eSMS
   * @param {*} data dữ liệu thay đổi
   * @param {*} property sms_content || zalo_content
   * @param {*} propertyChild property con trong property sms_content || zalo_content
   */
  const esmsEditorOnChange = (data, property, propertyChild) => {
    // debugger;
    var value = { ...props.value };

    if (property) {
      value.esms[property][propertyChild] = data;
    } else {
      value.esms = data;
    }

    props.onChange(value);
  };

  return (
    <>
      <Tabs type="card" items={lstTab} style={{ padding: "8px 8px 0 8px" }} />
    </>
  );
});

export default memo(ContentPicker);
