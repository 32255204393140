import React from "react";
import { Modal, Input, Form, Row, Col, InputNumber, Checkbox, Card, Radio } from "antd";
import api from "api";
import MyInputNumber from "components/picker/inputnumber_picker";
import DatePickerNew from "components/picker/date_picker";
import SendingChannel from "../sending_channel";
import ListVoucherPicker from "./list_voucher_picker";
import ListPhoneCardPicker from "./list_phone_card_picker";
import moment from "moment";
import { toNoUnicode } from "common/text";
const { TextArea } = Input;

export default class PopupAddVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: "",
      load: false,
      gotlstapp: false,
      id: 0,
      type: this.props.type != "all" ? this.props.type : "exchange_point",
    };

    this.addform = React.createRef();
  }
  componentDidMount = () => {
    this.getApp();
  };

  open = (id) => {
    this.getData(id);
    this.setState({ visible: true, data: "", id: id });
  };
  getApp = () => {
    if (this.state.type) {
      var splist = [];
      splist.push(this.state.type);
      api
        .post("shop/getinstalledappbycategoriesandsupports", {
          categories: ["crm"],
          supports: splist,
        })
        .then((res) => {
          this.setState({ lst: res, gotlstapp: true });
        })
        .catch((err) => {});
    } else {
    }
  };
  getData = (id) => {
    if (id === 0) {
      var data = {
        name: "",
        id: "",
        voucher_limit: {
          is_voucher_limit: false,
        },
        exchange: {
          value: null,
        },
        prefix: {
          is_prefix: false,
        },
        min_order_total_price: {
          is_min_order_total_price: false,
        },
        time_start: {
          is_time_start: false,
        },
        time_end: {
          is_time_end: false,
        },
        voucher_quantity: {
          is_voucher_quantity: false,
        },
        type: this.props.type,
        voucher: {
          type: this.props.type == "phone_card" ? "phone_card" : "create",
          list_chanel: [],
          list_voucher: [],
        },
      };

      //this.addform.current.setFieldsValue(data);
      this.setState({ data: data, id: id }, () => {
        this.addform.current.setFieldsValue(data);
      });
    } else {
      api
        .post("voucher/getbyid", { id: id })
        .then((res) => {
          if (res.time_start.is_time_start) {
            res.time_start.value = moment(res.time_start.value);
          }
          if (res.time_end.is_time_end) {
            res.time_end.value = moment(res.time_end.value);
          }
          this.setState({ data: res, type: res.type }, () => {
            this.addform.current.setFieldsValue(res);
          });
        })
        .catch((err) => {});
    }
  };

  close = () => {
    this.addform.current.resetFields();
    this.setState({ visible: false, type: null });
  };

  onTextChange = (data) => {
    this.setState({ data: data });
  };
  saveTemplate = (values) => {
    values.type = this.state.type;
    if (this.state.id !== 0) {
      values.id = this.state.id;
    }
    api
      .post("/voucher/savetemplate", { data: values })
      .then((res) => {
        this.props.reload();
        this.close();
      })
      .catch((err) => {});
  };
  onPrefixChange = (value) => {
    var data = toNoUnicode(value.toUpperCase());
    this.addform.current.setFieldsValue({ prefix: { value: data } });
    // this.handleTemplateDescription();
  };

  render() {
    var unit = "";
    var step = 0;
    if (this.props.type === "exchange_point") {
      unit = "Số tiền";
      step = 1000;
    }
    if (this.props.type === "exchange_percentage") {
      unit = "%";
      step = 10;
    }
    return (
      <Modal
        open={this.state.visible}
        title="Thêm voucher"
        onOk={() => {
          this.addform.current.submit();
        }}
        onCancel={() => this.close()}
        okText="Lưu"
        width="60%"
        cancelText="Trở lại"
      >
        <Card size="small" hidden={!(this.props.type === "all")} title="Chi tiết" style={{ width: "100%", margin: "0px 0px 10px 10px", "align-items": "center" }}>
          <Radio.Group
            name="type_picker"
            value={this.state.type}
            onChange={(e) => {
              this.setState({ type: e.target.value }, () => {
                this.addform.current.setFieldsValue({ voucher: { type: "phone_card" }, type: e.target.value });
                this.getApp();
              });
            }}
          >
            <Radio value="exchange_point">Voucher theo số tiền</Radio>
            <Radio value="exchange_percentage">Voucher theo phần trăm</Radio>
            <Radio value="exchange_freeship">Voucher Freeship</Radio>
            <Radio value="exchange_gift">Voucher quà tặng</Radio>
            <Radio value="phone_card">Thẻ cào điện thoại</Radio>
          </Radio.Group>
        </Card>
        <Form name="voucher_template" ref={this.addform} layout="vertical" onFinish={this.saveTemplate}>
          <Row style={{ display: "flex", width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <Card size="small" title="Chi tiết" style={{ width: "100%", margin: "0px 0px 10px 10px" }}>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      name="name"
                      label="Tên hiển thị"
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa nhập tên!",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }}></Input>
                    </Form.Item>
                  </Col>
                  {this.state.type === "exchange_gift" && (
                    <Col span={6}>
                      <Form.Item
                        name={["exchange", "product_id"]}
                        label="Mã sản phẩm"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập mã sản phẩm!",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <Input
                        // onChange={(e) => {
                        //   this.productIDChange(e.target.value);
                        //   this.handleTemplateDescription();
                        // }}
                        ></Input>
                      </Form.Item>
                    </Col>
                  )}

                  {this.state.type !== "exchange_freeship" && this.state.type != "phone_card" && (
                    <Col span={6}>
                      <Form.Item
                        name={["exchange", "value"]}
                        label={this.state.type !== "exchange_gift" ? `Giá trị(${unit})` : "Số lượng"}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa chọn giá trị quy đổi!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={this.props.type === "exchange_percentage" ? 100 : null}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          step={step}
                          style={{ width: "100%" }}
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                  )}
                  {this.state.type != "phone_card" && (
                    <Col span={6}>
                      <Form.Item
                        name="purchase_url"
                        label="Url mua hàng"
                        rules={[
                          {
                            validator: (rule, value) => {
                              return new Promise((resolve, error) => {
                                if (value == null || value == "") resolve();
                                else {
                                  try {
                                    var url = new URL(value);
                                    resolve();
                                  } catch {
                                    error();
                                  }
                                }
                              });
                            },
                            message: "Đường dẫn không hợp lệ!",
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          onBlur={(e) => {
                            e.target.value = e.target.value.trim();
                            this.addform.current.setFieldsValue({ purchase_url: e.target.value });
                            this.addform.current.validateFields(["purchase_url"]);
                          }}
                        ></Input>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name={["prefix", "is_prefix"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Voucher Prefix</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.prefix !== currentValues.prefix}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["prefix", "is_prefix"]);
                        return (
                          <Form.Item
                            name={["prefix", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập tiền tố!",
                                },
                              ]
                            }
                          >
                            <Input onChange={(e) => this.onPrefixChange(e.target.value)} style={{ width: "100%" }}></Input>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={["min_order_total_price", "is_min_order_total_price"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Giá trị tối thiểu của đơn hàng</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.min_order_total_price !== currentValues.min_order_total_price}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["min_order_total_price", "is_min_order_total_price"]);
                        return (
                          <Form.Item
                            name={["min_order_total_price", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập giá trị tối thiểu của đơn hàng!",
                                },
                              ]
                            }
                          >
                            <MyInputNumber style={{ width: "100%" }} min={0} disabled={value ? false : true} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item name={["voucher_limit", "is_voucher_limit"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Giới hạn hiệu lực của voucher (ngày)</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher_limit !== currentValues.voucher_limit}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["voucher_limit", "is_voucher_limit"]);
                        return (
                          <Form.Item
                            name={["voucher_limit", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập giới hạn hiệu lực của voucher (ngày)! ",
                                },
                              ]
                            }
                          >
                            <MyInputNumber style={{ width: "100%" }} min={0} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={["voucher_quantity", "is_voucher_quantity"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Giới hạn số lượng voucher</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher_quantity !== currentValues.voucher_quantity}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["voucher_quantity", "is_voucher_quantity"]);
                        return (
                          <Form.Item
                            name={["voucher_quantity", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập số lượng voucher!",
                                },
                              ]
                            }
                          >
                            <InputNumber min={1} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} step={1} style={{ width: "100%" }}></InputNumber>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name={["time_start", "is_time_start"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Thời gian bắt đầu</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.time_start !== currentValues.time_start}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["time_start", "is_time_start"]);
                        return (
                          <Form.Item
                            name={["time_start", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập ngày bắt đầu!",
                                },
                                {
                                  validator: (rule, value) => {
                                    return new Promise((resolve, error) => {
                                      if (value < moment().startOf("day") && (this.state.data.id === "" || this.state.data.id == null)) {
                                        error();
                                      } else {
                                        resolve();
                                      }
                                    });
                                  },
                                  message: "Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại!",
                                },
                                {
                                  validator: (rule, value) => {
                                    var data = this.addform.current.getFieldsValue();
                                    return new Promise((resolve, error) => {
                                      if (data.time_end.is_time_end == false || data.time_end.value == null || value < data.time_end.value) {
                                        resolve();
                                      } else {
                                        error();
                                      }
                                    });
                                  },
                                  message: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc!",
                                },
                              ]
                            }
                          >
                            <DatePickerNew placeholder="Chọn ngày" style={{ width: "100%" }} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={["time_end", "is_time_end"]} valuePropName="checked">
                      <Checkbox style={{ marginBottom: 10 }}>Thời gian kết thúc</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.time_end !== currentValues.time_end}>
                      {({ getFieldValue }) => {
                        var value = getFieldValue(["time_end", "is_time_end"]);
                        return (
                          <Form.Item
                            name={["time_end", "value"]}
                            hidden={value ? false : true}
                            rules={
                              value == true && [
                                {
                                  required: true,
                                  message: "Bạn chưa nhập ngày kết thúc!",
                                },
                                {
                                  validator: (rule, value) => {
                                    return new Promise((resolve, error) => {
                                      if (value > moment()) {
                                        resolve();
                                      } else {
                                        error();
                                      }
                                    });
                                  },
                                  message: "Ngày kết thúc phải lớn hơn ngày hiện tại!",
                                },
                                {
                                  validator: (rule, value) => {
                                    var data = this.addform.current.getFieldsValue();
                                    return new Promise((resolve, error) => {
                                      if (data.time_start.is_time_start == false || data.time_start.value == null || value > data.time_start.value) {
                                        resolve();
                                      } else {
                                        error();
                                      }
                                    });
                                  },
                                  message: "Ngày kết thúc phải lớn hơn ngày bắt đầu!",
                                },
                              ]
                            }
                          >
                            <DatePickerNew placeholder="Chọn ngày" style={{ width: "100%" }} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  {this.state.type == "exchange_percentage" && (
                    <Col span={6}>
                      <Form.Item name={["max_value", "is_max_value"]} valuePropName="checked">
                        <Checkbox style={{ marginBottom: 10 }}>Giá trị tối đa của voucher</Checkbox>
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.max_value !== currentValues.max_value}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["max_value", "is_max_value"]);
                          return (
                            <Form.Item
                              name={["max_value", "value"]}
                              hidden={value ? false : true}
                              rules={
                                value == true && [
                                  {
                                    required: true,
                                    message: "Bạn chưa nhập giá trị tối đa của voucher!",
                                  },
                                ]
                              }
                            >
                              <MyInputNumber style={{ width: "100%" }} min={0} step={1000} disabled={value ? false : true} />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row>
                  {this.state.gotlstapp && (
                    <Card size="small" title="Voucher" style={{ margin: "0px 0px 10px 10px", width: "100%" }}>
                      <Form.Item
                        name={["voucher", "type"]}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa chọn hình thức phát voucher! ",
                          },
                        ]}
                      >
                        <Radio.Group>
                          {this.state.type == "phone_card" ? (
                            <Radio value="phone_card">Thẻ cào điện thoại</Radio>
                          ) : (
                            <>
                              <Radio value="create">Tạo qua kênh</Radio>
                              <Radio value="available">Có sẵn</Radio>
                            </>
                          )}
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher !== currentValues.voucher}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["voucher", "type"]);
                          return (
                            <Form.Item
                              name={["voucher", "list_chanel"]}
                              hidden={value === "create" ? false : true}
                              style={{ paddingTop: 15 }}
                              rules={
                                value == "create" && [
                                  {
                                    validator: (rule, value) => {
                                      return new Promise((resolve, error) => {
                                        if (value.length !== 0) {
                                          resolve();
                                        } else {
                                          error();
                                        }
                                      });
                                    },
                                    message: "Bạn chưa chọn kênh tạo voucher!",
                                  },
                                ]
                              }
                            >
                              <SendingChannel load={true} lst={this.state.lst} campaign_id={this.state.data.id} />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher !== currentValues.voucher}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["voucher", "type"]);
                          return (
                            <Form.Item
                              name={["voucher", "list_voucher"]}
                              hidden={value === "available" ? false : true}
                              style={{ paddingTop: 15 }}
                              rules={
                                value == "available" && [
                                  {
                                    validator: (rule, value) => {
                                      return new Promise((resolve, error) => {
                                        if (value.length !== 0) {
                                          resolve();
                                        } else {
                                          error();
                                        }
                                      });
                                    },
                                    message: "Bạn chưa thêm voucher!",
                                  },
                                  {
                                    validator: (rule, value) => {
                                      var data = this.addform.current.getFieldsValue();
                                      return new Promise((resolve, error) => {
                                        if (data.voucher_quantity.is_voucher_quantity && data.voucher_quantity.value && value.length != data.voucher_quantity.value) {
                                          error();
                                        } else {
                                          resolve();
                                        }
                                      });
                                    },
                                    message: "Số lượng voucher khác với số lượng quy định!",
                                  },
                                ]
                              }
                            >
                              <ListVoucherPicker />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher !== currentValues.voucher}>
                        {({ getFieldValue }) => {
                          var value = getFieldValue(["type"]);
                          console.log("value", value);
                          return (
                            <Form.Item
                              name={["voucher", "list_phone_card"]}
                              hidden={value === "phone_card" ? false : true}
                              style={{ paddingTop: 15 }}
                              rules={
                                value == "phone_card" && [
                                  {
                                    validator: (rule, value) => {
                                      return new Promise((resolve, error) => {
                                        if (value.length !== 0) {
                                          resolve();
                                        } else {
                                          error();
                                        }
                                      });
                                    },
                                    message: "Bạn chưa thêm thẻ cào!",
                                  },
                                ]
                              }
                            >
                              <ListPhoneCardPicker />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Card>
                  )}
                </Row>
              </Card>
            </div>
          </Row>
        </Form>
      </Modal>
    );
  }
}
