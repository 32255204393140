import React from "react";
import { Modal, Button, Divider, Space } from "antd";
import api from "api";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import QRCode from "qrcode.react";

export default class PopupQrPrint extends React.Component {
  constructor(props) {
    super(props);
    this.id = [];
    this.state = {
      isVisible: false,
      shop_url: "shop1",
      id: "",
    };
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    api.get("pointcode/getqrpath").then((data) => {
      this.setState({ shop_url: data.url });
    });
  }

  open = (id) => {
    this.setState({ isVisible: true });
    this.id = id;
  };

  close = (e) => {
    this.setState({ isVisible: false });
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    return (
      <Button type="primary">
        <Space>
          <i className="fas fa-print"></i> In
        </Space>{" "}
      </Button>
    );
  };

  render() {
    return (
      <Modal
        title="Mã Qr"
        open={this.state.isVisible}
        onOk={this.close}
        onCancel={this.close}
        footer={[<Button onClick={this.close}>Đóng</Button>, <ReactToPrint content={this.reactToPrintContent} documentTitle="AwesomeFileName" removeAfterPrint trigger={this.reactToPrintTrigger} />]}
      >
        <div ref={this.setComponentRef}>
          {this.id &&
            this.id.map((item) => {
              return (
                <div>
                  <div style={{ justifyContent: "center", paddingTop: 25, alignItems: "center", width: "auto", display: "flex", flexDirection: "column", height: "auto", border: "1px solid #000000" }}>
                    <QRCode value={`${this.state.shop_url}?id=${item.id_point}`} />

                    <br />
                    <p>Mã đơn hàng: {item.id}</p>
                  </div>
                  <br />
                </div>
              );
            })}
        </div>
      </Modal>
    );
  }
}
