import React, { useState, useRef } from "react";
import { Button, Modal, Form, Input, Select, Typography, Divider, message, InputNumber, Space, Row, Col } from "antd";
import api from "api";
const { Text, Link } = Typography;

function ModalAddEditUser(props) {
  const [validateUsername, setValidateUsername] = useState();
  const [form] = Form.useForm();
  const usernameInput = useRef(null);

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  React.useEffect(() => {
    if (props.mode === "edit" && props.isModalOpen) {
      form.setFieldsValue(props.record);
    } else {
      form.resetFields();
    }
  }, [props.isModalOpen, props.mode]);

  /**
   * Hàm save mới thành viên hoặc update thành viên
   * @author NhatAnh
   */
  const onFinish = async () => {
    // setValidateUsername(null);
    var dataPost = form.getFieldsValue();
    if (props.mode === "edit") {
      dataPost.user_id = props.record.user_id;
    }
    try {
      await api.post("master/user/save", dataPost).then((res) => {
        if (res) {
          message.success(res.message);
          props.setIsModalOpen(false);
        }
      });
    } catch (error) {
      if (error.code === 605) {
        setValidateUsername(error.message);
        form.setFieldsValue({ user_name: "" });
        usernameInput.current.focus();
      } else {
        setValidateUsername(null);
        message.error(error.message);
      }
    }
  };

  const onFinishFailed = () => {
    if (form.getFieldError("user_name")) {
      setValidateUsername(null);
    }
  };

  const onChange = () => {
    setValidateUsername(null);
  };

  return (
    <>
      <Modal
        title={
          props.mode === "add" ? (
            <Text style={{ fontSize: 16 }}>Thêm nhân viên mới</Text>
          ) : (
            <Text style={{ fontSize: 16 }}>Chỉnh sửa thông tin nhân viên</Text>
          )
        }
        footer={[
          <Row align="middle">
            <Col span={12} align="left">
              <Text>Mật khẩu mặc định là 1234567890</Text>
            </Col>
            <Col span={12} align="right">
              <Button onClick={handleCancel}>Đóng</Button>
              <Button key="submit" form="basic" type="primary" htmlType="submit">
                Lưu
              </Button>
            </Col>
          </Row>,
        ]}
        open={props.isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ role: "admin" }}
          autoComplete="off"
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
        >
          <Form.Item
            label="Tên tài khoản"
            name="user_name"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  var check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                  var checkNotUnicode = /[^\u0000-\u007F]+/;
                  if (value) {
                    if (check.test(value)) {
                      return Promise.reject("Tên tài khoản không được chứa khoảng trắng và ký tự đặc biệt");
                    } else if (checkNotUnicode.test(value)) {
                      return Promise.reject("Tên tài khoản phải là chữ không dấu");
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject("Tên tài khoản không được để trống");
                }
              }
            ]}
          >
            <Input
              status={validateUsername ? "error" : null}
              disabled={props.mode === "edit" ? true : false}
              onChange={onChange} ref={usernameInput}
            />
          </Form.Item>
          {validateUsername ? <Text style={{ color: "red", position: "absolute", top: 140 }}>{validateUsername}</Text> : null}

          <Form.Item label="Tên nhân viên" name="name" rules={[{ required: true, message: "Tên nhân viên không được để trống" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Số điện thoại"
            name="phone_number"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    return !isNaN(value) && value.charAt(0) === "0" ?
                      Promise.resolve() : Promise.reject(new Error("Số điện thoại không hợp lệ"))
                  }
                  return Promise.resolve();
                },
              },
              {
                max: 11,
                message: 'Số điện thoại không được dài hơn 11 ký tự'
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Phân quyền" name="role">
            <Select
              style={{ width: "100%" }}
              // onChange={handleChange}
              options={[
                { value: "admin", label: "Administrator" },
                { value: "sales", label: "Sales" },
                { value: "accountant", label: "Kế Toán" },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ModalAddEditUser;
