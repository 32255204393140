import React, { Component } from "react";
import { Button, List, Row, Col, Divider, Card, Empty, InputNumber, message, Typography } from "antd";
import PopupCreatePointProgram from "./popupcreatepointprogram";
import api from "api";
import { handleTemplateDescription } from "../../common/text.js";
import PopupCreateExchangPointProgram from "./exchange_point_program/popupcreateexchangepointprogram";
import Point from "./points";
import Expiry from "./expiry";
import PopupCreateGameProgram from "./gameprogram/popupcreategameprogram";

export default class ExchangePointProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointMethodData: [],
      lstGame: [],

      lstRewardProgram: [],
      showProgram: false,
    };
    this.PopupCreateExchangPointProgram = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var result = await api.post("exchangepointprogram/getlatestconfigdetails");
    var point = await api.get("loyaltyconfig/getpoint");
    this.setState({ lstRewardProgram: result, exchange_value: point.exchange_value });
  };

  editRewardProgarm = (item) => {
    var href = "";
    api.post("exchangepointprogram/getconfigbytype", { type: item.type }).then((res) => {
      if (res) {
        href = "/marketing/exchangeprogram/" + item.type + "/" + item.id;
        window.location.href = href;
      }
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Đổi điểm
            </div>
            <div className="description">
              Tạo ra những phần thưởng mà khách hàng của bạn có
              <br></br> thể nhận từ việc sử dụng điểm ,ví dụ voucher freeship <br></br> giảm 1000 cho mỗi 10 điểm
            </div>
            <br></br>
            <Button
              type="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                this.PopupCreateExchangPointProgram.current.open();
              }}
            >
              Tạo Hình Thức Đổi Quà
            </Button>
          </Col>
          <Col span={14}>
            {this.state.show && (this.state.lstRewardProgram == null || this.state.lstRewardProgram.length === 0) ? (
              <Card title={<h5 style={{ marginTop: 10 }}>{"Cách thức tích điểm"}</h5>} style={{ marginLeft: 20 }} bodyStyle={{ height: "25vh" }}>
                <div>
                  <Empty description="Hãy tạo mới chương trình đầu tiên"></Empty>
                  <Row justify="center">
                    <Button
                      onClick={() => {
                        this.PopupCreateExchangPointProgram.current.open();
                      }}
                      style={{ marginTop: 20 }}
                      type="primary"
                    >
                      <i className="fas fa-plus"></i>&nbsp;Tạo mới
                    </Button>
                  </Row>
                </div>
              </Card>
            ) : (
              <Card
                bodyStyle={{ paddingTop: 12, paddingBottom: 12 }}
                title={
                  <Typography.Title level={3} style={{ marginTop: 8 }}>
                    Quà tặng
                  </Typography.Title>
                }
                extra={
                  <Button
                    type="link"
                    href="/marketing/rewardprogram_config/list"
                    // onClick={() => {
                    //   this.props.navigate("/marketing/rewardprogram_config/list");
                    // }}
                  >
                    Xem tất cả
                  </Button>
                }
              >
                <List
                  style={{ background: "white" }}
                  dataSource={this.state.lstRewardProgram}
                  renderItem={(item) => {
                    return (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={
                            <div src={item.logo} style={{ width: 70, height: 70 }}>
                              {item.display_style === "image" ? (
                                <img src={item.logo} style={{ width: "100%", height: "100%" }}></img>
                              ) : (
                                <div style={{ backgroundColor: "#ff0000", padding: 10, width: "100%", height: "90%", display: "flex" }}>
                                  <span style={{ margin: "auto", color: "#ffffff", fontFamily: "cursive" }}>{item.text}</span>
                                </div>
                              )}
                            </div>
                          }
                          title={<div className="listTitle">{item.name}</div>}
                          description={<div className="description">{item.short_description}</div>}
                        />
                        <a
                          onClick={() => {
                            this.editRewardProgarm(item);
                          }}
                          className="edit-link"
                        >
                          Chỉnh sửa
                        </a>
                      </List.Item>
                    );
                  }}
                ></List>
              </Card>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={10}>
            <div className="description" style={{ fontWeight: "bold", color: "#000000", fontSize: 21 }}>
              Quy đổi điểm
            </div>
            <div className="description">
              Khách hàng có thể sử dụng điểm
              <br></br> để thanh toán đơn hàng thay cho tiền.
            </div>
          </Col>
          <Col span={14}>
            <Card title={<h5 style={{ marginTop: 10 }}>{"Quy đổi điểm"}</h5>}>
              <div>Giá trị quy đổi tương đương giữa điểm và tiền:</div>
              <div>
                1 điểm tương đương &nbsp; &nbsp;
                <InputNumber
                  min={0}
                  value={this.state.exchange_value}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onBlur={(e) => {
                    var olddata = this.state.exchange_value;
                    var value = e.target.value;
                    if (value < 0) value = 0;
                    this.setState({ exchange_value: Math.floor(value) });
                    api
                      .post("loyaltyconfig/changexchangepointvalue", { exchange_value: Math.floor(value) })
                      .then((res) => {
                        message.success(res.message);
                      })
                      .catch((err) => {
                        message.error(err.message);
                        this.setState({ exchange_value: olddata });
                      });
                  }}
                ></InputNumber>
                &nbsp;&nbsp; VNĐ
              </div>
            </Card>
          </Col>
        </Row>

        <PopupCreateExchangPointProgram ref={this.PopupCreateExchangPointProgram} />
      </div>
    );
  }
}
