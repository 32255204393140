import { useEffect } from 'react';
import { Button, Modal, Empty, Form, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { renderInput } from "common/custom_field.js";

/**
 * Modal cấu hình app
 * @param {Boolean} isModalOpen bật/tắt modal dựa vào props này
 * @param {Function} handleCancel hàm tắt modal cấu hình app
 * @param {Function} handleCancelModalWayToSendCampaign hàm tắt modal chọn app
 * @param {Object} appConfig app được chọn để cấu hình
 * @param {Number} appConfigIndex vị trí của app trong danh sách app
 * @param {String} modeConfig (add/edit) mode add là khi thêm mới app, mode edit là khi sửa app 
 * @param {Function} selectApp chọn app khi mode là add
 * @param {Function} formConfig mẫu form config của app 
 * @param {Function} value 
 * @param {Function} onChange 
 * @author huy 
 */
const ModalAppConfig = ({
  isModalOpen,
  handleCancel,
  handleCancelModalWayToSendCampaign,
  appConfig,
  appConfigIndex,
  modeConfig,
  selectApp,
  formConfig,
  value,
  onChange
}) => {
  // console.log('appConfig', appConfig);
  // console.log('formConfig', formConfig);

  const [form] = Form.useForm();

  /**
   * Hàm xử lý khi lưu cấu hình app
   * @authorhuy
   */
  const saveConfig = () => {
    var newDataPost = value;
    if (modeConfig === 'add') {
      selectApp(appConfigIndex);

      newDataPost.push({
        id: appConfig.id,
        parameters: [
          { type: "id", value: form.getFieldsValue()[formConfig[0].value] }
        ]
      });
    } else {
      newDataPost[appConfigIndex].parameters[0].value = form.getFieldsValue()[formConfig[0].value];
    }

    onChange(newDataPost);

    //đóng modal app config
    handleCancel();
    setTimeout(() => {
      //đóng modal chọn app
      handleCancelModalWayToSendCampaign();
    }, 250);

  }

  const renderConfigForm = () => {
    return formConfig.map((config) => {
      var rules = [];

      if (config.required == true)
        rules = [
          {
            required: true,
            type: "string",
            message: `${config.label} là trường bắt buộc!`,
          },
        ];

      var result = (
        <Form.Item name={config.value} label={config.label} rules={rules} >
          {/* {renderInput(config)} */}
          <Select
            options={config.options}
            onChange={onChangeSelectConfigApp}
          />
        </Form.Item>
      );
      // form.setFieldValue(config.value, config.options[0].value)
      return result;
    })
  }

  const onChangeSelectConfigApp = (value) => {
  }

  return (
    <Modal
      title={"Cấu hình " + appConfig.name}
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button>
          <Link to={'/apps/' + appConfig.id} target='_blank'>
            Cấu hình
          </Link>
        </Button>,
        <Button onClick={handleCancel}>Thoát</Button>,
        formConfig.length > 0 && (<Button type='primary' onClick={() => form.submit()}>Lưu</Button>)
      ]}
    >
      <Form
        name="modalConfig"
        layout="vertical"
        form={form}
        onFinish={saveConfig}
        onFinishFailed={(err) => console.log(err)}>
        {formConfig.length > 0 ? renderConfigForm() : <Empty />}
      </Form>
    </Modal>
  )
}

export default ModalAppConfig