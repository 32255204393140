import { Badge, Button, Dropdown, Form, Input, Select, Space, message } from "antd";
import { MoreOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AntTable from "components/common/AntTable";
import React, { useEffect, useState } from "react";
import SyncModal from "./SyncModal";
import api from "api";
import numeral from "numeral";

function ProductTab({ totalProduct }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customSearch, setCustomSearch] = useState();
  const [form] = Form.useForm();
  const refTable = React.createRef();
  const columns = [
    {
      title: "Ảnh",
      dataIndex: "images",
      key: "images",
      render: (images, record, index) => {
        return (
          <div
            style={{
              width: 80,
              height: 80,
              backgroundImage: `url(${images && images.length > 0 ? images[0]?.src : ""})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Biến thể",
      dataIndex: "variants",
      key: "variants",
      render: (variants, record, index) => {
        // if(variations.length === 0){
        //     return 1
        // } else {
        return variants.length;
        // }
      },
    },
    {
      title: "Mã",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "variants",
      render: (variants, record, index) => {
        return numeral(record.variants[0].price).format("0,0");
      },
    },
    {
      title: "Kết nối với Loyalty",
      dataIndex: "is_link",
      key: "is_link",
      render: (is_link, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        if (is_link) {
          return <Badge color="green" text="Đã kết nối APP" />;
        } else {
          return <Badge color="red" text="Chưa kết nối APP" />;
        }
      },
    },
    {
      // title: "Kết nối với Loyalty",
      dataIndex: "slug",
      key: "slug",
      render: (slug, record, index) => {
        // var findItem = linkList.find((item) => {
        //   return item.woo_product_id === record.id;
        // });
        return (
          <Dropdown
            trigger={"click"}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Đồng bộ lại",
                },
              ],
              onClick: (e) => {
                syncHaravanProduct(record);
              },
            }}
            placement="bottomLeft"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const syncHaravanProduct = (haravanProduct) => {
    api
      .post("app/haravan/config/syncproduct", haravanProduct)
      .then((res) => {
        refTable.current.reload();
        // checkIsLink(
        //   listWooProduct.map((item) => {
        //     return { woo_product_id: item.id };
        //   }),
        //   listWooProduct
        // );
      })
      .catch((err) => {
        if (err?.message) {
          message.error(err.message);
        } else {
          message.error("Đồng bộ thất bại");
        }
      });
  };

  const openSyncModal = () => {
    setIsModalOpen(true);
  };

  const onFinish = () => {
    var data = form.getFieldsValue();
    setCustomSearch(data);
  };

  useEffect(() => {
    if (customSearch) {
      refTable.current.reload();
    }
  }, [customSearch]);
  return (
    <div>
      <Form form={form} name="basic" onFinish={onFinish} autoComplete="off">
        <Space>
          <Form.Item name="text">
            <Input placeholder="Tên sản phẩm hoặc mã sản phẩm" style={{ width: "calc(100vw - 376px)" }} />
          </Form.Item>

          <Form.Item>
            <Button style={{ width: 112 }} icon={<SearchOutlined />} type="primary" htmlType="submit">
              Tìm kiếm
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: 112 }} icon={<SyncOutlined />} type="primary" ghost onClick={openSyncModal}>
              Đồng bộ
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <AntTable
        columns={columns}
        autoLoad={true}
        customSearch={customSearch}
        tableName={"tbHaravanProduct"}
        ajax="app/haravan/config/listallproducts"
        ref={refTable}
        rowKey={(record) => record.id}
      ></AntTable>
      {isModalOpen && <SyncModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} totalProduct={totalProduct} />}
    </div>
  );
}

export default ProductTab;
