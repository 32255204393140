import React, { Component } from "react";
import PopupPassWord from "./popup_password";
import api from "api";
import "./style.css";
import { Button, Form, message, Input, Upload, Image, Avatar, Card, Space } from "antd";
import UploadImagePicker from "components/picker/image_picker";
import { PlusOutlined } from '@ant-design/icons';
import { getPath } from 'common/file.js';
import { Col, Row } from 'antd';

export default class ProFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data: {},
            avatar: "",
        }
        this.PopupPassWord = React.createRef();
        this.Form = React.createRef();
    }
    componentDidMount = () => {

        this.getProfile();
    }

    getProfile = async () => {
        api.post("profile/get").then((res) => {
            this.setState({
                data: res,
                loadData: true,
            }, e => {
                this.Form.current.setFieldsValue(res)
            });
        });
    };






    onFinish = (values) => {


        api.post("profile/save", { data: values }).then(res => {
            message.success("Lưu thành công")
            // this.props.getData(this.state.avatar, this.state.data.first_name, this.state.data.last_name,);
        }).catch(err => {
            if (err.code === 269) {
                message.error("Không tìm thấy user")
            }
            else {
                message.error("Có lỗi xảy ra")
            }

        })
    }

    render() {

        return (
            <>

                <Card>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        ref={this.Form}
                        onFinish={this.onFinish}
                    >


                        <Form.Item
                            name="avatar"
                            label="Hình đại diện"
                            rules={[
                                {
                                    required: true,
                                    message: "Bạn chưa chọn logo!",
                                },
                            ]}
                        >
                            <UploadImagePicker crop={{ quality: 1, aspect: 1 }} size={{ width: 64, height: 64 }} tags={["logo"]} hideLibary ></UploadImagePicker>
                        </Form.Item>

                        <Row gutter={6}>
                            <Col span={12}>
                                <Form.Item

                                    label="Họ tên đệm"
                                    name="first_name"
                                    rules={[{ required: true, message: 'Please input your firstname!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Tên"
                                    name="last_name"
                                    rules={[{ required: true, message: 'Please input your lastname!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item

                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item

                                    label="Số điện thoại"
                                    name="phone"
                                    rules={[{ required: true, message: 'Please input your phone!' }]}
                                >
                                    <Input />
                                </Form.Item></Col>
                        </Row>











                        <div style={{ display: "flex", justifyContent: "flex-end" }} >

                            <Space>
                                <Button type="primary" onClick={() => { this.PopupPassWord.current.open(); }}>
                                    Đổi mật khẩu
                                </Button>

                                <Button type="primary" onClick={() => this.Form.current.submit()}>Lưu</Button>
                            </Space>

                        </div>

                    </Form>

                </Card>



                <PopupPassWord ref={this.PopupPassWord}></PopupPassWord>
            </>
        )
    }
}

