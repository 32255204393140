import React from "react";
import { Radio, Space, Upload, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import api from "api";

export default class HTMLEditorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        uselogo: "system",
        image: this.props.system,
      },
    };
    this.modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },
    };
    this.ReactQuill = React.createRef();
  }

  componentDidMount = () => {
    console.log("this.props.value", this.props.value);
    this.setState({ data: this.props.value });
  };

  componentDidUpdate = (preProps, prevState) => {
    if (preProps.value != this.props.value) {
      this.setState({ data: this.props.value });
    }
  };

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleChange(html) {
    this.setState({ data: html }, () => {
      this.onChange(this.state.data);
    });
  }
  imageHandler(data) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    var path = null;
    console.log("this.quill", this.quill);
    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        var form = new FormData();
        if (file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");
        form.append("file", file);
        await api
          .post("File/upload", form)
          .then((data) => {
            //   var editor = this.quill.editor
            this.quill.insertEmbed(this.quill.getSelection(), "image", data.path);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.warn("Chỉ upload hình ảnh");
      }
    };
  }

  render() {
    return (
      <div>
        <ReactQuill
          style={this.props.style}
          ref={this.ReactQuill}
          value={this.state.data ? this.state.data : ""}
          modules={this.modules}
          onChange={(e) => {
            this.handleChange(e);
          }}
        />
      </div>
    );
  }
}
