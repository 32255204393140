import React, { Component } from "react";
import { Button, Input, Form, message, Modal, Col, Row, Select, Checkbox } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import api from "api";
import { toNoUnicode } from "common/text";
import SelectNew from "components/picker/dropdown_picker";
import { phone_regex } from "common/regex";

export default class ChangeUserPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.form = React.createRef();
    this.logout = React.createRef();
  }

  componentDidMount() {}

  open = async (user_name) => {
    this.setState({ user_name: user_name, visible: true }, () => {
      var data = {
        password: "",
        confirm: "",
        logout: true,
      };
      this.form.current.setFieldsValue(data);
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    values.user_name = this.state.user_name;
    api
      .post("user/changepassword", values)
      .then((resp) => {
        console.log(values.logout);
        if (values.logout) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        message.success("Đổi mật khẩu thành công thành công");
        this.close();
      })
      .catch((error) => {
        this.close();
        message.error("Có lỗi xảy ra khi lưu");
      });
  };

  close = () => {
    console.log("close");
    this.setState({ visible: false });
  };

  onSubmit = () => {
    if (this.form) if (this.form.current && !this.state.existed_username) this.form.current.submit();
  };

  render() {
    return (
      <Modal
        title={"Thay đổi mật khẩu"}
        open={this.state.visible}
        onCancel={this.handleCancel}
        maskClosable={false}
        width={"50%"}
        footer={[
          <>
            <Button style={{ marginRight: 10 }} type="default" onClick={this.handleCancel}>
              <i className="fas fa-window-close" style={{ marginRight: 10 }}></i> Hủy
            </Button>
            <Button onClick={this.onSubmit} style={{ marginRight: 10 }} type="primary" key="submit" htmlType="submit">
              <i className="fas fa-save" style={{ marginRight: 10 }}></i> Thay đổi mật khẩu
            </Button>
          </>,
        ]}
      >
        <Form layout="vertical" onFinish={this.onFinish} id="user-form" ref={this.form}>
          <Form.Item
            name="password"
            label="Mật khẩu mới"
            validateTrigger={"onBlur"}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập mật khẩu!",
              },
              {
                validator: (rule, value) => {
                  return new Promise((resolve, error) => {
                    if (value.length > 50) {
                      error();
                    } else {
                      resolve();
                    }
                  });
                },
                message: "Mật khẩu không được dài quá 50 ký tự!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Xác nhận mật khẩu"
            validateTrigger={"onBlur"}
            rules={[
              {
                required: true,
                message: "Bạn chưa xác nhận mật khẩu!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item hidden>
            <Button htmlType="submit" loading={this.state.btnLoading}></Button>
          </Form.Item>
          <Form.Item name="logout" valuePropName="checked">
            <Checkbox>Đăng xuất</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
