import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Tabs, Checkbox, DatePicker } from "antd";
import api from "api";
import AntTable from "components/common/AntTable";
import moment from "moment";
import { formatL } from "../../../common/date_time";
import { formatCurrency } from "components/common/format/number";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const { Search } = Input;
const { Option } = Select;

export default class PointHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      filter: {
        query: {
          $and: [{ customer_id: this.props.id }],
        },
      },

      lstApp: [],
      tabKey: "addpoint",
      gotLstApp: false,
      isCreateDate: false,
    };
    this.tbAdd = React.createRef();
    this.tbUse = React.createRef();
  }

  componentDidMount = () => {
    api
      .post("shop/getinstalledappbycategories", { categories: ["crm"] })
      .then((res) => {
        this.setState({ lstApp: res, gotLstApp: true });
      })
      .catch((err) => {});
  };

  onSelectFilterChange = (key, isSelected) => {
    // var filter = { ...this.state.filter };
    // if (isSelected) {
    //     return;
    // }
    // switch (key) {
    // }
  };

  delete = (id) => {
    api
      .post("point_history/cancelpointhistory", { id: id })
      .then((res) => {
        message.success("Hoàn tác thành công", 3);
        this.state.tabKey === "addpoint" ? this.tbAdd.current.reload() : this.tbUse.current.reload();
      })
      .catch((err) => {
        if (err.status != 999) message.error(err.message, 3);
        else message.error("Hoàn tác không thành công", 3);
      });
  };

  getColumn = () => {
    var columns = [
      {
        title: "Ngày tạo",
        dataIndex: "create_date",
        key: "create_date",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.create_date);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },

      {
        title: "Điểm",
        dataIndex: "change_point",
        key: "change_point",
        render: (text, record, index) => {
          return <div>{formatCurrency(record.change_point)}</div>;
        },
        sorter: true,
      },

      {
        title: "Mô tả",
        dataIndex: "description",
        key: "description",
      },

      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              {!record.is_cancel && record.change_point > 0 && (
                <Popconfirm title="Bạn có chắc muốn hoàn tác không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                  <Button type="link">Hoàn tác</Button>
                </Popconfirm>
              )}
            </>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    return (
      <>
        {this.state.gotLstApp && (
          <>
            <div>
              <Card>
                {this.state.tabKey === "addpoint" && (
                  <AntTable
                    columns={this.getColumn()}
                    customSearch={this.state.filter}
                    tableName={"tbaddpointhistory"}
                    ajax="point_history/getlistadd"
                    ref={this.tbAdd}
                    pagination={false}
                  ></AntTable>
                )}
              </Card>
            </div>
          </>
        )}
      </>
    );
  }
}
