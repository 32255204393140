import { Modal, Button } from "antd";
import api from "api";
import React from "react";
import AppConfig from "./popup_app_config";

export default class PopupSelectApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lst: [],
      lstPicker: [],
      id: -1,
      visible: false,
      lstData: [],
    };
    this.AppConfig = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.lstPicker) var lstData = this.props.lstPicker;
    this.setState({ lstData: lstData });
  };

  open = (id) => {
    if (id != -1 && id != null) {
      var lstData = this.state.lstData;
      if (this.props.lstPicker) lstData = this.props.lstPicker;
      this.setState({ visible: true, lst: this.props.lst, lstData: lstData, id: id });
    } else {
      this.setState({ visible: true, lst: this.props.lst, lstData: this.props.lstPicker });
    }
  };
  renderItem(item) {
    return (
      <div
        onClick={() => {
          if (this.state.lstData.findIndex((x) => x.id === item.id) === -1) this.clickItem(item);
        }}
        style={
          this.state.lstData && this.state.lstData.findIndex((x) => x.id === item.id) > -1
            ? {
                height: "230px",
                width: "230px",
                boxShadow: "#bdbddb 0px 13px 27px -5px, #a6a6a6 0px 8px 16px -8px",
                border: "1px solid #dfdfdf",
                margin: "0 10px 30px 10px",
                padding: "15px",
                position: "relative",
                opacity: 0.3,
              }
            : {
                height: "230px",
                width: "230px",
                boxShadow: "#bdbddb 0px 13px 27px -5px, #a6a6a6 0px 8px 16px -8px",
                border: "1px solid #dfdfdf",
                margin: "0 10px 30px 10px",
                cursor: "pointer",
                padding: "15px",
                position: "relative",
              }
        }
      >
        <div>
          <img src={item.logo} style={{ width: "70px", height: "70px" }}></img>
        </div>
        <div style={{ marginTop: "20px", fontSize: "20px", fontWeight: "bold" }}>{item.name}</div>
        <div style={{ marginTop: "20px", fontSize: "15px", color: "#c9c9c9" }}>{item.description}</div>
      </div>
    );
  }

  clickItem = (value) => {
    var lst = [];
    lst.push(value);
    this.setState({ lstPicker: lst }, () => {
      if (lst.length > 0) this.AppConfig.current.open(lst[0].id, 0, lst[0], value.config_url);
    });
  };

  onChangeParameters = (value, app_index) => {
    var lst = this.state.lstPicker;
    lst[app_index].parameters = value;
    this.setState({ lstPicker: lst });
  };

  onOk = () => {
    var lst = this.state.lstPicker;
    if (this.state.id != -1 && this.state.id != null) {
      this.props.finish(lst, this.state.id);
    } else this.props.finish(lst);

    this.setState({ visible: false });
  };

  onCancel = () => {
    if (this.state.id != -1 && this.state.id != null) {
      //mode này dùng cho màn hình tích điểm
      this.setState({ visible: false, lstPicker: [] }, () => {
        this.onOk();
      });
    } else {
      // if (this.props.campaign_id !== "") {
      //     api.post("campaign/getbyid", { id: this.props.campaign_id }).then(res => {
      //         this.setState({ visible: false, lstPicker: res.way_to_send })
      //     })
      // }
      // else {
      //     this.setState({ visible: false, lstPicker: [] })
      // }
      this.setState({ visible: false });
    }
  };

  render() {
    var lst = this.state.lst;
    return (
      <>
        <Modal
          title="Chọn kênh gửi thông tin"
          open={this.state.visible}
          onCancel={() => {
            this.onCancel();
          }}
          width={"56%"}
          footer={[
            <Button
              onClick={() => {
                this.onCancel();
              }}
              type="primary"
            >
              Thoát
            </Button>,
          ]}
          maskClosable={false}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <a
              style={{
                height: "230px",
                width: "230px",
                display: "flex",
                boxShadow: "#bdbddb 0px 13px 27px -5px, #a6a6a6 0px 8px 16px -8px",
                border: "1px solid #dfdfdf",
                margin: "0 10px 30px 10px",
                cursor: "pointer",
              }}
              href="/apps"
              target="_blank"
            >
              <div style={{ margin: "auto" }}>
                <div style={{ border: "5px solid #969696", height: "50px", width: "50px", fontSize: "30px", display: "flex", color: "#969696", margin: "auto" }}>
                  <i className="fa fa-plus" style={{ margin: "auto" }}></i>
                </div>
                <span style={{ color: "#898585", marginTop: "15px" }}>Thêm mới</span>
              </div>
            </a>
            {lst.map((item) => {
              return this.renderItem(item);
            })}
          </div>
        </Modal>
        <AppConfig onChangeParameters={this.onChangeParameters} close={this.onCancel} ok={this.onOk} ref={this.AppConfig}></AppConfig>
      </>
    );
  }
}
