import React, { useState } from "react";
import { Modal, Alert, Typography, Space, Button } from "antd";
import api from "api";
import moment from "moment";

const { Text } = Typography;
function TestWebhookModal({ isModalOpen, setIsModalOpen, webhook, setResult, mode }) {
  const [content, setContent] = useState(
    <Space direction="vertical" style={{ width: "100%" }}>
      <Text>Dữ liệu mẫu gửi về server của bạn</Text>
      <Alert message="Thông tin" description="Kết quả gửi sẽ được trả về tại đây" type="info" showIcon />
    </Space>
  );
  const [loading, setLoading] = useState(false);
  const handleOk = async (event) => {
    setLoading(true);
    var payload = {};
    if (event === "order_create") {
      payload = {
        id: "Z0000001",
        created_on: "2024-05-07T07:24:35Z",
        created_on_date: "2024-05-07",
        currency: "VND",
        email: null,
        financial_status: "pending",
        order_status: "new",
        note: null,
        order_note: null,
        order_delivery_note: null,
        source_name: "ZALOMINIAPP",
        sub_total_price: 22000,
        total_discounts: null,
        total_line_items_price: 22000,
        shipping_amount: 20000,
        total_price: 42000,
        shipping_address: {
          address1: "Hhb",
          address2: null,
          city: "",
          first_name: "",
          last_name: "",
          phone: "0288888888",
          province: "Tỉnh Bắc Kạn",
          province_code: "BK",
          zip: "",
          country_code: "VN",
          country_name: "Vietnam",
          company: null,
          country: "Vietnam",
          name: "Test",
          district: "Huyện Pác Nặm",
          district_code: "120",
          ward: "Xã Xuân La",
          ward_code: "7130",
        },
        discount_codes: [],
        client_details: null,
        ignore_process: null,
        fb: {},
        app_id: null,
        minus_points: 0,
        minus_points_value: 0,
        line_items: [
          {
            price: 11000,
            product_id: "415e34e4-021a-4f70-b38a-1e1f46b13725",
            quantity: 1,
            sku: "AKD01",
            variant_id: "28448bdf-50be-4b19-979c-1b6a6ca407dc",
            variant_title: "áo khoát da Mặc định",
            name: "áo khoát da",
            gift_card: false,
            total_discount: 0,
          },
          {
            price: 11000,
            product_id: "869fb14b-30fd-4ebd-9adc-dfc7fefb037b",
            quantity: 1,
            sku: "AKD02-XA-L",
            variant_id: "a95d187b-94cc-4b0f-9466-bcca3eaee490",
            variant_title: "áo khoát da có biến thể xanh l",
            name: "áo khoát da có biến thể",
            gift_card: false,
            total_discount: 0,
          },
        ],
        customer_id: "8UXA24",
        updated_date: "2024-05-07T07:24:35.277Z",
      };
    } else if (event === "payment_create") {
      payload = {
        loyaltyOrderId: "Z0000369",
        transTime: "2024-04-01T08:49:47.406Z",
        createdAt: "2024-04-01T08:49:47.406Z",
        amount: 420000,
        method: "VNPAY_SANDBOX",
        orderId: "228840360617010007853759543_1711961305682",
        transId: "240401_1548256822072095934042206812655",
        appId: "4092782077303332957",
        extradata: "%7B%22myTransactionId%22%3A%22Z0000369%22%7D",
        resultCode: 1,
        description: "Thanh%20to%C3%A1n%20%C4%91%C6%A1n%20h%C3%A0ng%20Z0000369",
        message: "Giao dịch thành công",
        paymentChannel: "VNPAY_SANDBOX",
      };
    } else {
      payload = {
        list_service: [
          {
            id: "0878b6ac-ae77-4da6-80e6-7967438ca6c9",
            name: "Khám răng",
            category_id: ["efa000ad-97cb-4a71-9cd1-4d90691b30d4"],
            images: ["https://storage.googleapis.com/cubeloyalty_asia/violet/7190a519-66e9-4ba2-871c-a5f60a24fd0b.png"],
            is_variant: false,
            variants: [
              {
                name: "Mặc Định",
                id: "01",
                isImage: false,
                options: [
                  {
                    id: 1,
                    value: "Mặc Định",
                    image: "",
                  },
                ],
                position: 1,
              },
            ],
            created_date: null,
            created_by: "test",
            updated_date: null,
            updated_by: null,
            is_delete: false,
            deleted_by: null,
            deleted_date: null,
            original_price_range: {
              original_price_min: 10000,
              original_price_max: 10000,
            },
            sale_price_range: {
              sale_price_min: 10000,
              sale_price_max: 10000,
            },
            is_active: true,
            is_require_contact: false,
            skus: [
              {
                variant_id: "56f2b065-19c3-4068-9108-f6313ec3c98e",
                variant_title: "Khám răng",
                quantity: 1,
                option1: 1,
                option2: null,
                option3: null,
                original_price: 10000,
                sale_price: 10000,
                sku: null,
                is_active: true,
              },
            ],
            search_text: "ao so mi",
            max_discount: 0,
          },
          {
            _id: "6653bbc708c004f2f9412664",
            id: "0878b6ac-ae77-4da6-80e6-7967438ca6c0",
            name: "Khám răng",
            category_id: ["efa000ad-97cb-4a71-9cd1-4d90691b30d4"],
            images: ["https://storage.googleapis.com/cubeloyalty_asia/violet/7190a519-66e9-4ba2-871c-a5f60a24fd0b.png"],
            is_variant: false,
            variants: [
              {
                name: "Mặc Định",
                id: "01",
                isImage: false,
                options: [
                  {
                    id: 1,
                    value: "Mặc Định",
                    image: "",
                  },
                ],
                position: 1,
              },
            ],
            created_date: null,
            created_by: "test",
            updated_date: null,
            updated_by: null,
            is_delete: false,
            deleted_by: null,
            deleted_date: null,
            original_price_range: {
              original_price_min: 10000,
              original_price_max: 10000,
            },
            sale_price_range: {
              sale_price_min: 10000,
              sale_price_max: 10000,
            },
            is_active: true,
            is_require_contact: false,
            skus: [
              {
                variant_id: "56f2b065-19c3-4068-9108-f6313ec3c98e",
                variant_title: "Khám răng",
                quantity: 1,
                option1: 1,
                option2: null,
                option3: null,
                original_price: 10000,
                sale_price: 10000,
                sku: null,
                is_active: true,
              },
            ],
            search_text: "kham rang",
            max_discount: 0,
          },
        ],
        location: {
          id: "97a610a6-d516-4631-acc1-9af8bfbb106a",
          images: [],
          name: "Nha khoa Tâm Sài Gòn",
          address: "Số 7 Trần Quý Cáp",
          phone: "0797225886",
          map_image: "",
          open_at: "07:00 - 21:00",
          province: "Tỉnh Lâm Đồng",
          province_code: "LDD",
          zip: "",
          country_code: "VN",
          country_name: "Vietnam",
          country: "Vietnam",
          district: "Thành phố Đà Lạt",
          district_code: "391",
          ward: "Phường 9",
          ward_code: "4498",
        },
        order_time: "2024-05-27T12:00:00.000Z",

        name: "Dbb",
        phone: "020393833883",
        customer_id: "8UXA24",
        created_on: "2024-05-27T09:50:41.608Z",
        status: "new",
        id: "0000010",
      };
    }
    await api
      .post(
        "app/miniapp/config/sendtourl",
        event === "order_create"
          ? { ...webhook, order: payload, shop_name: localStorage.getItem("shop_name"), event: event, mode: "new" }
          : event === "payment_create"
          ? { ...webhook, payment: payload, shop_name: localStorage.getItem("shop_name"), event: event, mode: "new" }
          : { ...webhook, service: payload, shop_name: localStorage.getItem("shop_name"), event: event, mode: "new" }
      )
      .then((res) => {
        setLoading(false);
        setContent(
          <Space direction="vertical" style={{ width: "100%" }}>
            <Text>Dữ liệu mẫu gửi về server của bạn</Text>
            <Alert message="Gửi thành công" description={JSON.stringify({ status: "success", data: res })} type="success" showIcon />
          </Space>
        );
      })
      .catch((error) => {
        setLoading(false);
        setContent(
          <Space direction="vertical" style={{ width: "100%" }}>
            <Text>Dữ liệu mẫu gửi về server của bạn</Text>
            <Alert message="Gửi thất bại" description={JSON.stringify({ status: "error", error: error })} type="error" showIcon />
          </Space>
        );
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    setContent(
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text>Dữ liệu mẫu gửi về server của bạn</Text>
        <Alert message="Thông tin" description="Kết quả gửi sẽ được trả về tại đây" type="info" showIcon />
      </Space>
    );
  };
  return (
    <Modal
      confirmLoading={loading}
      title="Test webhook data"
      open={isModalOpen}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={
        mode === "order"
          ? [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  handleOk("order_create");
                }}
              >
                Gửi order
              </Button>,
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  handleOk("payment_create");
                }}
              >
                Gửi payment
              </Button>,
            ]
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  handleOk("service_create");
                }}
              >
                Gửi lịch hẹn
              </Button>,
            ]
      }
      // cancelText="Thoát"
      // okText="Gửi"
    >
      {content}
    </Modal>
  );
}

export default TestWebhookModal;
