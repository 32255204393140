import React, { Component } from "react";
import { Button, List, Row, Col, Form, Input, message, Card, Typography } from "antd";
import api from "api";
import UploadImagePicker from "components/picker/image_picker";

export default class Point extends Component {
  constructor(props) {
    super(props);
    this.state = {
      points: {},
      edit: false,
      logo: "",
      plural: "",
      singular: "",
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    var point = await api.post("loyaltyconfig/getpoint");
    if (point != null) {
      this.setState(
        {
          point: point,
          logo: point.logo,
          plural: point.name.plural,
          singular: point.name.singular,
        },
        () => {
          this.form.current.setFieldsValue({
            plural: point.name.plural,
            singular: point.name.singular,
            logo: point.logo,
          });
        }
      );
    }
  };

  onChangeButton = () => {
    this.form.current.submit();
    this.setState({
      edit: false,
    });
  };

  onClickButton = () => {
    this.setState({
      edit: true,
    });
  };
  finish = (values) => {
    api
      .post("loyaltyconfig/savepoint", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
        this.setState({ data: {} });
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };

  onChange = (value, name) => {
    var state = this.state;
    state[name] = value;
    this.setState(state);
  };

  render() {
    return (
      <Card
        bodyStyle={{ paddingTop: 12, paddingBottom: 12 }}
        title={
          <Typography.Title level={3} style={{ marginTop: 8 }}>
            Tên điểm
          </Typography.Title>
        }
        extra={
          <Button type="link" onClick={this.onClickButton}>
            Edit
          </Button>
        }
      >
        <List style={{ background: "white" }}>
          {/* <br />             */}
          <Form name="basic" layout="vertical" colon="false" ref={this.form} onFinish={this.finish}>
            <Col xs={{ span: 8 }} lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                name="logo"
                label="logo"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa chọn logo!",
                  },
                ]}
              >
                <UploadImagePicker
                  crop={{ quality: 1, aspect: 1 }}
                  size={{ width: 64, height: 64 }}
                  tags={["logo"]}
                  disabled={this.state.edit ? false : true}
                  onChange={(value) => {
                    this.onChange(value, "logo");
                  }}
                ></UploadImagePicker>
              </Form.Item>
            </Col>
            <Form.Item label="Số nhiều" name="plural">
              <Input
                disabled={this.state.edit ? false : true}
                onChange={(e) => {
                  this.onChange(e.target.value, "plural");
                }}
              />
            </Form.Item>
            <span>Ví dụ: Bạn đã tích lũy được 5 </span>
            {this.state.logo != null && this.state.logo != "" && <img src={this.state.logo} style={{ width: 18, height: 18, marginBottom: 4 }} />}
            <span> {this.state.plural}</span>

            <br />

            <Form.Item label="Số ít" name="singular">
              <Input
                disabled={this.state.edit ? false : true}
                onChange={(e) => {
                  this.onChange(e.target.value, "singular");
                }}
              />
            </Form.Item>
            <span>Ví dụ: Bạn đã tích lũy được 1 </span>
            {this.state.logo != null && this.state.logo != "" && <img src={this.state.logo} style={{ width: 18, height: 18, marginBottom: 4 }} />}
            <span> {this.state.singular}</span>
          </Form>
          {this.state.edit && (
            <div style={{ marginLeft: "80%" }}>
              <Button
                style={{ margin: 10 }}
                onClick={() => {
                  this.setState({ edit: false });
                }}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" onClick={this.onChangeButton}>
                Save
              </Button>
            </div>
          )}
        </List>
      </Card>
    );
  }
}
