import { useState, memo, useEffect } from "react";
import { Button, Col, Row,Tag,Image, Typography,Descriptions,Card,Tooltip,Skeleton, message} from "antd";
import { CopyTwoTone } from "@ant-design/icons";
import api from "api";
import numeral from "numeral"
import { blue } from "@ant-design/colors";
const { Title, Text } = Typography;
const DetailTransaction = ({data,modelTitle,PropslistBank}) => {
  const [listBank,setListBank]=useState([])
  const [selectedBank,setSelectedBank]=useState({})
  const [loading,setLoading]=useState(true)
  // lấy danh sách bank
  const getBanks= ()=>{
    api.post("bank/getbank").then((res)=>{
        setListBank(res)
        setLoading(false)
    }).catch((err)=>{
        setListBank([])
    })
  }
  const copypass=(value)=>{
    navigator.clipboard.writeText(value);
    message.success("Sao chép thành công");
  }
  useEffect(() => {
    if(PropslistBank)
    {   
      setListBank(PropslistBank)
      setLoading(false)
    }
    else
    getBanks()
  }, []);
  useEffect(() => {
    var find = listBank.find((item) => item.bank_name === data?.bank);
    setSelectedBank(find)
  }, [data,listBank]);
  return (
      <Card type="inner" title={modelTitle} style={{ width: "100%", textAlign: "start" }} >
        <Skeleton loading={loading} active avatar>
            <Row>
              <Col span={24} lg={16}>
                <Descriptions size="small" bordered>
                  <Descriptions.Item label="Chủ tài khoản" span={12}>
                    {selectedBank?.account_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Số tài khoản" span={12}>
                    <Text>{selectedBank?.account_number}</Text>
                    <Tooltip title="Copied!" placement="right" trigger="click">
                      <Button
                        style={{ padding: 0, marginLeft: 4, border: 0 }}
                        onClick={()=>copypass(selectedBank?.account_number)}
                        icon={<CopyTwoTone />}
                      ></Button>
                    </Tooltip>
                  </Descriptions.Item>
                  <Descriptions.Item label="Chi nhánh" span={12}>
                    {selectedBank?.bank_name}
                    <br></br>
                    {selectedBank?.bank_branch}
                  </Descriptions.Item>
                  <Descriptions.Item label="Số tiền" span={12}>
                    <Text strong style={{ color: blue.primary }}>
                      {numeral(data?.amount?.value).format("0,0")}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Mã thanh toán" span={12}>
                    <Tag color="#40A9FF"><Title level={5} style={{color:"#fff",marginBottom:0}}>{data.code_id}</Title></Tag>
                    <Tooltip title="Copied!" placement="right" trigger="click">
                      <Button
                        style={{ padding: 0, border: 0 }}
                        onClick={()=>copypass(data?.code_id)}
                        icon={<CopyTwoTone />}
                      ></Button>
                    </Tooltip>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={24} lg={8} style={{ padding: 8 }}>
                <Image
                  width={"100%"}
                  preview={false}
                  src={`https://img.vietqr.io/image/${selectedBank?.bank_bin}-${selectedBank?.account_number}-compact.jpg?amount=${data?.amount?.value}&addInfo=${data?.code_id}`}
                ></Image>
              </Col>
            </Row>
        </Skeleton>
              
    </Card>
  );
};

export default memo(DetailTransaction);