import React, { Component } from "react";
import api from "api"
import Customer from "../customer"

export default class Page1 extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [] }
        this.Customer = React.createRef();
        this.popupAddEdit = React.createRef();
    }


    test1 = _ => {
        api.get("test/list").then(resp => {
            this.setState({ data: resp.data });
        })
    }

    test2 = _ => {
        api.get("test/insert").then(resp => {
            this.setState({ data: resp.data });
        })
    }
    
  addNew = () => {
    this.popupAddEdit.current.open('add');
  };


    popupCustomer = () => {
        this.Customer.current.open();
    }

    render() {

        return (
            <div>
                {/* Dây là page số 1
                <Space>
                    <Button onClick={this.test1}>Test Api 1</Button>
                    <Button onClick={this.test2}>Test Api 2</Button>
                    <Button onClick={() => this.popupCustomer()}>Test Customer</Button>
                    <Button onClick={() => this.addNew()}>Test AddNewCustomer</Button>

                </Space> */}
                <Customer ref={this.Customer}></Customer>
                {/* <CustomerField></CustomerField> */}
                {/* <div>
                    {JSON.stringify(this.state.data)}
                </div>
                <AddNewEdit ref={this.popupAddEdit}></AddNewEdit> */}

                
            </div>
        );
    }
}
