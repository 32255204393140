import React, { Component } from "react";
import api from "api";
import { Input, Button, Form, Checkbox, Row, Col, message, Modal } from "antd";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        type: "",
        gmail: {},
        microsoft: {},
        smtp: {
          secure: true,
          auth: {
            username: "",
            password: "",
          },

          port: "",
          mailserver: "",
        },
        name: "",
        id: "",
      },
      type: "",
      mode: "",
    };
    this.inputType = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount = async () => {
    let url = window.location.href;
    if (url.includes("mysapo")) {
      await this.sapoVerification(url);
    }
  };

  //Sapo
  //Begin
  //======Begin: Authencation=======

  sapoVerification = async (urlcallback) => {
    let url = new URL(urlcallback);
    let code = url.searchParams.get("code");
    let hmac = url.searchParams.get("hmac");
    let store = url.searchParams.get("store");

    let sapoConnection = {
      code: code,
      hmac: hmac,
      store: store,
      token: "",
      type: "sapo",
    };

    api
      .post("app/sapo/sapoconnection/getaccesstoken", { data: sapoConnection })
      .then((rest) => {
        sapoConnection.token = rest.access_token;
        api
          .post("app/sapo/sapoconnection/addnewedit", { data: sapoConnection })
          .then((rest) => {
            message.success("Đồng bộ thành công", 3);
            window.location = "/app/sapo";
          })
          .catch((err) => {
            message.error("Đồng bộ thất bại", 3);
          });
      })
      .catch((err) => {
        message.error("Đồng bộ thất bại", 3);
        return;
      });
  };
  //======End: Authencation=======
  //End

  ///lưu setting
  saveSetting = (data) => {
    const url = new URL(data.name);

    let api_key = "a737ffdc19a943b58f100045a554ac67";
    let scopes = "write_orders,read_customers,read_customers,write_customers,read_orders,write_orders,read_price_rules,write_price_rules,read_products,write_products";
    //var redirect_uri = 'https://dev.cubetiger.app/crm';
    var redirect_uri = "http://localhost:3000/app/sapo";
    let auth_url = `https://${url.host}/admin/oauth/authorize?client_id=${api_key}&scope=${scopes}&redirect_uri=${redirect_uri}`;
    window.open(auth_url);
  };

  open = (mode, element) => {
    if (mode === "add") {
      var data = {
        type: "",
        gmail: {},
        microsoft: {},
        smtp: {
          secure: true,
          auth: {
            username: "",
            password: "",
          },

          port: "",
          host: "",
        },
        name: "",
        id: "",
      };
      this.setState({ data: data, visible: true, mode: mode }, () => {
        this.setDataForm(data);
      });
    } else {
      this.setState({ data: element, visible: true, mode: mode, type: element.type }, () => {
        this.setDataForm(element);
      });
    }
  };

  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };

  render() {
    return (
      <Modal
        title="Cấu hình kết nối"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={[
          <div style={{ height: "30px" }}>
            <Button htmlType="submit" type="primary" form="myform">
              Tiếp tục
            </Button>
          </div>,
        ]}
        width="50%"
      >
        <Form name="emailsetting" ref={this.form} layout="vertical" onFinish={this.saveSetting} id="myform">
          <Form.Item
            name="name"
            label={<h3>Tên miền cửa hàng</h3>}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập tên!",
              },
            ]}
          >
            <Input placeholder="https://{store}.mysapo.net" style={{ maxWidth: "880px", borderRadius: "5px" }} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
