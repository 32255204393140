import React, { Component } from "react";
import { Button, message, Modal, Form, Input } from "antd";
import api from "api";
import MyInputNumber from "components/picker/inputnumber_picker";
import HTMLEditorPicker from "components/picker/html_editor_picker";
import UploadImagePicker from "components/picker/image_picker";

const { TextArea } = Input;

export default class PopupMemberShipLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      type: "total_spent",
      visible: false,
    };
    this.form = React.createRef();
  }

  open(id) {
    if (id === "") {
      this.setState({ visible: true, data: {}, type: this.props.type }, () => {
        this.form.current.resetFields();
      });
    } else {
      api.post("/loyaltyconfig/gettierbyid", { id: id }).then((res) => {
        this.setState({ visible: true, data: res, type: this.props.type }, () => {
          this.form.current.setFieldsValue(res);
        });
      });
    }
  }

  saveMBSLevel = (values) => {
    api
      .post("/loyaltyconfig/saveTier", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
        this.setState({ visible: false }, () => {
          this.props.reload();
        });
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  render() {
    return (
      <>
        <Modal
          title="Hạng thành viên"
          open={this.state.visible}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          width="50%"
          bodyStyle={{ overflow: "auto" }}
          footer={[
            <Button
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              Thoát
            </Button>,

            <Button
              type="primary"
              onClick={() => {
                this.form.current.submit();
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form name="membership_level" ref={this.form} layout="vertical" onFinish={this.saveMBSLevel}>
            <Form.Item name="id" hidden>
              <Input></Input>
            </Form.Item>

            <Form.Item
              label="Tên"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập tên!",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Hình ảnh"
              name="logo"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa chọn logo!",
                },
              ]}
            >
              <UploadImagePicker crop={{ quality: 1, aspect: 1 }} size={{ width: 64, height: 64 }} tags={["tier"]}></UploadImagePicker>
            </Form.Item>
            <Form.Item
              label={`Số ${this.state.type === "total_spent" ? "tiền chi tiêu" : "điểm tích lũy"} cần có`}
              name="value"
              rules={[{ required: true, message: `Bạn chưa chọn ${this.state.type === "total_spent" ? "tiền chi tiêu" : "điểm tích lũy"}!` }]}
            >
              <MyInputNumber step={10} style={{ width: "50%" }}></MyInputNumber>
            </Form.Item>
            <Form.Item
              label="Mô tả ngắn"
              name="short_description"
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập mô tả ngắn!",
                },
              ]}
            >
              <TextArea rows={2}></TextArea>
            </Form.Item>
            <Form.Item
              name="description"
              label="Mô tả chi tiết"
              rules={[
                {
                  validator: (rule, value) => {
                    // console.log("value", value);
                    return new Promise((resolve, error) => {
                      if (value === "<p><br></p>") {
                        error();
                      } else {
                        resolve();
                      }
                    });
                  },
                  message: "Bạn chưa nhập mô tả chi tiết!",
                },
                {
                  required: true,
                  message: "Bạn chưa nhập mô tả chi tiết!",
                },
              ]}
            >
              <HTMLEditorPicker style={{ height: "100%" }}></HTMLEditorPicker>
            </Form.Item>
            <Form.Item hidden>
              <Button type="primary" htmlType="submit" hidden>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
