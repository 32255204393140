import React from "react";
import { Modal, Input } from "antd";
const { TextArea } = Input;

export default class PopupAddVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: "",
    };
  }

  open = () => {
    this.setState({ visible: true, data: "" });
  };

  close = () => {
    this.setState({ visible: false });
  };

  onTextChange = (data) => {
    this.setState({ data: data });
  };

  onOk = () => {
    var lst = [];
    lst = this.state.data.split("\n");
    lst = lst.map((v) => (v = v.trim()));
    const unique = [...new Set(lst)];
    var lstVoucher = [];
    unique.forEach((item) => {
      if (item != "") lstVoucher.push({ voucher_id: item, divided: false });
    });
    this.props.onChange(lstVoucher);
    this.setState({ visible: false });
  };

  render() {
    return (
      <Modal
        open={this.state.visible}
        title={this.props.card ? "Thêm thẻ cào" : "Thêm voucher"}
        onOk={() => {
          this.onOk();
        }}
        onCancel={() => this.close()}
        okText="Lưu"
        cancelText="Trở lại"
      >
        <div>
          <span style={{ color: "#f0f0f0" }}>* </span> Lưu ý: Nhập mỗi dòng một voucher.
        </div>
        <div>
          <TextArea
            onChange={(e) => {
              this.onTextChange(e.target.value);
            }}
            value={this.state.data}
            rows={10}
          ></TextArea>
        </div>
      </Modal>
    );
  }
}
