import React, { Component } from "react";
import { Menu, Checkbox, Select, Dropdown, Button, Input, Space, Row, Col, Empty } from "antd";

const { Option } = Select;
export default class SearchBox extends Component {
  constructor(props) {
    super(props);
    var currentSetting = localStorage.getItem(this.props.name);
    if (currentSetting) currentSetting = JSON.parse(currentSetting);
    else currentSetting = [];
    this.state = {
      currentSetting: currentSetting,
      visible: false,
      data: this.props.data,
      dataButton: this.props.dataButton,
      placeholder: this.props.placeholder,
    };
  }

  componentDidMount() {
    var data = [...this.state.data];
    data.forEach((item) => {
      if (this.state.currentSetting.includes(item.Key)) item.isDisplay = true;
    });
    this.setState({ data: data });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      var currentSetting = localStorage.getItem(this.props.name);
      if (currentSetting)
        this.setState({ currentSetting: JSON.parse(currentSetting) }, () => {
          var data = [...this.props.data];
          data.forEach((item) => {
            if (this.state.currentSetting.includes(item.Key)) item.isDisplay = true;
          });
          this.setState({ data: data });
        });
    }
  };

  renderFilter = () => {
    var lstFilterDisplay = this.state.data.map((item) => {
      if (item.isDisplay) {
        return item.Component;
      }
    });

    return lstFilterDisplay;
  };

  renderButton = () => {
    var lstFilterDisplay = this.state.dataButton.map((item, index) => {
      return <div key={index}>{item}</div>;
    });

    return lstFilterDisplay;
  };

  handleInput(e) {
    if (this.props.onChange) this.props.onChange(e.target.value);
  }

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  selectFilter = (e) => {
    var data = [...this.state.data];
    var itemSelect = data.find((x) => x.Key == e.key);
    if (itemSelect) {
      if (!itemSelect.isDisplay) itemSelect.isDisplay = true;
      else itemSelect.isDisplay = false;
      this.setState({ data: data }, () => {
        this.reWriteLocalStorage(data);
        if (this.props.onSelectFilterChange) {
          this.props.onSelectFilterChange(e.key, itemSelect.isDisplay);
        }
      });
    }
  };

  reWriteLocalStorage(data) {
    var lst = data.filter((x) => x.isDisplay).map((item) => item.Key);
    localStorage.removeItem(this.props.name);
    localStorage.setItem(this.props.name, JSON.stringify(lst));
  }

  onChangeChecked = (e, key) => {
    var data = [...this.state.data];
    var itemSelect = data.find((x) => x.Key == key);
    itemSelect.isDisplay = e.target.checked;
    this.setState({ data: data }, () => this.reWriteLocalStorage(data));
  };

  search = () => {
    if (this.props.onSearch) this.props.onSearch();
  };

  render() {
    var lstOptionFilter = null;
    if (this.state.data.length != 0) {
      lstOptionFilter = this.state.data.map((item) => {
        return (
          <Menu.Item key={item.Key}>
            <Space>
              {/*<div className="pull-right">*/}
              <div className="">
                <Checkbox
                  checked={item.isDisplay}
                  onChange={(e) => {
                    this.onChangeChecked(e, item.Key);
                  }}
                ></Checkbox>
              </div>
              <span>{item.Name}</span>
            </Space>
          </Menu.Item>
        );
      });
    } else lstOptionFilter = <Empty />;
    return (
      <div>
        <Input.Search
          addonBefore={
            <Dropdown
              overlay={<Menu onClick={this.selectFilter}>{lstOptionFilter}</Menu>}
              onOpenChange={this.handleVisibleChange}
              open={this.state.visible}
              style={{ width: "100%" }}
            >
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} trigger={["click"]}>
                Nâng cao <i className="fa fa-angle-down" aria-hidden="true"></i>
              </a>
            </Dropdown>
          }
          placeholder={this.state.placeholder}
          onBlur={this.handleInput.bind(this)}
          onSearch={(s) => {
            this.props.onChange(s);
          }}
        ></Input.Search>
        <Row style={{ marginTop: 15 }}>{this.renderFilter()}</Row>
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
              {this.renderButton()}
              <Button type="primary" onClick={this.search} style={{ marginLeft: 15 }}>
                <i className="fa fa-search" aria-hidden="true"></i>&nbsp;Tìm kiếm
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
