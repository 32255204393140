import { useState, memo, useEffect, createRef } from "react";
import { Badge, Space, Tag, Typography, Popconfirm, message, Modal, Button } from "antd";
import ModalPackageRenewal from "./ModalPackageRenewal";
import api from "api";
import numeral from "numeral";
import DetailTransaction from "./DetailTransaction";
import AntTable from "components/common/AntTable";
import { blue } from "@ant-design/colors";
const { Title, Text } = Typography;
const PackageRenewalHistory = (props) => {
  const Tb = createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ismodalopenintable, setIsModalOpenInTable] = useState(false);
  const [selectTransaction, setSelectTransaction] = useState({});
  const [mode, setMode] = useState("add");
  const [filter, setFilter] = useState({
    status: { $in: ["pending", "completed", "canceled"] },
    code_id: { $ne: "" },
    create_on: { $ne: "" },
  });
  var columns = [
    {
      title: "Mã giao dịch",
      dataIndex: "code_id",
      key: "code_id",
      align: "center",
      render: (text) => (
        <Tag color="#40A9FF" style={{ fontSize: 16 }}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Ngân hàng",
      dataIndex: "bank",
      key: "bank",
      align: "center",
    },
    {
      title: "Gói cước",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text, record, index) => {
        // console.log(record)
        return (
          <>
            <Text>{record.plan?.name}</Text>
            <br />
            <Text>{record.duration?.value} tháng</Text>
          </>
        );
      },
    },
    {
      title: "Giá tiền",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record, index) => {
        return (
          <Title level={5} style={{ weight: 700, color: "rgba(0, 0, 0, 0.45)", marginBottom: 0 }}>
            {numeral(text.value).format("0,0")}
          </Title>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => {
        switch (text) {
          case "pending":
            return (
              <Space>
                <Badge color="yellow" />
                <Text>Chờ thanh toán</Text>
              </Space>
            );
          case "completed":
            return (
              <Space>
                <Badge color="green" />
                <Text>Đã thanh toán</Text>
              </Space>
            );
          case "canceled":
            return (
              <Space>
                <Badge color="#D9D9D9" />
                <Text>Huỷ thanh toán</Text>
              </Space>
            );
        }
      },
    },
    {
      title: "Thao tác",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <Space direction="horizontal">
          <Text style={{ color: blue.primary, paddingRight: 8 }} onClick={() => handleShowDetail(record)}>
            Xem
          </Text>
          {record.status === "pending" && (
            <Popconfirm title="Bạn có muốn hủy giao dịch này không?" onConfirm={() => cancelSubscription(record.id)} okText="Đồng ý" cancelText="Thoát">
              <Text style={{ color: blue.primary }}>Xoá</Text>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  /**
   * Hàm xử lý khi nhấn nút đồng ý tại popconfirm huỷ giao dịch
   * @param {String} idSubscription id của giao dịch
   */
  const cancelSubscription = async (idSubscription) => {
    var status = await api.post("subscription/getstatussubscription", { id: idSubscription });

    if (status === "pending") {
      await api.post("subscription/canceled_subscription", { id: idSubscription }).then((res) => {
        if (res) {
          reloadTable();
          message.success("Huỷ thành công!");
        } else {
          message.error("Thao tác không hợp lệ, vui lòng F5 để thử lại");
        }
      });
    } else {
      message.error("Thao tác không hợp lệ, vui lòng F5 để thử lại");
    }
  };
  const reloadTable = () => {
    Tb.current.reload();
  };
  const openModal = (mode, record) => {
    setIsModalOpen(true);
    setMode(mode);
  };
  const canceldetailtransaction = () => {
    setIsModalOpenInTable(false);
  };
  const handleShowDetail = (record) => {
    setSelectTransaction(record);
    setIsModalOpenInTable(true);
  };
  useEffect(() => {}, []);
  useEffect(() => {
    if (props.packagerenewal == true) {
      openModal("add");
      props.setPackageRenewal(false);
    }
  }, [props.packagerenewal]);
  return (
    <div style={{ marginLeft: "-24px", marginRight: "-24px" }}>
      <AntTable size="small" columns={columns} customSearch={filter} ajax="subscription/getlist" ref={Tb} tableName={"tbCustomer"} />
      <Modal
        title="Thông tin gia hạn"
        open={ismodalopenintable}
        footer={[<Button onClick={() => canceldetailtransaction()}>Đóng</Button>]}
        onCancel={() => canceldetailtransaction()}
        width={848}
        centered={true}
        bodyStyle={{ borderBottom: "1px solid #f0f0f0", borderTop: "1px solid #f0f0f0", padding: "0 24px", margin: "0 -24px" }}
      >
        <div style={{ padding: "24px 0" }}>
          <DetailTransaction data={selectTransaction} modelTitle={"Thông tin gia hạn"} />
        </div>
      </Modal>
      <ModalPackageRenewal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} mode={mode} record={{}} reloadTable={reloadTable} />
    </div>
  );
};

export default memo(PackageRenewalHistory);
