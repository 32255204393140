import React, { Component } from "react";
import api from "api";
import { Input, Button, Space, Card, Form, Alert, Checkbox, Row, Col, message, Modal } from "antd";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        type: "",
        auth: {
          client_id: "",
          client_secret: "",
          BrandName: "",
        },
        name: "",
        id: null,
      },
      type: "",
      mode: "",
    };
    this.inputType = React.createRef();

    this.form = React.createRef();
  }

  ///lưu setting
  saveSetting = (data) => {
    var mode = this.state.mode;
    data.id = this.state.data.id;
    data.name = data.auth.BrandName;
    var dataPost = { config: data, type: mode };
    console.log("dataPost", dataPost);
    api
      .post("app/smsfpt/config/save", dataPost)
      .then((res) => {
        message.success("Lưu thành công", 3);
        this.props.getConfig();
        this.setState({ visible: false, data: {} });
      })
      .catch((err) => {
        if (err.code == 246) message.error("Lưu thất bại, Brand Name đã tồn tại", 3);
        else message.error("Lưu thất bại, có lỗi xảy ra trong quá trình chứng thực Client ID, Secret", 3);
      });
  };

  open = (mode, element) => {
    if (mode === "add") {
      var data = {
        type: "",
        auth: {
          client_id: "",
          client_secret: "",
          BrandName: "",
        },
        name: "",
        id: null,
      };
      this.setState({ data: data, visible: true, mode: mode }, () => {
        this.form.current.resetFields();
        this.setDataForm(data);
      });
    } else {
      this.setState({ data: element, visible: true, mode: mode, type: element.type }, () => {
        this.form.current.resetFields();
        this.setDataForm(element);
      });
    }
  };

  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };

  render() {
    return (
      <Modal
        title="Cấu hình SMS FPT"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={[
          <div style={{ height: "30px" }}>
            <Button htmlType="submit" type="primary" form="myform">
              Lưu
            </Button>
          </div>,
        ]}
        width="50%"
      >
        <Form ref={this.form} layout="vertical" onFinish={this.saveSetting} id="myform">
          <Form.Item name="name" hidden></Form.Item>
          <Form.Item
            name={["auth", "client_id"]}
            label={<b>Client ID</b>}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập Client ID!",
              },
            ]}
          >
            <Input placeholder="Client ID" style={{ width: "100%", borderRadius: "5px" }} />
          </Form.Item>

          <Form.Item
            name={["auth", "client_secret"]}
            label={<b>Secret</b>}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập Secret!",
              },
            ]}
          >
            <Input placeholder="Secret" style={{ width: "100%", borderRadius: "5px" }} />
          </Form.Item>
          <Form.Item
            name={["auth", "BrandName"]}
            label={<b>Brand Name</b>}
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập Brand Name!",
              },
            ]}
          >
            <Input placeholder="BrandName" style={{ width: "100%", borderRadius: "5px" }} />
          </Form.Item>

          <div>
            Vui lòng nhập thông tin do FPT cung cấp. Xem tài liệu hướng dẫn nhập tại <a href="https://developer.sms.fpt.net/document/huong-dan-dang-ky-ung-dung">đây</a>.
          </div>
        </Form>
      </Modal>
    );
  }
}
