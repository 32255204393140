import React, { Component } from "react";
import { Select, Tag } from "antd";
import api from "api";

export default class TagPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lst_tag: [],
    };
  }

  componentDidMount() {
    this.getTagConfig();
  }

  tagRender = (tag) => {
    if (tag != null) {
      var lst_tag = this.state.lst_tag;
      var config_tag = {};
      if (tag.value) {
        if (tag.value.id != null) config_tag = lst_tag.find((element) => element.id == tag.value.id);
        else config_tag = lst_tag.find((element) => element.id == tag.value);
      }

      if (config_tag != null)
        return (
          <Tag color={config_tag.color} style={{ marginRight: 3 }}>
            {config_tag.label}
          </Tag>
        );
      else return <Tag></Tag>;
    }
  };

  getTagConfig = () => {
    if (this.props.options != null && this.props.options.length != 0) this.setState({ lst_tag: this.props.options });
    else
      api.post("customerconfig/getconfig", { query: { key: "tag" } }).then((res) => {
        if (res.length > 0) {
          this.setState({ lst_tag: res });
        }
      });
  };

  getTagByID = (tag_id) => {
    var tag = this.state.lst_tag.find((element) => element.id == tag_id);
    if (tag != null) return tag;
    else return null;
  };

  onChange = (value) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    var value = this.props.value;
    setTimeout(() => {
      if (value != null && value.length != 0 && this.state.lst_tag.length != 0) {
        value = value.map((element) => {
          if (typeof element === "string") return this.getTagByID(element);
          else return element;
        });
        value = value.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      }
    }, 20);
    return (
      <div>
        <Select
          mode="multiple"
          onChange={this.onChange}
          showArrow
          allowClear
          tagRender={this.tagRender}
          value={value}
          style={{ width: "100%" }}
          options={this.state.lst_tag.map((x) => {
            return { value: x.id, label: x.label };
          })}
        />
      </div>
    );
  }
}
