import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import api from "api";

export default class PercentLoyalty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      returnTime: 4,
      loyalRate: 0,
      save: false,
    };
    this.tooltipTitle = "";
  }

  componentDidMount = () => {
    this.getDefaultReturnTime();
  };

  //tùng code

  getLoyalCustomer(save) {
    this.tooltipTitle = (
      <span>
        Số lần quay lại để trở thành khách hàng trung thành <nbsp></nbsp>
        <InputNumber onChange={(e) => this.returnTimeChange(e)} value={this.state.returnTime} />
      </span>
    );
    api
      .post("dashboard/getloyalcustomer", { returnTime: this.state.returnTime, save: this.state.save })
      .then((res) => {
        var returncus = res[0].Return;
        var total = res[0].Total;
        var loyalRate = (returncus / total) * 100;
        this.setState({ loyalRate: loyalRate });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  returnTimeChange = (e) => {
    if (e != null) {
      this.setState({ returnTime: Number(e), save: true }, this.getLoyalCustomer);
    }
  };
  async getDefaultReturnTime() {
    api
      .post("dashboard/getdefaultreturntime")
      .then((res) => {
        this.setState({ returnTime: res }, this.getLoyalCustomer);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  //tùng endcode

  render() {
    return (
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Tooltip title={this.tooltipTitle} placement="topLeft">
              <Statistic
                title="Tỉ lệ trung thành"
                value={this.state.loyalRate ? Math.round(this.state.loyalRate * 100) / 100 : 0}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<i className="fa fa-users"></i>}
                suffix="%"
              />
            </Tooltip>
          </Col>
          {/* <Col span={6}>
                    <label>Số lần quay lại</label>
                    <InputNumber
                      value={this.state.returnTime}
                      onChange={(e) => this.returnTimeChange(e.target.value)}
                    />
                  </Col> */}
        </Row>
      </Card>
    );
  }
}
