import React from "react";

export default class PreviewImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      showBtnPreview: false,
      selectedSrc: null,
      name: "",
    };
  }

  componentDidMount = () => {
    this.setState({ url: this.props.url, name: this.props.name });
  };

  componentDidUpdate = (prevPops, prevState) => {
    if (this.props.url !== prevPops.url) {
      this.setState({ url: this.props.url, name: this.props.name });
    }
  };

  render = (item) => {
    var boderColor = "#f0f0f0";
    if (this.state.url == this.props.selectedSrc) {
      boderColor = "#458fff";
    }
    if (this.props.tags && this.props.tags[0] === "promotion") {
      return (
        <>
          <div
            style={{ width: 150, height: 120, border: `2px solid ${boderColor}`, borderRadius: 2, cursor: "pointer", position: "relative", overflow: "hidden", display: "flex" }}
            onClick={(e) => {
              this.props.onClickItem(this.state.url);
            }}
            onMouseEnter={() => this.setState({ showBtnPreview: true })}
            onMouseLeave={() => this.setState({ showBtnPreview: false })}
            onDoubleClick={(e) => {
              this.props.selectImg(this.state.url);
            }}
          >
            <div style={{ margin: "auto" }}>
              <img style={{ width: "100%", height: "auto" }} src={this.state.url} />
              {this.state.showBtnPreview && (
                <>
                  <div
                    style={{ position: "absolute", zIndex: 999, top: 0, left: 5 }}
                    onClick={() => {
                      this.props.previewImage(this.state.url);
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>{this.state.name}</div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{ width: 100, height: 100, border: `2px solid ${boderColor}`, borderRadius: 2, cursor: "pointer", position: "relative", overflow: "hidden", display: "flex" }}
            onClick={(e) => {
              this.props.onClickItem(this.state.url);
            }}
            onMouseEnter={() => this.setState({ showBtnPreview: true })}
            onMouseLeave={() => this.setState({ showBtnPreview: false })}
            onDoubleClick={(e) => {
              this.props.selectImg(this.state.url);
            }}
          >
            <div style={{ margin: "auto" }}>
              <img style={{ width: "100%", height: "auto" }} src={this.state.url} />
              {this.state.showBtnPreview && (
                <>
                  <div
                    style={{ position: "absolute", zIndex: 999, top: 0, left: 5 }}
                    onClick={() => {
                      this.props.previewImage(this.state.url);
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>{this.state.name}</div>
        </>
      );
    }
  };
}
