import React from "react";
import { Button, Tooltip, Row, Col } from "antd";

export default class CoppyCol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  coppy = () => {
    navigator.clipboard.writeText(this.props.value);
    this.setState({ visible: true });
    setTimeout(() => {
      this.setState({ visible: false });
    }, 2000);
  };
  render() {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ wordWrap: "break-all", maxWidth: 600 }}>{this.props.value}</div>

        <Tooltip placement="bottom" title={"Coppied"} open={this.state.visible}>
          <Button onClick={() => this.coppy()}>Copy</Button>
        </Tooltip>
      </div>
    );
  }
}
