import React from "react";
import "./login.css";
import { Row, Col } from "antd";
import { Typography, Space } from "antd";
import { Form, Input, Button, Checkbox, message, Spin, Card, Image, Modal } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import api from "api";

const { Title } = Typography;
const { Meta } = Card;
// const [form] = Form.useForm();

export default class LoginMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      validateUsername: null,
      validatePassword: null,
    };
    // api.setTokenKey("master_token");
    this.form = React.createRef();
  }

  onFinish = (values) => {
    this.setState({
      loading: true,
    });
    api
      .post("master/login/index", values)
      .then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("name", res.user.name);
        localStorage.setItem("role", res.user.role);
        localStorage.setItem("user_name", values.user_name);
        // api.setToken(res.token);
        window.location.href = "/master";
      })
      .catch((err) => {
        this.setState({
          loading: false,
          validatePassword: err.code === 600 ? "Tên đăng nhập hoặc mật khẩu không chính xác" : null,
          validateUsername: err.code === 602 ? "Tài khoản chưa được kích hoạt" : null,
        });
        // message.error(err.message);
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("onFinishFailed:", errorInfo);
  };

  onChange = () => {
    if (this.state.validatePassword || this.state.validateUsername) {
      this.setState({
        validatePassword: null,
        validateUsername: null,
      });
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: "#F0F2F5" }}>
        <Spin size="large" spinning={this.state.loading} style={{ marginTop: "30vh" }}>
          <Row>
            <Col span={24} style={{ height: "100vh" }}>
              <Form
                ref={this.form}
                name="basic"
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  width: "100%",
                }}
                autoComplete="off"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Row justify="center" align="middle" style={{ height: "100vh" }}>
                  <Card
                    style={{
                      width: "448px",
                      boxShadow: "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                    }}
                    title={
                      <Row justify="center" align="middle" style={{ padding: "16px 0" }}>
                        <Image preview={false} src="/assets/images/cube-loyalty-logo.png" />
                      </Row>
                    }
                    actions={[
                      <Form.Item
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{ paddingLeft: 24, paddingRight: 24, marginBottom: 8 }}
                      >
                        <Button type="primary" style={{ width: "100%" }} htmlType="submit">
                          Đăng nhập
                        </Button>
                      </Form.Item>,
                    ]}
                  >
                    <Form.Item
                      name="user_name"
                      rules={[
                        {
                          required: true,
                          message: "Tên đăng nhập không được để trống",
                        },
                      ]}
                      style={{ paddingBottom: 8 }}
                    >
                      <Input placeholder="Tên đăng nhập" status={this.state.validateUsername ? "error" : null} onChange={this.onChange} />
                    </Form.Item>
                    {this.state.validateUsername && <p style={{ position: "absolute", bottom: 124, color: "red" }}>{this.state.validateUsername}</p>}
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Mật khẩu không được để trống",
                        },
                      ]}
                      style={{ paddingBottom: 0, marginBottom: 4 }}
                    >
                      <Input.Password status={this.state.validatePassword ? "error" : null} placeholder="Mật khẩu" onChange={this.onChange} />
                    </Form.Item>
                    {this.state.validatePassword && <p style={{ color: "red", margin: 0 }}>{this.state.validatePassword}</p>}
                  </Card>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
