import { useReducer } from "react";
import ServiceReducer from "./ServiceReducer";
import ServiceContext from "context/product/ProductContext";

// import { sumItems } from "./ServiceReducer";
// import { getStorage } from "common/zalo_storage/ZaloStorage";

//Local Storage
const serviceItem = localStorage.getItem("serviceItem") ? JSON.parse(localStorage.getItem("serviceItem")) : {};
// var data = await getStorage({ keys: ["cartItems", "shippingAddress", "voucher"] });
const ServiceState = ({ children }) => {
  //Initial State of the cart
  // const initialState = {
  //   cartItems: [],
  //   checkout: false,
  // };

  //Change the code above to that below to get the initial state from local storage
  const initialState = {
    serviceItem: serviceItem,
    // shippingAddress: data.shippingAddress ? JSON.parse(data.shippingAddress) : {},
    // voucher: data.voucher ? JSON.parse(data.voucher) : {},
    // ...sumItems(data.cartItems ? JSON.parse(data.cartItems) : []),
    // checkout: false,
  };

  //Set up the reducer
  const [state, dispatch] = useReducer(ServiceReducer, initialState);

  //Function to handle when an item is added from the store into the Cart
  const updateToService = (payload) => {
    // debugger;
    dispatch({ type: "UPDATE_TO_SERVICE", payload });
  };
  const addToService = (payload) => {
    console.log("payload", payload);
    dispatch({ type: "ADD_TO_SERVICE", payload });
  };

  return (
    //Add the above functions into the Context provider, and pass to the children
    <ServiceContext.Provider
      value={{
        addToService,
        updateToService,
        // To access the total, we need to pass in the state
        ...state,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export default ServiceState;
