import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Typography, Row } from "antd";
import UploadImagePicker from "components/picker/upload_image_picker";
import api from "api";

const { Text } = Typography;
function AddCategoryModal({ isModalOpen, setIsModalOpen, setIsReload, item }) {
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const handleOk = () => {
    // console.log(form.getFieldsValue());
    form
      .validateFields()
      .then((res) => {
        var data = form.getFieldsValue();
        api
          .post("service_category/save", item ? { id: item.id, ...data } : data)
          .then((res) => {
            message.success("Lưu danh mục thành công!");
            setIsModalOpen(false);
            setIsReload(true);
          })
          .catch((err) => {
            message.error(err);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (item) {
      if (item.image) {
        setImages([item.image]);
      }
      form.setFieldsValue(item);
    }
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal title={item ? "Sửa danh mục" : "Thêm danh mục"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText={"Lưu"} cancelText={"Thoát"}>
      <Form form={form} layout="vertical">
        <Row>
          <Form.Item
            //   className="banner_image"
            label={"Hình ảnh danh mục"}
            name={"image"}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập hình ảnh chính cho danh mục!",
              },
            ]}
            style={{ width: 132 }}
            // className={"category_image"}
          >
            <UploadImagePicker
              images={images}
              onChange={(e) => {
                //   onChange("images", e);
                setImages([e]);
              }}
              onRemove={(e) => {
                setImages([]);
                //   var dataPost = {};
                //   dataPost.images = productContext.productItem.images.filter((item) => {
                //     return item !== e.url;
                //   });
                //   productContext.updateToProduct(dataPost);
              }}
              limitImage={1}
            />
          </Form.Item>
          <Text type="secondary" style={{ maxWidth: 160, marginTop: 30 }}>
            Hình ảnh có tỷ lệ 1:1, kích thước hình ảnh không vượt quá 500KB
          </Text>
        </Row>

        <Form.Item
          label="Tên danh mục"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên danh mục!",
            },
          ]}
        >
          <Input placeholder="Tên danh mục" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddCategoryModal;
