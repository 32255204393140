import React, { useState, useContext } from "react";
import { Form, Input, Checkbox, Select, Row, Typography, Col, Button, Space, Divider } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import api from "api";
import { useEffect } from "react";
import VariantTable from "./VariantTable";
import ProductContext from "context/product/ProductContext";
import UploadImagePicker from "components/picker/upload_image_picker";
import { render } from "@testing-library/react";
import { createRef } from "react";
const { Option } = Select;
const { Text, Link } = Typography;
function AddEditVariant({ item, index, onChange, onRemove, destroyTable }) {
  const [variantList, setVariantList] = useState([]);
  const [form] = Form.useForm();
  const variantRef = createRef();
  const optionRef = createRef();
  const [isValidVariantRef, setIsValidVariantRef] = useState(0);
  const [isValidOptionRef, setIsValidOptionRef] = useState(0);
  //   const [optionList, setOptionList] = useState([]);

  const productContext = useContext(ProductContext);
  //   const [mode, setMode] = useState(props.mode);
  const getVariantList = () => {
    api.post("miniapp_product_variant/list").then((res) => {
      if (res.length > 0) {
        var newVariantList = res;
        // if (productContext.productItem.variants.length > 0) {
        //   var variants = productContext.productItem.variants;
        //   variants.forEach((v) => {
        //     newVariantList = newVariantList.filter((n) => {
        //       return n.id !== v.id;
        //     });
        //   });
        // }

        setVariantList(
          newVariantList.map((item) => {
            return { value: item.id, label: item.name, optionList: item.options };
          })
        );
      }
    });
  };

  useEffect(() => {
    getVariantList();
  }, []);

  const onChangeVariant = (value) => {
    var newItem = { ...item };
    var find = variantList.find((variant) => {
      return variant.value === value;
    });
    if (find) {
      newItem.id = find.value;
      newItem.name = find.label;
      newItem.options = find.optionsList;
    }
    // var current = form.getFieldsValue();;
    // console.log('current', newItem);
    form.setFieldValue(`variant${index}`, { id: newItem.id, options: undefined, isImage: newItem.isImage });
    //  var variantForm = form.getFieldsValue();
    // variantForm[`variant${index}`].options = undefined;
    //console.log("variantForm", variantForm);
    onChange(newItem, index, "variants");
  };

  const onChangeOption = (value) => {
    var newItem = { ...item };

    var newOptions = [];

    renderOptionList(newItem.id).forEach((opt) => {
      if (value.includes(opt.value)) {
        newOptions.push({
          id: opt.value,
          value: opt.label,
          image:
            newItem.options &&
            newItem.options.find((f) => {
              return f.id === opt.value;
            })
              ? newItem.options.find((f) => {
                  return f.id === opt.value;
                }).image
              : "",
        });
      }
    });
    newItem.options = newOptions;
    onChange(newItem, index, "options");
  };

  const renderOptionList = (variant_id) => {
    // console.log(item);
    var findVariant = variantList.find((variant) => {
      return variant.value === variant_id;
    });
    // console.log("findVariant", findVariant);
    if (findVariant) {
      return findVariant.optionList.map((opt) => {
        return { value: opt.id, label: opt.value };
      });
    }
    return [];
  };

  useEffect(() => {
    if (item.mode === "edit") {
      form.setFieldValue("variants", [item]);
    }
  }, [item.mode]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        var variantForm = form.getFieldsValue();
        if (variantForm[`variant${index}`].id && variantForm[`variant${index}`].options.length > 0) {
          var newVariants = productContext.productItem.variants;
          newVariants[index] = item;
          productContext.updateToProduct({ variants: newVariants });
          var newItem = { ...item };
          newItem.mode = "view";
          onChange(newItem, index);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {(item.mode === "add" || item.mode === "edit") && (
        <Form style={{ padding: 16, background: "#F0F2F5", position: "relative" }} layout="vertical" form={form}>
          {index === 0 && (
            <Form.Item name={[`variant${index}`, "isImage"]} style={{ position: "absolute", right: 8, top: 8 }} valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  var newItem = { ...item };
                  newItem.isImage = e.target.checked;
                  onChange(newItem, index);
                }}
              >
                Thêm hình ảnh
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            label="Tên biến thể"
            name={[`variant${index}`, "id"]}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập tên biến thể",
              },
              {
                message: "Vui lòng nhập biến thể khác",
                validator: (_, value) => {
                  var variants = productContext.productItem.variants;
                  if (value) {
                    if (variants.length > 0) {
                      if (
                        variants
                          .map((v) => {
                            return v.id;
                          })
                          .includes(value) &&
                        variants[index].id !== value
                      ) {
                        return Promise.reject("Some message here");
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              placeholder="Tên biến thể"
              options={variantList}
              onDropdownVisibleChange={(e) => {
                if (!e) {
                  variantRef.current.resetFields(["variant_name"]);
                }
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Form ref={variantRef} layout="inline">
                    <Row style={{ width: "100%", padding: "0 12px 5px 12px", alignItems: "center" }}>
                      {/* <Form.Item
                        label="Tên sản phẩm"
                        name="category_name"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên sản phẩm!",
                          },
                        ]}
                      > */}
                      <Col span={20}>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"variant_name"}
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền tên biến thể",
                            },
                            {
                              message: "Tên biến thể đã tồn tại",
                              validator: (_, value) => {
                                var variantNameList = variantList.map((v) => {
                                  return v.label.toLowerCase();
                                });
                                if (value && variantNameList.length > 0) {
                                  if (variantNameList.includes(value.trim().toLowerCase())) {
                                    return Promise.reject("Some message here");
                                  }
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input placeholder="Tên biến thể" />
                        </Form.Item>
                      </Col>

                      {/* </Form.Item> */}
                      <Col span={4}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => {
                            variantRef.current
                              .validateFields(["variant_name"])
                              .then((value) => {
                                api
                                  .post("miniapp_product_variant/save", { name: variantRef.current.getFieldValue("variant_name"), options: [] })
                                  .then((res) => {
                                    variantRef.current.resetFields(["variant_name"]);
                                    getVariantList();
                                  });
                              })
                              .catch((err) => {
                                console.log("lỗi", err);
                              });
                            // if (!variantRef.current.getFieldValue("variant_name")) {
                            //   setIsValidVariantRef(1);
                            // } else if (
                            //   variantList.length > 0 &&
                            //   variantList
                            //     .map((v) => {
                            //       return v.name;
                            //     })
                            //     .includes(variantRef.current.input.value)
                            // ) {
                            //   setIsValidVariantRef(2);
                            // } else {
                            //   api.post("miniapp_product_variant/save", { name: variantRef.current.input.value, options: [] }).then((res) => {
                            //     getVariantList();
                            //   });
                            // }
                          }}
                          type="link"
                          icon={<PlusOutlined />}
                        >
                          Thêm mới
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
              onChange={(value) => {
                onChangeVariant(value);
              }}
            />
          </Form.Item>
          <Form.Item name={[`variant${index}`, "options"]} label="Tuỳ chọn" rules={[{ required: true, message: "Vui lòng nhập tuỳ chọn cho biến thể" }]}>
            <Select
              placeholder="Nhập một tuỳ chọn"
              options={renderOptionList(item.id)}
              mode="multiple"
              onChange={(value) => {
                onChangeOption(value);
              }}
              onDropdownVisibleChange={(e) => {
                if (!e) {
                  optionRef.current.resetFields(["option_name"]);
                }
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Form ref={optionRef} layout="inline">
                    <Row style={{ width: "100%", padding: "0 12px 5px 12px", alignItems: "center" }}>
                      {/* <Form.Item
                        label="Tên sản phẩm"
                        name="category_name"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên sản phẩm!",
                          },
                        ]}
                      > */}
                      <Col span={20}>
                        <Form.Item
                          name={"option_name"}
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng điền tên tuỳ chọn",
                            },
                            {
                              message: "Tên tuỳ chọn đã tồn tại",
                              validator: (_, value) => {
                                var options = renderOptionList(item.id);
                                var optionNameList =
                                  options.length > 0
                                    ? options.map((opt) => {
                                        return opt.label.toLowerCase();
                                      })
                                    : [];
                                if (value && optionNameList.length > 0) {
                                  if (optionNameList.includes(value.trim().toLowerCase())) {
                                    return Promise.reject("Some message here");
                                  }
                                }

                                return Promise.resolve();
                              },
                            },
                            {
                              message: "Vui lòng chọn biến thể trước",
                              validator: (_, value) => {
                                if (value && !item.id) {
                                  // if (optionNameList.includes(value.trim().toLowerCase())) {
                                  return Promise.reject("Some message here");
                                  // }
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input placeholder="Tên tuỳ chọn" />
                        </Form.Item>
                      </Col>

                      {/* </Form.Item> */}
                      <Col span={4}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => {
                            optionRef.current
                              .validateFields(["option_name"])
                              .then((value) => {
                                var options = renderOptionList(item.id);

                                var newOptions =
                                  options.length > 0
                                    ? options.map((opt) => {
                                        return { id: opt.value, value: opt.label };
                                      })
                                    : [];
                                if (newOptions.length > 0) {
                                  newOptions.push({
                                    id: renderOptionList(item.id).pop().value + 1,
                                    value: optionRef.current.getFieldValue("option_name").trim(),
                                  });
                                } else {
                                  newOptions = [{ id: 1, value: optionRef.current.getFieldValue("option_name").trim() }];
                                }

                                optionRef.current.resetFields(["option_name"]);
                                api.post("miniapp_product_variant/save", { id: item.id, options: newOptions }).then((res) => {
                                  getVariantList();
                                });
                              })
                              .catch((err) => {});
                            // if (!optionRef.current.input.value) {
                            //   setIsValidOptionRef(1);
                            // } else if (
                            //   renderOptionList(item.id).length > 0 &&
                            //   renderOptionList(item.id)
                            //     .map((opt) => {
                            //       return opt.value;
                            //     })
                            //     .includes(optionRef.current.input.value.trim())
                            // ) {
                            //   setIsValidOptionRef(2);
                            // } else {
                            //   var newOptions =
                            //     renderOptionList(item.id).length > 0
                            //       ? renderOptionList(item.id).push({
                            //           id: renderOptionList(item.id).pop().value + 1,
                            //           value: optionRef.current.input.value.trim(),
                            //         })
                            //       : [{ id: 1, value: optionRef.current.input.value.trim() }];

                            //   api.post("miniapp_product_variant/save", { id: item.id, options: newOptions }).then((res) => {
                            //     getVariantList();
                            //   });
                            // }
                          }}
                          type="link"
                          icon={<PlusOutlined />}
                        >
                          Thêm mới
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            />
          </Form.Item>
          {item.isImage &&
            item.options?.length > 0 &&
            item.options[0].id &&
            item.options.map((opt, i) => {
              console.log(opt);
              return (
                <Row style={{ position: "relative", alignItems: "center" }}>
                  <Form.Item name={[`variant${index}`, `option${i + 1}_image`]} rules={[{ required: true, message: "Vui lòng nhập hình ảnh cho tuỳ chọn" }]}>
                    <UploadImagePicker
                      images={opt.image ? [opt.image] : []}
                      limitImage={1}
                      onChange={(value) => {
                        var newItem = { ...item };
                        newItem.options[i].image = value;
                        onChange(newItem, index);
                      }}
                      onRemove={(value) => {
                        var newItem = { ...item };
                        newItem.options[i].image = "";
                        onChange(newItem, index);
                        form.setFieldValue([`variant${index}`, `option${i + 1}_image`], "");
                      }}
                    />
                  </Form.Item>

                  <Text style={{ position: "absolute", left: 127, top: 40 }}>{opt.value}</Text>
                </Row>
              );
            })}
          {/* {item.options.map((opt, i) => {
            return (
              <Row gutter={[24, 0]} align="middle">
                {item.isImage && index === 0 && (
                  <Col span={3}>
                    <Form.Item
                      label={"Tuỳ chọn"}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập hình ảnh chính cho sản phẩm",
                        },
                      ]}
                    >
                      <UploadImagePicker />
                    </Form.Item>
                  </Col>
                )}
                <Col span={item.isImage && index === 0 ? 20 : 23}>
                  <Form.Item
                    name={["variants", index, "options", i, "id"]}
                    label={(!item.isImage || index !== 0) && "Tuỳ chọn"}
                    rules={[{ required: true, message: "Vui lòng nhập tuỳ chọn cho biến thể" }]}
                    style={{ margin: item.isImage && index === 0 && 0 }}
                  >
                    <Select
                      placeholder="Nhập một tuỳ chọn"
                      options={renderOptionList(item.id, i)}
                      mode="multiple"
                      onChange={(value) => {
                        onChangeOption(value, i);
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Button style={{ width: "100%" }} type="link" icon={<PlusOutlined />}>
                            Thêm mớI
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <DeleteFilled
                    style={{ fontSize: 16 }}
                    onClick={() => {
                      var newItem = { ...item };
                      newItem.options = newItem.options.filter((option, index) => {
                        return index !== i;
                      });
                      var variantForm = form.getFieldsValue().variants;
                      variantForm[index] = newItem;
                      form.setFieldValue("variants", variantForm);

                      onChange(newItem, index, "options");
                    }}
                  />
                </Col>
              </Row>
            );
          })} */}
          {/* <Form.Item>
            <Button
              style={{ width: "100%" }}
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => {
                var newItem = { ...item };
                newItem.options.push({ id: "", value: "", image: "" });
                onChange(newItem, index, "options");
              }}
            >
              Thêm mới
            </Button>
          </Form.Item> */}
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="primary"
              // onClick={() => {

              // htmlType="submit"
              onClick={onFinish}
            >
              Hoàn tất
            </Button>
            {/* {index > 0 && ( */}
            <Button
              // type="text"
              // onClick={() => {

              // htmlType="submit"
              style={{ marginLeft: 8 }}
              onClick={() => {
                var newItem = productContext.productItem.variants[index] ? productContext.productItem.variants[index] : { id: null, mode: "view" };
                newItem.mode = "view";
                onChange(newItem, index);
              }}
            >
              Thoát
            </Button>
            {/* )} */}
          </Form.Item>
        </Form>
      )}
      {item.mode === "view" && (
        <div style={{ padding: 16, background: "#F0F2F5" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Space direction="vertical">
                <Text>{item.name}</Text>
                <Row>
                  <Space>
                    {item.options.map((opt) => {
                      return (
                        <Space style={{ padding: "4px 16px", borderRadius: 50, background: "#00000040" }}>
                          {opt.image && (
                            <div style={{ height: 24, width: 24, borderRadius: "50%", backgroundImage: `url(${opt.image})`, backgroundSize: "cover" }}></div>
                          )}
                          <Text>{opt.value}</Text>
                        </Space>
                      );
                    })}
                  </Space>
                </Row>
              </Space>
            </Col>

            <Col>
              <Row justify={"center"} align={"middle"}>
                <Button
                  type="link"
                  onClick={() => {
                    var newItem = { ...item };
                    newItem.mode = "edit";
                    onChange(newItem, index, "variants");
                    var variant = productContext.productItem.variants[index];

                    var formData = {};
                    formData.id = variant.id;

                    formData.options = variant.options.map((opt) => {
                      return opt.id;
                    });
                    formData.isImage = variant.isImage;
                    variant.options.forEach((opt, i) => {
                      formData[`option${i + 1}_image`] = opt.image;
                    });

                    form.setFieldValue(`variant${index}`, formData);
                  }}
                >
                  Chỉnh sửa
                </Button>
              </Row>

              {index !== 0 && (
                <Row justify={"center"} align={"middle"}>
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      var newVariantItems = productContext.productItem.variants;
                      newVariantItems = newVariantItems.filter((variant) => {
                        return variant.id !== item.id;
                      });
                      productContext.updateToProduct({ variants: newVariantItems });
                      onRemove(item, index);
                    }}
                    // onClick={() => {
                    //   var newItem = { ...item };
                    //   newItem.mode = "edit";
                    //   onChange(newItem, index);
                    // }}
                  >
                    Xoá
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default AddEditVariant;
