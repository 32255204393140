import api from "api";
const setQueryField = async (res, config, component) => {
  let provinces = [];
  let tags = [];
  // provinces = await api.get('mapprovince/getprovince', {});
  let products = [];
  let tier = [];
  let customFields = [];
  tags = await api.post("customerconfig/getconfig", { query: { key: "tag" } });
  products = await api.get("customerconfig/getProductData", {});
  tier = await api.get("customerconfig/gettiercustomer", {});
  customFields = await api.post("customerconfig/getconfig", { query: { key: "field" } });

  var lstTag = tags.map((item) => {
    let tempItem = {
      value: item.id,
      title: item.label,
      color: item.color,
    };

    return tempItem;
  });

  var lstPro = products.map((item) => {
    let tempItem = {
      value: item.product_id,
      title: item.name,
    };

    return tempItem;
  });

  var lstTier = tier.map((item) => {
    let tempItem = {
      value: item.id,
      title: item.short_description,
    };

    return tempItem;
  });

  let fields = {
    first_name: {
      type: "text",
      label: "Tên khách hàng",
    },
    abc: {
      type: "boolean",
      label: "abc",
    },
    last_name: {
      type: "text",
      label: "Họ khách hàng",
    },
    phone: {
      type: "text",
      label: "Số điện thoại",
    },
    email: {
      type: "text",
      label: "Email",
    },
    birth_day: {
      type: "date",
      label: "Ngày sinh",
      operators: ["birth_day_equal", "birth_day_thismonth", "birth_day_next_xdays"],
      widgets: { number: { operators: ["birth_day_next_xdays"] } },
    },
    gender: {
      type: "multiselect",
      label: "Giới tính",
      fieldSettings: {
        listValues: [
          { value: "male", title: "Nam" },
          { value: "female", title: "Nữ" },
          { value: "other", title: "Khác" },
        ],
      },
    },

    total_spent: {
      type: "number",
      label: "Tổng chi tiêu ",
    },

    points: {
      type: "number",
      label: "Số Điểm ",
    },

    tier_id: {
      type: "select",
      label: "Hạng Thành Viên ",
      fieldSettings: {
        listValues: lstTier,
      },
    },

    tags: {
      type: "multiselect",
      label: "Nhãn",
      fieldSettings: {
        listValues: lstTag,
      },
    },

    product_ids: {
      type: "multiselect",
      label: "Sản phẩm",
      operators: ["product_already_buy", "product_not_buy_yet"],
      widgets: { multiselect: { operators: ["product_already_buy", "product_not_buy_yet"] } },
      tab_key: "optional",
      tooltip: "Lọc sản phẩm khách hàng từng mua hoặc chưa mua bao giờ",
      fieldSettings: {
        asyncFetch: async (s) => {
          var products = await api.post("customerconfig/getProductData", { search: s });
          var lstPro = products.map((item) => {
            let tempItem = {
              value: item.product_id,
              title: item.name,
            };

            return tempItem;
          });
          //  console.log('here we go');
          return lstPro;
        },
        // allowCustomValues: true
      },
    },

    source_name: {
      type: "text",
      label: "Nguồn Đơn",
      operators: ["array_like", "array_not_like"],
      widgets: { text: { operators: ["array_like", "array_not_like"] } },
      tab_key: "optional",
      tooltip: "Lọc kênh khách hàng đã mua hoặc chưa mua",
    },

    buy_range: {
      type: "date",
      label: "Ngày Mua",
      operators: ["product_buy_period"],
      widgets: {
        date: {
          operators: ["product_buy_period"],
          opProps: {
            product_buy_period: {
              isSpecialRange: true,
              textSeparators: [null, null],
            },
          },
        },
      },
      tab_key: "optional",
      tooltip: "Ngày mua hàng",
    },
    last_buy_date: {
      type: "date",
      label: "Ngày mua hàng gần nhất",
      //operators: ["last_buy_before_xdays"],
      //widgets: { number: { operators: ["last_buy_before_xdays"] } },
    },

    orders_count: {
      type: "number",
      label: "Số lần mua hàng",
    },
    ecurring_notifications: {
      type: "select",
      label: "Thông báo Facebook",
      operators: ["recurring_notifications_daily", "recurring_notifications_weekly", "recurring_notifications_monthly"],
      widgets: { multiselect: { operators: ["recurring_notifications_daily", "recurring_notifications_weekly", "recurring_notifications_monthly"] } },
      tab_key: "optional",
    },
  };

  // for (let index = 0; index < res.length; index++) {
  //     var element = res[index];
  //     if (element.is_basic)
  //         continue;

  //     let property = {};

  //     if (element.type === 'multiple') {
  //         let values = []
  //         if (element.attribute === 'province_id') {

  //             provinces.forEach(item => {
  //                 let tempItem = {
  //                     value: item.value,
  //                     title: item.state_name,
  //                 }

  //                 values.push(tempItem)
  //             });

  //             property = {
  //                 label: element.label,
  //                 type: 'treemultiselect',
  //                 fieldSettings: {
  //                     treeExpandAll: true,
  //                     listValues: values
  //                 }
  //             }
  //         }

  //         if (element.attribute === 'tag') {
  //             tags.forEach(item => {
  //                 let tempItem = {
  //                     value: item.id,
  //                     title: item.label,
  //                     color: item.color
  //                 }

  //                 values.push(tempItem)
  //             });

  //             property = {
  //                 label: element.label,
  //                 type: 'treemultiselect',
  //                 fieldSettings: {
  //                     treeExpandAll: true,
  //                     listValues: values
  //                 }
  //             }
  //         }
  //     }
  //     else {
  //         property = {
  //             label: element.label,
  //             type: element.type
  //         }

  //     }

  //     if (['dropdown', 'check', 'radio', "multiple"].includes(element.type) && !element.is_basic) {
  //         let values = []
  //         element.type = 'multiple';
  //         element.options.forEach(item => {
  //             let tempItem = {
  //                 value: item.value,
  //                 title: item.label,
  //             }

  //             values.push(tempItem)
  //         });

  //         property = {
  //             lable: element.label,
  //             type: 'treemultiselect',
  //             fieldSettings: {
  //                 treeExpandAll: true,
  //                 listValues: values
  //             }
  //         }
  //     }

  //     fields[element.attribute] = property

  // }
  fields.address = {
    label: "Địa chỉ",
    type: "!struct",
    subfields: {
      address1: {
        label: "Địa chỉ 1",
        type: "text",
      },
      address2: {
        label: "Địa chỉ 2",
        type: "text",
      },

      city: {
        label: "Thành phố",
        type: "text",
      },

      province: {
        label: "Tỉnh thành",
        type: "text",
      },

      country_name: {
        label: "Quốc Gia",
        type: "text",
      },
    },
  };

  fields.formula = {
    label: "Tùy chỉnh",
    type: "formular",
  };

  fields.orders = {
    label: "Đơn hàng",
    type: "!group",
    mode: "array",
    conjunctions: ["AND", "OR"],
    defaultOperator: "some",
    initialEmptyWhere: true,
    showNot: false,
    subfields: {
      created_on_date: {
        type: "date",
        label: "Ngày mua",
      },
      total_price: {
        type: "number",
        label: "Tổng tiền",
      },
      sub_total_price: {
        type: "number",
        label: "Tổng tiền sản phẩm",
      },

      // phần product_ids nằm trong line_item. vậy nên nếu muốn tìm kiếm sản phẩm theo tên thì phải
      // tìm trong chi tiết sản phẩm. nếu truy vấn thì phần sản phẩm sẽ giống với phần chi tiết sản phẩm nên bị dư
      //       product_ids: {
      //         type: "text",
      //         label: "Sản phẩm",
      //       },

      source_name: {
        type: "text",
        label: "Nguồn Đơn",
        //     operators: ["array_like"],
        //     widgets: { text: { operators: ["array_like"] } }
      },

      line_items: {
        label: "Chi tiết đơn hàng",
        type: "!group",
        mode: "array",
        conjunctions: ["AND", "OR"],
        defaultOperator: "some",
        initialEmptyWhere: true,
        showNot: false,
        subfields: {
          product_id: {
            type: "text",
            label: "Sản phẩm",
          },
          quantity: {
            type: "number",
            label: "Số Lượng",
          },
        },
      },
    },
  };

  const mapListType = [
    {
      type: "text",
      typeMap: "text",
    },
    {
      type: "long_text",
      typeMap: "text",
    },
    {
      type: "number",
      typeMap: "number",
    },
    {
      type: "date",
      typeMap: "datetime",
    },
    {
      type: "dropdown",
      typeMap: "select",
    },
    {
      type: "multiple",
      typeMap: "multiselect",
    },
    {
      type: "check",
      typeMap: "boolean",
    },
    {
      type: "radio",
      typeMap: "select",
    },
    {
      type: "datetime",
      typeMap: "datetime",
    },
  ];
  customFields.forEach((element) => {
    var type = "text";
    var mapType = mapListType.find((x) => x.type == element.type);
    if (mapType) type = mapType.typeMap;
    var obj = { type: type, label: element.label };

    if (type == "select" || type == "multiselect") {
      obj.fieldSettings = {
        listValues: element.options,
      };
    }
    fields[element.attribute] = obj;
  });
  config.fields = fields;
  config.tab = {
    tab: "1",
  };
  component.setState({ loadingFinish: false });
};

export { setQueryField };
