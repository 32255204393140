import React, { useState } from "react";
import { Card, Button, Form, Input, Row, Col, Space, Typography, message, Checkbox } from "antd";
import { PlusOutlined, PlusSquareOutlined, SettingOutlined, DeleteOutlined } from "@ant-design/icons";
import ModalWayToSendCampaign from "./ModalWayToSendCampaign";
import ModalAppConfig from "./ModalAppConfig";
import api from "api";

const { Meta } = Card;
const { Text } = Typography;

/**
 * Màn hình Phương thức gửi ở bước 2
 * @param {Function} prevStep hàm quay về màn hình ở bước trước
 * @param {Function} nextStep hàm tiến tới bước tiếp theo
 * @param {Array} lstApp danh sách app được đăng kí
 * @param {Array} lstSelectedApp danh sách app được chọn
 * @param {*} setLstSelectedApp thay đổi danh sách app được chọn
 * @author huy
 */
function WayToSendCampaign({ prevStep, nextStep, lstApp, lstSelectedApp, setLstSelectedApp, value, onChange, sendAllApp, onChangeSendAllApp }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModapConfigOpen, setIsModapConfigOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [appConfig, setAppConfig] = useState({});
  const [appConfigIndex, setAppConfigIndex] = useState();
  const [modeConfig, setModeConfig] = useState("edit");
  const [formConfig, setFormConfig] = useState([]);
  const [error, setError] = useState(null);
  const bodyCardStyle = {
    minHeight: "48vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  /**
   * Hàm xử lý khi nhấn nút thêm phương thức
   * @author huy
   */
  const showModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Hàm xử lý khi nhấn nút thoát modal chọn app
   * @author huy
   */
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /**
   * Hàm render danh sách app được chọn cho chiến dịch
   * @param {*} item App được chọn
   * @param {*} index vị trí của App trong mảng
   * @author huy
   */
  const renderListAppSelected = (item, index) => {
    return (
      <Col xs={24} md={12} lg={8} xl={6} xxl={4}>
        <Card
          style={{ boxShadow: "2px 4px 12px rgba(0,0,0,.08)", cursor: "pointer" }}
          cover={<img alt="example" src={item?.logo} style={{ width: "88px", height: "88px", padding: 8, objectFit: "cover" }} />}
          actions={[
            <SettingOutlined key="setting" onClick={() => configApp(item, index, "edit")} />,
            <DeleteOutlined key="delete" onClick={() => deleteApp(index)} />,
          ]}
        >
          <Meta title={item?.name} description={item?.description} />
        </Card>
      </Col>
    );
  };

  /**
   * Hàm xử lý khi nhấn vào icon cấu hình app ở màn hình phương thức gửi
   * hoặc chọn app trong modal chọn phương thức
   * @author huy
   */
  const configApp = async (item, index, mode) => {
    setAppConfig(item);
    setAppConfigIndex(index);
    setModeConfig(mode);

    //lấy mẫu form config cho app từ db
    var dataPost = {
      query: { app_id: item.id },
      limit: 100,
      skip: 0,
    };
    setLoadingModal(true);
    var res = await api.post("app/listconfig", dataPost);

    if (res.response) {
      setFormConfig(res.response);
      setLoadingModal(false);
      setIsModapConfigOpen(true);
    }
  };

  /**
   * Hàm xử lý khi nhấn nút thoát modal config app
   * @author huy
   */
  const handleCancelModalConfig = () => {
    setIsModapConfigOpen(false);
  };

  /**
   * Hàm xử lý xoá app khỏi danh sách được chọn
   * @param {*} index vị trí của App trong mảng
   * @author huy
   */
  const deleteApp = (index) => {
    var newDataPost = value;
    var newLst = [...lstSelectedApp];

    //xoá app khỏi way_to_send trong dataPost
    var indexWay = newDataPost.findIndex((item) => item.id === newLst[index].id);
    newDataPost.splice(indexWay, 1);

    //xoá app khỏi danh sách được chọn
    newLst.splice(index, 1);

    onChange(newDataPost);
    setLstSelectedApp([...newLst]);
  };

  /**
   * Hàm xử lý chọn app sau khi đã cấu hình app
   * @param {*} index vị trí của App trong mảng
   * @author huy
   */
  const selectApp = (index) => {
    setLstSelectedApp([...lstSelectedApp, lstApp[index]]);
  };

  /**
   * Hàm xử lý khi nhấn nút tiếp tục
   * @author huy
   */
  const onClickNextStep = () => {
    if (lstSelectedApp.length > 0) {
      nextStep();
      setError(null);
    } else {
      setError({ message: "Bạn chưa chọn phương thức gửi !" });
      //  message.error('Bạn chưa chọn phương thức gửi !', 3);
    }
  };

  const onChangeCheckBox = (e) => {
    onChangeSendAllApp(e.target.checked);
  }

  return (
    <>
      <Card
        title="Phương thức gửi"
        style={{ width: "100%" }}
        extra={<Checkbox onChange={onChangeCheckBox} checked={sendAllApp}>Tất cả</Checkbox>}
        headStyle={{ borderBottom: 0 }}
        bodyStyle={lstSelectedApp.length > 0 ? { minHeight: "48vh" } : bodyCardStyle}
      >
        {lstSelectedApp.length > 0 ? (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={8} xl={6} xxl={4}>
              <Card
                style={{ width: "100%", height: "100%", boxShadow: "2px 4px 12px rgba(0,0,0,.08)", cursor: "pointer" }}
                bodyStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={showModal}
              >
                <Space direction="vertical">
                  <PlusSquareOutlined style={{ fontSize: 48, color: "#8c8c8c" }} />
                  <Text>Thêm mới</Text>
                </Space>
              </Card>
            </Col>

            {lstSelectedApp.map((item, index) => {
              return renderListAppSelected(item, index);
            })}
          </Row>
        ) : (
          <div style={{ alignItems: "center" }}>
            {error && <div style={{ color: "#ff4d4f" }}>{error.message}</div>}
            <div>
              <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm phương thức
              </Button>
            </div>
          </div>
        )}
      </Card>
      {nextStep && prevStep && (
        <Space style={{ float: "right", marginTop: 16 }}>
          <Button onClick={prevStep}>Trở lại</Button>
          <Button onClick={onClickNextStep} type="primary">
            Tiếp tục
          </Button>
        </Space>
      )}

      <ModalWayToSendCampaign
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        loadingModal={loadingModal}
        lstApp={lstApp}
        lstSelectedApp={lstSelectedApp}
        setLstSelectedApp={setLstSelectedApp}
        configApp={configApp}
      />

      <ModalAppConfig
        isModalOpen={isModapConfigOpen}
        handleCancel={handleCancelModalConfig}
        handleCancelModalWayToSendCampaign={handleCancel}
        appConfig={appConfig}
        appConfigIndex={appConfigIndex}
        modeConfig={modeConfig}
        selectApp={selectApp}
        formConfig={formConfig}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default WayToSendCampaign;
