import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import TemplateOption from "./templateOption";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ListOption from "./listOption";
import CompleteCampaign from "./completeCampaign";
function AddCampaignExcel() {
  const location = useLocation();
  const oaId = location.state.oaId;
  const oaName = location.state.oaName;
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [dataPost, setDataPost] = useState({
    name: "",
    schedule: {
      date: null,
      time: null,
      type: "zns_excel",
    },
    customer_segment_id: null,
    send_all_app: false,
    way_to_send: [
      {
        id: "zalo",
        parameters: [
          {
            type: "id",
            value: "7057a1fa-8b5e-4fc2-a97f-92d83986b43e",
          },
        ],
      },
    ],
    content: {
      email: {
        html: null,
        json: null,
        subject: null,
        attachments: [],
      },
      default_email: {
        html: null,
        json: null,
        subject: null,
        attachments: [],
      },
      zalo: {
        preview_url: "",
        price: null,
        params: [],
        oa_id: oaId,
        oa_name: oaName,
        template_id: null,
      },
      esms: {
        type: "sms",
        sms_content: {
          content: "",
          brand_name: "",
          list_key: [],
          sms_template: "",
        },
        zalo_content: {
          oa_id: "",
          template_id: "",
          params: [],
        },
      },
      smsfpt: null,
    },
  });

  // console.log(oaId);
  const onChange = (value) => {
    var newData = { ...dataPost };
    Object.keys(value).forEach((key) => {
      newData[key] = value[key];
    });
    setDataPost(newData);
  };

  const back = () => {
    navigate("/campaign/zns_excel");
  };

  // useEffect(() => {
  //   console.log(dataPost);
  // }, [dataPost]);
  return (
    <div style={{ backgroundColor: "white", padding: 16, gap: 16, display: "flex", flexDirection: "column" }}>
      <Steps
        size="small"
        current={currentStep}
        items={[
          {
            title: "Chọn mẫu",
            description: "Chọn mẫu tin ZNS",
          },
          {
            title: "Thiết lập danh sách",
            description: "Danh sách người nhận",
          },
          {
            title: "Hoàn tất",
            description: "Gửi tin",
          },
        ]}
      />
      {currentStep === 0 && <TemplateOption onChange={onChange} oaId={oaId} oaName={oaName} setCurrentStep={setCurrentStep} dataPost={dataPost} back={back} />}
      {currentStep === 1 && <ListOption onChange={onChange} oaId={oaId} oaName={oaName} setCurrentStep={setCurrentStep} dataPost={dataPost} back={back} />}
      {currentStep === 2 && (
        <CompleteCampaign onChange={onChange} oaId={oaId} oaName={oaName} setCurrentStep={setCurrentStep} dataPost={dataPost} back={back} />
      )}
    </div>
  );
}

export default AddCampaignExcel;
