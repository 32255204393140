import React, { Component } from "react";

import { Form, Input, InputNumber, Button, Space, Modal, Select, Popconfirm, Card, Row, Col, message, Empty, Skeleton } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Typography } from "antd";
import api from "api";
import FieldNew from "./fieldnew";
import { dataTypes, makeid } from "common/custom_field";
import PopupCustomerField from "components/customer/Excel/PopupCustomerField";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "rgb(228, 230, 235)",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});
export default class CustomerFieldNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      items: [],
      dataTypes: dataTypes,
      editID: "",
      itemTam: [],
      show: false,
    };
    this.form = React.createRef();
    this.PopupCustomerField = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api
      .post("customerconfig/getconfig", { query: { key: "field" } })
      .then((res) => {
        this.setState({ items: res, show: true });
      })
      .catch((err) => {
        this.setState({ show: true });
      });
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    var items = [];
    var lst = [...this.state.items];
    var lst = reorder(lst, result.source.index, result.destination.index);
    this.setState(
      {
        items: lst,
      },
      this.save
    );
  };

  getColID = (e) => {
    var col = "";
    while (true) {
      col = "col_" + makeid(5);
      if (this.state.items.findIndex((x) => x.id == col) < 0) return col;
    }
  };

  addField = (item) => {
    var values = { ...item, label: "Untitle", id: this.getColID(), attribute: "" };
    values.showEdit = true;

    //api.post("customer/addfield",values)
    var lst = [...this.state.itemTam];
    lst.push(values);
    this.setState({
      itemTam: lst,
    });
  };

  save = () => {
    var value = this.state.items;
    api.post("customerconfig/save", { config: value, key: "field" });
    this.setState({ isVisible: false });
  };

  updateItem = (item, type) => {
    var { id, label } = item;
    var lst = [...this.state.items];
    var lsttam = [...this.state.itemTam];
    var index = lsttam.findIndex((x) => x.id == id);
    lsttam[index] = item;
    delete item.showEdit;
    if (type == "edit") {
      var index = lst.findIndex((x) => x.id == id);
      lst[index] = item;
      delete item.showEdit;
    } else {
      delete item.showEdit;
      lst.push(item);
    }
    this.setState({
      items: lst,
      itemTam: lsttam,
    });

    api.post("customerconfig/save", { config: lst, key: "field" });
  };

  deleteItem = (id) => {
    var lsttam = [...this.state.itemTam];
    var i = lsttam.findIndex((x) => x.id == id);

    lsttam.splice(i, 1);
    this.setState({
      itemTam: lsttam,
    });

    var lst = [...this.state.items];
    var i = lst.findIndex((x) => x.id == id);
    if (i >= 0) {
      lst.splice(i, 1);
      this.setState({
        items: lst,
      });
    }
    api.post("customerconfig/save", { config: lst, key: "field" });
  };

  render() {
    var lst = this.state.items;
    return (
      <Card
        title="Cấu hình trường dữ liệu"
        extra={
          <Button onClick={() => this.PopupCustomerField.current.open()} type="primary">
            <i className="fa fa-plus" aria-hidden="true"></i>&ensp;Thêm mới
          </Button>
        }
      >
        {this.state.show ? (
          <Row>
            <Col span={24}>
              {lst.length > 0 || !lst ? (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {this.state.items.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <li style={{ margin: "0px", padding: "10px" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <FieldNew lstdata={this.state.items} reloadOption={this.getData} data={item} deleteItem={this.deleteItem} save={this.updateItem} showEdit={item.showEdit}></FieldNew>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div>
                  <Empty />
                  <div style={{ display: "flex" }}>
                    <Button type="primary" style={{ margin: "auto", width: 200 }} onClick={() => this.PopupCustomerField.current.open()}>
                      <i className="fa fa-plus" aria-hidden="true"></i>&ensp;Thêm mới
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <Skeleton active />
        )}

        <PopupCustomerField ref={this.PopupCustomerField} reloadOption={this.getData}></PopupCustomerField>
      </Card>
    );
  }
}
