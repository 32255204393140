import React, { Component } from "react";
import api from "api";
import { Input, Button, Form, Checkbox, Row, Col, message, Modal, Radio } from "antd";

export default class PopupTestSendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {},
      receiver: "",
      loadBtn: false,
      element: {},
    };
    this.inputType = React.createRef();

    this.form = React.createRef();
  }

  open = (data, element) => {
    this.getData(data, element);
  };

  getData = (data, element) => {
    api.get("app/defaultemail/config/getmailtest").then((res) => {
      this.setState({ visible: true, receiver: res, data: data, element: element });
    });
  };

  validateemail = (data) => {
    if (data == null || data === "") return false;

    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var check = filter.test(data);
    if (check) {
      return true;
    }

    return false;
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onChange = (value) => {
    this.setState({ receiver: value });
    if (value == null || value == "") {
      message.error("Bạn chưa nhập email người nhận");
      return;
    }
  };

  send = () => {
    if (!this.validateemail(this.state.receiver)) {
      message.error("Sai email người nhận");
      return;
    }

    var data = {
      event: "send",
      data: {
        html: "VGhpcyBpcyBhIGVtYWlsIHRlc3Qgb2Ygd2Vic2l0ZSBMb3lhbHR5VGlnZXI=",
        subject: "LoyaltyTiger test email",
        attachment: [],
        from: "",
        to: {
          email: this.state.receiver,
        },
      },
      parameters: [
        {
          type: "id",
          value: this.state.data.id + "_" + this.state.element.id,
        },
      ],
    };
    this.setState({
      loadBtn: true,
    });
    api
      .post("app/defaultemail/send_test/sendtestmail", data)
      .then((res) => {
        message.success("Gửi thành công", 3);
        this.setState({ loadBtn: false });
      })
      .catch((err) => {
        console.log("err", err);
        message.error(`Gửi thất bại`, 3);
        this.setState({ loadBtn: false });
      });
  };

  render() {
    return (
      <Modal
        title="Test Email "
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        footer={[
          <Button
            onClick={() => {
              this.handleCancel();
            }}
          >
            Thoát
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              this.setState({ loadBtn: true }, () => {
                this.send();
              });
            }}
          >
            Gửi
          </Button>,
        ]}
      >
        <div>
          <div style={{ marginBottom: 15 }}>
            <label>Người nhận:</label>
          </div>
          <Input
            onPressEnter={(e) => {
              this.onChange(e.target.value);
              this.setState({ loadBtn: true }, () => {
                this.send();
              });
            }}
            value={this.state.receiver}
            onChange={(e) => {
              this.onChange(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
    );
  }
}
