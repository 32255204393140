import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Tabs, Checkbox, DatePicker, Space } from "antd";
import api from "api";
import AntTable from "components/common/AntTable";
import SearchBox from "components/common/SearchBox/SearchBox";
import moment from "moment";
import { formatL } from "../../common/date_time";
import { formatCurrency } from "components/common/format/number";

import PopupQr from "./popup_qr";
import numeral from "numeral";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const { Search } = Input;
const { Option } = Select;

const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];

export default class PointHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: {
        query: {
          $and: [{}],
        },
      },
      token: "",
      status: "",
      isStatus: false,
      isCreateDate: false,
      show: false,
      today: new Date(),
    };

    this.tbl = React.createRef();
    this.SearchBox = React.createRef();
    this.PopupQr = React.createRef();
  }

  // getAccessToken = () => {
  //   api
  //     .post("pointcode/getaccesstoken")
  //     .then((res) => {
  //       message.success("Lấy token thành công");
  //       this.setState({ token: res.accesstoken });
  //     })
  //     .catch((err) => {
  //       message.error("Lấy token thất bại");
  //     });
  // };

  getColumn = () => {
    var columns = [
      {
        title: "Mã",
        dataIndex: "id",
        key: "id",
        sorter: true,
        render: (text, record, index) => {
          return <div>{record.id} </div>;
        },
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_on",
        key: "create_on",
        sorter: true,
        render: (text, record, index) => {
          var date = new Date(record.create_on);
          date = moment(date);
          return <div>{formatL(date)}</div>;
        },
      },

      {
        title: "Ghi Chú",
        dataIndex: "note",
        key: "note",
      },

      {
        title: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Popconfirm title="Bạn có chắc muốn hoàn tác không? " onConfirm={() => this.delete(record.id)} okText="Có" cancelText="Không">
                <Button type="link">Hoàn tác</Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
    return columns;
  };

  processPointCode = (id, action) => {
    console.log("fhfgg", id);
    api
      .post("pointcode/adminProcessPointCode", {
        data: { id: id, action: action },
      })
      .then((res) => {
        this.tbl.current.reload();
      });
  };

  handleStatusChange = (name, value) => {
    // debugger
    value = value.toLowerCase();
    var filter = {};
    filter = this.state.filter;
    var index = filter.query.$and.findIndex((x) => x[name]);
    if ((name == "status" && value === "all") || (name === "ids" && value === "")) {
      if (index > -1) filter.query.$and.splice(index, 1);
    } else {
      if (index > -1) {
        filter.query.$and[index][name] = value;
      } else {
        filter.query.$and.push({ [name]: value });
      }
    }

    this.tbl.current.reload(false);
    // this.setState({ filter: filter },() => {this.tbl.current.reload();});
    this.setState({ filter: filter });
  };

  checkBoxControl = (name, affectName, check) => {
    var state = { ...this.state };
    state[name] = check;
    if (!check) {
      var array = state.filter.query.$and.filter((x) => !x[affectName]);
      state.filter.query.$and = array;
    } else {
      var array = state.filter.query.$and.filter((x) => !x[affectName]);
      state.filter.query.$and = array;
      var obj = {};
      obj[affectName] = { $gte: this.subtractDate(this.state.today, 30), $lte: this.state.today };
      state.filter.query.$and.push(obj);
    }
    state[affectName] = null;
    this.setState(state, () => {
      this.tbl.current.reload();
    });
  };

  onRangePickerChange = (name, value) => {
    var state = { ...this.state };
    var array = state.filter.query.$and.filter((x) => !x[name]);
    var obj = {};
    obj[name] = { $gte: value[0], $lte: value[1] };
    array.push(obj);
    state.filter.query.$and = array;
    state[name] = value;
    this.setState(state, () => {
      this.tbl.current.reload();
    });
  };

  subtractDate = (date, minusDays) => {
    var result = new Date();
    var dateOffset = 24 * 60 * 60 * 1000 * minusDays;
    result.setTime(date.getTime() - dateOffset);
    return result;
  };

  onSelectFilterChange = (key, isSelected) => {
    // var filter = { ...this.state.filter };
    // if (isSelected) {
    //     return;
    // }
    // switch (key) {
    // }
  };
  // onFilterChange = (name, value) => {
  //   var filter = {};
  //   filter = this.state.filter;
  //   var index = filter.query.$and.findIndex((x) => x[name]);
  //   if (value == "") {
  //     if (index > -1) filter.query.$and.splice(index, 1);
  //   } else {
  //     if (index > -1) {
  //       filter.query.$and[index][name].$regex = value;
  //     } else {
  //       filter.query.$and.push({[name]: { $regex: value } });
  //     }
  //   }
  //   this.setState({ filter: filter },() => {
  //     this.tbl.current.reload();
  //   });
  // }

  getFilters = () => {
    var filters = [
      {
        Key: "status",
        Name: "Trạng thái",
        Component: (
          <Col span={6}>
            <div>
              <label>Trạng thái</label>
              <div style={{ display: "flex" }}>
                <Select
                  defaultValue="all"
                  style={{ width: "80%" }}
                  onChange={(value) => {
                    this.handleStatusChange("status", value);
                  }}
                >
                  <Option value="all">Tất Cả</Option>
                  <Option value="new">Mới</Option>
                  <Option value="success">Đã Tích Điểm</Option>
                  <Option value="cancel"> Đã Huỷ</Option>
                </Select>
              </div>
            </div>
          </Col>
        ),
      },
      {
        Key: "create_date",
        Name: "Ngày tạo",
        Component: (
          <Col span={6}>
            <div style={{ display: "inline-block" }}>
              <label>Ngày tạo</label>
              <div style={{ display: "flex", marginRight: 10 }}>
                <div style={{ display: "flex", flex: 1, padding: "5px 8px", border: "1px solid #dfdfdf" }}>
                  <Checkbox
                    style={{ margin: "auto" }}
                    onChange={(e) => {
                      this.checkBoxControl("isCreateDate", "create_on", e.target.checked);
                    }}
                    value={this.state.isCreateDate}
                  ></Checkbox>
                </div>

                <RangePicker
                  defaultValue={[moment(this.subtractDate(this.state.today, 30), dateFormat), moment(this.state.today, dateFormat)]}
                  value={this.state.create_date}
                  disabled={this.state.isCreateDate === false ? [true, true] : [false, false]}
                  style={{ width: "100%" }}
                  onChange={(e) => this.onRangePickerChange("create_on", e)}
                  format={dateFormat}
                />
              </div>
            </div>
          </Col>
        ),
      },
    ];
    return filters;
  };

  render() {
    var filters = [];
    filters = this.getFilters();
    return (
      <>
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: 20,
            border: "1px solid #dfdfdf",
            marginBottom: 30,
          }}
        >
          <div>
            <SearchBox
              name="pointhistory"
              data={filters}
              onSelectFilterChange={this.onSelectFilterChange}
              onChange={(value) => {
                this.handleStatusChange("ids", value);
              }}
              placeholder="Thông tin"
              dataButton={[
                <Button
                  type="primary"
                  onClick={() => {
                    this.PopupQr.current.open();
                  }}
                  style={{ marginRight: 15 }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Thêm mới
                </Button>,
              ]}
              // onSearch={() => {
              //   this.tbl.current.reload();
              // }}

              ref={this.SearchBox}
            />
          </div>
        </div>
        {/* <div style={{ backgroundColor: "#ffffff", padding: 20, border: "1px solid #dfdfdf", marginBottom: 30 }}>
          <div style={{ height: 80 }}>
            <div style={{ marginBottom: 20 }}>
              <Input
                readOnly
                onClick={(e) => {
                  navigator.clipboard.writeText(this.state.token);
                  message.success("coppied");
                }}
                value={this.state.token}
              ></Input>
            </div>
            <div style={{ float: "right" }}>
              <Button
                type="primary"
                onClick={() => {
                  this.getAccessToken();
                }}
              >
                Lấy token
              </Button>
            </div>
          </div>
        </div> */}
        <div>
          <Card>
            <AntTable
              columns={[
                {
                  title: "Mã điểm",
                  dataIndex: "id",
                  key: "id",
                  sorter: true,
                  render: (text, record, index) => {
                    // console.log("record", record);
                    return (
                      <div>
                        <Space>
                          {record.status == "success" && <i className="far fa-check-circle" style={{ color: "#4CAF50" }}></i>}

                          {record.status == "cancel" && <i className="fas fa-ban" style={{ color: "#FF5252" }}></i>}

                          {record.status == "new" && <i className="far fa-clock" style={{ color: "#2196F3" }}></i>}

                          {record.id ?? ""}
                        </Space>
                        <div></div>
                      </div>
                    );
                  },
                },
                {
                  title: "Thời gian",
                  dataIndex: "create_on",
                  key: "create_on",
                  sorter: (a, b) => a.create_on - b.create_on,
                  defaultSortOrder: "descend",
                  render: (text, record, index) => {
                    return (
                      <div>
                        {record.create_on ? formatL(record.create_on) : ""} <div>{record.create_by}</div>
                        {record.update_on && (
                          <div>
                            Cập nhật {formatL(record.update_on)} bởi {record.update_by}
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Nội dung",
                  dataIndex: "type",
                  key: "type",
                  sorter: true,
                  render: (text, record, index) => {
                    if (record.type == "order") {
                      return (
                        <div>
                          <i className="fas fa-file-invoice"></i> {record.order_id}
                          <div>
                            <i className="fas fa-dollar-sign"></i> {numeral(record.total_amount).format("#,##0")}
                          </div>
                          <div>
                            <i> {record.note}</i>
                          </div>
                        </div>
                      );
                    }

                    if (record.type == "product") {
                      return (
                        <div>
                          <i className="fas fa-glass-martini"></i> {record.product_id}.
                          <div>
                            <i className="fas fa-dollar-sign"></i> {numeral(record.total_amount).format("#,##0")}
                          </div>
                          <div>
                            <i> {record.note}</i>
                          </div>
                        </div>
                      );
                    }

                    if (record.type == "point") {
                      return (
                        <div>
                          <div>
                            <i className="fas fa-hand-holding-usd"></i> {numeral(record.point).format("#,##0")}
                          </div>
                          <div>
                            <i> {record.note}</i>
                          </div>
                        </div>
                      );
                    }
                    return <div></div>;
                  },
                },
                {
                  title: "",

                  sorter: false,
                  render: (text, record, index) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Button type="link" onClick={(e) => this.PopupQr.current.open(record.id)}>
                          <i className="fas fa-edit"></i>
                        </Button>
                        {record.request_customer_id && (
                          <div>
                            <Button type="link" onClick={() => this.processPointCode(record.id, "confirm")}>
                              <i className="fas fa-check-square"></i>
                            </Button>
                            <Button type="link" onClick={() => this.processPointCode(record.id, "refuse")}>
                              <i className="fas fa-calendar-times"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              customSearch={this.state.filter}
              tableName={"tblpointcode"}
              ajax="pointcode/list"
              ref={this.tbl}
            ></AntTable>
          </Card>
        </div>

        <PopupQr
          ref={this.PopupQr}
          onSaved={(e) => {
            this.tbl.current.reload();
          }}
        />
      </>
    );
  }
}
