import React, { Component } from "react";
import { Modal, Form, Input, Checkbox, Button, message } from "antd";
import api from "api";
export default class PopupEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mode: "add",
      saveText: "Lưu",
      loading: false,
    };
    this.form = React.createRef();
  }
  componentDidMount = () => {};
  open = async (mode, data) => {
    this.setState({ visible: true, mode: mode }, () => {
      if (mode == "edit") {
        var keyData = { ...data };
        keyData.permission = {};
        data.permission.map((permission) => (keyData.permission[permission] = true));
        this.form.current.setFieldsValue(keyData);
      } else {
        this.form.current.resetFields();
      }
    });
  };
  save = (values) => {
    console.log("values", values);
    this.setState({ loading: true, saveText: "Đang xử lý" });
    api
      .post("customerconfig/saveapikey", { data: values })
      .then((res) => {
        message.success("Tạo Key Thành Công");
        this.props.reload();
      })
      .catch((err) => {
        console.log(err);
        message.error("Đã có lỗi xảy ra, vui lòng thử lại");
      })
      .finally(() => {
        this.setState({ visible: false, loading: false, saveText: "Lưu" });
      });
  };
  render() {
    return (
      <Modal
        title="Cấu hình OPEN API"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        onOK={() => this.form.current.submit()}
        style={{ height: "25%" }}
        footer={[
          <Button onClick={() => this.setState({ visible: false })}>Thoát</Button>,
          <Button
            type="primary"
            onClick={(e) => {
              this.form.current.submit();
            }}
            loading={this.state.loading}
          >
            {this.state.saveText}
          </Button>,
        ]}
      >
        <Form ref={this.form} layout={"horizontal"} onFinish={this.save}>
          <Form.Item
            name="first_name"
            label={"Tên API"}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập vào tên API!",
              },
              {
                max: 200,
                message: "Tối đa chỉ 200 ký tự",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <span>Phân quyền</span>
          <div style={{ display: "flex" }}>
            <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.permission !== currentValues.permission}>
              {({ getFieldValue }) => {
                var customer = getFieldValue(["permission", "customer"]);
                return (
                  <Form.Item
                    name={["permission", "order"]}
                    label={"Đơn hàng"}
                    valuePropName="checked"
                    rules={
                      !customer && [
                        {
                          validator: async (_, checked) => {
                            if (!checked) {
                              return Promise.reject(new Error("Cần phải chọn ít nhất 1 phân quyền"));
                            }
                          },
                        },
                      ]
                    }
                  >
                    <Checkbox />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item style={{ marginLeft: "40%" }} name={["permission", "customer"]} label={"Khách hàng"} valuePropName="checked">
              <Checkbox id="customerCheckbox" />
            </Form.Item>
          </div>
          <Form.Item name="id">
            <Input hidden={true} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
