import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, CarryOutOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Pie, Line } from "@ant-design/plots";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency } from "../common/format/number";
import CustomerPointEverydayChart from "./customerpointeveryday_chart";
import CustomerPerTier from "./customerpertier_chart";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalVoucherOrder: 0,
      voucherRevenue: 0,
      totalCampaign: 0,
      totalCustomer: 0,
      totalPointAdded: 0,
      totalPointUsed: 0,
      currency: "VND",
      repeatPurchaseRate: 0,
      returnTime: 4,
      loyalRate: 0,
      save: false,
    };
  }

  componentDidMount = () => {
    var start_date = moment().subtract(7, "d");
    var end_date = moment();
    this.getAllData(start_date, end_date);
  };
  getAllData = (start_date, end_date) => {
    this.getTotalOrderAndRevenue(start_date, end_date);
    this.getTotalRunningCampaign();
    this.getTotalPointAdded();
    this.getTotalPointUsed();
    this.getRepeatPurchaseRate();
  };

  //tùng code
  getTotalOrderAndRevenue(start_date, end_date) {
    api
      .post("dashboard/getvoucherorder", {
        start_date: start_date,
        end_date: end_date,
      })
      .then((res) => {
        this.setState({ totalVoucherOrder: res.totalOrder });
        this.setState({ voucherRevenue: res.revenue });
        this.setState({ currency: res.currency });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getTotalRunningCampaign() {
    api
      .post("dashboard/getrunningcampaign")
      .then((res) => {
        this.setState({ totalCampaign: res.totalCampaign });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTotalPointAdded() {
    var datapost = {
      end_date: moment().add(1, "day"),
      start_date: moment().add(-6, "day"),
    };
    api
      .post("dashboard/gettotalpointadded", datapost)
      .then((res) => {
        this.setState({ totalPointAdded: res });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  getTotalPointUsed() {
    var datapost = {
      end_date: moment().add(1, "day"),
      start_date: moment().add(-6, "day"),
    };
    api
      .post("dashboard/gettotalpointused", datapost)
      .then((res) => {
        this.setState({ totalPointUsed: res });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  //tùng endcode

  //hoàng code

  getRepeatPurchaseRate = () => {
    api
      .post("dashboard/getrepeatpurchaserate")
      .then((res) => {
        this.setState({ repeatPurchaseRate: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //hoàng endcode

  render() {
    return (
      <>
        <Col span={8}>
          <Card>
            <Statistic title="Tổng số khách hàng" value={this.props.totalCustomer} precision={0} valueStyle={{ color: "#3f8600" }} prefix={<i className="fa fa-users"></i>} suffix="" />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Tổng số điểm mới" value={this.state.totalPointAdded} precision={0} valueStyle={{ color: "#3f8600" }} prefix={<i className="fa fa-star"></i>} suffix="" />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Tổng số điểm đã sử dụng" value={this.state.totalPointUsed} precision={0} valueStyle={{ color: "#3f8600" }} prefix={<i className="fa fa-star"></i>} suffix="" />
          </Card>
        </Col>

        <Col span={8}>
          <Card>
            <Statistic
              title="Số lượng chiến dịch đang chạy"
              value={this.state.totalCampaign}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<i className="fas fa-bullhorn"></i>}
              suffix="Chiến dịch"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Tổng số đơn hàng" value={this.state.totalVoucherOrder} precision={0} valueStyle={{ color: "#3f8600" }} prefix={<CarryOutOutlined />} suffix="Đơn" />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Doanh số" value={this.state.voucherRevenue} precision={2} valueStyle={{ color: "#3f8600" }} prefix={<DollarCircleOutlined />} suffix={this.state.currency} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Tỷ lệ quy đổi"
              value={Math.round((this.state.totalPointUsed / this.state.totalPointAdded || 0) * 100) / 100}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<i className="fas fa-percent"></i>}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="RPP"
              value={Math.round(this.state.repeatPurchaseRate * 100) / 100}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<i className="fas fa-rupee-sign"></i>}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={8}>
          <PercentLoyalty></PercentLoyalty>
        </Col>
      </>
    );
  }
}
