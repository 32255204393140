import { Button, Checkbox, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import api from "api";

function AddNewConnectModal({ isModalOpen, setIsModalOpen }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true);
        var payload = form.getFieldsValue();
        saveConfig(payload);
      })
      .catch((err) => {
        console.log("lỗi");
      });
    // setIsModalOpen(false);
  };

  const saveConfig = (payload) => {
    api
      .post("app/mshopkeeper/config/save", payload)
      .then((res) => {
        setIsLoading(false);
        message.success("Lưu thành công");
        setIsModalOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal confirmLoading={isLoading} title="Thêm mới KIOTVIET" open={isModalOpen} onOk={handleOk} okText="Lưu" onCancel={handleCancel} cancelText="Thoát">
      <Form layout="vertical" name="basic" form={form} autoComplete="off" initialValues={{ AppID: "MShopKeeperOpenPlatform" }}>
        <Form.Item
          label="Tên kết nối"
          name="Domain"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên kết nối với MISAeshop",
            },
          ]}
        >
          <Input placeholder="demoquanao.mshopkeeper.vn" />
        </Form.Item>
        <Form.Item
          label="AppID"
          name="AppID"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập AppID",
            },
          ]}
        >
          <Input readOnly />
        </Form.Item>

        <Form.Item
          label="Mã bảo mật"
          name="Secret"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mã bảo mật",
            },
          ]}
        >
          <Input placeholder="Mã bảo mật" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddNewConnectModal;
