import React, { Component } from "react";
import api from "api";
import { Modal, Form, Button, Input, Checkbox, message } from "antd";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: { use_key: false },
      mode: null,
      loadingBtn: false,
    };
    this.form = React.createRef();
  }
  open = (mode, element) => {
    // if (mode === "add") {
    var data = {
      url: "",
      consumerkey: "",
      consumerSecret: "",
    };
    this.setState({ data: data, visible: true, mode: mode }, () => {
      this.form.current.resetFields();
    });
    // } else {
    //   this.setState({ data: element, visible: true, mode: mode, type: element.type }, () => {
    //     this.setDataForm(element);
    //   });
    // }
  };
  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };
  saveSetting = (data) => {
    this.setState({ loadingBtn: true }, () => {
      if (data.use_key) {
        api
          .post("app/woo/config/save", data)
          .then((res) => {
            message.success("Lưu thành công", 3);
            this.props.getConfig();
            this.setState({ visible: false, data: {} });
            this.setState({ loadingBtn: false });
          })
          .catch((err) => {
            message.error(err, 3);
            this.setState({ loadingBtn: false });
          });
      } else {
        api.post("app/woo/config/getrequestpermisionurl", { url: data.url }).then((res) => {
          window.open(res, "_blank").focus();
          this.setState({ loadingBtn: false });
        });
      }
    });
  };

  render() {
    return (
      <Modal
        title="Cấu hình trang woocommerce"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false })}
        footer={[
          <div style={{ height: "30px" }}>
            <Button htmlType="submit" type="primary" form="form_woo" loading={this.state.loadingBtn}>
              Lưu
            </Button>
          </div>,
        ]}
        width="50%"
      >
        <Form ref={this.form} layout="vertical" onFinish={this.saveSetting} id="form_woo">
          <Form.Item
            name="url"
            label="Đường dẫn"
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập đường dẫn",
              },
            ]}
          >
            <Input placeholder="URL" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="use_key" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                var data = { ...this.state.data };
                data.use_key = e.target.checked;
                this.setState({ data: data });
              }}
            >
              Dùng khoá có sẵn
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="consumerKey"
            label="Consumer Key"
            hidden={!(this.state.data.use_key ?? false)}
            rules={[
              {
                required: this.state.data.use_key ?? false,
                message: "Bạn chưa nhập khoá",
              },
            ]}
          >
            <Input placeholder="Consumer Key" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="consumerSecret"
            label="Consumer Secret"
            hidden={!(this.state.data.use_key ?? false)}
            rules={[
              {
                required: this.state.data.use_key ?? false,
                message: "Bạn chưa nhập mã bí mật",
              },
            ]}
          >
            <Input placeholder="Consumer Secret" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
