import { useReducer } from "react";
import ProductContext from "./ProductContext";
import ProductReducer from "./ProductReducer";
// import { sumItems } from "./ProductReducer";
// import { getStorage } from "common/zalo_storage/ZaloStorage";

//Local Storage
const productItem = localStorage.getItem("productItem") ? JSON.parse(localStorage.getItem("productItem")) : {};
// var data = await getStorage({ keys: ["cartItems", "shippingAddress", "voucher"] });
const ProductState = ({ children }) => {
  //Initial State of the cart
  // const initialState = {
  //   cartItems: [],
  //   checkout: false,
  // };

  //Change the code above to that below to get the initial state from local storage
  const initialState = {
    productItem: productItem,
    // shippingAddress: data.shippingAddress ? JSON.parse(data.shippingAddress) : {},
    // voucher: data.voucher ? JSON.parse(data.voucher) : {},
    // ...sumItems(data.cartItems ? JSON.parse(data.cartItems) : []),
    // checkout: false,
  };

  //Set up the reducer
  const [state, dispatch] = useReducer(ProductReducer, initialState);

  //Function to handle when an item is added from the store into the Cart
  const updateToProduct = (payload) => {
    // debugger;
    dispatch({ type: "UPDATE_TO_PRODUCT", payload });
  };
  const addToProduct = (payload) => {
    console.log("payload", payload);
    dispatch({ type: "ADD_TO_PRODUCT", payload });
  };

  return (
    //Add the above functions into the Context provider, and pass to the children
    <ProductContext.Provider
      value={{
        // showCart: state.showCart,
        // cartItems: state.cartItems,
        // shippingAddress: state.shippingAddress,
        // voucher: state.voucher,
        // addToCart,
        // updateToCart,
        // removeFromCart,
        // increase,
        // decrease,
        // handleCheckout,
        // clearCart,
        // addShippingAddress,
        // addVoucher,
        addToProduct,
        updateToProduct,
        // To access the total, we need to pass in the state
        ...state,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductState;
