import React, { Component } from "react";
import { Modal } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.core.css"></link>;

export default class EditorHtml extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      editorHtml: "",
    };
    this.ReactQuill = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  open = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
    this.props.getHtml(this.state.editorHtml);
  };

  handleChange(html) {
    this.setState({ editorHtml: html });
  }

  render() {
    return (
      <>
        <Modal title="Rich Text Editor" open={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} width={"70%"}>
          <ReactQuill
            ref={this.ReactQuill}
            value={this.state.editorHtml}
            style={{ minHeight: "70%" }}
            onChange={(e) => {
              this.handleChange(e);
            }}
          />
        </Modal>
      </>
    );
  }
}
