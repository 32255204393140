import { Modal, Form, Input, Select, message } from "antd";
import UploadImagePicker from "components/picker/upload_image_picker";
import React, { useEffect, useState } from "react";
import api from "api";

function AddBannerModal({ isModalOpen, setIsModalOpen, value, setReload }) {
  const [form] = Form.useForm();
  const [image, setImage] = useState();
  const [pageList, setPageList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setLoading(true);
    form
      .validateFields(["image", "link_to", ["page", "id"]])
      .then((res) => {
        var newData = form.getFieldsValue();
        newData.page["title"] = pageList.find((item) => {
          return item.value === newData.page.id;
        }).label;
        if (value && value.id) {
          newData["id"] = value.id;
        }
        api
          .post("miniapp_banner/save", newData)
          .then((res) => {
            setLoading(false);
            message.open({
              type: "success",
              content: "Lưu thành công",
            });
            setReload(true);
            handleCancel();
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields(["image", "link_to", ["page", "id"]]);
    setImage();
    setPageList([]);
  };

  const onChangeLinkTo = (value) => {
    form.resetFields(["page"]);
    switch (value) {
      case "product":
        setPageList(productList);
        break;
      case "category":
        setPageList(categoryList);
        break;
      case "blog":
        setPageList(blogList);
        break;
      case "game":
        setPageList(gameList);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const getListProduct = () => {
      api
        .post("miniapp_product/listall")
        .then((res) => {
          if (res.length > 0) {
            setProductList(
              res.map((item) => {
                return { value: item.id, label: item.name };
              })
            );
          } else {
            setProductList([]);
          }
        })
        .catch((error) => {
          setProductList([]);
        });
    };
    const getListCategory = () => {
      api
        .post("miniapp_category/list")
        .then((res) => {
          if (res.length > 0) {
            setCategoryList(
              res
                .filter((item) => {
                  return !item.is_delete;
                })
                .map((item) => {
                  return { value: item.id, label: item.name };
                })
            );
          } else {
            setCategoryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setCategoryList([]);
        });
    };
    const getListBlog = () => {
      api
        .post("blog/listall")
        .then((res) => {
          if (res.length > 0) {
            setBlogList(
              res
                .filter((item) => {
                  return !item.is_delete;
                })
                .map((item) => {
                  return { value: item.id, label: item.title };
                })
            );
          } else {
            setBlogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setBlogList([]);
        });
    };
    const getListGame = () => {
      api
        .post("gameprogram/getallforbannerconfig")
        .then((res) => {
          if (res.length > 0) {
            console.log(res);
            setGameList(
              res.map((item) => {
                return { value: item.id, label: item.name, image: item.image };
              })
            );
          } else {
            setGameList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setGameList([]);
        });
    };
    getListProduct();
    getListCategory();
    getListBlog();
    getListGame();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      if (value) {
        onChangeLinkTo(value.link_to);
        form.setFieldsValue(value);
        setImage(value.image);
      }
    }
  }, [isModalOpen]);

  return (
    <Modal title={value ? "Chỉnh sửa Banner" : "Thêm mới Banner"} okText="Lưu" cancelText="Thoát" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Hình ảnh banner (16:9)"
          name="image"
          rules={[
            {
              required: true,
              message: "Vui lòng upload hình ảnh",
            },
          ]}
          className="banner_image"
        >
          <UploadImagePicker
            // className={"banner_image"}
            // style={{ display: "flex", justifyContent: "center" }}
            images={image ? [image] : []}
            onChange={(e) => {
              setImage(e);
            }}
            onRemove={(e) => {
              form.resetFields(["image"]);
              setImage();
            }}
            limitImage={1}
            crop={{ aspect: 16 / 9 }}
          />
        </Form.Item>

        <Form.Item
          label="Link đến"
          name="link_to"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn đích đến của Banner",
            },
          ]}
        >
          <Select
            onChange={onChangeLinkTo}
            placeholder="Link đến"
            options={[
              {
                value: "product",
                label: "Sản phẩm",
              },
              {
                value: "category",
                label: "Danh mục",
              },
              {
                value: "blog",
                label: "Bài viết",
              },
              {
                value: "game",
                label: "Game",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Trang"
          name={["page", "id"]}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập trang đến của Banner",
            },
          ]}
        >
          <Select
            placeholder="Trang"
            options={pageList}
            onChange={(e) => {
              var findItem = pageList.find((item) => {
                return item.value === e && item.image;
              });
              if (findItem) {
                setImage(findItem.image);
                form.setFieldValue("image", findItem.image);
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddBannerModal;
