import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, CarryOutOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Pie, Line } from "@ant-design/plots";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency } from "../common/format/number";
import numeral from "numeral";

export default class CustomerPerTier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      perTiers: {},
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };
  getAllData = () => {
    this.getCustomerPerTier();
  };

  //tùng code

  getCustomerPerTier() {
    api
      .post("dashboard/getcustomerpertiers")
      .then((res) => {
        this.setState({ perTiers: res });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  //tùng endcode

  render() {
    const data = [];
    for (var x in this.state.perTiers) {
      if (this.state.perTiers.hasOwnProperty(x)) {
        let partition = {};
        partition.type = x;
        partition.value = this.state.perTiers[x];
        data.push(partition);
      }
    }
    const config = {
      appendPadding: 5,
      data,
      angleField: "value",
      colorField: "type",
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-30%",
        content: ({ percent }) => {
          if (percent > 0) {
            return numeral(percent * 100).format("0.00") + "%";
          }
        },
        style: {
          fontSize: 20,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };

    console.log(config);

    return (
      <Card title="Thành viên từng hạng">
        <Pie {...config} />
      </Card>
    );
  }
}
