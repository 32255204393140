import { memo, useState, useEffect, createRef, useRef } from "react";
import { Button, Row, Col, Table, Badge, Modal, message, Drawer } from "antd";
import { MessageOutlined, SyncOutlined } from "@ant-design/icons";
import AntTable from "components/common/AntTable";
import APIHelper from "api";
import moment from "moment";
import numeral from "numeral";

const ListTemplate = ({ configOaId }) => {
  const Tb = useRef("tbSendHistory");
  const url_zalo = "https://zalo.me/g/fxfwsv717";
  const notApprovedYet = <Badge status={"error"} text="Chưa Duyệt" />;
  const Approved = <Badge status={"success"} text="Đã Duyệt" />;
  const [detailtemplate, setDetailTemplate] = useState([]);
  const status = {
    PENDING_REVIEW: notApprovedYet,
    DISABLE: notApprovedYet,
    ENABLE: Approved,
    REJECT: notApprovedYet,
  };
  const [ismodalopen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState({ oa_id: configOaId });
  const getColumn = () => {
    var columns = [
      {
        title: "Mã Template",
        dataIndex: "templateId",
        key: "templateId",
        width: 75,
      },
      {
        title: "Tên mẫu tin",
        dataIndex: "templateName",
        key: "templateName",
        width: 200,
      },
      {
        title: "Trạng Thái",
        dataIndex: "status",
        key: "status",
        width: 100,
        render: (text, record, index) => {
          return status[text] || status["PENDING_REVIEW"];
        },
      },
      {
        title: "Giá",
        dataIndex: "price",
        key: "price",
        width: 100,
        render: (text, record, index) => {
          return numeral(text).format("0,0");
        },
      },
      {
        title: "Ngày Tạo",
        dataIndex: "createdTime",
        key: "createdTime",
        width: 100,
        render: (text, record, index) => {
          if (text != "" && text != null) {
            var date = new Date(text);
            date = moment(date);
            return <div>{date.format("DD/MM/YYYY")}</div>;
          } else return "";
        },
      },
      {
        title: "",
        key: "action",
        width: 100,
        align: "center",
        render: (text, record, index) => {
          return (
            <>
              <Button type="link" color="blue" onClick={() => getdetailtemplate(record.templateId)}>
                Xem
              </Button>
            </>
          );
        },
      },
    ];
    return columns;
  };
  const onClickSync = () => {
    Tb.current.reload();
  };
  const canceldetailtemplate = () => {
    setIsModalOpen(false);
  };
  const onClickContactTemplate = () => {
    window.open(url_zalo);
  };
  const getdetailtemplate = (template_id) => {
    APIHelper.post("app/zalov2/config/getdetailtemplate", { template_id: template_id, oa_id: configOaId })
      .then((res) => {
        setDetailTemplate(res);
        setIsModalOpen(true);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  useEffect(() => {
    if (configOaId != null) {
      setFilter({ oa_id: configOaId });
    }
  }, [configOaId]);
  useEffect(() => {
    Tb.current.reload();
  }, [filter]);
  return (
    <>
      <Row gutter={[16, 16]} justify={"end"} style={{ marginBottom: "8px", paddingRight: "8px", marginTop: "16px" }}>
        <Col>
          <Button size={"large"} type="primary" onClick={() => onClickContactTemplate()} icon={<MessageOutlined />}>
            Liên hệ tạo mẫu
          </Button>
        </Col>
        <Col>
          <Button size={"large"} onClick={() => onClickSync()} icon={<SyncOutlined />}>
            <span>Đồng bộ mẫu tin</span>
          </Button>
        </Col>
      </Row>
      <AntTable
        size="small"
        columns={getColumn()}
        customSearch={filter}
        tableName={"tbCustomerListTemplate"}
        ajax="app/zalov2/config/gettemplatesandprice"
        ref={Tb}
      ></AntTable>
      <Drawer title="Mẫu tin chăm sóc khách hàng" open={ismodalopen} onClose={() => canceldetailtemplate()} width={572}>
        <iframe
          style={{ top: "0px", width: "100%", height: "100vh" }}
          frameborder="0"
          allowfullscreen
          scrolling="no"
          src={detailtemplate?.previewUrl || ""}
        ></iframe>
      </Drawer>
      {/* <Modal
                title="Mẫu tin chăm sóc khách hàng"
                open={ismodalopen}
                footer={[
                    <Button onClick={()=>canceldetailtemplate()}>Thoát</Button>,
                ]}
                onCancel={()=>canceldetailtemplate()}
                width={572}
                >
                    <div style={{marginLeft: "-24px",marginRight: "-24px",backgroundColor: "#f2f4f8"}}>
                            <iframe style={{display: "block", width: "100%", height: "456px"}} frameborder="0" allowfullscreen scrolling="no" src={detailtemplate?.previewUrl||""}></iframe>

                    </div>
            </Modal> */}
    </>
  );
};
export default memo(ListTemplate);
