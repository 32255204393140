import React from "react";
import { Popover, Button, Row, Col } from "antd";
import { SketchPicker } from "react-color";
import "./index.css";

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    palettes: [
      { name: "", data: ["606c38", "283618", "fefae0", "dda15e", "bc6c25"] },
      { name: "", data: ["cdb4db", "ffc8dd", "ffafcc", "bde0fe", "a2d2ff"] },
      { name: "", data: ["264653", "2a9d8f", "e9c46a", "f4a261", "e76f51"] },
      { name: "", data: ["8ecae6", "219ebc", "023047", "ffb703", "fb8500"] },
      { name: "", data: ["e63946", "f1faee", "a8dadc", "457b9d", "1d3557"] },
      { name: "", data: ["ccd5ae", "e9edc9", "fefae0", "faedcd", "d4a373"] },
      { name: "", data: ["03045e", "023e8a", "0077b6", "0096c7", "00b4d8", "48cae4", "90e0ef", "ade8f4", "caf0f8"] },
      { name: "", data: ["ffbe0b", "fb5607", "ff006e", "8338ec", "3a86ff"] },
      { name: "", data: ["9b5de5", "f15bb5", "fee440", "00bbf9", "00f5d4"] },
      { name: "", data: ["001219", "005f73", "0a9396", "94d2bd", "e9d8a6", "ee9b00", "ca6702", "bb3e03", "ae2012", "9b2226"] },
      { name: "", data: ["cad2c5", "84a98c", "52796f", "354f52", "2f3e46"] },
      { name: "", data: ["ffd6ff", "e7c6ff", "c8b6ff", "b8c0ff", "bbd0ff"] },
      { name: "", data: ["2b2d42", "8d99ae", "edf2f4", "ef233c", "d90429"] },
      { name: "", data: ["0081a7", "00afb9", "fdfcdc", "fed9b7", "f07167"] },
      { name: "", data: ["fec5bb", "fcd5ce", "fae1dd", "f8edeb", "e8e8e4", "d8e2dc", "ece4db", "ffe5d9", "ffd7ba", "fec89a"] },
      { name: "", data: ["132a13", "31572c", "4f772d", "90a955", "ecf39e"] },
      { name: "", data: ["001524", "15616d", "ffecd1", "ff7d00", "78290f"] },
      { name: "", data: ["390099", "9e0059", "ff0054", "ff5400", "ffbd00"] },
      { name: "", data: ["22577a", "38a3a5", "57cc99", "80ed99", "c7f9cc"] },
      { name: "", data: ["d8f3dc", "b7e4c7", "95d5b2", "74c69d", "52b788", "40916c", "2d6a4f", "1b4332", "081c15"] },
      { name: "", data: ["590d22", "800f2f", "a4133c", "c9184a", "ff4d6d", "ff758f", "ff8fa3", "ffb3c1", "ffccd5", "fff0f3"] },
      { name: "", data: ["1a535c", "4ecdc4", "f7fff7", "ff6b6b", "ffe66d"] },
      { name: "", data: ["ff7b00", "ff8800", "ff9500", "ffa200", "ffaa00", "ffb700", "ffc300", "ffd000", "ffdd00", "ffea00"] },
      { name: "", data: ["ff69eb", "ff86c8", "ffa3a5", "ffbf81", "ffdc5e"] },
    ],
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    if (this.props.onChange) {
      this.props.onChange(color.map((item) => `#${item}`));
    }
  };

  renderPalette = () => {
    return (
      <div style={{ width: 500, padding: 15 }}>
        <Row gutter={[6, 6]}>
          {this.state.palettes.map((item) => {
            var w = 100.0 / (item.data.length * 1.0);
            return (
              <Col span={6}>
                <div style={{ height: 32, display: "flex", borderRadius: 15, cursor: "pointer" }} onClick={(e) => this.handleChange(item.data)}>
                  {item.data.map((color) => {
                    return <div style={{ backgroundColor: `#${color}`, height: "100%", width: `${w}%` }}></div>;
                  })}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  render() {
    const styles = {
      color: {
        width: "40px",
        height: "30px",
        padding: "0px",
        paddingTop: "0px",
        borderRadius: "2px",
        background: this.props.value,
      },
      swatch: {
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
    };

    return (
      // <div style={{ display: "flex" }}>
      <Popover content={this.renderPalette()} trigger="click" overlayClassName="overlayClassNameColorPicker">
        {this.props.value ? (
          <div style={{ height: 32, width: 119, display: "flex", borderRadius: 15, cursor: "pointer" }}>
            {this.props.value &&
              this.props.value.length > 0 &&
              this.props.value.map((color) => {
                var w = 100.0 / (this.props.value.length * 1.0);
                return <div style={{ backgroundColor: `${color}`, height: "100%", width: `${w}%` }}></div>;
              })}
          </div>
        ) : (
          <Button type="link">Chọn Màu</Button>
        )}
      </Popover>

      // {/* <div style={{ marginLeft: 15, paddingTop: 5 }}>{this.props.value}</div> */}
      // {/* </div> */}
    );
  }
}

export default ColorPicker;
