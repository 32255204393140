import React from "react";
import { Modal, Space, Input, Divider, Table, Button, Upload, Steps, Alert, Form, Select, Card, message, Result, Radio, Checkbox } from "antd";
import PopupCustomerField from "./PopupCustomerField";
import { createSearchText } from "common/text";

export default class CustomerFieldPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listField: [],
    };
    this.PopupCustomerField = React.createRef();
  }

  componentDidMount = () => {
    this.search();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.listField != prevProps.listField) {
      this.search();
    }
  };

  search = (e) => {
    var listField = [];
    if (e != "" && e != null) e = createSearchText(e);
    this.props.listField.forEach((item) => {
      var obj = null;
      if (item.type !== "object") {
        obj = {
          label: item.label,
          value: item.attribute,
        };
        listField = [...listField, obj];
      } else {
        obj = {
          label: item.label,
        };
        var opt = [];
        item.child.map((ele) => {
          opt = [
            ...opt,
            {
              label: ele.label,
              value: item.attribute + "," + ele.attribute,
            },
          ];
        });
        obj.options = opt;
        listField = [...listField, obj];
      }
    });
    this.setState({ listField: listField });
  };

  render() {
    return (
      <>
        <PopupCustomerField ref={this.PopupCustomerField} reloadOption={this.reloadOption}></PopupCustomerField>
        <Select
          onChange={(vl) => {
            this.props.onChange(vl);
          }}
          value={this.props.value}
          onSearch={this.search}
          options={this.state.listField}
          optionFilterProp="label"
          showSearch
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.PopupCustomerField.current.open();
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>&ensp;Thêm lựa chọn
                </Button>
              </div>
            </div>
          )}
        ></Select>
      </>
    );
  }
}
