import { useState, memo, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { Input, Upload, Button, Tabs, Col, Row, Modal, message, Form } from "antd";
import ReactSrcDocIframe from "../../../common/iframe-doc";
import { default_html } from "./default_design";
import Editor from "./editor_popup";
import Content from "./popup_test_content";
import TextEditor from "../text_picker";
import api from "api";
import { getPath } from "common/file.js";
import { UploadOutlined } from "@ant-design/icons";
const EmailEditor = forwardRef((props, ref) => {
  const { value, onChange } = props;
  const editorRef = useRef("editorRef");
  const [reviewMode, setReviewMode] = useState("desktop");
  // const [data, setData] = useState()
  const [error, setError] = useState({});
  // return (<div>email editor</div>)
  const onChangeRichMedia = (newvalue) => {
    console.log("newValue", newvalue);
    setError({});
    var newContent = value;
    newContent.html = newvalue.html;
    newContent.json = newvalue.json;
    onChange(newContent);
  };

  const openEditor = () => {
    editorRef.current.open();
  };

  var onChangeSubject = (subject) => {
    var newValue = value;
    newValue.subject = subject;
    setError({});
    onChange(newValue);
  };

  useImperativeHandle(
    ref,
    () => {
      console.log("useImperativeHandle", value);
      return {
        validate: validate,
      };
    },
    [value]
  );
  /**
   * Kiểm tra dữ liệu
   * Báo lỗi ! trên các tab đang lỗi
   * @returns {object} null nếu dữ liệu hợp lệ, {key:"message"} nếu dữ liệu chưa hợp lệ
   */
  const validate = () => {
    var error = {};
    setError({});
    console.log("validate email", value);
    if (!value) {
      error.subject = "subject is empty";
      setError(error);
      return error;
    }

    if (!value.subject || value.subject === "" || value.subject.trim() === "") {
      error.subject = "subject is empty";
    }
    if (!value.html || value.html === "" || value.html.trim() === "") {
      error.html = "html is empty";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return error;
    }
    return null;
  };

  const upload = (e) => {
    var form = new FormData();
    form.append("file", e.file);
    api
      .post("File/upload", form)
      .then((data) => {
        var attachments = [...value.attachments] ?? [];
        attachments.push({
          uid: Math.random(),
          name: e.file.name,
          filename: e.file.name,
          url: getPath(data.path),
          path: data.path,
        });
        onChange({ ...value, attachments: attachments });
      })
      .catch((err) => {
        console.error("lỗi");
      });
  };

  const removeAttachment = (obj) => {
    console.log(value);
    var newValue = value;
    newValue.attachments = value.attachments.filter((item) => item.uid != obj.uid);
    onChange(newValue);
  };

  return (
    <div>
      <Row gutter="16">
        <Col span={9}>
          <div style={{ paddingBottom: 8 }}>
            <label>
              <span style={{ color: "red" }}>*</span>Tiêu đề
            </label>
          </div>
          <div style={{ paddingBottom: 24 }}>
            <TextEditor value={value?.subject} onChange={(e) => onChangeSubject(e)} row={1} placeholder="Tiêu đề email" />
            {error.subject && <div style={{ color: "red" }}>Bạn chưa nhập tiêu đề!</div>}
          </div>

          <div style={{ paddingBottom: 8 }}>
            <label>File đính kèm:</label>
          </div>
          <Upload
            showUploadList={{
              showDownloadIcon: true,
              downloadIcon: "download",
            }}
            fileList={value?.attachments}
            customRequest={upload}
            onRemove={removeAttachment}
          >
            <Button icon={<UploadOutlined />}>File đính kèm nội dung</Button>
          </Upload>
        </Col>
        <Col span={15}>
          <label>Nội dung</label>
          <Editor ref={editorRef} value={value} onChangeRichMedia={onChangeRichMedia} type={value?.type} />
          <div className="pull-right" style={{ display: "flex", justifyContent: "flex-end" }}></div>
          <Row style={{ display: "flex", justifyContent: "center", marginBottom: 30, marginTop: 15 }}>
            <Button
              type={reviewMode == "desktop" ? "primary" : "default"}
              style={{ marginRight: 5 }}
              onClick={(e) => {
                setReviewMode("desktop");
              }}
            >
              Desktop
            </Button>
            <Button
              type={reviewMode == "mobile" ? "primary" : "default"}
              onClick={(e) => {
                setReviewMode("mobile");
              }}
            >
              Mobile
            </Button>
          </Row>
          <Button type="primary" style={{ position: "absolute", right: 10, top: 38 }} onClick={openEditor}>
            Sửa
          </Button>

          <div align="center">
            {error.html && <div style={{ color: "red" }}>Bạn chưa chọn 1 mẫu email</div>}
            {reviewMode == "desktop" ? (
              <ReactSrcDocIframe style={{ marginBottom: 30, borderStyle: "none" }} srcDoc={value?.html} width="100%" height="610" />
            ) : (
              <div id="phone-frame">
                <div class="sub-top"></div>
                <ReactSrcDocIframe style={{ marginBottom: 10, marginRight: 25, borderStyle: "none" }} srcDoc={value?.html} width="320" height="570" />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* <Content ref={this.Content} wayToSend={this.props.wayToSend}></Content> */}
    </div>
  );
});

export default memo(EmailEditor);
