import React from "react";
import { Input, Button, Form, Checkbox, Row, Col, message, Modal, Radio } from "antd";



export default class GmailPicker extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (value, name) => {
    if (this.props.onChange) {
      var old = { ...this.props.value };
      old[name] = value;
      this.props.onChange(old);
    }
  };



  render() {
    return (
      <div>

        <div style={{ marginTop: 10 }}>
          Ghi chú:
          <ul>
            <li>
              Chú ý: Không sử dụng tài khoản và mật khẩu Google của bạn. Hãy tạo mật khẩu cho ứng dụng theo hướng dẫn.

            </li>
            <li>
              <a href="https://support.google.com/accounts/answer/185833?hl=vi" target="_blank">

                Hướng dẫn{" "}
              </a>{" "} tạo password để gửi mail với tài khoản Google{" "}


            </li>
            <li>
              Số lượng email gửi sẽ bị giới hạn theo{" "}
              <a href="https://support.google.com/mail/answer/22839?hl=en" target="_blank">
                {" "}
                chính sách{" "}
              </a>{" "}
              của Google
            </li>


          </ul>
        </div>
      </div>
    );
  }
}
