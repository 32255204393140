import React, { Component } from "react";
import { Modal } from "antd";

import CustomerFilter from "./cutomer_filter";

export default class SegmentsCampingAddNewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      segmentId: 0,
      segmentName: "",
    };

    this.CustomerFilter = React.createRef();
  }

  open = async (id) => {
    this.setState({
      visible: true,
      segmentId: id,
      segmentName: "",
    });
  };

  close = (e) => {
    this.setState({
      visible: false,
    });
  };

  confirm = () => {
    this.CustomerFilter.current.addNewEdit();
  };

  getName = (data) => {
    this.setState({ segmentName: data });
  };

  render() {
    return (
      <div>
        <Modal
          title={this.state.segmentName === "" ? "Nhóm khách hàng" : `Nhóm khách hàng ${this.state.segmentName}`}
          open={this.state.visible}
          onOk={() => {}}
          onCancel={() => this.close()}
          width="80%"
          loading={this.state.loading}
          footer={[]}
        >
          <CustomerFilter ref={this.CustomerFilter} id={this.state.segmentId} getName={this.getName} segmentMode={true} reload={this.props.reload}></CustomerFilter>
        </Modal>
      </div>
    );
  }
}
