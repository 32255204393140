import React from "react";
import { Button, Switch, Row, Col } from "antd";

export default class ButtonAddMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      border: "2px dashed #dfdfdf",
    };
  }

  render() {
    return (
      <div
        style={{ width: "100%", border: this.state.border, padding: "10px 18px", fontSize: 20, fontFamily: "Poppins", borderRadius: 15 }}
        onMouseEnter={() => {
          this.setState({ border: "2px dashed #00bfff" });
        }}
        onMouseLeave={() => {
          this.setState({ border: "2px dashed #dfdfdf" });
        }}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick(this.props.text);
          }
        }}
      >
        <div>
          {this.props.icon}&nbsp;&nbsp;{this.props.text}
        </div>
      </div>
    );
  }
}
