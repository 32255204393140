import React, { useEffect, useState } from "react";
import { Button, Row, List, Typography, Popconfirm, Col, Card } from "antd";
import AddWebhookModal from "./AddWebhookModal";
import TestWebhookModal from "./TestWebhookModal";
import { useNavigate } from "react-router";
// import { useNavigate } from "react-router";
// const { Text } = Typography;

function WebhookConfig({ value, onChange, serviceWebhook = [], onChangeServiceWebhook }) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTestOpen, setIsModalTestOpen] = useState(false);
  const [mode, setMode] = useState();
  const [listOrderWebhookItem, setListOrderWebhookItem] = useState([
    {
      content: (
        <Button
          style={{ width: "100%" }}
          type="dashed"
          onClick={() => {
            setMode("order");
            setIsModalOpen(true);
          }}
        >
          Thêm mới
        </Button>
      ),
    },
  ]);
  const [listServiceWebhookItem, setListServiceWebhookItem] = useState([
    {
      content: (
        <Button
          style={{ width: "100%" }}
          type="dashed"
          onClick={() => {
            setMode("service");
            setIsModalOpen(true);
          }}
        >
          Thêm mới
        </Button>
      ),
    },
  ]);
  const [record, setRecord] = useState();

  useEffect(() => {
    if (!isModalTestOpen) {
      setRecord();
    }
  }, [isModalTestOpen]);

  useEffect(() => {
    if (serviceWebhook && serviceWebhook.length > 0) {
      var newListItem = [...serviceWebhook];
      newListItem.unshift({
        content: (
          <Button
            style={{ width: "100%" }}
            type="dashed"
            onClick={() => {
              setMode("service");
              setIsModalOpen(true);
            }}
          >
            Thêm mới
          </Button>
        ),
      });
      setListServiceWebhookItem(newListItem);
    } else {
      var newListItem = [
        {
          content: (
            <Button
              style={{ width: "100%" }}
              type="dashed"
              onClick={() => {
                setMode("service");
                setIsModalOpen(true);
              }}
            >
              Thêm mới
            </Button>
          ),
        },
      ];
      setListServiceWebhookItem(newListItem);
    }
  }, [serviceWebhook]);

  useEffect(() => {
    if (value && value.length > 0) {
      var newListItem = [...value];
      newListItem.unshift({
        content: (
          <Button
            style={{ width: "100%" }}
            type="dashed"
            onClick={() => {
              setMode("order");
              setIsModalOpen(true);
            }}
          >
            Thêm mới
          </Button>
        ),
      });
      setListOrderWebhookItem(newListItem);
    } else {
      var newListItem = [
        {
          content: (
            <Button
              style={{ width: "100%" }}
              type="dashed"
              onClick={() => {
                setMode("order");
                setIsModalOpen(true);
              }}
            >
              Thêm mới
            </Button>
          ),
        },
      ];
      setListOrderWebhookItem(newListItem);
    }
  }, [value]);

  const onSave = (newWebhook) => {
    if (mode === "order") {
      var newValue = [...value];
      newValue.push(newWebhook);
      onChange(newValue);
    } else {
      var newValue = [...serviceWebhook];
      newValue.push(newWebhook);
      onChangeServiceWebhook(newValue);
    }
  };

  const renderTitle = (type) => {
    switch (type) {
      case "trustsales":
        return "TRUSTSALES";
      case "trustsales_sandbox":
        return "TRUSTSALES SANDBOX";
      case "webhook_url":
        return "WEBHOOK URL";
      case "mshopkeeper":
        return "MISAeSHOP";
      default:
        break;
    }
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              backgroundColor: "#F5F5F5",
              padding: "8px 16px",
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              border: "1px solid #d9d9d9",
              borderBottom: 0,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Webhook khi phát sinh đơn hàng</span>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                navigate("webhook_history");
              }}
            >
              Xem lịch sử
            </Button>
          </div>
          <List
            style={{ width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
            // header={<div>Webhook khi phát sinh đơn hàng</div>}
            size="small"
            bordered
            dataSource={listOrderWebhookItem}
            renderItem={(item, index) => (
              <List.Item
                actions={
                  index !== 0 && [
                    <Button
                      type="link"
                      key="send_test"
                      onClick={() => {
                        setMode("order");
                        setIsModalTestOpen(true);
                        setRecord(item);
                      }}
                    >
                      Send test
                    </Button>,

                    <Popconfirm
                      title="Bạn có chắc muốn xóa webhook không?"
                      onConfirm={() => {
                        var newValue = [...value];
                        newValue = newValue.filter((v, i) => {
                          return i !== index - 1;
                        });

                        onChange(newValue);
                      }}
                      okText="Có"
                      cancelText="Không"
                    >
                      <Button type="link" style={{ fontSize: 14 }}>
                        <Typography.Text type="danger">Xoá</Typography.Text>
                      </Button>
                    </Popconfirm>,
                  ]
                }
              >
                {item.content ? item.content : <List.Item.Meta title={renderTitle(item.type)} description={`Link: ${item.url}`}></List.Item.Meta>}
              </List.Item>
            )}
          />
        </Col>

        <Col span={12}>
          <div
            style={{
              display: "flex",
              backgroundColor: "#F5F5F5",
              padding: "8px 16px",
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              border: "1px solid #d9d9d9",
              borderBottom: 0,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Webhook khi phát sinh đặt lịch</span>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                navigate("webhook_history");
              }}
            >
              Xem lịch sử
            </Button>
          </div>
          <List
            style={{ width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
            // header={<div>Webhook khi phát sinh đơn hàng</div>}
            size="small"
            bordered
            dataSource={listServiceWebhookItem}
            renderItem={(item, index) => (
              <List.Item
                actions={
                  index !== 0 && [
                    <Button
                      type="link"
                      key="send_test"
                      onClick={() => {
                        setMode("service");
                        setIsModalTestOpen(true);
                        setRecord(item);
                      }}
                    >
                      Send test
                    </Button>,

                    <Popconfirm
                      title="Bạn có chắc muốn xóa webhook không?"
                      onConfirm={() => {
                        var newValue = [...serviceWebhook];
                        newValue = newValue.filter((v, i) => {
                          return i !== index - 1;
                        });

                        onChangeServiceWebhook(newValue);
                      }}
                      okText="Có"
                      cancelText="Không"
                    >
                      <Button type="link" style={{ fontSize: 14 }}>
                        <Typography.Text type="danger">Xoá</Typography.Text>
                      </Button>
                    </Popconfirm>,
                  ]
                }
              >
                {item.content ? item.content : <List.Item.Meta title={renderTitle(item.type)} description={`Link: ${item.url}`}></List.Item.Meta>}
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {isModalOpen && (
        <AddWebhookModal
          isModalOpen={isModalOpen}
          mode={mode}
          setIsModalOpen={setIsModalOpen}
          onSave={onSave}
          webhookList={mode === "order" ? value : serviceWebhook}
        />
      )}
      <TestWebhookModal isModalOpen={isModalTestOpen} setIsModalOpen={setIsModalTestOpen} webhook={record} mode={mode} />
    </>
  );
}

export default WebhookConfig;
