import { memo, useEffect } from "react";
import {Col, Row,Typography} from "antd";
import CustomerInformation from "./CustomerInformation";
import History from "./History";
const { Title, Text } = Typography;
const TransactionManagement = () => {
  useEffect(() => {
  }, []);

  return (
  <div>
    <Title level={4}>Quản lý giao dịch</Title>
    <Row justify="start"style={{marginTop:8}}>
        <Col span={24} xl={8} style={{paddingRight:10,marginTop:10}}>
            <CustomerInformation/>
        </Col>
        <Col span={24} style={{marginTop:10}}  xl={16}>
          <History/>
        </Col>
    </Row>
  </div>);
};

export default memo(TransactionManagement);
