import { Modal, Input, Form, Select, Radio } from "antd";
import React, { useEffect, useState } from "react";
import api from "api";
import { uuid } from "components/common/react-awesome-query-builder/lib/utils";

function AddBlockModal({ isModalOpen, setIsModalOpen, onSave }) {
  const [categoryProductList, setCategoryProductList] = useState([]);
  const [categoryServiceList, setCategoryServiceList] = useState([]);
  const [typeSeleted, setTypeSelected] = useState("product_block");
  const [form] = Form.useForm();
  const handleOk = () => {
    var fields =
      typeSeleted === "product_block"
        ? [
            ["props", "lablel"],
            ["props", "category_id"],
          ]
        : [["props", "lablel"]];
    form
      .validateFields(fields)
      .then((res) => {
        var newValue = form.getFieldsValue();
        if (!newValue.props.category_id) {
          newValue.props["category_id"] = "";
        }
        newValue.id = uuid();
        onSave(newValue);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log("lỗi");
      });
    // console.log(form.getFieldsValue());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getProductCategory();

    getServiceCategory();
  }, []);

  const getProductCategory = async () => {
    await api.post("miniapp_category/list").then((res) => {
      console.log(res);
      setCategoryProductList(
        res.map((item) => {
          return { value: item.id, label: item.name };
        })
      );
    });
  };

  const getServiceCategory = async () => {
    await api.post("service_category/list").then((res) => {
      console.log(res);
      setCategoryServiceList(
        res.map((item) => {
          return { value: item.id, label: item.name };
        })
      );
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields([
        ["props", "lablel"],
        ["props", "category_id"],
      ]);
    }
  }, [isModalOpen]);
  return (
    <Modal title="Thêm mới" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Lưu" cancelText="Thoát" bodyStyle={{ paddingTop: 24 }}>
      <Form form={form} layout="vertical" initialValues={{ type: "product_block" }}>
        <Form.Item name="type" style={{ marginBottom: 16 }}>
          <Radio.Group
            onChange={(e) => {
              setTypeSelected(e.target.value);
              form.resetFields([["props", "category_id"]]);
            }}
            options={[
              {
                label: "Slider sản phẩm",
                value: "product_block",
              },
              {
                label: "Danh mục sản phẩm",
                value: "category_block",
              },
              {
                label: "Banner",
                value: "banner_block",
              },
              {
                label: "Tin tức Zalo OA",
                value: "news_zalo_block",
                // disabled: true,
              },
              {
                label: "Khám phá",
                value: "explore_block",
                // disabled: true,
              },
              {
                label: "Quan tâm OA",
                value: "follow_oa_block",
                // disabled: true,
              },
              {
                label: "Đặt lịch",
                value: "service_block",
                // disabled: true,
              },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          name={["props", "lablel"]}
          label="Tên block"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên",
            },
          ]}
        >
          <Input placeholder="Tên block" maxLength={50} showCount />
        </Form.Item>
        {/* {typeSeleted === "product_block" && ( */}
        {typeSeleted === "product_block" && (
          <Form.Item
            name={["props", "category_id"]}
            label="Danh mục"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn 1 danh mục",
              },
            ]}
          >
            <Select
              // onChange={handleChange}
              placeholder="Danh mục"
              options={categoryProductList}
            />
          </Form.Item>
        )}

        {typeSeleted === "service_block" && (
          <Form.Item
            hidden={typeSeleted !== "service_block"}
            name={["props", "category_id"]}
            label="Danh mục"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn 1 danh mục",
              },
            ]}
          >
            <Select
              // onChange={handleChange}
              placeholder="Danh mục"
              options={categoryServiceList}
            />
          </Form.Item>
        )}
        {/* )} */}
      </Form>
    </Modal>
  );
}

export default AddBlockModal;
