import { Badge, Button, Descriptions, Drawer, Form, Input, message, Select, Table } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import api from "api";
function ServiceOrderDetail({ open, setOpen, record }) {
  const [loading, setLoading] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    var formData = form.getFieldsValue();
    var dataPost = { ...record };
    Object.keys(formData).forEach((key) => {
      dataPost[key] = formData[key];
    });

    api
      .post("service_order/updateorder", dataPost)
      .then((res) => {
        setLoading(false);
        message.success("Cập nhật thành công");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const [form] = Form.useForm();
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      width={"50vw"}
      title="Thông tin đặt lịch"
      extra={[
        <Button style={{ marginRight: 8 }} onClick={onClose}>
          Thoát
        </Button>,
        <Button type="primary" onClick={onSubmit} loading={loading}>
          Cập nhật
        </Button>,
      ]}
      closeIcon={false}
      open={open}
    >
      <Form form={form} initialValues={{ status: record.status }}>
        <Descriptions size="small" bordered column={2}>
          <Descriptions.Item span={2} label="Mã" style={{ alignItems: "center" }}>
            #{record.id}
          </Descriptions.Item>
          <Descriptions.Item label="Họ tên">{record.name}</Descriptions.Item>
          <Descriptions.Item label="Số điện thoại">{record.phone}</Descriptions.Item>
          <Descriptions.Item label="Lịch hẹn">{moment(record.order_time).format("DD/MM/YYYY HH:mm")}</Descriptions.Item>
          <Descriptions.Item label="Chi nhánh">{record.location.name}</Descriptions.Item>
          <Descriptions.Item label="Đặt cho" span={2}>
            {record.is_me ? "Cho chính tôi" : "Cho người khác"}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái" span={2}>
            <Form.Item style={{ margin: 0 }} name={"status"}>
              <Select
                style={{ width: 144 }}
                placeholder="Trạng thái"
                options={[
                  { value: "new", label: <Badge color="yellow" text="Chờ xác nhận" /> },
                  { value: "paid", label: <Badge color="blue" text="Xác nhận" /> },
                  { value: "success", label: <Badge color="green" text="Thành công" /> },
                  { value: "cancel", label: <Badge color="red" text="Huỷ" /> },
                ]}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Dịch vụ thêm" span={2}>
            <Table
              pagination={false}
              columns={[
                {
                  title: "Dịch vụ",
                  dataIndex: "name",
                  key: "name",
                  // sorter: true,
                  // render: (text, record, index) => {
                  //   return <p style={{ marginBottom: "auto", marginTop: "auto" }}>{moment(text).format("DD/MM/YYYY")}</p>;
                  // },
                },
                {
                  title: "Giá",
                  dataIndex: "skus",
                  key: "skus",
                  align: "right",
                  render: (text) => {
                    if (text) {
                      return numeral(text[0].sale_price).format("0,0") + "đ";
                    }
                  },
                },
              ]}
              dataSource={record.list_service}
            ></Table>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </Drawer>
  );
}

export default ServiceOrderDetail;
