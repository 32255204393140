import React, { Component } from "react";
import api from "api";
import { Input, Card, Button, message } from "antd";

export default class ApiConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
    };
  }
  componentDidMount = () => {
    api.post("api_config/getapiconfig").then((res) => {
      this.setState({ token: res.token });
    });
  };

  changeToken = () => {
    api.post("api_config/createtoken").then((res) => {
      this.setState({ token: res.token });
    });
  };

  copyToken = () => {
    navigator.clipboard.writeText(this.state.token);
    message.success("đã sao chép token");
  };

  render() {
    return (
      <Card>
        <div>Token:</div>
        <div>
          <Input value={this.state.token} readOnly onClick={this.copyToken}></Input>
        </div>
        <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
          <Button onClick={this.changeToken}>Tạo token mới</Button>
        </div>
      </Card>
    );
  }
}
