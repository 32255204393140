import React, { useContext, useEffect, useState } from "react";
import { Upload, Button, message, Typography } from "antd";
import ImgCrop from "antd-img-crop";
import api from "api";
import { getPath } from "common/file.js";
import "./index.css";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ProductContext from "context/product/ProductContext";

const { Text } = Typography;
function UploadImagePicker({ images, resize, crop, onChange, style, className, onRemove, limitImage }) {
  const [isImgCrop, setIsImgCrop] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const beforeUpload = (file) => {
    //kiểm tra xem có dùng component ImgCrop không có thì file .svg chuyển thành file .png
    var fileName = file.name.split(".");
    if (fileName[1] === "svg" && isImgCrop) {
      var blob = file.slice(0, file.size, "image/png");
      var newFile = new File([blob], `${fileName[0]}.png`, { type: "image/png" });
      file = newFile;
    }
    //Tiến hành resize file ảnh phù hợp với kích cở
    return new Promise((resolve) => {
      if (resize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = document.createElement("img");
          img.src = reader.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = resize.width;
            canvas.height = resize.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, resize.width, resize.height);
            canvas.toBlob((result) => resolve(result));
          };
        };
      } else {
        resolve(file);
      }
    });
  };

  const beforeDrop = (e) => {
    var lst = e.name.split(".");
    var tail = lst[lst.length - 1];
    var lstAllow = [
      //image
      "jpg",
      "jpeg",
      "png",
      "gif",
      // "svg",
    ];
    tail = tail.toLocaleLowerCase();
    // Accept images only
    if (!lstAllow.includes(tail)) {
      //   setState({ isImgCrop: false });
      setIsImgCrop(false);
      message.error("Vui lòng upload file hình ảnh đuôi png, jpg hoặc jpeg");
      return false;
    }
    setIsImgCrop(true);
    return true;
  };

  const onChangeImage = (value) => {
    // var dataPost = productContext.productItem;
    // if (productContext.productItem.images && productContext.productItem.images.length > 0) {
    //   dataPost.images = productContext.productItem.images;
    //   dataPost.images.push(value);
    // } else {
    //   dataPost.images = [value];
    // }

    // productContext.updateToProduct(dataPost);

    if (onChange) {
      onChange(value);
    }
  };

  const uploadImage = (e) => {
    var form = new FormData();
    if (e.file.size > 3145728) return message.error("Vui lòng upload file hình ảnh dưới 3MB");

    if (fileFilter(e.file)) {
      form.append("file", e.file);
      setIsUploading(true);
      api
        .post("File/upload", form)
        .then((data) => {
          //   setState({ isUploading: false });;
          setIsUploading(false);
          var url = getPath(data.path);
          onChangeImage(url);
        })
        .catch((err) => {
          //   setState({ isUploading: false });
          setIsUploading(false);
          message.error("Không thể tải file. Vui lòng thử lại");
        });
    } else message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpeg, svg hoặc gif");
  };

  const fileFilter = (file) => {
    var ext = "";
    var fileName = file.name;
    var i = fileName.lastIndexOf(".");
    if (i === -1) ext = "unknown";
    else ext = fileName.slice(i + 1);
    var lstAllow = [
      //image
      "jpg",
      "jpeg",
      "png",
      "gif",
      "svg",
    ];

    ext = ext.toLocaleLowerCase();
    // Accept images only
    if (!lstAllow.includes(ext)) {
      return false;
    }
    return true;
  };

  const uploadButton = (
    <div>
      {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const onPreview = async (file) => {
    debugger;
    console.log("file", file);
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <ImgCrop {...crop} minZoom={0.1} cropperProps={{ restrictPosition: false }} beforeCrop={beforeDrop}>
        <Upload
          name="avatar"
          listType="picture-card"
          className={className}
          // showUploadList={false}
          fileList={
            images && images.length > 0
              ? images.map((item, index) => {
                  return { uid: index, name: "image" + index, status: "done", url: item };
                })
              : []
          }
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          accept=".png,.jpg,.jpeg,.gif,.svg"
          //   showUploadList={false}
          customRequest={uploadImage}
          beforeUpload={beforeUpload}
          style={style}
          onRemove={(file) => {
            onRemove(file);
          }}
          onPreview={onPreview}
        >
          {(images?.length < limitImage || !images) && uploadButton}
          {/* <Button type="link" loading={isUploading}>
          {isUploading ? "Uploading" : "Upload"}
        </Button> */}
        </Upload>
      </ImgCrop>
    </>
  );
}

export default UploadImagePicker;
