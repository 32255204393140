import React, { Component } from "react";
import "../admin.css";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Col, Row, Card } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, VideoCameraOutlined, UploadOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;

export default class SubMenu extends React.Component {
  toggle = () => {
    this.props.toggleChildCollapsed();
    this.props.setSubMenuWidth();
  };
  onSelectChildMenu = (path, newpage) => {
    if (newpage) {
      window.open(path, "_blank").focus();
      return;
    }
    if (this.props.collapsed) this.props.navigate(path + "?child_collapsed=true");
    // window.open(path + "?child_collapsed=true", '_self');
    else this.props.navigate(path);
    // window.open(path, '_self');
  };

  render() {
    console.log();
    var menu = this.props.menu;
    var current_parent_key = this.props.current_parent_key;
    var current_parent = menu.find((z) => z.key == current_parent_key);
    var current_child = [];
    if (current_parent != null) current_child = current_parent.child;
    if (current_child.length == 0) {
      return null;
    }
    return (
      <Sider
        hidden={window.location.pathname.split("/").includes("game") ? true : false}
        width={"140px"}
        // style={{ marginRight: "2px", height: "100%" }}
        collapsedWidth={"45px"}
        theme="light"
        className="slider-menu"
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
      >
        {/* <div className="header-background-submenu" style={{ display: "flex", justifyContent: "flex-end" }}>
          {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "menu-switch",
            onClick: this.toggle,
          })}
        </div> */}
        <Menu
          theme="light"
          className="sider-submenu"
          // style={{ marginTop: 50 }}
          mode="inline"
          defaultSelectedKeys={this.props.current_child_key}
          items={current_child}
          onClick={(e) => {
            var item = current_child.find((x) => x.key == e.key);
            this.onSelectChildMenu(item?.path, false);
          }}
        ></Menu>
      </Sider>
    );
  }
}
