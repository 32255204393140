import React from "react";
import { Row, Empty, Button, Space, List, Input, Select, message, Form } from "antd";
import PopupAddVoucher from "./popup_add_voucher";
import PopupListVoucher from "./popup_list_voucher";
import { formatCurrency } from "components/common/format/number";
import api from "api";

export default class ListVoucherPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      loading: true,
      options: null,
      lst: [],
    };
    this.popupListVoucher = React.createRef();
  }
  componentDidMount = async () => {
    await this.getVoucher();
    if (this.props.value) {
      this.getVoucherbyid(this.props.value);
    }
  };
  getVoucherData = () => {
    console.log("voucher data");
  };

  // onChange = (value) => {
  //   console.log("value", value);
  // };
  setVoucherSelected = (voucher) => {
    this.setState({ value: JSON.stringify({ id: voucher.id, type: voucher.type }) });
    if (this.props.onVoucherSelected) {
      this.props.onVoucherSelected(voucher);
    }
    if (this.props.onChange) {
      this.props.onChange(JSON.stringify({ id: voucher.id, type: voucher.type }));
    }
  };
  reload = () => {
    this.getVoucher();
  };
  getVoucherbyid = async (data) => {
    try {
      data = JSON.parse(data);
      api
        .post("voucher/getbyid", { id: data.id })
        .then((res) => {
          this.setState({ value: res?.name });
        })
        .catch((err) => {
          message.warning("Lỗi tên");
        });
    } catch (error) {}
  };
  getVoucher = () => {
    api
      .post("voucher/getvoucherstemplate", {
        query: { type: this.props.type },
        pagging: { skip: 0, limit: 10, sort: {} },
      })
      .then((res) => {
        var vouchers = res.data;
        var options = res.data.map((voucher) => {
          return {
            label: voucher.name,
            value: JSON.stringify({ id: voucher.id, type: voucher.type }),
          };
        });
        this.setState({ vouchers: vouchers, options: options, loading: false });
      })
      .catch((err) => {
        message.warning("Lỗi");
      });
  };
  onChange = (value) => {
    if (this.props.onChange) {
      var voucher = this.state.vouchers.find((x) => x.id === value);
      if (this.props.onVoucherSelected) {
        this.props.onVoucherSelected(voucher);
      }
      this.props.onChange(value);
      this.setState({ value: value });
    }
  };
  removeAscent = (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\s/g, "");
    return str;
  };
  searchVoucher = (e) => {
    var newText = this.removeAscent(e);
    api
      .post("voucher/getvoucherstemplate", {
        query: { type: this.props.type, search_text: { $regex: newText } },
        pagging: {},
      })
      .then((res) => {
        var vouchers = res.data;
        var options = res.data?.map((voucher) => {
          return {
            label: voucher.name,
            value: JSON.stringify({ id: voucher.id, type: voucher.type }),
          };
        });

        this.setState({ vouchers: vouchers, options: options, loading: false });
      })
      .catch((err) => {
        message.error("Không tìm thấy voucher");
      });
  };

  render() {
    var lst = [];
    //var data = this.popupListVoucher.current.getFieldsValue(true);
    var data = null;
    if (this.props.value) lst = this.props.value;
    return (
      <>
        {this.props.name ? (
          <Space.Compact block style={{ width: "100%" }}>
            <Form.Item style={{ width: "100%", margin: 0 }} label={this.props.label} rules={this.props.rules} name={this.props.name}>
              <Select
                // style={this.props.style ? this.props.style : { width: 300 }}
                loading={this.state.loading}
                showSearch
                options={this.state.options}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => this.onChange(e)}
                onSearch={(e) => this.searchVoucher(e)}
                value={this.state.value}
              ></Select>
            </Form.Item>
            <Form.Item style={{ marginTop: 30 }}>
              <Button onClick={() => this.popupListVoucher.current.open()} loading={this.state.loading}>
                ...
              </Button>
            </Form.Item>
          </Space.Compact>
        ) : (
          <Row style={{ display: "inline" }}>
            <div style={{ margin: "auto" }}>
              <Select
                style={this.props.style ? this.props.style : { width: 300 }}
                loading={this.state.loading}
                showSearch
                options={this.state.options}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => this.onChange(e)}
                onSearch={(e) => this.searchVoucher(e)}
                value={this.state.value}
              ></Select>
              <Button onClick={() => this.popupListVoucher.current.open()} loading={this.state.loading}>
                ...
              </Button>
            </div>
          </Row>
        )}
        <PopupListVoucher ref={this.popupListVoucher} reload={this.reload} setVoucherSelected={this.setVoucherSelected} type={this.props.type} />
      </>
    );
  }
}
