import React from "react";
import { Table, Popconfirm, Button } from "antd";
import PopupRewad from "./popup_reward";
import api from "api";
import PopupColor from "./popupColor";

export default class RewardPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [],refresh:false };
    this.PopupRewad = React.createRef();
    this.PopupColor = React.createRef()
  }

  componentDidMount = () => {
    this.setState({ data: this.props.value });
  };

  onChange = (value) => {
    this.setState({ data: value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.props.getLastest(value)
  };

  addReward = (reward) => {
    var lstReward = [...this.props.value];
    if(Array.isArray(reward)){
      this.onChange(reward);
      return
    }

    var index = lstReward.findIndex((x) => x.id === reward.id);
    if (index > -1) {
      lstReward[index] = reward;
    } else {
      lstReward.push(reward);
    }
    this.onChange(lstReward);

  };

  removeReward(id) {
    var lstReward = [...this.state.data];
    var index = lstReward.findIndex((x) => x.id === id);
    if (index > -1) {
      lstReward.splice(index, 1);
      this.setState({ data: lstReward }, () => {
        this.onChange("ls",lstReward);
      });
    }
  }

  getColum() {
    var columns = [
      {
        title: "Tên phần thưởng",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Loại",
        dataIndex: "type",
        key: "type",
        render: (text, record, index) => {
          var type = "";
          if (record.type === "point") type = "Tích điểm";
          else {
            if (record.type === "fail") type = "Mất lượt";
            else {
              switch (record.voucher.type) {
                case "exchange_point":
                  type = "Voucher giảm giá";
                  break;
                case "exchange_freeship":
                  type = "Miễn phí vận chuyển";
                  break;
                case "exchange_percentage":
                  type = "Voucher giảm phần trăm";
                  break;

                default:
                  break;
              }
            }
          }
          return <div>{type}</div>;
        },
      },

      {
        title: () => {

          return (
              <div style={{display:"flex",justifyContent:"center"}}>
                <Button disabled = {this.state.data.length>0?false:true} onClick={() => this.PopupColor.current.open()} type="primary">
                  Chọn màu
                </Button>
              </div>
          );
        },
        dataIndex: "background",
        key: "background",
        render: (text, record, index) => {
          return <div style={{ width: 20, height: 20, backgroundColor: record.background, border: "1px #dfdfdf solid",margin:"auto" }}></div>;
        },
      },
      {
        title: "Số lượng hiển thị",
        dataIndex: "amount",
        key: "amount",

        render: (text, record, index) => {
          return <div>{record.amount}</div>;
        },
      },
      {
        title: "Tỉ lệ",
        dataIndex: "percent",
        key: "percent",
        render: (text, record, index) => {
          return <div>{record.percent}</div>;
        },
      },
      {
        title: () => {
          return (
            <div>
              <Button onClick={() => this.PopupRewad.current.open({})} type="primary">
                Thêm phần thưởng
              </Button>
            </div>
          );
        },

        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Popconfirm title="Bạn có chắc muốn xóa không? " onConfirm={() => this.removeReward(record.id)} okText="Có" cancelText="Không">
                <Button type="link">Xóa</Button>
              </Popconfirm>
              <a style={{ color: "#0287FF", marginRight: "10px" }} onClick={()=>this.PopupRewad.current.open(record)}>
                Sửa
              </a>
            </>
          );
        },
      },

    ];
    return columns;
  }

  render() {
    var colums = this.getColum();
    var data = this.state.data;
    setTimeout(()=>{this.setState({})},1000)
    return (
      <div>
        <PopupRewad ref={this.PopupRewad} dataTable = {data} addReward={this.addReward}></PopupRewad>
        {!this.state.refresh?
            <Table columns={colums} dataSource={data} style={{ width: "100%" }}></Table>
            :<>Loading...</>}
        <PopupColor ref={this.PopupColor}  dataTable = {data}  addReward={this.addReward}></PopupColor>
      </div>
    );
  }
}
