import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, message, Card, Col, Row, Typography, Form, Space, Modal, Divider, Comment, Table } from "antd";
import api from "api";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

export default class CategoryPicker extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { options: [] };
    this.PopupAddEdit = React.createRef();
  }

  componentDidMount() {
    this.loadCategory();
  }

  loadCategory() {
    api.get("/blog/listcategory").then((data) => {
      var options = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.setState({ options: options });
    });
  }

  addItem = (_) => {
    this.PopupAddEdit.current.open();
  };

  popupAddEditChange = (e) => {
    this.loadCategory();
    if (e) {
      this.props.onChange(e);
    }
  };

  render() {
    return (
      <>
        <Select
          placeholder={this.props.placeholder}
          options={this.state.options}
          onChange={this.props.onChange}
          value={this.props.value}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider />
              <div style={{ display: "flex", paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
                <div>
                  <Button type="link" icon={<PlusOutlined />} onClick={this.addItem}>
                    Thêm
                  </Button>
                </div>
              </div>
            </>
          )}
        />
        <PopupAddEdit ref={this.PopupAddEdit} onChange={this.popupAddEditChange} />
      </>
    );
  }
}

class PopupAddEdit extends Component {
  constructor(pros) {
    super(pros);
    this.state = { visible: false, add_item: "", data: [] };
  }

  componentDidMount() {
    this.loadCategory();
  }

  loadCategory() {
    api.get("/blog/listcategory").then((data) => {
      this.setState({ data: data });
    });
  }

  open() {
    this.setState({ visible: true });
  }

  handleCancel = (e) => {
    this.setState({ visible: false });
  };

  addItem = (_) => {
    api
      .post("/blog/addcategory", { name: this.state.add_item })
      .then((data) => {
        message.success("Đã thêm mới");
        this.setState({ add_item: "" });
        this.loadCategory();
        this.props.onChange(data.id);
      })
      .catch((e) => {
        switch (e.message) {
          case "Invalid name":
            e.message = "tên không đúng định dạng";
        }
        message.error("Lỗi " + e.message);
        this.setState({ add_item: "" });
      });
  };

  deleteConfirm = (id) => {
    api
      .post("/blog/gepostcountofcategory", { id: id })
      .then((data) => {
        Modal.confirm({
          title: "Xóa danh mục",
          icon: <ExclamationCircleOutlined />,
          content: `Danh mục hiện có ${data.count} bài viết trong hệ thống, bạn có muốn xóa danh mục ko?`,
          onOk: (e) => {
            return new Promise((r, e) => {
              this.delete(id);
              r();
            });
          },
          onCancel() {},
        });
      })
      .catch((e) => {});
  };

  delete = (id) => {
    api
      .post("/blog/deletecategory", { id: id })
      .then((data) => {
        message.success("Đã xóa");
        this.loadCategory();
        this.props.onChange();
      })
      .catch((e) => {
        message.error("Lỗi " + e.message);
        this.setState({ add_item: "" });
      });
  };

  render() {
    return (
      <Modal title="Danh mục" open={this.state.visible} onOk={this.handleCancel} onCancel={this.handleCancel} footer={null}>
        <div style={{ display: "flex", paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
          <div style={{ flex: 1, paddingRight: 15 }}>
            <Input
              placeholder="Nhập tên danh mục"
              onChange={(e) => {
                this.setState({ add_item: e.target.value });
              }}
              value={this.state.add_item}
            />
          </div>
          <div>
            <Button type="primary" icon={<PlusOutlined />} onClick={this.addItem}>
              Thêm
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          columns={[
            {
              title: "Tên",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "",

              sorter: false,
              render: (text, record, index) => {
                return (
                  <Space>
                    <Button type="link" onClick={(e) => this.deleteConfirm(record.id)}>
                      {" "}
                      Xóa
                    </Button>

                    {/* <Button type="link" onClick={() => { window.location = `/marketing/blog/edit?id=${record.id}` }} >Sửa</Button> */}
                  </Space>
                );
              },
            },
          ]}
        ></Table>
      </Modal>
    );
  }
}
