import React from "react";
import { Radio, Space, Button, Row, Form, Card, Checkbox, message, Col, DatePicker, Input, InputNumber, Select } from "antd";
import api from "api";
import CurrencyPicker from "components/picker/currency_picker";
import { formatShortDate } from "../../../common/format/date";
import { formatCurrency } from "../../../common/format/number";
import moment from "moment";
import { toNoUnicode } from "common/text";
import MyInputNumber from "components/picker/inputnumber_picker";
import UploadImagePicker from "components/picker/image_picker";
import SendingChannel from "./sending_channel";
import HTMLEditorPicker from "components/picker/html_editor_picker/index";
import ListVoucherPicker from "./picker/list_voucher_picker";
import DatePickerNew from "components/picker/date_picker";
import SegmentPicker from "components/picker/segment_picker";
import VoucherPicker from "./picker/voucher_picker";
import { useParams } from "react-router-dom";


const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

class VoucherPercentage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {},
      uploaded_logo: "/image/microsoft.png",
      showData: false,
      isLimit: false,
      PointLimit: false,
      load: false,
      segmentName: "",
      description: "",
      isPrefix: false,
      byLevel: false,
      participants: "all",
      point: {},
      program_id: null,
      voucher: null,
    };
    this.form = React.createRef();
    this.SendMethods = React.createRef();
    this.VoucherPicker = React.createRef();
  }

  componentDidMount() {
    console.log("hello");
    //  this.getApp();
    this.getTier();
  }

  // getApp = () => {
  //   api
  //     .post("shop/getinstalledappbycategoriesandsupports", {
  //       categories: ["crm"],
  //       supports: ["percentageoff"],
  //     })
  //     .then((res) => {
  //       this.setState({ lst: res, gotlstapp: true }, () => {
  //         this.getDataGold();
  //       });
  //     })
  //     .catch((err) => {});
  // };

  getTier = () => {
    api.post("/loyaltyconfig/getTier").then((res) => {
      this.setState({ tiers: res.tiers }, () => {
        this.getDataGold();
      });
    });
  };

  getConfig = () => {
    api
      .post("exchangepointprogram/getconfigbytype", {
        type: this.props.VoucherType,
      })
      .then((res) => {
        this.setState({ config: res }, () => {
          this.getData();
        });
      });
  };

  getDataGold = async () => {
    var point = await api.post("loyaltyconfig/getpoint");
    if (point != null) {
      this.setState(
        {
          point: point,
        },
        () => {
          this.getConfig();
        }
      );
    }
  };

  getData = () => {
    //  console.log(this.props.VoucherId)
    if (this.props.VoucherId == 0) {
      var voucher = null;
      var data = {
        name: "",
        id: "",
        type: this.props.VoucherType,
        is_active: true,
        uselogo: "system",
        image: "https://storage.googleapis.com/cubeloyalty_asia/system/default_img/cover_exchange_program.png",
        logo: "https://storage.googleapis.com/cubeloyalty_asia/system/default_img/logo_exchange_program.png",
        display_style: "image",
        short_description: "",
        voucher: voucher,
      };
      this.form.current.setFieldsValue(data);
      this.setState({ data: data, load: true }, () => {
        this.changeDescription(data);
      });
    } else {
      api
        .post("exchangepointprogram/getconfigbyid", {
          id: this.props.VoucherId,
        })
        .then((res) => {
          if (res.conditions.time_limit.is_limited_time) {
            this.setState({ timeLimit: true });
            res.conditions.time_limit.time = [moment(res.conditions.time_limit.start), moment(res.conditions.time_limit.end)];
          }

          this.form.current.setFieldsValue(res);
          this.setState({ data: res, load: true, voucher: res.voucher }, () => {
            this.changeDescription();
          });
        })
        .catch((err) => {
          console.log("err", err);
          message.warning("Không tìm thấy chương trình");
        });
    }
  };
  setVoucherData = (voucher) => {
    api.post("voucher/getbyid", { id: voucher }).then((res) => {
      this.setState({ voucher: res }, () => {
        this.changeDescription();
      });
    });
  };

  changeDescription() {
    var data = this.form.current.getFieldsValue();
    var voucher = this.state.voucher;
    var exchange = data.exchange;
    var config = this.state.config;
    var typeText = "";
    switch (this.props.VoucherType) {
      case "exchange_point":
        typeText = "Đổi điểm lấy khuyến mãi";
        break;
      case "exchange_percentage":
        typeText = "Đổi điểm lấy khuyến mãi phần trăm";
        break;
      case "exchange_freeship":
        typeText = "Đổi điểm lấy khuyến mãi freeship";
        break;
      case "exchange_gift":
        typeText = "Đổi điểm lấy quà";
        break;
      case "phone_card":
        typeText = "Đổi điểm lấy thẻ cào điện thoại thoại";
        break;
      default:
        break;
    }

    var textPoint = " ";
    if (data.exchange.is_by_tier) {
      var lst = Object.keys(data.exchange.tierpoint).map((item) => {
        return this.state.tiers.find((x) => x.id === item);
      });
      textPoint = "<div><ul>";
      lst.forEach((item) => {
        textPoint += "<li>" + item.name + ": " + (data.exchange.tierpoint[item.id] ? data.exchange?.tierpoint[item.id] : "") + " " + this.state.point + ".</li>";
      });
      textPoint += "</ul></div>";
    } else {
      textPoint = data.exchange.point ? data.exchange.point : "" + " " + this.state.point;
    }
    var text = "";
    if (data.conditions?.participants?.type === "all") {
      text = "Áp dụng với tất cả khách hàng.";
    } else {
      if (data.conditions?.participants?.type === "tier") {
        var tier = this.state.tiers.find((x) => x.id === data.conditions?.participants.tier_id);
        if (tier) {
          text = "Áp dụng với khách hàng có hạng: " + tier.name;
        } else {
          text = "Áp dụng với khách hàng có hạng: " + "";
        }
      } else {
        text = "Áp dụng với tập khách hàng: " + (this.state.segmentName ? this.state.segmentName : "");
      }
    }

    var textPrefix = "";
    if (voucher?.prefix?.is_prefix && voucher?.prefix?.value != null && voucher?.prefix?.value != "") textPrefix = "Tiền tố: " + voucher?.prefix?.value;
    else textPrefix = "Không có tiền tố";
    var textMin = "";

    var textVoucherLimit = "";
    if (voucher?.voucher_limit?.is_voucher_limit && voucher?.voucher_limit?.value != null && voucher?.voucher_limit?.value != "") textVoucherLimit = voucher?.voucher_limit?.value + " ngày";
    else textVoucherLimit = " không giới hạn";
    var textTotalSpent = "";
    if (voucher?.min_order_total_price?.is_min_order_total_price && voucher?.min_order_total_price.value != null && voucher?.min_order_total_price?.value != "")
      textTotalSpent = "Phải chi tiêu ít nhất " + voucher?.min_order_total_price?.value + " VNĐ để tham gia chương trình";
    else textTotalSpent = "Không giới hạn tiền chi tiêu";
    var voucherQuantityText = "";
    if (voucher?.voucher_quantity?.is_voucher_quantity && voucher?.voucher_quantity?.value != null) voucherQuantityText = ` ${formatCurrency(data?.voucher_quantity?.value)} voucher`;
    else voucherQuantityText = "không giới hạn";
    var description = config.description;
    var textMaxvalue = "";
    if (this.props.VoucherType != "exchange_freeship") {
      textMaxvalue = formatCurrency(voucher?.maxvalue) + " VNĐ";
    } else {
      textMaxvalue = formatCurrency(voucher?.value) + " VNĐ";
    }
    if (voucher?.max_value) {
      description = description.replace("{{max}}", "Giá trị lớn nhất " + formatCurrency(voucher?.max_value) + " VNĐ");
    } else {
      description = description.replace("{{max}}", "");
      description = description.replace("{{maxvalue}}", "");
    }
    if (voucher?.min_value) {
      description = description.replace("{{min}}", "Giá trị nhỏ nhất " + formatCurrency(voucher?.min_value) + " VNĐ");
    } else {
      description = description.replace("{{min}}", "");
      description = description.replace("{{minvalue}}", "");
    }
    description = description
      .replaceAll("{{point}}", formatCurrency(exchange.point) + " " + this.state.point.name.singular)
      .replaceAll("{{percent}}", formatCurrency(voucher?.exchange?.value))
      .replaceAll("{{voucherlimit}}", textVoucherLimit)
      .replace("{{prefix}}", textPrefix)
      .replace("{{maxvalue}}", textMaxvalue)
      .replace("{{voucherquantity}}", voucherQuantityText)
      .replace("{{min_order}}", textTotalSpent)
      .replace("{{productid}}", voucher?.exchange?.product_id)
      .replace("{{quantity}}", voucher?.exchange?.value)
      .replace("{{type}}", typeText)
      .replace("{{money}}", voucher?.exchange?.value)
      .replace("{{max}}", "Giá trị lớn nhất " + formatCurrency(voucher?.max_value) + " VNĐ")
      .replace("{{min}}", "Giá trị nhỏ nhất " + formatCurrency(voucher?.min_value) + " VNĐ");
    this.setState({ description: description });
  }

  saveProgram = (values) => {
    values.name = values.name.trim();
    var config = this.state.config;

    if (!values?.exchange?.is_by_tier) {
      values.short_description = "Khách hàng sẽ đươc tặng " + formatCurrency(values.exchange.point) + " " + this.state.point + " vào kho quà của mình";
    } else {
      values.short_description = "Khách hàng sẽ được tặng " + this.state.point + " (tùy vào hạng thành viên) vào kho quà của mình";
    }

    if (values.conditions.time_limit.is_limited_time) {
      values.conditions.time_limit = {
        is_limited_time: values.conditions.time_limit.is_limited_time,
        start: values.conditions.time_limit.time[0],
        end: values.conditions.time_limit.time[1],
      };
    } else values.conditions.time_limit.is_limited_time = false;

    if (values.customer_segment_id == null) values.customer_segment_id = "0";
    values.short_description = `Mỗi ${formatCurrency(values.exchange.point)} ${
      values.exchange.point > 1 ? this.state.point.name.plural : this.state.point.name.singular
    } tương đương với voucher khuyến mãi ${formatCurrency(values.exchange.percent)}%`;
    if (!values.voucher_limit) values.voucher_limit = 0;
    api
      .post("exchangepointprogram/saveprogram", { data: values })
      .then((res) => {
        message.success("Lưu thành công");
        var href = "/marketing/exchangeprogram" + "/" + this.props.VoucherType + "/" + res.program_id;
        if (!window.location.href.includes(href)) {
          console.log(href);
          window.location.href = href;
        }
        this.getConfig();
      })
      .catch((err) => {
        if (err.code == 262) {
          message.error("Voucher Prefix đã tồn tại!");
        } else {
          message.error("Có lỗi xảy ra khi lưu");
        }
      });
  };

  radioChange = (value) => {
    var config = this.state.config;
    config.is_active = value;
    this.setState({ config });
  };

  handleTemplateDescription = () => {
    var data = this.form.current.getFieldValue();

    this.changeDescription();
  };

  onPrefixChange = (value) => {
    var data = toNoUnicode(value.toUpperCase());
    this.form.current.setFieldsValue({ prefix: { value: data } });
    this.handleTemplateDescription();
  };

  onLimitChange = (value) => {
    var config = this.state.config;
    if (!value) {
      config.customer_segment_id = "0";
      this.form.current.setFieldsValue({ customer_segment_id: "0" });
    }
    this.setState({ isLimit: value }, () => this.handleTemplateDescription(config));
  };

  onTimeLimitChange = (value) => {
    var config = this.state.config;
    if (!value) {
      config.timelimit = null;
      this.form.current.setFieldsValue({ time_limit: null });
    } else {
      var a = moment();
      var b = moment().add(7, "days");
      var data = [a, b];
      this.form.current.setFieldsValue({ time_limit: data });
    }
    this.setState({ timeLimit: value }, () => this.handleTemplateDescription(config));
  };

  onSegmentChange = (e) => {
    this.setState({ segmentName: e.name }, () => {
      this.handleTemplateDescription();
    });
  };

  onIsPrefix = (check) => {
    if (!check) {
      this.form.current.setFieldsValue({ prefix: "" });
    }
    this.setState({ isPrefix: check });
  };

  getText = (value) => {
    if (value) {
      this.form.current.setFieldsValue({ text: `Sale of ${value}%!` });
    } else {
      this.form.current.setFieldsValue({ text: `Sale of` });
    }
  };

  participantsChange = (value) => {
    this.setState({ participants: value }, () => {
      this.changeDescription();
    });
  };

  render() {
    return (
      <Form name="emailsetting" ref={this.form} layout="vertical" onFinish={this.saveProgram}>
        {this.state.load && (
          <div className={"program-wrapper"} style={{ width: "101%", marginLeft: -13 }}>
            <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} style={{ marginLeft: 0, marginRight: 0 }}>
              <Col span={8} style={{ paddingRight: 0 }}>
                <Card title="Trạng thái" size="small" style={{ width: "100%", height: "100%" }} bodyStyle={{ height: "100%" }}>
                  <Form.Item hidden name={"id"} label={"id"} rules={[{ type: "string" }]}></Form.Item>
                  <Form.Item hidden name={"type"} label={"type"} rules={[{ type: "string" }]}></Form.Item>
                  <Form.Item name="is_active">
                    <Radio.Group onChange={(e) => this.radioChange(e.target.value)}>
                      <Space direction="vertical">
                        <Radio value={true} style={{ marginTop: 30 }}>
                          Hoạt động
                        </Radio>
                        <Radio value={false} style={{ marginTop: 30 }}>
                          Tạm dừng
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </Col>

              <Col span={16} style={{ paddingLeft: 0, paddingRight: 10 }}>
                <Card
                  title="Nội dung chương trình"
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: "0px 10px 10px 10px",
                  }}
                  bodyStyle={{ height: "100%" }}
                  size="small"
                >
                  {this.state.description != null && this.state.description != "" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.description,
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Card title="Hiển thị" size="small" style={{ width: "100%", margin: "10px 0px 10px 10px" }} bodyStyle={{ height: "100%" }}>
                <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }}>
                  <Col xs={{ span: 8 }} lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name={"name"}
                      label={"Tên chương trình"}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa nhập tên!",
                        },
                        {
                          validator: (rule, value) => {
                            return new Promise((resolve, error) => {
                              if (value.length < 500) {
                                resolve();
                              } else {
                                error();
                              }
                            });
                          },
                          message: "Không nhập quá 500 kí tự!",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập tên" />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item name="display_style" rules={[{ required: true, message: "Bạn phải chọn kiểu mô tả!" }]}>
                      <Radio.Group>
                        <Radio value="image">Hình ảnh</Radio>
                        <Radio value="text">Văn bản</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.display_style !== currentValues.display_style}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("display_style");
                        return (
                          <>
                            <div hidden={data === "text" ? false : true}>
                              <Form.Item
                                name="text"
                                style={{ marginTop: -26 }}
                                rules={
                                  data === "text" && [
                                    {
                                      required: true,
                                      message: "Bạn chưa nhập trường văn bản!",
                                    },
                                  ]
                                }
                              >
                                <Input placeholder="Văn bản" style={{ width: "48%" }} />
                              </Form.Item>
                            </div>
                            <div hidden={data === "image" ? false : true}>
                              <Row gutter={16}>
                                <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                  <Form.Item
                                    name="logo"
                                    label="Logo"
                                    style={{ marginTop: -25 }}
                                    rules={
                                      data === "image" && [
                                        {
                                          required: true,
                                          message: "Bạn chưa chọn logo!",
                                        },
                                      ]
                                    }
                                  >
                                    <UploadImagePicker crop={{ quality: 1, aspect: 3 / 4 }} size={{ width: 64, height: 64 }} tags={["promotion_icon"]}></UploadImagePicker>
                                  </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                  <Form.Item
                                    name="image"
                                    label="Hình ảnh"
                                    style={{ marginTop: -25 }}
                                    rules={
                                      data === "image" && [
                                        {
                                          required: true,
                                          message: "Bạn chưa chọn hình ảnh!",
                                        },
                                      ]
                                    }
                                  >
                                    <UploadImagePicker size={{ width: 400, height: 200 }} crop={{ quality: 1, aspect: 1.9 / 1 }} tags={["promotion"]}></UploadImagePicker>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row style={{ display: "flex", width: "100%" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <Card size="small" title="Chi tiết" style={{ width: "100%", margin: "0px 0px 10px 10px" }}>
                  {this.props.VoucherType != "phone_card" && (
                    <Form.Item rules={[{ required: true, message: "Bạn phải chọn kiểu chương trình!" }]} name="point_redemption_style">
                      <Radio.Group>
                        <Radio value="fixed">Cố định</Radio>
                        <Radio value="increments">Tăng dần</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}

                  <Row gutter={16}>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.point_redemption_style !== currentValues.point_redemption_style}>
                      {({ getFieldValue }) => {
                        var data = getFieldValue("point_redemption_style");
                        return (
                          <>
                            <Col span={6} hidden={data === "increments" ? false : true}>
                              <Form.Item name={["min_point", "is_min_point"]} valuePropName="checked">
                                <Checkbox
                                  onChange={() => {
                                    this.handleTemplateDescription();
                                  }}
                                  style={{ marginBottom: 10 }}
                                >
                                  Số {this.state.point.name.singular} tối thiểu cần thiết để đổi phần thưởng
                                </Checkbox>
                              </Form.Item>
                              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.min_point !== currentValues.min_point}>
                                {({ getFieldValue }) => {
                                  var value = getFieldValue(["min_point", "is_min_point"]);
                                  return (
                                    <Form.Item
                                      name={["min_point", "value"]}
                                      hidden={value ? false : true}
                                      rules={
                                        data === "increments" &&
                                        value == true && [
                                          {
                                            required: true,
                                            message: "Bạn chưa nhập số " + this.state.point.name.singular + " tối thiểu!",
                                          },
                                          {
                                            validator: (rule, value) => {
                                              var data = this.form.current.getFieldsValue();
                                              return new Promise((resolve, error) => {
                                                if (data.max_point.is_max_point == false || data.max_point.value == null || value < data.max_point.value) {
                                                  resolve();
                                                } else {
                                                  error();
                                                }
                                              });
                                            },
                                            message: "Giá trị tối thiểu phải nhỏ hơn số điểm tối đa!",
                                          },
                                        ]
                                      }
                                    >
                                      <MyInputNumber
                                        onChange={() => {
                                          this.handleTemplateDescription();
                                        }}
                                        style={{ width: "100%" }}
                                        min={0}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </Col>

                            <Col span={6} hidden={data === "increments" ? false : true}>
                              <Form.Item name={["max_point", "is_max_point"]} valuePropName="checked">
                                <Checkbox
                                  onChange={() => {
                                    this.handleTemplateDescription();
                                  }}
                                  style={{ marginBottom: 10 }}
                                >
                                  Số {this.state.point.name.singular} tối đa có thể đổi phần thưởng
                                </Checkbox>
                              </Form.Item>
                              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.max_point !== currentValues.max_point}>
                                {({ getFieldValue }) => {
                                  var value = getFieldValue(["max_point", "is_max_point"]);
                                  return (
                                    <Form.Item
                                      name={["max_point", "value"]}
                                      hidden={value ? false : true}
                                      rules={
                                        data === "increments" &&
                                        value == true && [
                                          {
                                            required: true,
                                            message: "Bạn chưa nhập " + this.state.point.name.singular + " tối đa!",
                                          },
                                          {
                                            validator: (rule, value) => {
                                              var data = this.form.current.getFieldsValue();
                                              return new Promise((resolve, error) => {
                                                if (data.min_point.is_min_point == false || data.min_point.value == null || value > data.min_point.value) {
                                                  resolve();
                                                } else {
                                                  error();
                                                }
                                              });
                                            },
                                            message: "Giá trị tối đa phải lớn hơn số điểm tối thiểu!",
                                          },
                                        ]
                                      }
                                    >
                                      <MyInputNumber
                                        onChange={() => {
                                          this.handleTemplateDescription();
                                        }}
                                        style={{ width: "100%" }}
                                        min={0}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        name={["exchange", "point"]}
                        label={this.state.point.name.singular}
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa chọn " + this.state.point.name.singular + "!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          style={{ width: "100%" }}
                          onChange={() => {
                            this.handleTemplateDescription();
                          }}
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                    <Col span={6} />

                    <Col span={8}>
                      <Form.Item
                        name="voucher_id"
                        label="Voucher đang sử dụng"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập voucher!",
                          },
                        ]}
                      >
                        <VoucherPicker
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            this.setVoucherData(e);
                          }}
                          type={this.props.VoucherType}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Row>
            <Row>
              <Col style={{ margin: "0px 0px 10px 10px", width: "100%" }}>
                <Card title="Điều kiện tham gia" style={{ width: "100%", height: "100%", marginBottom: 20 }} bodyStyle={{ height: "100%" }} size="small">
                  <Form.Item
                    label="Đối tượng"
                    name={["conditions", "participants", "type"]}
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa chọn loại đối tượng!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        this.participantsChange(e.target.value);
                      }}
                    >
                      <Radio value={"all"}>Tất cả</Radio>
                      <Radio value={"tier"}>Theo hạng</Radio>
                      <Radio value={"group"}>Theo nhóm</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {this.state.participants === "group" && (
                    <Form.Item
                      name={["conditions", "participants", "customer_segment_id"]}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa chọn nhóm!",
                        },
                      ]}
                      style={{ width: "50%" }}
                    >
                      <SegmentPicker setValueSegment={this.onSegmentChange}></SegmentPicker>
                    </Form.Item>
                  )}
                  {this.state.participants === "tier" && (
                    <Form.Item
                      name={["conditions", "participants", "tier_id"]}
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa chọn hạng!",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "50%" }}
                        // onChange={() => {
                        //   this.handleTemplateDescription();
                        // }}
                      >
                        {this.state.tiers.map((item) => {
                          return <Option value={item.id}>{item.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item name={["conditions", "time_limit", "is_limited_time"]} valuePropName="checked">
                    <Checkbox style={{ marginBottom: 10 }} checked={this.state.timeLimit} onChange={(e) => this.onTimeLimitChange(e.target.checked)}>
                      Giới hạn thời gian
                    </Checkbox>
                  </Form.Item>
                  {this.state.timeLimit && (
                    <Form.Item
                      name={["conditions", "time_limit", "time"]}
                      rules={
                        this.state.timeLimit && [
                          {
                            type: "array",
                            required: true,
                            message: "Bạn chưa chọn giới hạn thời gian!",
                          },
                        ]
                      }
                    >
                      <RangePicker format={"DD-MM-YYYY"} />
                    </Form.Item>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Card size="small" title="Mô tả" style={{ margin: "0px 0px 10px 10px", width: "100%" }}>
                <Form.Item
                  name="description"
                  style={{ height: "90%" }}
                  rules={[
                    {
                      required: true,
                      message: "Bạn chưa nhập mô tả!",
                    },
                  ]}
                >
                  <HTMLEditorPicker style={{ height: "100%" }}></HTMLEditorPicker>
                </Form.Item>
              </Card>
            </Row>
            <Row style={{ marginLeft: 10 }}>
              <Card size="small" style={{ width: "100%", height: 60 }}>
                <Button type="primary" style={{ position: "absolute", right: 20 }} htmlType="submit">
                  Lưu
                </Button>
              </Card>
            </Row>
          </div>
        )}
      </Form>
    );
  }
}
const VoucherPercentageHoc = () => {
  const { id,type} = useParams();

  return <VoucherPercentage VoucherId={id} VoucherType={type}></VoucherPercentage>;
};

export default VoucherPercentageHoc;

