import { Alert, Badge, Button, DatePicker, Descriptions, message, Modal, Progress, Result, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import api from "api";
import moment from "moment";
import * as dayjs from "dayjs";

function RerunWebhookModal({ mode, isModalOpen, setIsModalOpen, data }) {
  const [isRun, setIsRun] = useState(false);
  const [percent, setPercent] = useState(0);
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [listData, setListData] = useState([]);
  const [error, setError] = useState();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (mode === "pre_run") {
      getListDataFail();
    } else {
      sendWebhook(data);
      for (let index = 0; index < 101; index++) {
        setTimeout(() => {
          setPercent(index);
        }, 2000);
      }
    }
  }, [mode]);

  const getListDataFail = (created_time) => {
    api
      .post("webhook_log/listfail", { created_time: created_time })
      .then((res) => {
        setListData(res.data);
        setTotal(res.total);
      })
      .catch((error) => {
        console.log(error);
        setListData([]);
        setTotal(0);
      });
  };

  const onChangeDate = (e) => {
    if (e.length > 0) {
      var created_time = e.map((item, index) => {
        if (index === 0) {
          //   console.log(new Date(moment(dayjs(item).format("MM/DD/YYYY")).startOf("day")));
          return moment(dayjs(item).format("MM/DD/YYYY")).startOf("day").toISOString();
        } else {
          return moment(dayjs(item).format("MM/DD/YYYY")).endOf("day").toISOString();
        }
      });
      getListDataFail(created_time);
    }
  };

  const sendWebhook = async (data) => {
    await api
      .post(
        "app/miniapp/config/sendtourl",
        data.event === "order_create"
          ? {
              type: data.type,
              url: data.url,
              headers: data.headers,
              params: data.params,
              order: data.data,
              shop_name: localStorage.getItem("shop_name"),
              event: data.event,
              mode: "rerun",
            }
          : {
              type: data.type,
              url: data.url,
              headers: data.headers,
              params: data.params,
              payment: data.data,
              shop_name: localStorage.getItem("shop_name"),
              event: data.event,
              mode: "rerun",
            }
      )
      .then((res) => {
        setResponse(res);
        setError();
      })
      .catch((error) => {
        setError(error);
        setResponse();
      });
  };
  return (
    <Modal
      className="sync-modal"
      title={mode === "pre_run" ? "Chạy lại webhook thất bại" : "Gửi lại webhook"}
      footer={
        isRun
          ? []
          : [
              <Button key="back" onClick={handleCancel}>
                Thoát
              </Button>,
            ]
      }
      onCancel={handleCancel}
      width={700}
      //   bodyStyle={{ height: 260 }}
      open={isModalOpen}
    >
      {mode === "pre_run" && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            className="sync-alert"
            message="Lưu ý"
            description={
              <Space direction="vertical">
                <Badge color="black" text="Hệ thống chỉ chạy lại webhook thất bại" />
                <Badge color="black" text="Hệ thống sau khi chạy lại webhook sẽ cập nhật lại thời gian và trạng thái của webhook" />

                <Badge color="black" text="Hệ thống chỉ xử lý lại các loại hook còn tồn tại" />
              </Space>
            }
            type="info"
            showIcon
            // icon={<InfoCircleOutlined />}
          />
          <Row>
            <Descriptions style={{ width: "100%" }} column={1} bordered>
              <Descriptions.Item label="Ngày gửi">
                <DatePicker.RangePicker placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} onChange={onChangeDate}></DatePicker.RangePicker>
              </Descriptions.Item>
              <Descriptions.Item label="Trạng thái">Thất bại</Descriptions.Item>
            </Descriptions>
          </Row>
          <Row justify={"center"}>
            <Button
              type="primary"
              loading={isLoading}
              icon={!isLoading ? <SyncOutlined /> : ""}
              onClick={async () => {
                if (listData.length > 0) {
                  setIsLoading(true);
                  for (let index = 0; index < listData.length; index++) {
                    const item = listData[index];
                    await sendWebhook(item);
                  }
                  setIsLoading(false);
                  message.success("Chạy lại webhook thành công");
                }
              }}
            >
              Chạy lại {total} webhook
            </Button>
          </Row>
        </Space>
      )}

      {mode === "run" && (
        <>
          <Row justify={"center"} align={"middle"} style={{ minHeight: 400 }}>
            {!response && !error && (
              <Space direction="vertical" style={{ textAlign: "center" }}>
                <Progress type="circle" percent={percent} />
                <Typography.Text>{data.type}</Typography.Text>
                <Typography.Text>{data.order_id}</Typography.Text>
              </Space>
            )}

            {response && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Result status="success" title="Gửi lại thành công" />
                <Alert description={JSON.stringify(response)} type="success" showIcon />
              </Space>
            )}

            {error && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Result status="error" title="Gửi lại thất bại" />
                <Alert description={JSON.stringify(error)} type="error" showIcon />
              </Space>
            )}
          </Row>
        </>
      )}
    </Modal>
  );
}

export default RerunWebhookModal;
