import React from "react";
import { Radio, Space, Button, Row, Tabs, Form, InputNumber, Card, Checkbox, message, Col, DatePicker, Input, Select, Empty, Table, Dropdown } from "antd";
import PopupcreateChildVoucher from "./popupcreatechildvoucher";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { formatCurrency, formatDecimal } from "../common/format/number";

export default class VoucherChild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lstChildVoucher: this.props.voucherParent.list_voucher,
    };
    this.PopupcreateChildVoucher = React.createRef();
  }

  getChildVoucher = (lstChildVoucher) => {
    var lst = this.state.lstChildVoucher;
    lst = lst.concat(lstChildVoucher);
    this.setState({ lstChildVoucher: lst });
  };

  dropdownButton = () => {
    return (
      <div style={{ backgroundColor: "#ffffff", border: "1px solid #dfdfdf" }}>
        <div>
          <Button
            type="link"
            style={{ color: "#000000" }}
            onClick={() => {
              this.exportExcel(1);
            }}
          >
            Tất cả
          </Button>
        </div>
        <div>
          <Button
            type="link"
            style={{ color: "#000000" }}
            onClick={() => {
              this.exportExcel(2);
            }}
          >
            Đã sử dụng
          </Button>
        </div>
        <div>
          <Button
            type="link"
            style={{ color: "#000000" }}
            onClick={() => {
              this.exportExcel(3);
            }}
          >
            Chưa sử dụng
          </Button>
        </div>
      </div>
    );
  };

  exportExcel = (mode) => {
    var lstdata = [];
    var lstvoucher = this.state.lstChildVoucher;
    if (mode == 1) {
      lstdata = lstvoucher;
    }
    if (mode == 2) {
      lstdata = lstvoucher.filter((x) => x.is_use == true);
    }
    if (mode == 3) lstdata = lstvoucher.filter((x) => x.is_use == false);
    var lstexcel = lstdata.map((item) => {
      var data = "";
      if (item.is_use) data = "Đã dùng";
      else data = "Chưa dùng";
      var o = {};

      o["Mã voucher"] = item["id"];
      o["Ngày tạo"] = moment(item["create_date"]).format("DD/MM/YYYY");
      o["Sử dụng"] = data;

      return o;
    });

    const ws = XLSX.utils.json_to_sheet(lstexcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dt = new Blob([excelBuffer], { type: ".xlsx" });
    FileSaver.saveAs(dt, "danh sách voucher con (" + this.props.voucherParent.voucher_code + ").xlsx");
  };

  render() {
    var columns = [
      {
        title: "Mã con",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Ngày tạo",
        dataIndex: "create_date",
        key: "create_date",
        render: (text, record, index) => {
          var date = moment(record.create_date);
          return <div>{date.format("DD-MM-YYYY")}</div>;
        },
      },
      {
        title: "Sử dụng",
        dataIndex: "is_use",
        key: "is_use",
        render: (text, record, index) => {
          return <div>{record.is_use ? "Đã được sử dụng" : "Chưa được sử dụng"}</div>;
        },
      },
    ];
    if (this.props.voucherParent.voucher_type === "pointprogarm_prefix") {
      columns = [
        {
          title: "Mã con",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Giá trị",
          dataIndex: "value",
          key: "value",
          render: (text, record, index) => {
            return <div>{formatCurrency(record.value)}</div>;
          },
        },
        {
          title: "Ngày tạo",
          dataIndex: "create_date",
          key: "create_date",
          render: (text, record, index) => {
            var date = moment(record.create_date);
            return <div>{date.format("DD-MM-YYYY")}</div>;
          },
        },
        {
          title: "Ngày hết hạn",
          dataIndex: "expiration_date",
          key: "expiration_date",
          render: (text, record, index) => {
            var date = moment(record.expiration_date);
            return <div>{date.format("DD-MM-YYYY")}</div>;
          },
        },
        {
          title: "Sử dụng",
          dataIndex: "is_use",
          key: "is_use",
          render: (text, record, index) => {
            return <div>{record.is_use ? "Đã được sử dụng" : "Chưa được sử dụng"}</div>;
          },
        },
      ];
    }
    var pagination = {
      showTotal: () => {
        return <div style={{ marginRight: 30 }}>Tổng cộng: {this.state.lstChildVoucher.length} voucher</div>;
      },
    };
    return (
      <Card
        title={<span style={{ fontSize: 21 }}>Danh sách voucher</span>}
        style={{ paddingLeft: 6, width: "50%" }}
        bodyStyle={{ display: "flex", height: "100%" }}
        extra={
          <div>
            <Dropdown overlay={() => this.dropdownButton()}>
              <Button>
                ExportExcel &nbsp;<i className="fa fa-chevron-down" aria-hidden="true"></i>
              </Button>
            </Dropdown>
            <Button
              hidden={this.props.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false}
              style={{ marginLeft: 20 }}
              onClick={() => {
                this.PopupcreateChildVoucher.current.open();
              }}
              type="primary"
            >
              <i className="fas fa-plus"></i> &nbsp;Thêm mới
            </Button>
          </div>
        }
      >
        {this.state.lstChildVoucher.length == 0 ? (
          <div style={{ margin: "auto" }}>
            <Empty />
            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                hidden={this.props.voucherParent.voucher_type === "pointprogarm_prefix" ? true : false}
                style={{ margin: "auto", width: 200 }}
                onClick={() => {
                  this.PopupcreateChildVoucher.current.open();
                }}
                type="primary"
              >
                <i className="fas fa-plus"></i> &nbsp;Thêm mới
              </Button>
            </div>
          </div>
        ) : (
          <Table columns={columns} dataSource={this.state.lstChildVoucher} pagination={pagination} rowKey={(record) => record.id} style={{ width: "100%" }}></Table>
        )}

        <PopupcreateChildVoucher ref={this.PopupcreateChildVoucher} voucherParent={this.props.voucherParent} getChildVoucher={this.getChildVoucher}></PopupcreateChildVoucher>
      </Card>
    );
  }
}
