"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IFrame = function IFrame(_ref) {
  var onLoad = _ref.onLoad,
      src = _ref.src,
      title = _ref.title,
      style = _ref.style,
      id = _ref.id,
      srcDoc = _ref.srcDoc,
      frameborder = _ref.frameborder,
      height = _ref.height,
      width = _ref.width,
      key = _ref.key,
      referrerPolicy = _ref.referrerPolicy,
      sandbox = _ref.sandbox;

  var ifrm = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    if (ifrm.current.contentDocument && srcDoc) {
      var doc = ifrm.current.contentWindow.document;
      doc.open();
      doc.write(srcDoc);
      doc.close();
      onLoad && onLoad();
    }
  }, [srcDoc, onLoad]);

  return _react2.default.createElement("iframe", {
    ref: ifrm,
    id: id,
    src: src,
    style: style,
    onLoad: onLoad,
    title: title,
    frameBorder: frameborder,
    height: height,
    width: width,
    key: key,
    referrerPolicy: referrerPolicy,
    sandbox: sandbox
  });
};

exports.default = IFrame;