import React, { Component } from "react";

import { Form, Input, InputNumber, Button, Space, Modal, Select, Popconfirm, Card, Row, Col, message } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Typography } from "antd";
import api from "api";
import Field from "./field";

import { dataTypes, makeid } from "common/custom_field";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "rgb(228, 230, 235)",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});
export default class CustomerField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      items: [],
      dataTypes: dataTypes,
      editID: "",
      itemTam: [],
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    var dataPost = {
      query: { key: "field" },
    };
    api
      .get("customerconfig/getconfig", dataPost)
      .then((res) => {
        if (res.length > 0) {
          this.setState({ itemTam: res, items: res });
        }
      })
      .catch((err) => {});
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    var items = [];
    var lsttam = [...this.state.itemTam];
    var lst = [...this.state.items];
    var itemtam = reorder(lsttam, result.source.index, result.destination.index);
    this.setState({
      itemTam: itemtam,
    });
    itemtam.forEach((item, index) => {
      if (lst.findIndex((x) => x == item) >= 0) {
        items.push(item);
      }
    });

    this.setState(
      {
        items: items,
      },
      this.save
    );
  };

  getColID = (e) => {
    var col = "";
    while (true) {
      col = "col_" + makeid(5);
      if (this.state.items.findIndex((x) => x.id == col) < 0) return col;
    }
  };

  addField = (item) => {
    var values = { ...item, label: "Untitle", id: this.getColID(), attribute: "" };
    values.showEdit = true;

    //api.post("customer/addfield",values)
    var lst = [...this.state.itemTam];
    lst.push(values);
    this.setState({
      itemTam: lst,
    });
  };

  save = () => {
    var value = this.state.items;
    api.post("customerconfig/save", { field: value });

    //  this.props.onFieldChange(this.state.items)
    //  this.form.current.submit();
    //this.setState({ isVisible: false })
  };

  updateItem = (item, type) => {
    var { id, label } = item;
    var lst = [...this.state.items];
    var lsttam = [...this.state.itemTam];
    var index = lsttam.findIndex((x) => x.id == id);
    lsttam[index] = item;
    delete item.showEdit;
    if (type == "edit") {
      var index = lst.findIndex((x) => x.id == id);
      lst[index] = item;
      delete item.showEdit;
    } else {
      delete item.showEdit;
      lst.push(item);
    }
    this.setState({
      items: lst,
      itemTam: lsttam,
    });

    api.post("customerconfig/save", { field: lst });
  };

  deleteItem = (id) => {
    console.log("fgregt")
    var lsttam = [...this.state.itemTam];
    var i = lsttam.findIndex((x) => x.id == id);

    lsttam.splice(i, 1);
    this.setState({
      itemTam: lsttam,
    });

    var lst = [...this.state.items];
    var i = lst.findIndex((x) => x.id == id);
    if (i >= 0) {
      lst.splice(i, 1);
      this.setState({
        items: lst,
      });
    }

    api.post("customerconfig/save", { field: lst });
  };

  render() {
    return (
      <Card title="Cấu hình trường dữ liệu">
        <Row>
          <Col span={8}>
            <div style={{ padding: "0 20px" }}>
              <table style={{ borderTop: "1px solid #dfdfdf", width: "100%" }}>
                {this.state.dataTypes.map((item) => {
                  return (
                    <tr style={{ border: "1px solid #dfdfdf", height: "47px", display: "flex", borderTop: "0" }}>
                      <div style={{ margin: "auto", display: "flex", justifyContent: "space-between", width: "100%", padding: "0 20px" }}>
                        {item.label}
                        <div style={{ cursor: "pointer", backgroundColor: "#007BFF", borderRadius: "5px", width: "20px", height: "15px", position: "relative" }}>
                          <span style={{ color: "#ffffff", position: "absolute", top: "-4px", right: "5px" }} onClick={(e) => this.addField(item)}>
                            +
                          </span>
                        </div>
                      </div>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Col>
          <Col span={16}>
            {this.state.itemTam.length > 0 && (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {this.state.itemTam.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <li style={{ margin: "0px", padding: "10px" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Field lstdata={this.state.items} data={item} deleteItem={this.deleteItem} save={this.updateItem} showEdit={item.showEdit}>
                                {" "}
                              </Field>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}
