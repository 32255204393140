import React, { Component } from "react";
import api from "api";
import { Input, Button, Space, Card, Empty, Tooltip, Row, Popconfirm, message, Skeleton, Col } from "antd";
import PopupSetting from "./popupsetting";
const { Meta } = Card;

export default class SmsFptAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      signIn: "",
      showBtn: false,
      loading: true,
    };
    this.PopupSetting = React.createRef();
  }

  componentDidMount = () => {
    this.getConfig();
  };

  // lấy data config
  getConfig = () => {
    api.post("app/smsfpt/config/getconfig", { shop_id: 0 }).then((res) => {
      if (res != null) {
        this.setState({ data: res, loading: false });
      } else {
        this.setState({ showBtn: true, loading: false });
      }
    });
  };

  //deleteconfig
  delete = (id) => {
    var dataPost = {
      id: id,
      shop_id: 0,
    };
    api
      .post("app/smsfpt/config/delete", dataPost)
      .then((res) => {
        message.success("Xóa thành công", 3);
        this.setState({ loading: true });
        this.getConfig();
        this.setState({ visible: false, data: [] });
      })
      .catch((err) => {
        message.error("Xóa thất bại", 3);
      });
  };

  //render item
  renderItem = (element, item) => {
    return (
      <div style={{ marginRight: "10px", height: 320 }}>
        <Card
          className={"sms-fpt-card-item"}
          bordered={true}
          cover={<img height="auto" src={item.img} />}
          actions={[
            <div
              onClick={() => {
                this.PopupSetting.current.open("edit", element);
              }}
            >
              <i className="far fa-edit"></i>
            </div>,
            <Popconfirm
              title="Bạn có chắc muốn xóa không?"
              onConfirm={() => {
                this.delete(element.id);
              }}
              okText="Có"
              cancelText="Không"
            >
              ,
              <div style={{ marginTop: -22 }}>
                <i className="far fa-trash-alt"></i>
              </div>
            </Popconfirm>,
          ]}
        >
          <Meta title="SMS FPT" description={element.name} />
        </Card>
      </div>
    );
  };

  render() {
    // danh sách các tài khoản có thể kết nối
    var data = this.state.data.configs;
    var lst = [{ type: "smsfpt", name: "FPT", img: "/assets/images/app/smsfpt/smsfpt-outline.png" }];

    return (
      <>
        <Card
          size="small"
          title={<span style={{ fontWeight: "normal", fontSize: "14px" }}>Các tài khoản đã kết nối</span>}
          bordered={false}
          style={{ border: "1px solid #dfdfdf" }}
          extra={
            <Button type="primary" onClick={() => this.PopupSetting.current.open("add", {})}>
              Thêm mới
            </Button>
          }
        >
          {this.state.loading ? (
            <Skeleton />
          ) : !data || data.length === 0 ? (
            <div style={{ marginTop: "13vh" }}>
              <Empty description="Hãy thêm mới tài khoản đầu tiên"></Empty>
              <Row justify="center" style={{ marginBottom: "15vh" }}>
                <Button onClick={() => this.PopupSetting.current.open("add", {})} style={{ marginTop: 20 }} type="primary">
                  <i className="fas fa-plus"></i>&nbsp;Thêm mới
                </Button>
              </Row>
            </div>
          ) : (
            <Row>
              {lst.map((item) => {
                return data.map((element) => {
                  return (
                    <Col xl={4} lg={5} md={6}>
                      {this.renderItem(element, item)}
                    </Col>
                  );
                });
              })}
            </Row>
          )}
          <PopupSetting ref={this.PopupSetting} data={this.state.data} getConfig={this.getConfig}></PopupSetting>
        </Card>
      </>
    );
  }
}
