import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Button, message, Row, Col } from "antd";
import api from "api";
import moment from "moment";

const ShowLogo = ({ lst, lstapp }) => {
  const [lstlogo, setLstlogo] = useState([]);
  useEffect(() => {
    var lstlogocopy = [];
    lst.forEach((item) => {
      var index = lstapp.findIndex((x) => x.id === item.id);
      if (index > -1) lstlogocopy.push(lstapp[index].logo);
    });
    setLstlogo(lstlogocopy);
  }, [lst, lstapp]);
  return (
    <Space style={{ display: "flex", flexWrap: "wrap", maxWidth: "450px" }}>
      {lstlogo.map((item) => {
        return (
          <Space.Compact style={{ margin: "20px" }}>
            <img src={item} style={{ width: "70px", height: "70px" }}></img>
          </Space.Compact>
        );
      })}
    </Space>
    // <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "450px" }}>
    //   {lstlogo.map((item) => {
    //     return (
    //       <div style={{ margin: "20px" }}>
    //         <img src={item} style={{ width: "70px", height: "70px" }}></img>
    //       </div>
    //     );
    //   })}
    // </div>
  );
};
function SuccessCampaign({ prevStep, value }) {
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState();
  const [lstapp, setLstApp] = useState([]);
  const [segment, setSegment] = useState({});
  const dateofweek = [
    {
      name: "Thứ 2",
      value: "MON",
    },
    {
      name: "Thứ 3",
      value: "TUE",
    },
    {
      name: "Thứ 4",
      value: "WED",
    },
    {
      name: "Thứ 5",
      value: "THU",
    },
    {
      name: "Thứ 6",
      value: "FRI",
    },
    {
      name: "Thứ 7",
      value: "SAT",
    },
    {
      name: "Chủ nhật",
      value: "SUN",
    },
  ];
  const onFinish = async () => {
    await api
      .post("campaign/save", value)
      .then((res) => {
        message.success("Lưu chiến dịch thành công", 3);
        setTimeout(() => {
          onExit();
        }, 1000);
        // this.props.navigate("/campaign");
        // this.setState({ visible: false, steps: this.defaultSteps, current: 0, loadingBtn: false }, () => {
        //     this.campaignForm.current.resetFields();
        //     this.props.reload();
        // });
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };
  const getSegment = () => {
    api
      .post("segments/getsegmentbyid", { id: value.customer_segment_id })
      .then((res) => {
        setSegment(res);
      })
      .catch((err) => {
        setSegment({});
      });
  };
  const getApp = () => {
    api
      .post("shop/getinstalledappbycategories", { categories: ["communication"] })
      .then((res) => {
        setLstApp(res);
      })
      .catch((err) => {});
  };

  const onExit = () => {
    navigate("/campaign");
  };

  useEffect(() => {
    switch (value.schedule.type) {
      case "onetime":
        setSchedule("Một lần - " + moment(value.schedule.time).format("HH:mm") + " " + moment(value.schedule.date).format("DD/MM/YYYY"));
        break;
      case "daily":
        setSchedule(moment(value.schedule.time).format("HH:mm") + " hàng ngày");
        break;
      case "weekly":
        var day = dateofweek.find((x) => x.value === value.schedule.date_of_week);
        setSchedule(moment(value.schedule.time).format("HH:mm") + " " + day.name + " " + "hàng tuần");
        break;
      case "monthly":
        setSchedule(moment(value.schedule.time).format("HH:mm") + " ngày " + value.schedule.date_of_month + " hàng tháng");
        break;
    }
    // var vl = moment();
    // if (value.schedule.time != null && value.schedule.time != "") {
    //   var date = new Date(value.schedule.time);
    //   vl = moment(date);
    // } else vl = "";
    // switch (value.schedule.type) {
    //   case "daily":
    //     setSchedule(vl.format("HH:mm") + " hàng ngày");
    //     break;
    //   case "weekly":
    //     var name = dateofweek.find((x) => x.value === value.schedule.dateofweek);
    //     setSchedule(vl.format("HH:mm") + " " + name.name + " hàng tuần");
    //     break;
    //   case "monthly":
    //     setSchedule(vl.format("HH:mm") + " ngày " + value.schedule.dateofmonth + " hàng tháng");
    //     break;
    //   case "onetime":
    //     var date = new Date(value.schedule.date);
    //     var text = moment(value.schedule.date).utc().format("DD-MM-YYYY");
    //     setSchedule("Một lần - " + moment(value.schedule.time).utc().format("HH:mm") + " " + text);
    //     break;
    // }
  }, [value]);
  useEffect(() => {
    getSegment();
    getApp();
  }, []);

  return (
    <>
      <Row style={{ minHeight: "56vh" }}>
        <Col span={11}>
          <Space direction="vertical" style={{ padding: 20, gap: 0 }}>
            <Space.Compact style={{ marginBottom: "20px" }}>
              <label style={{ fontWeight: "bold" }}>Chiến dịch:</label>&ensp;
              <span>{value.name}</span>
            </Space.Compact>
            <Space.Compact style={{ marginBottom: "20px" }}>
              <label style={{ fontWeight: "bold" }}>Mô tả:</label>&ensp;
              <span>{value.description}</span>
            </Space.Compact>
            <Space.Compact style={{ marginBottom: "20px" }}>
              <label style={{ fontWeight: "bold" }}>Lịch chạy:</label>&ensp;
              <span>{schedule}</span>
            </Space.Compact>
            <Space.Compact style={{ marginBottom: "20px" }}>
              <label style={{ fontWeight: "bold" }}>Đối tượng:</label>&ensp;
              <span>{value.customer_segment ? value.customer_segment[0] && value.customer_segment[0].name : segment?.name || ""}</span>
            </Space.Compact>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" style={{ padding: 20, gap: 0 }}>
            <Space.Compact>
              <label style={{ fontWeight: "bold" }}>Nội dung</label>
            </Space.Compact>
            <Space.Compact style={{ margin: "20px" }}>
              <Button onClick={prevStep}>
                {" "}
                <i className="fas fa-eye"></i>&ensp;xem nội dung
              </Button>
            </Space.Compact>
            <Space.Compact>
              <label style={{ fontWeight: "bold" }}>Kênh gửi</label>
            </Space.Compact>
            <Space.Compact>
              <ShowLogo lst={value.way_to_send} lstapp={lstapp}></ShowLogo>
            </Space.Compact>
          </Space>
        </Col>
      </Row>
      <Space style={{ float: "right", marginTop: 16 }}>
        <Button onClick={prevStep}>Trở lại</Button>
        <Button type="primary" onClick={onFinish}>
          Hoàn tất
        </Button>
      </Space>
    </>
  );
}

export default SuccessCampaign;
