import React from "react";
import Customer from "./index"
import api from "api"
export default class CustomerInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            customerID: null
        }
    }

    componentDidMount() {
        //this.props.options.phone
        api.post("customer/getcustomerbyinfo", { phone: this.props.options.phone, email: this.props.options.email }).then(data => {
            if (data.id) {
                this.setState({ isLoading: false, customerID: data.id })
            }
            else {
                this.setState({ isLoading: false })
            }
        }).catch(err => {
            this.setState({ isLoading: false })
        })

    }


    render() {
        if (this.state.isLoading) {
            return (
                <div>Loading ...</div>
            )
        }
        if (!this.state.customerID) {
            return (
                <div>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                        <img src="https://storage.googleapis.com/cubeloyalty_asia/system/Logo%20CubeLoyalty/chu_nhat_1.png" style={{ width: "300px", height: "auto" }}></img>

                    </div>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex", marginTop: 5, flexDirection: "column", alignItems: "center" }}>
                        <div>
                            Không tìm thấy thông tin khách hàng tại hệ thống Cubeloyalty.
                        </div>
                        <div>Vui lòng đồng bộ dữ liệu khách hàng 2 hệ thống.</div>
                        <div>
                            Tham khảo <a href="https://cubetiger.app/apps/trustsales" target="blank">tại đây</a>.
                        </div>
                    </div>



                </div>
            )
        }
        return (



            <Customer id={this.state.customerID}></Customer>

        )
    }
}
