import React, { useState } from "react";
import { Modal, Button, Form, Input, Steps, Table, Checkbox, message } from "antd";
import api from "api";
import { toNoUnicode } from "common/text";

export default class PopupName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      name: "",
      item: {},
      data: {},
    };
    this.form = React.createRef();
  }

  open = (item, id) => {
    console.log("ghuhgi'");
    if (id && id != "") {
      api.post("app/defaultemail/config/getaccountbyid", { id: item.id, account_id: id }).then((res) => {
        this.setState({ isVisible: true, item: item, data: res }, () => {
          this.form.current.resetFields();
          this.form.current.setFieldsValue(res);
        });
      });
    } else {
      var data = {};
      this.setState({ isVisible: true, data: data, item: item }, () => {
        this.form.current.resetFields();
        this.form.current.setFieldsValue({ name: null, prefix: null, id: null });
      });
    }
  };

  handleCancel = () => {
    this.setState({ isVisible: false });
  };

  save = (values) => {
    api
      .post("app/defaultemail/config/savename", { id: this.state.item.id, data: values })
      .then((res) => {
        message.success("Lưu thành công");
        this.props.reload();
        this.setState({ loadingBtn: false, isVisible: false });
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        this.setState({ loadingBtn: false });
      });
  };

  onChange = (value, name) => {
    var state = this.state;
    state[name] = value;
    this.setState(state);
  };

  prefixChnage = (value) => {
    var data = toNoUnicode(value);
    this.form.current.setFieldsValue({ prefix: data });
  };

  render() {
    return (
      <>
        <Modal
          title="Thêm tài khoản mới"
          width={"50%"}
          open={this.state.isVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                this.handleCancel();
              }}
            >
              Thoát
            </Button>,

            <Button
              type="primary"
              loading={this.state.loadingBtn ? true : false}
              onClick={() => {
                this.setState({ loadingBtn: true }, () => {
                  this.form.current.submit();
                  this.setState({ loadingBtn: false });
                });
              }}
            >
              Lưu
            </Button>,
          ]}
        >
          <Form ref={this.form} layout="vertical" onFinish={this.save}>
            <Form.Item
              name="name"
              label="Tên"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên!",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              name="prefix"
              label="Địa chỉ mail"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tiền tố!",
                },
              ]}
            >
              <Input
                suffix={"@" + this.state.item.domain}
                onChange={(e) => {
                  this.prefixChnage(e.target.value);
                }}
              ></Input>
            </Form.Item>
            <Form.Item name={"id"} hidden></Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
