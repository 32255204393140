import { memo, useMemo, useEffect, useState } from "react";
import { Tabs, Button, Card, message } from "antd";
import InformationAccount from "./InformationAccount_config";
import ListTemplate from "./ListTemplate_config";
import SendingHistory from "./SendingHistory";
import api from "api";
import ANTDConfiguration from "./configuration";
const ZaloApp = ({ data, position, getData }) => {
  //hoàng
  //tuấn
  const deleteoas = () => {
    const oa_id = data?.oas[0]?.oa_id || null;
    api
      .delete("app/zalo/config/deleteoa", { data: { oa_id: oa_id } })
      .then((res) => {
        getData();
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const slot = useMemo(() => {
    return (
      <Button danger type="link" onClick={deleteoas}>
        <span className="ZaloApp_Card_tabBarExtraContent">Gỡ tài khoản</span>
      </Button>
    );
  }, [position]);
  const items = [
    {
      key: "1",
      label: <span className="ZaloApp_Card_Label">Thông tin tài khoản</span>,
      children: <InformationAccount configOaId={data?.oas[0]?.oa_id || null} />,
    },
    {
      key: "2",
      label: <span className="ZaloApp_Card_Label">Cài đặt cấu Hình</span>,
      children: <ANTDConfiguration configMenuData={data.menu_setting} getData={getData}/>,
    },
    {
      key: "3",
      label: <span className="ZaloApp_Card_Label">Danh sách mẫu tin</span>,
      children: <ListTemplate configOaId={data?.oas[0]?.oa_id || null} />,
    },
    {
      key: "4",
      label: <span className="ZaloApp_Card_Label">Lịch sử gửi tin</span>,
      children: <SendingHistory configOaId={data?.oas[0]?.oa_id || null} />,
    },
  ];
  //tuấn
  //nhật
  //nhật

  return (
    <>
      <div>
        <Card style={{ minHeight: "86vh" }}>
          <Tabs defaultActiveKey="1" items={items} tabBarExtraContent={slot} />
        </Card>
      </div>
    </>
  );
};

export default memo(ZaloApp);
