import React, { Component } from "react";
import api from "api";
import { Input, Button, Form, Checkbox, Row, Col, message, Modal, Radio } from "antd";
import EmailTypePicker from "./type_picker";
import EmailGmailPicker from "./gmail_picker";
import PopupTestSendEmail from "./popuptestsendemail";

export default class PopupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {
        type: "",
        gmail: null,
        smtp: {
          secure: true,
          auth: {
            username: "",
            password: "",
          },

          port: "",
          mailserver: "",
        },
        name: "",
        id: "",
      },
      type: "",
      mode: "",
      message: "Gửi tối đa 500 email trong một ngày",
      show: false,
    };
    this.inputType = React.createRef();
    this.PopupTestSendEmail = React.createRef();
    this.form = React.createRef();
  }

  ///lưu setting
  saveSetting = (data) => {
    var mode = this.state.mode;
    data.id = this.state.data.id;
    var dataPost = { config: data, shop_id: 0, type: mode };
    api
      .post("app/email/config/save", dataPost)
      .then((res) => {
        message.success("Lưu thành công", 3);
        this.props.getDataEmailConfig();
        this.setState({ visible: false, data: {} });
      })
      .catch((err) => {
        message.error("Lưu thất bại", 3);
      });
  };

  open = (mode, element) => {
    if (mode === "add") {
      var data = {
        type: "",
        gmail: null,
        smtp: {
          secure: true,
          auth: {
            username: "",
            password: "",
          },

          port: "",
          host: "",
        },
        name: "",
        id: "",
      };
      this.setState({ data: data, visible: true, mode: mode, show: true, type: "smtp" }, () => {
        this.form.current.resetFields();
      });
    } else {
      this.setState({ data: element, visible: true, mode: mode, type: element.type, show: true }, () => {
        this.setDataForm(element);
      });
    }
  };

  setDataForm = (data) => {
    this.form.current.setFieldsValue(data);
  };

  onSuccess = (response) => {
    api.post("app/email/googleaccount/authenticate", { code: response.code }).then((res) => {
      this.setState({ data: { gmail: res } });
    });
  };

  onValuesChange = (changedValues, allValues) => {
    this.setState({ data: allValues });
  };

  sendTestEmail = async (e) => {
    try {
      await this.form.current.validateFields();
      this.PopupTestSendEmail.current.open(this.state.data);
    } catch (e) {
      message.error("Bạn phải nhập đầy đủ thông tin");
    }
  };

  render() {
    return (
      <Modal
        title="Cấu hình email"
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, type: "" })}
        footer={[
          <Button
            type="primary"
            onClick={(e) => {
              this.form.current.submit();
            }}
          >
            Lưu
          </Button>,
          <div style={{ float: "left" }}>
            <Button onClick={this.sendTestEmail}>Gửi email test</Button>
          </div>,
        ]}
        width="50%"
      >
        {this.state.show && (
          <Form name="emailsetting" id="form_setting" ref={this.form} layout="vertical" onFinish={this.saveSetting} onValuesChange={this.onValuesChange} initialValues={{ type: "smtp" }}>
            <Form.Item
              name="name"
              label={<h3>Tên tài khoản</h3>}
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập tên!",
                },
              ]}
            >
              <Input placeholder="Tên tài khoản" style={{ maxWidth: "880px", borderRadius: "5px" }} />
            </Form.Item>
            <Form.Item name="id" hidden></Form.Item>

            <Form.Item
              name="name_send"
              label={<h3>Tên gửi</h3>}
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập tên gửi!",
                },
              ]}
            >
              <Input placeholder="Tên gửi" style={{ maxWidth: "880px", borderRadius: "5px" }} />
            </Form.Item>

            <Form.Item
              name="type"
              label={<h3>Loại</h3>}
              rules={[
                {
                  validator: (rule, value) => {
                    return new Promise((resolve, error) => {
                      if (value) {
                        resolve();
                      } else {
                        error();
                      }
                    });
                  },
                  message: "Bạn cần chọn 1 loại!",
                },
              ]}
            >
              <EmailTypePicker
                onChange={(e) => {
                  this.setState({ type: e });
                }}
              ></EmailTypePicker>
            </Form.Item>

            <Form.Item name={["gmail", "auth"]} hidden={this.state.type === "gmail" ? false : true}>
              <Input.Group>
                <Row gutter={16} style={{ marginTop: "10px" }}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name={["gmail", "auth", "username"]}
                      label="Tài khoản Gmail"
                      rules={
                        this.state.type === "gmail" && [
                          {
                            required: true,

                            message: "Bạn chưa nhập tài khoản Gmail!",
                          },
                          {
                            type: "email",
                            message: "Email không đúng định dạng!",
                          },
                        ]
                      }
                    >
                      <Input placeholder="Tài khoản Gmail" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name={["gmail", "auth", "password"]}
                      label="Mật khẩu"
                      rules={
                        this.state.type === "gmail" && [
                          {
                            required: true,
                            message: "Bạn chưa nhập mật khẩu Gmail!",
                          },
                        ]
                      }
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <EmailGmailPicker />
                </Row>
              </Input.Group>
            </Form.Item>

            <Form.Item hidden={this.state.type === "smtp" ? false : true}>
              <Input.Group>
                <div>
                  <Row gutter={16} style={{ marginTop: "10px" }}>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        name={["smtp", "host"]}
                        label="Server"
                        rules={
                          this.state.type === "smtp" && [
                            {
                              required: true,
                              message: "Bạn chưa nhập sever của mail!",
                            },
                          ]
                        }
                      >
                        <Input placeholder="Email sever" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Form.Item
                        name={["smtp", "port"]}
                        label=" Port"
                        rules={
                          this.state.type === "smtp" && [
                            {
                              required: true,
                              message: "Bạn chưa nhập SMTP Port!",
                            },
                          ]
                        }
                      >
                        <Input placeholder="SMTP Port " />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item>
                        <Form.Item name={["smtp", "secure"]} valuePropName="checked" noStyle>
                          <Checkbox style={{ marginTop: "30px" }}>Use TSL</Checkbox>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <Row gutter={16} style={{ marginTop: "10px" }}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name={["smtp", "auth", "username"]}
                      label="User name"
                      rules={
                        this.state.type === "smtp" && [
                          {
                            required: true,
                            message: "Bạn chưa nhập tên đăng nhập!",
                          },
                        ]
                      }
                    >
                      <Input placeholder="Username" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name={["smtp", "auth", "password"]}
                      label="Password"
                      rules={
                        this.state.type === "smtp" && [
                          {
                            required: true,
                            message: "Bạn chưa nhập mật khẩu!",
                          },
                        ]
                      }
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Form>
        )}
        <PopupTestSendEmail ref={this.PopupTestSendEmail}></PopupTestSendEmail>
      </Modal>
    );
  }
}
