import React, { Component } from "react";
import { Input, Button, Select, Popconfirm, Col, message, Card, Row, Image, Statistic, InputNumber, Tooltip } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, CarryOutOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Pie, Line } from "@ant-design/plots";
import api from "api";
import moment from "moment";
import PercentLoyalty from "./percentloyalty";
import { formatCurrency } from "../common/format/number";

export default class CustomerPointEverydayChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lstDataPointEveryday: [],
    };
  }

  componentDidMount = () => {
    this.getAllData(this.state.start_date, this.state.end_date);
  };
  getAllData = () => {
    this.getDataPointChart();
  };

  //tùng code

  //tùng endcode

  //hoàng code
  getDataPointChart = () => {
    var lastdate = moment().add(-7, "days");
    var timezone = new Date().getTimezoneOffset().toString();
    if (timezone.length === 4) timezone = timezone.replace("-", "-0").replace("+", "+0");
    if (timezone.length === 3) timezone = timezone.replace("-", "-00").replace("+", "+00");
    if (timezone.length === 2) timezone = timezone.replace("-", "-000").replace("+", "+000");
    var datapost = {
      timezone: timezone,
      end_date: moment().add(1, "day"),
      start_date: moment().add(-6, "day"),
    };
    var list = [];
    for (var i = 1; i <= 7; i++) {
      lastdate.add(1, "days");
      list.push({
        create_date: lastdate.format("DD/MM/YYYY"),
        type: "add",
        nameline: "Tổng điểm tích được",
        namecol: "Số điểm tích được",
      });
      list.push({
        create_date: lastdate.format("DD/MM/YYYY"),
        type: "use",
        nameline: "Tổng điểm đã dùng",
        namecol: "Số điểm đã dùng",
      });
    }
    api
      .post("dashboard/getdatapointaddedeveryday", datapost)
      .then((res) => {
        list.forEach((item, index) => {
          if (item.type === "add") {
            var data = res.lstAdded.find((x) => x.create_date === item.create_date);
            if (data) {
              item.value = data.value;
            } else {
              item.value = 0;
            }
          } else {
            var data = res.lstUsed.find((x) => x.create_date === item.create_date);
            if (data) {
              item.value = data.value;
            } else {
              item.value = 0;
            }
          }
        });
        this.setState({ lstDataPointEveryday: list, showChart: true });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //hoàng endcode

  render() {
    const config2 = {
      data: this.state.lstDataPointEveryday,
      xField: "create_date",
      yField: "value",
      seriesField: "nameline",
      yAxis: {
        label: {
          formatter: (v) => formatCurrency(v),
        },
      },
      legend: {
        position: "top",
      },
      smooth: true,
      animation: {
        appear: {
          animation: "path-in",
          duration: 5000,
        },
      },
      tooltip: true,
    };
    return (
      <Card title="Biểu đồ tích điểm theo từng ngày">
        <Line {...config2} />
      </Card>
    );
  }
}
